import React, { useState, useRef, useEffect, Fragment } from 'react';
import { Link } from "react-router-dom";
import classNames from "classnames";
import axios from "axios";
import _ from 'underscore';
import { useDebounce } from 'hooks/useDebounce';

import { DateToLocal } from 'components/dates';
import { Breadcrumbs } from 'components/navigation';
import { FormTextInput } from "components/form-fields";
import { LinkButton, Button } from 'components/buttons'
import { ListingTable } from "components/listing";
import { ShowHide } from "components/layout";
import { PageRestricted } from 'pages/errors/page-restricted';
import { UserAccess, UserHasAccess } from 'components/user-access';
import { NotificationToaster, AlertConfirm } from "components/notifications";
import { Filter } from 'components/filtering';
import { Tooltip } from 'components/tooltip';

import { Icon, Intent } from '@blueprintjs/core';
import { HelperFunctions } from "helpers";

import { SelfReportingService } from 'services';

export function ScheduleListing(props) {

    const defaultRecordSize = 25;
    const debounceTimeout = 750;

    const [initalSetupComplete, setInitalSetupComplete] = useState(false);
    const [selectedScheduleId, setselectedScheduleId] = useState(0);
    const [showDeleteConfirm, setShowDeleteConfirm] = useState(false);
    const [totalRecords, setTotalRecords] = useState(0);
    const [rawTableData, setRawTableData] = useState([]);
    const [tableRows, setTableRows] = useState([]);
    const [pageNumber, setPageNumber] = useState(1);
    const [pageSize, setPageSize] = useState(defaultRecordSize);
    const [pageFilters, setPageFilters] = useState([]);
    const [searchTerm, setSearchTerm] = useState("");
    const [loadingData, setLoadingData] = useState(true);
    const [noDataMessage, setNoDataMessage] = useState("You have no schedules set up, please click 'Add new schedule' to get started.");
    const tableHeaders = ["Name", "Status", "Occurence", "Start Date", "End Date", "Next Run Date", "Recipients", "Reports", "Actions"];
    const sortableHeaders = ["Name", "Status", "Occurence", "Start Date", "End Date", "Next Run Date"];
    const [tableSortBy, setTableSortBy] = useState("Name");
    const [tableSortDir, setTableSortDir] = useState("A");
    const [appliedFilters, setAppliedFilters] = useState([]);
    const [showFilters, setShowFilters] = useState(false);
    const debouncedSearchTerm = useDebounce(searchTerm, debounceTimeout);
    const inputSearchRef = useRef(null);
    const requiredAction = "ScheduleReports:Manage";
    const canAccessPage = UserHasAccess(requiredAction);


    const currentPageNumber = useRef();
    const currentPageSize = useRef();
    const currentSearchTerm = useRef();
    const currentTableSortBy = useRef();
    const currentTableSortDir = useRef();
    const currentAppliedFilters = useRef();

    useEffect(() => {
        setPageNumber(1);
        setNoDataMessage("No results found, please try a different search term.");
    }, [debouncedSearchTerm]);

    useEffect(() => {

        axios.all([
            SelfReportingService.getScheduleStatuses(),
            SelfReportingService.getReportGroups()
        ]).then(axios.spread(function (statusList, reportGroups) {

            var mappedStatuses = HelperFunctions.sortAlphabetically(statusList.map(function (status) {
                return { "id": status.name, "name": status.name }
            }), "name");

            var mappedReports = HelperFunctions.sortAlphabetically(
                _.chain(reportGroups).pluck("reports").flatten().uniq(function (r) {
                    return r.id
                }).value(), "name");

            var filterArray = [
                {
                    "name": "Status", "items": [
                        ...mappedStatuses
                    ]
                },
                {
                    "name": "Occurence", "items": [
                        { "id": "day", "name": "Daily" },
                        { "id": "week", "name": "Weekly" },
                        { "id": "month", "name": "Monthly" },
                        { "id": "year", "name": "Yearly" },
                        { "id": "445", "name": "4-4-5 Period" }
                    ]
                },
                {
                    "name": "Reports", "items": [
                        ...mappedReports
                    ]
                }
            ];

            setPageFilters(filterArray);
        }), function () {
            NotificationToaster.show(Intent.DANGER, "Unable to fetch schedules, please try again.");
        });

    }, []);

    function fetchTableData(forceRefresh) {
        if (canAccessPage && initalSetupComplete) {

            if (
                currentPageNumber.current === pageNumber &&
                currentPageSize.current === pageSize &&
                currentSearchTerm.current === debouncedSearchTerm &&
                currentTableSortBy.current === tableSortBy &&
                currentTableSortDir.current === tableSortDir &&
                currentAppliedFilters.current === appliedFilters.length &&
                !forceRefresh
            ) {
                //If none of the values have changed, then 2 have been updated at once, so prevent the listing from performing another call.
                return;
            }

            currentPageNumber.current = pageNumber;
            currentPageSize.current = pageSize;
            currentSearchTerm.current = debouncedSearchTerm;
            currentTableSortBy.current = tableSortBy;
            currentTableSortDir.current = tableSortDir;
            currentAppliedFilters.current = appliedFilters.length;

            setLoadingData(true);
            SelfReportingService.getSchedulesList(pageSize, pageNumber, debouncedSearchTerm, tableSortBy, tableSortDir, appliedFilters).then(function (r) {
                setRawTableData(r.data);
                setTotalRecords(r.totalCount);
                setLoadingData(false);
                
                setTimeout(function () {
                    if (inputSearchRef.current != null) {
                        inputSearchRef.current.focus();
                    }
                });

            });
        }
    }

    useEffect(() => {
        fetchTableData(false);
    }, [pageSize, pageNumber, tableSortBy, tableSortDir, debouncedSearchTerm, appliedFilters.length, initalSetupComplete]);

    useEffect(() => {

        let data = [];

        if (rawTableData != null) {
            data = rawTableData.map((d) => {

                return [
                    <Link to={`/schedule/${d.id}`}>{d.name}</Link>,
                    generateStatusColumn(d),
                    d.friendlyOccurence,
                    <DateToLocal>{d.startDate}</DateToLocal>,
                    <ShowHide
                        evaluator={d.endDate !== "-" && d.endDate !== null}
                        show={(
                            <DateToLocal>{d.endDate}</ DateToLocal>
                        )}
                        hide={("-")}
                    />,
                    <DateToLocal>{d.nextSendDate}</DateToLocal>,
                    d.userCount,
                    d.reportCount,
                    <Fragment>
                        <Button onClick={() => deleteSchedule(d.id)} icon="trash" iconOnly={true} text="Delete Schedule" large={false} minimal={true}></Button>
                        <Tooltip content="Duplicate" position="top">
                            <LinkButton text="Clone Schedule" href={`/schedule/create?clone=${d.id}`} icon="duplicate" iconOnly={true} large={false} minimal={true} />
                        </Tooltip>
                    </Fragment>
                ];

            })
        }

        setTableRows(data);
    }, [rawTableData]);

    function generateStatusColumn(scheduleRow) {
        
        return (
            <div className="inline-items" >
                <span className="inline-item">{scheduleRow.status}</span>
                {
                    scheduleRow.lastRunHasErrors ?
                        <Tooltip className="inline-item">
                            <Link to={`/schedule/${scheduleRow.id}/runs`}><Icon icon="warning-sign" intent="danger" /></Link>Not all reports were generated successfully on the last run
                        </Tooltip>
                    : null
                }
                
                {
                    scheduleRow.userError ?
                        <Tooltip className="inline-item">
                            <Link to={`/schedule/${scheduleRow.id}`}><Icon icon="warning-sign" intent="danger" /></Link>{scheduleRow.errorReason}
                        </Tooltip>
                    : null
                }
                
            </div>
        );
    }


    function onPagingChange(newPageNumber, newPageSize) {
        setPageNumber(newPageNumber);
        setPageSize(newPageSize);
    }

    function deleteSchedule(scheduleId) {
        setselectedScheduleId(scheduleId);
        setShowDeleteConfirm(true);
    }

    function onTableSort(header, direction) {
        setTableSortDir(direction);
        setTableSortBy(header);
    }

    function onDeleteConfirm() {
        setShowDeleteConfirm(false);

        SelfReportingService.deleteSchedule(selectedScheduleId).then(() => {
            NotificationToaster.show(Intent.SUCCESS, "Schedule has been successfully deleted");
            fetchTableData(true);
            setselectedScheduleId(0);
        }, function () {
            NotificationToaster.show(Intent.DANGER, "Unable to delete the schedule, please try again.");
            setselectedScheduleId(0);
        });
    }

    function onSearchChange(item) {
        setSearchTerm(item.target.value);
    }

    function onDeleteCancel() {
        setselectedScheduleId(0);
        setShowDeleteConfirm(false);
    }

    function onToggleFilter() {
        setShowFilters((prevState) => {
            return !prevState;
        })
    }

    function onFilterChange(listingFilters) {
        setInitalSetupComplete(true);
        setPageNumber(1);
        setNoDataMessage("No results found, please try a different filter.");
        setAppliedFilters(listingFilters);

        if (listingFilters.length > appliedFilters.length) {
            setShowFilters(true);
        }
    }

    return (

        <UserAccess perform={requiredAction}
            yes={() => (
                <div className="row">
                    <Breadcrumbs items={props.breadcrumbs} />

                    <h1>Schedule Management</h1>
                    <div className="intro-text">
                        <p>In this section you can add and manage the schedules.</p>
                    </div>

                    <div id="new-schedule-holder" className="spacer-bottom">
                        <LinkButton intent="primary" text="Add new schedule" href="/schedule/create" id="new-schedule" />
                    </div>

                    <ShowHide
                        evaluator={tableRows.length === 0 && !loadingData && debouncedSearchTerm === "" && appliedFilters.length === 0 && initalSetupComplete}
                        hide={(
                            <Fragment>
                                <div className={classNames("pull-left", { "spacer-bottom": !showFilters })}>
                                    <div className="inline-items">
                                        <FormTextInput inputRef={inputSearchRef} placeholder="Schedule Search" onChange={onSearchChange} value={searchTerm} large disabled={loadingData} icon="search" id="schedule-search-field" />
                                        <Tooltip content="Filter" position="right">
                                            <Button icon="filter" onClick={onToggleFilter} className={classNames({ "active": appliedFilters.length > 0 })} />
                                        </Tooltip>
                                    </div>
                                </div>
                                <Filter filterName="schedules" visible={showFilters} filters={pageFilters} onUpdate={onFilterChange} />
                            </Fragment>
                        )}
                    />

                    <ListingTable
                        id="listing-table-schedule"
                        noDataMessage={noDataMessage}
                        sortable
                        sortableHeaders={sortableHeaders}
                        sortedBy={tableSortBy}
                        sortedDir={tableSortDir}
                        onSort={onTableSort}
                        headers={tableHeaders}
                        data={tableRows}
                        totalRecordCount={totalRecords}
                        onPagingChange={onPagingChange}
                        loadingData={loadingData}
                    />

                    <AlertConfirm
                        title="Please confirm"
                        isOpen={showDeleteConfirm}
                        onConfirm={onDeleteConfirm}
                        onCancel={onDeleteCancel}
                    >
                        <p>Are you sure you want to delete this schedule?</p>
                    </AlertConfirm>

                </div>
            )}
            no={() => (
                <PageRestricted />
            )}
        />



    );

}
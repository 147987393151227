import { Icon, Intent } from '@blueprintjs/core';
import { Breadcrumbs } from 'components/navigation';
import { AlertUnsavedData, NotificationInline, NotificationToaster, AlertConfirm } from 'components/notifications';
import { UserAccess, UserHasAccess } from 'components/user-access';
import { Wizard } from 'components/wizard';
import PropTypes from 'prop-types';
import React, { Fragment, useEffect, useState } from 'react';
import { useParams } from 'react-router';
import { JobService } from 'services';
import { OcrSection } from './shared';
import { useDirtyData } from 'hooks/useDirtyData';
import { ShowHide } from 'components/layout';
import { FormReadonly } from 'components/form-fields';
import { DataDisplayTable } from 'components/data-display';
import { DateToLocal } from 'components/dates';
import { PageRestricted } from 'pages/errors/page-restricted';
import { LinkButton } from 'components/buttons';
import { useDispatch } from 'react-redux'
import { InstantReportButton } from 'components/reporting/InstantReportButton';


export function OcrCompleteReview(props) {
    const [savingSection, setSavingSection] = useState(false);
    const [savingReviewConfirmationShow, setSavingReviewConfirmationShow] = useState(false);
    const [sections, setSections] = useState([]);
    const [wizardSteps, setWizardSteps] = useState([]);
    const [employees, setEmployees] = useState([]);
    const [assets, setAssets] = useState([]);
    const [loadingWizardSteps, setLoadingWizardSteps] = useState(true);
    const [currentStep, setCurrentStep] = useState(0);
    const [wizardComplete, setWizardComplete] = useState(false);
    const [reportComplete, setReportComplete] = useState(false);
    const [allSectionsComplete, setAllSectionsComplete] = useState(false);
    const [loading, setLoading] = useState(false);
    const [originalSection, setOriginalSection] = useState({});
    const [currentSection, setCurrentSection] = useState({});
    const [failedAppointment, setFailedAppointment] = useState(false);
    const [downloadingReport, setDownloadingReport] = useState(false);
    const [generatingReportInstanceId, setGeneratingReportInstanceId] = useState(null);
    const [failedInfo, setFailedInfo] = useState([]);
    const [failedReason, setFailedReason] = useState("");
    const [failedComments, setFailedComments] = useState("");
    const [previsit, setPrevisit] = useState({});
    const isAuditor = UserHasAccess("Internal:OCR");
    const dispatch = useDispatch();
 

    const isDataDirty = useDirtyData(originalSection, currentSection);

    const { jobId } = useParams();

    useEffect(() => {
        onInitialLoad();
    }, [jobId]);

    useEffect(() => {
        const mappedWizardSteps = sections.map(section => {
            return {
                stepTitle: section.sectionTitle,
                stepStatus: getSectionStatus(section)
            };
        });

        setWizardSteps(mappedWizardSteps);
        setAllSectionsComplete(sections.every(value => value.sectionComplete === true));

    }, [sections]);

    useEffect(() => {
        if (currentStep != null && currentStep < sections.length) {
            const newCurrentSection = sections[currentStep];
            setCurrentSection(newCurrentSection);

            if (sections[currentStep].sectionId !== currentSection.sectionId) {
                loadSection(newCurrentSection.sectionId);
            }
        }
    }, [sections, currentStep]);

    function onInitialLoad() {

        JobService.getOcrPrevisit(jobId).then(function (previsit) {
            setPrevisit(previsit);
            var isReportComplete = previsit.jobState.toLowerCase() === 'done';
            var isAppointmentFailed = previsit.jobState.toLowerCase() === 'failed appointment';

            setReportComplete(previsit.jobState.toLowerCase() === 'done' || previsit.jobState.toLowerCase() === 'done (incomplete)');
            setFailedAppointment(isAppointmentFailed);

            dispatch({ type: 'SET_REDIRECT_TAB', payload: isReportComplete || isAppointmentFailed ? "Completed" : "Scheduled Compliance Reviews" });

            if (isAppointmentFailed) {
                setFailedInfo([
                    { header: "Company Name", value: previsit.failedAppointment.companyName },
                    { header: "Location", value: previsit.failedAppointment.location },
                    { header: "Advisor's Name", value: previsit.failedAppointment.staffName },
                    { header: "Scheduled Date", value: <DateToLocal>{previsit.failedAppointment.scheduledDate}</DateToLocal> },
                    { header: "Reference Number", value: previsit.failedAppointment.referenceNumber },
                    { header: "Product", value: previsit.failedAppointment.product },
                ]);
                setFailedReason(previsit.failedAppointment?.failedAppointmentReason?.failureReason);
                setFailedComments(previsit.failedAppointment?.failedAppointmentReason?.failureAdditionalComments);
            }
            else {
                JobService.getOcrSectionsData(jobId).then(function (sectionData) {
                    setCurrentStep(0);
                    setLoadingWizardSteps(false);
                    setSections(sectionData.sections);
                    setEmployees(sectionData.employees);
                    setAssets(sectionData.assets);
                }, () => {
                    NotificationToaster.show(Intent.DANGER, "Could not load compliance review. Please refresh the page.");
                });
            }

        }, function() {
            NotificationToaster.show(Intent.DANGER, "Could not load compliance review. Please refresh the page.");
        });

       
    }

    function getSectionStatus(section) {
        if (!section.sectionComplete) {
            return 'INCOMPLETE';
        }

        if (!section.businessLogicResult) {
            return 'COMPLETE';
        }

        const businessLogicResult = section.businessLogicResult.toUpperCase();

        if (businessLogicResult === 'TRUE') {
            return 'COMPLETE';
        } else {
            return 'COMPLETE-WITH-ERRORS';
        }
    }

    function onStepChange(step) {
        setCurrentStep(step);
    }

    function onSectionChange(updatedSection) {
        setSections(previousSections => {
            const sectionsClone = [...previousSections];

            const sectionIndex = sectionsClone.findIndex(clonedSection => clonedSection.sectionId === updatedSection.sectionId);
            sectionsClone[sectionIndex] = updatedSection;

            return sectionsClone;
        });
    }

    function getAdditionalButtons() {

        function showPrevisitButton() {

            if (isAuditor) {
                return (
                    <LinkButton intent="primary" text="View pre-visit settings" href={`/reviews/compliance-review/pre-visit/${jobId}`} />
                );
            }

            return null;
        }

        if (reportComplete) {
            return (
                <Fragment>
                    <InstantReportButton 
                        onReportGenerate={onReportGenerate} 
                        onReportDownloadComplete={onReportDownloadComplete} 
                        id="downloadAlerts" 
                        text="Download report" 
                        reportId={generatingReportInstanceId} 
                        buttonDisabled={downloadingReport} 
                        intent="primary"
                    />
                    {showPrevisitButton()}
                </Fragment>

            );
        } else {
            return (
                <Fragment>
                    {showPrevisitButton()}
                </Fragment>

            );
        }
    }

    function finish() {
        setSavingSection(true);

        if (!allSectionsComplete) {
            setSavingReviewConfirmationShow(true);
        } else {
            completeReview();
        }
    }

    function finishCancel() {
        setSavingReviewConfirmationShow(false);
        setSavingSection(false);
    }

    function completeReview() {
        setSavingReviewConfirmationShow(false);

        JobService.saveOcrSection(jobId, currentSection.layouts)
            .then(() => {

                JobService.completePrevisit(jobId).then(
                    () => {
                        setWizardComplete(true);
                        setSavingSection(false);
                        setReportComplete(true);
                    },
                    (error) => {
                        setSavingSection(false);
                        NotificationToaster.show(Intent.DANGER, error);
                    }
                );

            }, function () {
                NotificationToaster.show(Intent.DANGER, "Could not save compliance review section. Please try again.");
                setSavingSection(false);
            });
    }

    function saveSection() {
        setSavingSection(true);
        return JobService.saveOcrSection(jobId, currentSection.layouts)
            .then(() => {
                setSavingSection(false);
                NotificationToaster.show(Intent.SUCCESS, "Compliance review section saved successfully.");

                loadSection(currentSection.sectionId);
            },
                () => {
                    NotificationToaster.show(Intent.DANGER, "Could not save compliance review section. Please try again.");
                    setSavingSection(false);
                });
    }

    function loadSection(sectionId) {
        setLoading(true);
        JobService.getOcrSection(jobId, sectionId)
            .then(loadedSection => {
                setLoading(false);
                onSectionChange(loadedSection);
                setOriginalSection(loadedSection);
            },
                () => {
                    NotificationToaster.show(Intent.DANGER, "Could not retrieve compliance review section. Please try again.");
                });
    }

    function onReportGenerate() {
        setDownloadingReport(true);
        JobService.generateOcrReport(jobId).then(function (result) {
            setGeneratingReportInstanceId(result);
        }, function (error) {
            NotificationToaster.show(Intent.DANGER, error);
            setDownloadingReport(false);
        });
    }

    function renderWizardFooter() {
        return (
            <NotificationInline show={downloadingReport && generatingReportInstanceId != null} allowClose={false} intent="info" text="Your report is being generated, download will begin once the report is ready." />
        );
    }

    function onReportDownloadComplete()
    {
        setDownloadingReport(false);
        setGeneratingReportInstanceId(null);
    }

    return <UserAccess perform={["Internal:OCR", "ComplianceReview:View"]}
        yes={() => (
            <div className="row">
                <Breadcrumbs items={props.breadcrumbs} />
                <ShowHide
                    evaluator={!failedAppointment}
                    show={(
                        <ShowHide
                            evaluator={!wizardComplete}
                            show={(
                                <Fragment>
                                    <Wizard
                                        onStepChange={(step) => onStepChange(step)}
                                        onFinish={finish}
                                        canProceed
                                        additionalStepInformation={wizardSteps}
                                        showStepTitles
                                        allowDirectStepNavigation
                                        saveBetweenSteps={!reportComplete}
                                        disabled={savingSection}
                                        loading={loadingWizardSteps}
                                        additionalButtons={getAdditionalButtons()}
                                        canFinish={!reportComplete}
                                        saveAction={saveSection}
                                        footer={renderWizardFooter}
                                        isComplete={reportComplete}
                                        showFinishOnAllSteps
                                    >
                                        {sections.map((section, index) => {
                                            return (
                                                <div key={section.sectionId}>
                                                    <OcrSection
                                                        section={sections[currentStep]}
                                                        step={index}
                                                        employees={employees}
                                                        assets={assets}
                                                        onSectionChange={onSectionChange}
                                                        saving={savingSection}
                                                        loading={loading}
                                                        readonly={reportComplete}
                                                        previsit={previsit}
                                                    />
                                                </div>
                                            );
                                        })}
                                    </Wizard>
                                    <AlertUnsavedData
                                        isDirty={isDataDirty}>
                                    </AlertUnsavedData>
                                </Fragment>
                            )}
                            hide={(
                                <div>
                                    <div className="spacer-bottom" id="create-success-icon">
                                        <Icon icon="tick-circle" iconSize={40} intent={Intent.SUCCESS} />
                                    </div>
                                    <h2 id="create-success-name">Compliance review has been completed successfully</h2>
                                    <div className="button-row" id="create-success-buttons">
                                        <LinkButton intent="primary" text="Compliance reviews" id="compliance-review-return" href="/reviews" />
                                    </div>
                                </div>
                            )}
                        />
                        
                    )}
                    hide={(
                        <div>
                            <h1>Failed Appointment</h1>
                            <DataDisplayTable
                                data={failedInfo}
                                displayInRows={false}
                                loading={loading}
                            />

                            <FormReadonly bold headingText="Reason for failure" value={failedReason} />
                            <FormReadonly bold headingText="Additional Comments" value={failedComments} />
                        </div>
                    )}
                />

                <AlertConfirm
                    title="Incomplete Review Confirmation"
                    isOpen={savingReviewConfirmationShow}
                    onConfirm={completeReview}
                    onCancel={finishCancel}
                    confirmButtonIntent="primary"
                    confirmButtonText="Finish"
                >
                    <p>This review is incomplete, are you sure you want to save and finish?</p>
                </AlertConfirm>
            </div>
        )}
        no={() => (
            <PageRestricted />
        )}
    />;
}

OcrCompleteReview.propTypes = {
    breadcrumbs: PropTypes.array.isRequired,
};

import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { TimePicker } from "@blueprintjs/datetime";
import { FormHelperText } from "components/form-fields";
import classNames from "classnames";
import moment from "moment";
import 'components/form-fields/FormFields.css';
import "moment/locale/en-gb";
import { ShowHide } from "components/layout";

const dateLocale = "en-gb";

export function FormTimePicker(props) {
    const [value, setValue] = useState(null);
    const minTimeDefault = moment().startOf('day').toDate();
    const maxTimeDefault = moment().endOf('day').toDate();
    const [minTime, setMinTime] = useState(minTimeDefault);
    const [maxTime, setMaxTime] = useState(maxTimeDefault);

    useEffect(() => {
        var newValue = null;

        if (props.value) {
            newValue = moment.utc(props.value).locale(dateLocale).toDate();
        }

        setValue(newValue);

    }, [props.value]);

    function setMinTimeFromProps() {
        const newMinTime = props.minTime ? props.minTime : minTimeDefault;
        setMinTime(newMinTime);
    }
    function setMaxTimeFromProps() {
        const newMaxTime = props.maxTime ? props.maxTime : maxTimeDefault;
        setMaxTime(newMaxTime);
    }

    function handleTimeChange(selectedDate) {        
        if (selectedDate === "") {
            selectedDate = null;
        }
        props.onChange(selectedDate);
    }

    useEffect(setMinTimeFromProps, [props.minTime]);
    useEffect(setMaxTimeFromProps, [props.maxTime]);

    return (
        <div className="form-field">
            <ShowHide
                evaluator={props.headingText != null}
                show={(
                    <h4 className={classNames({ "bp3-skeleton": props.loading })}>{props.headingText}</h4>
                )}
            />
            <FormHelperText loading={props.loading}>{props.helperText}</FormHelperText>
            <TimePicker
                disabled={props.disabled}
                className={classNames({ "bp3-skeleton": props.loading }, { "margin-up": props.showArrowButtons })}
                value={value}
                onChange={handleTimeChange}
                minTime={minTime}
                maxTime={maxTime}
                showArrowButtons={props.showArrowButtons}
                useAmPm={props.useAmPm}
            ></TimePicker>
            <FormHelperText loading={props.loading} danger={true}>{props.dangerHelperText}</FormHelperText>
        </div>
    );
}

FormTimePicker.defaultProps = {
    id: null,
    value: null,
    disabled: false,
    loading: false,
    minTime: null,
    maxTime: null,
    headingText: null,
    dangerHelperText: "",
    helperText: "",
    showArrowButtons: true,
    useAmPm: false
};

FormTimePicker.propTypes = {
    id: PropTypes.string,
    value: PropTypes.string,
    minTime: PropTypes.instanceOf(Date),
    maxTime: PropTypes.instanceOf(Date),
    loading: PropTypes.bool,
    headingText: PropTypes.string,
    disabled: PropTypes.bool,
    onChange: PropTypes.func.isRequired,
    dangerHelperText: PropTypes.string,
    helperText: PropTypes.string,
    showArrowButtons: PropTypes.bool,
    useAmPm: PropTypes.bool
};
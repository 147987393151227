import React, { Fragment, useState, useEffect } from 'react';
import { FormTextArea, FormCheckboxList, FormTextInput, FormMultiSelect, FormSwitch, FormNumericInput, FormSelect, FormReadonly } from "components/form-fields";
import { Table } from 'components/listing';
import { ShowHide } from 'components/layout';
import { useValidation } from "hooks/useValidation";

var _ = require('underscore');


export function SelfReportingReportSettings(props) {

    const [reportTypes, setReportTypes] = useState([]);
    const [paramHeaderList, setParamHeaderList] = useState([]);
    const [showMinMaxInt, setShowMinMaxInt] = useState(false);
    const [isValid, errors, validate] = useValidation();
    const numWithBounds = 'number with bounds';

    useEffect(function (oldValue) {
        if (oldValue == null && props.report != null) {
            updateSettingsAndValidate(props.report);
        }
    }, [props.report]);

    useEffect(() => {

        if (props.reportTypes.length > 0) {
            mapReportTypes(props.reportTypes);
            setReportHeadings();
        }

        function mapReportTypes(data) {

            var mappedTypes = _.map(data, function (item) {
                const reportHasType = _.some(props.report.reportTypes, function (t) {
                    return t.id === item.id;
                });

                return {
                    ...item,
                    checked: reportHasType
                };
            });

            setReportTypes(mappedTypes);


        }

    }, [props.report.reportTypes, props.reportTypes]);

    useEffect(function () {
        props.onValidationChange(isValid);
    }, [isValid]);

    function setReportHeadings(paramList) {

        if (!paramList) {
            paramList = props.report.reportParameters;
        }

        if (paramList && paramList.some(p => p.parameterType.name.toLowerCase() === numWithBounds)){
            setParamHeaderList(["Parameter Name", "Friendly Name", "Parameter Type", "Min value", "Max Value", "Visible to User"]);
            setShowMinMaxInt(true);
        } else {
            setParamHeaderList(["Parameter Name", "Friendly Name", "Parameter Type", "Visible to User"]);
            setShowMinMaxInt(false);
        }
    }

    function onNameChange(event) {

        var updatedReport = {
            ...props.report,
            name: event.target.value
        }

        updateSettingsAndValidate(updatedReport);
    }

    function onDescriptionChange(event) {
        var updatedReport = {
            ...props.report,
            description: event.target.value
        }

        updateSettingsAndValidate(updatedReport);
    }

    function onReportGroupSelect(item) {

        var tempList = [...props.report.reportGroups];

        if (tempList.some(x => x.id === item.id)) {
            return;
        }

        tempList.push(item);

        updateSettingsAndValidate({
            ...props.report,
            reportGroups: tempList
        });

    }

    function onReportGroupRemove(i, index) {

        var tempList = [...props.report.reportGroups];
        tempList.splice(index, 1);

        updateSettingsAndValidate({
            ...props.report,
            reportGroups: tempList
        });
    }

    function onReportTypesChange(event, id) {

        var tempItems = [...reportTypes];

        var match = _.find(tempItems, function (item) {
            return item.id === id;
        });
        match.checked = event.target.checked;

        var selectedItems = _.filter(tempItems, function (item) {
            return item.checked;
        });

        updateSettingsAndValidate({
            ...props.report,
            reportTypes: selectedItems
        });
    }

    function onDateRangeRestrict(item) {
        var updatedReport = {
            ...props.report,
            restrictDateRange: item.currentTarget.checked
        }

        updateSettingsAndValidate(updatedReport);
    }

    function onRestrictMonthChange(item) {
        var updatedReport = {
            ...props.report,
            restrictNumberOfMonths: item
        }

        updateSettingsAndValidate(updatedReport);
    }

    function onInternalOnly(item) {

        var updatedReport = {
            ...props.report,
            internal: item.currentTarget.checked
        }

        updateSettingsAndValidate(updatedReport);
    }

    function updateSettingsAndValidate(report) {

        if (props.loading) {
            return;
        }

        props.onSettingsUpdate(report);

        let rules = [];
        rules.push({ fieldName: "name", required: true });
        rules.push({ fieldName: "description", maxLength: 4000 });
        rules.push({ fieldName: "locationBusinessAreaId", required: true });
        rules.push({ fieldName: "reportGroups", required: true });
        rules.push({ fieldName: "reportTypes", required: true });

        if (report.restrictDateRange) {
            rules.push({ fieldName: "restrictNumberOfMonths", minValue: 1, maxValue:30 });
        }

        let externalRules = null;

        var paramsInError = report.reportParameters.filter(p => p.parameterType.name.toLowerCase() === numWithBounds
            && p.intMin >= p.intMax)

        if (paramsInError && paramsInError.length > 0) {

            let externalRule = {
                fieldName: `paramMinValue`,
                valid: false,
                errorMessage: "The number with bounds min value must be less than the max value."
            };
            externalRules = [externalRule];
        }

        validate(rules, report, externalRules);
    }

    function formatParamTableHeaders() {
        return {
            headers: paramHeaderList.map((value) => {
                return {
                    key: value,
                    value: value
                }
            })
        };
    }

    function onParamChange(index, event) {

        var updatedParams = [
            ...props.report.reportParameters
        ];

        updatedParams[index].friendlyName = event.target.value;

        var updatedReport = {
            ...props.report,
            reportParameters: updatedParams
        }

        updateSettingsAndValidate(updatedReport);

    }

    function onParamTypeChange(index, item) {

        var updatedParams = [
            ...props.report.reportParameters
        ];

        updatedParams[index].parameterType = item;

        updatedParams.forEach(p => {
            if (p.parameterType.name.toLowerCase() !== numWithBounds) {
                p.intMin = null;
                p.intMax = null;
            } else {
                p.intMin = p.intMin == null ? 0 : p.intMin;
                p.intMax = p.intMax == null ? 1 : p.intMax;
            }
        });

        var updatedReport = {
            ...props.report,
            reportParameters: updatedParams
        }

        setReportHeadings(updatedParams);

        updateSettingsAndValidate(updatedReport);
    }

    function onParamIntBoundsTypeChange(index, minBoundChanged, value) {

        var updatedParams = [
            ...props.report.reportParameters
        ];

        if (minBoundChanged) {
            updatedParams[index].intMin = value;
        } else {
            updatedParams[index].intMax = value;
        }

        var updatedReport = {
            ...props.report,
            reportParameters: updatedParams
        }

        updateSettingsAndValidate(updatedReport);
    }

    function onBusinessAreaChange(item) {

        var updatedReport = {
            ...props.report,
            locationBusinessAreaId: item.id
        }

        updateSettingsAndValidate(updatedReport);
        
    }

    function sortReportsByTitle(a, b) {
        var nameA = a.name.toUpperCase();
        var nameB = b.name.toUpperCase();
        if (nameA < nameB) {
            return -1;
        }
        if (nameA > nameB) {
            return 1;
        }

        // names must be equal
        return 0;
    }

    function formatParamTable() {

        return props.report.reportParameters.sort(sortReportsByTitle).map((row, rowIndex) => {
            var editRow = [
                row.name,
                <FormTextInput
                    loading={props.loading}
                    disabled={props.saving}
                    value={row.friendlyName}
                    onChange={(ev) => onParamChange(rowIndex, ev)}
                />,
                <FormSelect
                    disabled={props.saving}
                    loading={props.loading}
                    items={props.reportParameterTypes}
                    onItemSelect={(ev) => onParamTypeChange(rowIndex, ev)}
                    selectedValue={row.parameterType.id}
                ></FormSelect>
            ];

            if (showMinMaxInt) {
                editRow.push(row.parameterType.name.toLowerCase() === numWithBounds ?
                    <FormNumericInput id="input-param-min-value" loading={props.loading} allowDecimal={false} onValueChange={(ev) => onParamIntBoundsTypeChange(rowIndex, true, ev)} min={0} max={500} selectedValue={row.intMin}
                    />
                    : '');
                editRow.push(row.parameterType.name.toLowerCase() === numWithBounds ?
                    <FormNumericInput id="input-param-max-value" loading={props.loading} allowDecimal={false} onValueChange={(ev) => onParamIntBoundsTypeChange(rowIndex, false, ev)} min={0} max={500} selectedValue={row.intMax} />
                    : '');
            }

            editRow.push(row.hidden ? "No" : "Yes");

            return {
                cells: editRow,
                key: rowIndex
            };
        });

    }


    return (

        <div>
            <FormReadonly
                loading={props.loading}
                headingText="Report URL:"
                value={props.report.url}
            />
           
            <FormTextInput
                id="input-report-name"
                loading={props.loading}
                disabled={props.saving}
                headingText="Report name:"
                value={props.report.name}
                onChange={onNameChange}
                dangerHelperText={errors.name}
            />

            <FormTextArea
                disabled={props.saving}
                loading={props.loading}
                onChange={onDescriptionChange}
                headingText="Report description:"
                value={props.report.description}
                dangerHelperText={errors.description}
            />

            <FormSelect
                disabled={props.saving}
                loading={props.loading}
                items={props.businessAreas}
                headingText="Report business area:"
                onItemSelect={onBusinessAreaChange}
                selectedValue={props.report.locationBusinessAreaId}
                dangerHelperText={errors.locationBusinessAreaId}
            ></FormSelect>

            <FormMultiSelect
                id="input-report-groups"
                headingText="Which report groups should the report belong to?"
                items={props.reportGroups}
                onItemSelect={onReportGroupSelect}
                selectedValues={props.report.reportGroups}
                placeholder="Add to report groups"
                loading={props.loading}
                disabled={props.disabled}
                onRemove={onReportGroupRemove}
                dangerHelperText={errors.reportGroups}
            ></FormMultiSelect>

            <FormCheckboxList
                id="input-report-types"
                items={reportTypes}
                onChange={onReportTypesChange}
                headingText="Report types:"
                loading={props.loading}
                disabled={props.saving}
                dangerHelperText={errors.reportTypes}
            ></FormCheckboxList>

            <FormSwitch
                disabled={props.saving}
                label="Restrict date range:"
                onChange={onDateRangeRestrict}
                checked={props.report.restrictDateRange}
                loading={props.loading}
                id="input-restrict-date"
                helperText={(<Fragment><p>Select this option if you want to restrict the user's to only being able to run this report for a certain number of months</p></Fragment>)}
            ></FormSwitch>

            <ShowHide
                evaluator={props.report.restrictDateRange}
                show={(
                    <FormNumericInput
                        disabled={props.saving}
                        min={1}
                        max={30}
                        selectedValue={props.report.restrictNumberOfMonths}
                        onValueChange={onRestrictMonthChange}
                        headingText={"Number of months to restrict the report to:"}
                        loading={props.loading}
                        id="input-stat-number-days"
                        dangerHelperText={errors.restrictNumberOfMonths}
                    ></FormNumericInput>
                )}
            />

            <div className='spacer-bottom form-field'>
                <h4>Report parameters:</h4>
                <Table
                    headerData={formatParamTableHeaders()}
                    data={formatParamTable()}
                    loadingData={false}
                    fitToContent={false}
                    dangerHelperText={errors.paramMinValue}
                />
            </div>

            <FormSwitch
                disabled={props.saving}
                label="Make report internal only:"
                onChange={onInternalOnly}
                checked={props.report.internal}
                loading={props.loading}
                id="input-internal-only"
                helperText={(<Fragment><p>Setting this to true will allow the report to be tested without external users being able to see it</p></Fragment>)}
            ></FormSwitch>

        </div>

    );

}
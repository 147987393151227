import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Intent } from '@blueprintjs/core';

import { ButtonSave } from "components/buttons";
import { NotificationToaster, AlertUnsavedData } from "components/notifications";
import { HierarchyService, LocationService } from "services";
import { HierarchyStructure as Structure } from 'components/hierarchy';
import { UserPermissionsSelection } from "pages/locations/hierarchies/edit/shared";
import { useDirtyData } from "hooks/useDirtyData";
import { ShowHide } from 'components/layout';
import { HelperFunctions } from 'helpers';


export function HierarchyStructure(props) {

    const [structure, setStructure] = useState([]);
    const [locations, setLocations] = useState([]);
    const [loading, setLoading] = useState(true);
    const [saving, setSaving] = useState(false);
    const [convertUserPermissions, setConvertUserPermissions] = useState(null);
    const [groupsBeenDeleted, setGroupsBeenDeleted] = useState(false);
    const [permissionHierarchy, setPermissionHierarchy] = useState(false);


    const [isDirty, setIsDirty] = useState(false);
    const isDataDirty = useDirtyData({ changesOccurred: false }, { changesOccurred: isDirty });

    useEffect(loadHierarchyStructure, [props.hierarchy]);

    function loadHierarchyStructure() {

        setLoading(true);       

        if (props.hierarchy.businessAreas.length === 0) {
            return;
        }

        //CSS class to apply to locations to show thier type
        function calculateBusinessAreaClass(businessAreas, availableAreas, locationState, archived) {

            var displayAreas = businessAreas.filter(function (ba) {
                return availableAreas.some(function (aa) {
                    return aa.locationBusinessAreaId === ba.locationBusinessAreaId;
                })
            });

            var classArray = displayAreas.map(function (area) {
                return area.businessArea.toLowerCase();
            });

            var isInactive = locationState.name.toLowerCase() === "inactive" || !!archived

            return `${classArray.join("-")}-loc ${isInactive ? "inactive-loc" : ""}`;
        }

        var locationFilters = props.hierarchy.businessAreas.map(function (ba) {
            return {
                "key": "BusinessAreas",
                "value": ba.businessArea
            };
        });

        setPermissionHierarchy(props.hierarchy.hierarchyFeatures.some(function (hf) {
            return hf.featureType === "PERMISSIONS"
        }));

        axios.all([
            HierarchyService.getHierarchyStructure(props.hierarchyId),
            LocationService.getLocationList(0, 1, "", "", "", { "filters": locationFilters })
        ]).then(axios.spread(function (structureResponse, locationResponse) {

            var mappedLocationList = locationResponse.data.map((l) => {
                return {
                    ...l,
                    class: calculateBusinessAreaClass(l.businessAreas, props.hierarchy.businessAreas, l.state, l.archived)
                };
            });
            setLocations(mappedLocationList);

            setLocationBusinessAreas(structureResponse, mappedLocationList);
            //map the structure

            setStructure(structureResponse);
            setLoading(false);

        }), () => {
            NotificationToaster.show(Intent.DANGER, "Could not load the hierarchy structure. Please refresh the page.");
        });
    }


    function setLocationBusinessAreas(currentStructure, mappedLocations) {
        for (var i = 0; i < currentStructure.length; i++) {
            var currentItem = currentStructure[i];

            if (currentItem.locations != null && currentItem.locations.length > 0) {
                for (var j = 0; j < currentItem.locations.length; j++) {
                    var currentLocation = currentItem.locations[j];

                    var foundLocation = mappedLocations.find(function (l) {
                        return l.id === currentLocation.locationId;
                    });

                    if (foundLocation != null) {
                        currentLocation.class = foundLocation.class;
                    }
                }
            }

            if (currentItem.groups != null && currentItem.groups.length > 0) {
                setLocationBusinessAreas(currentItem.groups, mappedLocations);
            }

        }
    }

    function onHierarchyStructureUpdate(newStructure, action) {
        if (!groupsBeenDeleted) {
            setGroupsBeenDeleted(action === "GROUPREMOVE")
        }
        setStructure(newStructure);
    }

    function setStructureAsDirty() {
        setIsDirty(true);
    }

    function onConvertUserPermissionsChange(value) {
        setConvertUserPermissions(value);
    }

    function onHierarchySave() {
        setSaving(true);

        HierarchyService.updateHierarchyStructure(props.hierarchyId, structure, convertUserPermissions).then(
            () => {
                setSaving(false);
                setIsDirty(false);
                setConvertUserPermissions(null);
                setGroupsBeenDeleted(false);
                loadHierarchyStructure();
                NotificationToaster.show(Intent.SUCCESS, "Hierarchy updated successfully.");
            },
            (error) => {
                setSaving(false);
                const badRequestCode = 400;
                if (error.response.status === badRequestCode) {
                    NotificationToaster.show(Intent.WARNING, error.response.data);
                } else {
                    NotificationToaster.show(Intent.DANGER, "Could not update the hierarchy. Please try again.");
                }
            }
        );
    }

    function onHierarchyExport() {
        return new Promise((resolve, reject) => {
            HierarchyService.exportHierarchy(props.hierarchyId).then(
                (result) => {
                    NotificationToaster.show(Intent.SUCCESS, "Hierarchy has been exported successfully, download will now begin.");

                    var filename = result.headers["download-filename"];

                    if (filename == null) {
                        filename = "Hierarchy-Export.xls"
                    }

                    HelperFunctions.downloadFile(result.data, filename);
                    resolve();
                },
                (error) => {
                    NotificationToaster.show(Intent.DANGER, "Could not export the hierarchy. Please try again.");
                    reject(error);
                });
        });
    }

    return (
        <div>

            <Structure onHierarchyUpdate={onHierarchyStructureUpdate} hierarchy={props.hierarchy} structure={structure} hierarchyId={props.hierarchyId} locations={locations} loading={loading} saving={saving} onHierarchyDirtyChange={setStructureAsDirty} export={onHierarchyExport} />

            <AlertUnsavedData
                isDirty={isDataDirty}>
            </AlertUnsavedData>

            <ShowHide
                evaluator={permissionHierarchy && groupsBeenDeleted}
                show={(
                    <div className="spacer-bottom">
                        <UserPermissionsSelection alertText="You are about to delete hierarchy groups that control users permissions. What would you like to happen to users who are inheriting locations through these groups?" onConvertUserPermissionsChange={onConvertUserPermissionsChange} value={convertUserPermissions == null ? "" : convertUserPermissions.toString()} />
                    </div>
                )}
            />

            <ButtonSave
                id="hierarchy-settings-save"
                onClick={onHierarchySave}
                disabled={saving}
                loading={loading}
                simpleDisabled={permissionHierarchy && groupsBeenDeleted && convertUserPermissions == null}
            ></ButtonSave>
        </div>
    );

}
import React, { Fragment, useEffect, useState, useRef } from "react";
import { useParams } from 'react-router-dom';
import classNames from 'classnames';
import { Intent } from '@blueprintjs/core';
import axios from "axios";

import { ShowHide } from 'components/layout';
import { NotificationInline, NotificationToaster } from 'components/notifications';
import { SelfReportingService, BlobStorageService } from "services";
import { ButtonSave, Button } from "components/buttons";
import { Tooltip } from "components/tooltip";
import { ReportParameters } from 'components/settings';


export function SelfServiceReport(props) {

    const requestModel = {
        reportType: null,
        parameters: []
    }

    const { id } = useParams();
    const [loading, setLoading] = useState(false);
    const [report, setReport] = useState({});
    const [generatingReport, setGeneratingReport] = useState(false);
    const [formatTypes, setFormatTypes] = useState([]);
    const [invalidReport, setInvalidReport] = useState(false);
    const [favourited, setFavourited] = useState(false);
    const [generationRequest, setGenerationRequest] = useState(requestModel);
    const [locations, setLocations] = useState([]);
    const [isValid, setIsValid] = useState(false);
    const [generatingReportInstanceId, setGeneratingReportInstanceId] = useState(null);
    const generationCancelTokenSource = useRef();

    useEffect(init, [id]);

    function init() {
        if (id === 0 || id == null) {
            return;
        }

        setLoading(true);
        setInvalidReport(false);
        setReport({});
        setGenerationRequest(requestModel);
        setFormatTypes([]);
        setGeneratingReportInstanceId(null);
        setGeneratingReport(false);

        if (generationCancelTokenSource.current != null) {
            generationCancelTokenSource.current.cancel();
            generationCancelTokenSource.current = null;
        }

        SelfReportingService.getReport(id, false).then(function (reportResponse) {

            setReport(reportResponse);
            setLoading(false);

            setFormatTypes(reportResponse.reportTypes.map(function (rt) {
                return {value: rt.id, label: rt.name}
            }));

            var defaultReportType = null;

            if (reportResponse.reportTypes.length === 1) {
                defaultReportType = reportResponse.reportTypes[0].id;
            }

            var initalParams = reportResponse.reportParameters.map(function (rp) {
                var defaultValue = null;

                if (rp.options != null && rp.options.length > 0) {
                    var defaultOption = rp.options.find(function (o) {
                        return o.default;
                    });

                    if (defaultOption != null) {
                        if (rp.parameterType.key === "Boolean") {
                            defaultValue = defaultOption.value.toLowerCase();
                        } else {
                            defaultValue = defaultOption.value;
                        }

                    }

                }

                return { "Key": rp.name, "Value": defaultValue, "Type": rp.parameterType.key }
            });

            setGenerationRequest({
                reportType: defaultReportType,
                parameters: initalParams
            });

            props.onReportLoad(reportResponse);

        }, function () {
            setLoading(false);
            setInvalidReport(true);
        });

    }

    useEffect(function () {

        if (props.favourites == null) {
            return;
        }

        setFavourited(props.favourites.some(function (f) {
            return f.id.toString() === id;
        }));

    }, [props.favourites, id]);

    function setLocationsByBusinessArea(businessArea) {

        if (businessArea == null || props.locations == null || props.locations.length === 0) {
            return;
        }

        var permissionsForBusinessArea = props.locations.find(function (ba) {
            return ba.id === businessArea;
        });

        setLocations(permissionsForBusinessArea.permissions);

    }

    useEffect(function () {
        setLocationsByBusinessArea(report.locationBusinessAreaId);
    }, [props.locations, report.locationBusinessAreaId]);

    
    function onGenerateReport() {
        setGeneratingReport(true);
        SelfReportingService.generateReport(id, generationRequest).then(function (result) {
            setGeneratingReportInstanceId(result);
        }, function (error) {
            NotificationToaster.show(Intent.DANGER, error);
            setGeneratingReport(false);
        });
    }

    useEffect(function () {
        if (generatingReportInstanceId != null) {
            checkIfReportIsGenerated(generatingReportInstanceId, 1);
        }
    }, [generatingReportInstanceId]);

    function checkIfReportIsGenerated(generationId, checkNumber) {

        var notReadyStatus = 204;
        var quickCheckInstances = 60;
        var quickCheckTimeout = 5000;
        var longCheckTimeout = 30000;

        var checkingTimeout = checkNumber <= quickCheckInstances ? quickCheckTimeout : longCheckTimeout;

        setTimeout(function () {
            generationCancelTokenSource.current = axios.CancelToken.source();
            var throwError = function () {
                NotificationToaster.show(Intent.DANGER, "Could not generate the report. Please try again.");
                setGeneratingReport(false);
                setGeneratingReportInstanceId(null);
            }

            SelfReportingService.checkReportGenerationStatus(id, generationId, generationCancelTokenSource.current.token).then(function (response) {
                if (response.status === notReadyStatus)
                {
                    checkIfReportIsGenerated(generationId, checkNumber + 1);
                }
                else
                {
                    BlobStorageService.downloadFile(response.data.storageName, response.data.containerName, response.data.fileName, response.data.token, response.data.downloadName).then(function () {
                        NotificationToaster.show(
                            Intent.SUCCESS,
                            "Report has been generated successfully, download will now begin"
                        );
                        setGeneratingReport(false);
                        setGeneratingReportInstanceId(null);
                    }, throwError);
                    
                }
            }, throwError);
        }, checkingTimeout);
        
    }

    function onFavouriteToggle() {
        props.onFavouriteClick(report);
    }

    function onParamUpdate(params) {
        setGenerationRequest({
            ...generationRequest,
            parameters: params
        });
    }

    function onFormatUpdate(format) {
        setGenerationRequest({
            ...generationRequest,
            reportType: format
        });
    }

    function onValidationUpdate(valid) {
        setIsValid(valid);
    }

    return (
        <div>
            <ShowHide
                evaluator={invalidReport}
                show={(
                    <div className="row">
                        <NotificationInline
                            allowClose={false}
                            show
                            text="You do not have permission to view this report"
                            intent="danger">
                        </NotificationInline>
                    </div>
                )}
                hide={(
                    <Fragment>
                        <div className="inline-items spacer-bottom-small">
                            <h3 className={classNames({ "bp3-skeleton": loading })}>{report.name}</h3>
                            <Tooltip content={favourited ? "Remove report from favourites" : "Add report to your favourites"}>
                                <Button iconOnly minimal large={false} intent={favourited ? "warning" : ""} icon="star" onClick={onFavouriteToggle} loading={loading} />
                            </Tooltip>
                        </div>
                        <p className={classNames("intro-text", { "bp3-skeleton": loading })}>{report.description}</p>

                        <ReportParameters disabled={generatingReport} locations={locations} restrictDateRange={report.restrictDateRange} restrictNumberOfMonths={report.restrictNumberOfMonths} parameters={report.reportParameters} fileTypes={formatTypes} onParamUpdate={onParamUpdate} onFormatUpdate={onFormatUpdate} onValidationUpdate={onValidationUpdate} values={generationRequest} reportId={id} />

                        <ShowHide
                            evaluator={generatingReportInstanceId != null}
                            show={(
                                <NotificationInline
                                    id="notification-report-generation-inprogress"
                                    show={true}
                                    allowClose={false}
                                    text="Your report is now being generated, if you remain on this page, the report will auto download once ready, otherwise it can be downloaded from the self service home screen within the next 24 hours."
                                    intent="info">
                                </NotificationInline>
                            )}
                            hide={(
                                <ButtonSave icon="export" text="Generate report" onClick={onGenerateReport} disabled={generatingReport} loading={loading} simpleDisabled={!isValid} />
                            )}
                        />

                    </Fragment>
                )}
            />

        </div>
    );
}

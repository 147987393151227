import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import { Checkbox, Collapse, Icon } from "@blueprintjs/core";

import { ShowHide } from "components/layout";

export function FormGroupedCheckboxItems(props) {

    function calculateSelectedChildren(items, count) {

        var selectedItems = 0;

        if (items == null) {
            return count;
        }

        for (var i = 0; i < items.length; i++) {
            var currentItem = items[i];

            if (currentItem.type === "group" && currentItem.items != null && currentItem.items.length > 0) {
                count = calculateSelectedChildren(currentItem.items, count);
            }

            if (currentItem.type === "item" && currentItem.checked && (props.searchTerm.length === 0 || currentItem.appearInSearch)) {
                selectedItems++;
            }

        }

        return count + selectedItems;
    }

    function onItemSelect(itemId, itemDisabled) {
        if (!props.disabled && !itemDisabled) {
            props.onItemSelect(itemId)
        }
    }

    return (
        <div>
            {
                props.items != null && props.items.map(function (item) {

                    var displayForSearch = props.searchTerm.length > 0 && item.appearInSearch;

                    if (item.type === "group") {
                        return (
                            <ShowHide
                                key={`group-${item.id}`}
                                evaluator={props.searchTerm.length === 0 || item.appearInSearch}
                                show={(
                                    <div>
                                        <div className={classNames("grouped-checkbox-row", `checkbox-list-group-${item.id}`, { "bp3-skeleton": props.loading })}>
                                            <h4 onClick={() => { props.onGroupToggle(item.id) }}><Icon className="collapsable-icon-status" icon={item.expanded ? "chevron-down" : "chevron-right"} />{item.name} <span className="grouped-checkbox-select-count">{props.singleSelection ? '' : `(${calculateSelectedChildren(item.items, 0)} selected)`}</span></h4>
                                            <ShowHide
                                                evaluator={props.groupsSelectable}
                                                show={(
                                                    <Checkbox checked={item.checked} indeterminate={item.partialChecked} onChange={() => { props.onGroupSelect(item.id); }} disabled={item.disabled || props.disabled} />
                                                )}
                                            />
                                        </div>
                                        <Collapse isOpen={item.expanded || displayForSearch} className="grouped-checkbox-list">
                                            <FormGroupedCheckboxItems items={item.items} searchTerm={props.searchTerm} groupsSelectable={props.groupsSelectable} onGroupToggle={props.onGroupToggle} onItemSelect={props.onItemSelect} onGroupSelect={props.onGroupSelect} loading={props.loading} filter={props.filter} itemIcon={props.itemIcon} disabled={props.disabled} singleSelection={props.singleSelection} />
                                        </Collapse>
                                    </div>
                                )}
                                
                            />
                            
                        );
                    } else {
                        return (
                            <ShowHide
                                key={`item-${item.id}`}
                                evaluator={(props.searchTerm.length === 0 || item.appearInSearch) && (props.filter == null || props.filter(item))}
                                show={(
                                    <div key={`item-${item.id}`} className={classNames("grouped-checkbox-row", { "bp3-skeleton": props.loading, "checkbox-list-single-click": props.singleSelection })} >
                                        <span onClick={() => { onItemSelect(item.id, item.disabled) }}>
                                            <ShowHide
                                                evaluator={props.itemIcon != null}
                                                show={(
                                                    <Icon className="collapsable-icon-status" icon={props.itemIcon} />
                                                )}
                                            />
                                            <label>{item.name}</label>
                                        </span>
                                        <ShowHide
                                            evaluator={!props.singleSelection}
                                            show={(
                                                <Checkbox checked={item.checked} onChange={() => { onItemSelect(item.id, item.disabled) }} disabled={item.disabled || props.disabled} />
                                            )}
                                        />
                                    </div>
                                )}
                            />
                        );
                    }
                })
            }
        </div>
    );
}

FormGroupedCheckboxItems.defaultProps = {
    searchable: false,
    showTags: false,
    filter: null,
    itemIcon: null,
    disabled: false,
    singleSelection: false
};

FormGroupedCheckboxItems.propTypes = {
    searchable: PropTypes.bool,
    showTags: PropTypes.bool,
    filter: PropTypes.func,
    itemIcon: PropTypes.string,
    disabled: PropTypes.bool,
    singleSelection: PropTypes.bool
};

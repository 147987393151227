import ApiService from 'services/ApiService';

const badRequestCode = 400;

export const AlertService = {
	getUserPreferences() {
		return new Promise((resolve, reject) => {
			ApiService.get('/alert/Preferences').then(
				(result) => {
					resolve(result.data);
				},
				(error) => {
					reject(error);
				});
		});
	},
	saveUserPreferences(disabledAlertIdList) {
		return new Promise((resolve, reject) => {
			ApiService.put('/alert/Preferences', disabledAlertIdList).then(
				(result) => {
					resolve(result.data);
				},
				(error) => {
					reject(error);
				});
		});
	},
	getAlerts(data) {
		return new Promise((resolve, reject) => {
			ApiService.post(`/alert/alerts/Search`, data).then(
				(result) => {
					resolve(result.data)
				},
				(error) => {
					reject(error);
				});
		});
	},
	getAlertTypes(applyPreferences, includeCounts) {
		return new Promise((resolve, reject) => {
			ApiService.get(`/alert/AlertTypes?applyPreferences=${applyPreferences}&includeCounts=${includeCounts}`).then(
				(result) => {
					resolve(result.data)
				},
				(error) => {
					reject(error);
				});
		});
	},
	getAlertFilters(alertTypeId) {
		return new Promise((resolve, reject) => {
			ApiService.get(`/alert/AlertFilters/alertType/${alertTypeId}`).then(
				(result) => {
					resolve(result.data)
				},
				(error) => {
					reject(error);
				});
		});
	},
	getOptions() {
		return new Promise((resolve, reject) => {
			ApiService.get(`/alert/Options/Alerts`).then(
				(result) => {
					resolve(result.data)
				},
				(error) => {
					reject(error);
				});
		});
	},
	mapAlertGroupings(alerts) {
		var mappedAlertPreferenceSections = [...new Set(alerts.map(p => p.sectionTitle))];

		return mappedAlertPreferenceSections.map(function (pref) {
			return {
				sectionName: pref,
				sectionAlerts: alerts.filter(p => p.sectionTitle === pref)
			}
		})
	},
	getEntityBreakdown(employeeIds, alertTypeIds) {
		let queryString = '?';

		for (let employeeId of employeeIds) {
			queryString += `entityIds=${employeeId}&`;
		}

		for (let typeId of alertTypeIds) {
			queryString += `alertTypeIds=${typeId}&`;
		}

		return new Promise((resolve, reject) => {
			ApiService.get(`alert/Alerts/EntityBreakdown${queryString}`).then(
				(result) => {
					resolve(result.data);
				},
				(error) => {
					reject(error);
				});
		});
	},
	saveAlertStatus(alertId, alertStatusId, comment) {
		return new Promise((resolve, reject) => {
			ApiService.patch(`alert/Alerts/${alertId}`, { alertStatusId: alertStatusId, comment: comment }).then(
				(result) => {
					resolve(result.data)
				},
				(error) => {
					reject(error);
				});
		});
	},
	postReportRequest(data) {
		return new Promise((resolve, reject) => {
			ApiService.post(`alert/Reports/Alert/`, data).then(
				(result) => {
					resolve(result.data);
				},
				(error) => {
					if (error.response && error.response.status === badRequestCode) {
						reject(error.response.data);
					} else {
						reject("Unable to download the report, please try again.");
					}
				}
			)
		});
	}
}
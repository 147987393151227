import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Icon } from "@blueprintjs/core";
import './Status.css';
import classNames from 'classnames';

export function TrendStatus(props) {

    const [trendClass, setTrendClass] = useState("");
    const [trendIcon, setTrendIcon] = useState("");
    const [trend, setTrend] = useState("");

    useEffect(() => {

        if (props.trend == null) {
            setTrendClass("");
            setTrendIcon("");
            setTrend("");
            return;
        }

        if (props.trend > 0) {
            setTrendIcon("trending-up");
            setTrendClass("status-trend-up");
        } else if (props.trend < 0) {
            setTrendIcon("trending-down");
            setTrendClass("status-trend-down");
        } else {
            setTrendIcon("layout-linear");
            setTrendClass("status-trend-even");
        }

        //we have to multiply by 10,000 and then divide by 100 to get a percentage with 2 decimal places because JS struggles with floating point numbers
        setTrend(`${Math.round(props.trend * 10000) / 100}%`);

    }, [props.trend])

    return (
        <div className={classNames("status-trend", trendClass, props.className)}>
            <span>{trend}</span> <Icon icon={trendIcon} />
        </div>
    );
}

TrendStatus.defaultProps = {
    trend: null,
    className: ""
};

TrendStatus.propTypes = {
    trend: PropTypes.number,
    className: PropTypes.string
};
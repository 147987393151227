import { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux'

export function useDirtyData(originalValues, currentValues, checkOrder  = false) {

	const [isDirty, setIsDirty] = useState(false);
	const dispatch = useDispatch();

	useEffect(() => {
		setIsDirty(false);
		dispatch({ type: 'RESET_DIRTY_FLAG' })
    }, [originalValues]);

	useEffect(update, [currentValues]);

	function update() {
		var isUpdateDirty = checkIsDirty(originalValues, currentValues, checkOrder )
		setIsDirty(isUpdateDirty);
		dispatch({ type: 'SET_DIRTY_FLAG', payload: isUpdateDirty });
	}

	function checkIsDirty(newObj, oldObj, orderCheck) {

		if (newObj === null && oldObj === null) {
			return false;
		}

		if (newObj == null) {
			return true;
		}

		if (Array.isArray(newObj) && Array.isArray(oldObj) && newObj.length !== oldObj.length) {
			return true;
		}

		//If obj is an array, sort them so all items are in the same order and then perform dirty check for each item
		if (Array.isArray(newObj) && Array.isArray(oldObj))
		{
			const newObjClone = [...newObj];
			const oldObjClone = [...oldObj];

			if (orderCheck === false) {
				newObjClone.sort(sortArrayByProperties);
				oldObjClone.sort(sortArrayByProperties);
			}

			//Loop through the array and check all objects
			for (var i = 0; i < newObjClone.length; i++) {
				var arrCheck = checkIsDirty(newObjClone[i], oldObjClone[i]);
				if (arrCheck) {
					return true;
                }
            }
		}
		else if (typeof oldObj === 'object') {
			for (var property in oldObj) {
				if (oldObj.hasOwnProperty(property)) {
					if (typeof oldObj[property] === 'object' && oldObj[property] != null) {
						var nestedPropertiesDirty = checkIsDirty(newObj[property], oldObj[property]);
						if (nestedPropertiesDirty) {
							return true;
						}
					}
					else if (newObj[property] !== oldObj[property]) {
						return true;
					}
				}
			}
		} else {
			// At this point both oldObj must be a value type, so we can do a straight data comparison
			return oldObj !== newObj;
		}
		
		return false;
	}

	function sortArrayByProperties(firstItem, secondItem) {
		var stringFirstItem = JSON.stringify(firstItem);
		var stringSecondItem = JSON.stringify(secondItem);
		return stringFirstItem.localeCompare(stringSecondItem);
    }

    return isDirty;
}
import React, { useState, useRef, useEffect, Fragment } from 'react';
import { Link } from "react-router-dom";
import { Intent, Position } from '@blueprintjs/core';
import classNames from "classnames";
import axios from 'axios';

import { useDebounce } from 'hooks/useDebounce';

import { Tooltip } from 'components/tooltip';
import { Breadcrumbs } from 'components/navigation';
import { FormLocationSelector, FormTextInput } from "components/form-fields";
import { LinkButton, Button } from 'components/buttons'
import { ListingTable } from "components/listing";
import { ShowHide } from "components/layout";
import { AlertConfirm, Modal, NotificationToaster } from "components/notifications";
import { UserService, CollisionsService, EmployeeService } from 'services';
import { DateToLocal } from "components/dates";
import { HelperFunctions } from 'helpers';
import { PageRestricted } from 'pages/errors/page-restricted';
import { UserAccess, UserHasAccess } from 'components/user-access';
import { Filter } from 'components/filtering';
import { DataDisplayTable } from 'components/data-display';
import { Money } from 'components/formatting/Money';
import { CircleStatus } from 'components/status';

export function CollisionsListing(props) {

    const defaultRecordSize = 25;
    const debounceTimeout = 750;

    const [locationSetupComplete, setLocationSetupComplete] = useState(false);
    const [filterSetupComplete, setFilterSetupComplete] = useState(false);
    const [initalSetupComplete, setInitalSetupComplete] = useState(false);
    const [selectedCollisionId, setSelectedCollisionId] = useState(0);
    const [totalRecords, setTotalRecords] = useState(0);
    const [rawTableData, setRawTableData] = useState([]);
    const [tableRows, setTableRows] = useState([]);
    const [pageNumber, setPageNumber] = useState(1);
    const [pageFilters, setPageFilters] = useState([]);
    const [pageSize, setPageSize] = useState(defaultRecordSize);
    const [searchTerm, setSearchTerm] = useState("");
    const [loadingData, setLoadingData] = useState(true);
    const [noDataMessage, setNoDataMessage] = useState("You have no collisions recorded.");
    const tableHeaders = ["Incident Date", "Employee", "Asset Registration", "Fault Status", "Injury Level", "Damage Level", "Third Party Involved", "Actions"];
    const sortableHeaders = [{ headerName: "Incident Date", sortName: "IncidentDate" }, { headerName: "Employee", sortName: "EmployeeName" }, { headerName: "Asset Registration", sortName: "AssetRegistration" }];
    const [tableSortBy, setTableSortBy] = useState(sortableHeaders[0]);
    const [tableSortDir, setTableSortDir] = useState("D");
    const [showDeleteConfirm, setShowDeleteConfirm] = useState(false);
    const [collisionInfo, setCollisionInfo] = useState([]);
    const [appliedFilters, setAppliedFilters] = useState([]);
    const [showFilters, setShowFilters] = useState(false);
    const [locationList, setLocationList] = useState({ location: [], hierarchy: [] });
    const debouncedSearchTerm = useDebounce(searchTerm, debounceTimeout);
    const inputSearchRef = useRef(null);
    
    const [showLocationsAsHierarachy, setShowLocationsAsHierarachy] = useState(true);
    const [selectedLocations, setSelectedLocations] = useState([]);
    const locationListOnFilterOpen = useRef(selectedLocations);

    const currentPageNumber = useRef();
    const currentPageSize = useRef();
    const currentSearchTerm = useRef();
    const currentTableSortBy = useRef();
    const currentTableSortDir = useRef();
    const currentAppliedFilters = useRef();

    const requiredAction = "Collisions:View";
    const canAccessPage = UserHasAccess(requiredAction);
    const canManageCollisions = UserHasAccess("Collisions:Import");

    useEffect(() => {
        setPageNumber(1);
        setNoDataMessage("No results found, please try a different search term.");
    }, [debouncedSearchTerm]);


    useEffect(() => {
        setupLocations();
        axios.all([EmployeeService.getEmployeeOptions(), CollisionsService.getOptions()]).then(axios.spread(function (employeeOptions, collisionOptions) {

            var mappedDamageLevels = collisionOptions.damageLevels.map(function (o) {
                return { "id": o.name, "name": o.name }
            });
            var mappedInjuryLevels = collisionOptions.injuryLevels.map(function (o) {
                return { "id": o.name, "name": o.name }
            });
            var mappedEmployeeTypes = employeeOptions.employeeTypes.map(function (o) {
                return { "id": o.name, "name": o.name }
            });

            mappedDamageLevels = HelperFunctions.sortAlphabetically(mappedDamageLevels, "name");
            mappedInjuryLevels = HelperFunctions.sortAlphabetically(mappedInjuryLevels, "name");
            mappedEmployeeTypes = HelperFunctions.sortAlphabetically(mappedEmployeeTypes, "name");

            var filterArray = [
                {
                    "displayName": "Employee Type", "name": "EmployeeType", "items": mappedEmployeeTypes
                },
                {
                    "displayName": "Fault Status", "name": "AtFault", "items": [
                        { "id": "Yes", "name": "At Fault" },
                        { "id": "No", "name": "Not at Fault" }
                    ]
                },
                {
                    "displayName": "Third Party Involved", "name": "ThirdPartyInvolved", "items": [
                        { "id": "Yes", "name": "Yes" },
                        { "id": "No", "name": "No" }
                    ]
                },
                {
                    "displayName": "Damage Level", "name": "DamageLevel", "items": mappedDamageLevels
                },
                {
                    "displayName": "Injury Level", "name": "InjuryLevel", "items": mappedInjuryLevels
                }
            ];

            setPageFilters(filterArray);

        }));
    }, [])

    function fetchTableData(forceRefresh) {
        if (canAccessPage && initalSetupComplete) {

            if (currentPageNumber.current === pageNumber &&
                currentPageSize.current === pageSize &&
                currentSearchTerm.current === debouncedSearchTerm &&
                currentTableSortBy.current === tableSortBy &&
                currentTableSortDir.current === tableSortDir &&
                currentAppliedFilters.current === appliedFilters.length &&
                locationListOnFilterOpen.current === selectedLocations &&
                !forceRefresh
            ) {
                //If none of the values have changed, then 2 have been updated at once, so prevent the listing from performing another call.
                return;
            }

            currentPageNumber.current = pageNumber;
            currentPageSize.current = pageSize;
            currentSearchTerm.current = debouncedSearchTerm;
            currentTableSortBy.current = tableSortBy;
            currentTableSortDir.current = tableSortDir;
            currentAppliedFilters.current = appliedFilters.length;

            setLoadingData(true);

            CollisionsService.getAllCollisions(pageNumber, pageSize, debouncedSearchTerm, tableSortBy.sortName, tableSortDir, appliedFilters, selectedLocations).then(function (r) {
                setRawTableData(r.data);
                setTotalRecords(r.totalCount);
                setLoadingData(false);
                setTimeout(function () {
                    if (inputSearchRef.current != null) {
                        inputSearchRef.current.focus();
                    }
                });
            });
        }
    }

    useEffect(() => {
        fetchTableData(false);
    }, [pageSize, pageNumber, tableSortBy, tableSortDir, debouncedSearchTerm, appliedFilters.length, initalSetupComplete]);

    useEffect(() => {
        setInitalSetupComplete(locationSetupComplete && filterSetupComplete);
    }, [locationSetupComplete, filterSetupComplete])

    function displayFriendlyBoolean(value) {
        if (value == null) {
            return "Unknown"
        }

        return value ? "Yes" : "No";
    }

    function displayFriendlyFault(value) {
        if (value == null) {
            return "Unknown"
        }

        return value ? "At fault" : "Not at fault";
    }

    function displayFiendlyValue(value) {
        return value == null ? "Unknown" : value;
    }

    function displayFriendlyLink(value, url, id) {
        return value == null ? <span>Unknown</span> : <a href={`${url}${id}`}>{value}</a>;
    }

    useEffect(() => {
        let returnParams = `&pageNo=${pageNumber}&searchTerm=${searchTerm}`;

        let data = [];

        if (rawTableData != null) {
            data = rawTableData.map((d) => {

                return [
                    <ShowHide
                        evaluator={canManageCollisions}
                        key={`incident-link-${d.collisionId}`}
                        show={(
                            <Link to={`/collisions/${d.collisionId}`}><DateToLocal format="DD/MM/YYYY">{d.incidentDate}</DateToLocal></Link>
                        )}
                        hide={(<DateToLocal format="DD/MM/YYYY" >{d.incidentDate}</DateToLocal>)}
                    />
                    ,
                    <div className="inline-items" key={`employee-status-${d.collisionId}`}>
                        <div className="inline-item">
                            <CircleStatus status={d.employeeStatus} hollow={d.employeeType?.toLowerCase() === "agency"} tooltip={`${d.employeeStatus} ${d.employeeType?.toLowerCase()} employee`} />
                        </div>
                        <div className="inline-item">
                            {d.employeeName}
                        </div>
                    </div>
                    ,
                    displayFiendlyValue(d.assetRegistration),
                    displayFriendlyFault(d.driverAtFault),
                    displayFiendlyValue(d.collisionInjuryLevel),
                    displayFiendlyValue(d.collisionDamageLevel),
                    displayFriendlyBoolean(d.thirdPartyInvolvement),
                    <div className="button-row-small" key={`incident-actions-${d.collisionId}`}>
                        <Tooltip content="View more details">
                            <Button onClick={() => viewCollisionInfo(d)} icon="document-open" iconOnly={true} text="View details" large={false} minimal={true}></Button>
                        </Tooltip>
                        <Tooltip position={Position.TOP} content="Employee Profile">
                            <LinkButton
                                href={`${window.env.VISION_URL}/#/Drivers/RedirectToProfile?employeeId=${d.employeeId}${returnParams}`}
                                icon={"person"}
                                externalLink={true}
                                minimal={true}
                                iconOnly={true}
                                text="Profile"
                                large={false}
                            />
                        </Tooltip>
                        <Tooltip position={Position.TOP} content="Asset Profile">
                            <LinkButton
                                href={`${window.env.VISION_URL}/#/Fleet/RedirectToProfile?assetId=${d.assetId}&tab=details${returnParams}`}
                                icon={"truck"}
                                externalLink={true}
                                minimal={true}
                                iconOnly={true}
                                text="Profile"
                                large={false}
                            />
                        </Tooltip>
                        <ShowHide
                            evaluator={canManageCollisions}
                            show={(
                                <Tooltip content="Delete collision">
                                    <Button onClick={() => deleteCollision(d.collisionId)} icon="trash" iconOnly={true} text="Delete collision" large={false} minimal={true}></Button>
                                </Tooltip>
                            )}>
                        </ShowHide>
                    </div>];

            })
        }

        setTableRows(data);
    }, [rawTableData]);


    function onTableSort(header, direction) {
        setTableSortDir(direction);
        setTableSortBy(header);
    }

    function onSearchChange(item) {
        setSearchTerm(item.target.value);
    }

    function onPagingChange(newPageNumber, newPageSize) {
        setPageNumber(newPageNumber);
        setPageSize(newPageSize);
    }

    function deleteCollision(collisionId) {
        setSelectedCollisionId(collisionId);
        setShowDeleteConfirm(true);
    }

    function onDeleteConfirm() {
        setShowDeleteConfirm(false);

        CollisionsService.deleteCollision(selectedCollisionId).then(() => {
            NotificationToaster.show(Intent.SUCCESS, "Collision has been successfully deleted");
            fetchTableData(true);
            setSelectedCollisionId(0);
        }, function () {
            NotificationToaster.show(Intent.DANGER, "Unable to delete the collision, please try again.");
            setSelectedCollisionId(0);
        });
    }

    function onDeleteCancel() {
        setSelectedCollisionId(0);
        setShowDeleteConfirm(false);
    }

    function onToggleFilter() {
        setShowFilters((prevState) => {
            return !prevState;
        })
    }

    function viewCollisionInfo(collision) {

        var info = [
            {
                header: "Incident Date",
                value: <DateToLocal format="DD/MM/YYYY">{collision.incidentDate}</DateToLocal>,
                key: 'incident-date'
            },
            {
                header: "Employee",
                value: displayFriendlyLink(collision.employeeName, `${window.env.VISION_URL}/#/Drivers/RedirectToProfile?employeeId=`, collision.employeeId),
                key: 'employee'
            },
            {
                header: "Employee Location",
                value: displayFiendlyValue(collision.employeeLocation),
                key: 'employee-location'
            },
            {
                header: "Asset Registration",
                value: displayFriendlyLink(collision.assetRegistration, `${window.env.VISION_URL}/#/Fleet/RedirectToProfile?tab=detailsTab&assetId=`, collision.assetId),
                key: 'asset'
            },
            {
                header: "Asset Location",
                value: displayFiendlyValue(collision.assetLocation),
                key: 'asset-location'
            },
            {
                header: "Fault Status",
                value: displayFriendlyFault(collision.driverAtFault),
                key: 'fault'
            },
            {
                header: "Injury Level",
                value: displayFiendlyValue(collision.collisionInjuryLevel),
                key: 'injury'
            },
            {
                header: "Damage Level",
                value: displayFiendlyValue(collision.collisionDamageLevel),
                key: 'damage'
            },
            {
                header: "Third Party Involved",
                value: displayFriendlyBoolean(collision.thirdPartyInvolvement),
                key: 'third-party'
            },
            {
                header: "Area of Damage",
                value: displayFiendlyValue(collision.areaOfDamage),
                key: 'damage-area'
            },
            {
                header: "Repair Cost",
                value: <Money>{collision.repairCost}</Money>,
                key: 'repair-cost'
            },
            {
                header: "Total Cost",
                value: <Money>{collision.totalCost}</Money>,
                key: 'total-cost'
            },
        ];

        setCollisionInfo(info);
    }

    function onInfoDismiss() {
        setCollisionInfo([]);
    }

    function onFilterChange(listingFilters) {
        setPageNumber(1);
        setNoDataMessage("No results found, please try a different filter.");
        setAppliedFilters(listingFilters);
        if (listingFilters.length > appliedFilters.length) {
            setShowFilters(true);
        }
        setFilterSetupComplete(true);
    }

    function setupLocations() {
        UserService.getLoggedInUserLocations(1, true, true).then(function (locations) {
            setLocationList(locations);
            setLocationSetupComplete(true);
            setSelectedLocations(locations.location[0].items.map((i) => i.id));
        }, function (error) {
            NotificationToaster.show(Intent.DANGER, `Locations failed ${error}`);
        });
    }

    function onLocationListOpened() {
        locationListOnFilterOpen.current = selectedLocations;
    }

    function onLocationListClose() {
        if (locationListOnFilterOpen.current.toString() !== selectedLocations.toString()) {
            locationListOnFilterOpen.current = selectedLocations;
            fetchTableData(true);
        }
    }

    function setUseHierarchy() {
        setShowLocationsAsHierarachy(prev => !prev);
    }

    return (
        <UserAccess perform={requiredAction}
            yes={() => (
                <div className="row">

                    <Breadcrumbs items={props.breadcrumbs} />

                    <h1>Collisions</h1>

                    <div className="button-row button-row-stacked-mobile spacer-bottom">
                        <div>
                            <ShowHide
                                evaluator={canManageCollisions}
                                show={(
                                    <LinkButton intent="primary" text="Add new collision" href="/collisions/create" id="new-collision" />
                                )}
                            />
                        </div>
                    </div>

                    <ShowHide
                        evaluator={tableRows.length === 0 && !loadingData && pageFilters.length === 0 && currentSearchTerm.current === ""}
                        hide={(
                            <Fragment>
                                <div className={classNames("pull-left", { "spacer-bottom": !showFilters })}>
                                    <div className="inline-items">
                                        <FormTextInput
                                            maxLength={50}
                                            inputRef={inputSearchRef}
                                            placeholder="Employee name or registration "
                                            onChange={onSearchChange}
                                            value={searchTerm}
                                            large
                                            disabled={loadingData}
                                            icon="search"
                                            id="collision-search-field" />

                                        <FormLocationSelector
                                            businessArea={"Tacho"}
                                            loading={loadingData}
                                            selectedLocations={selectedLocations}
                                            setSelectedLocations={setSelectedLocations}
                                            useHierarchy={showLocationsAsHierarachy}
                                            setUseHierarchy={setUseHierarchy}
                                            useLocationDefaults={false}
                                            useCustomLocations
                                            customLocations={locationList}
                                            locationDefaultsToTrue
                                            onLocationListOpened={onLocationListOpened}
                                            onLocationListClose={onLocationListClose}
                                        />

                                        <Tooltip content="Filter" position="right">
                                            <Button icon="filter" onClick={onToggleFilter} className={classNames({ "active": appliedFilters.length > 0 })} />
                                        </Tooltip>
                                            
                                    </div>
                                </div>
                                <Filter filterName="collisions" visible={showFilters} filters={pageFilters} onUpdate={onFilterChange} />
                            </Fragment>
                        )}
                    />

                    <ListingTable
                        id="listing-table-collisions"
                        headers={tableHeaders}
                        data={tableRows}
                        totalRecordCount={totalRecords}
                        onPagingChange={onPagingChange}
                        loadingData={loadingData}
                        noDataMessage={noDataMessage}
                        sortable
                        sortableHeaders={sortableHeaders}
                        sortedBy={tableSortBy}
                        sortedDir={tableSortDir}
                        onSort={onTableSort}
                    />

                    <AlertConfirm
                        title="Please confirm"
                        isOpen={showDeleteConfirm}
                        onConfirm={onDeleteConfirm}
                        onCancel={onDeleteCancel}
                    >
                        <p>Are you sure you want to delete this collision?</p>
                    </AlertConfirm>

                    <Modal
                        isOpen={collisionInfo.length > 0}
                        title={"Collision Details"}
                        onClose={onInfoDismiss}
                    >
                        <DataDisplayTable
                            data={collisionInfo}
                            displayInRows={true}
                            loading={loadingData}
                        />
                    </Modal>


                </div>
            )}
            no={() => (
                <PageRestricted />
            )}
        />
    );

}
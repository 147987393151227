import ApiService from 'services/ApiService';
import ApiRequestService from "services/ApiRequestService";

const badRequestCode = 400;

export const JobService = {
	getReport(reportId) {
		return new Promise((resolve, reject) => {
			ApiService.get(`/job/Reports?reportId=${reportId}`).then(
				(result) => {
					resolve(result.data);
				},
				(error) => {
					reject(error);
				});
		});
	},
	signOffEmployeeAssessment(reportId) {
		const employeeAssessmentSignOff = {
			reportId: reportId
		};

		return new Promise((resolve, reject) => {
			//This no longer exists and will have to be rewritten in the EmployeeMS if it is ever required
			ApiService.post(`/job/employeeAssessments/signOff`, employeeAssessmentSignOff).then(
				(result) => {
					resolve(result);
				},
				(error) => {
					reject(error);
				});
		});
	},
	getJobFile(documentStorageId) {
		return new Promise((resolve, reject) => {
			ApiService.get(`/job/JobFiles/${documentStorageId}`, { responseType: 'blob' }).then(
				(result) => {
					resolve(result.data);
				},
				(error) => {
					reject(error);
				});
		});
	},
	getJobFileToken(documentStorageId) {
		return new Promise((resolve, reject) => {
			ApiService.get(`/job/JobFiles/${documentStorageId}/token`).then(
				(result) => {
					resolve(result.data);
				},
				(error) => {
					reject(error);
				});
		});
	},
	createPrevisit(previsit) {
		return new Promise((resolve, reject) => {
			ApiService.post(`/job/OcrPrevisit`, mapPrevist(previsit)).then(
				(result) => {
					resolve(result.data);
				},
				(error) => {
					if (error.response && error.response.status === badRequestCode) {
						reject(error.response.data);
					} else {
						reject("Unable to create compliance review previsit, please try again.");
					}
				});
		});
	},
	savePrevisit(previsit) {
		return new Promise((resolve, reject) => {
			ApiService.put(`/job/OcrPrevisit`, mapPrevist(previsit)).then(
				(result) => {
					resolve(result.data);
				},
				(error) => {
					if (error.response && error.response.status === badRequestCode) {
						reject(error.response.data);
					} else {
						reject("Unable to save compliance review previsit, please try again.");
					}
				});
		});
	},
	getOcrPrevisit(id) {
		return new Promise((resolve, reject) => {
			ApiService.get(`/job/OcrPrevisit/${id}`).then(
				(result) => {
					resolve(result.data);
				},
				(error) => {
					reject(error.response);
				});
		});
	},
	getOcrSectionsData(jobId) {
		return new Promise((resolve, reject) => {
			ApiService.get(`/job/OcrSection/${jobId}`).then(
				(result) => {
					resolve(result.data);
				},
				(error) => {
					reject(error);
				});
		});
	},
	saveOcrSection(jobId, layouts) {
		return new Promise((resolve, reject) => {
			ApiService.put(`/job/OcrSection/${jobId}`, layouts).then(
				(result) => {
					resolve(result.data);
				},
				(error) => {
					reject(error);
				});
		});
	},
	getOcrSection(jobId, sectionId) {
		return new Promise((resolve, reject) => {
			ApiService.get(`/job/OcrSection/?jobId=${jobId}&sectionId=${sectionId}`).then(
				(result) => {
					if (result.data?.sections?.length > 0) {
						const section = {
							...result.data.sections[0],
							layouts: result.data.layouts
						};

						resolve(section);
					} else {
						reject();
					}
				},
				(error) => {
					reject(error);
				});
		});
	},
	getOcrSectionsForJob(jobId) {
		return new Promise((resolve, reject) => {
			ApiService.get(`/job/OcrSection/?jobId=${jobId}`).then(
				(result) => {
					const sections = result.data.sections;

					for (const section of sections) {
						section.layouts = result.data
							.layouts
							.filter(x => x.sectionId === section.sectionId);
					}

					resolve(sections);
				},
				(error) => {
					reject(error);
				});
		});
	},
	completePrevisit(jobId) {
		return new Promise((resolve, reject) => {
			const ocrPrevisitCompletion = {
				jobId: jobId
			};

			ApiService.post(`/job/OcrPrevisit/complete`, ocrPrevisitCompletion).then(
				(result) => {
					resolve(result.data);
				},
				(error) => {
					if (error.response && error.response.status === badRequestCode) {
						reject(error.response.data);
					} else {
						reject("Unable to complete compliance review previsit, please try again.");
					}
				});
		});
	},
	failAppointment(jobId, failedAppointmentReason) {
		return new Promise((resolve, reject) => {
			ApiService.patch(`/job/jobs/${jobId}/failAppointment`, failedAppointmentReason).then(
				(result) => {
					resolve(result.data);
				},
				(error) => {
					if (error.response && error.response.status === badRequestCode) {
						reject(error.response.data);
					} else {
						reject("Unable to fail appointment, please try again.");
					}
				});
		});
	},
	generateOcrReport(jobId) {
		return new Promise((resolve, reject) => {
			ApiService.post(`/job/OcrReport/${jobId}/Generate`).then(
				(result) => {
					resolve(result.data);
				},
				(error) => {
					if (error.response && error.response.status === badRequestCode) {
						reject(error.response.data);
					} else {
						reject("Unable to download the report, please try again.");
					}
				});
		});
	},
	getOcrJobs(data) {
		return new Promise((resolve, reject) => {
			ApiService.post(`/job/OcrJobs/Search`, data).then(
				(result) => {
					resolve(result.data)
				},
				(error) => {
					reject(error);
				});
		});
	},
	getJob(jobId) {
		return new Promise((resolve, reject) => {
			ApiService.get(`/job/jobs/${jobId}`)
				.then((response) => resolve(response.data))
				.catch((error) => reject(error));
		})
	},
	saveJobDetails(jobDetails) {
		return new Promise((resolve, reject) => {
			ApiService.patch(`/job/jobs/${jobDetails.jobId}/jobInternalAdmin`, jobDetails).then(
				(result) => {
					resolve(result.data);
				},
				(error) => {
					reject(error);
				});
		});
	},
	closeJob(jobId, closeJob) {
		return new Promise((resolve, reject) => {
			ApiService.patch(`/job/jobs/${jobId}/complete`, closeJob).then(
				(result) => {
					resolve(result.data);
				},
				(error) => {
					reject(error);
				});
		});
	},	
	getJobFiles(jobId) {
		return new Promise((resolve, reject) => {
			ApiService.get(`/job/jobFiles/job/${jobId}`)
				.then((response) => resolve(response.data))
				.catch((error) => reject(error));
		});
	},
	deleteJobFile(jobId, storageId) {
		return new Promise((resolve, reject) => {
			ApiService.delete(`/job/jobFiles/job/${jobId}`, { data: [storageId] })
				.then((response) => resolve(response.data))
				.catch((error) => reject(error));
		});
	},
	uploadJobFile(jobId, jobFile) {
		const config = {
			headers: {
				'content-Type': 'multipart/form-data'
			}
		};

		const formData = new FormData();
		formData.append('file', jobFile.file)

		formData.append('description', jobFile.description);

		return new Promise((resolve, reject) => {
			ApiService.post(`/job/jobFiles/${jobId}/Job`, formData, config)
				.then(function (response) {
					resolve(response);
				})
				.catch(function (error) {
					reject(error);
				});
		});
	},	
	getCompletedVisit(jobId) {
		return new Promise((resolve, reject) => {
			ApiService.get(`/job/jobs/${jobId}/VisitCompletion`)
				.then((response) => resolve(response.data))
				.catch((error) => reject(error));
		});
	},
	getJobs(data) {
		return new Promise((resolve, reject) => {
			ApiService.post(`/job/Jobs/Search`, data).then(
				(result) => {
					resolve(result.data)
				},
				(error) => {
					reject(error);
				});
		});
	},
	getCompletedJobs(data) {
		return new Promise((resolve, reject) => {
			ApiService.post(`/job/Jobs/CompletedJob/Search`, data).then(
				(result) => {
					resolve(result.data)
				},
				(error) => {
					reject(error);
				});
		});
	},
	getAmendmentJobs(data) {
		return new Promise((resolve, reject) => {
			ApiService.post(`/job/JobsSchedule/Search`, data).then(
				(result) => {
					resolve(result.data)
				},
				(error) => {
					reject(error);
				});
		});
	},
	getJobsByContracts(data) {
		return new Promise((resolve, reject) => {
			ApiService.post(`/job/Jobs/SearchByContracts`, data).then(
				(result) => {
					resolve(result.data)
				},
				(error) => {
					reject(error);
				});
		});
	},
	acknowledgeJob(jobId, acknowledgedOnBehalfOf) {
		return new Promise((resolve, reject) => {
			ApiService.patch(`/job/JobsSchedule/${jobId}/AcknowledgeJob`, acknowledgedOnBehalfOf).then(
				(result) => {
					resolve(result.data);
				},
				(error) => {
					if (error.response && error.response.status === badRequestCode) {
						reject(error.response.data);
					} else {
						reject("Unable to acknowledge the job, please try again.");
					}
				});
		});
	},
	amendJob(jobId, amendJobRequest) {
		return new Promise((resolve, reject) => {
			ApiService.patch(`/job/JobsSchedule/${jobId}/AmendJob`, amendJobRequest).then(
				(result) => {
					resolve(result.data);
				},
				(error) => {
					if (error.response && error.response.status === badRequestCode) {
						reject(error.response.data);
					} else {
						reject("Unable to acknowledge the job, please try again.");
					}
				});
		});
	},
	amendmentUpdate(jobId, amendmentUpdateRequest) {
		return new Promise((resolve, reject) => {
			ApiService.patch(`/job/JobsSchedule/${jobId}/AmendmentUpdate`, amendmentUpdateRequest).then(
				(result) => {
					resolve(result.data);
				},
				(error) => {
					if (error.response && error.response.status === badRequestCode) {
						reject(error.response.data);
					} else {
						reject("Unable to update the job, please try again.");
					}
				});
		});
	},	
	getLocationJobCount(locationId) {
		return new Promise((resolve, reject) => {
			ApiService.get(`/job/jobs/${locationId}/LocationJobCount`).then(
				(result) => {
					resolve(result.data);
				},
				(error) => {
					reject(error.response);
				});
		});
	},
	bulkMoveJobsForLocation(settings) {
		return new Promise((resolve, reject) => {
			ApiService.put(`/job/BulkMove/Location`, settings)
				.then(function (response) {
					resolve(response);
				})
				.catch(function (error) {
					reject(error);
				});
		});
	},
	getBulkMoveChangeLogForLocation(locationId, pageSize, pageNumber, startDate, endDate) {
		return ApiRequestService.get('job', `BulkMove/Location/Changelog/${locationId}?resultCount=${pageSize}&pageNumber=${pageNumber}&startDate=${startDate}&endDate=${endDate}`);
	},
	internalSearchJobs(pageSize, pageNumber, searchTerm) {
		return new Promise((resolve, reject) => {
			ApiService.get(`/job/Search?requestCount=${pageSize}&pageNumber=${pageNumber}&searchTerm=${searchTerm}`)
				.then(function (response) {
					resolve(response.data);
				})
				.catch(function () {
					reject("Unable to search jobs. Please try again.");
				});
		});
	},
	getChangelog(jobId, pageSize, pageNumber, startDate, endDate) {
		return new Promise((resolve, reject) => {
			ApiService.get(`/job/changelog/${jobId}?resultCount=${pageSize}&pageNumber=${pageNumber}&startDate=${startDate}&endDate=${endDate}`).then(
				(result) => {
					resolve(result.data);
				},
				(error) => {
					if (error.response && error.response.status === badRequestCode && error.response.data) {
						reject(error.response.data);
					} else {
						reject("Unable to get the job history, please try again.");
					}
				});
		});
	},
	getJobAssigneeNames(data) {
		return new Promise((resolve, reject) => {
			ApiService.post(`/job/Jobs/AssigneeNames`, data).then(
				(result) => {
					resolve(result.data)
				},
				(error) => {
					reject(error);
				});
		});
	}
}

function mapPrevist(previsit) {
	return JSON.parse(JSON.stringify(previsit));
}

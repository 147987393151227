import React, { useState, useEffect } from 'react';
import { Intent } from '@blueprintjs/core';

import { useDirtyData } from 'hooks/useDirtyData';
import { HelperFunctions } from 'helpers';
import { NotificationToaster, AlertUnsavedData } from "components/notifications";
import { ButtonSave } from "components/buttons";

import { UserService } from "services";
import { UserLocationSettings } from 'pages/users/edit/shared';

export function UserLocations(props) {

    const [permissions, setPermissions] = useState([]);
    const [loading, setLoading] = useState(true);
    const [saving, setSaving] = useState(false);
    const [originalValues, setOriginalValues] = useState([]);
    const isDataDirty = useDirtyData(originalValues, permissions);

    useEffect(function () {
        UserService.getLocationPermissions(props.userId).then(function (permissionsResponse) {
            setPermissions(permissionsResponse);

            var originalPermissions = HelperFunctions.deepClone(permissionsResponse);
            setOriginalValues(originalPermissions);

            setLoading(false);
        });
    }, []);

    function onPermissionsUpdate(newPermissions) {
        setPermissions(newPermissions);
    }

    function onSave() {
        setSaving(true);
        UserService.updateLocationPermissions(props.userId, permissions).then(function () {
            NotificationToaster.show(Intent.SUCCESS, "User's permissions updated successfully.");
            setOriginalValues(HelperFunctions.deepClone(permissions));
            setSaving(false);
        }, function (error) {
            setSaving(false);
            NotificationToaster.show(Intent.DANGER, error);
        });
    }

    return (
        <div className="row">
            <h2>User Locations</h2>
            
            <UserLocationSettings permissions={permissions} loading={loading} saving={saving} onPermissionsUpdate={onPermissionsUpdate} />

            <AlertUnsavedData
                isDirty={isDataDirty}>
            </AlertUnsavedData>

            <ButtonSave text="Save" intent="primary" onClick={onSave} saving={saving} disabled={saving} loading={loading} />
            
        </div>
    );
}
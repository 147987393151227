import React, { useState, useEffect, Fragment } from 'react';

import { Intent, Icon } from '@blueprintjs/core';
import { FormHelperText, FormSuggest, FormCheckboxList } from "components/form-fields";
import { Button } from "components/buttons";
import { NotificationToaster, AlertConfirm, NotificationInline, AlertUnsavedData } from "components/notifications";
import { ShowHide } from 'components/layout';
import { Wizard } from "components/wizard";
import classNames from 'classnames';
import PropTypes from 'prop-types';

export function MigrationWizard(props) {
	const [showTransferConfirm, setShowTransferConfirm] = useState(false);
	const [wizardStep, setWizardStep] = useState(0);
	const [wizardValid, setWizardValid] = useState(false);
	const [wizardFinished, setWizardFinished] = useState(false);
	const [transferSuccessful, setTransferSuccessful] = useState(false);
	const [loadingAffectedEntityCount, setLoadingAffectedEntityCount] = useState(false);
	const [totalCountZero, setTotalCountZero] = useState(false);
	const [confirmationMessageList, setConfirmationMessageList] = useState([]);

	const [wizardSteps, setWizardSteps] = useState([]);
	const [selectedEntities, setSelectedEntities] = useState([]);

	useEffect(setAllWizardSteps, [selectedEntities, props.selectedTargetEntity, props.targetEntityTitle, props.sourceEntityName, props.loading, props.targetEntityList, props.entitySelectionWarning]);

	useEffect(setIfWizardValid, [wizardStep, wizardSteps]);

	useEffect(() => {
		const newSelectedEntities = props.entities
			.filter(x => x.checked);

		setSelectedEntities([...newSelectedEntities]);
	}, [props.entities]);

	function setAllWizardSteps() {
		const defaultWizardSteps = [
			{
				key: 'TARGET-SELECTOR',
				valid: !!props.selectedTargetEntity?.id,
				step: <div>
					<h2>Select the target {props.targetEntityTitle.toLowerCase()}</h2>
					<FormSuggest
						loading={props.loading}
						items={props.targetEntityList}
						selectedValue={props.selectedTargetEntity?.id}
						onItemSelect={onTargetEntitySelect}
						placeholder={`Select ${props.targetEntityTitle.toLowerCase()} to transfer to`}
						headingText={`Select the ${props.targetEntityTitle.toLowerCase()} to which you want to migrate to:`}
					/>
				</div>
			},
			{
				key: 'ENTITY-SELECTOR',
				valid: selectedEntities.length > 0,
				step: <div>
					<h2>Which entities would you like to migrate?</h2>
					<h4>From {props.targetEntityTitle}</h4>
					<FormHelperText>{props.sourceEntityName}</FormHelperText>
					<h4>To {props.targetEntityTitle}:</h4>
					<FormHelperText>{props.selectedTargetEntity != null ? props.selectedTargetEntity.name : ""}</FormHelperText>

					<FormCheckboxList
						id="checklist-services-to-migrate"
						items={props.entities}
						onChange={handleCheckboxChange}
						headingText="Entities to migrate:"
						loading={props.loading}
						disabled={props.saving}
					>
					</FormCheckboxList>
					<NotificationInline
						id="notification-settings-disabled"
						loading={props.loading}
						show={!!props.entitySelectionWarning || props.entitySelectionWarnings.length > 0}
						text={props.entitySelectionWarning}
						messages={props.entitySelectionWarnings}
						intent="warning"
						allowClose={false}>
					</NotificationInline>
				</div>
			}
		];

		const additionalWizardSteps = selectedEntities
			.filter(x => x.wizardSteps)
			.map(x => x.wizardSteps)
			.flat();

		const allSteps = [...defaultWizardSteps.concat([...additionalWizardSteps])];

		setWizardSteps(allSteps);
	}

	function setIfWizardValid() {
		const valid = !!wizardSteps[wizardStep]?.valid;
		setWizardValid(valid);
	}

	function onTargetEntitySelect(item) {
		props.onTargetEntitySelect(item);
	}

	function resetComponent() {
		setWizardStep(0);
		setWizardValid(false);
		setWizardFinished(false);
		setTransferSuccessful(false);

		props.onReset();
	}

	function handleCheckboxChange(event, id) {
		props.onEntitySelected(event.target.checked, id);
	}

	function onWizardChange(step) {
		setWizardStep(step);
	}

	function onTransferEntities() {
		setShowTransferConfirm(true);		
		setLoadingAffectedEntityCount(true);

		props.getTransferConfirmationList()
			.then((confirmationList) => {
				setConfirmationMessageList(confirmationList);
				var total = getTotalConfirmationCount(confirmationList);
				setTotalCountZero(total === 0);
				setLoadingAffectedEntityCount(false);
			}, () => {
				NotificationToaster.show(Intent.DANGER, "Could not load the confirmation message. Please try again.");
			});
	}

	function getTotalConfirmationCount(confirmationList) {
		var total = 0;
		confirmationList.forEach(confirmation => total = total + confirmation.props.children[0]);
		return total;
    }
	function onTransferEntitiesConfirm() {
		setShowTransferConfirm(false);

		props.onTransferConfirm().then(() => {
			setWizardFinished(true);
			setTransferSuccessful(true);

			props.onReset();
		}, () => {
			setWizardFinished(true);
			setTransferSuccessful(false);

			props.onReset();
		});
	}

	function onTransferEntitiesCancel() {
		setShowTransferConfirm(false);
		props.onTransferCancel();
	}

	return (
		<div className="row">
			<ShowHide
				evaluator={!wizardFinished}
				show={(
					<Fragment>
						<Wizard loading={props.loading} onStepChange={onWizardChange} onFinish={onTransferEntities} canProceed={wizardValid} disabled={props.saving}>
							{wizardSteps.map(x => <Fragment key={x.key}>{x.step}</Fragment>)}
						</Wizard>
					</Fragment>
				)}
				hide={(
					<div>
						<ShowHide
							evaluator={transferSuccessful}
							show={(
								<Fragment>
									<div className="spacer-bottom" id="create-success-icon">
										<Icon icon="tick-circle" iconSize={40} intent={Intent.SUCCESS} />
									</div>
									<h2 id="create-success-name">The migration is being processed</h2>
									<ShowHide
										evaluator={selectedEntities.some(x => x.successMessage)}
										show={(<div className="spacer-bottom">
											<p>Please note that:</p>
											<ul>
												{selectedEntities.filter(x => x.successMessage).map(x => <li key={x.key}>{x.successMessage}</li>)}
											</ul>
										</div>)}
									/>
								</Fragment>
							)}
							hide={(
								<Fragment>
									<div className="spacer-bottom" id="create-error-icon">
										<Icon icon="error" iconSize={40} intent={Intent.DANGER} />
									</div>
									<h2 id="create-error-name">Some or all of the transfers were not completed</h2>
									<div className="spacer-bottom">
										<p>Please manually investigate to see which entities have been transferred and then run the wizard again.</p>
									</div>
								</Fragment>
							)}
						/>

						<div className="button-row" id="migration-complete-buttons">
							<Button intent="primary" text="Restart migration" id="restart-migration" onClick={resetComponent} />
						</div>

					</div>
				)}
			/>

			<AlertConfirm
				title="Please confirm"
				isOpen={showTransferConfirm}
				onConfirm={onTransferEntitiesConfirm}
				onCancel={onTransferEntitiesCancel}
				disabled={loadingAffectedEntityCount || totalCountZero}
			>
				<div className={classNames({ 'bp3-skeleton': loadingAffectedEntityCount })}>
					<p>Are you sure you want to transfer these entities? This operation will transfer:</p>
					<ul>
						{confirmationMessageList.map(x => <li key={x}>{x}</li>)}
					</ul>
					<strong>Once transferred this cannot be undone.</strong>
				</div>
			</AlertConfirm>
			<AlertUnsavedData
				isDirty={props.isWizardDirty}>
			</AlertUnsavedData>

		</div>
	);
}

MigrationWizard.defaultProps = {
	targetEntityTitle: 'Entity',
	loading: false,
	saving: false,
	selectedTargetEntity: {},
	entitySelectionWarning: '',
	entitySelectionWarnings: []
};

MigrationWizard.propTypes = {
	entities: PropTypes.array.isRequired,
	onTargetEntitySelect: PropTypes.func.isRequired,
	targetEntityList: PropTypes.array.isRequired,
	onReset: PropTypes.func.isRequired,
	onEntitySelected: PropTypes.func.isRequired,
	getTransferConfirmationList: PropTypes.func.isRequired,
	onTransferConfirm: PropTypes.func.isRequired,
	onTransferCancel: PropTypes.func.isRequired,
	sourceEntityName: PropTypes.string.isRequired,
	isWizardDirty: PropTypes.bool.isRequired,
	targetEntityTitle: PropTypes.string,
	loading: PropTypes.bool,
	saving: PropTypes.bool,
	selectedTargetEntity: PropTypes.object,
	entitySelectionWarning: PropTypes.string,
	entitySelectionWarnings: PropTypes.array
};
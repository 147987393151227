import PropTypes from 'prop-types';
import React from 'react';

import './DataDisplay.css';

export function DataDisplayImageTable(props) {
    return (
        <div className="data-display-table-columns">
            {props.imageSources && props.imageSources.map(source => {
                return (
                    <div className="data-display-table-field data-display-table-image-field" key={source}>
                        <div className="data-display-table-image-container">
                            <img src={source}></img>
                        </div>
                    </div>
                );
            })}
        </div>
    );
}

DataDisplayImageTable.propTypes = {
    imageSources: PropTypes.array.isRequired
};
import { NonIdealState } from '@blueprintjs/core';
import { DateToLocal } from 'components/dates';
import { ShowHide } from 'components/layout';
import { Table } from 'components/listing';
import { StatusBlock } from 'components/status';
import PropTypes from 'prop-types';
import React, { Fragment, useState, useEffect } from 'react';

export function EmployeeLicenceCheckingEndorsements(props) {
    const headerData = {
        headers: [
            {
                key: 'STATUS',
                value: 'Status'
            },
            {
                key: 'CODE', 
                value: 'Code'
            },
            {
                key: 'OFFENCE',
                value: 'Offence date'
            },
            {
                key: 'CONVICTION',
                value: 'Conviction date'
            },
            {
                key: 'INACTIVE',
                value: 'DVLA inactive date'
            },
            {
                key: 'POINTS',
                value: 'Number of points'
            },
            {
                key: 'DISQUALIFIED',
                value: 'Resulted in disqualification'
            }
        ]
    };
    const [data, setData] = useState([]);

    useEffect(formatData, [props.licenceCheck]);

    function formatData() {
        let formattedData = [];

        if (props?.licenceCheck?.endorsements) {
            formattedData = props.licenceCheck.endorsements
                .map((x, index) => {
                    return {
                        cells: [
                            <StatusBlock large={false} bold={false} status={getStatusForEndorsement(x)}>{getStatusForEndorsement(x)}</StatusBlock>,
                            x.code,
                            <DateToLocal format='DD/MM/YYYY'>{x.offenceDate}</DateToLocal>,
                            <DateToLocal format='DD/MM/YYYY'>{x.convictionDate}</DateToLocal>,
                            <DateToLocal format='DD/MM/YYYY'>{x.dvlaInactiveDate}</DateToLocal>,
                            x.noOfPoints,
                            x.isDisqual ? <Fragment>{'Yes until '}<DateToLocal format='DD/MM/YYYY'>{x.disqualEndDate}</DateToLocal></Fragment> : 'No'
                        ],
                        key: index
                    };
                });
        }

        setData(formattedData);
    }

    function getStatusForEndorsement(endorsement) {
        if (endorsement.dvlaInactiveDate) {
            const formattedInactiveDate = new Date(endorsement.dvlaInactiveDate);
            const today = new Date();

            return formattedInactiveDate > today ? 'Active' : 'Inactive';
        }

        return 'Active';
    }

    return <div className='spacer-top-small'>
        <ShowHide
            evaluator={data.length > 0}
            show={
                <Table
                    headerData={headerData}
                    data={data}
                    loadingData={false}
                    fitToContent={false}
                />
            }
            hide={
                <NonIdealState
                    description={"No valid endorsements exist for this employee in Licence Bureau."}
                />
            }
        />
    </div>;
}

EmployeeLicenceCheckingEndorsements.defaultProps = {
    licenceCheck: null
};

EmployeeLicenceCheckingEndorsements.propTypes = {
    licenceCheck: PropTypes.object
};
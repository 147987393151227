import React, { Fragment, useState, useEffect } from 'react';
import { Intent } from '@blueprintjs/core'
import { FormHelperText, FormTextInput } from "components/form-fields";
import { LoginLayout } from 'pages/layouts';
import { ShowHide } from 'components/layout';
import { ButtonSave } from 'components/buttons';
import { useValidation } from "hooks/useValidation";
import { UserService } from 'services';
import { NotificationToaster } from "components/notifications";
import { Link } from 'react-router-dom';


export function ForgotPasswordRequest() {

    const [sent, setSent] = useState(false);
    const [saving, setSaving] = useState(false);
    const [email, setEmail] = useState('');
 
    const [isValid, errors, validate] = useValidation();

    const rules = [
        { fieldName: "email", required: true, type: "email" }
    ];

    let showReturnLink = document.referrer !== "" && document.referrer.startsWith(window.env.SSOINSTANCEDOMAIN);

    useEffect(validateForm, [email]);

    function validateForm() {
        validate(rules, email);
    }

    function onEmailChange(event) {
        setEmail(event.target.value);
    }

    function onGoBack() {
        window.history.back();
    }

    function sendRequest() {
        setSaving(true);

        if (isValid)
        {
            UserService.requestReset(email).then(function () {
                setSaving(false);
                setSent(true);
            }, function (error) {
                setSaving(false);
                const badRequestCode = 400;
                    if (error.response && error.response.status === badRequestCode) {
                    NotificationToaster.show(Intent.WARNING, "The email address requested could not be found.");
                } else {
                    NotificationToaster.show(Intent.DANGER, "Could not send a reset email, please try again.");
                }
            });
        }
        else
        {
            setSaving(false);
        }

    }

    return (
        <LoginLayout>

            <ShowHide
                evaluator={!sent}
                show={(
                    <Fragment>
                        <h2>Forgot your Password?</h2>

                        <FormHelperText>
                            <p>Enter the email address associated with your Vision account and we'll send you a link to reset your password.</p>
                        </FormHelperText>

                        <FormTextInput
                            id="reset-email"
                            value={email}
                            onChange={onEmailChange}
                            dangerHelperText={errors.password}
                        />

                        <ButtonSave
                            onClick={sendRequest}
                            disabled={saving}
                            text="Send password reset email"
                            icon={null}
                            simpleDisabled={!isValid}
                        ></ButtonSave>	

                    </Fragment>
                )}
                hide={(
                    <Fragment>
                        <h2>Please check your inbox</h2>

                        <FormHelperText>
                            <p>Please click on the link in the email we have just sent you to reset your password. If an email does not arrive in a few minutes, please check your junk folder.</p>
                        </FormHelperText>
                    </Fragment>
                )}

            />

            <ShowHide
                evaluator={showReturnLink}
                show={(
                    <div className="links">
                        <Link to={'#'} onClick={onGoBack}>Return to login</Link>
                    </div>
                )}
            />

            
            
        </LoginLayout>
    );

}
import { FormReadonly } from 'components/form-fields';
import PropTypes from 'prop-types';
import React from 'react';

export function OcrFixedPercentageRedGreen(props) {

    function getClassName() {
        var redBoundary = 45;
        var greenBoundary = 75;
        var className = '';
        if (props.layout.answer) {
            className = 'cell-percentage form-field-';
            if (props.layout.answer <= redBoundary) {
                className = className + "red";
            }
            if (props.layout.answer > redBoundary && props.layout.answer < greenBoundary) {
                className = className + "amber";
            } else if (props.layout.answer >= greenBoundary) {
                className = className + "green";
            }
        }

        return className;
    }

    return <FormReadonly
        headingText={props.layout.secondaryLayoutTitle ? props.layout.secondaryLayoutTitle : ''}
        value={props.layout.answer}
        loading={props.loading}
        largerValueText={!props.layout.secondaryLayoutTitle}
        className={getClassName()}
    />;
}

OcrFixedPercentageRedGreen.propTypes = {
    loading: PropTypes.bool,
    layout: PropTypes.object.isRequired,
    onChange: PropTypes.func.isRequired,
    disabled: PropTypes.bool
};

OcrFixedPercentageRedGreen.defaultProps = {
    loading: false,
    disabled: false
};

import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { FormTextInput } from 'components/form-fields';
import { Button } from 'components/buttons';
import { ShowHide } from 'components/layout';

export function Comment(props){

    const maxCommentSize = 256;

    const [isEditing, setisEditing] = useState(props.isEditibleInitially);
    const [comment, setComment] = useState(props.value ?? "");

    useEffect(() => {
        setComment(props.value);
    }, [props.value]);

    function handleIsEditingChange(editible){
        setisEditing(editible);
    }

    function onCommentChange(event){
        if (event.target.value === undefined) { return; }

        setComment(event.target.value);
    }

    function onSaveComment(){
        props.onSave(comment);
        handleIsEditingChange(false);
    }

    function onDeleteComment(){
        props.onDelete();
        setComment("");
        handleIsEditingChange(false);
    }

    function hasComment(){
        if (!comment){
            return false;
        }
        return comment.length > 0;
    }

    return (
        <div className={classNames('inline-items', props.className)}
            id={props.id}
        >
            <FormTextInput 
                disabled={!isEditing} 
                onChange={onCommentChange} 
                value={comment ?? ""} 
                id={`${props.id}-comment`}
                maxLength={maxCommentSize} />
                
            <ShowHide evaluator={!isEditing} show={(
                <>
                    <Button
                        iconOnly={true}
                        icon={"edit"}
                        minimal={true}
                        large={false}
                        onClick={() => handleIsEditingChange(true)}
                    />
                    <Button
                        iconOnly={true}
                        icon={"trash"}
                        minimal={true}
                        intent="danger"
                        large={false}
                        onClick={onDeleteComment}
                    />
                </>
            )}
            hide={(
                <>
                    <Button
                        iconOnly={true}
                        icon={"cross"}
                        minimal={true}
                        large={false}
                        onClick={() => handleIsEditingChange(false)}
                    />          
                    <Button
                        iconOnly={true}
                        icon={"floppy-disk"}
                        minimal={false}
                        intent='primary'
                        large={false}
                        onClick={onSaveComment}
                        disabled={!hasComment()}
                    />
                </>
            )} />
        </div>
    );
}

Comment.defaultProps = {
    id: null,
    color: "",
    selected: false,
    disabled: false,
    onClick: () => { },
    onDelete: () => { },
    value: "",
    isEditibleInitially: false
};

Comment.propTypes = {
    id: PropTypes.string,
    onClick: PropTypes.func,
    onSave: PropTypes.func.isRequired,
    onDelete: PropTypes.func.isRequired,
    className: PropTypes.string,
    value: PropTypes.string,
    isEditibleInitially: PropTypes.bool,
};
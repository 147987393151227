import React from 'react';
import { Overlay } from '@blueprintjs/core';
import PropTypes from 'prop-types';

import { HelperFunctions } from 'helpers';
import { ShowHide } from '../layout';

export function MediaViewer(props) {

    function handleClose(e) {
        HelperFunctions.executeIfNotNull(props.onClose);
    }

    return (
        <Overlay isOpen={props.isOpen} onClose={handleClose} portalClassName="media-player-overlay">
            <div className="bp3-dialog-container">
                <div className="media-player">
                    <ShowHide
                        evaluator={props.mediaSource != null}
                        show={(
                            <video autoPlay controls>
                                <source src={props.mediaSource} type={props.mediaType} />
                            </video>
                        )}
                    />
                </div>
            </div>
        </Overlay>
    );
}

MediaViewer.defaultProps = {
    onClose: null,
    mediaType: "video/mp4"
};

MediaViewer.propTypes = {
    isOpen: PropTypes.bool.isRequired,
    mediaSource: PropTypes.string,
    mediaType: PropTypes.string,
    onClose: PropTypes.func,
};
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { FormGroupedCheckboxList, FormHelperText } from "components/form-fields";
import { SelfReportingService } from 'services';
import { HelperFunctions } from 'helpers';

export function ScheduleReports(props) {

    const [reportGroups, setReportGroups] = useState([]);

    useEffect(() => {
        SelfReportingService.getReportGroups().then(r => {
            let mappedReports = r.map(function (reportGroup) {
                reportGroup.type = "group";
                reportGroup.expanded = false;
                reportGroup.items = reportGroup.reports.map(function (report) {
                    return {
                        ...report,
                        checked: reportChecked(report.id),
                        disabled: props.disabled,
                        type: "item"
                    };
                });
                return reportGroup;
            });

            mappedReports = mappedReports.filter(function (g) {
                return g.items && g.items.length > 0;
            });

            applyCheckedItems(mappedReports, props.schedule.reports, false);

            setReportGroups(mappedReports);
        });

        props.onValidationUpdate(props.schedule.reports.length > 0);

    }, []);

    function reportChecked(id) {
        return props.schedule.reports.some(function (report) {
            return report.id === id;
        });
    }

    function onItemSelect(itemId) {

        var cloneSelectedReports = [...props.schedule.reports];

        if (cloneSelectedReports.includes(itemId)) {
            cloneSelectedReports = cloneSelectedReports.filter(function (l) {
                return l !== itemId;
            })
        } else {
            cloneSelectedReports.push(itemId);
        }

        var cloneSchedule = {
            ...props.schedule,
            reports: cloneSelectedReports
        }

        props.onScheduleUpdate(cloneSchedule);
        props.onValidationUpdate(cloneSelectedReports.length > 0);
    }

    function onGroupToggle(groupId) {
        var clonedGroups = [
            ...reportGroups
        ];

        var group = HelperFunctions.findRecursiveItem(clonedGroups, groupId, "group");
        group.expanded = !group.expanded;

        setReportGroups(clonedGroups);
    }

    function applyCheckedItems(hierarchy, permissions, parentChecked) {

        var childItemsChecked = false;

        if (hierarchy == null) {
            return childItemsChecked;
        }

        for (var i = 0; i < hierarchy.length; i++) {
            var currentItem = hierarchy[i];
            var currentItemChecked = false;
            currentItem.checked = false;
            currentItem.disabled = false;
            currentItem.partialChecked = false;


            if (parentChecked) {
                currentItem.checked = true;
                currentItem.disabled = true;
            } else {

                if (currentItem.type === "item" && permissions.includes(currentItem.id)) {
                    currentItem.checked = true;
                    childItemsChecked = true;
                }

            }

            var childChecked = applyCheckedItems(currentItem.items, permissions, parentChecked || currentItemChecked);

            if (childChecked) {
                childItemsChecked = true;

                if (!currentItem.checked) {
                    currentItem.partialChecked = true;
                }
            }
        }

        return childItemsChecked;

    }

    useEffect(function () {

        var clonedReportGroups = [...reportGroups];
        applyCheckedItems(clonedReportGroups, props.schedule.reports, false);

        setReportGroups(clonedReportGroups);
        props.onValidationUpdate(props.schedule.reports.length > 0);


    }, [props.schedule.reports]);

    function onReportSearch(term) {
        var clonedReportGroups = [...reportGroups];
        applySearchTerm(clonedReportGroups, term.toLowerCase());
        setReportGroups(clonedReportGroups);
    }

    function applySearchTerm(hierarchy, searchTerm) {

        var searchReturnedResults = false;
        var anyChildrenReturnedResults = false;

        if (hierarchy == null) {
            return false;
        }

        for (var i = 0; i < hierarchy.length; i++) {
            var currentItem = hierarchy[i];
            currentItem.appearInSearch = false;

            if (currentItem.type === "item" && (currentItem.name.toLowerCase().indexOf(searchTerm.toLowerCase()) > -1)) {
                currentItem.appearInSearch = true;
                searchReturnedResults = true;
            }

            var childSearchReturnedResults = applySearchTerm(currentItem.items, searchTerm);

            if (childSearchReturnedResults) {
                currentItem.appearInSearch = true;
            }

            anyChildrenReturnedResults = anyChildrenReturnedResults || childSearchReturnedResults;
        }

        return searchReturnedResults || anyChildrenReturnedResults;
    }

    return (
        <div>
            <h2>Schedule Reports</h2>
            <FormHelperText danger={true}>{props.dangerHelperText}</FormHelperText>
            <FormGroupedCheckboxList
                items={reportGroups}
                groupsSelectable={false}
                onGroupToggle={onGroupToggle}
                onItemSelect={onItemSelect}
                loading={props.loading}
                saving={props.disabled}
                searchable
                onSearch={onReportSearch}
                showTags
                itemIcon="document"
                onTagRemove={onItemSelect}
            />

        </div>
    );
}

ScheduleReports.defaultProps = {
    dangerHelperText: null,
    loading: false,
    disabled: false
};

ScheduleReports.propTypes = {
    loading: PropTypes.bool,
    dangerHelperText: PropTypes.string,
    onValidationUpdate: PropTypes.func.isRequired,
    onScheduleUpdate: PropTypes.func.isRequired,
    schedule: PropTypes.object.isRequired,
    disabled: PropTypes.bool
};
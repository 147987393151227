import React, { useState } from 'react';
import { DndContext, closestCenter, DragOverlay } from '@dnd-kit/core';
import { SortableContext, rectSortingStrategy, arrayMove, useSortable } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import './DragDropArea.css';
import { Icon } from '@blueprintjs/core';

function DroppableContainer({ className, children }) {
    return (
        <div className={classNames('drag-drop', className)}>
            {children}
        </div>
    );
}

DroppableContainer.propTypes = {
    className: PropTypes.string,
    children: PropTypes.node.isRequired,
};

DroppableContainer.defaultProps = {
    className: '',
};

function DraggableItem({ id, item, isDragging, onRemoveItem, disabled }) {
    const { attributes, listeners, setNodeRef, transform, transition } = useSortable({
        id: id.toString(),
    });

    const style = {
        transform: CSS.Translate.toString(transform),
        transition,
    };

    const handleRemoveClick = (e) => {
        e.stopPropagation();
        e.preventDefault();
        onRemoveItem(item.id);
    };

    return (
        <div
            ref={setNodeRef}
            style={style}
            {...attributes}
            className={classNames(
                'drag-drop-item',
                {
                    'dragging-hidden': isDragging,
                    'disabled': disabled
                }
            )}
        >
            <div {...listeners} className="drag-handle">
                <Icon className="draggable-item-duplicate" icon="layout-grid" iconSize={12} />
                <span className="item-text">{item.name}</span>
            </div>
            <Icon
                className="drag-drop-remove-button"
                icon="small-cross"
                onClick={handleRemoveClick}
                disabled={isDragging || disabled}
            />
        </div>
    );
}

DraggableItem.propTypes = {
    id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
    item: PropTypes.shape({
        id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
        name: PropTypes.string.isRequired,
    }).isRequired,
    isDragging: PropTypes.bool.isRequired,
    onRemoveItem: PropTypes.func.isRequired,
    disabled: PropTypes.bool,
};

DraggableItem.defaultProps = {
    disabled: false,
};

export function DragDropArea({ items, onDragEnd, onRemoveItem, disabled }) {
    const [activeId, setActiveId] = useState(null);

    const handleDragEnd = (event) => {
        const { active, over } = event;
        setActiveId(null);
        if (over && active.id !== over.id) {
            const oldIndex = items.findIndex((item) => item.id === Number(active.id));
            const newIndex = items.findIndex((item) => item.id === Number(over.id));
            if (oldIndex !== -1 && newIndex !== -1) {
                const newItems = arrayMove(items, oldIndex, newIndex);
                onDragEnd(newItems.map((item, index) => ({ ...item, order: index })));
            }
        }
    };

    const handleDragStart = (event) => {
        setActiveId(event.active.id);
    };

    const getItemById = (id) => items.find((item) => item.id === Number(id));

    return (
        <div className={classNames('drag-drop-container', { 'disabled': disabled })}>
            <DndContext
                onDragEnd={handleDragEnd}
                onDragStart={handleDragStart}
                collisionDetection={closestCenter}
            >
                <SortableContext items={items.map(item => item.id.toString())} strategy={rectSortingStrategy}>
                    <DroppableContainer className="droppable">
                        {items.map((item) => (
                            <DraggableItem
                                key={item.id}
                                id={item.id}
                                item={item}
                                isDragging={activeId === item.id.toString()}
                                onRemoveItem={() => onRemoveItem(item.id.toString())}
                                disabled={disabled}
                            />
                        ))}
                    </DroppableContainer>
                </SortableContext>
                <DragOverlay>
                        <div className="drag-drop-item">
                            <div className="drag-handle">
                            <Icon className="draggable-item-duplicate" icon="layout-grid" iconSize={12} />
                                <span className="item-text">{getItemById(activeId)?.name}</span>
                            </div>
                            <Icon className="drag-drop-remove-button" icon="small-cross" />
                        </div>
                </DragOverlay>
            </DndContext>
        </div>
    );
}

DragDropArea.propTypes = {
    items: PropTypes.arrayOf(PropTypes.shape({
        id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
        name: PropTypes.string.isRequired,
    })).isRequired,
    onDragEnd: PropTypes.func.isRequired,
    onRemoveItem: PropTypes.func.isRequired,
    disabled: PropTypes.bool,
};

DragDropArea.defaultProps = {
    disabled: false,
};

export default DragDropArea;
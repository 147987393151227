import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Tag } from "@blueprintjs/core";
import './Status.css';
import classNames from 'classnames';

export function StatusBlock(props) {
    const [backgroundColor, setBackgroundColor] = useState('00C6B2');
    const [contrastMode, setContrastMode] = useState(false);

    useEffect(() => {
        let newBackgroundColor = '00C6B2';

        const hexLength = 6;
        const redByteStart = 0;
        const redByteEnd = 2;
        const greenByteEnd = 4;
        const blueByteEnd = 6;

        const redBrightness = 0.299;
        const greenBrightness = 0.587;
        const blueBrightness = 0.114;
        const contrastThreshold = 186;

        if (props.background) {
            newBackgroundColor = props.background;

            if (props.background.length === hexLength) {
                var red = parseInt(props.background.substring(redByteStart, redByteEnd), 16);
                var green = parseInt(props.background.substring(redByteEnd, greenByteEnd), 16);
                var blue = parseInt(props.background.substring(greenByteEnd, blueByteEnd), 16);

                setContrastMode((red * redBrightness + green * greenBrightness + blue * blueBrightness) > contrastThreshold);
            }
        }

        switch (props.status?.toLowerCase()) {
            case 'away':
            case 'amber':
                newBackgroundColor = 'FF7454';
                break;
            case 'inactive':
            case 'red':
                newBackgroundColor = 'EC2D6D';
                break;
            default:
                break;
        }

        setBackgroundColor(newBackgroundColor);
    }, [props.background, props.status])

    return (
        <Tag
            large={props.large}
            className={classNames("status-block", { "hollow": props.hollow, "not-bold": !props.bold, "dark": contrastMode })}
            style={{ backgroundColor: props.hollow ? 'transparent' : `#${backgroundColor}`, borderColor: `#${backgroundColor}`, color: props.hollow ? `#${backgroundColor}` : '' }}
        >
            {props.children}
        </Tag>
    );
}

StatusBlock.defaultProps = {
    background: '',
    hollow: false,
    bold: true,
    status: '',
    large: true
};

StatusBlock.propTypes = {
    background: PropTypes.string,
    children: PropTypes.node,
    hollow: PropTypes.bool,
    bold: PropTypes.bool,
    status: PropTypes.string,
    large: PropTypes.bool
};
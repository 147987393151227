import React from 'react';
import classNames from 'classnames';
import { TextArea } from "@blueprintjs/core";
import PropTypes from 'prop-types';

import { FormHelperText } from "components/form-fields";

import 'components/form-fields/FormFields.css';
import { ShowHide } from 'components/layout';
import { Numeric } from 'components/formatting';

export function FormTextArea(props) {

	return (
		<div className="form-field">
			<h4 className={classNames({ "bp3-skeleton": props.loading })}>{props.headingText}</h4>
			<FormHelperText loading={props.loading}>{props.helperText}</FormHelperText>
			<TextArea
				id={props.id}
				disabled={props.disabled}
				className={classNames("hide-in-print", { "bp3-skeleton": props.loading }, { "wide": props.wide })}
				onChange={props.onChange}
				maxLength={props.maxLength}
				value={props.value}
				inputRef={props.inputRef}
			/>
			<ShowHide
				evaluator={props.showCounter && props.maxLength != null}
				show={(
					<p className="hide-in-print form-text-counter">
						<Numeric>{props.value?.length ?? 0}</Numeric> / <Numeric>{props.maxLength}</Numeric>
					</p>
				)}
			/>
			<p className="show-in-print">{props.value}</p>
			<FormHelperText danger={true}>{props.dangerHelperText}</FormHelperText>
		</div>
	);
}

FormTextArea.defaultProps = {
	id: null,
	value: "",
	loading: false,
	headingText: "",
	helperText: null,
	disabled: false,
	dangerHelperText: null,
	maxLength: null,
	showCounter: false,
	wide: false
};

FormTextArea.propTypes = {
	id: PropTypes.string,
	value: PropTypes.string,
	onChange: PropTypes.func.isRequired,
	loading: PropTypes.bool,
	headingText: PropTypes.string,
	helperText: PropTypes.node,
	disabled: PropTypes.bool,
	dangerHelperText: PropTypes.node,
	maxLength: PropTypes.number,
	showCounter: PropTypes.bool,
	wide: PropTypes.bool,
	inputRef: PropTypes.object
};
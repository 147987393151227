import React, { useState, useEffect } from 'react';
import { ListingTable } from "components/listing";
import { PageRestricted } from 'pages/errors/page-restricted';
import { UserAccess, UserHasAccess } from 'components/user-access';
import { DateToLocal } from "components/dates";
import { EmailService, UserService } from "services";

export function UserEmailHistory(props) {

    const defaultRecordSize = 25;


    const [totalRecords, setTotalRecords] = useState(0);
    const [tableRows, setTableRows] = useState([]);
    const [pageNumber, setPageNumber] = useState(1);
    const [pageSize, setPageSize] = useState(defaultRecordSize);
    
    const [loadingData, setLoadingData] = useState(true);
    const noDataMessage = "It looks like no emails have been sent to this user.";
    const tableHeaders = ["Subject", "Sent Date", "Status"];
    const sortableHeaders = ["Sent Date"];
    const [tableSortBy, setTableSortBy] = useState("Sent Date");
    const [tableSortDir, setTableSortDir] = useState("D");
    const requiredAction = "Users:Manage";
    const canAccessPage = UserHasAccess(requiredAction);

    useEffect(() => {
        if (loadingData && canAccessPage) {
            UserService.getUserBasicInfo(props.userId).then(userResponse => {
                EmailService.getEmailHistory(userResponse.emailAddress, pageSize, pageNumber, tableSortDir).then(emailResponse => {
                    let results = emailResponse.sentEmails;
                    let data = [];
                    if (results != null) {
                        data = results.map((d) => {
                            return [d.subject, <DateToLocal>{d.sentDate}</DateToLocal>, d.emailStatus];
                        })
                    }
                    setTableRows(data);
                    setTotalRecords(emailResponse.totalCount);
                    setLoadingData(false);
                });
            });
        }

    }, [loadingData, pageSize, pageNumber, tableSortBy, tableSortDir]);



    function onPagingChange(newPageNumber, newPageSize) {
        setPageNumber(newPageNumber);
        setPageSize(newPageSize);
        setLoadingData(true);
    }

    function onTableSort(header, direction) {
        setTableSortBy(header);
        setTableSortDir(direction);
        setLoadingData(true);
    }

    return (

        <UserAccess perform={requiredAction}
            yes={() => (
                <div className="row">
                    <h1>Email History</h1>


                    <ListingTable
                        id="listing-table-location"
                        headers={tableHeaders}
                        data={tableRows}
                        totalRecordCount={totalRecords}
                        onPagingChange={onPagingChange}
                        loadingData={loadingData}
                        noDataMessage={noDataMessage}
                        sortable
                        sortableHeaders={sortableHeaders}
                        sortedBy={tableSortBy}
                        sortedDir={tableSortDir}
                        onSort={onTableSort}
                    />
                </div>
            )}
            no={() => (
                <PageRestricted />
            )}
        />
    );

}
import React, { Fragment, useEffect, useState } from "react";
import {
    Button,
    Intent,
    NonIdealState,
    Position,
    Tooltip,
    Spinner
} from "@blueprintjs/core";
import { Route, Switch, NavLink, useLocation } from "react-router-dom";
import { useSelector } from 'react-redux';
import classNames from 'classnames';

import { useDebounce } from "hooks/useDebounce";

import { PageRestricted } from "pages/errors/page-restricted";
import { SelfServiceReport } from "pages/self-service-reports";
import { UserAccess } from "components/user-access";
import { SelfReportingService, UserService, LocationService, BlobStorageService } from "services";
import { HelperFunctions } from "helpers";
import { VerticalExpanderGroup, Breadcrumbs } from "components/navigation";
import { FormTextInput } from "components/form-fields";
import { ShowHide } from "components/layout";
import axios from "axios";
import { Table } from "components/listing";
import { NotificationToaster } from "components/notifications";
import { DateToLocal } from "components/dates";

export function SelfServiceReports(props) {
    const [allGroups, setAllGroups] = useState([
        {
            id: 0,
            name: "loading group",
            reports: [{ id: 1, name: "loading report" }],
        },
        {
            id: 1,
            name: "loading group",
            reports: [{ id: 1, name: "loading report" }],
        },
        {
            id: 2,
            name: "loading group",
            reports: [{ id: 1, name: "loading report" }],
        },
        {
            id: 3,
            name: "loading group",
            reports: [{ id: 1, name: "loading report" }],
        },
    ]);
    const [favourites, setFavourites] = useState([]);
    const [reportGroups, setReportGroups] = useState([]);
    const debounceTimeout = 250;
    const [loading, setLoading] = useState(true);
    const [landingPageLoading, setLandingPageLoading] = useState(true);
    const [searchTerm, setSearchTerm] = useState("");
    const [runHistory, setRunHistory] = useState([]);
    const debouncedSearchTerm = useDebounce(searchTerm, debounceTimeout);
    const [selectedGroup, setSelectedGroup] = useState(0);
    const [selectedReport, setSelectedReport] = useState(null);
    const [initalReport, setInitialReport] = useState(false);
    const requiredAction = "SelfService:View";
    const [currentDownloadId, setCurrentDownloadId] = useState(null);
    const [generatedReports, setGeneratedReports] = useState(null);

    const accountId = useSelector(state => state.loggedInUser.accountId);

    const [permissions, setPermissions] = useState([]);

  const tableHeaders = ["Report Name", "Requested date", "Current state", ""];
  const [generatedReportRows, setGeneratedReportRows] = useState([]);

    let location = useLocation()

    useEffect(() => {

        if (location.pathname === '/self-service-reports') {
            setLandingPageLoading(true);
            setSelectedReport(null);
            setSelectedGroup(0);
            setInitialReport(false);
            axios.all([
                SelfReportingService.getRecentlyRun(),
                SelfReportingService.getGenerationRequests()
            ]).then(axios.spread(function (historyResponse, generationRequestResponse) {
                setRunHistory(historyResponse);
                setGeneratedReports(generationRequestResponse);
                setLandingPageLoading(false);
            }));
        }
    }, [location]);

    function initialLoad() {
        axios
            .all([
                SelfReportingService.getReportGroups(),
                SelfReportingService.getFavourites(),
                LocationService.getLocationBusinessAreas()
            ])
            .then(
                axios.spread(function (
                    groupResponse,
                    favouriteResponse,
                    businessAreasResponse
                ) {
                    setAllGroups(groupResponse);
                    setFavourites(favouriteResponse);

                    var allPermissions = [];

                    var permissionRequests = [];

                    businessAreasResponse.forEach(function (ba) {
                        if (!ba.internal) {
                            permissionRequests.push(
                                UserService.getLoggedInUserLocations(ba.locationBusinessAreaId, false, true)
                            );
                        }

                        allPermissions.push({
                            id: ba.locationBusinessAreaId,
                        });
                    });

                    axios.all(permissionRequests).then(function (permissionResponses) {
                        permissionResponses.forEach(function (p, index) {
                            allPermissions[index].permissions = p;
                        });

                        setPermissions(allPermissions);
                    });

                    setLoading(false);
                })
            );
    }

    function formatGenerationRequestsHeaders() {
        var headers = tableHeaders.map((value) => {
            return {
                key: value,
                value: value,
            };
        });

        return {
            headers: headers,
        };
    }

    function setGenerationRequestsRows(generationRequestResponse) {

        if (generationRequestResponse == null) {
            return;
        }

        let data = generationRequestResponse.map((d) => {
            let requestDate = <DateToLocal>{d.requestDate}</DateToLocal>;
            return [d.reportName, requestDate, d.generationState, generateReportDownloadButton(d)];
        });

        setGeneratedReportRows(formatGenerationRequestsData(data));
    }

    function generateReportDownloadButton(downloadRow) {

        if (currentDownloadId === downloadRow.generationId) {
            return (
                <Spinner
                    size={24}
                    intent={Intent.PRIMARY}
                    className="justify-left"
                />
            );
        }

        if (downloadRow.generationStateKey.toLowerCase() === "complete") {
            return (
                <Tooltip position={Position.RIGHT} content={`Download the report`}>
                    <Button
                        icon="download"
                        minimal={true}
                        intent={Intent.NONE}
                        disabled={currentDownloadId != null}
                        onClick={() => getGeneratedReport(downloadRow.reportId, downloadRow.generationId)}
                    ></Button>
                </Tooltip>
            );
        }

        return (<Fragment />);
    }

    useEffect(function () {
        setGenerationRequestsRows(generatedReports)
    }, [generatedReports, currentDownloadId])

    function getGeneratedReport(reportId, generationId) {

        var throwError = function () {
            NotificationToaster.show(
                Intent.DANGER,
                "Could not download the report. Please try again later"
            );
            setCurrentDownloadId(null);
        }

        setCurrentDownloadId(generationId);
        SelfReportingService.checkReportGenerationStatus(reportId, generationId).then(
            function (result) {

                var successfulResponse = 200;

                if (result.status === successfulResponse) {
                    BlobStorageService.downloadFile(result.data.storageName, result.data.containerName, result.data.fileName, result.data.token, result.data.downloadName).then(function () {
                        NotificationToaster.show(
                            Intent.SUCCESS,
                            "Report will now begin downloading"
                        );
                        setCurrentDownloadId(null);
                    }, throwError);
                } else {
                    setCurrentDownloadId(null);
                    NotificationToaster.show(
                        Intent.WARNING,
                        "Report is not ready to be downloaded yet"
                    );
                }
            },
            throwError
        );
    }

    function formatGenerationRequestsData(data) {
        return data.map((row, rowIndex) => {
            return {
                cells: row,
                key: rowIndex,
            };
        });
    }

    useEffect(initialLoad, []);

    function onGroupSelected(id) {
        setSelectedGroup(id);
    }

    function onItemSelected(id) {
        setSelectedReport(id);
    }

    function onReportSearch(item) {
        setSearchTerm(item.target.value);
    }

    function onReportLoad(report) {
        if (selectedReport == null) {
            setSelectedReport(report.reportId);
            setInitialReport(true);
        }
    }

    useEffect(filterReportGroups, [
        debouncedSearchTerm,
        allGroups.length,
        favourites.length,
        accountId
    ]);

    function filterReportGroups() {
        //Filter on search term and then remove groups without any reports

        var favouriteGroup = {
            name: "Favourites",
            id: 0,
            accountId: null,
            reports: favourites,
        };

        var groupsToMap = [favouriteGroup, ...allGroups];

        var mappedGroups = groupsToMap.map(function (g, index) {
            var reports = [...g.reports];

            if (debouncedSearchTerm.trim() !== "") {
                reports = reports.filter(function (r) {
                    return (
                        r.name.toLowerCase().indexOf(debouncedSearchTerm.toLowerCase()) > -1
                    );
                });
            }

            var mappedReports = reports.map(function (i) {
                return {
                    location: `/self-service-reports/${i.id}/${HelperFunctions.kebabCase(
                        i.name
                    )}`,
                    title: i.name,
                    id: i.id,
                };
            });

            return {
                title: g.name,
                id: g.id,
                accountId: g.accountId,
                children: mappedReports,
            };
        });

        var filteredGroups = mappedGroups.filter(function (g) {
            return (g.accountId == null || g.accountId === accountId) && g.children.length > 0;
        });

        setReportGroups(filteredGroups);
    }

    useEffect(
        function () {
            if (initalReport) {
                return;
            }

            //Try and find a group with the report in and make that active
            var groupWithReport = reportGroups.find(function (g) {
                return (
                    g.children != null &&
                    g.children.some(function (r) {
                        return r.id === selectedReport;
                    })
                );
            });

            if (groupWithReport != null) {
                setSelectedGroup(groupWithReport.id);
            }
        },
        [selectedReport, initalReport]
    );

    function onFavouriteClick(report) {
        var favouritesClone = [...favourites];

        var alreadyFavourited = favouritesClone.some(function (f) {
            return f.id === report.reportId;
        });

        if (alreadyFavourited) {
            favouritesClone = favouritesClone.filter(function (f) {
                return f.id !== report.reportId;
            });
            SelfReportingService.removeFavourite(report.reportId);
        } else {
            favouritesClone.push({ id: report.reportId, name: report.name });
            SelfReportingService.addFavourite(report.reportId);
        }

        setFavourites(favouritesClone);
    }

    return (
        <UserAccess
            perform={requiredAction}
            yes={() => (
                <Fragment>
                    <div className="row">
                        <Breadcrumbs items={selectedReport == null ? props.breadcrumbs : [...props.breadcrumbs, { title: "Generate Report" }]} />
                        <h1>Self Service Reports</h1>
                    </div>

                    <div className="layout-cols">
                        <div className="col side-col">
                            <div className="row">
                                <FormTextInput
                                    placeholder="Search report name"
                                    onChange={onReportSearch}
                                    value={searchTerm}
                                    large
                                    disabled={loading}
                                    icon="search"
                                    id="report-search-field"
                                />
                                <VerticalExpanderGroup
                                    groups={reportGroups}
                                    selectedGroup={selectedGroup}
                                    selectedItem={selectedReport}
                                    onGroupSelect={onGroupSelected}
                                    onItemSelect={onItemSelected}
                                    loading={loading}
                                />
                                <ShowHide
                                    evaluator={
                                        reportGroups.length === 0 && debouncedSearchTerm.length > 0
                                    }
                                    show={
                                        <NonIdealState description="Search returned no results" />
                                    }
                                />
                            </div>
                        </div>
                        <div className="col content-col">
                            <Switch>
                                <Route path="/self-service-reports/:id(\d+)">
                                    <SelfServiceReport
                                        onFavouriteClick={onFavouriteClick}
                                        favourites={favourites}
                                        onReportLoad={onReportLoad}
                                        locations={permissions}
                                    />
                                </Route>
                                <Route>
                                    <div>
                                        <ShowHide
                                            evaluator={generatedReportRows.length > 0}
                                            show={
                                                <div className="spacer-bottom">
                                                    <h3>Reports generated within the last 24 hours</h3>
                                                    <Table
                                                        headerData={formatGenerationRequestsHeaders()}
                                                        data={generatedReportRows}
                                                        loadingData={landingPageLoading}
                                                    />
                                                </div>
                                            }
                                        />
                                        <ShowHide
                                            evaluator={runHistory.length > 0}
                                            show={
                                                <div className="spacer-bottom">
                                                    <h3>Recently Run Reports</h3>
                                                    <ul className="bp3-list">
                                                        {runHistory &&
                                                            runHistory.map(function (previousReport) {
                                                                return (
                                                                    <li key={previousReport.id}>
                                                                        <NavLink
                                                                            to={`/self-service-reports/${previousReport.id
                                                                                }/${HelperFunctions.kebabCase(
                                                                                    previousReport.name
                                                                                )}`}
                                                                            exact
                                                                        >
                                                                            {previousReport.name}
                                                                        </NavLink>
                                                                    </li>
                                                                );
                                                            })}
                                                    </ul>
                                                </div>
                                            }
                                        />
                                        <ShowHide
                                            evaluator={generatedReportRows.length > 0 || runHistory.length > 0}
                                            hide={
                                                <div>
                                                    <h3 className={classNames({ "bp3-skeleton": landingPageLoading })}>How to use the Self-service Reports</h3>
                                                    <div className="intro-text">
                                                        <p className={classNames({ "bp3-skeleton": landingPageLoading })}>
                                                            You can use the self-service screen to generate
                                                            reports for locations, drivers and vehicles. To
                                                            run a report, take the following steps:
                                                        </p>
                                                    </div>
                                                    <div className="spacer-bottom">
                                                        <ol className={classNames("bp3-list", { "bp3-skeleton": landingPageLoading })}>
                                                            <li>
                                                                If you know the name of the report you want to
                                                                run enter it into the search box or
                                                                alternatively, click the report group to find
                                                                the report
                                                            </li>
                                                            <li>Select the report you would like to run</li>
                                                            <li>
                                                                Fill in the report parameters, all required
                                                                fields are highlighted
                                                            </li>
                                                            <li>
                                                                Select the format you would like the report to
                                                                run in
                                                            </li>
                                                            <li>Click "Generate report"</li>
                                                        </ol>
                                                    </div>
                                                </div>
                                            }
                                        />
                                    </div>
                                </Route>
                            </Switch>
                        </div>
                    </div>
                </Fragment>
            )}
            no={() => <PageRestricted />}
        />
    );
}

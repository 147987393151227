import React, { useEffect, useState } from 'react';
import classNames from "classnames";
import { AlertUnsavedData, Modal, NotificationToaster } from "../../../components/notifications";
import { FormCheckbox } from "../../../components/form-fields";
import PropTypes from "prop-types";
import { ButtonSave } from "../../../components/buttons";
import { Intent } from "@blueprintjs/core";
import { useDirtyData } from "hooks/useDirtyData";
import './CalendarPreferencesModal.css';

export function CalendarPreferencesModal(props) {

    const { showPreferencesModal, closePreferencesModal,
        loading, showVehicleActivityTab, showUploadsTab, showDriversUsedTab, showTimes,
        onSavePreferences, onPreferencesSaved
    } = props;

    const [vehicleActivityTab, setVehicleActivityTab] = useState(showVehicleActivityTab);
    const [uploadsTab, setUploadsTab] = useState(showUploadsTab);
    const [driversUsedTab, setDriversUsedTab] = useState(showDriversUsedTab);
    const [times, setTimes] = useState(showTimes);

    const [saving, setSaving] = useState(false);
    const [showAlert, setShowAlert] = useState(false);

    useEffect(() => {
        if (showPreferencesModal) {
            setVehicleActivityTab(showVehicleActivityTab);
            setUploadsTab(showUploadsTab);
            setDriversUsedTab(showDriversUsedTab);
            setTimes(showTimes);
        }
    }, [showPreferencesModal, showVehicleActivityTab, showUploadsTab, showDriversUsedTab, showTimes]);

    const initialPreferences = {
        showVehicleActivityTab,
        showUploadsTab,
        showDriversUsedTab,
        showTimes,
    };

    const currentPreferences = {
        showVehicleActivityTab: vehicleActivityTab,
        showUploadsTab: uploadsTab,
        showDriversUsedTab: driversUsedTab,
        showTimes: times,
    };

    const isDataDirty = useDirtyData(initialPreferences, currentPreferences);

    function handleClosePreferencesModal() {
        if (saving) {
            return;
        }

        if (isDataDirty) {
            setShowAlert(true);
        } else {
            closePreferencesModal();
        }
    }

    function handleConfirmLeave() {
        closePreferencesModal();
        setShowAlert(false);
    }

    function handleCancelLeave() {
        setShowAlert(false);
    }

    function handleSave() {
        let preferences = {
            showVehicleActivity: vehicleActivityTab,
            showUploads: uploadsTab,
            showDriversUsed: driversUsedTab,
            showTimesOnCalendar: times
        };

        if (isDataDirty) {
            setSaving(true);
            onSavePreferences(preferences)
                .then(() => {
                    NotificationToaster.show(Intent.SUCCESS, `Calendar preferences were saved successfully.`);
                    onPreferencesSaved(preferences);
                    closePreferencesModal();
                    setSaving(false);
                })
                .catch((error) => {
                    const errorMessage = error.message || error.toString();
                    NotificationToaster.show(Intent.DANGER, `Failed to save calendar preferences. Please try again. ${errorMessage}`);
                    setSaving(false);
                });
        } else {
            closePreferencesModal();
        }
    }

    return (
        <Modal
            isOpen={showPreferencesModal}
            title="Display Preferences"
            onClose={handleClosePreferencesModal}
            autoFocus={false}
            enforceFocus={false}
            canOutsideClickClose={false}
            updateStateOnClose={false}
            className={classNames('calendar-preferences-modal', { 'calendar-preferences-modal-disable-close': saving })}
        >
            <div className="calendar-preferences-modal-wrapper">
                <div className={classNames("preferences-modal-body", "form-field", "form-field-select")}>
                    <h2 className="calendar-preferences-modal-header">Summaries</h2>
                    <FormCheckbox
                        checked={vehicleActivityTab}
                        label='Vehicle Activity'
                        onChange={() => setVehicleActivityTab(prev => !prev)}
                        loading={loading}
                        disabled={saving}
                    />
                    <FormCheckbox
                        checked={uploadsTab}
                        label='Uploads'
                        onChange={() => setUploadsTab(prev => !prev)}
                        loading={loading}
                        disabled={saving}
                    />
                    <FormCheckbox
                        checked={driversUsedTab}
                        label='Drivers Used'
                        onChange={() => setDriversUsedTab(prev => !prev)}
                        loading={loading}
                        disabled={saving}
                    />
                </div>
                <div className={classNames("preferences-modal-body", "form-field", "form-field-select")}>
                    <h2 className="calendar-preferences-modal-header">Calendar</h2>
                    <FormCheckbox
                        checked={times}
                        label='Show times on calendar'
                        onChange={() => setTimes(prev => !prev)}
                        loading={loading}
                        disabled={saving}
                    />
                </div>
            </div>
            <div className="spacer-bottom">
                <div className="calendar-preferences-modal-button">
                    <ButtonSave
                        intent={Intent.PRIMARY}
                        onClick={handleSave}
                        loading={saving}
                        disabled={saving}
                        icon="floppy-disk"
                    />
                </div>
            </div>
            {showAlert && (
                <AlertUnsavedData
                    isDirty={isDataDirty}
                    isDirtySamePage={isDataDirty}
                    onConfirmLeave={handleConfirmLeave}
                    onCancelLeave={handleCancelLeave}
                />
            )}
        </Modal>
    );
}

CalendarPreferencesModal.defaultProps = {
    showPreferencesModal: false,
    closePreferencesModal: () => { },
    showVehicleActivityTab: false,
    showUploadsTab: false,
    showDriversUsedTab: false,
    showTimes: false,
    loading: false,
    onSavePreferences: () => { },
    onPreferencesSaved: () => { },
};

CalendarPreferencesModal.propTypes = {
    showPreferencesModal: PropTypes.bool.isRequired,
    closePreferencesModal: PropTypes.func.isRequired,
    showVehicleActivityTab: PropTypes.bool,
    showUploadsTab: PropTypes.bool,
    showDriversUsedTab: PropTypes.bool,
    showTimes: PropTypes.bool,
    loading: PropTypes.bool,
    onSavePreferences: PropTypes.func.isRequired,
    onPreferencesSaved: PropTypes.func,
};

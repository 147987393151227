import React from 'react';
import { VerticalLink } from 'components/navigation/VerticalLink';
import PropTypes from 'prop-types';

import 'components/navigation/Navigation.css';

export function HorizontalLinkGroup(props) {
    return (
        <div className="bp3-tabs bp3-horizontal">
            <ul className="bp3-tab-list" role="tablist">
                {props.links.map((l, index) => {
                    return <VerticalLink key={index} location={l.location} title={l.title} exactRouteMatch={props.exactRouteMatch} externalLink={l.externalLink}></VerticalLink>
                })}
            </ul>
        </div>
    );
}

HorizontalLinkGroup.propTypes = {
    links: PropTypes.array.isRequired,
    exactRouteMatch: PropTypes.bool,
};

HorizontalLinkGroup.defaultProps = {
    exactRouteMatch: true
};
import { NonIdealState, Position } from '@blueprintjs/core';
import { Button } from 'components/buttons';
import { DateToLocal } from 'components/dates';
import { ShowHide } from 'components/layout';
import { Table } from 'components/listing';
import { Modal } from 'components/notifications';
import { StatusBlock } from 'components/status';
import { Tooltip } from 'components/tooltip';
import PropTypes from 'prop-types';
import React, { useState, useEffect } from 'react';

export function EmployeeLicenceCheckingCategories(props) {
    const [selectedCategory, setSelectedCategory] = useState(null);

    const headers = {
        headers: [
            {
                key: 'ICON',
                value: ''
            },
            {
                key: 'STATUS',
                value: 'Status'
            },
            {
                key: 'CATEGORY',
                value: 'Category'
            },
            {
                key: 'VALID FROM',
                value: 'Valid from'
            },
            {
                key: 'VALID TO',
                value: 'Valid to'
            },
            {
                key: 'DESCRIPTION',
                value: 'Description'
            },
            {
                key: 'RESTRICTIONS',
                value: 'Number of restrictions'
            }
        ]
    };
    const [data, setData] = useState([]);

    useEffect(formatCategoryData, [props.licenceCheck]);

    function formatCategoryData() {
        let formattedData = [];

        if (props?.licenceCheck?.entitlements) {
            formattedData = props.licenceCheck.entitlements
                .map((x, index) => {
                    return {
                        cells: [
                            <i className={`custom-icon custom-icon-cat-${x.code.toLowerCase()}`} />,
                            <StatusBlock status={getStatusForCategory(x)} large={false} bold={false}>{getStatusForCategory(x)}</StatusBlock>,
                            x.code,
                            <DateToLocal format='DD/MM/YYYY'>{x.validFrom}</DateToLocal>,
                            <DateToLocal format='DD/MM/YYYY'>{x.validTo}</DateToLocal>,
                            props.employeeOptions.licenceCategories.find(y => y.licenceCategoryKey.toLowerCase() === x.code.toLowerCase()).description,
                            formatRestrictions(x)
                        ],
                        key: index
                    };
                });
        }

        setData(formattedData);
    }

    function formatRestrictions(entitlement) {
        const numberOfRestrictions = entitlement.restrictions ? entitlement.restrictions.length : 0;
        const additionalElement = numberOfRestrictions > 0 ?
            <Tooltip position={Position.BOTTOM} content="View restrictions"><Button onClick={() => viewRestrictions(entitlement)} icon="info-sign" iconOnly={true} large={false} minimal={true}></Button></Tooltip>
            : '';

        return <span className='inline-items'>
            {numberOfRestrictions}
            {additionalElement}
        </span>;
    }

    function getStatusForCategory(category) {
        if (category.validTo) {
            const formattedValidTo = new Date(category.validTo);
            const today = new Date();

            return formattedValidTo > today ? 'Active' : 'Inactive';
        }

        return 'Active';
    }

    function viewRestrictions(category) {
        setSelectedCategory(category);
    }

    function onRestrictionsClose() {
        setSelectedCategory(null);
    }

    return <div className='spacer-top-small'>
        <ShowHide
            evaluator={data.length > 0}
            show={
                <Table
                    headerData={headers}
                    data={data}
                    loadingData={false}
                    fitToContent={false}
                />
            }
            hide={
                <NonIdealState
                    description={"No valid categories exist for this employee in Licence Bureau."}
                />
            }
        />
        <Modal
            isOpen={!!selectedCategory}
            title={'Restrictions'}
            onClose={onRestrictionsClose}
        >
            <p>The following restrictions apply to the {selectedCategory?.code} licence category:</p>
            <ul>
                {selectedCategory?.restrictions?.map(x => <li key={x}>{x.type} - {x.info}</li>)}
            </ul>
        </Modal>
    </div>;
}

EmployeeLicenceCheckingCategories.defaultProps = {
    licenceCheck: null,
    employeeOptions: null
};

EmployeeLicenceCheckingCategories.propTypes = {
    licenceCheck: PropTypes.object,
    employeeOptions: PropTypes.object
};
import React, { Fragment, useState, useEffect, useRef } from 'react';
import { Intent, NonIdealState } from '@blueprintjs/core';
import axios from 'axios';
import classNames from "classnames";
import { NotificationToaster, NotificationInline } from "components/notifications";
import { ShowHide } from "components/layout";
import { LocationService, UserService } from 'services';
import { useValidation } from "hooks/useValidation";
import { FormSelect, FormCheckboxList, FormTextInput } from 'components/form-fields';
import PropTypes from 'prop-types';
import { useDebounce } from 'hooks/useDebounce';
import { TagList } from 'components/status';
import { ListingPagination } from 'components/listing';
import { LinkButton } from 'components/buttons';

export function LocationContactSettings(props) {

    const originalModel = {
        users: [],
        locationContactTypeId: null
    };

    const debounceTimeout = 750;

    const [originalSettings, setOriginalSettings] = useState({})
    const [loading, setLoading] = useState(true);
    const [contactTypes, setContactTypes] = useState([]);
    const [businessAreas, setBusinessAreas] = useState([]);
    const [noUsers, setNoUsers] = useState(false);
    const [selectedBusinessArea, setSelectedBusinessArea] = useState({});
    const [isValid, errors, validate] = useValidation();
    const [contacts, setContacts] = useState(originalModel);
    const inputSearchRef = useRef(null);
    const [searchTerm, setSearchTerm] = useState("");
    const [users, setUsers] = useState([]);
    const [totalPages, setTotalPages] = useState(1)
    const [pageNumber, setPageNumber] = useState(1);
    const listSize = 50;
    const debouncedSearchTerm = useDebounce(searchTerm, debounceTimeout);
    const [selectedUsers, setSelectedUsers] = useState([]);
    const [lastSearchTerm, setLastSearchTerm] = useState("");
    const [searching, setSearching] = useState(false);

    useEffect(initialLoad, []);

    function initialLoad() {
        setSelectedBusinessArea(null);
        setContacts(originalModel);

        axios.all([
            LocationService.getLocationContactTypes(),
            LocationService.getLocationBusinessAreas()
        ]).then(axios.spread(function (contactTypeResponse, businessAreaResponse) {
            setContactTypes(contactTypeResponse.filter(ct => !props.excludeContactTypes.includes(ct.key)));
            setBusinessAreas(businessAreaResponse);
            setLoading(false);
        }), function () {
            NotificationToaster.show(Intent.DANGER, "Unable to load, please try again.");
        });
        props.onLocationContactSettingsUpdate(originalModel);
    }

    useEffect(() => {
        setContactTypes(props.contactTypes);
    }, [props.contactTypes]);

    useEffect(() => {
        setBusinessAreas(props.businessAreas);
    }, [props.businessAreas]);

    useEffect(() => {
        setContacts(props.contacts);
        setOriginalSettings(props.contacts);
    }, [props.contacts]);

    useEffect(() => {
        setLoading(props.loading);
    }, [props.loading]);

    useEffect(() => {
        props.onValidationUpdate(isValid);
    }, [isValid]);

    function onSearchChange(item) {
        setSearchTerm(item.target.value);
    }
    function onItemChange(event, id) {

        var isChecked = event != null && event.target != null && event.target.checked != null && event.target.checked;

        var tempUser = [...users];

        var selectedUser = tempUser.find(function (r) {
            return r.id === id;
        });

        if (selectedUser != null) {
            selectedUser.checked = isChecked;
            setUsers(tempUser);
        }

        var cloneSelectedUsers = [...selectedUsers];

        if (isChecked) {
            cloneSelectedUsers.push({
                id: selectedUser.id,
                name: selectedUser.name
            });
        } else {
            cloneSelectedUsers = cloneSelectedUsers.filter(function (r) {
                return r.id !== id;
            });
        }

        setSelectedUsers(cloneSelectedUsers);
        var cloneContacts = {
            ...props.contacts,
            users: cloneSelectedUsers
        }

        props.onContactsUpdate(cloneContacts);
    }

    function sortUsers(a, b) {
        var nameA = a.name.toUpperCase();
        var nameB = b.name.toUpperCase();
        if (nameA < nameB) {
            return -1;
        }
        if (nameA > nameB) {
            return 1;
        }

        // names must be equal
        return 0;

    }

    function onRemoveTag(event, id) {
        onItemChange(event, id);
    }

    function onPageChange(newPage) {
        setPageNumber(newPage);
    }

    function fetchUsers() {
        var searchFilter = [];

        if (debouncedSearchTerm !== lastSearchTerm) {
            setPageNumber(1);
        }
        setNoUsers(false);
        setSearching(true);

        if (props.external) {
            UserService.getExternalContactsEmailList(listSize, debouncedSearchTerm).then(function (u) {
                setUserList(u, 0, u.length, props.external);
            }, function () {
                NotificationToaster.show(Intent.DANGER, "Unable to load external contacts list, please try again.");
            });
            return;
        }
        UserService.getUserListWebsite(listSize, pageNumber, debouncedSearchTerm, "", "", searchFilter).then(function (u) {
            setUserList(u, u.totalCount, u.data.length, props.external);
        }, function () {
            NotificationToaster.show(Intent.DANGER, "Unable to load contacts list, please try again.");
        });
    }

    function setUserList(userList, totalCount, dataCount,  external) {
        setSearching(false);
        setLastSearchTerm(debouncedSearchTerm);
        var total = Math.ceil(totalCount / listSize);
        setTotalPages(total);

        if (debouncedSearchTerm === "" && dataCount === 0) {
            setNoUsers(true);
        }

        if (external) {
            var externalMappedUsers = userList.map(function (user) {
                return { id: user.externalContactID, name: user.emailAddress, checked: userChecked(user.userId), disabled: props.disabled };
            });
            setUsers(externalMappedUsers);
            return;
        }
        var mappedUsers = userList.data.map(function (user) {
            return { id: user.userId, name: user.emailAddress, checked: userChecked(user.userId), disabled: props.disabled };
        });
        setUsers(mappedUsers);
    }

    useEffect(fetchUsers, [pageNumber, debouncedSearchTerm]);

    function userChecked(id) {
        return selectedUsers.some(function (su) {
            return su.id === id;
        });
    }

    function onContactTypeSelect(item) {

        var clonedContact = {
            ...originalSettings,
            userId: null,
            locationContactTypeId: item.id,
            contactTypeName: item.name
        };

        setContacts(clonedContact);
        props.onLocationContactSettingsUpdate(clonedContact);

        var businessArea = businessAreas.find(function (a) {
            return a.locationBusinessAreaId === item.locationBusinessAreaId;
        });

        setSelectedBusinessArea(businessArea);
    }

    useEffect(updateValidationRules, [contacts, selectedBusinessArea])

    function updateValidationRules() {

        let rules = [
            { fieldName: "locationContactTypeId", required: true }
        ];

        var externalRules = [];
        if (contacts.users.length === 0) {
            var externalRule = {
                fieldName: "contacts",
                valid: false,
                errorMessage: 'At least on user is required '
            };
            externalRules = [externalRule];
        }

        validate(rules, contacts, externalRules);
    }

    return (
        <Fragment>
            <div className="spacer-bottom">
                <h2>New {props.external ? "External " : ""}Contacts</h2>

                <ShowHide
                    evaluator={!props.loading && contactTypes?.length === 0}
                    show={(
                        <div className="spacer-bottom">
                            <div className="spacer-bottom">
                                <p>This location does not have business areas which require contacts.</p>
                            </div>
                        </div>
                    )}
                />
                <NotificationInline show={!props.external} allowClose={false} intent="info" text="Adding a user as a contact to this location will give them permisions to this location in Vision if they do not already have it." />
                <TagList items={selectedUsers.toSorted(sortUsers)} disabled={props.disabled} onRemoveTag={onRemoveTag} />
                <FormSelect
                    disabled={props.saving || contactTypes?.length === 0 || searching}
                    items={contactTypes}
                    onItemSelect={onContactTypeSelect}
                    placeholder="Select contact type"
                    headingText="Select the type of contact to add:"
                    selectedValue={contacts.locationContactTypeId}
                    dangerHelperText={errors.locationContactTypeId}
                    loading={loading}
                    id="input-contact-type"
                ></FormSelect>
                <ShowHide
                    evaluator={props.external && noUsers}
                    show={(
                        <div className='form-field'>
                            <div className='spacer-bottom'>
                                <NonIdealState                                    
                                        title="Nothing to see here..."
                                        description="No contacts have been created for this location"
                                />
                            </div>
                            <div class="button-row button-row-stacked-mobile spacer-top spacer-bottom">
                                <LinkButton intent="primary" text="Create external contacts" href="/external-contact" id="create-contacts-link" />
                            </div>
                        </div>
                     )}
                />
                <ShowHide
                    evaluator={!props.external}
                    show={(
                        <NotificationInline show={noUsers && selectedBusinessArea != null} allowClose={false} intent="warning" text="If there are no users then this could be due to either a delay in processing or there are no users with permissions to this location. Please check again at a later time or assign this location to the user you want to make a location contact or pick a different contact type." />
                    )}
                />

                <div className="clearfix">
                    <div className={classNames("pull-left  spacer-bottom")}>
                        <div className="inline-items">
                            <FormTextInput inputRef={inputSearchRef} placeholder="User Search" onChange={onSearchChange} value={searchTerm} large disabled={props.disabled || searching} icon="search" id="user-search-field" />
                        </div>
                    </div>
                </div>
                                
                <Fragment>
                    <FormCheckboxList
                        id="user-checkbox"
                        items={users}
                        onChange={onItemChange}
                        loading={props.loading}
                        dangerHelperText=""
                        disabled={props.disabled}
                        striped={true}
                    ></FormCheckboxList>

                    <ShowHide
                        evaluator={totalPages > 1}
                        show={(
                            <ListingPagination currentPage={pageNumber} totalPages={totalPages} loadingData={false} onPageChange={onPageChange} />
                        )}
                    />
                </Fragment>
            </div>  
        </Fragment>
                  
    );
}
LocationContactSettings.defaultProps = {
    saving: false,
    external: false
};

LocationContactSettings.propTypes = {
    contacts: PropTypes.object.isRequired,
    excludeContactTypes: PropTypes.array.isRequired,
    saving: PropTypes.bool,
    external: PropTypes.bool,
    loading: PropTypes.bool.isRequired,
    locationId: PropTypes.number.isRequired,
    onContactsUpdate: PropTypes.func.isRequired,
};
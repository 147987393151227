import React, { useState, useRef, useEffect, Fragment } from 'react';
import { Link } from "react-router-dom";
import { Intent, Icon } from '@blueprintjs/core';
import { useSelector } from 'react-redux';
import classNames from "classnames";

import { useDebounce } from 'hooks/useDebounce';

import { Tooltip } from 'components/tooltip';
import { Breadcrumbs } from 'components/navigation';
import { FormTextInput } from "components/form-fields";
import { LinkButton, Button, ButtonSave } from 'components/buttons'
import { ListingTable } from "components/listing";
import { ShowHide } from "components/layout";
import { AlertConfirm, NotificationToaster } from "components/notifications";
import { UserService, AccountService } from 'services';
import { DateToLocal } from "components/dates";
import { HelperFunctions } from 'helpers';
import { PageRestricted } from 'pages/errors/page-restricted';
import { UserAccess, UserHasAccess } from 'components/user-access';
import { Filter } from 'components/filtering';


export function UserListing(props) {

    const defaultRecordSize = 25;
    const debounceTimeout = 750;

    const [initalSetupComplete, setInitalSetupComplete] = useState(false);
    const [selectedUserId, setselectedUserId] = useState(0);
    const [totalRecords, setTotalRecords] = useState(0);
    const [rawTableData, setRawTableData] = useState([]);
    const [tableRows, setTableRows] = useState([]);
    const [pageNumber, setPageNumber] = useState(1);
    const [pageFilters, setPageFilters] = useState([]);
    const [pageSize, setPageSize] = useState(defaultRecordSize);
    const [searchTerm, setSearchTerm] = useState("");
    const [loadingData, setLoadingData] = useState(false);
    const [noDataMessage, setNoDataMessage] = useState("You have no users set up, please click 'Add new user' to get started.");
    const [tableHeaders, setTableHeaders] = useState(["Email Address", "First Name", "Last Name", "Role", "Created", "Last Updated", "Actions"]);
    const sortableHeaders = ["Email Address", "First Name", "Last Name", "Created", "Last Updated", "Role", "Restricted Actions"];
    const [tableSortBy, setTableSortBy] = useState("Email Address");
    const [tableSortDir, setTableSortDir] = useState("A");
    const [showDeleteConfirm, setShowDeleteConfirm] = useState(false);
    const [appliedFilters, setAppliedFilters] = useState([]);
    const [showFilters, setShowFilters] = useState(false);
    const [allowAdd, setAllowAdd] = useState(false);
    const debouncedSearchTerm = useDebounce(searchTerm, debounceTimeout);
    const inputSearchRef = useRef(null);
    const isInternal = UserHasAccess("Internal");
    const restrictedActionColumn = 4;
    const loggedInUserId = useSelector(state => state.loggedInUser.userId);
    const [recalculatingPrimaryContacts, setRecalculatingPrimaryContacts] = useState(false);

    const currentPageNumber = useRef();
    const currentPageSize = useRef();
    const currentSearchTerm = useRef();
    const currentTableSortBy = useRef();
    const currentTableSortDir = useRef();
    const currentAppliedFilters = useRef();

    const internalAction = "Internal";
    const requiredAction = "Users:Manage";
    const canAccessPage = UserHasAccess(requiredAction);

    useEffect(() => {
            setPageNumber(1);
            setNoDataMessage("No results found, please try a different search term.");
    }, [debouncedSearchTerm]);


    useEffect(() => {
        UserService.getRoles().then(function (roleList) {

            var mappedRoles = HelperFunctions.sortAlphabetically(roleList.map(function (role) {
                return { "id": role.key, "name": role.name }
            }), "name");


            var filterArray = [
                {
                    "name": "Role", "items": [
                        ...mappedRoles
                    ]
                },
                {
                    "name": "Status", "items": [
                        { "id": "Active", "name": "Active" },
                        { "id": "Pending", "name": "Pending" }
                    ]
                }
            ];

            setPageFilters(filterArray);

        });
    }, [])

    function fetchTableData(forceRefresh) {
        if (canAccessPage && initalSetupComplete) {

            if (currentPageNumber.current === pageNumber &&
                currentPageSize.current === pageSize &&
                currentSearchTerm.current === debouncedSearchTerm &&
                currentTableSortBy.current === tableSortBy &&
                currentTableSortDir.current === tableSortDir &&
                currentAppliedFilters.current === appliedFilters.length &&
                !forceRefresh
            ) {
                //If none of the values have changed, then 2 have been updated at once, so prevent the listing from performing another call.
                return;
            }

            currentPageNumber.current = pageNumber;
            currentPageSize.current = pageSize;
            currentSearchTerm.current = debouncedSearchTerm;
            currentTableSortBy.current = tableSortBy;
            currentTableSortDir.current = tableSortDir;
            currentAppliedFilters.current = appliedFilters.length;

            setLoadingData(true);
            UserService.getUserList(pageSize, pageNumber, debouncedSearchTerm, tableSortBy, tableSortDir, appliedFilters).then(function (r) {
                setRawTableData(r.data);
                setTotalRecords(r.totalCount);
                setLoadingData(false);
                setRecalculatingPrimaryContacts(false);
                setTimeout(function () {
                    if (inputSearchRef.current != null) {
                        inputSearchRef.current.focus();
                    }
                });
            });
        }
    }

    useEffect(() => {
        fetchTableData(false);
    }, [pageSize, pageNumber, tableSortBy, tableSortDir, debouncedSearchTerm, appliedFilters.length, initalSetupComplete]);

    useEffect(() => {

        let data = [];

        if (rawTableData != null) {
            data = rawTableData.map((d) => {

                var baseColumns = [
                    <Link to={`/user/${d.userId}`}>{d.emailAddress}</Link>,
                    d.firstName,
                    d.lastName,
                    d.role.name,
                    <DateToLocal>{d.createdDate}</DateToLocal>,
                    <DateToLocal>{d.modifyDate}</DateToLocal>,
                    <div className="button-row-small">
                        <ShowHide
                            evaluator={d.role.key !== "AccountOwner" && d.userId !== loggedInUserId}
                            show={(
                                <Button onClick={() => deleteUser(d.userId)} icon="trash" iconOnly={true} text="Delete User" large={false} minimal={true}></Button>
                            )}>
                        </ShowHide>
                        <ShowHide
                            evaluator={d.status === "Pending"}
                            show={(
                                <Tooltip content="User registration is incomplete">
                                    <Icon icon="time" />
                                </Tooltip>
                            )}>
                        </ShowHide>
                        
                    </div>];

                if(isInternal) {
                    baseColumns = HelperFunctions.insertIntoArray(baseColumns, restrictedActionColumn, d.restricted_Action_Count);
                }

                return baseColumns;
            })
        }

        setTableRows(data);
    }, [rawTableData, isInternal, loggedInUserId]);

    useEffect(() => {
        if(isInternal) {
            setTableHeaders(HelperFunctions.insertIntoArray(tableHeaders, restrictedActionColumn, "Restricted Actions"));
        }
    }, [isInternal]);

    useEffect(() => {
        AccountService.getGeneralSettings().then(function (accountSettings) {
            setAllowAdd(!accountSettings.ssoEnabled);
        });
    }, []);

    function onTableSort(header, direction) {
        setTableSortDir(direction);
        setTableSortBy(header);
    }

    function onSearchChange(item) {
        setSearchTerm(item.target.value);
    }

    function onPagingChange(newPageNumber, newPageSize) {
        setPageNumber(newPageNumber);
        setPageSize(newPageSize);
    }

    function deleteUser(userId) {
        setselectedUserId(userId);
        setShowDeleteConfirm(true);
    }

    function onDeleteConfirm() {
        setShowDeleteConfirm(false);

        UserService.deleteUser(selectedUserId).then(() => {
            NotificationToaster.show(Intent.SUCCESS, "User has been successfully deleted");
            fetchTableData(true);
            setselectedUserId(0);
        }, function () {
            NotificationToaster.show(Intent.DANGER, "Unable to delete the user, please try again.");
            setselectedUserId(0);
        });
    }

    function onDeleteCancel() {
        setselectedUserId(0);
        setShowDeleteConfirm(false);
    }

    function onToggleFilter() {
        setShowFilters((prevState) => {
            return !prevState;
        })
    }

    function onFilterChange(listingFilters) {
        setInitalSetupComplete(true);
        setPageNumber(1);
        setNoDataMessage("No results found, please try a different filter.");
        setAppliedFilters(listingFilters);

        if (listingFilters.length > appliedFilters.length) {
            setShowFilters(true);
        }
    }

    function recalculatePrimaryContacts() {
        setRecalculatingPrimaryContacts(true);
        UserService.recalculatePrimaryContacts().then(function () {
            fetchTableData(true);
            NotificationToaster.show(Intent.SUCCESS, "Primary contacts has been successfully recalculated.");
        }, function () {
            setRecalculatingPrimaryContacts(false);
            NotificationToaster.show(Intent.DANGER, "Unable to recalculate the primary contacts, please try again.");
        });
    }

    return (
        <UserAccess perform={requiredAction}
            yes={() => (
                <div className="row">

                    <Breadcrumbs items={props.breadcrumbs} />

                    <h1>User Management</h1>
                    <div className="intro-text">
                        <p>From here you can manage your users</p>
                    </div>

                    <div className="button-row button-row-stacked-mobile spacer-bottom">
                        <div>
                        <ShowHide
                            evaluator={allowAdd}
                            show={(
                                <LinkButton intent="primary" text="Add new user" href="/user/create" id="new-user" />
                            )}
                        >

                        </ ShowHide>
                        <UserAccess perform={internalAction}
                            yes={() => (
                                <ButtonSave 
                                    text="Recalculate primary contacts" 
                                    intent="primary" 
                                    onClick={recalculatePrimaryContacts}
                                    disabled={recalculatingPrimaryContacts}
                                    icon="refresh"
                                />
                                )}
                        />
                        </div>
                    </div>
                   
                    <ShowHide
                        evaluator={tableRows.length === 0 && !loadingData && debouncedSearchTerm === "" && appliedFilters.length === 0 && initalSetupComplete}
                        hide={(
                                <Fragment>
                                <div className={classNames("pull-left", { "spacer-bottom": !showFilters })}>
                                <div className="inline-items">
                                        <FormTextInput inputRef={inputSearchRef} placeholder="User Search" onChange={onSearchChange} value={searchTerm} large disabled={loadingData} icon="search" id="user-search-field" />
                                        <Tooltip content="Filter" position="right">
                                            <Button icon="filter" onClick={onToggleFilter} className={classNames({ "active": appliedFilters.length > 0 })} />
                                        </Tooltip>
                                    </div>
                                </div>
                                <Filter filterName="users" visible={showFilters} filters={pageFilters} onUpdate={onFilterChange} />
                            </Fragment>
                        )}
                    />


                    <ListingTable
                        id="listing-table-user"
                        headers={tableHeaders}
                        data={tableRows}
                        totalRecordCount={totalRecords}
                        onPagingChange={onPagingChange}
                        loadingData={loadingData}
                        noDataMessage={noDataMessage}
                        sortable
                        sortableHeaders={sortableHeaders}
                        sortedBy={tableSortBy}
                        sortedDir={tableSortDir}
                        onSort={onTableSort}
                    />

                    <AlertConfirm
                        title="Please confirm"
                        isOpen={showDeleteConfirm}
                        onConfirm={onDeleteConfirm}
                        onCancel={onDeleteCancel}
                    >
                        <p>Are you sure you want to delete this user?</p>
                    </AlertConfirm>
            
                </div>
            )}
            no={() => (
                <PageRestricted />
            )}
        />
    );

}
import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import 'components/navigation/Navigation.css';
import { Icon } from '@blueprintjs/core';

export function Tabs(props) {

    function onTabClick(tab, index) {

        if (tab.disabled === true) {
            return;
        }

        props.onClick(index);

        if (props.vertical) {
            window.scrollTo(0, 0);
        }
    }

    return (
        <div className={classNames("bp3-tabs", { "bp3-horizontal": !props.vertical }, { "bp3-vertical": props.vertical })}>
            <ul role="tablist" className={classNames("bp3-tab-list", { "bp3-skeleton": props.loading })}>
                {props.tabs.map((l, index) => {
                    return (
                        <li
                            className={classNames("bp3-tab", { "bp3-skeleton": props.loading, "tab-disabled": l.disabled })}
                            key={l.title}
                            role="tab"
                            aria-selected={l.selected}
                            onClick={() => onTabClick(l, index)}
                        >
                            {l.icon ? <div className='center-icon'>
                                {l.title} <Icon icon="comment" iconSize={12} />
                            </div> : l.title}
                        </li>
                    )
                })}
            </ul>
        </div>
    );
}

Tabs.defaultProps = {
    loading: false,
    vertical: false
};

Tabs.propTypes = {
    tabs: PropTypes.array.isRequired,
    onClick: PropTypes.func.isRequired,
    loading: PropTypes.bool,
    vertical: PropTypes.bool
};

import React, { useState, useEffect, Fragment } from 'react';
import axios from 'axios';
import { Alignment, Intent } from '@blueprintjs/core';
import { AlertService, UserService } from 'services';
import { NotificationToaster, AlertUnsavedData, NotificationInline } from "components/notifications";
import { FormSwitch, FormSwitchList } from 'components/form-fields';
import { ButtonSave } from "components/buttons";
import { useDirtyData } from "hooks/useDirtyData";
import { HelperFunctions } from 'helpers';
import { UserAccess, UserHasAccess } from 'components/user-access';
import { ShowHide } from 'components/layout';
import { PageRestricted } from 'pages/errors/page-restricted';

var _ = require('underscore');

export function UserPreferences(props) {

    const [originalValues, setOriginalValues] = useState({});
    const [originalAlertValues, setOriginalAlertValues] = useState([]);
    const [preferences, setPreferences] = useState({
        visAuditReports: false,
        schedulingEmails: false,
        schedulingEmailsUpdatable: false,
        locationWithSingleSchedulingEmail:[]
    });
    const [saving, setSaving] = useState(false);
    const [loading, setLoading] = useState(true);
    const [warningMsg, setWarningMsg] = useState('');
    const [alertPreferenceSections, setAlertPreferenceSections] = useState([]);
    const isDataDirty = useDirtyData(originalValues, preferences);
    const isAlertDataDirty = useDirtyData(originalAlertValues, alertPreferenceSections);
    const isDriver = UserHasAccess("AuthorisedDriver");
    const maxNumberWarningLocationsDisplayed = 5;

    useEffect(() => {

        if (isDriver) {
            return;
        }

        if (props.userId != null && props.userId > 0) {

            var functionsToRun = [UserService.getUserPreferences(props.userId, props.editingOwnAccount)];

            if (props.editingOwnAccount) {
                functionsToRun.push(AlertService.getUserPreferences());
                functionsToRun.push(AlertService.getAlertTypes(false, false))
            }

            axios.all(functionsToRun).then(axios.spread(function (userPreferences, alertPreferences, alertTypes) {

                setPreferences(userPreferences);
                setOriginalValues(_.extend({}, userPreferences));

                if (props.editingOwnAccount && alertPreferences) {
                    var mappedAlertPreferences = alertPreferences.map(function (pref) {
                        return {
                            ...pref,
                            id: pref.alertTypeId,
                            checked: pref.enabled,
                            name: pref.title,
                            disabled: !alertTypes.find(x => x.alertTypeId === pref.alertTypeId)?.canBeDisabled
                        }
                    })

                    var groupedAlerts = AlertService.mapAlertGroupings(mappedAlertPreferences);

                    setAlertPreferenceSections(groupedAlerts);
                    setOriginalAlertValues(HelperFunctions.deepClone(groupedAlerts));
                }
                if (userPreferences?.locationWithSingleSchedulingEmail.length > 0) {
                    setWarningMessage(userPreferences.locationWithSingleSchedulingEmail);
                }

                setLoading(false);
            }), (error) => {
                setSaving(false);
                const badRequestCode = 400;
                if (error.response.status === badRequestCode) {
                    NotificationToaster.show(Intent.WARNING, error.response.message);
                } else {
                    NotificationToaster.show(Intent.DANGER, "Could not get preferences. Please try again.");
                }
            });
        }
    }, [props.userId]);

    function handleToggleChange(event, alert) {

        var tempPreferences = {
            ...preferences
        };

        tempPreferences[alert] = event.target.checked
        setPreferences(tempPreferences);
    }

    function setWarningMessage(locations) {
        var locationText = locations.length > 1 ? "locations" : "location";
        var conjunctiveText = locations.length > 1 ? "are " : "is ";
        var warningMessage = `Scheduling cannot be removed because this user is the only recipient for ${locations.length} ${locationText}.`;

        var fiveLocations = locations.slice(0, maxNumberWarningLocationsDisplayed);
        var locationList = `The ${locationText} ${(locations.length > maxNumberWarningLocationsDisplayed ? "include " : conjunctiveText)} ${fiveLocations.join(", ")}`;
        setWarningMsg(warningMessage + locationList);
    }

    function handleAlertToggleChange(event) {
        var tempAlertPreference = [...alertPreferenceSections];
        var toggleId = parseInt(event.currentTarget.getAttribute('identifier'));

        tempAlertPreference.forEach(s => s.sectionAlerts.map(p => {
            if (p.id === toggleId) {
                p.enabled = event.target.checked;
                p.checked = event.target.checked;
            }
        }));

        setAlertPreferenceSections(tempAlertPreference);
    }

    function saveSuccess() {
        setSaving(false);
        //Shouldn't matter if we set both of these regardless of what we saved
        setOriginalValues(_.extend({}, preferences));
        setOriginalAlertValues(HelperFunctions.deepClone(alertPreferenceSections));
        NotificationToaster.show(Intent.SUCCESS, "User updated successfully.");
    }

    function saveUser() {
        setSaving(true);

        var savingFunctions = [];

        if (isDataDirty) {
            savingFunctions.push(UserService.saveUserPreferences(props.userId, preferences, props.editingOwnAccount));
        }

        if (isAlertDataDirty) {
            var disabledIds = [];

            alertPreferenceSections.forEach(s => s.sectionAlerts.map(p => {
                if (!p.checked && !disabledIds.includes(p.id)) {
                    disabledIds.push(p.id);
                }
            }));

            savingFunctions.push(AlertService.saveUserPreferences(disabledIds));
        }

        if (savingFunctions.length > 0) {
            axios.all(savingFunctions).then(() => {
                saveSuccess();
            }, () => {
                setSaving(false);
                NotificationToaster.show(Intent.DANGER, "Could not update the user. Please try again.");
            });
        } else {
            saveSuccess();
        }
    }

    return (
        <Fragment>
            <ShowHide
            evaluator={!isDriver}
            show={(

            <div className="row">
                <h2>User Preferences</h2>
                <div className="intro-text">
                    <p>Here you can update your user preferences and customise your settings.</p>
                </div>

                <h3>Email Notifications</h3>
                    <NotificationInline
                        id="notification-scheduling-emails-disabled"
                        loading={props.loading}
                        show={!preferences.schedulingEmailsUpdatable && preferences.schedulingEmails === true}
                        text={warningMsg}
                        intent="info"
                        allowClose={false}>
                    </NotificationInline>
                    <div className="switchlist form-field">

                        <FormSwitch
                            disabled={saving}
                            loading={loading}
                            label="Audits and Inspection reports"
                            checked={preferences.visAuditReports}
                            onChange={(event) => handleToggleChange(event, "visAuditReports") }
                            inline={true}
                            alignment={Alignment.RIGHT}
                        />
                        <FormSwitch
                            disabled={saving || ( !preferences.schedulingEmailsUpdatable && preferences.schedulingEmails === true)}
                            loading={loading}
                            label="Scheduling"
                            checked={preferences.schedulingEmails}
                            onChange={(event) => handleToggleChange(event, "schedulingEmails")}
                            inline={true}
                            alignment={Alignment.RIGHT}
                        />
                        <UserAccess perform={"WalkaroundCheck:View"}
                            yes={() => (
                                <FormSwitch
                                    disabled={saving}
                                    loading={loading}
                                    label="Receive all walkaround check emails"
                                    checked={preferences.walkaroundEmails}
                                    onChange={(event) => handleToggleChange(event, "walkaroundEmails")}
                                    inline={true}
                                    alignment={Alignment.RIGHT}
                                />
                            )}
                        />
                        
                    </div>
                
                <div className="data-table-header">
                {
                        alertPreferenceSections.map((prefSection) => {
                            return (
                                <FormSwitchList
                                    items={prefSection.sectionAlerts}
                                    onChange={handleAlertToggleChange}
                                    headingText={prefSection.sectionName}
                                    loading={loading}
                                    switchListName="alertPreferencesList"
                                    disabled={saving}
                                    dangerHelperText=""
                                    headingLevel="h3"
                            />
                        );
                    })
                }
                </div>

                <AlertUnsavedData
                    isDirty={isDataDirty || isAlertDataDirty}>
                </AlertUnsavedData>

                <ButtonSave
                    id="user-preferences-save"
                    onClick={saveUser}
                    disabled={saving}
                    loading={loading}
                ></ButtonSave>

            </div>
            )}
            />
            <ShowHide
                evaluator={isDriver}
                show={(
                    <PageRestricted />
                )}
            />
        </Fragment>
    );
}
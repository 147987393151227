import React, { useEffect, Fragment, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useLocation } from 'react-router-dom'
import './Layout.css';
import { AccountService, AlertService, UserService, EmployeeService, AssetService } from 'services';
import { ButtonList } from 'components/button-list';
import { Menu, MenuItem, MenuDivider, Icon, Intent, Tag } from "@blueprintjs/core";
import { InternalMenuItem } from 'components/navigation';
import { AccountSwitcher } from 'components/notifications/AccountSwitcher';
import { UserAccess, UserHasAccess } from 'components/user-access/UserAccess';
import { Button } from 'components/buttons/Button';
import { FormSuggest } from 'components/form-fields/FormSuggest';
import { ButtonSave } from 'components/buttons/ButtonSave';
import axios from 'axios';
import { NotificationToaster } from 'components/notifications/NotificationToaster';
import classNames from 'classnames';
import { ShowHide } from './ShowHide';
import { CustomIcon } from 'components/icon/CustomIcon';

export function TopBar(props) {

    const dispatch = useDispatch();
    const displayName = useSelector(state => state.loggedInUser.displayName);
    const preventSwitch = useSelector(state => state.dirtyData.dirty);
    const tryingToSwitch = useSelector(state => state.dirtyData.attemptingRedirect);
    const allowDirtySwitch = useSelector(state => state.dirtyData.allowRedirect);
    const dirtyTask = useSelector(state => state.dirtyData.task);
    const accountName = useSelector(state => state.loggedInUser.memberName);
    const memberNumber = useSelector(state => state.loggedInUser.memberNumber);
    const isInternal = UserHasAccess("Internal");
    const [switchableAccounts, setSwitchableAccounts] = useState([]);
    const [showAccountSwitcher, setShowAccountSwitcher] = useState(false);
    const [selectedAccount, setSelectedAccount] = useState(0);
    const [attemptingSwitch, setAttemptingSwitch] = useState(false);
    const [accountsLoading, setAccountsLoading] = useState(true);
    const [alertTypes, setAlertTypes] = useState([]);
    const [alertCount, setAlertCount] = useState(0);
    const [loadingAlerts, setLoadingAlerts] = useState(true);
    const [loadingEmployeeHoldingArea, setLoadingEmployeeHoldingArea] = useState(true);
    const [loadingAssetHoldingArea, setLoadingAssetHoldingArea] = useState(true);
    const [employeeHoldingAreaCount, setEmployeeHoldingAreaCount] = useState(0);
    const [assetHoldingAreaCount, setAssetHoldingAreaCount] = useState(0);
    const isDriver = UserHasAccess("AuthorisedDriver");
    const canSeeHomePage = UserHasAccess("HoldingArea:View");

    const location = useLocation();

    useEffect(() => {
        dispatch({ type: 'SITE_DEFAULT_WIDTH' });
    }, [location]);

    const maxAlertCount = 999;

    useEffect(() => {
        axios.all([
            AccountService.getAllAccounts(),
            UserService.getAllAccounts(isInternal || isDriver),
        ]).then(axios.spread(function (getAllAccountsReponse, accountAccessReponse) {

            var mappedAccounts = getAllAccountsReponse.data.map(function (account) {
                account.name = `${account.memberNumber} - ${account.name}`;
                return account;
            });

            if (accountAccessReponse) {
                if (!isInternal && accountAccessReponse.canSwitchAccount && !accountAccessReponse.switchAllAccounts) {
                    mappedAccounts = mappedAccounts.filter(c => accountAccessReponse.accountIds.find(a => a === c.id) != null);
                }
            }

            setSwitchableAccounts(mappedAccounts.filter(c => c.memberNumber !== memberNumber));

            setAccountsLoading(false);
        }));

        const alertsReloadTime = 300000; //5 * 60 * 1000 - 5 minutes

        loadAlerts();

        const alertBellInterval = setInterval(() => {
            loadAlerts();
        }, alertsReloadTime);
        return () => clearInterval(alertBellInterval);
    }, []);

    useEffect(() => {
        if (allowDirtySwitch === true && tryingToSwitch === true) {

            switch (dirtyTask) {

                case "SWITCHACCOUNT":
                    confirmAccountSwitch();
                    break;
                case "LOGOUT":
                    onLogout();
                    break;
                default:
                    break;
            }

        }
    }, [allowDirtySwitch, tryingToSwitch]);

    useEffect(() => {
        setAlertCount(alertTypes
            .map(x => x.alertCount)
            .reduce((partialSum, a) => partialSum + a, 0));
    }, [alertTypes])

    function onLogout() {

        if (preventSwitch === true && allowDirtySwitch !== true) {
            dispatch({ type: 'BEGIN_REDIRECT', payload: "LOGOUT" });
            return;
        }

        UserService.signOut();
    }

    function loadAlerts() {
        loadAlertBell();
        if (canSeeHomePage) {
            loadHoldingArea();
        }
    }

    function loadAlertBell() {
        AlertService.getAlertTypes(true, true)
            .then(newAlertTypes => {
                const formattedAlertTypes = newAlertTypes
                    .filter(x => x.alertCount > 0)
                    .map(x => formatAlertType(x));

                setAlertTypes(formattedAlertTypes);

                setLoadingAlerts(false);
            }, () => {
                NotificationToaster.show(Intent.DANGER, "Could not load your alert data. Please try again.");
            });
    }

    function loadHoldingArea() {
        setLoadingEmployeeHoldingArea(true);
        setLoadingAssetHoldingArea(true);

        EmployeeService.GetHoldingAreaCounts()
            .then(result => {
                setEmployeeHoldingAreaCount(result.count);
                setLoadingEmployeeHoldingArea(false);
            }, () => {
                NotificationToaster.show(Intent.DANGER, "Could not load your employee holding area data. Please try again.");
            });

        AssetService.GetHoldingAreaCounts()
            .then(result => {
                setAssetHoldingAreaCount(result.count);
                setLoadingAssetHoldingArea(false);
            }, () => {
                NotificationToaster.show(Intent.DANGER, "Could not load your asset holding area data. Please try again.");
            });
    }

    function formatAlertType(alertType) {
        const formattedAlertType = {
            ...alertType,
            formattedTitle: formatAlertTypeTitle(alertType)
        };

        if (alertType.storedInLegacySystem) {
            formattedAlertType.url = `${window.env.VISION_URL}#/Alerts/${alertType.alertTypeId}`;
        } else {
            formattedAlertType.url = `/alert/${alertType.key}`;
        }

        return formattedAlertType;
    }

    function showAccountSelector() {
        setShowAccountSwitcher(true);
    }

    function hideAccountSelector() {
        setShowAccountSwitcher(false);
    }

    function confirmAccountSwitch() {
        if (selectedAccount !== 0 && selectedAccount != null) {
            setAttemptingSwitch(true);

            UserService.switchAccount(selectedAccount).then(() => {
                hideAccountSelector();
                window.location.reload();
            },
                (error) => {
                    NotificationToaster.show(Intent.DANGER, "You do not have permission to switch to this account.");
                    setAttemptingSwitch(false);
                    hideAccountSelector();

                    const noAccessCode = 403;

                    if (!isInternal && error.status === noAccessCode) {
                        var mappedAccounts = switchableAccounts;
                        mappedAccounts = mappedAccounts.filter(c => c.id !== selectedAccount);
                        setSwitchableAccounts(mappedAccounts);
                    }
                });
        }
    }

    function onAccountSelect(item) {
        setSelectedAccount(item.id);
    }

    function formatAlertTypeTitle(alertType) {
        const countKey = '{0}';
        let title = alertType.textTemplateSingle.replace(countKey, alertType.alertCount);

        if (alertType.alertCount > 1) {
            title = alertType.textTemplateMultiple.replace(countKey, alertType.alertCount);
        }

        let iconColour = '';

        if (alertType.iconColour?.toLowerCase() === 'concern') {
            iconColour = 'FFC107';
        } else if (alertType.iconColour?.toLowerCase() === 'warning') {
            iconColour = 'FF7454';
        } else if (alertType.iconColour?.toLowerCase() === 'danger') {
            iconColour = 'EC2D6D';
        }

        return <span><i className={classNames('fa', alertType.icon, 'alert-type-icon')} style={{ color: iconColour ? `#${iconColour}` : 'inherit' }} /> {title}</span>;
    }

    const menu = (
        <Menu>
            <InternalMenuItem text="Profile & Settings" location="/user/profile" />
            <MenuDivider />
            <InternalMenuItem text="Beta Features" location="/beta-features" />
            <MenuDivider />
            <MenuItem text="Logout" onClick={onLogout} />
        </Menu>);

    return (
        <div id="top-bar" className={props.environment}>
            <div className="bar-layout">
                <div>
                    <img alt="Vision logo" id="logo" src={props.environment !== "" ? "/images/vision-logo-white.svg" : "https://vision.fta.co.uk/Content/images/svg/vision-logo.svg"} />
                </div>
                <div className="bar-right-items">
                    <div className="top-bar-item">
                        <ShowHide
                            evaluator={props.environment !== ''}
                            show={(
                                <div className="top-bar-item environment-identifier">
                                    <span>{props.environment} environment</span>
                                </div>
                            )}
                        />
                    </div>
                <div className="top-bar-item">
                    <div className='alert-container inline-items'>
                        <ShowHide
                            evaluator={canSeeHomePage}
                            show={
                                <div className="inline-item">
                                    <ButtonList
                                        id="holding-area-menu"
                                        minimal={true}
                                        large={false}
                                        text={<CustomIcon iconName="holding-area" className="holding-area-icon" />}
                                        disabled={loadingAssetHoldingArea || loadingEmployeeHoldingArea}
                                        menuItems={(
                                            <Menu>
                                                <Fragment>
                                                    <InternalMenuItem
                                                        nodeType='a'
                                                        forceRedirect={true}
                                                        text={<span><i className={classNames('fa', 'fa-user', 'alert-type-icon')} style={{ color: employeeHoldingAreaCount > 0 ? '#EC2D6D' : '' }} /> {employeeHoldingAreaCount} new employee{employeeHoldingAreaCount === 1 ? '' : 's'}</span>}
                                                        location={`${window.env.VISION_URL}#/Alerts/NEWDRIVER`}
                                                        className="bp3-menu-item" />
                                                    <MenuDivider />
                                                    <InternalMenuItem
                                                        nodeType='a'
                                                        forceRedirect={true}
                                                        text={<span><i className={classNames('fa', 'fa-truck', 'alert-type-icon')} style={{ color: assetHoldingAreaCount > 0 ? '#EC2D6D' : '' }} /> {assetHoldingAreaCount} new asset{assetHoldingAreaCount === 1 ? '' : 's'}</span>}
                                                        location={`${window.env.VISION_URL}#/Alerts/NEWVEHICLE`}
                                                        className="bp3-menu-item" />
                                                </Fragment>
                                            </Menu>)}
                                    />
                                    <ShowHide
                                        evaluator={!loadingAssetHoldingArea && !loadingEmployeeHoldingArea && employeeHoldingAreaCount + assetHoldingAreaCount > 0}
                                        show={
                                            <Tag intent={Intent.DANGER} className="alert-count">
                                                {employeeHoldingAreaCount + assetHoldingAreaCount > maxAlertCount ? '999+' : employeeHoldingAreaCount + assetHoldingAreaCount}
                                            </Tag>
                                        }
                                    />
                                    <ShowHide
                                        evaluator={loadingAssetHoldingArea || loadingEmployeeHoldingArea}
                                        show={
                                            <div className="alert-count-load">
                                                <Icon icon="refresh" iconSize={12} />
                                            </div>
                                        }
                                    />
                                </div>
                            }
                        />
        <div className="inline-item">
            <ButtonList
                id="alert-bell-menu"
                minimal={true}
                large={false}
                text={<i className='fa fa-bell alert-bell-icon' />}
                disabled={loadingAlerts}
                menuItems={(
                    <Menu>
                        {alertTypes.map(x => {
                            return (
                                <Fragment key={x.alertTypeId}>
                                    <InternalMenuItem
                                        nodeType={x.storedInLegacySystem ? 'a' : 'MenuItem'}
                                        forceRedirect={true}
                                        text={x.formattedTitle}
                                        location={x.url}
                                        className="bp3-menu-item" />
                                    <MenuDivider />
                                </Fragment>
                            );
                        })}
                        <InternalMenuItem text={'View all alerts'} location='/alert-type' className='bold' />
                    </Menu>)}
            />
            <ShowHide
                evaluator={!loadingAlerts && alertCount > 0}
                show={
                    <Tag intent={Intent.DANGER} className="alert-count">
                        {alertCount > maxAlertCount ? '999+' : alertCount}
                    </Tag>
                }
            />
            <ShowHide
                evaluator={loadingAlerts}
                show={
                    <div className="alert-count-load">
                        <Icon icon="refresh" iconSize={12} />
                    </div>
                }
            />
        </div>

                        </div>
                    </div>
                    <div className="top-bar-item">
                        <UserAccess perform="Account:Switch"
                            yes={() => (
                                <Button text={`${accountName} - ${memberNumber}`} minimal={true} large={false} onClick={showAccountSelector} />
                            )}
                            no={() => (
                                <Fragment>
                                    {accountName} - {memberNumber}
                                </Fragment>
                            )}
                        />
                    </div>
                    <div className="top-bar-item">
                        <ButtonList text={(<Fragment>{displayName}  <Icon icon="chevron-down" /></Fragment>)} id="user-menu" icon="person" minimal={true} large={false} menuItems={menu} />
                    </div>
                </div>
            </div>
            <AccountSwitcher isOpen={showAccountSwitcher} onClose={hideAccountSelector}>
                <Fragment>
                    <div className="form-field-full">
                        <FormSuggest
                            loading={accountsLoading}
                            disabled={false}
                            items={switchableAccounts}
                            onItemSelect={onAccountSelect}
                            placeholder="Select account"
                            headingText="Please select which account you would like to switch to:"
                            selectedValue={selectedAccount}
                        />
                    </div>
                    <ButtonSave text="Confirm" icon={null} disabled={attemptingSwitch} loading={accountsLoading} intent="primary" onClick={confirmAccountSwitch} />
                </Fragment>
            </AccountSwitcher>
        </div>
    );
}
import classNames from 'classnames';
import { StatusHeader } from 'components/headers';
import { ShowHide } from 'components/layout';
import PropTypes from 'prop-types';
import React, { Fragment, useEffect, useState } from 'react';
import { OcrLayout } from '.';

export function OcrSection(props) {
    const [tooltip, setTooltip] = useState('');
    const [status, setStatus] = useState('');

    var trueValues = ['true', 'bysite', 'analoguecharts', 'logisticsuk', 'mixedmethods'];

    useEffect(() => {
        if (props.section) {
            const [newStatus, newTooltip] = getStatus(props.section.businessLogicResult, props.section.usePassFail, props.section.sectionComplete);

            setStatus(newStatus);
            setTooltip(newTooltip);
        }
    }, [props.section])

    function getStatus(businessLogicResult, usePassFail, sectionComplete = true) {
        const formattedBusinessLogicResult = businessLogicResult ? businessLogicResult.toUpperCase() : '';

        if (sectionComplete) {
            if (usePassFail) {
                switch (formattedBusinessLogicResult) {
                    case 'TRUE':
                        return ['SUCCESSFUL', 'The section has passed.'];
                    case 'FALSE':
                        return ['FAILED', 'The section has failed.'];
                    case 'NA':
                        return ['NA', 'The section has N/A Status.'];
                    default:
                        return ['', `The section has been completed but the status for the section is still pending.`];
                }
            }
            else {
                return ['SUCCESSFUL', 'The section is complete.'];
            }

        } else {
            if (usePassFail) {
                switch (formattedBusinessLogicResult) {
                    case 'TRUE':
                        return ['SUCCESSFUL-NOT-COMPLETE', 'The section has passed but is not complete.'];
                    case 'FALSE':
                        return ['FAILED-NOT-COMPLETE', 'The section has failed but is not complete.'];
                    default:
                        return ['', `The section has not been completed and it's status for the section is still pending.`];
                }
            }
            else {
                return ['SUCCESSFUL-NOT-COMPLETE', 'The section is not complete.'];
            }

        }
    }

    function renderLayouts() {
        if (props.section?.layouts) {
            return props.section.layouts
                .filter(layout => shouldShowLayout(layout))
                .sort(function (a, b) {
                    return a.layoutOrder - b.layoutOrder;
                })
                .map(layout => {
                    const [layoutStatus] = layout.businessLogicRequired ?
                        getStatus(layout.businessLogicResult, props.section.usePassFail) :
                        [''];

                    let layoutTooltip = '';

                    if (props.section.usePassFail) {
                        switch (layoutStatus) {
                            case 'SUCCESSFUL':
                                layoutTooltip = 'The subsection has passed.';
                                break;
                            case 'FAILED':
                                layoutTooltip = 'The subsection has failed.';
                                break;
                            case 'NA':
                                layoutTooltip = 'The subsection is not applicable.';
                                break;
                            default:
                                layoutTooltip = 'The subsection status is still pending. Please make sure the subsection is complete and then save the section to refresh the status.';
                                break;
                        }
                    }

                    return (
                        <OcrLayout
                            key={layout.sectionLayoutId}
                            loading={props.loading}
                            layout={layout}
                            status={layoutStatus}
                            statusTooltip={layoutTooltip}
                            onChange={onLayoutChange}
                            employees={props.employees}
                            assets={props.assets}
                            disabled={props.saving}
                            readonly={props.readonly}
                            previsit={props.previsit}
                        />
                    );
                });
        }

        return <Fragment />;
    }

    function isTrueAnswer(answer) {
        return trueValues.indexOf(answer?.toLowerCase()) > -1;
    }

    function shouldShowLayout(layout) {
        //if dependancy is A->B->C->D Then D should only show if C, B and A are true
        if (!layout.dependencyLayoutId) {
            return true;
        }

        var shouldShow = true;
        var moreDepencies = true;
        var dependencyLayoutId = layout.dependencyLayoutId;
        while (moreDepencies && shouldShow) {
            const dependentLayout = getDependentLayout(dependencyLayoutId);

            if (!isTrueAnswer(dependentLayout.answer)) {
                shouldShow = false;
            }
            if (dependentLayout.dependencyLayoutId) {
                dependencyLayoutId = dependentLayout.dependencyLayoutId;
            }
            else {
                moreDepencies = false;
            }
        }

        return shouldShow;
    }

    function getDependentLayout(dependencyLayoutId) {
        return props.section.layouts
            .find((sectionLayout) => sectionLayout.sectionLayoutId === dependencyLayoutId);
    }

    function doesSectionHaveLayouts() {
        return !!props.section && !!props.section.layouts && props.section.layouts.length > 0;
    }

    function getDummySection() {
        return <Fragment>
            <div className='spacer-bottom'>
                <h2 className='bp3-skeleton'>Section 1</h2>
            </div>
            <div className='spacer-bottom'>
                <h2 className='bp3-skeleton'>Section 2</h2>
            </div>
            <div className='spacer-bottom'>
                <h2 className='bp3-skeleton'>Section 3</h2>
            </div>
        </Fragment>;
    }

    function onLayoutChange(newLayout) {
        const clonedLayouts = [...props.section.layouts];

        const layoutIndex = clonedLayouts.findIndex((layout) => layout.sectionLayoutId === newLayout.sectionLayoutId);
        clonedLayouts[layoutIndex] = newLayout;

        props.onSectionChange({
            ...props.section,
            layouts: clonedLayouts
        })
    }

    return <div className={classNames({ 'print-page-break-avoid': props.step !== 0 })}>
        <ShowHide
            evaluator={props.loading && !props.section?.layouts}
            show={getDummySection()}
            hide={props.section &&
                <Fragment>
                    <div className='spacer-bottom'>
                        <StatusHeader text={props.section.sectionTitle} status={status} showStatus={!!status} tooltip={tooltip} />
                    </div>
                    <ShowHide
                        evaluator={doesSectionHaveLayouts()}
                        show={renderLayouts()}
                        hide={<div className="spacer-bottom">Section has not been implemented yet.</div>}
                    />

                </Fragment>}
        />
    </div>;
}

OcrSection.defaultProps = {
    saving: false,
    loading: false,
    readonly: false,
    previsit: {}
}

OcrSection.propTypes = {
    employees: PropTypes.array.isRequired,
    assets: PropTypes.array.isRequired,
    step: PropTypes.number.isRequired,
    onSectionChange: PropTypes.func.isRequired,
    section: PropTypes.object.isRequired,
    saving: PropTypes.bool,
    loading: PropTypes.bool,
    readonly: PropTypes.bool,
    previsit: PropTypes.object
};
import React from "react";
import { useParams } from "react-router-dom";
import { AssetCreateSettings } from "pages/asset/shared";

export function AssetCreate(props) {

    const { businessArea } = useParams();

    return (
        <AssetCreateSettings breadcrumbs={props.breadcrumbs} businessArea={businessArea} />
    );
}
import React, { Fragment, useState, useEffect } from 'react';
import { Route, Switch, Redirect, useParams } from 'react-router-dom';
import { NotificationInline } from 'components/notifications';
import { LinkButton } from 'components/buttons';
import { VerticalLinkGroup, Breadcrumbs } from 'components/navigation';
import { ShowHide } from 'components/layout';
import { UserAccess, UserHasAccess } from 'components/user-access';
import { PageRestricted } from 'pages/errors/page-restricted';
import { ScheduleEdit } from 'pages/schedules/edit/general-settings';
import { ScheduleRuns } from 'pages/schedules/edit/runs';
import { SelfReportingService } from 'services';
import { ScheduleErrors } from './schedule-errors';
import { ScheduleRunView } from 'pages/schedules/edit/runs/view';

var _ = require('underscore');

export function Schedule(props) {
    const { id } = useParams();
    const [invalidSchedule, setInvalidSchedule] = useState(false);
    const [loadingData, setLoadingData] = useState(true);

    const requiredAction = "ScheduleReports:Manage";
    const canAccessPage = UserHasAccess(requiredAction);

    const routes = [
        { location: `/schedule/${id}/general-settings`, title: "Schedule Settings" },
        { location: `/schedule/${id}/runs`, title: "Previous Runs" }
    ];

    useEffect(() => {

        if (!canAccessPage) {
            return;
        }

        if (loadingData) {
            SelfReportingService.getSchedule(id).then(r => {
                setLoadingData(false);
            }, (error) => {
                const noAccessCode = 403;

                if (error.status === noAccessCode) {
                    setInvalidSchedule(true);
                }
            });
        }

        setLoadingData(false);

    }, [loadingData, id]);

    return (


        <UserAccess perform={requiredAction}
            yes={() => (

                <ShowHide
                    evaluator={invalidSchedule}
                    show={(
                        <div className="row">
                            <Breadcrumbs items={props.breadcrumbs} />
                            <NotificationInline
                                allowClose={false}
                                show
                                text="You do not have permission to view this schedule"
                                intent="danger">
                            </NotificationInline>
                            <div>
                                <LinkButton intent="primary" text="Back to schedules" href="/schedule" id="return-schedule-listing" />
                            </div>
                        </div>
                    )}
                    hide={(
                        <Fragment>
                            <div className="row">
                                <Breadcrumbs items={props.breadcrumbs} />
                                <h1>Edit Schedule</h1>
                                <div className="intro-text">
                                    <p>Here you can configure your settings for your report schedule.</p>
                                </div>
                            </div>

                            <div className="layout-cols">
                                <div className="col side-col">
                                    <div className="row">
                                        <VerticalLinkGroup links={routes} exactRouteMatch={false}></VerticalLinkGroup>
                                    </div>
                                </div>
                                <div className="col content-col">

                                    <Switch>

                                        <Route path="/schedule/:id(\d+)/general-settings">
                                            <ScheduleEdit scheduleId={id} />
                                        </Route>
                                        <Route path="/schedule/:id(\d+)/runs/:runId(\d+)">
                                            <ScheduleRunView scheduleId={id} />
                                        </Route>
                                        <Route path="/schedule/:id(\d+)/runs">
                                            <ScheduleRuns scheduleId={id} />
                                        </Route>
                                        <Route path="/schedule/:id(\d+)/errors">
                                            <ScheduleErrors scheduleId={id} />
                                        </Route>
                                        <Redirect exact from="/schedule/:id(\d+)" to="/schedule/:id(\d+)/general-settings" />
                                    </Switch>
                                </div>
                            </div>
                        </Fragment>
                    )}
                />
            )}
            no={() => (
                <PageRestricted />
            )}
        />
    );

}
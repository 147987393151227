import React, { Fragment, useState, useEffect } from 'react';
import PropTypes from 'prop-types';

import { FormLocationSelector } from 'components/form-fields';
import { RiskService } from 'services/RiskService';
import { ListingPagination } from 'components/listing';
import { UserService } from 'services';
import { Intent, Position } from '@blueprintjs/core';
import { useDispatch, useSelector } from 'react-redux';
import { SetFiltering } from 'state/actions';
import { NotificationToaster } from 'components/notifications';
import axios from 'axios';
import { HelperFunctions } from 'helpers';

export function RiskSelector(props) {

    const dispatch = useDispatch();
    const [loadingSearchSettings, setLoadingSeachSettings] = useState(true);
    const [periodNumber, setPeriodNumber] = useState(0);
    const [totalPeriods, setTotalPeriods] = useState(1);
    const [selectedLocations, setSelectedLocations] = useState([]);
    const [locationList, setLocationList] = useState({});
    const [locationsChanged, setLocationsChanged] = useState(false);
    const cachedSearch = useSelector(state => state.filters["riskSearch"]);

    function init(setLocationsFromApi, search) {
        //Fetch locations and risk overview

        axios.all([UserService.getLoggedInUserLocations(1, true, true), RiskService.getRiskStatus()]).then(axios.spread(function (locationsResponse, statusResponse) {

            var latestPeriod = statusResponse.lastCompletePeriod ?? 0;
            var currentSelectedLocations = null;
            var currentSelectedPeriod = null;

            if (setLocationsFromApi) {
                currentSelectedLocations = mapLocationList(locationsResponse);
                currentSelectedPeriod = latestPeriod;
            } else {
                currentSelectedLocations = search.locations;
                currentSelectedPeriod = search.period <= latestPeriod ? search.period : latestPeriod;
            }

            setLocationList(locationsResponse);

            setPeriodNumber(currentSelectedPeriod);
            setSelectedLocations(currentSelectedLocations);

            setTotalPeriods(latestPeriod);
            setLoadingSeachSettings(false);

            HelperFunctions.executeIfNotNull(props.onLoaded, { locations: currentSelectedLocations, period: currentSelectedPeriod }, statusResponse)

        })).catch(function () {
            NotificationToaster.error(Intent.DANGER, "An error occurred while loading the risk search settings.");
        });
      
    }

    useEffect(function () {

        if (loadingSearchSettings && cachedSearch != null) {

            if (cachedSearch.locations?.length > 0) {
                init(false, cachedSearch);
            } else {
                init(true, null);
            }
        }

    }, [cachedSearch, loadingSearchSettings]);


    function onLocationSelectorClose() {
        if (locationsChanged) {
            dispatch(SetFiltering("riskSearch", { locations: selectedLocations, period: periodNumber }));
            setLocationsChanged(false);
        }
    }

    function onLocationSelect(locations) {

        if (selectedLocations.length !== locations.length) {
            setLocationsChanged(true);
        }
        var newLocationSelection = [...locations];

        setSelectedLocations(newLocationSelection);
    }

    function mapLocationList(locationRepsonse) {
        return locationRepsonse.location[0].items.map(function (l) {
            return l.id;
        });
    }

    function onPeriodChange(newPeriodNumber) {
        setPeriodNumber(newPeriodNumber);
        dispatch(SetFiltering("riskSearch", { locations: selectedLocations, period: newPeriodNumber }));
    }

    return (
        <Fragment>
            <div className="inline-items spacer-bottom">
                <div className="inline-item">
                    <FormLocationSelector
                        businessArea=""
                        loading={props.loading}
                        selectedLocations={selectedLocations}
                        setSelectedLocations={onLocationSelect}
                        useHierarchy={false}
                        useCustomLocations
                        customLocations={locationList}
                        disabled={props.disabled}
                        onLocationListClose={onLocationSelectorClose}
                    />
                </div>
                <div className="inline-item">
                    <ListingPagination currentPage={periodNumber} pagingListPosition={Position.RIGHT} totalPages={totalPeriods} loadingData={props.loading || props.disabled} onPageChange={onPeriodChange} itemName="Period" className="pagination-inline" />
                </div>
            </div>
        </Fragment>
    );

}

RiskSelector.defaultProps = {
    disabled: false
};

RiskSelector.propTypes = {
    disabled: PropTypes.bool,
    onLoaded: PropTypes.func
};
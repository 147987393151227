import React, { useState, useEffect, Fragment } from 'react';
import { FormRadio, FormTimePicker } from 'components/form-fields';
import { useValidation } from "hooks/useValidation";
import { ShowHide } from "components/layout";
import moment from "moment";
import PropTypes from 'prop-types';
import { HelperFunctions } from 'helpers';

export function AdditionalInfoSettings(props) {

    const timeFormat = "YYYY-MM-DD HH:mm:ss";
    const inductionDateTime = "01 Jan 2000";

    const [loading, setLoading] = useState(true);

    const [isValid, errors, validate] = useValidation();

    useEffect(updateValidationRules, [props.additionalInfoSettings]);

    function updateValidationRules() {

        const rules = [
            { fieldName: "weighPadAvailable", required: true },
            { fieldName: "inductionRequired", required: true }
        ];

        validate(rules, props.additionalInfoSettings.additionalInfo);
    }

    useEffect(() => {
        setLoading(props.loading);
    }, [props.loading]);


    useEffect(() => {
        HelperFunctions.executeIfNotNull(props.onValidationUpdate, isValid)
    }, [isValid]);


    function updateInductiontime(updateSettings) {

        if (updateSettings.inductionRequired) {
            var duration = moment(updateSettings.inductionDurationTime, timeFormat);
            updateSettings.inductionTime = (duration.hours() * 60) + duration.minutes();
        }
        else {
            updateSettings.inductionTime = 0;
            updateSettings.inductionDurationTime = moment(inductionDateTime).startOf('day').format(timeFormat);
        }
    }

    function onWeighPadAvailableChange(event) {
        HelperFunctions.executeIfNotNull(props.onAdditionalInfoSettingsUpdate, {
            ...props.additionalInfoSettings.additionalInfo,
            weighPadAvailable: event.currentTarget.value === "true"
        })
    }

    function onInductionRequired(event) {
        var inductionDurationMins = event.currentTarget.value === "true" ? props.additionalInfoSettings.additionalInfo.inductionDurationTime : props.originalInductionTime;
        var updateSettings = {
            ...props.additionalInfoSettings.additionalInfo,
            inductionRequired: event.currentTarget.value === "true",
            inductionDurationTime: inductionDurationMins
        }

        updateInductiontime(updateSettings);
        props.onAdditionalInfoSettingsUpdate(updateSettings);
    }

    function onInductionDurationTimeChange(item) {
        var inductionTime = 0;
        var inductionDurationTime = null;
        if (props.additionalInfoSettings.additionalInfo.inductionRequired) {
            inductionDurationTime = moment(item).format(timeFormat);
            var duration = moment(inductionDurationTime, timeFormat);
            inductionTime = (duration.hours() * 60) + duration.minutes();
        }

        var updateSettings = {
            ...props.additionalInfoSettings.additionalInfo,
            inductionDurationTime: inductionDurationTime,
            inductionTime: inductionTime
        }

        updateInductiontime(updateSettings);
        props.onAdditionalInfoSettingsUpdate(updateSettings);
    }    


    return (
        <Fragment>
            <FormRadio
                id="induction-required"
                disabled={props.readOnly || props.saving}
                onChange={onInductionRequired}
                headingText="Is an induction required?"
                selectedValue={props.additionalInfoSettings.additionalInfo.inductionRequired}
                dangerHelperText={errors.inductionRequired}
                loading={loading}
            ></FormRadio>
            <ShowHide
                evaluator={props.additionalInfoSettings !== undefined && props.additionalInfoSettings.additionalInfo.inductionRequired}
                show={(
                    <Fragment>
                        <div className="spacer-bottom">
                            <FormTimePicker
                                disabled={props.readOnly || props.saving || !props.additionalInfoSettings.additionalInfo.inductionRequired}
                                value={props.additionalInfoSettings.additionalInfo.inductionDurationTime}
                                onChange={onInductionDurationTimeChange}
                                headingText="How long will the induction take?"
                                dangerHelperText={errors.inductionDurationMins}
                                loading={loading}
                                id="input-induction-duration-mins"
                            ></FormTimePicker>
                        </div>
                    </Fragment>
                )}
            ></ShowHide>
            <FormRadio
                id="weigh-pad-available"
                disabled={props.readOnly || props.saving}
                onChange={onWeighPadAvailableChange}
                headingText="Please advise if there a weigh pad onsite if vehicles are being inspected by Logistics UK"
                selectedValue={props.additionalInfoSettings.additionalInfo.weighPadAvailable}
                dangerHelperText={errors.weighPadAvailable}
                loading={loading}
            ></FormRadio>
        </Fragment >
    );
}
AdditionalInfoSettings.defaultProps = {
    saving: false,
    readOnly: false
};

AdditionalInfoSettings.propTypes = {
    additionalInfoSettings: PropTypes.object.isRequired,
    originalInductionTime: PropTypes.string,
    saving: PropTypes.bool,
    loading: PropTypes.bool.isRequired,
    readOnly: PropTypes.bool
};
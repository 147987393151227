import React, { Fragment, useState, useEffect } from 'react';
import { Intent } from '@blueprintjs/core'
import { FormHelperText } from "components/form-fields";
import { LoginLayout } from 'pages/layouts';
import { ShowHide } from 'components/layout';
import { UserService } from 'services';
import { NotificationToaster } from "components/notifications";
import { useQuery } from 'hooks/useQuery';

export function ValidateEmail() {

    const query = useQuery();
    const userId = query.get("u");
    const resetCode = query.get("r");

    const confirmRequest = {
        password: null,
        resetGuid: resetCode
    };

    const [confirmed, setConfirmed] = useState(false);
    const [saving, setSaving] = useState(false);

    useEffect(() => {
        setSaving(true);
        UserService.confirmUser(userId, confirmRequest).then(function () {
            setSaving(false);
            setConfirmed(true);
        }, function (error) {
            setConfirmed(false);
            setSaving(false);
            const badRequestCode = 400;
            const forbiddenCode = 403;

            if (error && (error.status === badRequestCode || error.status === forbiddenCode)) {
                if (typeof error.data === "string") {
                    NotificationToaster.show(Intent.WARNING, error.data.toString());
                } else {
                    NotificationToaster.show(Intent.WARNING, "Confirmation code is either invalid or the user has already been approved.");
                }
                
            } else {
                NotificationToaster.show(Intent.DANGER, "Could not confirm the email address, please try again.");
            }
        });
    }, []);

    return (
        <LoginLayout>
            <ShowHide
                evaluator={!saving}
                show={(
                    <ShowHide
                        evaluator={!confirmed}
                        show={(
                            <Fragment>
                                <div className="text-align-center">
                                    <h2>We were unable to verify your account</h2>
                                    <FormHelperText>
                                        <p>Please <a href='/forgot-password'>reset your password</a> to receive a new link.</p>
                                    </FormHelperText>
                                </div>
                                
                            </Fragment>
                        )}
                        hide={(
                            <Fragment>
                                <div className="text-align-center">
                                <h2>Your email has been successfully validated</h2>
                                    <FormHelperText>
                                        <p>To sign in please <a href={window.env.VISION_URL}>login to Vision</a>.</p>
                                    </FormHelperText>
                                </div>
                            </Fragment>
                        )}
                    />
                )}
            />
        </LoginLayout>
    );

}
import React, { Fragment, useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import moment from "moment";

export function DateToLocal(props) {

	const [dateOutput, setDateOutput] = useState("");

	useEffect(() => {

		var dateUtcMoment = null;
		if (props.inputFormat !== "") {
			dateUtcMoment = moment.utc(props.children, props.inputFormat);
		}
		else {
			dateUtcMoment = moment.utc(props.children);
		}

		var format = props.format;

		if (dateUtcMoment.isValid()) {
			var dateUtc = dateUtcMoment.toDate();

			if (props.hideTimeIfMidnight && format !== "DD/MM/YYYY") {
				if (dateUtc.getSeconds() === 0 && dateUtc.getMinutes() === 0 && dateUtc.getHours() === 0) {
					format = "DD/MM/YYYY";
				}
			}

			setDateOutput(moment(dateUtc).local().format(format));
		} else {
			setDateOutput(props.invalidDateString);
		}
	}, [props.children]);


	return (
		<Fragment>{dateOutput}</Fragment>
	);
}

DateToLocal.defaultProps = {
	format: "DD/MM/YYYY HH:mm",
	invalidDateString: "-",
	hideTimeIfMidnight: false,
	inputFormat: ""
};

DateToLocal.propTypes = {
	format: PropTypes.string,
	invalidDateString: PropTypes.string,
	hideTimeIfMidnight: PropTypes.bool,
	inputFormat: PropTypes.string
};
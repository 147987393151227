import React, { useEffect, useState } from "react";
import PropTypes from 'prop-types';

import { RiskService } from "services/RiskService";
import { FormNumericInput } from "components/form-fields";
import { Table } from "components/listing";
import { ShowHide } from "components/layout";
import { Tooltip } from "components/tooltip";
import { Button, ButtonSave } from "components/buttons";
import { AlertUnsavedData, Modal, NotificationInline, NotificationToaster } from "components/notifications";
import { Icon, Intent } from "@blueprintjs/core";
import { StatusBlock } from "components/status";
import { useDirtyData } from "hooks/useDirtyData";

export function RiskBandSettings(props) {

    const [bands, setBands] = useState([]);
    const tableHeaders = formatParamTableHeaders();
    const [modifiedBands, setModifiedBands] = useState([]);
    const [saving, setSaving] = useState(false);
    const [loading, setLoading] = useState(true);
    const [modalBand, setModalBand] = useState(null);
    const [previousModalBand, setPreviousModalBand] = useState(null);
    const [pendingBandsExist, setPendingBandsExist] = useState(false);
    const isDataDirty = useDirtyData(0, modifiedBands.length);

    useEffect(init, []);

    function init() {

        setLoading(true);
        setModifiedBands([]);
        setBands([]);

        RiskService.getBands().then(function(bandResponse) {
            setBands(bandResponse);
            setPendingBandsExist(bandResponse.some(e => e.pendingChange != null));
            setLoading(false);
        }, function () {
            NotificationToaster.show(Intent.DANGER, "Settings could not be loaded. Please try loading the page again");
        });
    }

    function formatParamTableHeaders() {

        let paramHeaders = ["Risk Level", "Risk Level Name", "Band Min Score", "Band Max Score", ""];

        return {
            headers: paramHeaders.map((value) => {
                return {
                    key: value,
                    value: value
                }
            })
        };
    }

    function onLevelChange(bandId, score) {

        setModifiedBands((prev) => {
            var clonedBands = [...prev];
            var matchedBandIndex = clonedBands.findIndex(e => e.bandId === bandId);
            if (matchedBandIndex === -1) {
                clonedBands.push({
                    bandId: bandId,
                    maxScore: score
                });
            } else {
                var matchedEvent = clonedBands[matchedBandIndex];
                matchedEvent.maxScore = score;

                var previousBand = bands.find(e => e.id === bandId);
                if (calculateMaxScore(previousBand, false) === score) {
                    clonedBands.splice(matchedBandIndex, 1);
                }
            }

            return clonedBands;
        });
    }

    function showPendingInfo(band, previousBand) {
        setModalBand(band);
        setPreviousModalBand(previousBand);
    }

    function onModalDismiss() {
        setModalBand(null);
        setPreviousModalBand(null);
    }

    function formatParamTable(tableBands) {
        if (tableBands == null) {
            return [];
        }

        return tableBands.map(function (band, bandIndex) {


            var previousMax = calculateMaxScore(bandIndex === 0 ? null : bands[bandIndex-1]);
            var currentMax = calculateMaxScore(band);
            var nextMax = calculateMaxScore(bandIndex > bands.length - 2 ? null : bands[bandIndex + 1]);

            var currentMin = previousMax != null ? previousMax + 0.01 : "-";

            return {
                cells: [
                    <span key={`band-${band.id}`} className="debrief-level-status"><StatusBlock background={band.colour} dark={band.dark}>{band.id}</StatusBlock></span>,
                    band.name,
                    currentMin,
                    band.maxScore != null ? <FormNumericInput disabledInput disabled={saving} selectedValue={currentMax} min={previousMax == null ? 0 : previousMax + 2} max={nextMax == null ? 9999 : nextMax - 2} onValueChange={(ev) => { onLevelChange(band.id, ev) }} /> : "-",
                    <div className="button-row-small" key={`band-action-${band.id}`}>
                        <ShowHide
                            evaluator={band.pendingChange != null}
                            show={(
                                <Tooltip content="Band has a pending change, click for more info">
                                    <Button onClick={() => showPendingInfo(band, bandIndex === 0 ? null : bands[bandIndex - 1])} icon="time" iconOnly={true} large={false} minimal={true}></Button>
                                </Tooltip>
                            )}>
                        </ShowHide>

                    </div>
                ],
                key: band.id
            };
        });
    }

    function calculateMaxScore(band, includeModified = true) {

        if (band == null) {
            return null;
        }

        var currentMax = band.maxScore;

        if (band.pendingChange != null) {
            currentMax = band.pendingChange.maxScore;
        }
        else if (band.accountBand != null) {
            currentMax = band.accountBand.maxScore;
        }

        var modifiedBand = modifiedBands.find(e => e.bandId === band.id);
        if (modifiedBand != null && includeModified) {
            currentMax = modifiedBand.maxScore;
        }

        return currentMax;

    }

    function calculateNewMinScore(band) {

        if (band == null) {
            return "-";
        }

        if (band?.pendingChange?.maxScore == null) {
            return band?.accountBand?.maxScore + 1
        }

        return band?.pendingChange?.maxScore + 1
    }

    function onSaveBands() {
        setSaving(true);

        var bandsToSave = bands.map(function (band) {
            return {
                bandId: band.id,
                maxScore: calculateMaxScore(band)
            }
        });

        RiskService.saveBands(bandsToSave).then(function () {
            NotificationToaster.show(Intent.SUCCESS, "Bands saved successfully");
            init();
        }, function (error) {
            NotificationToaster.show(Intent.DANGER, error);
        }).finally(function () {
            setSaving(false);
        });

    }


    return (
        <div className='row'>
            <h2>Band Settings</h2>

            <NotificationInline
                intent="primary"
                show={!loading && pendingBandsExist}
                allowClose={false}
                text={(
                    <span>
                        There are saved pending changes to your risk bands. These changes will take effect from the start of your next risk period. Click the &nbsp;<Icon icon="time" />&nbsp; icon next to the event for more information.
                    </span>
                )}
            />

            <div className="spacer-bottom risk-settings-summary">
                <Table
                    headerData={tableHeaders}
                    data={formatParamTable(bands)}
                    loadingData={loading}
                    fitToContent={false}
                    shrinkLastColumn
                />

            </div>

            <NotificationInline allowClose={false} intent="warning" text="Because a risk period is currently in progress, any changes you make will not take effect until the next period begins" show={props.riskStarted && modifiedBands.length > 0} />
            <AlertUnsavedData isDirty={isDataDirty}></AlertUnsavedData>
            <ButtonSave onClick={onSaveBands} disabled={saving} simpleDisabled={modifiedBands.length === 0} loading={loading} />

            <Modal
                isOpen={modalBand != null}
                title={"Band Changes"}
                onClose={onModalDismiss}
            >
                <p>This band has been modified during the current period and the following changes will take place from the start of your next risk period:</p>
                <div className="inline-items spacer-bottom-small">
                    <div className="inline-item"><strong>Min Score:</strong></div>
                    <div className="inline-item">{previousModalBand == null ? "-" : previousModalBand?.accountBand?.maxScore + 1}</div>
                    <div className="inline-item"><Icon icon="arrow-right" /></div>
                    <div className="inline-item">{calculateNewMinScore(previousModalBand)}</div>
                </div>
                <div className="inline-items">
                    <div className="inline-item"><strong>Max Score:</strong></div>
                    <div className="inline-item">{modalBand?.accountBand?.maxScore == null ? "-" : modalBand?.accountBand?.maxScore}</div>
                    <div className="inline-item"><Icon icon="arrow-right" /></div>
                    <div className="inline-item">{modalBand?.pendingChange?.maxScore == null ? "-" : modalBand?.pendingChange?.maxScore}</div>
                </div>
            </Modal>

        </div>
    );
}

RiskBandSettings.propTypes = {
    riskStarted: PropTypes.bool.isRequired
};

import React, { Fragment } from 'react';
import PropTypes from 'prop-types';

export function ArrayToEllipsesString(props) {
    if (!props.children) {
        return null;
    }

    return (
        (props.children.length > props.length) ? <Fragment>{arrToEllipsesLength(props.children, props.length, props.joinCharacter)}&hellip;</Fragment> : defaultJoin(props.children, props.joinCharacter)
    );        
}

function arrToEllipsesLength(arr, ellipseLength, joinCharacter) {

    arr = arr.slice(0, ellipseLength);

    if (Array.isArray(arr)) {
        return arr.join(joinCharacter)
    }

    return arr;
}

function defaultJoin(arr, joinCharacter) {
    if (Array.isArray(arr)) {
        return arr.join(joinCharacter)
    }

    return arr;
}

ArrayToEllipsesString.defaultProps = {
    length: 3,
    joinCharacter: ', '
};

ArrayToEllipsesString.propTypes = {
    length: PropTypes.number,
    joinCharacter: PropTypes.string
};
import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import React from 'react';
import ReactDOM from 'react-dom';

import 'normalize.css/normalize.css';
import '@blueprintjs/core/lib/css/blueprint.css';
import '@blueprintjs/icons/lib/css/blueprint-icons.css';
import '@blueprintjs/datetime/lib/css/blueprint-datetime.css';

import { FocusStyleManager } from "@blueprintjs/core";

import Cookies from 'js-cookie';

import { createStore } from 'redux';
import { Provider } from 'react-redux';

import 'index.css';
import App from 'pages/app/App';
import { authentication } from 'components/user-access';
import { allReducers } from 'state/reducers';

//Set up redux store
const store = createStore(
    allReducers,
    window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__()
);

fetch('/env.json', {
    headers: {
        'Content-Type': 'application/json',
        'Accept': 'application/json'
    }
}).then(response => {
    if (!response.ok) {
        throw new Error("Could not load settings, please reload");
    }
    return response.json();
}).then(json => {

    window.env = json;

    var policy = "B2C_1A_StandardLogin";
    var ssoPolicy = Cookies.get('sso');
    
    if (ssoPolicy != null) {
        policy = `B2C_1A_SSO_${ssoPolicy}`;
    }

    authentication.initialize({
        auth: {
            clientId: window.env.SSOCLIENTIDSELFADMIN, 
            authority: `${window.env.SSOINSTANCEDOMAIN}/tfp/${window.env.SSOTENANT}/${policy}`,
            redirectUri: window.env.SSO_REDIRECT_URL,
            knownAuthorities: [window.env.SSODOMAIN],
            validateAuthority: false
        },
        cache: {
            cacheLocation: "localStorage",
            storeAuthStateInCookie: false
        }
    }, {
        scopes: ["openid", window.env.SSOUSERSCOPE]
    }, {
        scopes: [window.env.SSOUSERSCOPE], 
        forceRefresh: false
    });

    ReactDOM.render(
        <Provider store={store}>
            <App />
        </Provider>
    , document.getElementById('root'));
    FocusStyleManager.onlyShowFocusOnTabs();

});

export default store;






import React from "react";
import {Panel} from 'components/panel';
import PropTypes from "prop-types";
import moment from "moment";
import './HarshBrakingPanel.css';
import classNames from 'classnames';
import {Icon, Position } from  "@blueprintjs/core";
import { Tooltip } from "components/tooltip";
import { DateToLocal } from 'components/dates/DateToLocal';

export function HarshBrakingPanel(props) {

    function onHbRowClick(harshBraking) {
        if (props.onHbRowClick) {
            props.onHbRowClick(harshBraking);
        }
    }

    return (
        <Panel className={classNames("harsh-braking-panel", props.panelClassNames)} color={props.leftColor} disabled={props.disabled} >
            <h3 className="harsh-braking-header">
                <span>Harsh Braking 
                    <Tooltip content="Harsh braking is defined as periods containing a drop in speed of 24KPH or more in 3 seconds or less. All data displayed in KPH." position={Position.RIGHT} >
                        <Icon icon="help" iconSize={14} />
                    </Tooltip>
                </span>
            </h3>
            <div className="harsh-braking-content">
                {
                    props.items.map((i) => {
                        return (<Panel selected={props.highlightedHarshBraking?.startDate === i.startDate} key={moment(i.startDate).format("MMYYHHmmss")} className="harsh-braking-panel-item" onClick={() => onHbRowClick(i)}>
                                <h4><DateToLocal format="DD MMM YYYY HH:mm:ss">{i.startDate}</DateToLocal></h4>
                            <table className="harsh-braking-panel-item-table">
                                <tbody>
                                        <tr>
                                            <th>Start Speed</th>
                                            <th>End Speed</th>
                                            <th>Duration</th>
                                        </tr>
                                        <tr>
                                            <td>{String(i.startSpeed).padStart(2, '0')}kph</td>
                                            <td>{String(i.endSpeed).padStart(2, '0')}kph</td>
                                            <td>{i.duration}s</td>
                                        </tr>
                                    </tbody>
                                </table>
                        </Panel>);
                    })
                }
            </div>
        </Panel>
    );
}

HarshBrakingPanel.defaultProps = {
    disabled: false,
    items: []
};

HarshBrakingPanel.propTypes = {
    leftColor: PropTypes.string,
    items: PropTypes.array.isRequired,
    disabled: PropTypes.bool,
    panelClassNames: PropTypes.string,
    onHbRowClick: PropTypes.func,
    highlightedHarshBraking: PropTypes.object
};
import React from 'react';
import { Alert } from '@blueprintjs/core';
import { HelperFunctions } from 'helpers';

export function AlertBasic(props) {

    function handleAlertCancel() {
        HelperFunctions.executeIfNotNull(props.handleAlertCancel);        
    }

    function handleAlertConfirm() {
        HelperFunctions.executeIfNotNull(props.handleAlertConfirm);
    }

    function handleAlertClose() {
        HelperFunctions.executeIfNotNull(props.handleAlertClose);
    }

    return (
        <Alert
            isOpen={props.isOpen}
            intent={props.intent}
            cancelButtonText={props.cancelButtonText}
            confirmButtonText={props.confirmButtonText}
            onCancel={handleAlertCancel}
            onConfirm={handleAlertConfirm}
            onClose={handleAlertClose}>
            {props.children}
        </Alert>
    );
}
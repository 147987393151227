import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import moment from 'moment';
import { FormDateInput, FormSelect, FormSwitch, FormTextArea, FormTextInput } from 'components/form-fields';
import { useValidation } from 'hooks/useValidation';
import { ShowHide } from 'components/layout';
import { NotificationInline } from 'components/notifications';

export function SystemMessageForm(props) {
    const noSystemMessageTypeId = 0;
    const maintenanceSystemMessageTypeId = 1;
    const incidentSystemMessageTypeId = 2;
    const generalSystemMessageTypeId = 3;
    const fourMinuteBuffer = 4;
    const systemMessageIdsWithNoDateField = [noSystemMessageTypeId, incidentSystemMessageTypeId];
    const originalStartDate = props.originalSystemMessage.startDate;
    const [systemMessageValid, errors, validateSystemMessage] = useValidation();
    const [toolTipText, setToolTipText] = useState("");

    function onStartDateChange(event) {
        props.onSystemMessageUpdate({
            ...props.systemMessage,
            startDate: event ? moment(event).utc().format("YYYY-MM-DD HH:mm") : null
        });
    }

    function onEndDateChange(event) {
        props.onSystemMessageUpdate({
            ...props.systemMessage,
            endDate: event ? moment(event).utc().format("YYYY-MM-DD HH:mm") : null
        });
    }

    function onMessageTypeChange(item, originalValue) {
        if (originalValue !== null) {
            props.onSystemMessageUpdate({
                ...props.systemMessage,
                systemMessageTypeID: item.id
            });
        }
    }

    function onMessageChange(event) {
        props.onSystemMessageUpdate({
            ...props.systemMessage,
            message: event.target.value
        });
    }

    function onUrlChange(event) {
        props.onSystemMessageUpdate({
            ...props.systemMessage,
            url: event.target.value
        });
    }

    function onActiveChange(event) {
        props.onSystemMessageUpdate({
            ...props.systemMessage,
            active: event.target.checked
        });
    }

    useEffect(onValidationChange, [systemMessageValid]);

    function onValidationChange() {
        props.onValidationUpdate(systemMessageValid)
    }

    useEffect(validate, [props.systemMessage]);

    function validate() {
        if (props.isSystemMessageDirty) {
            let rules = [
                { fieldName: "startDate", required: !systemMessageIdsWithNoDateField.includes(props.systemMessage.systemMessageTypeID) },
                { fieldName: "endDate", required: !systemMessageIdsWithNoDateField.includes(props.systemMessage.systemMessageTypeID) },
                { fieldName: "systemMessageTypeID", required: true },
                { fieldName: "message", required: true }
            ];

            validateSystemMessage(rules, props.systemMessage);
        }
    }

    useEffect(() => {
        if (props.systemMessage.systemMessageTypeID === maintenanceSystemMessageTypeId) {
            setToolTipText("For maintenance messages the start and end dates refer to the time the release is expected to take place");
        }
        else if (props.systemMessage.systemMessageTypeID === generalSystemMessageTypeId) {
            setToolTipText("For general messages the start and end dates refer to the time you would like the banner to appear on Vision");
        }

    }, [props.systemMessage.systemMessageTypeID])


    return (
        <div>
            <FormSelect
                id="system-message-message-type"
                loading={props.loading}
                disabled={props.disabled || props.saving}
                items={props.systemMessageTypes}
                placeholder="Select a message type"
                headingText="Message Type:"
                selectedValue={props.systemMessage.systemMessageTypeID}
                onItemSelect={onMessageTypeChange}
                dangerHelperText={errors["systemMessageTypeID"]}
            ></FormSelect>

            <NotificationInline
                id="notification-system-messages"
                loading={props.loading}
                show={props.systemMessage.systemMessageTypeID === maintenanceSystemMessageTypeId || props.systemMessage.systemMessageTypeID === generalSystemMessageTypeId}
                text={toolTipText}
                intent="info">
            </NotificationInline>

            <ShowHide
                evaluator={systemMessageIdsWithNoDateField.includes(props.systemMessage.systemMessageTypeID)}
                hide={(
                    <>
                        <FormDateInput
                            id="system-message-start-date"
                            loading={props.loading}
                            disabled={props.disabled || props.saving || ( props.isUpdatingSystemMessage && props.systemMessage.systemMessageTypeID === maintenanceSystemMessageTypeId && moment(props.systemMessage.startDate).toDate() < moment().toDate() )}
                            headingText="Start Date:"
                            onChange={onStartDateChange}
                            value={props.systemMessage.startDate}
                            dangerHelperText={errors["startDate"]}
                            includeTime={true}
                            minDate={
                                props.systemMessage.startDate && moment(props.systemMessage.startDate).toDate() < moment().add(fourMinuteBuffer, 'm').toDate() 
                                ? moment(props.systemMessage.startDate).toDate() 
                                : moment().add(fourMinuteBuffer, 'm').toDate()
                            }
                            maxDate={props.systemMessage.endDate ? moment(props.systemMessage.endDate).toDate() : null}
                        ></FormDateInput>

                        <FormDateInput
                            id="system-message-end-date"
                            loading={props.loading}
                            disabled={props.disabled || props.saving}
                            headingText="End Date:"
                            onChange={onEndDateChange}
                            value={props.systemMessage.endDate}
                            dangerHelperText={errors["endDate"]}
                            includeTime={true}
                            minDate={props.systemMessage.startDate ? moment(props.systemMessage.startDate).add(2, 'm').toDate() : moment("2000-01-01").toDate()}
                        ></FormDateInput>
                    </>
                )}
            ></ShowHide>

            <FormTextArea
                id="system-message-message"
                maxLength={1000}
                loading={props.loading}
                disabled={props.disabled || props.saving}
                headingText="Message:"
                value={props.systemMessage.message}
                onChange={onMessageChange}
                dangerHelperText={errors["message"]}
            ></FormTextArea>

            <FormTextInput
                id="system-message-url"
                loading={props.loading}
                disabled={props.disabled || props.saving}
                value={props.systemMessage.url}
                headingText="URL:"
                onChange={onUrlChange}
                dangerHelperText={errors["url"]}
            ></FormTextInput>

            <ShowHide
                evaluator={props.isActiveEditable}
                show={(
                    <FormSwitch
                        id="system-message-status"
                        loading={props.loading}
                        disabled={props.disabled || props.saving}
                        headingText="Active:"
                        checked={props.systemMessage.active}
                        onChange={onActiveChange}
                    ></FormSwitch>
                )}
            ></ShowHide>
        </div>
    );
}

SystemMessageForm.defaultProps = {
    saving: false,
    loading: true,
    isSystemMessageDirty: false,
    disabled: false,
    isActiveEditable: true,
    isUpdatingSystemMessage: true
};

SystemMessageForm.propTypes = {
    originalSystemMessage: PropTypes.object.isRequired,
    systemMessage: PropTypes.object.isRequired,
    systemMessageTypes: PropTypes.array.isRequired,
    onSystemMessageUpdate: PropTypes.func.isRequired,
    onValidationUpdate: PropTypes.func.isRequired,
    isSystemMessageDirty: PropTypes.bool.isRequired,
    saving: PropTypes.bool,
    loading: PropTypes.bool,
    disabled: PropTypes.bool,
    isActiveEditable: PropTypes.bool,
    isUpdatingSystemMessage: PropTypes.bool.isRequired
};
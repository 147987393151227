import PropTypes from 'prop-types';
import { Intent } from "@blueprintjs/core";
import { ShowHide } from 'components/layout';
import React, { Fragment, useEffect, useState } from "react";
import { LinkButton, Button } from "components/buttons";
import { Tooltip } from "components/tooltip";
import ReactECharts from 'echarts-for-react';
import { ArrayToEllipsesString } from "components/formatting";
import { ListingTable } from "components/listing";
import { ReportingService } from 'services';
import classNames from 'classnames';
import '../HomePage.css';

export function Widget(props) {
    const [loading, setLoading] = useState(true);
    const [errorOnLoad, setErrorOnLoad] = useState(false);
    const [showCounter, setShowCounter] = useState(false);
    const [showListing, setShowListing] = useState(false);
    const [showGraph, setShowGraph] = useState(false);
    const [widgetData, setWidgetData] = useState(null);
    const [tableRows, setTableRows] = useState([]);
    const [tableHeader, setTableHeader] = useState([]);

    const truncateLength = 30;
    const visionLinkToReplace = "#vision#";

    function setUpWidget() {
        setLoading(true);
        if (props.widget.id !== undefined) {
            ReportingService.getWidget(props.widget.id).then(function (wd) {
                listingMapper(wd);
                graphMapper(wd);
                setWidgetData(wd);
                setLoading(false);
            }, () => {
                setLoading(false);
                setErrorOnLoad(true);
            });
        } else {
            setErrorOnLoad(true);
            setLoading(false);
        }
    }


    useEffect(function () {
        setErrorOnLoad(false);
        setUpWidget();
    }, [props.widget.id]);


    useEffect(function () {
        if (widgetData !== null) {
            if (widgetData.counter?.length > 0) {
                setShowCounter(true);
            }
            if (widgetData.listing?.headers?.length > 0) {
                setShowListing(true);
            }
            if (widgetData.graph?.series != null) {
                setShowGraph(true);
            }
        }
    }, [widgetData]);

    function listingMapper(w) {
        if (w.listing && w.listing.headers && w.listing.headers.length > 0) {
            let tr = w.listing.rows.map(function (item, index) {
                let truncate = false;
                let link = null;
                if (item.values !== null) {
                    item.values.map(function (v) {
                        if (v !== null && v.length > truncateLength) {
                            truncate = true;
                        }
                    });
                }

                if (item.link != null) {
                    if (item.link.toLowerCase().includes(visionLinkToReplace)) {
                        link = item.link.toLowerCase().replace(visionLinkToReplace, `${window.env.VISION_URL}/#/`);
                    } else {
                        link = item.link;
                    }
                }

                let rows = item.values.map((value) => {
                        if (truncate) {
                            return <ArrayToEllipsesString length={truncateLength} key={value}>{value}</ArrayToEllipsesString>
                        } else {
                            return value
                        };
                });
                if (link != null) {
                    rows.push(<LinkButton iconOnly icon="link" minimal text="View" href={link} large={false} />);
                }
                return rows;
            });
            setTableRows(tr);
            let th = w.listing.headers.map((value) => {
                return (value);
            });
            setTableHeader(th);
        }
    }

    function graphMapper(w) {
        if (w.graph != null && props.widget.graphType != null) {
            switch (props.widget.graphType.toLowerCase()) {


                case "stacked-bar":
                    w.graph.series = w.graph.values.map(function (v) {
                        return {
                            name: v.dataName.toUpperCase(),
                            type: 'bar',
                            stack: 'total',
                            data: v.values
                        }
                    })

                    w.graph.xAxis = {
                        type: 'value'
                    }

                    w.graph.yAxis = {
                        type: 'category',
                        data: ['']
                    }

                    break;

                case "stacked-bar-vertical":
                    w.graph.series = w.graph.values.map(function (v) {
                        return {
                            name: v.dataName.toUpperCase(),
                            type: 'bar',
                            stack: 'total',
                            data: v.values
                        }
                    })

                    w.graph.xAxis = {
                        data: w.graph.xAxisValue
                    }

                    w.graph.yAxis = {
                        type: 'value',
                        nameLocation: 'middle',
                        splitNumber: 2
                    }

                    break;

                case "line":
                    w.graph.series = w.graph.values.map(function (v) {
                        return {
                            name: v.dataName.toUpperCase(),
                            type: 'line',
                            data: v.values
                        }
                    })

                    w.graph.xAxis = {
                        data: w.graph.xAxisValue
                    }

                    w.graph.yAxis = {
                        type: 'value',
                        nameLocation: 'middle',
                        splitNumber: 2,
                        min: function (value) {
                            var lowValue = Math.floor(value.min - 10);

                            if (lowValue < 0) {
                                lowValue = 0;
                            }
                            return lowValue;
                        }
                    }
                    break;

                case "bar":
                    w.graph.series = w.graph.values.map(function (v) {
                        return {
                            name: v.dataName.toUpperCase(),
                            type: 'bar',
                            data: v.values
                        }
                    })

                    w.graph.xAxis = {
                        data: w.graph.xAxisValue
                    }

                    w.graph.yAxis = {
                        type: 'value',
                        nameLocation: 'middle',
                        splitNumber: 2
                    }

                    break;

                default:
                    break;
            }
        }
    }

    function refreshWidget() {
        setErrorOnLoad(false);
        setUpWidget();
    }

    return (
        <ShowHide
            evaluator={props.widget !== null}
            show={(  
                <div id="row">
                    <div className="widget">
                        <div className="widget-header">
                            <strong>{props.widget.name}</strong>

                            <ShowHide
                                evaluator={props.widget.linkUrl !== null && props.widget.linkText !== null}
                                show={(
                                    <div>
                                        <Tooltip content="Visit section" >
                                            <LinkButton  intent={Intent.PRIMARY} text={props.widget.linkText} href={props.widget.linkUrl} externalLink={props.widget.linkUrl != null && props.widget.linkUrl.indexOf("https://") === 0} />
                                        </Tooltip>
                                    </div>
                                )}/>

                        </div>

                        <div className="widget-body">

                        <ShowHide
                        evaluator={errorOnLoad}
                        show={(
                            <div className="widget-error">
                                <div className="bp3-non-ideal-state">
                                    <div className="bp3-non-ideal-state-visual">
                                        <span className="bp3-icon bp3-icon-error"></span>
                                    </div>
                                    <h4 className="bp3-heading">The widget failed to load</h4>
                                    <div>Please refesh the widget to retry.</div>
                                    <Button intent={Intent.PRIMARY} icon="refresh" onClick={refreshWidget} text="Refresh"></Button>
                                </div>
                            </div>
                        )} />

                        <ShowHide
                            evaluator={props.loading || loading}
                            show={(
                                <Fragment>
                                    <div className="widget-counters bp3-skeleton">
                                    </div>
                                    <div className={classNames("widget-listing bp3-skeleton", { "widget-listing-loading": !showCounter })}>
                                    </div>
                                </Fragment>
                            )}
                        />

                        <ShowHide
                            evaluator={showCounter}
                            show={(
                                <div className="widget-counters">
                                    <div className="widget-counters-headers">
                                        {showCounter && widgetData.counter.map(function (c) {
                                            return (
                                                <div className="widget-counter-header" key={`${c.name}`}>
                                                    <h5>{c.name}</h5>
                                                </div>
                                            );
                                        })}
                                    </div>
                                    <div className="widget-counters-stats">
                                        {showCounter && widgetData.counter.map(function (c) {
                                            return (
                                                <div className="widget-counter-stat" key={`${c.name}-${c.value}`}>
                                                    <p className="widget-stat">      
                                                        {c.value}{c.suffix}
                                                    </p>
                                                </div>
                                            );
                                        })}
                                    </div>
                                </div>
                            )}
                        />

                        <ShowHide
                            evaluator={showListing}
                            show={(
                                <div className={classNames("widget-listing", { "widget-listing-full": !showCounter })}>
                                    <ListingTable
                                        headers={tableHeader}
                                        data={tableRows}
                                        totalRecordCount={tableRows.length}
                                        pageable={false}
                                        loadingData={loading || props.loading}
                                        noDataMessage={"No results found"}
                                    />
                                </div>
                            )}
                        />

                        <ShowHide
                            evaluator={showGraph}
                            show={(
                                <div className="widget-graph">
                                    <ReactECharts
                                        option={{
                                            color: [
                                                '#005499',
                                                '#0070CC', 
                                                '#008CFF',
                                                '#33A3FF',
                                                '#66BAFF',
                                                '#99D1FF'
                                            ],
                                            tooltip: {
                                                trigger: 'axis',
                                                axisPointer: {
                                                    // Use axis to trigger tooltip
                                                    type: 'shadow' // 'shadow' as default; can also be 'line' or 'shadow'
                                                }
                                            },
                                            grid: {
                                                left: '5%',
                                                right: '5%',
                                                bottom: '5%',
                                                containLabel: true
                                            },
                                            xAxis: !showGraph || widgetData.graph.xAxis == null ? {} : widgetData.graph.xAxis,
                                            yAxis: !showGraph || widgetData.graph.yAxis == null ? {} : widgetData.graph.yAxis,
                                            legend: {},
                                            series: !showGraph || widgetData.graph.series == null ? [] : widgetData.graph.series
                                        }}
                                    />
                                </div>
                            )}
                        />

                    </div>
                </div>
            </div>
            )}
        />
        
    );
}

Widget.defaultProps = {
    loading: false,
    widget: null
};

Widget.propTypes = {
    loading: PropTypes.bool,
    widget: PropTypes.object
};
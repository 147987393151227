import { DataDisplayTable } from "components/data-display";
import { DateToLocal } from "components/dates";
import React, { useState, useEffect } from "react";
import PropTypes from 'prop-types';

export function EmployeeLicenceCheckingDetails(props) {
    const emptyFieldValue = '-';

    const [detailsData, setDetailsData] = useState([]);

    useEffect(formatData, [props.licenceCheck]);

    function formatData() {
        const data = [];

        addDateField(data, 'Licence expiry date', props.licenceCheck?.expiryDate);
        addDateField(data, 'Photocard expiry date', props?.licenceCheck?.photocardExpiryDate);
        addDateField(data, 'ADR expiry', props?.licenceCheck?.adrExpiry);
        addBooleanField(data, 'Medically fit to drive', props?.licenceCheck?.medicallyFitToDrive);
        addDataField(data, 'Licence status', props?.licenceCheck?.licenceStatus);
        addDataField(data, 'Driving licence origin', props?.licenceCheck?.drivingLicenceOrigin);
        addDateField(data, 'Consent form expiry', props?.licenceCheck?.consentFormExpiry);
        addDateField(data, 'Licence last checked', props?.licenceCheck?.licenceLastChecked);

        setDetailsData(data);
    }

    function addDateField(data, fieldName, fieldValue) {
        if (!fieldValue) {
            fieldValue = emptyFieldValue;
        } else {
            fieldValue = <DateToLocal format='DD/MM/YYYY'>{fieldValue}</DateToLocal>;
        }

        addDataField(data, fieldName, fieldValue);
    }

    function addBooleanField(data, fieldName, fieldValue) {
        if (fieldValue == null) {
            fieldValue = emptyFieldValue;
        } else {
            fieldValue = fieldValue ? 'Yes' : 'No';
        }

        addDataField(data, fieldName, fieldValue);
    }

    function addDataField(data, fieldName, fieldValue) {
        if (fieldValue == null) {
            fieldValue = emptyFieldValue;
        }

        data.push({
            header: fieldName,
            value: fieldValue,
            key: fieldName
        });
    }

    return <div className="spacer-top-small">
        <DataDisplayTable data={detailsData} displayInRows={false} loading={props.loading} />
    </div>;
}

EmployeeLicenceCheckingDetails.defaultProps = {
    licenceCheck: null,
    loading: false
};

EmployeeLicenceCheckingDetails.propTypes = {
    licenceCheck: PropTypes.object,
    loading: PropTypes.bool
};
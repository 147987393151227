import classNames from 'classnames';
import { ShowHide } from 'components/layout';
import PropTypes from 'prop-types';
import React from 'react';

export function FormReadonly(props) {
    return (
        <div className="form-field">
            <ShowHide
                evaluator={props.headingText.length > 0}
                show={(
                    <h4 className={classNames({ "bp3-skeleton": props.loading, "bold": props.bold }, props.className)}>{props.headingText}</h4>

                )}
            >
            </ShowHide>
            <span className={classNames({ "form-field-readonly-value": !props.largerValueText, "bp3-skeleton": props.loading }, props.className)}>{props.value}</span>
        </div>
    );

}

FormReadonly.propTypes = {
    headingText: PropTypes.string,
    value: PropTypes.string,
    loading: PropTypes.bool,
    largerValueText: PropTypes.bool,
    className: PropTypes.string,
    bold: PropTypes.bool
};

FormReadonly.defaultProps = {
    headingText: '',
    value: '',
    loading: false,
    largerValueText: false,
    bold: false
};
import React, { useEffect, useState, useRef } from 'react';
import PropTypes from 'prop-types';
import { Button, ButtonSave } from 'components/buttons';
import { FormHeading, FormTextArea } from "components/form-fields";
import { ShowHide } from "components/layout/ShowHide";
import { NotificationInline, AlertConfirm } from 'components/notifications';
import { Intent } from "@blueprintjs/core";
import './Note.css';

export function Note(props){

    const maxNoteSize = 1000;

    const [note, setNote] = useState("");
    const [showMultipleNotesWarning, setShowMultipleNotesWarning] = useState(false);
    const [deleteText, setDeleteText] = useState("Delete Note");
    const [canDelete, setCanDelete] = useState(false)
    const [deletingNote, setDeletingNote] = useState(false);
    const [deleteConfirmMessage, setDeleteConfirmMessage] = useState("");
    const [originalNote, setOriginalNote] = useState(null);
    const inputTextArea = useRef(null);

    function onSaveNote() {
        props.onSave(note);
        setOriginalNote(note);
    }

    function onDeleteNote() {
        setDeletingNote(true);
    }

    function onDelete() {
        setDeletingNote(false);
        props.onDelete();
        setNote("");
        setOriginalNote("");
    }

    function onDeleteCancel() {
        setDeletingNote(false);
    }

    function onNoteChange(event) {
        const newNote = event.target.value;
        setNote(newNote);
        if (props.onNoteChange) {
            props.onNoteChange(newNote);
        }
    }

    function focusOnInput() {
        if (inputTextArea.current != null) {
            inputTextArea.current.focus();
        }
    }

    useEffect(() => {
        if (originalNote && originalNote.length > 0) {
            setCanDelete(true);
        } else {
            setCanDelete(false);
        }
    }, [originalNote]);

    useEffect(() => {
        let allNotesIdentical = true;
        let notesSet = false;
        let plural = props.notesValue.length > 1 ? "s" : "";

        if (!props.inModal) {
            if (props.notesValue.length > 0) {
                //Should only ever be 1 note value
                if (props.notesValue[0] !== note) {
                    //This is the original load
                    setNote(props.notesValue[0]);
                }
                if (originalNote === null) {
                    setOriginalNote(props.notesValue[0]);  
                }
            }
        }
        else if (props.notesValue.length > 0) {
            props.notesValue.forEach((n) => {
                if (n !== props.notesValue[0]) {
                    allNotesIdentical = false;
                }
                if (n && n.length > 0) {
                    notesSet = true;
                    setCanDelete(true);
                    setDeleteConfirmMessage(props.notesValue.length > 1 ? "Are you sure you want to delete these notes?" : "Are you sure you want to delete this note?");
                }
            });

            if (allNotesIdentical && notesSet) {
                setNote(props.notesValue[0]);
            } else {
                setNote("");
            }

            if (!allNotesIdentical && props.notesValue.length > 1) {
                setShowMultipleNotesWarning(true);
            }
        }

        if (props.setTitle) {
            props.setTitle(notesSet ? `Edit note${plural}` : `Add note${plural}`);
            setDeleteText(`Delete note${plural}`);
        }

        setTimeout(focusOnInput);
    }, [props.notesValue]);



    return (

        <>
            <ShowHide
                evaluator={props.inModal}
                hide={<FormHeading headingLevel="h3">Notes</FormHeading>}>
            </ShowHide>
            <NotificationInline allowClose={false} text={"The selected days contain different notes so are not shown. Saving will make the notes for the selected days identical."} intent="warning" show={showMultipleNotesWarning} />
            <FormHeading headingLevel="h4">{props.datesLabel}</FormHeading>
            <div className="note-container">
                <FormTextArea
                    id="asset-notes"
                    maxLength={maxNoteSize}
                    disabled={props.disabled}
                    loading={props.isLoading}
                    helperText="Please type your note here:"
                    value={note}
                    onChange={onNoteChange}
                    inputRef={inputTextArea}
                    showCounter={true}
                ></FormTextArea>
            </div>
            <NotificationInline intent={Intent.WARNING} text="Note is too long, must be below 1000 characters" show={note.length >= maxNoteSize} isShort />
            <div className="button-row button-row-stacked-mobile">
                <ButtonSave
                    onClick={onSaveNote}
                    disabled={props.disabled}
                    simpleDisabled={note.length < 1 || props.isLoading || deletingNote}
                />
                <ShowHide
                    evaluator={canDelete}
                    show={
                        <Button
                            onClick={onDeleteNote}
                            icon="trash"
                            text={deleteText}
                            disabled={props.disabled || !canDelete || props.isLoading || deletingNote}
                            intent='DANGER'
                            className="note-button-delete"
                        />
                    }
                >
                </ShowHide>
            </div>

            <AlertConfirm
                title={deleteText}
                isOpen={deletingNote}
                onConfirm={onDelete}
                onCancel={onDeleteCancel}
                confirmButtonIntent="primary"
                confirmButtonText={deleteText}
            >
                <p>{deleteConfirmMessage}</p>
            </AlertConfirm>


        </>
    );
}

Comment.defaultProps = {
    setTitle: null,
    disabled: false,
    onSave: () => { },
    onDelete: () => { },
    notesValue: [],
    datesLabel: "",
    inModal: false,
    onNoteChange: null
};

Comment.propTypes = {
    setTitle: PropTypes.func,
    onSave: PropTypes.func.isRequired,
    onDelete: PropTypes.func.isRequired,
    disabled: PropTypes.bool,
    isLoading: PropTypes.bool,
    notesValue: PropTypes.array,
    datesLabel: PropTypes.string,
    inModal: PropTypes.bool,
    onNoteChange: PropTypes.func
};
import React, { useState, useEffect } from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import 'components/form-fields/FormFields.css';


export function FormHelperText(props) {

	const [danger, setDanger] = useState(false);

	useEffect(() => {
		const value = props.danger ? props.danger : false;
		setDanger(value);
	}, [props.danger]);

	if (!props.children || props.children === '') {
		return null;
	}

	return (
		<div className={classNames("helper-text", { "bp3-intent-danger": danger, "bp3-skeleton": props.loading })}>
			{props.children}
		</div>);
}

FormHelperText.defaultProps = {
	loading: false,
	danger: false
};

FormHelperText.propTypes = {
	children: PropTypes.node,
	loading: PropTypes.bool,
	danger: PropTypes.bool
};
import ApiService from 'services/ApiService'

const badRequestCode = 400;

export const ExternalContactService = {

	searchExternalContacts(searchObject) {
		return new Promise((resolve, reject) => {
			ApiService.post(
				`/user/ExternalContact/search`, searchObject).then(
					(response) => {
						resolve(response.data);
					},
					(error) => {
						if (error.response.status === badRequestCode && error.response.data) {
							reject(error.response.data);
						} else {
							reject(
								"Unable to search external contacts. Please try again."
							);
						}
					}
				);
		});
	},
	getExternalContact(externalContactId) {
		return new Promise((resolve, reject) => {
			ApiService.get(`/user/ExternalContact/${externalContactId}`).then(
				(result) => {
					resolve(result.data);
				},
				(error) => {
					if (error.response.status === badRequestCode && error.response.data) {
						reject(error.response.data);
					} else {
						reject("Unable to get user, please try again.");
					}
				});
		});
	},
	createExternalContact(externalContact) {
		return new Promise((resolve, reject) => {
			ApiService.post(`/user/ExternalContact`, externalContact).then(
				(result) => {
					resolve(result.data);
				},
				(error) => {
					if (error.response.status === badRequestCode && error.response.data) {
						reject(error.response.data);
					} else {
						reject("Unable to create user, please try again.");
					}
				});
		});
	},
	updateExternalContact(externalContact) {
		return new Promise((resolve, reject) => {

			var url = `/user/ExternalContact/${externalContact.externalContactID}`

			ApiService.put(url, externalContact).then(
				(result) => {
					resolve(result.data);
				},
				(error) => {
					if (error.response.status === badRequestCode && error.response.data) {
						reject(error.response.data);
					} else {
						reject("Could not update the external contact, please try again.");
					}
				});
		});
	},
	deleteExternalContact(externalContactId) {
		return new Promise((resolve, reject) => {
			ApiService.delete(`/user/ExternalContact/${externalContactId}`).then(
				(result) => {
					resolve(result.data);
				},
				() => {
					reject("Unable to delete the external contact, please try again.");
				});
		});
	}
}
import React, { useState, useEffect } from "react";
import { ListingTable } from "components/listing";
import { SelfReportingService } from "services";
import { DateToLocal } from "components/dates";
import PropTypes from "prop-types";
import { NotificationToaster } from "components/notifications";
import { Intent } from "@blueprintjs/core";

export function ScheduleErrors(props) {
  const defaultRecordSize = 25;

  const [totalRecords, setTotalRecords] = useState(0);
  const [tableRows, setTableRows] = useState([]);
  const [loadingData, setLoadingData] = useState(true);
  const noDataMessage = "The schedule does not have any errors";
  const [pageNumber, setPageNumber] = useState(1);
  const [pageSize, setPageSize] = useState(defaultRecordSize);

  const tableHeaders = ["Error date", "Error message"];

  useEffect(initialise, [pageSize, pageNumber]);

  function initialise() {
    SelfReportingService.getScheduleErrors(
      props.scheduleId,
      pageSize,
      pageNumber
    ).then((listingResponse) => {
        let data = [];

        if (listingResponse != null) {
          setTotalRecords(listingResponse.totalCount);
          data = listingResponse.data.map((d) => {
            return [<DateToLocal>{d.errorDate}</DateToLocal>, d.errorMessage];
          });
        }

        setTableRows(data);
        setLoadingData(false);
      },
      function () {
        setLoadingData(false);
        NotificationToaster.show(
          Intent.DANGER,
          "Could not retrieve schedule errors, please try again."
        );
      }
    );
  }

  function onPagingChange(newPageNumber, newPageSize) {
    setPageNumber(newPageNumber);
    setPageSize(newPageSize);
  }

  return (
    <div className="row">
      <h2>Schedule Errors</h2>
      <ListingTable
        id="listing-table-schedule-errors"
        headers={tableHeaders}
        onPagingChange={onPagingChange}
        loadingData={loadingData}
        noDataMessage={noDataMessage}
        data={tableRows}
        totalRecordCount={totalRecords}
        pageable={true}
      />
    </div>
  );
}

ScheduleErrors.propTypes = {
  scheduleId: PropTypes.string.isRequired,
};

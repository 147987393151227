import React, { useState, useEffect } from 'react';
import { Dialog } from '@blueprintjs/core';
import PropTypes from 'prop-types';

import { HelperFunctions } from 'helpers';
import classNames from 'classnames';

export function Modal(props) {
    const [isOpen, setIsOpen] = useState(false);

    useEffect(() => {
        setIsOpen(props.isOpen);
    }, [props.isOpen]);

    function handleClose() {
        HelperFunctions.executeIfNotNull(props.onClose);
        if (props.updateStateOnClose){
            setIsOpen(false);
        }
    }

    return (
        <Dialog
            isOpen={isOpen}
            title={props.title}
            isCloseButtonShown={props.showCloseButton}
            onClose={handleClose}
            autoFocus={false}
            enforceFocus={false}
            canOutsideClickClose={props.clickOutsideClose}
            className={classNames({ "wide": props.wide, "extra-wide": props.extraWide, "full-screen": props.fullScreen}, props.className)}
        >
            <div className="bp3-dialog-body">
                {props.children}
            </div>

        </Dialog>
    );
}

Modal.defaultProps = {
    title: "",
    isOpen: false,
    onClose: null,
    showCloseButton: true,
    clickOutsideClose: true,
    wide: false,
    extraWide: false,
    updateStateOnClose: true,
    fullScreen: false,
    className: ""
};

Modal.propTypes = {
    title: PropTypes.string.isRequired || PropTypes.element.isRequired,
    isOpen: PropTypes.bool.isRequired,
    onClose: PropTypes.func,
    showCloseButton: PropTypes.bool,
    clickOutsideClose: PropTypes.bool,
    wide: PropTypes.bool,
    extraWide: PropTypes.bool,
    updateStateOnClose: PropTypes.bool,
    fullScreen: PropTypes.bool,
    className: PropTypes.string
};
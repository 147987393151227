import React, { useState, useEffect } from 'react';
import axios from 'axios';

import { Icon, Intent } from '@blueprintjs/core';

import { Breadcrumbs } from 'components/navigation';
import { UserAccess, UserHasAccess } from 'components/user-access';
import { PageRestricted } from 'pages/errors/page-restricted';
import { useDirtyData } from 'hooks/useDirtyData';
import { AlertUnsavedData, NotificationToaster } from 'components/notifications';
import { Button, ButtonSave, LinkButton } from 'components/buttons';

import { OptionsService, SystemMessagesService } from 'services';
import { ShowHide } from 'components/layout';
import { SystemMessageForm } from 'pages/internal-admin/system-messages/shared';
import { defaultSystemMessageModel } from 'pages/internal-admin/system-messages/shared/systemMessageModel';

export function SystemMessageCreate(props) {
    const requiredAction = "Internal";
    const canAccessPage = UserHasAccess(requiredAction);
    const systemMessageModel = defaultSystemMessageModel;
    const [originalSystemMessage, setOriginalSystemMessage] = useState(systemMessageModel);
    const [saving, setSaving] = useState(false);
    const [isSystemMessageValid, setSystemMessageValid] = useState(false);
    const [systemMessage, setSystemMessage] = useState(originalSystemMessage);
    const [systemMessageCreated, setSystemMessageCreated] = useState(false);
    const [loading, setLoading] = useState(true);
    const [systemMessageTypes, setSystemMessageTypes] = useState([]);
    const isSystemMessageDirty = useDirtyData(originalSystemMessage, systemMessage);

    useEffect(initialLoad, []);

    function initialLoad() {
        if (!canAccessPage) {
            return;
        }

        axios.all([OptionsService.getSystemMessageTypes()]).then(axios.spread(function (systemMessageTypesResponse) {
            let mappedSystemMessageTypes = systemMessageTypesResponse.map(item => {
                return { id: item.systemMessageTypeId, name: item.systemMessageTypeName };
            });

            setSystemMessageTypes(mappedSystemMessageTypes);

            setLoading(false);
        },
            () => {
                NotificationToaster.show(Intent.DANGER, "Could not load settings, Please refresh the page.");
            }));
    }

    function onSystemMessageUpdate(updatedSystemMessage) {
        setSystemMessage(updatedSystemMessage);
    }

    function onValidationUpdate(isValid) {
        setSystemMessageValid(isValid);
    }

    function saveSystemMessage() {
        setSaving(true);

        if (!isSystemMessageValid) {
            NotificationToaster.show(Intent.WARNING, "There are errors on the page that need your attention");
            setSaving(false);
            return;
        }

        SystemMessagesService.saveSystemMessage(systemMessage).then(
            (response) => {

                const multiStatusCode = 207;
                if (response.status === multiStatusCode) {
                    NotificationToaster.show(Intent.WARNING, response.data);
                }

                setSystemMessageCreated(true);
                setSaving(false);

                setOriginalSystemMessage(systemMessage);
            },
            (error) => {
                setSaving(false);

                const badRequestCode = 400;
                if (error.status === badRequestCode) {
                    NotificationToaster.show(Intent.WARNING, error.data);
                } else {
                    NotificationToaster.show(Intent.DANGER, "Could not create system message. Please try again.");
                }
            }
        );
    }

    function resetComponent() {
        setSystemMessage(systemMessageModel);
        initialLoad();

        setSaving(false);
        setSystemMessageCreated(false);
    }

    return (

        <UserAccess perform={requiredAction}
            yes={() => (
                <div className="row">
                    <Breadcrumbs items={props.breadcrumbs} />

                    <ShowHide
                        evaluator={!systemMessageCreated}
                        show={(
                            <div>
                                <h1 id="header-new-system-message">Add New System Message</h1>

                                <SystemMessageForm
                                    originalSystemMessage={originalSystemMessage}
                                    systemMessage={systemMessage}
                                    systemMessageTypes={systemMessageTypes}
                                    onSystemMessageUpdate={onSystemMessageUpdate}
                                    onValidationUpdate={onValidationUpdate}
                                    isSystemMessageDirty={isSystemMessageDirty}
                                    saving={saving}
                                    loading={loading}
                                    isActiveEditable={false}
                                    isUpdatingSystemMessage={false}
                                />

                                <AlertUnsavedData
                                    isDirty={isSystemMessageDirty}>
                                </AlertUnsavedData>

                                <ButtonSave
                                    id="btn-system-message-create-new"
                                    onClick={saveSystemMessage}
                                    disabled={saving}
                                    loading={loading}
                                ></ButtonSave>
                            </div>
                        )}
                        hide={(
                            <div>
                                <div className="spacer-bottom" id="create-success-icon">
                                    <Icon icon="tick-circle" iconSize={40} intent={Intent.SUCCESS} />
                                </div>
                                <h2 id="create-success-name">System message has been added</h2>
                                <div className="spacer-bottom">
                                    <p>You can now go back to System Message Management or add another system message.</p>
                                </div>
                                <div className="button-row" id="create-success-buttons">
                                    <LinkButton intent="primary" text="System message management" id="listing-location" href="/internal-admin/system-messages" />
                                    <Button intent="primary" text="Add new system message" id="new-location" onClick={resetComponent} />
                                </div>
                            </div>
                        )}
                    ></ShowHide>

                </div>
            )}
            no={() => (
                <PageRestricted />
            )}
        />
    );
}
import React, { Fragment, useEffect, useState } from 'react';

import { Route, Switch, Redirect, useLocation } from 'react-router-dom';

import { EmployeeTrainingSettings } from 'pages/employee-settings/training-settings';
import { EmployeeAssessmentSettings } from 'pages/employee-settings/employee-assessment';
import { DebriefSettings } from 'pages/employee-settings/debrief-settings';
import { UserAccess, UserHasAccess } from 'components/user-access';

import { VerticalLinkGroup, Breadcrumbs } from 'components/navigation';
import { PageRestricted } from 'pages/errors/page-restricted';
import { EmployeeSettingsChangelog } from './changelog';

import 'pages/employee-settings/EmployeeSettings.css';

export function EmployeeSettings(props) {
    const [currentRoute, setCurrentRoute] = useState([]);

    const userCanAccessEmployeeAssessment = UserHasAccess("EmployeeAssessment:Manage");
    const userCanAccessTrainingSettings = UserHasAccess("Training:Manage");
    const userCanAccessDebriefSettings = UserHasAccess("Debrief:Manage");

    let defaultPath;

    let routes = [];

    if (userCanAccessEmployeeAssessment) {
        routes.push({ location: "/employee-settings/assessments", title: "Employee Assessment Settings" });
        defaultPath = (defaultPath == null) ? "/employee-settings/assessments" : defaultPath;
    }

    if (userCanAccessTrainingSettings) {
        routes.push({ location: "/employee-settings/training", title: "Training Settings" });
        defaultPath = (defaultPath == null) ? "/employee-settings/training" : defaultPath;
    }

    if (userCanAccessDebriefSettings) {
        routes.push({ location: "/employee-settings/debrief", title: "Debrief Settings" });
        defaultPath = (defaultPath == null) ? "/employee-settings/debrief" : defaultPath;
    }

    routes.push({ location: "/employee-settings/history", title: "History" });
    defaultPath = (defaultPath == null) ? "/employee-settings/history" : defaultPath;


    let location = useLocation();

    useEffect(findCurrentRoute, [location]);

    function findCurrentRoute() {
        const result = routes.find((l) => {
            return location.pathname.startsWith(l.location);
        });

        setCurrentRoute([result]);
    }

    return (
        <UserAccess perform={["Training:Manage", "EmployeeAssessment:Manage", "Debrief:Manage"]}
            yes={() => (
                <Fragment>
                    <div className="row">

                        <Breadcrumbs items={props.breadcrumbs.concat(currentRoute)} />

                        <h1>Employee Settings</h1>
                        <div className="intro-text">
                            <p>In this section you can configure your employee settings to suit the different services provided by Logistics UK.</p>
                        </div>
                    </div>

                    <div className="layout-cols">
                        <div className="col side-col">
                            <div className="row">
                                <VerticalLinkGroup links={routes} exactRouteMatch={false}></VerticalLinkGroup>
                            </div>
                        </div>
                        <div className="col content-col">
                            <Switch>
                                <Route path="/employee-settings/training">
                                    <EmployeeTrainingSettings></EmployeeTrainingSettings>
                                </Route>

                                <Route path="/employee-settings/debrief">
                                    <DebriefSettings></DebriefSettings>
                                </Route>

                                <Route exact path="/employee-settings" render={() => (<Redirect to={defaultPath} />)} />

                                <Route path="/employee-settings/assessments">
                                    <EmployeeAssessmentSettings></EmployeeAssessmentSettings>
                                </Route>

                                <Route path="/employee-settings/history">
                                    <EmployeeSettingsChangelog></EmployeeSettingsChangelog>
                                </Route>
                            </Switch>
                        </div>
                    </div>
                </Fragment>
            )}
            no={() => (
                <PageRestricted />
            )}
        />
    );
}
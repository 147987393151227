import { DateToLocal } from 'components/dates';
import PropTypes from 'prop-types';
import React, { Fragment, useEffect, useState } from 'react';
import classNames from 'classnames';
import moment from 'moment';

export function OcrDateRangeLayout(props) {
    const [dateRange, setDateRange] = useState({ startDate: null, endDate: null });

    useEffect(() => {
        convertLayoutToDateRange();
    }, [props.layout]);

    function convertLayoutToDateRange() {
        if (props?.layout?.table?.rows && props.layout.table.rows.length > 0) {
            const row = props.layout.table.rows[0];

            if (row.length > 1) {
                const startDate = row[0];
                const endDate = row[1];

                setDateRange({
                    startDate: startDate,
                    endDate: endDate
                });
            }
        }
    }

    return <Fragment>
        <div className={classNames("form-field", { "hide-in-print": !props.readonly, "hidden": props.readonly })}>
            <span className={classNames({ "bp3-skeleton": props.loading })}><DateToLocal format='DD/MM/YYYY'>{dateRange.startDate}</DateToLocal> - {<DateToLocal format='DD/MM/YYYY'>{dateRange.endDate}</DateToLocal>}</span>
                </div>
        <p className={classNames({ "show-in-print": !props.readonly, "visible": props.readonly, "bp3-skeleton": props.loading })}>{moment(dateRange.startDate, "MM/DD/YYYY", null).format("DD/MM/YYYY")} - {moment(dateRange.endDate, "MM/DD/YYYY", null).format("DD/MM/YYYY")}</p>
    </Fragment>       
}

OcrDateRangeLayout.propTypes = {
    loading: PropTypes.bool,
    layout: PropTypes.object.isRequired,
    disabled: PropTypes.bool,
    readonly: PropTypes.bool
};

OcrDateRangeLayout.defaultProps = {
    loading: false,
    disabled: false,
    readonly: false
};

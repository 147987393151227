import React from 'react';
import PropTypes from 'prop-types';
import { ShowHide } from 'components/layout';
import { NavLink } from 'react-router-dom'
import classNames from 'classnames';

import 'components/navigation/Navigation.css';


export function VerticalExpanderGroup(props) {
    return (
        <div className="bp3-tabs bp3-vertical">
            <ul className="bp3-tab-list" role="tablist">
                {props.groups.map((g, groupIndex) => {
                    return <li key={groupIndex} >
                        <div className={classNames("bp3-tab", { "bp3-skeleton": props.loading })} role="tab" aria-selected={props.selectedGroup === g.id && !props.loading} onClick={() => {
                            props.onGroupSelect(g.id)
                        }}>{g.title}</div>
                        <ShowHide
                            evaluator={props.selectedGroup === g.id && !props.loading}
                            show={(
                                <div className="bp3-tab-child-menu">
                                    <ul>
                                        {g.children && g.children.map((l, childIndex) => {
                                            return <li key={childIndex} className={classNames({ "active": props.selectedItem === l.id })}><NavLink to={l.location} exact onClick={() => {
                                                props.onItemSelect(l.id)
                                            }}>{l.title}</NavLink></li>
                                        })}
                                    </ul>
                                </div>
                            )}
                        />

                    </li>
                })}
            </ul>
        </div>
    );
}

VerticalExpanderGroup.defaultProps = {
    groups: [],
    selectedItem: 0,
    selectedGroup: 0
};

VerticalExpanderGroup.propTypes = {
    groups: PropTypes.array,
    selectedItem: PropTypes.number,
    selectedGroup: PropTypes.number,
    onGroupSelect: PropTypes.func
};
import React from 'react';
import './App.css';

//Routing
import { Route, BrowserRouter as Router, Switch } from 'react-router-dom'
import { authentication } from 'components/user-access';

//Pages

import { MainLayout } from 'pages/layouts/MainLayout';
import { DriverRegistration } from 'pages/register/driver';
import { UserConfirm } from 'pages/register/confirm';
import { ForgotPasswordRequest, ForgotPasswordConfirm } from 'pages/forgot-password';
import { ValidateEmail, ValidateExternalUserEmail } from 'pages/validate-email';

function App() {

    return (
        <Router>
            <Switch>
                <Route exact path="/register/driver" component={authentication.anonymousAccess(DriverRegistration)} />
                <Route exact path="/register/confirm" component={authentication.anonymousAccess(UserConfirm)} />
                <Route exact path="/validate-email" component={authentication.anonymousAccess(ValidateEmail)} />
                <Route exact path="/Validate-external-user-email" component={authentication.anonymousAccess(ValidateExternalUserEmail)} />
                <Route exact path="/forgot-password/confirm" component={authentication.anonymousAccess(ForgotPasswordConfirm)} />
                <Route exact path="/forgot-password" component={authentication.anonymousAccess(ForgotPasswordRequest)} />
                <Route component={authentication.required(MainLayout)} />
            </Switch>
        </Router>

    );
}

export default App;

import React, { Fragment } from 'react';
import PropTypes from 'prop-types';

export function Money(props) {

    return (
        <Fragment>{formatStringToMoney(props.children)}</Fragment>
    );

    function formatStringToMoney(value) {
        if (value == null || isNaN(value)) {
            return "-";
        }
        else {
            return new Intl.NumberFormat('en-GB', { style: 'currency', currency: props.currency }).format(value)
        }
    }
}

Money.defaultProps = {
    currency: "GBP"
};

Money.propTypes = {
    currency: PropTypes.string
};
import React from 'react';
import { PageRestricted } from 'pages/errors/page-restricted';
import { RiskListing } from 'pages/risk/shared';
import { UserAccess } from 'components/user-access';

export function RiskAssetListing(props) {
    const tableHeaders = ["Registration", "Risk Score", "Trend", "Location Position", "Company Position", "Location"];
    const sortableHeaders = [
        { headerName: "Registration", sortName: "REGISTRATION" },
        { headerName: "Risk Score", sortName: "SCORE" },
        { headerName: "Trend", sortName: "TREND" },
        { headerName: "Location Position", sortName: "LOCATIONPOSITION" },
        { headerName: "Company Position", sortName: "COMPANYPOSITION" },
        { headerName: "Location", sortName: "LOCATION" },
    ];

    return (
        <UserAccess perform={["Risk:View"]}
            yes={() => (
                <div className="row">

                    <RiskListing
                        title="Asset Risk"
                        riskEntityController="Assets"
                        searchInputPlaceholder="Asset Search"
                        sortableHeaders={sortableHeaders}
                        tableHeaders={tableHeaders}
                        breadcrumbs={props.breadcrumbs}
                    />

                </div>
            )}
            no={() => (
                <PageRestricted />
            )}
        />
    );
}
import React from 'react';
import { PageRestricted } from 'pages/errors/page-restricted';
import { RiskProfile } from 'pages/risk/shared';
import { UserAccess } from 'components/user-access';

export function RiskAssetProfile(props) {

    function mapAssetInfo(profileResponse) {
        return [
            {
                header: "Asset Ownership",
                value: profileResponse.assetOwnership,
                key: 'risk-asset-ownership'
            },
            {
                header: "Asset Status",
                value: profileResponse.assetStatus,
                key: 'current-events'
            },
            {
                header: "Asset Location",
                value: profileResponse.assetLocation,
                key: 'risk-asset-count'
            }
        ];
    }

    return (
        <UserAccess perform={["Risk:View"]}
            yes={() => (
                <RiskProfile 
                    title="Asset Risk"
                    riskEntityType="Asset"
                    riskEntityController="Assets"
                    profileResponseMapper={mapAssetInfo}
                    breadcrumbs={props.breadcrumbs}
                />
            )}
            no={() => (
                <PageRestricted />
            )}
        />

    );
}
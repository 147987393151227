import React from 'react';
import classNames from 'classnames';
import { FileInput } from "@blueprintjs/core";
import PropTypes from 'prop-types';

import { FormHelperText, FormHeading } from "components/form-fields";

import 'components/form-fields/FormFields.css';

export function FormFileInput(props) {
	return (
		<div className={classNames("form-field form-field-fileinput", { "no-label": !props.label })}>
			<FormHeading loading={props.loading} headingLevel={"h4"}>{props.headingText}</FormHeading>
			<FormHelperText loading={props.loading}>{props.helperText}</FormHelperText>
			<FileInput
				id={props.id}
				disabled={props.disabled}
				className={classNames({ "bp3-skeleton": props.loading })}
				onInputChange={props.onChange}
				buttonText={props.buttonText}
				fill={props.fill}
				text={props.text}
				hasSelection={props.hasSelection}/>
			<FormHelperText loading={props.loading} danger={true}>{props.dangerHelperText}</FormHelperText>
		</div>
	);
}

FormFileInput.defaultProps = {
	id: null,
	loading: false,
	headingText: "",
	helperText: null,
	disabled: false,
	dangerHelperText: null,
	fill: true,
	hasSelection:false
};

FormFileInput.propTypes = {
	id: PropTypes.string,
	text: PropTypes.string,
	fill: PropTypes.bool,
	onChange: PropTypes.func.isRequired,
	loading: PropTypes.bool,
	headingText: PropTypes.string,
	helperText: PropTypes.node,
	disabled: PropTypes.bool,
	dangerHelperText: PropTypes.node,
	buttonText: PropTypes.string,
	hasSelection: PropTypes.bool,
};
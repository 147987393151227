import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { Intent } from '@blueprintjs/core';
import { ExternalContactService, LocationService } from 'services';
import { NotificationToaster } from "components/notifications";
import { Breadcrumbs } from 'components/navigation';
import { ExternalContactSetting } from 'pages/external-contacts/shared';
import { UserAccess } from 'components/user-access';
import { PageRestricted } from 'pages/errors/page-restricted';
import axios from "axios";

var _ = require('underscore');

export function ExternalContactEdit(props) {

    const { id } = useParams();
    const [originalEmail, setOriginalEmail] = useState("");
    const [externalContact, setExternalContact] = useState({});
    const [saving, setSaving] = useState(false);
    const [loading, setLoading] = useState(true);
    const requiredAction = "Users:ExternalContacts:Manage";

    useEffect(init, [id]);

    function init() {
        ExternalContactService.getExternalContact(id).then(function (externalContactResponse) {

            //Set this for the UserBasicSettings component
            externalContactResponse.userType = "External";

            setExternalContact(externalContactResponse);
            setOriginalEmail(externalContactResponse.emailAddress);
            setLoading(false);
        }, function () {
            NotificationToaster.show(Intent.DANGER, "Could not retrieve external contact, please try again.");
        });
    }

    function saveExternalContactSucess(newExternalContact) {
        if (newExternalContact.emailAddress !== originalEmail) {
            setExternalContact((prevExternalContact) => {
                return {
                    ...prevExternalContact,
                    verified: false
                }
            });
        }
        setSaving(false);
        window.scrollTo({ top: 0, behavior: 'smooth' });
    }

    function onExternalContactSave(newExternalContact) {
        setSaving(true);


        return new Promise((resolve, reject) => {
            if (newExternalContact.LocationExternalContactIdsToDelete && newExternalContact.LocationExternalContactIdsToDelete.length > 0) {
                //We need to check that the delete works before doing the update as the update deletes the sync table and we don't want things getting out of step
                var locationExternalContactDeletes = [];

                newExternalContact.LocationExternalContactIdsToDelete.forEach(function (locationExternalContactId) {
                    locationExternalContactDeletes.push(LocationService.deleteExternalContact(locationExternalContactId));
                });

                axios.all(locationExternalContactDeletes).then(axios.spread(() => {
                    ExternalContactService.updateExternalContact(newExternalContact).then(
                        () => {
                            saveExternalContactSucess(newExternalContact);
                            resolve("External contact updated successfully.");
                        },
                        (error) => {
                            setSaving(false);
                            reject(error);
                        });
                }),
                    (error) => {
                        setSaving(false);
                        reject(error);
                    });
            } else {
                ExternalContactService.updateExternalContact(newExternalContact).then(
                    () => {
                        saveExternalContactSucess(newExternalContact);
                        resolve("External contact updated successfully.");
                    },
                    (error) => {
                        setSaving(false);
                        reject(error);
                    });
            }
        });
    }

    return (
        <UserAccess perform={requiredAction}
            yes={() => (

            <div className="row">
                <Breadcrumbs items={props.breadcrumbs} />

                <h2>External Contact Details</h2>

                <ExternalContactSetting
                    externalContact={externalContact}
                    loading={loading}
                    editing={true}
                    onExternalContactSave={onExternalContactSave}
                    saving={saving}
                />
            </div>
            
            )}
            no={() => (
                <PageRestricted />
            )}
        />


    );
}
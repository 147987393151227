import React, { useState, useEffect, Fragment, useRef } from 'react';
import { Link } from "react-router-dom";

import { Breadcrumbs } from 'components/navigation';
import { FormDateRangeInput, FormLocationSelector } from 'components/form-fields';
import { Button } from 'components/buttons'
import { ListingTable } from "components/listing";
import { PageRestricted } from 'pages/errors/page-restricted';
import { UserAccess, UserHasAccess } from 'components/user-access';
import { AssetService } from 'services';
import { ShowHide } from "components/layout";
import classNames from "classnames";
import moment from "moment";
import { Tooltip } from 'components/tooltip';
import { Filter } from 'components/filtering';
import { useSelector, useDispatch } from 'react-redux';
import { SetFiltering, SetRouting } from 'state/actions';
import { DateToLocal } from "components/dates";
import { NotificationToaster } from 'components/notifications';
import { Intent } from '@blueprintjs/core';

export function WalkaroundCheckListing(props) {

    const [initalSetupComplete, setInitalSetupComplete] = useState(false);
    const [dateSetupComplete, setDateSetupComplete] = useState(false);
    const [filterSetupComplete, setFilterSetupComplete] = useState(false);
    const [locationSetupComplete, setLocationSetupComplete] = useState(false);
    const dispatch = useDispatch();
    const defaultRecordSize = 25;
    const [tableSortBy, setTableSortBy] = useState({ headerName: "Date", sortName: "STARTDATE" });
    const [tableSortDir, setTableSortDir] = useState("D");
    const [showFilters, setShowFilters] = useState(false);
    const [appliedFilters, setAppliedFilters] = useState([]);
    const [totalRecords, setTotalRecords] = useState(0);
    const [tableRows, setTableRows] = useState([]);
    const [pageNumber, setPageNumber] = useState(1);
    const [pageSize, setPageSize] = useState(defaultRecordSize);
    const [loadingData, setLoadingData] = useState(true);
    const [startDate, setStartDate] = useState(null);
    const [endDate, setEndDate] = useState(null);
    const tableHeaders = ["Date", "Vehicle Registration / Driver", "Duration", "Defect Count", "Report Signed Off"];
    const pageFilters = [
        {
            name: "HASDEFECT",
            displayName: "Has Defects",
            items: [
                { id: "TRUE", name: "Yes" },
                { id: "FALSE", name: "No" },
            ],
        },
        {
            name: "DEFECTSIGNEDOFF",
            displayName: "Report Signed Off",
            items: [
                { id: "TRUE", name: "Yes" },
                { id: "FALSE", name: "No" },
            ],
        }
    ];

    const [noDataMessage, setNoDataMessage] = useState("No checks found, please try a different date range.");
    const sortableHeaders = [
        { headerName: "Date", sortName: "STARTDATE" },
        { headerName: "Duration", sortName: "DURATION" },
        { headerName: "Defect Count", sortName: "DEFECTCOUNT" },
        { headerName: "Report Signed Off", sortName: "REPORTSIGNEDOFF" }
    ];
    const requiredAction = "WalkaroundCheck:View";
    const canAccessPage = UserHasAccess(requiredAction);

    const dateFilterName = "walkaroundDates";
    const locationHierarchyName = "walkaroundHierarchy";
    const selectedDates = useSelector(state => state.filters[dateFilterName]);
    const reduxLocationInfo = useSelector(state => state.filters[locationHierarchyName]);

    const [selectedLocations, setSelectedLocations] = useState([]);
    const [useHierarchy, setUseHierarchy] = useState(true);
    const locationListOnFilterOpen = useRef(selectedLocations);
    const [useLocationDefaults, setUseLocationDefaults] = useState(null);

    const currentPageNumber = useRef();
    const currentPageSize = useRef();
    const currentStartDate = useRef();
    const currentEndDate = useRef();
    const currentTableSortBy = useRef();
    const currentTableSortDir = useRef();
    const currentAppliedFilters = useRef();
    const currentLocations = useRef();

     function calculateSignOffStatus(check) {
        if (check.defectCount === 0) {
            return "-";
        }
        return check.reportSignoffDate ? "Yes" : "No";
    }

    function fetchTableData(forceRefresh) {        
        if (!selectedLocations || selectedLocations.length === 0) {
            setTableRows([]);
            setTotalRecords(0);
            setLoadingData(false);
            dispatch(SetRouting(null, null, null));
            return;
        }

        if (canAccessPage && initalSetupComplete) {

            if (currentPageNumber.current === pageNumber &&
                currentPageSize.current === pageSize &&
                currentStartDate.current === startDate &&
                currentEndDate.current === endDate &&
                currentTableSortBy.current === tableSortBy &&
                currentTableSortDir.current === tableSortDir &&
                currentAppliedFilters.current === appliedFilters.length &&
                currentLocations.current === selectedLocations.length &&
                !forceRefresh
            ) {
                //If none of the values have changed, then 2 have been updated at once, so prevent the listing from performing another call.
                return;
            }

            currentPageNumber.current = pageNumber;
            currentPageSize.current = pageSize;
            currentStartDate.current = startDate;
            currentEndDate.current = endDate;
            currentTableSortBy.current = tableSortBy;
            currentTableSortDir.current = tableSortDir;
            currentAppliedFilters.current = appliedFilters.length;
            currentLocations.current = selectedLocations.length;

            setLoadingData(true);

            let mappedFilters = appliedFilters.map(function (f) {
                return {
                    key: f.name,
                    ...f
                }
            });

            if (selectedLocations != null) {
                var mappedLocations = selectedLocations.map(function (l) {
                    return { "key": "Location", "value": l }
                });

                mappedFilters = [
                    ...mappedFilters,
                    ...mappedLocations
                ];
            }

            const searchRequest = {
                requestCount: pageSize,
                pageNumber: pageNumber,
                sortField: tableSortBy.sortName,
                sortDirection: tableSortDir,
                filters: mappedFilters,
                startDate: startDate,
                endDate: endDate
            };

            AssetService.getAllWalkaroundChecks(searchRequest).then(r => {
                let data = [];

                if (r?.data != null) {
                    data = r.data.map((d) => {
                        var minutesInHour = 60;
                        var hours = Math.floor(d.duration / minutesInHour);
                        var minutes = d.duration - hours * minutesInHour;

                        return [
                            <DateToLocal key={`date-${d.walkaroundCheckId}`} format='DD/MM/YYYY'>{d.startDate}</DateToLocal>,
                            <Link key={`link-${d.walkaroundCheckId}`} to={`/walkaround-checks/${d.walkaroundCheckId}`}>{`${d.assetRegistration} - ${d.employeeFullName}`}</Link>,
                            (hours > 0 ? `${hours} hours and ${minutes} minutes` : `${minutes} minutes`),
                            d.defectCount,
                            calculateSignOffStatus(d)
                        ];
                    })
                }

                setTableRows(data);
                setTotalRecords(r.totalCount);
                setLoadingData(false);
                dispatch(SetRouting(null, null, null));

            }, function () {
                NotificationToaster.show(Intent.DANGER, "Could not retrieve walkaround checks, please try again");
            });
        }
    }

    useEffect(() => {
        fetchTableData(false);
    }, [pageSize, pageNumber, tableSortBy, tableSortDir, startDate, endDate, appliedFilters.length, initalSetupComplete]);


    function onFilterChange(listingFilters) {
        setFilterSetupComplete(true);
        setPageNumber(1);
        setNoDataMessage("No results found, please try a different filter.");
        setAppliedFilters(listingFilters);

        if (listingFilters.length > appliedFilters.length) {
            setShowFilters(true);
        }
    }

    function onTableSort(header, direction) {
        setTableSortDir(direction);
        setTableSortBy(header);
    }

    function onPagingChange(newPageNumber, newPageSize) {
        setPageNumber(newPageNumber);
        setPageSize(newPageSize);
    }

    function onToggleFilter() {
        setShowFilters((prevState) => {
            return !prevState;
        })
    }

    function onDateRangeChange(item) {
        if ((item?.startDate?.date != null && item?.endDate?.date != null || item?.startDate?.date == null && item?.endDate?.date == null)) {
            setPageNumber(1);

            dispatch(SetFiltering(dateFilterName, {
                startDate: item.startDate?.date,
                endDate: item.endDate?.date
            }));
        }
    }

    useEffect(() => {
        if (selectedLocations && selectedLocations.length > 0) {
            setLocationSetupComplete(true);
        }

        if (!loadingData) {
            dispatch(SetFiltering(locationHierarchyName,
                {
                    list: selectedLocations,
                    hierarchySelected: useHierarchy
                }
            ));
        }
    }, [selectedLocations, useHierarchy]);

     useEffect(() => {
        setReduxLocationInfo();
    }, [reduxLocationInfo]);

     function setReduxLocationInfo() {
        if (reduxLocationInfo?.hierarchySelected != null) {
            setSelectedLocations(reduxLocationInfo.list);
            setUseHierarchy(reduxLocationInfo.hierarchySelected);
            setUseLocationDefaults(false);
        } else {
            setUseLocationDefaults(true);
        }
    }

    useEffect(() => {
        setDates();
    }, [selectedDates]);

    function setDates() {
        setDateSetupComplete(true);
        const defaultMonthsBack = -3;
        if (selectedDates && selectedDates.startDate !== undefined && selectedDates.endDate !== undefined) {
            setStartDate(selectedDates.startDate?.format("YYYY-MM-DD"));
            setEndDate(selectedDates.endDate?.format("YYYY-MM-DD"));
        } else {
            setStartDate(moment().add(defaultMonthsBack, 'M').format("YYYY-MM-DD"));
            setEndDate(moment().add(1, 'M').format("YYYY-MM-DD"));
        }
    }

    useEffect(function () {
        setInitalSetupComplete(dateSetupComplete && filterSetupComplete && locationSetupComplete);
    }, [dateSetupComplete, filterSetupComplete, locationSetupComplete]);

    function onLocationListOpened() {
        locationListOnFilterOpen.current = selectedLocations;
    }

    function onLocationListClose() {
        if (locationListOnFilterOpen.current.toString() !== selectedLocations.toString()) {
            fetchTableData(true);
        }
    }

    return (
        <UserAccess perform={requiredAction}
            yes={() => (
                <div className="row">

                    <Breadcrumbs items={props.breadcrumbs} />

                    <h1>Walkaround Checks</h1>

                    <ShowHide
                        evaluator={tableRows.length === 0 && !loadingData && pageFilters.length === 0}
                        hide={(
                            <Fragment>
                                <div className={classNames("pull-left", { "spacer-bottom": !showFilters })}>
                                    <div className="inline-items">
                                        <FormLocationSelector
                                            useLocationDefaults={useLocationDefaults}
                                            locationDefaultsToTrue={true}
                                            onLocationListClose={onLocationListClose}
                                            onLocationListOpened={onLocationListOpened}
                                            businessArea={"Tacho"}
                                            loading={loadingData}
                                            selectedLocations={selectedLocations}
                                            setSelectedLocations={setSelectedLocations}
                                            useHierarchy={useHierarchy}
                                            setUseHierarchy={setUseHierarchy}
                                        />
                                        <FormDateRangeInput
                                            endDate={endDate}
                                            disabled={loadingData}
                                            headingText={""}
                                            large
                                            onChange={onDateRangeChange}
                                            startDate={startDate}
                                            allowClear={false}
                                        ></FormDateRangeInput>
                                        <Tooltip content="Filter" position="right">
                                            <Button icon="filter" onClick={onToggleFilter} className={classNames({ "active": appliedFilters.length > 0 })} />
                                        </Tooltip>
                                    </div>
                                </div>
                                <Filter filterName="walkaroundChecks" visible={showFilters} filters={pageFilters} onUpdate={onFilterChange} />
                            </Fragment>
                        )}
                    />

                    <ListingTable
                        id="listing-table-location"
                        headers={tableHeaders}
                        data={tableRows}
                        totalRecordCount={totalRecords}
                        onPagingChange={onPagingChange}
                        loadingData={loadingData}
                        noDataMessage={noDataMessage}
                        sortable
                        sortableHeaders={sortableHeaders}
                        sortedBy={tableSortBy}
                        sortedDir={tableSortDir}
                        onSort={onTableSort}
                        searchTerm={""}
                    />
                </div>
            )}
            no={() => (
                <PageRestricted />
            )}
        />
    );
}
import React, { useState, useEffect, Fragment, useRef } from 'react';
import { Link } from "react-router-dom";
import { useDebounce } from 'hooks/useDebounce';
import { StringToEllipsesString } from 'components/formatting';
import { FormTextInput, FormSelect } from 'components/form-fields';
import { Button } from 'components/buttons'
import { ListingTable } from "components/listing";
import { PageRestricted } from 'pages/errors/page-restricted';
import { UserAccess, UserHasAccess } from 'components/user-access';
import { JobService, UserService } from 'services';
import { ShowHide } from "components/layout";
import classNames from "classnames";
import { Tooltip } from 'components/tooltip';
import { Filter } from 'components/filtering';
import { useSelector, useDispatch } from 'react-redux';
import { SetFiltering } from 'state/actions';
import { DateToLocal } from "components/dates";
import { AlertConfirm, NotificationToaster } from "components/notifications";
import { useValidation } from "hooks/useValidation";
import { Intent } from '@blueprintjs/core';
import { StatusBlock } from "components/status";
import axios from 'axios';
import './JobListing.css';
export function JobAmendmentListing(props) {

    const defaultRecordSize = 25;
    const debounceTimeout = 750;
    const reasonMaxLength = 40;
    const jobIdLength = 20;
    const requiredAction = "Internal:Jobscheduler";
    const jobSchdulerSecurityGroup = "SG_jobscheduler";

    const [totalRecords, setTotalRecords] = useState(0);
    const [tableRows, setTableRows] = useState([]);
    const [pageNumber, setPageNumber] = useState(1);
    const [pageSize, setPageSize] = useState(defaultRecordSize);
    const [loadingData, setLoadingData] = useState(true);
    const [savingData, setSavingData] = useState(false);
    const [tableSortBy, setTableSortBy] = useState({ headerName: "Date", sortName: "COMPLETEDSCHEDULEDDATE" });
    const [tableSortDir, setTableSortDir] = useState("D");
    const [showFilters, setShowFilters] = useState(false);
    const [appliedFilters, setAppliedFilters] = useState([]);
    const [pageFilters, setPageFilters] = useState([]);
    const [showAssign, setShowAcknowledge] = useState(false);
    const [showAmend, setShowAmend] = useState(false);
    const [currentJobId, setCurrentJobId] = useState(-1);
    const [searchTerm, setSearchTerm] = useState("");
    const debouncedSearchTerm = useDebounce(searchTerm, debounceTimeout);
    const [isValid, errors, validate] = useValidation();
    const [assign, setAssign] = useState({
        selectedUser:""
    });

    const [selectedUser, setSelectedUser] = useState(null);
    const [tableHeaders, setTableHeaders] = useState([]);
    const [user, setUser] = useState(null);
    const [users, setUsers] = useState([]);
    const searchInputRef = useRef(null);
    const canAccessPage = UserHasAccess(requiredAction);
    const noDataMessage = "No Jobs found.";
    const tableHeadersUpcomingJobs = ["Reference", "Account", "Location", "Current Schedule Date", "Due Date", "Rescheduling Type", "Requestor", "Contact Number", "Reschedule Date", "Request Date", "Reason", "Status", "Assigned To", "Actions"];
    const sortableHeaders = [{ headerName: "Reference", sortName: "JOBID" },
        { headerName: "Rescheduling Type", sortName: "AMENDMENTTYPE" },
        { headerName: "Reason", sortName: "AMENDMENTREASON" },
        { headerName: "Location", sortName: "LOCATIONNAME" },
        { headerName: "Contact Number", sortName: "RESCHEDULEPHONENUMBER" },
        { headerName: "Account", sortName: "ACCOUNTNAME" },
        { headerName: "Current Schedule Date", sortName: "CURRENTSCHEDULEDDATE" },
        { headerName: "Due Date", sortName: "DUEDATE" },
        { headerName: "Requestor", sortName: "AMENDMENTBY" }, 
        { headerName: "Reschedule Date", sortName: "RESCHEDULEDATE" },  
        { headerName: "Request Date", sortName: "AMENDMENTDATE" },  
        { headerName: "Assigned To", sortName: "ASSIGNEENAME" },
        { headerName: "Status", sortName: "STATUS" }];
    const jobSearchReduxName = "jobSearch";

    const reduxSearch = useSelector(state => state.filters[jobSearchReduxName]);
    const dispatch = useDispatch();

    const currentPageNumber = useRef();
    const currentPageSize = useRef();
    const currentSearchTerm = useRef();
    const currentTableSortBy = useRef();
    const currentTableSortDir = useRef();
    const currentFilters = useRef();

    useEffect(init, []);
    function init() {
        axios.all([
            UserService.getLoggedInUser(),
            UserService.getUsersWithAction(jobSchdulerSecurityGroup),
        ]).then(axios.spread(function (userDetails, userListResponse) {

            setUser(userDetails);

            var mappedUsers = userListResponse.map(function (responseUser) {
                return {
                    id: responseUser.userId, name: (responseUser.firstName !== null && responseUser.firstName !== undefined && responseUser.firstName !== '')
                        && (responseUser.lastName !== null && responseUser.lastName !== undefined && responseUser.lastName !== '')
                            ? `${responseUser.firstName} ${responseUser.lastName}` : responseUser.emailAddress };
            });

            setUsers(mappedUsers);
        }));
    }

    useEffect(() => {
        setUpPage();
    }, [users]);

    useEffect(() => {
        validateAssign();
    }, [assign]);

    function switchToAccount(accountId, jobId) {
        UserService.switchAccount(accountId).then(function () {
            window.location.href = `/job/scheduling-view/${jobId}`;
        });
    }

    useEffect(() => {
        //This will trigger if the data changes, but will only process if the loadingData is set to true.
        if (loadingData && canAccessPage && pageFilters.length > 0 && user !== null) {

            let filtersArray = getFilters();

            if (
                currentPageNumber.current === pageNumber &&
                currentPageSize.current === pageSize &&
                currentSearchTerm.current === searchTerm &&
                currentTableSortBy.current === tableSortBy &&
                currentTableSortDir.current === tableSortDir &&
                currentFilters.current === filtersArray.length
            ) {
                //If none of the values have changed, then 2 have been updated at once, so prevent the listing from performing another call.
                return;
            }

            currentPageNumber.current = pageNumber;
            currentPageSize.current = pageSize;
            currentSearchTerm.current = searchTerm;
            currentTableSortBy.current = tableSortBy;
            currentTableSortDir.current = tableSortDir;
            currentFilters.current = filtersArray.length;

            const jobsRequest = {
                requestCount: pageSize,
                pageNumber: pageNumber,
                sortField: tableSortBy.sortName,
                sortDirection: tableSortDir,
                filters: filtersArray
            };

            JobService.getAmendmentJobs(jobsRequest).then(r => {
                let data = [];
                
                if (r?.data != null) {
                    data = r.data.map((d) => {
                        let statusBlock = setupStatusBlock(d);
                        let reasonBlock = setupReasonBlock(d);

                        let actionComplete = null;
                        let actionSwitch = null;

                        let actionAssign  = <Tooltip content='Click to assign job.'><Button icon='edit' minimal={true} large={false} onClick={() => onAssignRowClick(d.jobId)}></Button></Tooltip>;

                        if (d.assigneeId === user?.userId) {
                            actionComplete = <Tooltip content='Click to request complete the job amendment.'><Button icon='tick' minimal={true} large={false} onClick={() => onCompleteRowClick(d.jobId, d.assigneeId)}></Button></Tooltip>;
                        }

                        if (d.accountId === user?.accountId) {
                            actionSwitch = <Link to={`/job/scheduling-view/${d.jobId}`} key={`job-link-${d.jobId}`}><StringToEllipsesString length={jobIdLength} showTooltip={true}>{d.pubsJobId ?? d.jobId}</StringToEllipsesString></Link>;
                        } else {
                            actionSwitch = <Fragment>{d.pubsJobId ?? d.jobId}<Tooltip content="Switch account and view job details"><Button onClick={() => switchToAccount(d.accountId, d.jobId)} icon="swap-horizontal" iconOnly={true} large={false} minimal={true}></Button></Tooltip></Fragment>
                        }

                        let actionCopy = <Tooltip content="Copy to clipboard">
                            <Button minimal={true} large={false} icon="duplicate" iconOnly text="Copy to clipboard" onClick={() => copyJobId(d.pubsJobId ?? d.jobId)}></Button>
                        </Tooltip>

                        return [(<Fragment key={`id-cell-${d.jobId}`}> 
                            {actionSwitch}
                            {actionCopy}
                        </Fragment>),
                            d.accountName + ' - ' + d.memberNumber, d.locationName,
                            <DateToLocal key={`sch-date-${d.jobId}`}>{d.currentScheduledDate}</DateToLocal>,
                            <DateToLocal format='DD/MM/YYYY' key={`due-date-${d.jobId}`}>{d.dueDate}</DateToLocal>,
                            statusBlock, d.amendmentBy, 
                            d.reschedulePhoneNumber,                            
                            <DateToLocal format='DD/MM/YYYY' key={`resch-date-${d.jobId}`}>{d.rescheduleDate}</DateToLocal >,
                            <DateToLocal format='DD/MM/YYYY' key={`amend-date-${d.jobId}`}>{d.amendmentDate}</DateToLocal>,
                            reasonBlock, 
                            
                             d.amendmentStatus, d.assigneeName,
                            <div className="button-row-small inline-items" key={`actions-${d.jobId}`}>
                                {actionAssign}{actionComplete}
                            </div>
                        ];
                    });
                }

                setTableRows(data);
                setTotalRecords(r.totalCount);
                setLoadingData(false);
                focusOnTextBox(searchInputRef);
            }, function () {
                NotificationToaster.show(Intent.DANGER, "Job could not be loaded. Please try loading the page again.");
                setLoadingData(false);
            });
        }

    }, [loadingData, pageSize, pageNumber, tableSortBy, tableSortDir, appliedFilters, user]);

    useEffect(() => {
        if (debouncedSearchTerm !== null && debouncedSearchTerm !== currentSearchTerm.current) {
            setPageNumber(1);
            setLoadingData(true);
        }
    }, [debouncedSearchTerm]);

    useEffect(() => {
        //We know the redux store has loaded now
        if (reduxSearch != null && reduxSearch.length > 0 && reduxSearch !== searchTerm) {
            setSearchTerm(reduxSearch);
        }
    }, [reduxSearch]);

    function resetCurrent() {
        currentPageNumber.current = null;
        currentPageSize.current = null;
        currentSearchTerm.current = null;
        currentTableSortBy.current = null;
        currentTableSortDir.current = null;
        currentFilters.current = null;
    }

    function setUpPage() {
        dispatch({ type: 'SITE_FULL_WIDTH' });
        let itemStatus = [
            { "id": "Open", "name": "Open" },
            { "id": "Assigned", "name": "Assigned" }
        ];

        let itemBusinessAreas = [
            { "id": "VIS", "name": "VIS" },
            { "id": "Tacho", "name": "Tacho" }
        ];

        let userFilter = users.map(function (u) {
            return {
                "id": u.id,
                "name": u.name
            };
        });

        userFilter.unshift({ "id": "0", "name": "Unassigned" });

        resetCurrent();

        setTableHeaders(tableHeadersUpcomingJobs);

        var filterArray = [
            {
                "displayName": "Status", "name": "Status", "items": [
                    ...itemStatus
                ]
            },
            {
                "displayName": "Business Area", "name": "BusinessArea", "items": [
                    ...itemBusinessAreas
                ]
            },
            {
                "displayName": "Assignee Names", "name": "AssignedUser", "items": [
                    ...userFilter
                ]
            }
        ];

        setPageFilters(filterArray);
    }

    function getFilters(){
        let mappedFilters = appliedFilters.map(function (f) {
            return {
                key: f.name,
                ...f
            }
        });

        return [...mappedFilters,
        { "key": "Search", "value": searchTerm }
        ];
    }

    function setupStatusBlock(jobRow) {
        let StatusBlockStatus = jobRow.amendmentType === 'Amendment' ? 'Amber' : 'Red';
        let statusBoxText = jobRow.amendmentType === 'Amendment' ? 'Amendment' : 'Cancellation';

        return <StatusBlock status={StatusBlockStatus} bold={false} large={false}>{statusBoxText}</StatusBlock>;
    }

    function setupReasonBlock(jobRow) {
        let reasonBlock = jobRow.amendmentReason;

        if (reasonBlock != null && reasonBlock.length > reasonMaxLength) {
            let reasonBlockText = jobRow.amendmentReason.substring(0, reasonMaxLength) + "...";
            let reasonBlockTooltip = <span>{jobRow.amendmentReason}</span>;

            reasonBlock = <Tooltip content={reasonBlockTooltip}>
                {reasonBlockText}
            </Tooltip>;
        }

        return reasonBlock;
    }

    function validateAssign() {
        let assignRules = [{ fieldName: "selectedUser", required: true }];
        validate(assignRules, assign);
    }

    function onPagingChange(newNumber, newSize) {
        setPageNumber(newNumber);
        setPageSize(newSize);
        setLoadingData(true);
    }

    function onToggleFilter() {
        setShowFilters((prevState) => {
            return !prevState;
        })
    }

    function onTableSort(header, direction) {
        setTableSortBy(header);
        setTableSortDir(direction);
        setLoadingData(true);
    }

    function onFilterChange(listFilters) {
        setPageNumber(1);
        setLoadingData(true);
        setAppliedFilters(listFilters);

        if (listFilters.length > appliedFilters.length) {
            setShowFilters(true);
        }
    }
    
    function onCompleteRowClick(jobId, assigneeId) {
        setSelectedUser(assigneeId);
        setCurrentJobId(jobId);
        setShowAmend(true);
    }

    function closeComplete(reload) {
        validate([], {});

        setSavingData(false);
        if (reload) {
            setLoadingData(true);
        }
        setShowAmend(false);
    }

    function onAmendCancel() {
        closeComplete(false);
    }

    function onAssignRowClick(jobId) {
        //default to current user
        setSelectedUser(user.userId);

        setAssign((prevValue) => {
            return {
                ...prevValue,
                selectedUser: user.userId
            }
        });

        setCurrentJobId(jobId);
        validateAssign();
        setShowAcknowledge(true);
    }

    function copyJobId(jobId) {
        navigator.clipboard.writeText(jobId);
        NotificationToaster.show(Intent.SUCCESS, "Value copied to clipboard");
    }

    function focusOnTextBox(InputRef) {
        if (InputRef.current != null) {
            setTimeout(function () {
                InputRef.current.focus();
            });
        }
    }

    function closeAcknowledge(reload) {
        validate([], {});
        setAssign((prevValue) => {
            return {
                ...prevValue,
                selectedUser: ""
            }
        });

        setSelectedUser(null);
        setSavingData(false);
        if (reload) {
            setLoadingData(true);
        }
        setShowAcknowledge(false);
    }

    function onAssignConfirm() {
        validateAssign();
        if (isValid) {
            setSavingData(true);
            JobService.amendmentUpdate(currentJobId, { "completed": false, "assigneeId": selectedUser }).then(function () {
                resetCurrent();
                closeAcknowledge(true);
                NotificationToaster.show(Intent.SUCCESS, "Job assigned successfully");
            },
                (error) => {
                    closeAcknowledge(false);
                    NotificationToaster.show(Intent.DANGER, error);
                }
            );
        }
    }

    function onCompleteConfirm() {
        setSavingData(true);
        JobService.amendmentUpdate(currentJobId, { "completed": true, "assigneeId": selectedUser }).then(function () {
            resetCurrent();
            closeComplete(true);
            NotificationToaster.show(Intent.SUCCESS, "Job completed successfully");
        },
            (error) => {
                closeComplete(false);
                NotificationToaster.show(Intent.DANGER, error);
            }
        );
    }

    function onAssignCancel() {
        closeAcknowledge(false);
    }

    function onSearchChange(item) {
        setSearchTerm(item.target.value);
        dispatch(SetFiltering(jobSearchReduxName, item.target.value));

    }

    function onUserSelect(item) {

        setSelectedUser(item.id);
        setAssign((prevValue) => {
            return {
                ...prevValue,
                selectedUser: item.id
            }
        });
    }

    return (
        <UserAccess perform={requiredAction}
            yes={() => (
                <div className="row">
                    <div className="inline-items spacer-bottom">
                        <h1>{props.title}</h1>
                    </div>

                    <ShowHide
                        evaluator={tableRows.length === 0 && !loadingData && pageFilters.length === 0}
                        hide={(
                            <Fragment>
                                <div className={classNames("pull-left", { "spacer-bottom": !showFilters })}>
                                    <div className="inline-items">
                                        <FormTextInput
                                            maxLength={50}
                                            disabled={loadingData}
                                            placeholder="Account name"
                                            onChange={onSearchChange}
                                            value={searchTerm}
                                            large
                                            icon="search"
                                            inputRef={searchInputRef}
                                            id="job-search-field"
                                        />
                                        <Tooltip content="Filter" position="right">
                                            <Button icon="filter" className={classNames({ "active": appliedFilters.length > 0 })} onClick={onToggleFilter} />
                                        </Tooltip>
                                    </div>
                                </div>
                                <Filter filterName="jobAmendments" filters={pageFilters} visible={showFilters} onUpdate={onFilterChange} />
                            </Fragment>
                        )}
                    />

                    <ListingTable
                        id="listing-table-location"
                        headers={tableHeaders}
                        data={tableRows}
                        onPagingChange={onPagingChange}
                        totalRecordCount={totalRecords}
                        loadingData={loadingData}
                        noDataMessage={noDataMessage}
                        sortedBy={tableSortBy}
                        sortable
                        sortableHeaders={sortableHeaders}
                        onSort={onTableSort}
                        sortedDir={tableSortDir}
                    />

                    <AlertConfirm
                        title="Please assign user"
                        isOpen={showAssign}
                        saving={loadingData || savingData}
                        onConfirm={onAssignConfirm}
                        onCancel={onAssignCancel}
                        disabled={!isValid}
                    >
                        <Fragment>
                            <FormSelect
                                headingText="Assign to:"
                                id="restrction-templates"
                                items={users}
                                onItemSelect={onUserSelect}
                                selectedValue={selectedUser}
                                placeholder="Select User"
                                loading={loadingData}
                                dangerHelperText={errors.selectedUser}
                            ></FormSelect>
                        </Fragment>
                        <p>Are you sure you want to assign this amendment to this employee?</p>
                    </AlertConfirm>

                    <AlertConfirm
                        title="Please confirm completion"
                        isOpen={showAmend}
                        saving={loadingData || savingData}
                        onConfirm={onCompleteConfirm}
                        onCancel={onAmendCancel}
                    >

                        <p>Are you sure you want to complete this amendment?</p>
                    </AlertConfirm>
                </div>
            )}
            no={() => (
                <PageRestricted />
            )}
        />
    );
}
import React, { Fragment, useState, useEffect, useCallback  } from 'react';
import axios from 'axios';
import { Route, Switch, Redirect, useParams } from 'react-router-dom';
import { NotificationInline } from 'components/notifications';
import { LinkButton } from 'components/buttons';
import { VerticalLinkGroup, Breadcrumbs } from 'components/navigation';
import { UserGeneralSettings } from 'pages/users/edit/general-settings';
import { UserRole } from 'pages/users/edit/role';
import { UserChangelog } from 'pages/users/edit/changelog';
import { UserLocations } from 'pages/users/edit/locations'
import { AccountService, UserService } from 'services';
import { ShowHide } from 'components/layout';
import { UserPreferences } from './preferences/UserPreferences';
import { UserAccountAccess } from './account-access';
import { UserAccess, UserHasAccess } from 'components/user-access';
import { PageRestricted } from 'pages/errors/page-restricted';
import { UserEmailHistory } from 'pages/users/edit/email-history';

var _ = require('underscore');

export function User(props) {
    const { id } = useParams();
    const [invalidUser, setInvalidUser] = useState(false);
    const [loadingData, setLoadingData] = useState(true);
    const [isDriver, setIsDriver] = useState(null);
    const [isPending, setIsPending] = useState(null);
    const [isChildParentAccount, setIsChildParentAccount] = useState(false);
    const [ssoAccount, setSsoAccount] = useState(false);

    const requiredAction = "Users:Manage";
    const canAccessPage = UserHasAccess(requiredAction);

    const getRoutes = useCallback((hasDriverStatus, hasAccessAccount, userId, pendingUser) => {

        var pendingDriver = hasDriverStatus && pendingUser;

        var r = [
            { location: `/user/${userId}/general-settings`, title: "User Details" }
        ];

        if (!pendingDriver) {
            r.push({ location: `/user/${userId}/role`, title: "Role" });
        }

        if (hasDriverStatus != null && !hasDriverStatus) {
            r.push({ location: `/user/${userId}/locations`, title: "Locations" });
            r.push({ location: `/user/${userId}/preferences`, title: "Preferences" });
        }

        if (!pendingDriver) {
            r.push({ location: `/user/${userId}/changes`, title: "History" });
        }

        if (hasDriverStatus != null && !hasDriverStatus && hasAccessAccount) {
            r.push({ location: `/user/${userId}/account-access`, title: "Account Access" });
        }

        r.push({ location: `/user/${userId}/emails`, title: "Email History" });

        return r;
    }, [])

    const [routes, setRoutes] = useState(getRoutes(isDriver, isChildParentAccount, id, isPending));

    const [user, setUser] = useState({
        role: {},
        firstName: "",
        lastName: "",
        emailAddress: ""
    });

    useEffect(() => {
        setRoutes(getRoutes(isDriver, isChildParentAccount, id, isPending));
    }, [isDriver, isChildParentAccount, isPending]);

    useEffect(() => {

        if (!canAccessPage) {
            return;
        }

        function setAccountAccessRoute() {
            axios.all([UserService.getLoggedInUser(),
            AccountService.getAllAccounts(), AccountService.getGeneralSettings()]).then(axios.spread(function (userDetails, accounts, accountGeneralSettings) {
                var currentAccount = _.findWhere(accounts.data, { id: userDetails.accountId });
                var parentId = currentAccount.parentId != null ? currentAccount.parentId : userDetails.accountId;

                var relatedAccounts = _.filter(accounts.data, function (account) {
                    return account.parentId === parentId;
                });
                setIsChildParentAccount(relatedAccounts !== null && relatedAccounts.length > 0);
                setSsoAccount(accountGeneralSettings.ssoEnabled);
            }));
        }

        if (loadingData) {
            setAccountAccessRoute();
            UserService.getUserBasicInfo(id).then(r => {
                setUser(r);
                setLoadingData(false);
                setIsDriver(r.driverId != null);
                setIsPending(r.status === "Pending")
            }, (error) => {

                const noAccessCode = 403;

                if (error.status === noAccessCode) {
                    setInvalidUser(true);
                }
            });
        }
    }, [loadingData, id]);

    function onSave(modifiedUser) {
        setIsDriver(modifiedUser.role != null && modifiedUser.role.key != null && modifiedUser.role.key.toUpperCase() === "DRIVER");
        setUser(modifiedUser);
    }

    return (


        <UserAccess perform={requiredAction}
            yes={() => (

                <ShowHide
                    evaluator={invalidUser}
                    show={(
                        <div className="row">
                            <Breadcrumbs items={props.breadcrumbs} />
                            <NotificationInline
                                allowClose={false}
                                show
                                text="You do not have permission to view this user"
                                intent="danger">
                            </NotificationInline>
                            <div>
                                <LinkButton intent="primary" text="Back to user management" href="/user" id="return-user-listing" />
                            </div>
                        </div>
                    )}
                    hide={(
                        <Fragment>
                            <div className="row">
                                <Breadcrumbs items={props.breadcrumbs} />
                                <ShowHide
                                    evaluator={user.firstName !== ""}
                                    show={(<h1>{user.firstName + " " + user.lastName}</h1>)}
                                    hide={(<h1>{user.emailAddress}</h1>)}
                                />
                                <div className="intro-text">
                                    <p>Here you can configure your user settings for the different services provided by Vision.</p>
                                </div>
                            </div>

                            <div className="layout-cols">
                                <div className="col side-col">
                                    <div className="row">
                                        <VerticalLinkGroup links={routes}></VerticalLinkGroup>
                                    </div>
                                </div>
                                <div className="col content-col">
                                    <Switch>

                                        <Route path="/user/:id(\d+)/account-access">
                                                <UserAccountAccess user={user}></UserAccountAccess>
                                        </Route>
                                        <Route path="/user/:id(\d+)/general-settings">
                                            <UserGeneralSettings
                                                user={user}
                                                loading={loadingData}
                                                ssoAccount={ssoAccount}
                                                onSave={onSave}
                                            />
                                        </Route>
                                        <Redirect exact from="/user/:id(\d+)" to="/user/:id(\d+)/general-settings" />
                                        <Route path="/user/:id(\d+)/role">
                                            <UserRole user={user} onSave={onSave}/>
                                        </Route>
                                        <Route path="/user/:id(\d+)/locations">
                                            <UserLocations userId={id} />
                                        </Route>
                                        <Route path="/user/:id(\d+)/preferences">
                                            <UserPreferences userId={id}></UserPreferences>
                                        </Route>
                                        <Route path="/user/:id(\d+)/changes">
                                            <UserChangelog userId={id}></UserChangelog>
                                        </Route>
                                        <Route path="/user/:id(\d+)/emails">
                                            <UserEmailHistory userId={id}></UserEmailHistory>
                                        </Route>
                                    </Switch>
                                </div>
                            </div>
                        </Fragment>
                    )}
                />
            )}
            no={() => (
                <PageRestricted />
            )}
        />
    );

}
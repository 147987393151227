import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types'
import classNames from 'classnames';
import { Button, Intent } from "@blueprintjs/core";
import { DateRangeInput } from "@blueprintjs/datetime";
import { FormHelperText } from "components/form-fields";
import { ShowHide } from "components/layout";
import moment from "moment";
import 'components/form-fields/FormFields.css';
import "moment/locale/en-gb";
import MomentLocaleUtils from "react-day-picker/moment";

const dateLocale = "en-gb";
const dateFormat = "DD/MM/YYYY";

export function FormDateRangeInput(props) {
    const [dates, setDates] = useState({
        startDate: null,
        endDate: null
    });
    const monthsInPastDefault = 30;
    const yearsInFutureDefault = 5;
    const minDateDefault = moment().subtract(monthsInPastDefault, 'months').toDate();
    const maxDateDefault = moment().add(yearsInFutureDefault, 'years').toDate();
    const [minDate, setMinDate] = useState(minDateDefault);
    const [maxDate, setMaxDate] = useState(maxDateDefault);

    const [initialMonth, setInitialMonth] = useState(moment().add(props.initialMonthOffset, 'months'));

    const startInputRef = useRef(null);
    const endInputRef = useRef(null);

    function setMinDateFromProps() {
        var newMinDate = props.minDate ? props.minDate : minDateDefault;
        if (initialMonth.isBefore(moment(newMinDate))) {
            setInitialMonth(moment(newMinDate));
        }

        if (props.disabled) {
            newMinDate = moment('1990-01-01').toDate();
        }

        setMinDate(newMinDate);
    }
    function setMaxDateFromProps() {
        var newMaxDate = props.maxDate ? props.maxDate : maxDateDefault;
        if (initialMonth.isAfter(moment(newMaxDate))) {
            setInitialMonth(moment(newMaxDate));
        }

        if (props.disabled) {
            newMaxDate = moment('3000-01-01').toDate();
        }


        setMaxDate(newMaxDate);
    }
    useEffect(setMinDateFromProps, [props.minDate]);
    useEffect(setMaxDateFromProps, [props.maxDate]);

    useEffect(() => {
        setDates({
            startDate: props.startDate ? moment(props.startDate).locale(dateLocale).toDate() : null,
            endDate: props.endDate ? moment(props.endDate).locale(dateLocale).toDate() : null
        });
    }, [props.startDate, props.endDate]);

    function handleDateChange(selectedDate) {
        let startDate = {
            date: null,
            dateString: null
        };

        let endDate = {
            date: null,
            dateString: null
        }

        if (selectedDate[0] != null) {
            const startDateMoment = moment(selectedDate[0]);
            startDate.date = startDateMoment;
            startDate.dateString = startDateMoment.format("YYYY-MM-DD");
        }

        if (selectedDate[1] != null) {
            const endDateMoment = moment(selectedDate[1]);
            endDate.date = endDateMoment;
            endDate.dateString = endDateMoment.format("YYYY-MM-DD");
        }

        setDates({
            startDate: selectedDate[0],
            endDate: selectedDate[1]
        });

        props.onChange({
            startDate: startDate,
            endDate: endDate
        });
    }

    function openCalendar(ref) {
        if (ref.current != null) {
            ref.current.focus();
        }
    }

    function receiveStartInputRef(ref) {
        startInputRef.current = ref;
    }

    function receiveEndInputRef(ref) {
        endInputRef.current = ref;
    }

    function clearDates() {
        handleDateChange([null, null]);
    }

    function renderButton(ref) {
        return (
            <Button
                icon="calendar"
                minimal={true}
                onClick={() => { openCalendar(ref) }}
            />
        );
    }

    return (
        <div className="form-field form-field-date-range-input">
            <ShowHide
                evaluator={props.headingText?.length > 0 }
                show={(
                    <h4 className={classNames({ "bp3-skeleton": props.loading })}>{props.headingText}</h4>
                )}
            />
            <FormHelperText className={classNames({ "bp3-skeleton": props.loading })} >{props.helperText}</FormHelperText>
            <DateRangeInput
                id={props.id}
                allowSingleDayRange={true}
                contiguousCalendarMonths={false}
                className={classNames({ "bp3-skeleton": props.loading })}
                formatDate={date => moment(date).locale(dateLocale).format(dateFormat)}
                onChange={handleDateChange}
                parseDate={str => moment(str, dateFormat).locale(dateLocale).toDate()}
                initialMonth={initialMonth.toDate()}
                value={[dates.startDate, dates.endDate]}
                startInputProps={{ className: "small", inputRef: receiveStartInputRef, rightElement: renderButton(startInputRef), large: props.large }}
                endInputProps={{ className: "small", inputRef: receiveEndInputRef, rightElement: renderButton(endInputRef), large: props.large }}
                disabled={props.disabled}
                minDate={minDate}
                maxDate={maxDate}
                locale={dateLocale}
                localeUtils={MomentLocaleUtils}
            />
            <ShowHide
                evaluator={dates.startDate != null && dates.endDate != null && props.allowClear}
                show={(
                    <Button text="Clear dates" id={"clear-dates-btn" + props.id} intent={Intent.DANGER} minimal={true} icon="cross" onClick={clearDates} disabled={props.disabled} large={props.large}></Button>
                )}
            ></ShowHide>
            <FormHelperText loading={props.loading} danger={true}>{props.dangerHelperText}</FormHelperText>
        </div>
    );
}

FormDateRangeInput.defaultProps = {
    id: null,
    startDate: null,
    endDate: null,
    disabled: false,
    loading: false,
    minDate: null,
    maxDate: null,
    headingText: "",
    dangerHelperText: "",
    modifiers: null,
    initialMonthOffset: 0,
    large: false,
    allowClear: true
};

FormDateRangeInput.propTypes = {
    id: PropTypes.string,
    startDate: PropTypes.string,
    endDate: PropTypes.string,
    minDate: PropTypes.string,
    maxDate: PropTypes.string,
    loading: PropTypes.bool,
    headingText: PropTypes.string,
    disabled: PropTypes.bool,
    onChange: PropTypes.func.isRequired,
    modifiers: PropTypes.object,
    dangerHelperText: PropTypes.string,
    initialMonthOffset: PropTypes.number,
    large: PropTypes.bool,
    allowClear: PropTypes.bool
};
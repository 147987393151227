import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { AssetService } from "services";
import { NotificationToaster } from "components/notifications";
import { Intent } from "@blueprintjs/core";
import { Note } from 'components/note';

export function NotesTab(props) {
    const { assetId, date, note, setNote } = props;
    const [isSaving, setIsSaving] = useState(false);

    useEffect(() => {
        setNote(note);
    }, [setNote, note]);

    function onSaveNotes(newNote) {
        setIsSaving(true);
        AssetService.saveNote(assetId, [date], newNote).then(() => {
            setNote(newNote);
            NotificationToaster.show(Intent.SUCCESS, "Note has been saved successfully.", false);
            if (props.reloadContainer) {
                props.reloadContainer(newNote);
            }
        }).catch(() => {
            NotificationToaster.show(Intent.DANGER, "Failed to save note.", false);
        }).finally(() => {
            setIsSaving(false);
        });
    }

    function onDeleteNotes() {
        setIsSaving(true);
        AssetService.deleteNote(assetId, [date]).then(() => {
            setNote("");
            NotificationToaster.show(Intent.SUCCESS, "Note has been deleted successfully.", false);
            if (props.reloadContainer) {
                props.reloadContainer("");
            }
        }).catch(() => {
            NotificationToaster.show(Intent.DANGER, "Failed to delete note.", false);
        }).finally(() => {
            setIsSaving(false);
        });
    }

    return (
        <div className='spacer-top-small vehicle-day-notes'>
            <Note
                onSave={(newNote) => onSaveNotes(newNote)}
                onDelete={() => onDeleteNotes()}
                disabled={isSaving || props.loading}
                isLoading={props.isLoading}
                notesValue={[note]}
                inModal={false}
                onNoteChange={setNote}
            >
            </Note>
        </div>
    )
}

NotesTab.defaultProps = {
    assetId: 0,
    date: "",
    note: "",
    setNote: () => { },
    isLoading: false
};

NotesTab.propTypes = {
    assetId: PropTypes.number.isRequired,
    date: PropTypes.string.isRequired,
    note: PropTypes.string.isRequired,
    setNote: PropTypes.func.isRequired,
    reloadContainer: PropTypes.func,
    isLoading: PropTypes.bool
};
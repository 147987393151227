import React, { useState, useEffect, useRef, Fragment } from 'react';
import { Icon } from "@blueprintjs/core";
import StickyBox from "react-sticky-box";
import classNames from 'classnames';

import { ButtonSave } from 'components/buttons';
import { AlertConfirm } from "components/notifications";
import { Key } from 'components/key';
import { DragDropContext } from 'react-beautiful-dnd';
import { DraggableList } from 'components/draggable-list';
import { FormTextInput } from 'components/form-fields';
import { HierarchyItem } from 'components/hierarchy';
import { useSelector, useDispatch } from 'react-redux';
import { useDebounce } from 'hooks/useDebounce';
import { ShowHide } from 'components/layout';

import './Hierarchy.css';


export function HierarchyStructure(props) {

    const maxLevels = 3;
    const [groupDepth, setGroupDepth] = useState(null);
    const [locationDepth, setLocationDepth] = useState(null);
    const [mappedLocations, setMappedLocations] = useState([]);
    const [groupName, setGroupName] = useState("");
    const [groupNameError, setGroupNameError] = useState("");
    const [usedLocations, setUsedLocations] = useState([]);
    const [isDirty, setIsDirty] = useState(false);
    const [isExporting, setIsExporting] = useState(false);

    const [addingGroup, setAddingGroup] = useState(false);
    const [deletingGroup, setDeletingGroup] = useState(false);
    const [editingGroup, setEditingGroup] = useState(false);
    const [newGroupId, setNewGroupId] = useState(-1);

    const [locationSearch, setLocationSearch] = useState("");
    const [locationUnassignedOnly, setLocationUnassignedOnly] = useState(true);

    const debounceTimeout = 250;
    const [searchAssignedTerm, setSearchAssignedTerm] = useState("");
    const debouncedSearchAssignedTerm = useDebounce(searchAssignedTerm, debounceTimeout);

    const groupNameInputRef = useRef(null);

    const selectedGroup = useSelector(state => state.hierarchy.selectedGroup);
    const selectedLocation = useSelector(state => state.hierarchy.selectedLocation);
    const selectedGroupName = useSelector(state => state.hierarchy.selectedGroupName);
    const action = useSelector(state => state.hierarchy.action);

    const hierarchyKey = [{ "name": "Tacho Locations", "colours": "#00C6B2", "id": 1 }, { "name": "VIS Locations", "colours": "#5A70F3", "id": 2 }, { "name": "Audit Locations", "colours": "#FF7454", "id": 3 }, { "name": "PCN Locations", "colours": "#EC2D6D", "id": 4 }];
    const [displayKey, setDisplayKey] = useState([]);

    const dispatch = useDispatch();

    //On load
    useEffect(function () {

        if (props.loading) {
            return;
        }

        calculateHierarchyDepth();
        dispatch({ type: 'CLEAR_HIERARCHY_ACTION' });

    }, [props.structure, props.loading]);

    useEffect(function () {

        var filteredKeys = hierarchyKey.filter(function (key) {
            return props.hierarchy.businessAreas.some(function (area) {
                return area.locationBusinessAreaId === key.id;
            });
        });

        setDisplayKey(filteredKeys);

    }, [props.hierarchy]);

    useEffect(function () {
        setIsDirty(false);
    }, [props.saving]);


    useEffect(function () {
        setMappedLocations(props.locations);
        calculateHierarchyDepth();
    }, [props.locations.length]);

    //Listen for hierarchy action - Add/edit/delete group, delete location
    useEffect(function () {

        if (selectedGroup != null) {
            switch (action) {
                case "ADD":
                    setGroupName("");
                    setGroupNameError("");
                    setAddingGroup(true);

                    setTimeout(focusOnTextBox);

                    break;
                case "EDIT":
                    setGroupNameError("");
                    setEditingGroup(true);

                    setTimeout(focusOnTextBox);

                    break;
                case "DELETE":
                    setDeletingGroup(true);
                    break;

                case "DELETELOCATION":

                    var oldStructure = [
                        ...props.structure
                    ];

                    deleteLocationFromHierarchy(oldStructure, selectedGroup, selectedLocation);

                    props.onHierarchyUpdate(oldStructure, "LOCATIONREMOVE");
                    markAsDirty();
                    dispatch({ type: 'CLEAR_HIERARCHY_ACTION' });
                    break;
                default:
            }
        } else {
            setGroupName("");
            setGroupNameError("");
            setAddingGroup(false);
            setDeletingGroup(false);
            setEditingGroup(false);
        }

    }, [action, selectedGroup]);


    //Prefix input box for editing group name with the clicked group's name
    useEffect(function () {
        setGroupName(selectedGroupName);
    }, [selectedGroupName])


    function focusOnTextBox() {
        if (groupNameInputRef.current != null) {
            groupNameInputRef.current.focus();
        }
    }

    function markAsDirty() {
        setIsDirty(true);
        props.onHierarchyDirtyChange();
    }

    //Used to calculate what levels should be groups and what levels should be location groups, also calculates all used locations
    function calculateHierarchyDepth() {

        var maxDepths = {
            group: null,
            location: null,
            usedLocations: []
        }


        if (props.structure.length > 0) {
            maxDepths = getChildHierarchyDepth(props.structure, maxDepths, 0);
        }

        setGroupDepth(maxDepths.group);
        setLocationDepth(maxDepths.location);

        setUsedLocations(maxDepths.usedLocations);
    }

    function getChildHierarchyDepth(childStructure, maxDepths, currentDepth) {

        currentDepth++;

        if (currentDepth > maxDepths.group) {
            maxDepths.group = currentDepth;
        }

        for (var i = 0; i < childStructure.length; i++) {

            var currentGroup = childStructure[i];

            if (currentGroup.groups && currentGroup.groups.length > 0) {
                maxDepths = getChildHierarchyDepth(currentGroup.groups, maxDepths, currentDepth);
            }

            if (currentGroup.locations && currentGroup.locations.length > 0) {
                maxDepths.usedLocations = maxDepths.usedLocations.concat(currentGroup.locations);

                maxDepths.location = currentDepth;
            }
        }

        return maxDepths;
    }

    //Dragging and dropping a location into a group
    function onDragEnd(result) {
        if (result.destination != null) {
            var groupId = parseInt(result.destination.droppableId);
            var locationId = parseInt(result.draggableId);

            var draggedLocation = mappedLocations.filter(function (l) {
                return l.id === locationId;
            });

            var location = {
                locationId: draggedLocation[0].id,
                locationName: draggedLocation[0].name,
                locationReference: draggedLocation[0].reference,
                class: draggedLocation[0].class
            };

            var oldStructure = [
                ...props.structure
            ];

            addLocationToHierarchy(oldStructure, groupId, location);

            props.onHierarchyUpdate(oldStructure, "LOCATIONADD");
            markAsDirty();
        }

    }

    function deleteLocationFromHierarchy(oldStructure, groupId, locationId) {
        if (oldStructure != null) {
            for (var i = 0; i < oldStructure.length; i++) {
                var group = oldStructure[i];

                if (group.hierarchyGroupId === groupId) {
                    group.locations = group.locations.filter(function (l) {
                        return l.locationId !== locationId;
                    });
                    return;
                }

                deleteLocationFromHierarchy(oldStructure[i].groups, groupId, locationId);
            }
        }
    }

    function addLocationToHierarchy(oldStructure, groupId, location) {
        if (oldStructure != null) {
            for (var i = 0; i < oldStructure.length; i++) {
                var group = oldStructure[i];

                if (group.hierarchyGroupId === groupId) {

                    if (group.locations == null) {
                        group.locations = [];
                    }

                    //Check the location doesnt already exist in the group

                    if (group.locations.filter(function (l) {
                        return l.locationId === location.locationId;
                    }).length > 0) {
                        return;
                    }

                    group.locations.push(location);
                    return;

                }

                addLocationToHierarchy(oldStructure[i].groups, groupId, location);
            }
        }
    }

    function onFilterLocations(searchTerm, filterItems) {

        setLocationSearch(searchTerm);
        setLocationUnassignedOnly(filterItems);

    }

    function applyFiltering() {


        var filteredLocations = [
            ...props.locations
        ];

        if (locationSearch != null && locationSearch.trim().length > 0) {
            filteredLocations = filteredLocations.filter(function (l) {
                return (l.name.toLowerCase().indexOf(locationSearch.toLowerCase()) === 0
		        || (l.reference && l.reference.toLowerCase().indexOf(locationSearch.toLowerCase()) === 0));
            });
        }

        filteredLocations = filteredLocations.map(function (l) {
            return {
                ...l,
                assignedCount: usedLocations.filter(function (ul) {
                    return ul.locationId === l.id;
                }).length
            };
        })

        if (locationUnassignedOnly) {
            filteredLocations = filteredLocations.filter(function (l) {
                return l.assignedCount === 0;
            });
        }

        setMappedLocations(filteredLocations);

    }

    useEffect(applyFiltering, [locationSearch, locationUnassignedOnly, usedLocations.length, props.locations.length]);

    function onGroupNameChange(e) {
        setGroupName(e.target.value);
    }

    function onGroupAdd() {

        if (isGroupNameValid(false)) {

            var oldStructure = [
                ...props.structure
            ];

            var newGroup = {
                hierarchyGroupId: newGroupId,
                hierarchyGroupName: groupName,
                groups: [],
                locations: []
            };

            addGroupToHierarchy(oldStructure, newGroup, selectedGroup, 0);

            props.onHierarchyUpdate(oldStructure, "GROUPADD");
            markAsDirty();

            setNewGroupId(function (oldId) {
                return oldId - 1;
            });
        }

    }

    function onGroupEdit() {

        if (isGroupNameValid(true)) {

            var oldStructure = {
                groups: [...props.structure]
            };

            editGroupInHierarchy(oldStructure, groupName, selectedGroup);

            props.onHierarchyUpdate(oldStructure.groups, "GROUPEDIT");
            markAsDirty();
        }

    }

    function isGroupNameValid(isEditing) {
        if (groupName.trim().length === 0) {
            setGroupNameError("Please enter a name for your group");
            return false;
        }

        //check the group name hasnt been used in siblings
        if (checkIfSiblingNameExists(props.structure, groupName.trim().toLowerCase(), selectedGroup, 0, false, isEditing)) {
            setGroupNameError("A group with this name already exists");
            return false;
        }

        return true;
    }

    function onGroupNameKeyPress(event) {
        if (event.key.toLowerCase() === 'enter') {
            action === "EDIT" ? onGroupEdit() : onGroupAdd();
        }
    }

    function checkIfSiblingNameExists(oldStructure, newGroupName, selectedGroupId, parentId, siblingNameExists, isEditing) {
        if (oldStructure != null) {

            var levelToCheck = false;

            //If we are editing then the selectedGroupId is the parent, otherwise its the edited group
            if (isEditing) {
                levelToCheck = oldStructure.some(function (g) {
                    return g.hierarchyGroupId === selectedGroupId;
                });
            } else {
                levelToCheck = (selectedGroupId === parentId);
            }


            if (levelToCheck) {
                return oldStructure.some(function (g) {
                    return g.hierarchyGroupName.trim().toLowerCase() === newGroupName
                });
            }

            for (var i = 0; i < oldStructure.length; i++) {
                var childParentId = oldStructure[i].hierarchyGroupId;
                siblingNameExists = checkIfSiblingNameExists(oldStructure[i].groups, newGroupName, selectedGroupId, childParentId, siblingNameExists, isEditing);
            }
        }

        return siblingNameExists;
    }

    function addGroupToHierarchy(oldStructure, newGroup, selectedGroupId, parentId) {

        if (selectedGroupId === parentId) {
            oldStructure.push(newGroup);
            return;
        }

        if (oldStructure != null) {
            for (var i = 0; i < oldStructure.length; i++) {
                var childParentId = oldStructure[i].hierarchyGroupId;
                addGroupToHierarchy(oldStructure[i].groups, newGroup, selectedGroupId, childParentId);
            }
        }
    }

    function editGroupInHierarchy(oldStructure, newGroupName, selectedGroupId) {
        if (oldStructure.groups != null && oldStructure.groups.length > 0) {
            for (var i = 0; i < oldStructure.groups.length; i++) {

                var currentGroup = oldStructure.groups[i];

                if (currentGroup.hierarchyGroupId === selectedGroupId) {
                    currentGroup.hierarchyGroupName = newGroupName;
                    return;
                }

                if (currentGroup.groups != null && currentGroup.groups.length > 0) {
                    editGroupInHierarchy(oldStructure.groups[i], newGroupName, selectedGroupId);
                }

            }
        }
    }

    function onGroupDelete() {

        var oldStructure = {
            groups: [...props.structure]
        };
        deleteGroupFromHierarchy(oldStructure, selectedGroup);
        markAsDirty();
        props.onHierarchyUpdate(oldStructure.groups, selectedGroup > 0 ? "GROUPREMOVE" : "NEWGROUPREMOVE");
    }

    function deleteGroupFromHierarchy(oldStructure, selectedGroupId) {

        if (oldStructure.groups != null && oldStructure.groups.length > 0) {

            var filteredGroups = oldStructure.groups.filter(function (g) {
                return g.hierarchyGroupId !== selectedGroupId;
            });

            if (filteredGroups.length === oldStructure.groups.length) {
                for (var i = 0; i < oldStructure.groups.length; i++) {

                    deleteGroupFromHierarchy(oldStructure.groups[i], selectedGroupId);

                }
            } else {
                oldStructure.groups = filteredGroups;
                return;
            }

        }

    }

    function onModalCancel() {
        dispatch({ type: 'CLEAR_HIERARCHY_ACTION' });
    }

    function onSearchAssignedChange(item) {
        setSearchAssignedTerm(item.target.value);
    }

    function onExport() {
        setIsExporting(true);
        props.export().finally(function () {
            setIsExporting(false);
        });
    }

    useEffect(function () {

        var oldStructure = {
            groups: [...props.structure]
        };

        filterStructureOnSearchTerm(oldStructure, debouncedSearchAssignedTerm);

        props.onHierarchyUpdate(oldStructure.groups);

    }, [debouncedSearchAssignedTerm]);

    function filterStructureOnSearchTerm(oldStructure, searchTerm) {

        var searchVisible = null;

        if (oldStructure.groups == null || oldStructure.groups.length === 0) {
            //we are at the bottom level, see if there are any locations that match out search string

            if (oldStructure.locations != null) {

                if (searchTerm.trim().length > 0) {
                    searchVisible = oldStructure.locations.some(function (l) {
                        return (l.locationName.toLowerCase().indexOf(searchTerm.toLowerCase()) === 0
                        || (l.locationReference && l.locationReference.toLowerCase().indexOf(searchTerm.toLowerCase()) === 0));
                    });
                }

                oldStructure.searchVisible = searchVisible;
                return searchVisible;
            }
        }

        for (var i = 0; i < oldStructure.groups.length; i++) {

            var currentGroup = oldStructure.groups[i];
            var childVisible = filterStructureOnSearchTerm(currentGroup, searchTerm);

            if (!searchVisible) {
                searchVisible = childVisible;
            }
        }

        oldStructure.searchVisible = searchVisible;

        return searchVisible;

    }

    return (
        <Fragment>

            <DragDropContext
                onDragEnd={onDragEnd}>
                <div className="keys-button-container spacer-bottom-small">
                    <Key loading={props.loading} items={displayKey} />
                    <ShowHide
                        evaluator={props.hierarchyId > 0}
                        show={(
                            <div>
                            <ButtonSave
                                icon="export"
                                text="Export Hierarchy"
                                onClick={onExport}
                                loading={props.loading}
                                simpleDisabled={props.saving || isDirty}
                                disabled={isExporting}
                            />
                            </div>
                        )}
                    />
                </div>

                <div id="hierarchy-container">
                    <div className="locations-list spacer-bottom">
                        <StickyBox offsetTop={80} offsetBottom={20}>
                            <DraggableList id={"hierarchy-location-list"} onFilterItems={onFilterLocations} items={mappedLocations} filterLabel={"Only show unassigned locations"} loading={props.loading} disabled={props.saving} />
                        </StickyBox>
                    </div>

                    <div className="hierarchy-placeholder spacer-bottom">
                        <div className="hierarchy-item">
                            <div className="hierarchy-title-row">
                                <h4 className={classNames("hierarchy-title", { "bp3-skeleton": props.loading })}>
                                    <Icon icon="folder-open" /> &nbsp;
                                    {props.hierarchy?.hierarchyName}
                                </h4>
                                <div className="inline-items">
                                    <FormTextInput id="locationList-search-field" placeholder="Search assigned locations" onChange={onSearchAssignedChange} value={searchAssignedTerm} icon="search" loading={props.loading} disabled={props.saving} />
                                </div>
                            </div>

                            <ShowHide
                                evaluator={props.structure.length === 0}
                                show={(
                                    <div className={classNames("spacer", { "bp3-skeleton": props.loading })}>
                                        <p><em>Start by creating some groups for your hierarchy.</em></p>
                                        <p><em>Once you have created your structure, you can then assign locations by dragging them from the left menu into the relevant groups.</em></p>
                                        <p><em>Click on the add a group button below to get started.</em></p>
                                    </div>
                                )}
                            />


                            <HierarchyItem item={{ 'hierarchyGroupName': props.hierarchy?.hierarchyName, 'hierarchyGroupId': 0, 'groups': props.structure }} level={0} maxLevels={maxLevels} groupDepth={groupDepth} locationDepth={locationDepth} isSearching={debouncedSearchAssignedTerm.trim().length > 0} loading={props.loading} disabled={props.saving} />
                        </div>
                    </div>
                </div>

            </DragDropContext>

            <AlertConfirm
                title="Add new group"
                isOpen={addingGroup}
                onConfirm={onGroupAdd}
                onCancel={onModalCancel}
                confirmButtonIntent="primary"
                confirmButtonText="Add Group"
            >
                <FormTextInput id="new-group-name" inputRef={groupNameInputRef} headingText="What would you like to call your new group?" dangerHelperText={groupNameError} onChange={onGroupNameChange} onKeyPress={onGroupNameKeyPress} value={groupName} />
            </AlertConfirm>

            <AlertConfirm
                title="Edit group name"
                isOpen={editingGroup}
                onConfirm={onGroupEdit}
                onCancel={onModalCancel}
                confirmButtonIntent="primary"
                confirmButtonText="Update Group"
            >
                <FormTextInput id="edit-group-name" inputRef={groupNameInputRef} headingText="What would you like to call your new group?" dangerHelperText={groupNameError} onChange={onGroupNameChange} onKeyPress={onGroupNameKeyPress} value={groupName} />
            </AlertConfirm>

            <AlertConfirm
                title="Delete group"
                isOpen={deletingGroup}
                onConfirm={onGroupDelete}
                onCancel={onModalCancel}
            >
                <p>Are you sure you want to delete this group? Any child groups or locations will also be removed.</p>
            </AlertConfirm>

        </Fragment>

    );

}
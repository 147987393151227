import React, { useState, useEffect, Fragment } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';

import { FormDateInput, FormRadio, FormSelect, FormSwitch, FormTextInput } from 'components/form-fields';
import { UserAccess, UserHasAccess } from 'components/user-access';
import { AlertUnsavedData, Modal, NotificationInline, NotificationToaster } from 'components/notifications';
import { useDirtyData } from 'hooks/useDirtyData';
import { Button, ButtonSave, TextButton } from 'components/buttons';
import { RiskService } from 'services/RiskService';
import { Icon, Intent } from '@blueprintjs/core';
import { useValidation } from 'hooks/useValidation';
import { PageRestricted } from 'pages/errors/page-restricted';
import { ShowHide } from 'components/layout';
import { ListingTable } from 'components/listing';
import { DateToLocal } from 'components/dates';

export function RiskGeneralSettings(props) {
	const requiredAction = "Risk:Manage";
    const canAccessPage = UserHasAccess(requiredAction);

	const settingsModel = {
		enabled: false,
		startDate: null,
		periodsToBackfill: null,
		calculationType: null,
		includeAgencyEmployees: false,
		includeHireAssets: false,
		riskInProgress: false
	};

    const [originalSettings, setOriginalSettings] = useState(settingsModel);
	const [settings, setSettings] = useState(originalSettings);
	const [serverSettings, setServerSettings] = useState({});

    useEffect(initialLoad, []);

    const isSettingsDirty = useDirtyData(originalSettings, settings);
	const [loading, setLoading] = useState(true);
    const [saving, setSaving] = useState(false);
    const [settingsValid, errors, validateSettings] = useValidation();
	const [showChangeModel, setShowChangeModel] = useState(false);
	const historicPeriodOptions = [
		{ id: 1, name: "1 Period" },
		{ id: 2, name: "2 Periods" },
		{ id: 3, name: "3 Periods" },
		{ id: 4, name: "4 Periods" },
		{ id: 5, name: "5 Periods" },
		{ id: 6, name: "6 Periods" },
		{ id: 7, name: "7 Periods" },
		{ id: 8, name: "8 Periods" },
		{ id: 9, name: "9 Periods" },
		{ id: 10, name: "10 Periods" },
	]
	const [historicStartDate, setHistoricStartDate] = useState(null);
	const [historicNextPeriod, setHistoricNextPeriod] = useState(null);
	const [historicPeriods, setHistoricPeriods] = useState([]);

	const [canDelete, setCanDelete] = useState(false);
	const [showDeleteRiskDataModal, setShowDeleteRiskDataModal] = useState(false);
	const [deleteRiskDataPhrase, setDeleteRiskDataPhrase] = useState('');

	const requiredDeleteRiskDataPhrase = "confirm delete";

    function initialLoad() {
        if (!canAccessPage) {
            return;
        }

		setLoading(true);

        RiskService.getSettings().then(
			(response) => {
				setLoading(false);
		
				setServerSettings(response);

				var mappedSettings = {};

				if (response.pendingChange != null) {
					mappedSettings = {
						...response.pendingChange,
						startDate: response.startDate,
						periodsToBackfill: response.periodsToBackfill
					}
				} else {
					mappedSettings = {
						enabled: response.enabled,
						startDate: response.startDate,
						includeAgencyEmployees: response.includeAgencyEmployees,
						includeHireAssets: response.includeHireAssets,
						periodsToBackfill: response.periodsToBackfill
					}
				}

				if (response.periodsToBackfill != null) {
					mappedSettings.calculationType = "historic";
				}

				if (response.startDate != null) {
					mappedSettings.calculationType = "future";
				}

				props.onEnabledChange(mappedSettings.enabled);
				setOriginalSettings(mappedSettings);
				setSettings(mappedSettings);
				
			},
			() => {
				NotificationToaster.show(Intent.DANGER, "Could not load settings, Please refresh the page.");
			}
		)
    }

	function disabledDays(date) {
		const maxHour = 23;
		const maxMinute = 59;

		if (date == null) {
            return false;
		}

		var today = new Date();
		today.setHours(maxHour, maxMinute, maxMinute, maxMinute);

		return date.getDay() !== 1 || date < today;
    }

    const startDateModifiers = { disabledDays }

	function onEnabledChange(event) {
        setSettings({
            ...settings,
            enabled: event.target.checked
        });
    }

	function onStartDateChange(event) {
		if (!disabledDays(event)) {
			setSettings({
				...settings,
				startDate: event ? moment(event).format("YYYY-MM-DD") : null
			});
		}
    }

	function onIncludeAgencyEmployeesChange(event) {
        setSettings({
            ...settings,
            includeAgencyEmployees: event.target.checked
        });
    }

	function onIncludeHireAssetsChange(event) {
        setSettings({
            ...settings,
            includeHireAssets: event.target.checked
        });
    }

	function updateSettings() { 
		if (!canAccessPage) {
			return;
		}

		setSaving(true);

		if (!settingsValid) {
            NotificationToaster.show(Intent.WARNING, "There are errors on the page that need your attention");
            setSaving(false);
            return;
        }

		RiskService.updateSettings(settings).then(
			() => {
				setSaving(false);
				NotificationToaster.show(Intent.SUCCESS, "Settings updated successfully.");
				setOriginalSettings(settings);
				initialLoad();
			},
			(error) => {
				setSaving(false);
				NotificationToaster.show(Intent.DANGER, error);
			}
		)
	}

	useEffect(checkIfCanDelete, [settings.enabled, serverSettings.enabled, serverSettings.pendingChange?.enabled, serverSettings.startDate]);

	function checkIfCanDelete() {
		setCanDelete(settings.enabled === false && (serverSettings.enabled === false || serverSettings.pendingChange?.enabled === false) && serverSettings.riskInProgress === true);
	}

	function onDeleteRiskDataClick() {
		setShowDeleteRiskDataModal(true);
	}

	function onDeleteRiskDataPhraseChange(event) {
		setDeleteRiskDataPhrase(event.target.value);
	}

	function deleteRiskData() {
		if (deleteRiskDataPhrase.toLocaleLowerCase() !== requiredDeleteRiskDataPhrase.toLocaleLowerCase()) {
			NotificationToaster.show(Intent.WARNING, `You must type the phrase '${requiredDeleteRiskDataPhrase}' to confirm`);
			return;
		}
		setSaving(true);

		RiskService.deleteRiskData().then(
			() => {
				setSaving(false);

				setSettings(settingsModel);
				setOriginalSettings(settingsModel);
				setServerSettings({});
				setShowDeleteRiskDataModal(false);
				setDeleteRiskDataPhrase('');
				setCanDelete(false);
				NotificationToaster.show(Intent.SUCCESS, "Risk data deleted successfully.");
			},
			(error) => {
				setSaving(false);
				NotificationToaster.show(Intent.DANGER, error);
			}
		)
    }

    function onDeleteRiskDataCancel() {
        setShowDeleteRiskDataModal(false);
		setDeleteRiskDataPhrase('');
    }

	useEffect(validate, [settings, isSettingsDirty]);

	function validate() {
        if (isSettingsDirty) {
			let rules = [
				{ fieldName: "calculationType", required: settings.enabled },
				{ fieldName: "periodsToBackfill", required: settings.enabled && settings.calculationType === "historic" },
				{ fieldName: "startDate", required: settings.enabled && settings.calculationType === "future" }
			];

            validateSettings(rules, settings);
        }
	}

	function showPendingChanges(e) {
		setShowChangeModel(true);
		e.preventDefault();
	}

	function onChangeModalDismiss() {
		setShowChangeModel(false);
	}

	function onCalculationTypeChange(item) {
		var selection = item.currentTarget.value;

		if (selection === "historic") {
			setSettings({
				...settings,
				startDate: null,
				calculationType: selection
			});
		} else {
			setSettings({
				...settings,
				periodsToBackfill: null,
				calculationType: selection
			});
		}

	}

	function onPeriodSelect(item) {
		setSettings({
			...settings,
			periodsToBackfill: item.id
		});
	}

	useEffect(function () {

		if (!settings.riskInProgress && settings.periodsToBackfill != null) {
			var daysInWeek = 7;
			var daysInPeriod = 28;
			var todayDay = moment().isoWeekday();
			var daysUntilNextMonday = (daysInWeek - todayDay) + daysInWeek + 1;
			var nextMonday = moment().add(daysUntilNextMonday, 'd').startOf('day');
			var daysToSubtract = (settings.periodsToBackfill * daysInPeriod);
			var startDate = moment(nextMonday).subtract(daysToSubtract, 'd').startOf('day');

			var pointer = 1;
			var periods = [];
			while (pointer <= settings.periodsToBackfill) {

				var periodStart = moment(startDate).add((pointer - 1) * daysInPeriod, 'd').startOf('day');
				var periodEnd = moment(periodStart).add(daysInPeriod, 'd').subtract(1, 's');

				periods.push([
					pointer,
					<DateToLocal key={`start-${pointer}`}>{periodStart}</DateToLocal>,
					<DateToLocal key={`end-${pointer}`}>{periodEnd}</DateToLocal>
				]);

				pointer++;
			}

			setHistoricPeriods(periods);
			setHistoricStartDate(startDate.format("YYYY-MM-DD"));
			setHistoricNextPeriod(nextMonday.format("YYYY-MM-DD"));

		} else {
			setHistoricStartDate(null);
			setHistoricNextPeriod(null);
			setHistoricPeriods([]);
		}

	}, [settings]);

	return (
		<UserAccess perform={requiredAction}
			yes={() => (
				<div className="row">
					<h2>General Settings</h2>
					<NotificationInline
						intent="primary"
						show={serverSettings?.pendingChange != null && !loading}
						allowClose={false}
						text={(
							<span>
								<Fragment>
									There are saved pending changes to your risk configuration. These changes will take effect from the start of your next risk period. 
									<TextButton onClick={showPendingChanges}>Click here to view the changes</TextButton>
								</Fragment>
							</span>
						)}
					/>
					
					<FormSwitch
                        id="settings-enabled"
                        loading={loading}
						disabled={saving}
						headingText="Enable risk"
                        helperText="Allows you to assess your employees, assets and organisation over a number of key performance indicators to give you a clear measure of your organisation's risk."
                        checked={settings.enabled}
                        onChange={onEnabledChange}
					></FormSwitch>

					<NotificationInline 
						intent="warning" 
						text="If you disable risk, you will still receive events up until the end of the current period. Once the current period completes a new period will not be started."
						show={originalSettings.enabled && !settings.enabled && serverSettings.riskInProgress} 
					/>

					<ShowHide
						evaluator={settings.enabled}
						show={(
							<Fragment>

								<FormRadio
									id="settings-calculation-type"
									headingText="How would you like risk analysis calculated?"
									helperText="If you want to calculate previous periods, the system will raise historic events. If you pick a future start date only events raised after that date will count towards the risk scores"
									loading={loading}
									disabled={serverSettings.riskInProgress || !settings.enabled || saving}
									options={[{ value: "future", label: "Start my analysis from a specific date" }, { value: "historic", label: "Calculate previous periods" }]}
									selectedValue={settings.calculationType}
									onChange={onCalculationTypeChange}
									dangerHelperText={errors["calculationType"]}

								/>

								<ShowHide
									evaluator={settings.calculationType === "historic"}
									show={(
										<div>
											<FormSelect
												id="settings-historic-period-count"
												headingText="How many periods would you like to historically calculate?"
												helperText={settings.periodsToBackfill != null && !serverSettings.riskInProgress
													? "Each risk period lasts for 28 days, select an option to see a breakdown of historical periods that will be created."
													: "Each risk period lasts for 28 days"}
												loading={loading}
												placeholder="Select periods to calculate"
												disabled={serverSettings.riskInProgress || !settings.enabled || saving}
												items={historicPeriodOptions}
												onItemSelect={onPeriodSelect}
												selectedValue={settings.periodsToBackfill}
												dangerHelperText={errors["periodsToBackfill"]}
											/>
											<ShowHide
												evaluator={settings.periodsToBackfill != null && !serverSettings.riskInProgress}
												show={(
													<div className="spacer-bottom">
														<p className="helper-text">Historical data will be loaded for periods begining on <DateToLocal format="DD/MM/YYYY">{historicStartDate}</DateToLocal>. Scores will become available to view on <DateToLocal format="DD/MM/YYYY">{historicNextPeriod}</DateToLocal> after the next period has begun.</p>
														<ListingTable
															headers={["Period Number", "Period Start Date", "Period End Date"]}
															data={historicPeriods}
															totalRecordCount={historicPeriods.length}
															pageable={false}
															loadingData={false}
														>
														</ListingTable>
													</div>
												)}
											/>
										</div>
										
									)}
								/>

								<ShowHide
									evaluator={settings.calculationType === "future"}
									show={(
										<FormDateInput
											id="settings-start-date"
											disabled={serverSettings.riskInProgress || !settings.enabled || saving}
											headingText="The date you wish to start your risk calculations from:"
											helperText="The start date must be a Monday in the future. Each risk period will last for 28 days after which point the risk scores wil be calculated and a new period will begin."
											onChange={onStartDateChange}
											loading={loading}
											dangerHelperText={errors["startDate"]}
											modifiers={startDateModifiers}
											value={settings.startDate}
											minDate={moment("2000-01-01").toDate()}
										></FormDateInput>
									)}
								/>						

								<FormSwitch
									id="settings-include-agency-employees"
									loading={loading}
									disabled={saving}
									headingText="Include agency employees"
									helperText="Switching this option on will include agency employees in your risk calculations."
									checked={settings.includeAgencyEmployees}
									onChange={onIncludeAgencyEmployeesChange}
								></FormSwitch>

								<FormSwitch
									id="settings-include-hire-assets"
									loading={loading}
									disabled={saving}
									headingText="Include hire assets"
									helperText="Switching this option on will include hire assets in your risk calculations."
									checked={settings.includeHireAssets}
									onChange={onIncludeHireAssetsChange}
								></FormSwitch>
							</Fragment>
						)}
					></ShowHide>

					<AlertUnsavedData
                        isDirty={isSettingsDirty}>
                    </AlertUnsavedData>

                    <Modal
						isOpen={showDeleteRiskDataModal}
						clickOutsideClose={!saving}
                        title={'Delete Risk Data?'}
                        onClose={onDeleteRiskDataCancel}
                    >

                        <div className="bp3-dialog-body">
                            <p>Enter the phase 'confirm delete' to delete all risk data:</p>
                            <FormTextInput
                                value={deleteRiskDataPhrase}
                                onChange={onDeleteRiskDataPhraseChange}
                            />
                        </div>

                        <div className="bp3-dialog-footer">

							<div className="bp3-dialog-footer-actions">
								<Button 
									text="Cancel" 
									intent="none" 
									disabled={saving} 
									onClick={onDeleteRiskDataCancel}
								></Button>
								<ButtonSave
									id="journey-type-save"
									onClick={deleteRiskData}
									intent="danger"
									icon="trash"
									simpleDisabled={deleteRiskDataPhrase !== requiredDeleteRiskDataPhrase}
									disabled={saving}
									text={'Delete'}
								></ButtonSave>
							</div>
                        </div>


					</Modal>

					<Modal
						isOpen={showChangeModel}
						title={"Configuration Changes"}
						onClose={onChangeModalDismiss}
					>
						<p>The configuration has been modified during the current period and the following changes will take place from the start of your next risk period:</p>
						<div className="inline-items spacer-small">
							<div className="inline-item"><strong>Enabled:</strong></div>
							<div className="inline-item">{serverSettings?.enabled ? "Yes" : "No"}</div>
							<div className="inline-item"><Icon icon="arrow-right" /></div>
							<div className="inline-item">{serverSettings?.pendingChange?.enabled ? "Yes" : "No"}</div>
						</div>
						<div className="inline-items">
							<div className="inline-item"><strong>Include agency employees:</strong></div>
							<div className="inline-item">{serverSettings?.includeAgencyEmployees ? "Yes" : "No"}</div>
							<div className="inline-item"><Icon icon="arrow-right" /></div>
							<div className="inline-item">{serverSettings?.pendingChange?.includeAgencyEmployees ? "Yes" : "No"}</div>
						</div>
						<div className="inline-items spacer-small">
							<div className="inline-item"><strong>Include hire assets:</strong></div>
							<div className="inline-item">{serverSettings?.includeHireAssets ? "Yes" : "No"}</div>
							<div className="inline-item"><Icon icon="arrow-right" /></div>
							<div className="inline-item">{serverSettings?.pendingChange?.includeHireAssets ? "Yes" : "No"}</div>
						</div>
					</Modal>

					<div className="button-row">
						<ButtonSave
							id="btn-settings-update"
							onClick={updateSettings}
							disabled={saving && !showDeleteRiskDataModal}
							loading={loading}
						></ButtonSave>

						<ShowHide
							evaluator={canDelete}
							show={(
								<Button 
									text="Delete all data" 
									intent="danger" 
									disabled={saving}
									loading={loading} 
									onClick={onDeleteRiskDataClick}>
									
								</Button>
							)}
						></ShowHide>
					</div>
				</div>
			)}
			no={() => (
				<PageRestricted />
			)}
		/>
	);

}

RiskGeneralSettings.propTypes = {
	onEnabledChange: PropTypes.func.isRequired
};

import React, { Fragment, useEffect, useState } from "react";
import { Intent } from "@blueprintjs/core";

import { ButtonSave } from "components/buttons";
import { useDirtyData } from "hooks/useDirtyData";
import { DebriefGeneralSettings } from "pages/employee-settings/debrief-settings/general-settings";
import { DebriefEventSettings } from "pages/employee-settings/debrief-settings/event-settings";
import { PageRestricted } from "pages/errors/page-restricted";
import { UserAccess } from "components/user-access";
import { AlertUnsavedData, NotificationToaster } from "components/notifications";
import { DebriefService } from "services";
import { HelperFunctions } from "helpers";
import { ShowHide } from "components/layout";
import { Tabs } from "components/navigation";

export function DebriefSettings() {
    const defaultDebriefSettings = {
        debriefLevelsActive: true,
        debriefLevelsMandatory: false,
        startDate: null,
        customStartDate: false,
        customStartDateOverideAllowed: false,
        levels: [],
        events: []
    };
    const [debriefSettings, setDebriefSettings] = useState(defaultDebriefSettings);
    const [originalDebriefSettings, setOriginalDebriefSettings] = useState(defaultDebriefSettings);
    const [currentTab, setCurrentTab] = useState(0);
    const [loading, setLoading] = useState(true);
    const [saving, setSaving] = useState(false);
    const [generalSettingsValid, setGeneralSettingsValid] = useState(true);
    const [eventSettingsValid, setEventSettingsValid] = useState(true);

    const [tabs, setTabs] = useState([
        { key: "GENERAL", title: "General Settings", selected: true },
        { key: "EVENTS", title: "Event Settings" },
    ]);

    const isDataDirty = useDirtyData(originalDebriefSettings, debriefSettings);

    useEffect(onInitialLoad, []);

    function onInitialLoad() {

        DebriefService.getDebriefSettings().then(function (settingsResponse) {
            setDebriefSettings(settingsResponse);
            setOriginalDebriefSettings(HelperFunctions.deepClone(settingsResponse));
            setLoading(false);
        });
    }

    function onTabClick(index) {

        var clonedTabs = [
            ...tabs
        ];

        clonedTabs = clonedTabs.map(function (t, i) {
            return {
                ...t,
                selected: index === i
            }
        });

        setTabs(clonedTabs);
        setCurrentTab(index);
    }

    function saveSettings() {

        setSaving(true);

        DebriefService.saveDebriefSettings(debriefSettings).then(function () {
            NotificationToaster.show(Intent.SUCCESS, "Settings updated successfully.");
            setOriginalDebriefSettings(HelperFunctions.deepClone(debriefSettings));
        }, function (errorMessage) {
            NotificationToaster.show(Intent.DANGER, errorMessage);
        }).finally(function () {
            setSaving(false);
        });
    }

    function onSettingsUpdate(newSettings) {
        setDebriefSettings(newSettings);
    }

    function onGeneralValidationUpdate(isValid) {
        setGeneralSettingsValid(isValid);
    }

    function onEventValidationUpdate(isValid) {
        setEventSettingsValid(isValid);
    }

    return (
         <UserAccess perform={["Debrief:Manage"]}
            yes={() => (
                <div className="row">
                    <h1>Debrief Settings</h1>
                    <div className="intro-text">
                        <p>In this section you can configure your debrief settings.</p>
                    </div>
                   
                    <div className="spacer-bottom">
                        <Tabs tabs={tabs} onClick={onTabClick} loading={loading} />
                    </div>

                    <ShowHide
                        evaluator={currentTab === 0}
                        show={(
                            <DebriefGeneralSettings onUpdate={onSettingsUpdate} settings={debriefSettings} loading={loading} saving={saving} onValidationUpdate={onGeneralValidationUpdate} />
                        )}
                    />

                    <ShowHide
                        evaluator={currentTab === 1}
                        show={(
                            <DebriefEventSettings onUpdate={onSettingsUpdate} settings={debriefSettings} loading={loading} saving={saving} onValidationUpdate={onEventValidationUpdate} />
                        )}
                    />

                    <Fragment>
                        <ButtonSave
                            loading={loading}
                            onClick={saveSettings}
                            disabled={saving}
                            simpleDisabled={!eventSettingsValid || !generalSettingsValid}
                            id="btn-save-debrief-settings"
                        ></ButtonSave>
                    </Fragment>
                    <AlertUnsavedData
                        isDirty={isDataDirty}
                    ></AlertUnsavedData>
                </div>
            )}
            no={() => (
                <PageRestricted />
            )}
        />
       
    );
}
export const SetUserActions = (actions) => {
    return {
        type: "SET_USER_ACTIONS",
        payload: actions
    }
}

export const SetDisplayName = (displayName) => {
    return {
        type: "SET_DISPLAY_NAME",
        payload: displayName
    }
}

export const SetUserMemberNumber = (memberNumber) => {
    return {
        type: "SET_USER_MEMBER_NUMBER",
        payload: memberNumber
    }
}

export const SetUserMemberName = (memberName) => {
    return {
        type: "SET_USER_MEMBER_NAME",
        payload: memberName
    }
}

export const SetUserEmail = (email) => {
    return {
        type: "SET_USER_EMAIL",
        payload: email
    }
}

export const SetUserID = (userID) => {
    return {
        type: "SET_USER_ID",
        payload: userID
    }
}

export const SetUserAccountId = (accountId) => {
    return {
        type: "SET_USER_ACCOUNT_ID",
        payload: accountId
    }
}

export const SetUserPreviousLoginDate = (previousLoginDate) => {
    return {
        type: "SET_USER_PREVIOUS_LOGIN_DATE",
        payload: previousLoginDate
    }
}

export const SetFiltering = (filterName, filters) => {
    return {
        type: "SET_FILTER",
        name: filterName,
        payload: filters
    }
}

export const SetRouting = (title, route, id) => {
    return {
        type: "SET_ROUTE",
        title: title,
        route: route,
        id: id
    }
}
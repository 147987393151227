import React, { Fragment, useState, useEffect } from 'react';
import { Intent } from '@blueprintjs/core'
import { FormSelect, FormSwitch, FormTextArea, FormTextInput } from "components/form-fields";
import { NotificationInline, NotificationToaster, AlertUnsavedData } from "components/notifications";
import { ButtonSave } from "components/buttons";
import { ShowHide } from "components/layout";
import { useValidation } from "hooks/useValidation";
import { useDirtyData } from "hooks/useDirtyData";
import { AccountService } from "services";
import { UserAccess } from 'components/user-access';


var _ = require('underscore');

export function AccountGeneralSettings(props) {
	const [generalSettings, setGeneralSettings] = useState({
		companyMessage: "",
		sectorId: null,
		allowDriverAppAccess: false,
		driverAppActivationCode: ""
	});
	const [originalValues, setOriginalValues] = useState({});
	const [sectors, setSectors] = useState([]);
	const [showSectorDanger, setSectorDanger] = useState(false);
	const [loading, setLoading] = useState(true);
	const [saving, setSaving] = useState(false);
	const isDataDirty = useDirtyData(originalValues, generalSettings);
	const [isValid, errors, validate] = useValidation();

	useEffect(initialLoad, []);
	useEffect(validateModelIfDirty, [isDataDirty, generalSettings]);

	function initialLoad() {

		function getGeneralSettings() {
			AccountService.getGeneralSettings().then(
				(settings) => {
					setGeneralSettings(settings);
					setOriginalValues(_.extend({}, settings));
					setLoading(false);
				},
				(error) => {
					NotificationToaster.show(Intent.DANGER, "Could not load settings. Please refresh the page.")
				}
			);
		}

		AccountService.getSectors().then(
			(data) => {
				setSectors(data);
				getGeneralSettings();
			},
			(error) => {
				NotificationToaster.show(Intent.DANGER, "Could not load sectors. Please refresh the page.");
			});
	}	

	function saveGeneralSettings() {
		setSaving(true);
		if (!isValid) {
			NotificationToaster.show(Intent.WARNING, "Settings not valid. Please check your settings and try again.");
			setSaving(false);
			return;
		}
		AccountService.saveGeneralSettings(generalSettings).then(
			() => {
				setOriginalValues(_.extend({}, generalSettings));
				setSaving(false);
				NotificationToaster.show(Intent.SUCCESS, "Settings updated successfully.");
			},
			(error) => {
				setSaving(false);
				NotificationToaster.show(Intent.DANGER, "Unable to update settings. Please try again later.");				
			});		
	}	

	function handleMessageChange(event) {
		setGeneralSettings({
			...generalSettings,
			companyMessage: event.target.value
		});		
	}

	function onSectorChange(item, originalValue) {		
		if (originalValue !== null) {
			setGeneralSettings({
				...generalSettings,
				sectorId: item.id
			});
			setSectorDanger(item.id !== originalValues.sectorId);			
		}		
	}

	function onAppAccessChange(event) {
		setGeneralSettings({
			...generalSettings,
			allowDriverAppAccess: event.target.checked
		});		
	}

	function validateModelIfDirty() {
		if (isDataDirty) {
			validateModel();
		}
	}

	function validateModel() {
		const rules = getRules();
		validate(rules, generalSettings);
	}

	function getRules() {
		let rules = [
			{ fieldName: "sectorId", required: true }
		];
		return rules;
	}
	
	return (
		<div className="row">
			<h2>General Settings</h2>				
			<FormSelect
				loading={loading}
				disabled={saving}
				items={sectors}
				onItemSelect={onSectorChange}
				placeholder="Select a sector"
				headingText="The sector of your organisation:"
				helperText={(<p>This is used to compare you against similar operators in our dashboards and self-service reporting suites.</p>)}
				dangerHelperText={errors.sectorId}
				selectedValue={generalSettings.sectorId}>
			</FormSelect>
			<NotificationInline
				loading={loading}
				show={showSectorDanger}
				text="By changing your default sector, you are altering all your historic data benchmarks against the sector average."
				intent="danger">
			</NotificationInline>
			<FormTextArea
				maxLength={255}
				disabled={saving}
				loading={loading}
				onChange={handleMessageChange}
				helperText={(<p>This will appear when you and your team log in to Vision.</p>)}
				headingText="Set Vision message to your company users:"
				value={generalSettings.companyMessage}>
			</FormTextArea>

			<UserAccess perform={["DriverApp:Approve"]}
				yes={() => (
					<Fragment>
						<FormSwitch
							disabled={saving}
							loading={loading}
							label="Vision Driver App access:"
							helperText={(
								<div>
									<p>Once you have turned on Vision Driver App access, a Driver activation code will be displayed. This will be required by drivers within your organisation when they register for access via the app.</p>
									<p>Once a driver has requested access via the app, an alert will be displayed within Vision allowing you to authorise or decline the request.</p>
								</div>
							)}
							checked={generalSettings.allowDriverAppAccess}
							onChange={onAppAccessChange}>
						</FormSwitch>
						<ShowHide evaluator={generalSettings.allowDriverAppAccess}
							show={(
								<Fragment>
									<FormTextInput
										loading={loading}
										headingText="Driver activation code:"
										disabled={true}
										value={generalSettings.driverAppActivationCode}>
									</FormTextInput>
								</Fragment>
							)}>
						</ShowHide>
					</Fragment>
				)}
			/>

			<AlertUnsavedData
				isDirty={isDataDirty}>
			</AlertUnsavedData>
			<ButtonSave
				loading={loading}
				onClick={saveGeneralSettings}
				disabled={saving}
			></ButtonSave>					
		</div>
	);
	
}
import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { ShowHide } from "components/layout";
import './Panel.css';

export function Panel(props){
    function onPanelClick(){
        if (!props.disabled) {
            props.onClick(props.id);
        }
    }
    return (
        <div className={classNames('box-panel', {"box-panel-selected": props.selected }, { "panel-disabled bp3-skeleton": props.disabled }, props.className)}
            id={props.id}
            onClick={onPanelClick}
            aria-hidden="true">
            <ShowHide evaluator={props.color}
                show={<div className='box-panel-color-bar' style={{ backgroundColor: props.color }}></div>} 
            />
            <div className={classNames({'box-panel-content' : !props.isTab }, {'box-panel-after-color-bar' : props.color !== ""})}>
                {props.children}
            </div>
        </div>
    );
}

Panel.defaultProps = {
    id: null,
    color: "",
    selected: false,
    disabled: false,
    onClick: () => { },
    isTab: false
};

Panel.propTypes = {
    id: PropTypes.string,
    onClick: PropTypes.func,
    disabled: PropTypes.bool,
    className: PropTypes.string,
    color: PropTypes.string,
    isTab: PropTypes.bool,
    selected: PropTypes.bool
};
import React from 'react';
import PropTypes from 'prop-types';
import { FormRadio } from "components/form-fields";
import { NotificationInline } from "components/notifications";

export function UserPermissionsSelection(props) {

    const userPermissionsOptions = [
        { value: "true", label: "Users should keep all locations they currently inherit" },
        { value: "false", label: "Users should lose all locations they currently inherit" }
    ]

    function onConvertUserPermissionsChange(item) {
        props.onConvertUserPermissionsChange(item.currentTarget.value === "true");
    }

    return (
        <div className="spacer-top-small">
            <NotificationInline show allowClose={false} intent="danger" text={props.alertText} />
            <FormRadio
                onChange={onConvertUserPermissionsChange}
                selectedValue={props.value}
                options={userPermissionsOptions}
            ></FormRadio>
            <p><strong>Note: Users who have been given access directly to a location, or inherit from 'all locations' will still have access to those locations regardless of your choice above.</strong></p>
        </div>
    );
}

UserPermissionsSelection.defaultProps = {
    disabled: false,
    alertText: ""
};

UserPermissionsSelection.propTypes = {
    disabled: PropTypes.bool,
    onConvertUserPermissionsChange: PropTypes.func.isRequired,
    value: PropTypes.string,
    alertText: PropTypes.string
};
import ApiService from 'services/ApiService';

const badRequestCode = 400;
const incidentSystemMessageTypeId = 2;

export const SystemMessagesService = {
    getAllSystemMessages(isCurrent) {
        return new Promise((resolve, reject) => {
            ApiService.get(`/system/SystemMessages?current=${isCurrent}`).then(
                (result) => {
                    resolve(result.data);
                },
                (error) => {
                    reject(error);
                });
        });
    },
    getSystemMessage(systemMessageId) {
        return new Promise((resolve, reject) => {
            ApiService.get(`/system/SystemMessages/${systemMessageId}`).then(
                (result) => {
                    resolve(result.data);
                },
                (error) => {
                    reject(error);
                });
        });
    },
    saveSystemMessage(systemMessage) {
        systemMessage = cleanIncidentTypeSystemMessage(systemMessage);
        systemMessage = encodeUrl(systemMessage)
        return new Promise((resolve, reject) => {
            ApiService.post('/system/SystemMessages', systemMessage)
                .then((response) => {
                    resolve(response);
                })
                .catch((error) => {
                    if (error.response.status === badRequestCode) {
                        reject(error.response);
                    } else {
                        reject(error);
                    }
                })
        });
    },
    updateSystemMessage(systemMessageId, systemMessage) {
        systemMessage = cleanIncidentTypeSystemMessage(systemMessage);
        systemMessage = encodeUrl(systemMessage)
        return new Promise((resolve, reject) => {
            ApiService.put(`/system/SystemMessages/${systemMessageId}`, systemMessage)
                .then((response) => {
                    resolve(response);
                })
                .catch((error) => {
                    if (error.response.status === badRequestCode) {
                        reject(error.response);
                    } else {
                        reject(error);
                    }
                })
        });
    }
}



function cleanIncidentTypeSystemMessage(systemMessage) {
    if(systemMessage.systemMessageTypeID !== incidentSystemMessageTypeId) {
        return systemMessage;
    }

    systemMessage.startDate = null;
    systemMessage.endDate = null;

    return systemMessage;
}

function encodeUrl(systemMessage) {
    if(systemMessage.url === "") {
        return systemMessage;
    }

    systemMessage.url = encodeURIComponent(systemMessage.url);
    return systemMessage;
}
import React, { Fragment, useState, useEffect } from 'react';
import { Route, Switch, Redirect } from 'react-router-dom'
import 'pages/risk/risk.css';

import { VerticalLinkGroup } from 'components/navigation';
import { PageRestricted } from 'pages/errors/page-restricted';
import { UserAccess } from 'components/user-access';
import { RiskGeneralSettings } from './RiskGeneralSettings';
import { RiskEventSettings } from './RiskEventSettings';
import { RiskBandSettings } from './RiskBandSettings';
import { RiskService } from 'services/RiskService';
import { RiskSettingsHistory } from 'pages/risk/settings/RiskSettingsHistory';

export function RiskSettings() {

    const [routes, setRoutes] = useState([]);
    const [riskStarted, setRiskStarted] = useState(false);
    const [riskEnabled, setRiskEnabled] = useState(false);

    useEffect(init, []);

    function init() {
        RiskService.getSettings().then(function (generalSettings) {
            setRiskStarted(generalSettings.riskInProgress);
            setRiskEnabled(generalSettings.enabled);
        });
    }

    function onEnabledChange(enabled) {
        setRiskEnabled(enabled);
    }

    useEffect(function () {

        var menuRoutes = [{ location: "/risk-settings/general", title: "General Settings" }];

        if (riskEnabled) {
            menuRoutes.push(
                { location: "/risk-settings/events/employee", title: "Employee Event Settings" },
                { location: "/risk-settings/events/asset", title: "Asset Event Settings" },
                { location: "/risk-settings/bands", title: "Band Settings" }
            );
        }

        menuRoutes.push(
            { location: "/risk-settings/history", title: "History" }
        )

        setRoutes(menuRoutes);

    }, [riskEnabled]);

    return (
        <UserAccess perform={["Risk:Manage"]}
            yes={() => (
                <Fragment>
                    <div className="row">
                        <h1>Risk Settings</h1>
                        <div className="intro-text">
                            <p>In this section you can configure your risk settings to create a risk score suits to your organisation.</p>
                        </div>
                    </div>

                    <div className="layout-cols">
                        <div className="col side-col">
                            <div className="row">
                                <VerticalLinkGroup links={routes} exactRouteMatch={false}></VerticalLinkGroup>
                            </div>
                        </div>
                        <div className="col content-col">
                            <Switch>
                                <Route path="/risk-settings/general">
                                    <RiskGeneralSettings onEnabledChange={onEnabledChange}></RiskGeneralSettings>
                                </Route>
                                
                                <Route exact path="/risk-settings" render={() => (<Redirect to="/risk-settings/general" />)} />
                                <Route path="/risk-settings/bands">
                                    <RiskBandSettings riskStarted={riskStarted}></RiskBandSettings>
                                </Route>
                                <Route path="/risk-settings/events/employee">
                                    <RiskEventSettings areaId={1} areaName="Employee" riskStarted={riskStarted}></RiskEventSettings>
                                </Route>
                                <Route path="/risk-settings/events/asset">
                                    <RiskEventSettings areaId={2} areaName="Asset" riskStarted={riskStarted}></RiskEventSettings>
                                </Route>
                                <Route path="/risk-settings/history">
                                    <RiskSettingsHistory></RiskSettingsHistory>
                                </Route>
                            </Switch>
                        </div>
                        


                    </div>
                </Fragment>
            )}
            no={() => (
                <PageRestricted />
            )}
        />

    );

}
import React from 'react';
import PropTypes from 'prop-types';
import { Popover, Menu, PopoverPosition } from "@blueprintjs/core";
import { Button } from 'components/buttons/Button';
import './dropdown.css';

export function Dropdown(props) {
    function handleButtonClick() {
        if (props.onClick) {
            props.onClick();
        }
    }
    const menuItems = (
        <>
            {props.preamble && <div className='dropdown-preamble'>{props.preamble}</div>}
            <Menu>
                {props.items.map(item => (item))}
            </Menu>
        </>
    );
    let popoverPosition = PopoverPosition[props.popoverPosition];

    return (
        <span className="dropdown-container">
            <Popover content={menuItems} position={popoverPosition} usePortal={props.popoverUsePortal}  >
                <Button
                    alignText="left"
                    rightIcon={props.showArrow ? "caret-down" : null}
                    className={props.className}
                    icon={props.icon}
                    loading={props.loading}
                    disabled={props.disabled}
                    minimal={props.minimal}
                    large={props.large}
                    intent={props.intent}
                    id={props.id}
                    text={props.text}
                    iconOnly={props.iconOnly}
                    onClick={handleButtonClick}
                    size={props.size}
                />
            </Popover>
        </span>
    );
}

Dropdown.defaultProps = {
    id: null,
    intent: "",
    loading: false,
    disabled: false,
    icon: null,
    iconOnly: false,
    text: "",
    minimal: false,
    large: true,
    items: [],
    preamble: null,
    popoverUsePortal: true,
    onClick: () => { },
    showArrow: true,
    popoverPosition: "BOTTOM"
};

Dropdown.propTypes = {
    id: PropTypes.string,
    intent: PropTypes.string,
    loading: PropTypes.bool,
    onClick: PropTypes.func,
    disabled: PropTypes.bool,
    icon: PropTypes.string,
    iconOnly: PropTypes.bool,
    text: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.object
    ]),
    minimal: PropTypes.bool,
    large: PropTypes.bool,
    className: PropTypes.string,
    items: PropTypes.array.isRequired,
    preamble: PropTypes.object,
    popoverUsePortal: PropTypes.bool,
    showArrow: PropTypes.bool,
    popoverPosition: PropTypes.string
};
import React, { useState, useEffect } from 'react';
import axios from 'axios';

import { Intent, Icon } from '@blueprintjs/core';
import { Breadcrumbs } from 'components/navigation';
import { NotificationToaster, AlertUnsavedData } from "components/notifications";
import { LinkButton, Button } from "components/buttons";
import { ShowHide } from "components/layout";
import { Wizard } from "components/wizard";
import { useDirtyData } from 'hooks/useDirtyData';
import { UserService } from "services";
import { UserBasicSettings, UserRoleSettings } from 'components/settings';
import { UserAccess } from 'components/user-access';
import { PageRestricted } from 'pages/errors/page-restricted';
import { UserLocationSettings } from 'pages/users/edit/shared';

export function UserCreate(props) {

    const userModel = {
        "emailAddress": "",
        "firstName": "",
        "lastName": "",
        "phoneNumber": "",
        "secondaryPhoneNumber": "",
        "role": {},
        "actionRestrictions": []
    };

    const [originalSettings, setOriginalSettings] = useState(userModel);
    const [basicSettingsValid, setBasicSettingsValid] = useState(false);
    const [roleSettingsValue, setRoleSettingsValid] = useState(false);
    const [loading, setLoading] = useState(true);
    const [saving, setSaving] = useState(false);
    const [userCreated, setUserCreated] = useState(false);
    const [user, setUser] = useState(userModel);
    const [userLocationPermissions, setUserLocationPermissions] = useState([]);
    const [wizardStep, setWizardStep] = useState(0);
    const [wizardValid, setWizardValid] = useState(false);
    const isUserDirty = useDirtyData(originalSettings, user);

    const requiredAction = "Users:Manage";
    const basicSettingsStep = 0;
    const roleStep = 1;
    const permissionsStep = 2;

    useEffect(initialLoad, []);
    function initialLoad() {
        setLoading(false);
    }

    function updateUser(updatedUser) {
        setUser(updatedUser);
    }

    function updateUserLocationPermissions(permissions) {
        setUserLocationPermissions(permissions);
    }

    function saveUser() {
        setSaving(true);

        UserService.createUser(user).then(
            (userId) => {

                var userUpdates = [UserService.updateLocationPermissions(userId, userLocationPermissions)];

                if (user.actionRestrictions != null && user.actionRestrictions.length > 0) {
                    userUpdates.push(UserService.restrictActions(userId, user.actionRestrictions));
                }

                axios.all(userUpdates).then(function () {
                    setSaving(false);
                    setUserCreated(true);
                }, function () {
                    setSaving(false);
                    setUserCreated(true);
                    NotificationToaster.show(Intent.WARNING, "The user has been created, but some of the settings could not be applied. Please edit the user to complete setup.");
                });
               
                setOriginalSettings(user);
            },
            (error) => {
                setSaving(false);
                NotificationToaster.show(Intent.DANGER, error);
            }
        );
    }
    function resetComponent() {
        setUser(userModel);
        setOriginalSettings(userModel);
        initialLoad();
        setSaving(false);
        setUserCreated(false);
        setBasicSettingsValid(false);
        setRoleSettingsValid(false);
        setUserLocationPermissions([]);
        setWizardStep(0);
    }
    function onBasicValidationUpdate(isValid) {
        setBasicSettingsValid(isValid);
    }

    function onRoleValidationUpdate(isValid) {
        setRoleSettingsValid(isValid);
    }

    function onWizardChange(step) {
        setWizardStep(step);
    }

    useEffect(setIfWizardValid, [wizardStep, basicSettingsValid, roleSettingsValue]);

    function setIfWizardValid() {
        var valid = (wizardStep === basicSettingsStep && basicSettingsValid) || (wizardStep === roleStep && roleSettingsValue) || wizardStep === permissionsStep;
        setWizardValid(valid);
    }

    return (

        <UserAccess perform={requiredAction}
            yes={() => (

                <div className="row">
                    <Breadcrumbs items={props.breadcrumbs} />
                    <ShowHide
                        evaluator={!userCreated}
                        show={(
                            <div>
                                <Wizard onStepChange={onWizardChange} onFinish={saveUser} canProceed={wizardValid} disabled={saving}>

                                    <div>
                                        <h1 id="header-new-user">Add in the new user's details</h1>
                                        <UserBasicSettings user={user} loading={loading} editing={false} onSettingsUpdate={updateUser} saving={saving} onValidationUpdate={onBasicValidationUpdate} />
                                    </div>
                                    <div>
                                        <h1 id="header-new-user">What role would you like {user.firstName} to have?</h1>
                                        <UserRoleSettings settings={user} onSettingsUpdate={updateUser} onValidationUpdate={onRoleValidationUpdate} />
                                    </div>
                                    <div>
                                        <h1 id="header-new-user">What locations should the user have access to?</h1>
                                        <UserLocationSettings permissions={userLocationPermissions} onPermissionsUpdate={updateUserLocationPermissions} />
                                    </div>

                                </Wizard>
                                <AlertUnsavedData
                                    isDirty={isUserDirty}>
                                </AlertUnsavedData>

                            </div>
                        )}
                        hide={(
                            <div>
                                <div className="spacer-bottom" id="create-success-icon">
                                    <Icon icon="tick-circle" iconSize={40} intent={Intent.SUCCESS} />
                                </div>
                                <h2 id="create-success-name">{user.firstName != null && user.firstName.length > 0 ? user.firstName : user.emailAddress} has been added as a user</h2>
                                <div className="spacer-bottom">
                                    <p>They will shortly receive an email, asking them to confirm their account and create a password for login. Until then they will not be able to access Vision.</p>
                                </div>
                                <div className="button-row" id="create-success-buttons">
                                    <LinkButton intent="primary" text="User management" id="listing-user" href="/user" />
                                    <Button intent="primary" text="Add new user" id="new-user" onClick={resetComponent} />
                                </div>
                            </div>
                        )}
                    ></ShowHide>

                </div>
            
            )}
            no={() => (
                <PageRestricted />
            )}
        />

        
    );
}
import React from 'react';
import PropTypes from 'prop-types';
import { Tag, Intent } from "@blueprintjs/core";
import './Status.css';

export function TagList(props) {

    return (
        <div className="tag-list spacer-bottom-small">
            {props.items && props.items.map((item) => {
                return (
                    <Tag key={item.name} intent={Intent.PRIMARY} large={true} onRemove={props.disabled || props.onRemoveTag == null ? null : (event) => { props.onRemoveTag(event, item.id) }}>{item.name}</Tag>
                )
            })}
        </div>
    );
}

TagList.defaultProps = {
    disabled: false,
    items: [],
    onRemoveTag: null
};

TagList.propTypes = {
    disabled: PropTypes.bool,
    items: PropTypes.array,
    onRemoveTag: PropTypes.func
};
import React from 'react';
import { UserAccess } from 'components/user-access';
import { PageRestricted } from 'pages/errors/page-restricted';
import { RiskListing } from 'pages/risk/shared';

export function RiskEmployeeListing(props) {
    const tableHeaders = ["Employee", "Risk Score", "Trend", "Location Position", "Company Position", "Location"];
    const sortableHeaders = [
        { headerName: "Employee", sortName: "EMPLOYEENAME" },
        { headerName: "Risk Score", sortName: "SCORE" },
        { headerName: "Trend", sortName: "TREND" },
        { headerName: "Location Position", sortName: "LOCATIONPOSITION" },
        { headerName: "Company Position", sortName: "COMPANYPOSITION" },
        { headerName: "Location", sortName: "LOCATION" },
    ];

    return (

        <UserAccess perform={["Risk:View"]}
            yes={() => (
                <div className="row">

                    <RiskListing
                        title="Employee Risk"
                        riskEntityController="Employees"
                        searchInputPlaceholder="Employee Search"
                        sortableHeaders={sortableHeaders}
                        tableHeaders={tableHeaders}
                        breadcrumbs={props.breadcrumbs}
                    />

                </div>
            )}
            no={() => (
                <PageRestricted />
            )}
        />
    );

}
import React, { Fragment, useEffect, useState } from "react";
import { useParams } from 'react-router-dom';
import { Intent } from '@blueprintjs/core';
import * as JSZip from 'jszip';
import axios from "axios";

import { UserAccess, UserHasAccess } from 'components/user-access';
import { Breadcrumbs } from 'components/navigation';
import { Button, LinkButton, ButtonSave } from 'components/buttons';
import { Collapsable, ShowHide } from "components/layout";
import { PageRestricted } from 'pages/errors/page-restricted';
import { DataDisplayTable } from 'components/data-display';
import { AlertConfirm, NotificationToaster, NotificationInline } from "components/notifications";
import { BlobStorageService, SelfReportingService } from "services";
import { DateToLocal } from "components/dates";
import { HelperFunctions } from "helpers";

export function ScheduledReport(props) {

    const { id } = useParams();
    const requiredAction = "ScheduleReports:View";
    const canAccessPage = UserHasAccess(requiredAction);

    const [scheduleInstance, setScheduleInstance] = useState({});
    const [loadingData, setLoadingData] = useState(true);
    const [invalidInstance, setInvalidInstance] = useState(false);
    const [headerSections, setHeaderSections] = useState([]);
    const [showRerunConfirm, setShowRerunConfirm] = useState(false);
    const [reports, setReports] = useState([]);
    const [downloadingReport, setDownloadingReport] = useState(false);
    const [downloadingAllReports, setDownloadingAllReports] = useState(false);
    const [hasDownloadableReports, setHasDownloadableReports] = useState(false);

    function onCollapsableClick(index) {
        var clonedReports = [
            ...reports
        ];

        var selectedSection = clonedReports[index];
        selectedSection.expanded = !selectedSection.expanded;

        setReports(clonedReports);

    }

    useEffect(() => {

        if (!canAccessPage) {
            return;
        }

        if (loadingData) {
            SelfReportingService.getScheduleInstance(id).then(r => {
                setScheduleInstance(r);

                var headers = [
                    { header: "Report generation date", value: <DateToLocal>{r.runDate}</DateToLocal> }
                ];

                if (r.schedule != null) {
                    headers.push({ header: "Schedule", value: r.schedule.scheduleName });
                    headers.push({ header: "Next run date", value: <DateToLocal>{r.schedule.nextRunDate}</DateToLocal> });
                    headers.push({ header: "Schedule total runs", value: r.schedule.totalRuns });
                }

                setHeaderSections(headers);
                setReports(r.reports.map(function (report, index) {
                    return {
                        ...report,
                        expanded: index === 0 ? true : false
                    };
                }));
                setHasDownloadableReports(r.reports.some((report) => report.generationState === "COMPLETE"))
                setLoadingData(false);

            }, (error) => {
                const noAccessCode = 403;

                if (error.status === noAccessCode) {
                    setInvalidInstance(true);
                }
            });
        }
    }, [loadingData, id]);

    function onRerunSchedule() {
        setShowRerunConfirm(true);
    }

    function onRerunConfirm() {
        setShowRerunConfirm(false);

        SelfReportingService.rerunScheduleForUser(id).then(function () {
            NotificationToaster.show(Intent.SUCCESS, "Schedule re-run successfully created");
        }, function () {
            NotificationToaster.show(Intent.DANGER, "Unable to re-run the schedule, please try again.");
        });

    }

    function onRerunCancel() {
        setShowRerunConfirm(false);
    }

    function onDownloadAllReports() {
        setDownloadingAllReports(true);

        var tokenRequests = [];

        scheduleInstance.reports.forEach(function (r) {
            if (r.generationState === "COMPLETE") {
                tokenRequests.push(SelfReportingService.getScheduledReportToken(id, r.reportGenerationId));
            }
        });

        axios.all(tokenRequests).then(function (tokens) {

            var blobRequests = [];

            tokens.forEach(function (response) {
                blobRequests.push(BlobStorageService.fetchFile(response.storageName, response.containerName, response.fileName, response.token));
            });

            axios.all(blobRequests).then(function (blobs) {

                const zip = new JSZip();

                tokens.forEach(function (token, index) {
                    zip.file(token.downloadName, blobs[index]);
                });

                zip.generateAsync({ type: "blob" }).then(function (zipContents) {
                    HelperFunctions.downloadFile(zipContents, `${HelperFunctions.kebabCase(scheduleInstance.instanceName)}.zip`);
                    setDownloadingAllReports(false);
                }, function () {
                    NotificationToaster.show(Intent.DANGER, "Unable to download your reports, please try again.");
                    setDownloadingAllReports(false);
                });

            }, function () {
                NotificationToaster.show(Intent.DANGER, "Unable to download your reports, please try again.");
                setDownloadingAllReports(false);
            });
            

        }, function () {
            NotificationToaster.show(Intent.DANGER, "Unable to download your reports, please try again.");
            setDownloadingAllReports(false);
        });
    }

    function onDownloadReport(reportInstanceId) {
        setDownloadingReport(true);
        SelfReportingService.getScheduledReportToken(id, reportInstanceId).then(function (response) {
            BlobStorageService.downloadFile(response.storageName, response.containerName, response.fileName, response.token, response.downloadName).then(function () {
                NotificationToaster.show(
                    Intent.SUCCESS,
                    "Report has been fetched successfully, download will now begin"
                );
                setDownloadingReport(false);
            }, function () {
                NotificationToaster.show(Intent.DANGER, "Unable to download your report, please try again.");
                setDownloadingReport(false);
            });
        }, function () {
            NotificationToaster.show(Intent.DANGER, "Unable to download your report, please try again.");
            setDownloadingReport(false);
        })


    }

    return (

        <UserAccess perform={requiredAction}
            yes={() => (

                <ShowHide
                    evaluator={invalidInstance}
                    show={(
                        <div className="row">
                            <Breadcrumbs items={props.breadcrumbs} />
                            <NotificationInline
                                allowClose={false}
                                show
                                text="You do not have permission to view this report"
                                intent="danger">
                            </NotificationInline>
                            <div>
                                <LinkButton intent="primary" text="Back to scheduled reports" href="/scheduled-reports" id="return-report-listing" />
                            </div>
                        </div>
                    )}
                    hide={(
                        <Fragment>
                            <div className="row">
                                <Breadcrumbs items={props.breadcrumbs} />

                                <h1>{scheduleInstance.instanceName}</h1>
                                <div className="button-row spacer-bottom">
                                    <ShowHide
                                        evaluator={hasDownloadableReports}
                                        show={(
                                            <ButtonSave text="Download all reports" icon="download" onClick={onDownloadAllReports} id="schedule-download-reports" disabled={downloadingAllReports} simpleDisabled={downloadingReport} loading={loadingData} />
                                        )}
                                    />
                                    <ShowHide
                                        evaluator={!scheduleInstance.rerun && scheduleInstance.instanceComplete}
                                        show={(
                                            <Button text="Re-run schedule" icon="refresh" onClick={onRerunSchedule} id="schedule-re-run" disabled={downloadingReport || downloadingAllReports} loading={loadingData} />
                                        )}
                                    />
                                </div>
                                <DataDisplayTable
                                    data={headerSections}
                                    displayInRows={false}
                                    loading={props.loadingData}
                                />

                                {
                                    reports && reports.map((report, index) => {
                                        return (
                                            <Collapsable
                                                key={report.reportGenerationId}
                                                loading={loadingData}
                                                title={report.reportName}
                                                fullWidth={true}
                                                expanded={report.expanded}
                                                onClick={() => onCollapsableClick(index)}
                                            >
                                                <div className="spacer-bottom">
                                                    <DataDisplayTable
                                                        data={report.parameters.map((p) => {
                                                            return {header: p.name, value: p.value}
                                                        })}
                                                        displayInRows={true}
                                                        loading={props.loadingData}
                                                    />
                                                </div>
                                                <ShowHide
                                                    evaluator={report.generationState === "COMPLETE"}
                                                    show={(
                                                        <ButtonSave text="Download report" icon="download" onClick={() => { onDownloadReport(report.reportGenerationId) }} disabled={downloadingReport} simpleDisabled={downloadingAllReports || downloadingReport} />
                                                    )}
                                                />
                                                <NotificationInline show={report.generationState === "ERRORED"} allowClose={false} intent="danger" text="There was a problem generating the report for this schedule run. You may wish to re-run the instance" />
                                                <NotificationInline show={report.generationState === "RUNNING" || report.generationState === "PENDING"} allowClose={false} intent="primary" text="Your report is still being processed, you will be able to download it once it has finished generating" />

                                            </Collapsable>);
                                    })
                                }

                            </div>

                            <AlertConfirm
                                title="Please confirm"
                                isOpen={showRerunConfirm}
                                onConfirm={onRerunConfirm}
                                onCancel={onRerunCancel}
                            >
                                <p>Are you sure you want to re-run this schedule? This will re-create the reports for just you.</p>
                            </AlertConfirm>
                        </Fragment>
                    )}
                />

                
            )}
            no={() => (
                <PageRestricted />
            )}
        />

    );

}
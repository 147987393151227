import React, { useState } from 'react';
import { JourneyTypesService } from 'services';
import { AuditingTable } from 'components/auditing';
import { UserAccess } from 'components/user-access';
import { PageRestricted } from 'pages/errors/page-restricted';

export function AlertHistory(props) {
    const requiredAction = "TachoAlerts:Manage"; 
    const noDataMessage = "It looks like no previous changes have been made to your journey types.";
    const [tableData, setTableData] = useState(null);
    const [tableRecordCount, setTableRecordCount] = useState(0);

    function onTableChange(pageSize, pageNumber, startDate, endDate) {
        JourneyTypesService.getChangeLog(pageSize, pageNumber, startDate, endDate).then(r => {
            setTableData(r.data);
            setTableRecordCount(r.totalCount);
        });
    }

    return (
        <UserAccess perform={requiredAction} 
            yes={() => ( 
                <AuditingTable data={tableData} totalCount={tableRecordCount} onChange={onTableChange} noDataMessage={noDataMessage}></AuditingTable>
            )} 
            no={() => ( 
                <div className="row"> 
                    <PageRestricted /> 
                </div> 
            )} 
        /> 
        
    );
}
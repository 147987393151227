import React, { useState, useEffect } from "react";
import PropTypes from 'prop-types';
import { UserAccess, UserHasAccess } from "components/user-access";
import { PageRestricted } from "pages/errors/page-restricted";
import { Tabs } from "components/navigation";
import { ShowHide } from "components/layout";
import { LocationAddressSettings, AdditionalInfoSettings, BusinessAreaOperatingHoursTable } from 'pages/locations/shared';
import classNames from "classnames";

export function LocationDetails(props) {
    const [currentTabKey, setCurrentTabKey] = useState("");

    const requiredActions = ["ScheduledAppointment:View"];
    const isInternal = UserHasAccess("Internal");
    const [tabs, setTabs] = useState([]);
    

    useEffect(initialLoad, []);

    function initialLoad() {
        let tabList = [{ key: "ADDRESS", title: "Location Address", selected: true }];

        if (isInternal) {
            tabList.push({ key: "OPERATINGHOURS", title: "Location Visiting Hours", selected: false });
        }

        tabList.push({ key: "ADDITIONALINFO", title: "Additional Info", selected: false });

        setTabs(tabList);
    }

    function onTabClick(index) {

        var clonedTabs = [
            ...tabs
        ];

        clonedTabs = clonedTabs.map(function (t, i) {
            return {
                ...t,
                selected: index === i
            };
        });

        setTabs(clonedTabs);
        setCurrentTabKey(tabs[index].key);
    }

    useEffect(function () {

        if (tabs.length === 0 || props.loading) {
            return;
        }

        onTabClick(0);

    }, [tabs.length, props.loading])


    return <UserAccess
        perform={requiredActions}
        yes={() => (
            <div className="row">
                <h2>Visit Location Details</h2>
                <div>
                    <div className="spacer-bottom">
                        <div className="intro-text">
                            <p className={classNames({ "bp3-skeleton": props.loading })}>In this tab you can view information about the location.</p>
                        </div>
                        <div className="spacer-bottom">
                            <Tabs tabs={tabs} onClick={onTabClick} loading={props.loading} />
                        </div>
                        <ShowHide
                            evaluator={currentTabKey === 'ADDRESS'}
                            show={(
                                <LocationAddressSettings addressSettings={props.addressSettings} loading={props.loading} readOnly={true} />
                            )}
                        />

                        <ShowHide
                            evaluator={currentTabKey === 'OPERATINGHOURS'}
                            show={(

                            <ShowHide
                                    evaluator={props.operatingHours && props.operatingHours.length > 0}
                                    show={(
                                        <BusinessAreaOperatingHoursTable
                                            loading={false}
                                            operatingHours={props.operatingHours}
                                            saving={false}
                                            readOnly={true}
                                        />
                                    )}
                                    hide={(
                                        <div className="spacer-bottom">
                                            <div className="intro-text">
                                                <p className={classNames({ "bp3-skeleton": props.loading })}>There are no visiting hours for the location business area.</p>
                                            </div>
                                        </div>
                                    )}
                                ></ShowHide>
                            )}
                        />

                        <ShowHide
                            evaluator={currentTabKey === 'ADDITIONALINFO'}
                            show={(
                                <AdditionalInfoSettings
                                    additionalInfoSettings={props.additionalInfo }
                                    originalInductionTime={'2000-01-01 00:00:00'}
                                    saving={false}
                                    loading={false}
                                    readOnly={true} 
                                />
                            )}
                        />
                    </div>
                </div>
            </div>
        )}
        no={() => (
            <PageRestricted />
        )}
    />;
}

LocationDetails.defaultProps = {
    operatingHours: [],
    addressSettings: null,
    loading: false
}

LocationDetails.propTypes = {
    operatingHours: PropTypes.array,
    addressSettings: PropTypes.object,
    additionalInfo: PropTypes.object,
    loading: PropTypes.bool
};
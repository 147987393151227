import React, { Fragment, useState, useEffect } from 'react';
import { Intent } from '@blueprintjs/core';

import { NotificationInline, NotificationToaster } from "components/notifications";
import { FormRadio, FormSelect, FormDateInput, FormNumericInput } from "components/form-fields";
import { ShowHide } from "components/layout";
import { useValidation } from "hooks/useValidation";
import { AccountService } from "services";

import moment from "moment";
import PropTypes from 'prop-types';

export function WorkingTimeSettings(props) {

    const [isValid, errors, validate] = useValidation();
    const [workingTimeReferenceStartDateRequired, setWorkingTimeReferenceStartDateRequired] = useState(false);
    const [loading, setLoading] = useState(true);

    const [defaultOptions, setDefaultOptions] = useState({
        workingTimeVehicleOperation: []
    });

    const [originalLeadInAdjustment, setOriginalLeadInAdjustment] = useState('');

    const minLeadInDriveAdjustMinutes = 0;
    const maxLeadInDriveAdjustMinutes = 20;

    useEffect(onValidationChange, [isValid])

    function onValidationChange() {
        props.onValidationUpdate(isValid);
    }

    useEffect(updateSettingsAndValidate, [props.settings, workingTimeReferenceStartDateRequired])

    function updateSettingsAndValidate() {
        let rules = [];
       
        if (!props.workingtimeDisabled) {
            rules.push({ fieldName: "optOutNightWork", required: true });
            rules.push({ fieldName: "analysePeriodOfAvailability", required: true });
            if (!props.settings.optOutNightWork) {
                rules.push({ fieldName: "workingTimeVehicleOperation", required: true });
            }
            if (workingTimeReferenceStartDateRequired) {
                rules.push({ fieldName: "workingTimeReferenceStartDate", required: true });
            }
            rules.push({ fieldName: "leadInAdjustment", minValue: minLeadInDriveAdjustMinutes, maxValue: maxLeadInDriveAdjustMinutes, required: true });
        }

        validate(rules, props.settings);
    }

    useEffect(setDefaultSettings, [props.settings])

    function setDefaultSettings() {
        if (props.settings.leadInAdjustment && originalLeadInAdjustment === '') {
            setOriginalLeadInAdjustment(props.settings.leadInAdjustment);
        }
    }

    useEffect(initialLoad, []);

    function initialLoad() {
        
    AccountService.getTachoOptions().then(
        (options) => {
            setDefaultOptions(options);
            setLoading(false);
        },
        () => {
            NotificationToaster.show(Intent.DANGER, "Could not load options. Please refresh the page.");
        });
    }

    function onWorkingTimeReferenceFullChange(item) {

        if (item) {
            setWorkingTimeReferenceStartDateRequired(item.requiresDate);
        }

        props.onSettingsUpdate({
            ...props.settings,
            workingTimeReferenceFull: item.id
        });
    }

    useEffect(() => {
        var item = defaultOptions.workingTimeReferenceFull != null && defaultOptions.workingTimeReferenceFull.find(element => element.id === props.settings.workingTimeReferenceFull);
        if (item) {
            setWorkingTimeReferenceStartDateRequired(item.requiresDate);
        }

    }, [props.settings.workingTimeReferenceFull, defaultOptions.workingTimeReferenceFull]);

    function onOptOutNightWorkChange(item) {
        props.onSettingsUpdate({
            ...props.settings,
            optOutNightWork: item.currentTarget.value === "true"
        });
    }

    function onWorkingTimeVehicleOperationChange(item) {
        props.onSettingsUpdate({
            ...props.settings,
            workingTimeVehicleOperation: item.currentTarget.value
        });
    }

    function onAnalysePeriodOfAvailabilityChange(item) {
        var clonedTachoSettings = {
            ...props.settings,
            analysePeriodOfAvailability: item.currentTarget.value === "true"
        }

        props.onSettingsUpdate(clonedTachoSettings);
    }

    function onWorkingTimeReferenceStartDateChange(item) {
        if (!disabledDays(item)) {
            props.onSettingsUpdate({
                ...props.settings,
                workingTimeReferenceStartDate: item ? moment(item).format("YYYY-MM-DD") : null
            });
        }
    }

    function onLeadInDriveAdjustMinutesChange(item) {
        props.onSettingsUpdate({
            ...props.settings,
            leadInAdjustment : item
        });
    }

    function disabledDays(date) {
        if (date == null) {
            return false;
        }
        return date.getDay() !== 1;
    }

    const workingTimeReferenceStartDateModifiers = { disabledDays }

    return (
        <Fragment>
            <NotificationInline
                id="notification-settings-disabled"
                loading={loading}
                show={props.workingtimeDisabled}
                text="Working time is not enabled at account level, if you would like working time infringements to show against drivers at this location, working time must be set on the account"
                intent="danger">
            </NotificationInline>

            <FormRadio
                id="working-time-night-work"
                disabled={props.saving}
                onChange={onOptOutNightWorkChange}
                headingText="Opt out of 10 hour night working limit:"
                helperText={(<p>If your workforce has an agreement in place you can choose to opt out of the 10 hour night work limit and we will calculate your data to reflect this.</p>)}
                selectedValue={props.settings.optOutNightWork}
                dangerHelperText={errors.optOutNightWork}
                loading={loading}
            ></FormRadio>
            <ShowHide
                evaluator={!props.settings.optOutNightWork}
                show={(
                    <FormRadio
                        id="working-time-operation"
                        disabled={props.saving}
                        onChange={onWorkingTimeVehicleOperationChange}
                        headingText="Type of operation that best describes your organisation:"
                        selectedValue={props.settings.workingTimeVehicleOperation}
                        options={defaultOptions.workingTimeVehicleOperation}
                        dangerHelperText={errors.workingTimeVehicleOperation}
                        loading={loading}
                    ></FormRadio>
                )}
            ></ShowHide>
            <FormSelect
                id="working-time-reference-period"
                disabled={props.saving}
                items={defaultOptions.workingTimeReferenceFull}
                onItemSelect={onWorkingTimeReferenceFullChange}
                placeholder="Select a reference period"
                headingText="Use the following reference period:"
                selectedValue={props.settings.workingTimeReferenceFull}
                dangerHelperText={errors.workingTimeReferenceFull}
                loading={loading}
            ></FormSelect>
            <ShowHide
                evaluator={workingTimeReferenceStartDateRequired}
                show={(
                    <FormDateInput
                        id="working-time-reference-start"
                        disabled={props.saving}
                        headingText="Date reference period commenced:"
                        onChange={onWorkingTimeReferenceStartDateChange}
                        value={props.settings.workingTimeReferenceStartDate}
                        loading={loading}
                        helperText={(<p>If you have previously used a different system to monitor your working time you can upload your working time data on the <a href="https://testvision.fta.co.uk/#/Import">Import page</a> using the RTD Interim Report. </p>)}
                        dangerHelperText={errors.workingTimeReferenceStartDate}
                        modifiers={workingTimeReferenceStartDateModifiers}
                        minDate={moment("2000-01-01").toDate()}
                    ></FormDateInput>
                )}
            ></ShowHide>

            <FormNumericInput
                disabled={props.saving}
                min={minLeadInDriveAdjustMinutes}
                max={maxLeadInDriveAdjustMinutes}
                selectedValue={props.settings.leadInAdjustment}
                onValueChange={onLeadInDriveAdjustMinutesChange}
                headingText={"Lead-in Drive Adjust (minutes):"}
                helperText={(<p>The drive adjustment field is used in conjunction with the Lead in/ Lead out dashboard and Compliance reviews. The number of minutes added allows for the lead in time to be interrupted by that amount of drive, in order to move a vehicle to a safe place to carry out walk around checks or similar activities.</p>)}
                dangerHelperText={errors.leadInAdjustment}
                loading={loading}
                id="input-lead-in-drive-adjust"
            ></FormNumericInput>

            <NotificationInline
                show={props.settings.leadInAdjustment && props.settings.leadInAdjustment !== originalLeadInAdjustment }
                text="This will take up to 24 hours to recalculate in dashboard"
                intent="info"
            ></NotificationInline>

            <FormRadio
                id="working-time-poa"
                disabled={props.saving}
                onChange={onAnalysePeriodOfAvailabilityChange}
                headingText="Analyse your POA (Period of Availability) as working hours when the driver card is inserted to slot 2 of the tachograph:"
                selectedValue={props.settings.analysePeriodOfAvailability}
                dangerHelperText={errors.analysePeriodOfAvailability}
                loading={loading}
            ></FormRadio>
               
        </Fragment>

    );
}

WorkingTimeSettings.defaultProps = {
    saving: false,
    workingtimeDisabled: false
};

WorkingTimeSettings.propTypes = {
    settings: PropTypes.object.isRequired,
    saving: PropTypes.bool,
    workingtimeDisabled: PropTypes.bool,
    onSettingsUpdate: PropTypes.func.isRequired,
    onValidationUpdate: PropTypes.func.isRequired
};
import React, { useState, useEffect } from 'react';
import { Button, MenuItem, Position } from "@blueprintjs/core";
import { Select } from "@blueprintjs/select";
import classNames from 'classnames';
import PropTypes from 'prop-types';

import { FormHelperText } from "components/form-fields";
import 'components/form-fields/FormFields.css';
import { ShowHide } from 'components/layout';

export function FormSelect(props) {

	const [selectedItem, setSelectedItem] = useState(null);

	useEffect(setItemFromValue, [props.selectedValue, props.items]);

	function setItemFromValue() {
		var newItem = props.items.find(obj => obj.id === props.selectedValue)
		setSelectedItem(newItem);
	}

	function renderSelect(item, { handleClick, modifiers }) {

		
		return (
			<MenuItem
				active={modifiers.active}
				onClick={handleClick}
				key={item.id}
				text={item.name}
				disabled={item.disabled || modifiers.disabled}
			/>
		);
	}

	return (
		<div className="form-field form-field-select" id={props.id}>
			<ShowHide
				evaluator={props.headingText.length > 0}
				show={(
					<h4 className={classNames({ "bp3-skeleton": props.loading })}>{props.headingText}</h4>
				)}
			>
			</ShowHide>
			
			<FormHelperText loading={props.loading}>{props.helperText}</FormHelperText>
			<Select
				className={classNames({ "bp3-skeleton": props.loading })}
				items={props.items}
				onItemSelect={props.onItemSelect}
				itemRenderer={renderSelect}
				filterable={false}
				popoverProps={{ minimal: true, position: Position.BOTTOM_LEFT, boundary: "window", usePortal: false }}
				activeItem={selectedItem}
				disabled={props.disabled}				
			><Button text={selectedItem ? selectedItem.name : props.placeholder} disabled={props.disabled} rightIcon="double-caret-vertical" className={classNames({
				"fixed-width": !props.dynamicWidth,
				"bp3-skeleton": props.loading
			})} />
			</Select>
			<FormHelperText loading={props.loading} danger={true}>{props.dangerHelperText}</FormHelperText>
		</div>
	);
}

FormSelect.defaultProps = {
	id: null,
	placeholder: "Choose an item",
	items: [],
	loading: false,
	headingText: "",
	helperText: null,
	disabled: false,
	selectedValue: null,
	dangerHelperText: null
};

FormSelect.propTypes = {
    id: PropTypes.string,
	placeholder: PropTypes.string,
	items: PropTypes.array,
	onItemSelect: PropTypes.func.isRequired,
	dynamicWidth: PropTypes.bool,
	loading: PropTypes.bool,
	headingText: PropTypes.string,
	helperText: PropTypes.node,
	disabled: PropTypes.bool,
	selectedValue: PropTypes.any,
	dangerHelperText: PropTypes.node
};
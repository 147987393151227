import { FormSwitch } from "components/form-fields";
import PropTypes from 'prop-types';
import React from "react";

export function MigrationEmployeeBehaviours(props) {

    function onCopyBannedChanged(event) {
        const settingsClone = { ...props.settings };

        settingsClone.migrateBannedStatus = event.target.checked;

        props.onSettingsChange(settingsClone);
    } 
 
    return <div>
        <h2>Choose behaviour for migration</h2>
        <FormSwitch
            id="banned-driver-copy"
            disabled={props.saving}
            headingText="Maintain employee banned status from originating account"
            checked={props.settings.migrateBannedStatus}
            onChange={onCopyBannedChanged}
        />
        
    </div>;
}

MigrationEmployeeBehaviours.propTypes = {
    settings: PropTypes.object.isRequired,
    saving: PropTypes.bool,
    onSettingsChange: PropTypes.func.isRequired
};

MigrationEmployeeBehaviours.defaultProps = {
    saving: false
};
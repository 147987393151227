import React, { useEffect, useState } from "react";
import { Intent, Icon } from '@blueprintjs/core';
import moment from "moment";
import axios from 'axios';
import PropTypes from 'prop-types';


import { useValidation } from "hooks/useValidation";
import { Breadcrumbs } from "components/navigation";
import { UserAccess } from "components/user-access";
import { PageRestricted } from "pages/errors/page-restricted";
import { useDirtyData } from 'hooks/useDirtyData';
import { ShowHide } from "components/layout";
import { NotificationToaster, AlertUnsavedData } from "components/notifications";
import { LinkButton, Button, ButtonSave } from "components/buttons";
import { AssetService, LocationService, UserService } from 'services';
import { FormSelect, FormTextInput, FormDateInput, FormLocationSelector, FormSuggest, FormPagedCheckboxList } from "components/form-fields";

export function AssetCreateSettings(props) {

    const assetModel = {
        registration: null,
        assetType: {},
        chassisNumber: null,
        tankNo: null,
        serialNumber: null,
        assetLocation: {
            location: {},
            assetStatus: {},
            assetOwnership: {},
            startDate: null,
        },
        analysisRules: null,
        linkedVehicleId: null,
        relatedAssets: []
    };

    const [isValid, errors, validate] = useValidation();
    const [originalSettings, setOriginalSettings] = useState(assetModel);
    const [loading, setLoading] = useState(true);
    const [saving, setSaving] = useState(false);
    const [assetCreated, setAssetCreated] = useState(false);
    const [asset, setAsset] = useState(assetModel);
    const isUserDirty = useDirtyData(originalSettings, asset);
    const [assetTypes, setAssetTypes] = useState([]);
    const [assetOwnerships, setAssetOwnerships] = useState([]);
    const [tachographRules, setTachographRules] = useState([]);
    const [registrationType, setRegistrationType] = useState("");
    const [showLocationsAsHierarachy, setShowLocationsAsHierarachy] = useState(true);
    const [locationList, setLocationList] = useState({ location: [], hierarchy: [] });
    const [selectedLocations, setSelectedLocations] = useState([]);    
    const monthsInPastDefault = 240;
    const minDateDefault = moment().subtract(monthsInPastDefault, 'months').toDate();
    const [isInternalMaintenance, setIsInternalMaintenance] = useState(false);
    const [isAttachable, setIsAttachable] = useState(false);
    const [assetList, setAssetList] = useState([]);
    const [assetSearch, setAssetSearch] = useState('');
    const [loadingAssets, setLoadingAssets] = useState(false);
    const [totalRelatedAssetRecordCount, setTotalAssetRecordCount] = useState(0);
    const [assetTypeSelected, setAssetTypeSelected] = useState(false);

    const requiredAction = ["Fleet:Add", "FleetPCN:Add", "FleetVIS:Add"];
    const maintenanceBusinessArea = "2";
    const maxAssets = 50;
    useEffect(initialLoad, [props.businessArea]);
    function initialLoad() {

        if (props.businessArea == null) {
            return;
        }

        axios.all([AssetService.getAssetOptions(), UserService.getLoggedInUserLocations(props.businessArea, false, true), LocationService.getLocationBusinessAreas(), UserService.getLoggedInUser()]).then(axios.spread(function (optionsResponse, locationPermissionsResponse, businessAreasResponse, userDetails) {

            var activeStatus = optionsResponse.assetStatuses.find(x => x.name.toLowerCase() === 'active');
            var internalMaintenance = userDetails.isInternal && props.businessArea === maintenanceBusinessArea;
            var assetOwnership = {};
            var analysisRules = {};

            //default values if internal maintenance
            if (internalMaintenance) {

                var owned = optionsResponse.assetOwnerships.filter(x => x.name === "Owned");
                var eu = optionsResponse.analysisRules.filter(x => x.name === "EU");

                assetOwnership = owned.length > 0 ? owned[0] : {};
                analysisRules = eu.length > 0 ? eu[0] : {};
                setRegistrationType("registration");
            }

            setIsInternalMaintenance(internalMaintenance);

            var clonedLocationSettings = {
                ...asset.assetLocation,
                assetStatus: activeStatus,
                assetOwnership: assetOwnership
            };

            let updatedAsset = {
                ...asset,
                analysisRules: analysisRules,
                assetLocation: clonedLocationSettings
            };

            setAsset(updatedAsset);
            setOriginalSettings(updatedAsset);

            var businessArea = businessAreasResponse.find(x => x.locationBusinessAreaId === parseInt(props.businessArea));
            if (businessArea == null) {
                return;
            }

            setAssetTypes(optionsResponse.assetTypes.filter(x => x.publicAllowed && businessArea != null && ((businessArea.businessArea.toUpperCase() === "VIS") || (businessArea.businessArea.toUpperCase() !== "VIS" && !x.visOnly))));
            setAssetOwnerships(optionsResponse.assetOwnerships);
            setTachographRules(optionsResponse.analysisRules);
            setLocationList(locationPermissionsResponse);

            setLoading(false);
        }));
    }

    function onAssetTypeChange(type) {
        var typeName = type.name.toLowerCase();

        if (typeName === asset.assetType.name) {
            return;
        }

        var clonedAssets = {
            ...asset,
            assetType: type,
            registration: null,
            chassisNumber: null,
            tankNo: null,
            serialNumber: null
        };

        if (isAttachable !== type.isAttachable) {
            clonedAssets.linkedVehicleId = null;
            clonedAssets.relatedAssets = [];
        }

        setAsset(clonedAssets);

        setAssetTypeSelected(true);
        setIsAttachable(type.isAttachable);

        var regType = type.registration.toLowerCase();
        if (regType !== registrationType) {
            setRegistrationType(regType);
        }
    }

    function onLinkedVehicleSelect(item) {
        setAsset({
            ...asset,
            linkedVehicleId: item.id
        });
    }

    function formatAssets(assets) {
        return assets.map(assetToMap => {
            return {
                id: assetToMap.assetId,
                name: assetToMap.assetRegistration
            };
        });
    }

    function searchAsset(listSize, pageNumber, searchText) {

        if (isAttachable == null) {
            return;
        }

        setLoadingAssets(true);

        const assetsRequest = {
            pageNumber: pageNumber,
            searchTerm: searchText,
            requestCount:listSize,
            filters: [{ key: "ISATTACHABLE", value: !isAttachable ? "YES" : "NO" }]
        };

        AssetService.searchAssets(assetsRequest).then(pagedAssets => {
            setAssetList(formatAssets(pagedAssets.data));
            setTotalAssetRecordCount(pagedAssets.totalCount);
            setLoadingAssets(false);
            }, (error) => {
                NotificationToaster.show(Intent.DANGER, "Could not load settings. Please refresh the page.");
            });
    }

    useEffect(function () {
        if (assetSearch.length > 0) {
            searchAsset(maxAssets, 1, assetSearch);
        }
    }, [assetSearch, isAttachable])

    function onAssetSearchTextChange(event) {
        setAssetSearch(event);
    }

    function onOwnershipChange(ownership) {

        var clonedLocationSettings = {
            ...asset.assetLocation,
            assetOwnership: ownership
        };

        setAsset({
            ...asset,
            assetLocation: clonedLocationSettings
        });
    }

    function onRulesetChange(ruleset) {
        setAsset({
            ...asset,
            analysisRules: ruleset
        });
    }

    function onRegistrationChange(event) {
        setAsset({
            ...asset,
            registration: event.target.value.toUpperCase()
        });
    }

    function onChassisNumberChange(event) {
        setAsset({
            ...asset,
            chassisNumber: event.target.value.toUpperCase()
        });
    }

    function onSerialNumberChange(event) {
        setAsset({
            ...asset,
            serialNumber: event.target.value.toUpperCase()
        });
    }

    function onTankNumberChange(event) {
        setAsset({
            ...asset,
            tankNo: event.target.value.toUpperCase()
        });
    }

    function onStartDateChange(item) {
        var clonedLocationSettings = {
            ...asset.assetLocation,
            startDate: item ? moment(item).format("YYYY-MM-DD") : null
        };

        setAsset({
            ...asset,
            assetLocation: clonedLocationSettings
        });
    }

    function onLocationSelect(locations) {

        var lastLocation = locations.length > 0 ? locations.pop() : null;
        var lastLocationProperty = lastLocation == null ? {} : { id: lastLocation };

        var clonedLocationSettings = {
            ...asset.assetLocation,
            location: lastLocationProperty
        };

        setAsset({
            ...asset,
            assetLocation: clonedLocationSettings
        });

        setSelectedLocations(lastLocation == null ? [] : [lastLocation]);
    }

    function onRelatedAssetChange(item, isChecked) {
        if (isChecked) {
            const relatedAssetsCloned = [...asset.relatedAssets];
            relatedAssetsCloned.push({
                ...item,
                assetId: item.id
            });

            setAsset({
                ...asset,
                relatedAssets: relatedAssetsCloned
            });

        } else {
            const selectedLocationIndex = asset.relatedAssets.findIndex(x => x.id === item.id);
            onRemove(selectedLocationIndex, 'relatedAssets');
        }
    }

    function onRemove(index, listName) {
        var temp = [...asset[listName]];
        temp.splice(index, 1);

        var updatedAssetSettings = {
            ...asset
        };

        updatedAssetSettings[listName] = temp;
        setAsset(updatedAssetSettings);


    }

    function onRelatedAssetsPageChange(listSize, pageNumber, debouncedSearchTerm) {
        setLoadingAssets(true);
        searchAsset(listSize, pageNumber, debouncedSearchTerm);
    }
    function setUseHierarchy() {
        setShowLocationsAsHierarachy(previous => !previous);
    }


    function saveAsset() {
        setSaving(true);

        AssetService.createAsset(asset).then(
            (assetId) => {
                setSaving(false);
                setAssetCreated(true);
                setAsset(assetModel);
                setOriginalSettings(assetModel);
                setSelectedLocations([]);
            },
            (error) => {
                setSaving(false);
                NotificationToaster.show(Intent.DANGER, error);
            }
        );
    }

    function resetComponent() {
        setAsset(assetModel);
        setOriginalSettings(assetModel);
        initialLoad();
        setSaving(false);
        setAssetCreated(false);
    }

    useEffect(validateModel, [asset])

    function validateModel() {
        let rules = [{ fieldName: "assetLocation.location", required: true }, { fieldName: "assetLocation.assetOwnership", required: true }];

        if (!isInternalMaintenance) {
            rules.push({ fieldName: "assetType", required: true });
            rules.push({ fieldName: "assetLocation.startDate", required: true });
        }
        switch (registrationType) {

            case "registration":
                rules.push({ fieldName: "registration", required: true, type: "alphanumeric" })
                break;
            case "serial":
                rules.push({ fieldName: "serialNumber", required: true })
                break;
            case "chassis":
                rules.push({ fieldName: "chassisNumber", required: true })
                break;
            case "tank":
                rules.push({ fieldName: "tankNo", required: true })
                break;
            default:
                rules.push({ fieldName: "registration", required: true, type: "alphanumeric" })
                break;
        }

        validate(rules, asset);
    }

    return (

        <UserAccess perform={requiredAction}
            yes={() => (

                <div className="row">
                    <Breadcrumbs items={props.breadcrumbs} />
                    <ShowHide
                        evaluator={!assetCreated}
                        show={(
                            <div>
                                <h1 id="header-new-asset">Asset Details</h1>

                                <FormSelect
                                    id="field-asset-type"
                                    disabled={saving}
                                    items={assetTypes}
                                    onItemSelect={onAssetTypeChange}
                                    placeholder="Select an asset type"
                                    headingText="Asset category:"
                                    selectedValue={asset.assetType.id}
                                    loading={loading}
                                    dangerHelperText={errors.assetType}
                                ></FormSelect>

                                <ShowHide
                                    evaluator={registrationType === "registration" || (registrationType === "" && isInternalMaintenance)}
                                    show={(
                                        <FormTextInput
                                            id="field-asset-registration"
                                            loading={loading}
                                            value={asset.registration ? asset.registration : ""}
                                            headingText="Registration:"
                                            onChange={onRegistrationChange}
                                            disabled={saving}
                                            dangerHelperText={errors.registration}
                                        >
                                        </FormTextInput>
                                    )}
                                />

                                <ShowHide
                                    evaluator={registrationType === "serial"}
                                    show={(
                                        <FormTextInput
                                            id="field-asset-serial"
                                            loading={loading}
                                            value={asset.serialNumber ? asset.serialNumber : ""}
                                            headingText="Serial Number:"
                                            onChange={onSerialNumberChange}
                                            disabled={saving}
                                            dangerHelperText={errors.serialNumber}
                                        >
                                        </FormTextInput>
                                    )}
                                />

                                <ShowHide
                                    evaluator={registrationType === "tank"}
                                    show={(
                                        <FormTextInput
                                            id="field-asset-tank-number"
                                            loading={loading}
                                            value={asset.tankNo ? asset.tankNo : ""}
                                            headingText="Tank Number:"
                                            onChange={onTankNumberChange}
                                            disabled={saving}
                                            dangerHelperText={errors.tankNo}
                                        >
                                        </FormTextInput>
                                    )}
                                />

                                <ShowHide
                                    evaluator={registrationType === "chassis"}
                                    show={(
                                        <FormTextInput
                                            id="field-asset-chassis"
                                            loading={loading}
                                            value={asset.chassisNumber ? asset.chassisNumber : ""}
                                            headingText="Chassis Number:"
                                            onChange={onChassisNumberChange}
                                            disabled={saving}
                                            dangerHelperText={errors.chassisNumber}
                                        >
                                        </FormTextInput>
                                    )}
                                />
                                <FormLocationSelector
                                    headingText={`Location asset belongs to:`}
                                    dangerHelperText={errors["assetLocation.location"]}
                                    businessArea={"Tacho"}
                                    loading={loading}
                                    selectedLocations={selectedLocations}
                                    setSelectedLocations={onLocationSelect}
                                    useHierarchy={showLocationsAsHierarachy}
                                    setUseHierarchy={setUseHierarchy}
                                    useLocationDefaults={false}
                                    useCustomLocations
                                    customLocations={locationList}
                                    locationDefaultsToTrue
                                    singleSelection
                                />

                                <FormSelect
                                    id="field-asset-ownership"
                                    disabled={saving}
                                    items={assetOwnerships}
                                    onItemSelect={onOwnershipChange}
                                    placeholder="Select an ownership status"
                                    headingText="Asset ownership status:"
                                    selectedValue={asset.assetLocation.assetOwnership.id}
                                    loading={loading}
                                    dangerHelperText={errors["assetLocation.assetOwnership"]}
                                ></FormSelect>

                                <FormSelect
                                    id="field-asset-ruleset"
                                    disabled={saving}
                                    items={tachographRules}
                                    onItemSelect={onRulesetChange}
                                    placeholder="Select a ruleset"
                                    headingText="Tachograph rules:"
                                    selectedValue={asset.analysisRules?.id}
                                    loading={loading}
                                ></FormSelect>

                                <FormDateInput
                                    id="field-asset-start-date"
                                    disabled={saving}
                                    headingText="Start date:"
                                    onChange={onStartDateChange}
                                    value={asset.assetLocation.startDate}
                                    loading={loading}
                                    helperText="This is the date from which we will start analysis"
                                    dangerHelperText={errors.startDate}
                                    minDate={minDateDefault}
                                ></FormDateInput>

                                <ShowHide
                                    evaluator={assetTypeSelected}
                                    show={(
                                        <ShowHide
                                            evaluator={isAttachable}
                                            show={(
                                                <FormSuggest
                                                    onQueryChange={onAssetSearchTextChange}
                                                    items={assetList}
                                                    selectedValue={asset.linkedVehicleId}
                                                    loading={loading}
                                                    onItemSelect={onLinkedVehicleSelect}
                                                    headingText="Linked asset:"
                                                    helperText="Select the asset to link this asset too."
                                                    placeholder="Select parent asset"
                                                    dangerHelperText={errors.linkedVehicleId}
                                                    disabled={saving || isAttachable === null}
                                                    clearSelection={true}
                                                />
                                            )}
                                            hide={(
                                                <FormPagedCheckboxList
                                                    id="related-assets"
                                                    onPageChange={onRelatedAssetsPageChange}
                                                    headingText="Select the assets related to this asset:"
                                                    items={assetList}
                                                    totalItemCount={totalRelatedAssetRecordCount}
                                                    onItemSelect={onRelatedAssetChange}
                                                    selectedItems={asset.relatedAssets}
                                                    dangerHelperText={errors.relatedAssets}
                                                    placeholder="Search assets"
                                                    loading={loading}
                                                    loadingPage={loadingAssets}
                                                    disabled={props.disabled}
                                                    saving={props.saving}
                                                    noDataMessage="No assets found."
                                                ></FormPagedCheckboxList>
                                            )}

                                        />
                                    )}
                                />

                                <AlertUnsavedData
                                    isDirty={isUserDirty}>
                                </AlertUnsavedData>

                                <ButtonSave disabled={saving} simpleDisabled={!isValid} onClick={saveAsset} />

                            </div>
                        )}
                        hide={(
                            <div>
                                <div className="spacer-bottom" id="create-success-icon">
                                    <Icon icon="tick-circle" iconSize={40} intent={Intent.SUCCESS} />
                                </div>
                                <h2 id="create-success-name">Asset has been added successfully</h2>
                                <div className="spacer-bottom">
                                    <p>Your asset has been created, there may be a short period of time before it is accessible via the Manage my Fleet screen.</p>
                                </div>
                                <div className="button-row" id="create-success-buttons">
                                    <LinkButton intent="primary" text="Manage my Fleet" id="listing-mmf" externalLink href={`${window.env.VISION_URL}/#/Fleet/`} />
                                    <Button intent="primary" text="Add new asset" id="new-asset" onClick={resetComponent} />
                                </div>
                            </div>
                        )}
                    ></ShowHide>

                </div>

            )}
            no={() => (
                <PageRestricted />
            )}
        />

    );
}

AssetCreateSettings.defaultProps = {
    breadcrumbs: []
};

AssetCreateSettings.propTypes = {
    businessArea: PropTypes.string.isRequired,
    breadcrumbs: PropTypes.array
};
import React, { Fragment, useState, useEffect, useCallback } from 'react';
import axios from 'axios';

import { Route, Switch, Redirect } from 'react-router-dom';
import { VerticalLinkGroup, Breadcrumbs } from 'components/navigation';
import { UserGeneralSettings } from 'pages/users/edit/general-settings';
import {  AccountService, UserService } from 'services';
import { ShowHide } from 'components/layout';
import { UserPreferences } from './preferences/UserPreferences';

var _ = require('underscore');

export function UserProfile(props) {
    const [loadingData, setLoadingData] = useState(true);
    const [isDriver, setIsDriver] = useState(null);
    const [ssoAccount, setSsoAccount] = useState(false);

    const getRoutes = useCallback((hasDriverStatus) => {

        var r = [
            { location: `/user/profile/general-settings`, title: "User Details" }
        ];

        if (hasDriverStatus != null && !hasDriverStatus) {
            r.push({ location: `/user/profile/preferences`, title: "Preferences" });
        }

        return r;
    }, [])

    const [routes, setRoutes] = useState(getRoutes(isDriver));

    const [user, setUser] = useState({
        role: {},
        firstName: "",
        lastName: "",
        emailAddress: "",
        phoneNumber: "",
    });

    useEffect(() => {
        setRoutes(getRoutes(isDriver));
    }, [isDriver]);

    useEffect(() => {

        if (loadingData) {

            axios.all([UserService.getLoggedInUser(), UserService.getRoles(), AccountService.getGeneralSettings()]).then(axios.spread(function (userDetails, roles, accountGeneralSettings) {

                var userRole = roles.filter(function (r) {
                    return r.key === userDetails.role
                })[0];

                setUser({
                    ...userDetails,
                    role: userRole
                });
                setLoadingData(false);
                setIsDriver(userDetails.role != null && userDetails.role.toUpperCase() === "DRIVERAPPUSER");
                setSsoAccount(accountGeneralSettings.ssoEnabled);
            }));
        }
    }, [loadingData]);

    function onSave(modifiedUser) {
        setUser(modifiedUser);
    }

    return (
        <Fragment>
            <div className="row">
                <Breadcrumbs items={props.breadcrumbs} />
                <ShowHide
                    evaluator={user.firstName !== ""}
                    show={(<h1>{user.firstName + " " + user.lastName}</h1>)}
                    hide={(<h1>{user.emailAddress}</h1>)}
                />
                
            </div>

            <div className="layout-cols">
                <div className="col side-col">
                    <div className="row">
                        <VerticalLinkGroup links={routes}></VerticalLinkGroup>
                    </div>
                </div>
                <div className="col content-col">
                    <Switch>
                        <Route path="/user/profile/general-settings">
                            <UserGeneralSettings
                                user={user}
                                loading={loadingData}
                                onSave={onSave}
                                ssoAccount={ssoAccount}
                                editingOwnAccount={true}
                            />
                        </Route>
                        <Redirect exact from="/user/profile" to="/user/profile/general-settings" />
                        <Route path="/user/profile/preferences">
                            <UserPreferences userId={user.userId} editingOwnAccount={true}></UserPreferences>
                        </Route>
                    </Switch>
                </div>
            </div>
        </Fragment>
    );

}
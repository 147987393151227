import React, { useState, useEffect } from 'react';
import classNames from 'classnames';
import { Callout, Intent, Button } from "@blueprintjs/core";
import { ShowHide } from "components/layout";

import PropTypes from 'prop-types';


import './Notifications.css';

export function NotificationInline(props) {
    const [intent, setIntent] = useState(Intent.NONE);
    const [icon, setIcon] = useState(null);
    const [show, setShow] = useState(false);

    useEffect(() => {
        if (props.intent) {
            switch (props.intent.toLowerCase()) {
                case "warning":
                    setIntent(Intent.WARNING);
                    setIcon("warning-sign");
                    break;
                case "info":
                case "primary":
                    setIntent(Intent.PRIMARY);
                    setIcon("notifications");
                    break;
                case "danger":
                    setIntent(Intent.DANGER);
                    setIcon("error");
                    break;
                case "success":
                    setIntent(Intent.SUCCESS);
                    setIcon("tick-circle");
                    break;
                default:
                    setIntent(Intent.NONE);
                    setIcon(null);
                    break;
            }
        }
    }, [props.intent]);

    useEffect(() => {
        setShow(props.show ? props.show : false);
    }, [props.show]);

    function closeNotification() {
        setShow(false);
    }

    return (
        <ShowHide
            evaluator={show}
            show={(
                <Callout className={classNames("notification-inline", { "closable": props.allowClose, "short-width": props.isShort })}
                    intent={intent}
                    icon={icon}
                >
                    {props.messages.map(x => <div key={x.message}>{x.message}</div>)}
                    {props.text}
                    <ShowHide
                        evaluator={props.allowClose}
                        show={(
                            <Button icon="cross" minimal="true" className="btn-close" onClick={closeNotification}></Button>
                        )}
                    />
                </Callout>
            )}
        ></ShowHide>
    );
}

NotificationInline.defaultProps = {
    allowClose: true,
    text: "",
    show: false,
    messages: [],
    isShort: false
};

NotificationInline.propTypes = {
    intent: PropTypes.string.isRequired,
    show: PropTypes.bool,
    text: PropTypes.string,
    allowClose: PropTypes.bool,
    messages: PropTypes.array,
    isShort: PropTypes.bool
};
var _ = require('underscore');

const zeroTime = '2020-01-01 00:00:00'
const timeFormat = "YYYY-MM-DD HH:mm:ss";
const dayCount = 7;
function mapAdditionalProperties(ohs, additionalPropertiesMapped, setAdditionalPropertiesMapped) {

	if (additionalPropertiesMapped) {
		return ohs;
	}

	const operatingHoursPlus = ohs.map(item => {
		var newItem = {
			...item,
			day: getDayString(item.dayOfWeek),
			startDate: item.startTime1 === null ? zeroTime : '2020-01-01 ' + item.startTime1,
			startDate1: item.startTime2 === null ? zeroTime : '2020-01-01 ' + item.startTime2,
			endDate: item.endTime1 === null ? zeroTime : '2020-01-01 ' + item.endTime1,
			endDate1: item.endTime2 === null ? zeroTime : '2020-01-01 ' + item.endTime2,
			valid: true,
			errorMessage: ''
		};
		newItem.range2Disabled = setRange2Disabled(newItem);
		return newItem;
	})

	setAdditionalPropertiesMapped(true);
	return operatingHoursPlus;
}

function getDayString(dayOfWeek) {
	var date = new Date();
	var currentDay = date.getDay();
	var distance = dayOfWeek - currentDay;
	date.setDate(date.getDate() + distance);
	return date.toLocaleString('en-us', { weekday: 'long' });
}

function setRange2Disabled(openingHour) {
	return isNotSet(openingHour.startDate) && isNotSet(openingHour.endDate);
}

function isNotSet(date) {
	return date === undefined || date === null || date === zeroTime;
}

function getFullWeekOperatingHours(ohs, additionalPropertiesMapped, setAdditionalPropertiesMapped) {
	var weekOfOperatingHours = [];
	for (var i = 1; i < dayCount; i++) {
		addOperatingHourstoWeek(i, ohs, weekOfOperatingHours);
	}
	addOperatingHourstoWeek(0, ohs, weekOfOperatingHours);
	weekOfOperatingHours = mapAdditionalProperties(weekOfOperatingHours, additionalPropertiesMapped, setAdditionalPropertiesMapped);
	return weekOfOperatingHours;
}

function addOperatingHourstoWeek(dayIdx, ohs, weekOfOperatingHours) {
	var operatingHoursForDay = ohs.find(l => l.dayOfWeek === dayIdx);

	if (operatingHoursForDay) {
		weekOfOperatingHours.push(operatingHoursForDay);
	}
	else {
		weekOfOperatingHours.push({ dayOfWeek: dayIdx, startTime1: null, endTime1: null, startTime2: null, endTime2: null });
	}
}

export const OperatingHoursService = {
	getFullWeekOperatingHours: getFullWeekOperatingHours
};


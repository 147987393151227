import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Button, ButtonGroup, MenuItem } from "@blueprintjs/core";
import { Select } from "@blueprintjs/select";

import { FormHelperText } from "components/form-fields";

import 'components/form-fields/FormFields.css';


export function FormDayMonthSelect(props) {
	const allowLeapYear = props.allowLeapYear ? props.allowLeapYear : false;
	const maxDaysInMonth = 31;
	const maxDaysFebLeap = 29;
	const maxDaysFebNoLeap = 28;

	let daysArray = [];
	for (let i = 1; i <= maxDaysInMonth; i++) {
		daysArray.push(i);
	}
	const [days, setDays] = useState({
		items: daysArray.map(m => { return { id: m, name: m + getDateEnding(m), itemDisabled: false } }),
		selectedItem: null,
		placeholder: "Day"
	});
	const [months, setMonths] = useState({
		items: [{ id: 1, name: "January", maxDay: 31, itemDisabled: false }, { id: 2, name: "February", maxDay: allowLeapYear ? maxDaysFebLeap : maxDaysFebNoLeap, itemDisabled: false },
		{ id: 3, name: "March", maxDay: 31, itemDisabled: false }, { id: 4, name: "April", maxDay: 30, itemDisabled: false },
		{ id: 5, name: "May", maxDay: 31, itemDisabled: false }, { id: 6, name: "June", maxDay: 30, itemDisabled: false },
		{ id: 7, name: "July", maxDay: 31, itemDisabled: false }, { id: 8, name: "August", maxDay: 31, itemDisabled: false },
		{ id: 9, name: "September", maxDay: 30, itemDisabled: false }, { id: 10, name: "October", maxDay: 31, itemDisabled: false },
		{ id: 11, name: "November", maxDay: 30, itemDisabled: false }, { id: 12, name: "December", maxDay: 31, itemDisabled: false }],
		selectedItem: null,
		placeholder: "Month"
	});

	function getDateEnding(d) {
		if (d > 3 && d < 21) return 'th';
		switch (d % 10) {
			case 1: return "st";
			case 2: return "nd";
			case 3: return "rd";
			default: return "th";
		}
	}

	useEffect(setMonthFromValue, [props.selectedMonthValue]);

	useEffect(setDayFromValue, [props.selectedDayValue]);


	function setDayFromValue() {
		setDays({
			...days,
			selectedItem: days.items.find(obj => obj.id === props.selectedDayValue)
		});
	}

	function setMonthFromValue() {
		setMonths({
			...months,
			selectedItem: months.items.find(obj => obj.id === props.selectedMonthValue)
		});
	}

	function onDayItemSelect(item) {
		//locking down the selectable months when a day is selected.
		const monthsDisabled = months.items.map(m => {
			return { ...m, itemDisabled: item.id > m.maxDay }
		});
		setDays({
			...days,
			selectedItem: item
		});
		setMonths({
			...months,
			items: monthsDisabled
		});
		props.onDayItemSelect(item);
	}
	function onMonthItemSelect(item){
		//locking down the selectable days when a month is selected.
		const daysDisabled = days.items.map(m => {
			return { ...m, itemDisabled: item.maxDay < m.id }
		});
		setMonths({
			...months,
			selectedItem: item
		});
		setDays({
			...days,
			items: daysDisabled
		});
		props.onMonthItemSelect(item);
	}

	function isItemDisabled(item) {
		return item.itemDisabled;
	}

	function renderSelect(item, { handleClick, modifiers }){
		return (
			<MenuItem
				active={modifiers.active}
				onClick={handleClick}
				key={item.id}
				text={item.name}
				disabled={modifiers.disabled}
			/>
		);
	}

	return (
		<div className="form-field form-field-select form-field-day-month-select">
			<h4>{props.headingText}</h4>
			<FormHelperText>{props.helperText}</FormHelperText>
			<ButtonGroup id={props.id}>
				<Select
					disabled={props.disabled}
					items={days.items}
					onItemSelect={onDayItemSelect}
					itemRenderer={renderSelect}
					filterable={false}
					itemDisabled={isItemDisabled}
					popoverProps={{ minimal: true }}
				><Button disabled={props.disabled} text={days.selectedItem ? days.selectedItem.name : days.placeholder} rightIcon="double-caret-vertical" className="fixed-width" />
				</Select>
				<Select
					disabled={props.disabled}
					items={months.items}
					onItemSelect={onMonthItemSelect}
					itemRenderer={renderSelect}
					filterable={false}
					itemDisabled={isItemDisabled}
					popoverProps={{ minimal: true }}
				><Button disabled={props.disabled} text={months.selectedItem ? months.selectedItem.name : months.placeholder} rightIcon="double-caret-vertical" className="fixed-width" />
				</Select>
			</ButtonGroup>
			<FormHelperText danger={true}>{props.dangerHelperText}</FormHelperText>
		</div>
	);
}

FormDayMonthSelect.defaultProps = {
	id: null,
	allowLeapYear: false,
	selectedMonthValue: null,
	selectedDayValue: null,
	headingText: "",
	disabled: false,
	dangerHelperText: ""
};

FormDayMonthSelect.propTypes = {
	id: PropTypes.string,
	allowLeapYear: PropTypes.bool,
	selectedMonthValue: PropTypes.number,
	selectedDayValue: PropTypes.number,
	onDayItemSelect: PropTypes.func.isRequired,
	onMonthItemSelect: PropTypes.func.isRequired,
	headingText: PropTypes.string,
	disabled: PropTypes.bool,
	dangerHelperText: PropTypes.string
};
import React, { useEffect, useState } from 'react';
import { FormDateInput, FormTextInput } from 'components/form-fields';
import moment from "moment";
import { useValidation } from "hooks/useValidation";
import PropTypes from 'prop-types';
import { ReoccurrenceSettings } from 'components/settings';

export function ScheduleReoccurrenceSettings(props) {

    const [isValid, errors, validate] = useValidation();
    const [reoccurrenceValid, setReoccurrenceValid] = useState(false);
    const maxMonthsInPast = 6;
    const maxMonthsInFuture = 12;

    useEffect(onValidationChange, [isValid, reoccurrenceValid]);

    function onValidationChange() {
        props.onValidationUpdate(isValid && reoccurrenceValid);
    }

    useEffect(updateValidationRules, [props.schedule]);

    function updateValidationRules() {
        const rules = [
            { fieldName: "name", required: true },
            { fieldName: "startDate", required: true, type: "date" },
        ];

        validate(rules, props.schedule, []);
    }

    function onReoccurrenceValidationUpdate(isReoccurrenceValid) {
        setReoccurrenceValid(isReoccurrenceValid);
    }

    function onNameChange(event) {
        props.onScheduleUpdate({
            ...props.schedule,
            name: event.target.value
        });
    }

    function onStartDateChange(item) {
        props.onScheduleUpdate({
            ...props.schedule,
            startDate: item ? moment(item).utc().format("YYYY-MM-DD HH:mm") : null
        });
    }

    function onReoccurrenceChange(item) {
        props.onScheduleUpdate({
            ...props.schedule,
            reoccurrenceSettings: item
        });
    }

    return (
        <div>
            <h2>Schedule Settings</h2>
            <FormTextInput
                id="schedule-name"
                loading={props.loading}
                value={props.schedule.name}
                headingText="Schedule title:"
                onChange={onNameChange}
                dangerHelperText={errors.name}
                disabled={props.disabled}
            />

            <FormDateInput
                id="schedule-start-date"
                headingText="When would you like your schedule to start?"
                onChange={onStartDateChange}
                value={props.schedule.startDate}
                loading={props.loading}
                minDate={(props.disabled || props.editing) ? null : moment().subtract(maxMonthsInPast, 'M').startOf('day').toDate()}
                maxDate={(props.disabled || props.editing) ? null : moment().add(maxMonthsInFuture, 'M').toDate()}
                dangerHelperText={errors.startDate}
                includeTime={true}
                disabled={props.disabled || props.editing}
            />

            <ReoccurrenceSettings
                allowWeeklyRepeating={props.allowWeeklyRepeating}
                loading={props.loading}
                reoccurrenceSettings={props.schedule.reoccurrenceSettings}
                onReoccurrenceUpdate={onReoccurrenceChange}
                onValidationUpdate={onReoccurrenceValidationUpdate}
                startDate={props.schedule.startDate}
                disabled={props.disabled || props.editing}
            ></ReoccurrenceSettings>

        </div>
    );
}

ScheduleReoccurrenceSettings.defaultProps = {
    disabled: false,
    allowWeeklyRepeating: false,
    editing: false
};

ScheduleReoccurrenceSettings.propTypes = {
    disabled: PropTypes.bool,
    allowWeeklyRepeating: PropTypes.bool,
    onValidationUpdate: PropTypes.func.isRequired,
    onScheduleUpdate: PropTypes.func.isRequired,
    schedule: PropTypes.object.isRequired,
    editing: PropTypes.bool
};
import { Icon, Intent, Tooltip } from "@blueprintjs/core";
import axios from "axios";
import classNames from "classnames";
import { Button } from "components/buttons";
import { Filter } from "components/filtering";
import { FormHelperText, FormSuggest, FormSwitch, FormTextInput } from "components/form-fields";
import { ShowHide } from "components/layout";
import { ListingTable } from "components/listing";
import { NotificationToaster } from "components/notifications";
import { useDebounce } from "hooks/useDebounce";
import React, { Fragment, useRef, useEffect, useState } from "react";
import { LocationService } from "services";
import PropTypes from 'prop-types';

export function MigrationLocationsSelector(props) {
    const locationFilter = {
        "filters":
            [
                { "key": "BusinessAreas", "value": "Tacho" },
                { "key": "States", "value": "Active" }
            ]
    };

    var pageFilters = [
        {
            "displayName": "Assigned or unassigned locations",
            "name": "assignment",
            "items": [
                { id: 'assigned', name: 'Assigned' },
                { id: 'unassigned', name: 'Unassigned' }
            ]
        }
    ];

    const tableHeaders = ["Source location", "Target location", ""];
    const debounceTimeout = 750;
    const defaultPageSize = 25;
    const defaultEmptyListingMessage = 'No valid locations exist for this account.';

    const [fromLocationList, setFromLocationList] = useState([]);
    const [toLocationList, setToLocationList] = useState([]);
    const [tableRows, setTableRows] = useState([]);
    const [totalRecords, setTotalRecords] = useState(0);
    const [pageNumber, setPageNumber] = useState(1);
    const [pageSize, setPageSize] = useState(defaultPageSize);
    const [loading, setLoading] = useState(true);
    const [appliedFilters, setAppliedFilters] = useState([]);
    const [showFilters, setShowFilters] = useState(false);
    const [searchTerm, setSearchTerm] = useState('');
    const [emptyListingMessage, setEmptyListingMessage] = useState(defaultEmptyListingMessage);
    const [autoSelectLocationsSetting, setAutoSelectLocationsSetting] = useState(false);

    const searchInputRef = useRef(null);

    const debouncedSearchTerm = useDebounce(searchTerm, debounceTimeout);

    useEffect(initialLoad, []);

    useEffect(() => {
        const filteredList = fromLocationList
            .filter(filterLocation);

        setTotalRecords(filteredList.length);

        const pagedList = filteredList.slice((pageNumber - 1) * pageSize, pageNumber * pageSize);

        const newTableRows = pagedList.map(r => {
            const selectedLocation = getSelectedLocation(r.id);
            return [
                r.name,
                <FormSuggest
                    items={toLocationList}
                    onItemSelect={(location) => onLocationSelected(r, location)}
                    headingText=""
                    selectedValue={selectedLocation?.toLocation?.id}
                    disabled={props.saving}
                />,
                selectedLocation?.autoSelected ? <Tooltip content="Locations were automatically matched"><Icon icon="automatic-updates" /></Tooltip> : ""
            ];
        });

        setTableRows(newTableRows);
    }, [pageNumber, pageSize, fromLocationList, toLocationList, props.settings, debouncedSearchTerm, appliedFilters, props.saving]);

    useEffect(() => {
        setPageNumber(1);
        setEmptyListingMessage("No results found, please try a different search term.");
    }, [debouncedSearchTerm]);

    function initialLoad() {
        const fromLocationFilter = {
            "filters":
                [
                    ...locationFilter["filters"],
                    ...props.additionalLocationFilters
                ]
        };

        axios.all([
            LocationService.getLocationList(0, 1, '', 'Name', 'A', fromLocationFilter),
            LocationService.getLocationListForAnotherAccount(props.toAccountId, 0, 1, '', 'Name', 'A', locationFilter)
        ]).then(axios.spread(function (fromLocationsResponse, toLocationsResponse) {
            if (toLocationsResponse.totalCount > 0) {
                setFromLocationList(formatLocations(fromLocationsResponse.data));
                setToLocationList(formatLocations(toLocationsResponse.data));
                setTotalRecords(fromLocationsResponse.totalCount);
            } else {
                setEmptyListingMessage(`The account the ${props.entityTitle.toLowerCase()} are being migrated to does not have any valid locations. Please create some locations for this account.`);
            }
            setLoading(false);
        }), () => {
            NotificationToaster.show(Intent.DANGER, "Could not load the location needed. Please refresh the page.");
        });
    }

    function filterLocation(location) {
        if (debouncedSearchTerm && !location.name.toLowerCase().includes(debouncedSearchTerm.trim().toLowerCase())) {
            return false;
        }

        const numberOfAssignmentFilters = pageFilters.find(x => x.name === 'assignment').items.length;

        const assignmentFilter = appliedFilters.filter(x => x.name === 'assignment');
        const bothAssignedAndUnassignedSelected = assignmentFilter.length === numberOfAssignmentFilters || assignmentFilter.length === 0;

        if (!bothAssignedAndUnassignedSelected) {
            const filterValue = assignmentFilter[0].value;

            if (filterValue === 'assigned' && !props.settings.locationsMatch.some(x => x.fromLocation?.id === location.id)) {
                return false;
            }
            if (filterValue === 'unassigned' && props.settings.locationsMatch.some(x => x.fromLocation?.id === location.id)) {
                return false;
            }
        }

        return true;
    }

    function formatLocations(locationList) {
        return locationList.map(x => {
            return {
                id: x.id,
                name: x.name
            };
        });
    }

    function onPagingChange(newPageNumber, newPageSize) {
        setPageNumber(newPageNumber);
        setPageSize(newPageSize);
    }

    function onLocationSelected(fromLocation, selectedLocation) {
        const selectedLocationsClone = props.settings.locationsMatch ? [...props.settings.locationsMatch] : [];

        const locationIndex = selectedLocationsClone.findIndex(x => x.fromLocation.id === fromLocation.id);

        if (locationIndex > -1) {
            selectedLocationsClone.splice(locationIndex, 1);
        }

        if (selectedLocation && selectedLocation.id) {
            selectedLocationsClone.push({
                fromLocation: fromLocation,
                toLocation: selectedLocation
            });
        }

        const settingsClone = {
            ...props.settings,
            locationsMatch: selectedLocationsClone
        };

        props.onSettingsChange(settingsClone);
    }

    function getSelectedLocation(locationId) {
        return props.settings.locationsMatch?.find(x => x.fromLocation?.id === locationId);
    }

    function onToggleFilter() {
        setShowFilters((prevState) => {
            return !prevState;
        });
    }

    function onSearchChange(item) {
        setSearchTerm(item.target.value);
    }

    function onFilterChange(listingFilters) {
        setPageNumber(1);
        setEmptyListingMessage("No results found, please try a different filter.");
        setAppliedFilters(listingFilters);

        if (listingFilters.length > appliedFilters.length) {
            setShowFilters(true);
        }
    }

    function onAutoSelectLocations(event) {
        const newAutoSelectLocations = event.target.checked;

        if (newAutoSelectLocations) {
            autoSelectLocations();
        } else {
            removeAutoSelectLocations();
        }

        setAutoSelectLocationsSetting(newAutoSelectLocations);
    }


    function autoSelectLocations() {
        let assignedLocationsClone = [...props.settings.locationsMatch];

        const unassignedLocations = fromLocationList.filter(x => !assignedLocationsClone.some(y => y.fromLocation?.id === x.id));

        const autoSelectedLocations = unassignedLocations.map(x => {
            return {
                fromLocation: x,
                toLocation: toLocationList.find(y => y.name.toLowerCase().trim() === x.name.toLowerCase().trim()),
                autoSelected: true
            };
        }).filter(x => x.toLocation);

        assignedLocationsClone = assignedLocationsClone.concat(autoSelectedLocations);

        props.onSettingsChange({
            ...props.settings,
            locationsMatch: assignedLocationsClone
        });

        const numberOfAutoMatchedLocations = autoSelectedLocations.length;

        if (numberOfAutoMatchedLocations > 0) {
            NotificationToaster.show(Intent.SUCCESS, `Automatically matched ${numberOfAutoMatchedLocations} locations.`);
        } else {
            NotificationToaster.show(Intent.WARNING, 'Could not automatically match any locations as none match on name.');
        }
    }

    function removeAutoSelectLocations() {
        const nonAutoSelectedLocations = props.settings.locationsMatch.filter(x => !x.autoSelected);

        props.onSettingsChange({
            ...props.settings,
            locationsMatch: nonAutoSelectedLocations
        });
    }


    return <div>
        <h2>Map locations for the {props.entityTitle.toLowerCase()}</h2>
        <FormHelperText>Map locations from the current account to the locations on the account being migrated to. {props.entityTitle} from the locations on the left of the table will be migrated to the locations on the right of the table.</FormHelperText>
        <div className="spacer-bottom">

            <ShowHide
                evaluator={tableRows.length === 0 && !loading && debouncedSearchTerm === "" && appliedFilters.length === 0}
                hide={(
                    <Fragment>
                        <div className={classNames("pull-left", { "spacer-bottom": !showFilters })}>
                            <div className="inline-items">
                                <FormTextInput inputRef={searchInputRef} placeholder="Location Search" onChange={onSearchChange} value={searchTerm} large loading={loading} disabled={props.saving} icon="search" id="migration-location-search-field" />
                                <Tooltip content="Filter" position="right">
                                    <Button icon="filter" onClick={onToggleFilter} className={classNames({ "active": appliedFilters.length > 0 })} disabled={props.saving} />
                                </Tooltip>
                                <div className="inline-item form-field-inline-switcher-container">
                                    <span className={classNames("form-field-inline-switcher-label", { "bp3-skeleton": loading })}>Automatically match locations based on their name</span>
                                    <div className="form-field-inline-switcher">
                                        <FormSwitch
                                            disabled={props.saving}
                                            onChange={onAutoSelectLocations}
                                            alignment="right"
                                            inline={true}
                                            loading={loading}
                                            checked={autoSelectLocationsSetting} />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <Filter filterName="migration" visible={showFilters} filters={pageFilters} onUpdate={onFilterChange} disabled={props.saving} />
                    </Fragment>
                )}
            />

            <ListingTable
                id="listing-table-migration-locations"
                headers={tableHeaders}
                data={tableRows}
                totalRecordCount={totalRecords}
                onPagingChange={onPagingChange}
                loadingData={loading}
                noDataMessage={emptyListingMessage}
            />
        </div>
    </div>;
}

MigrationLocationsSelector.defaultProps = {
    saving: false,
    toAccountId: null,
    entityTitle: 'Entities',
    additionalLocationFilters: []
};

MigrationLocationsSelector.propTypes = {
    saving: PropTypes.bool,
    settings: PropTypes.object.isRequired,
    toAccountId: PropTypes.number,
    onSettingsChange: PropTypes.func.isRequired,
    entityTitle: PropTypes.string,
    additionalLocationFilters: PropTypes.array
};
import ApiService from "services/ApiService";

const badRequestCode = 400;

export const ApiRequestService = {

    get (microservice, endpoint) {
        return new Promise((resolve, reject) => {
            ApiService.get(`/${microservice}/${endpoint}`).then(
                (result) => {
                    resolve(result.data);
                },
                (error) => {
                    reject(error);
                });
        });
    },
    post(microservice, endpoint, params, errorMessage) {
        return new Promise((resolve, reject) => {
            ApiService.post(`/${microservice}/${endpoint}`, params).then(
                (result) => {
                    resolve(result.data);
                },
                (error) => {
                    if (errorMessage) {
                        if (error.response.status === badRequestCode) {
                            reject(error.response.data);
                        } else {
                            reject(errorMessage);
                        }
                    } else {
                        reject(error.response);
                    }
                });
        });
    },
    put(microservice, endpoint, params) {
        return new Promise((resolve, reject) => {
            ApiService.put(`/${microservice}/${endpoint}`, params).then(
                (result) => {
                    resolve(result.data);
                },
                (error) => {
                    reject(error.response);
                });
        });
    },
    patch(microservice, endpoint, params) {
        return new Promise((resolve, reject) => {
            ApiService.patch(`/${microservice}/${endpoint}`, params).then(
                (result) => {
                    resolve(result.data);
                },
                (error) => {
                    reject(error.response);
                });
        });
    }
};

export default ApiRequestService;
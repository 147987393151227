import React, { useState,  useEffect } from 'react';
import { Link } from "react-router-dom";

import { Breadcrumbs } from 'components/navigation';
import { ActiveStatus } from 'components/status';
import { ArrayToEllipsesString } from 'components/formatting';
import { LinkButton } from 'components/buttons'
import { ListingTable } from "components/listing";
import { PageRestricted } from 'pages/errors/page-restricted';
import { UserAccess, UserHasAccess } from 'components/user-access';

import { EmployeeService } from 'services';

import moment from "moment";

export function PolicyListing(props) {

    const defaultRecordSize = 25;

    const [totalRecords, setTotalRecords] = useState(0);
    const [tableRows, setTableRows] = useState([]);
    const [pageNumber, setPageNumber] = useState(1);
    const [pageSize, setPageSize] = useState(defaultRecordSize);
    const [loadingData, setLoadingData] = useState(true);
    const noDataMessage = "You have no policies, please click 'Add new policy' to get started.";
    const tableHeaders = ["Status", "Name", "No. of Docs", "Active From", "End Date", "Driver Types", "Driver Categories", "Locations"];
    const sortableHeaders = ["Status", "Name", "Active From", "End Date"];
    const [tableSortBy, setTableSortBy] = useState("Name");
    const [tableSortDir, setTableSortDir] = useState("A");
    const requiredAction = "EmployeeDeclaration:Manage";
    const canAccessPage = UserHasAccess(requiredAction);
    
    useEffect(() => {
        if (loadingData && canAccessPage) {

            EmployeeService.getPolicies(pageSize, pageNumber, tableSortBy, tableSortDir).then(r => {

                let results = r.data;
                let data = [];

                if (results != null) {
                    data = results.map((d) => {
                        
                        var docCount = d.policyDocuments.length;

                        var driverCats = d.employeeCategorys.map(b => {
                            return b.name
                        });
                                                
                        var drStatus = d.employeeTypes.map(t => {
                            return t.name
                        });

                        var locations = getLocations(d);

                        var endDateStr = 'not set';
                        if (d.endDate) {
                            endDateStr = moment(d.endDate).format('DD/MM/YYYY');
                        }                        


                        return [(<ActiveStatus isActive={d.active} />), (<Link to={`/policy/${d.policyId}`}>{d.name}</Link>),
                            docCount, moment(d.startDate).format('DD/MM/YYYY'), endDateStr, drStatus.join(', '),
                            (<ArrayToEllipsesString>{driverCats}</ArrayToEllipsesString>), (<ArrayToEllipsesString>{locations}</ArrayToEllipsesString>)];
                    })
                }

                setTableRows(data);
                setTotalRecords(r.totalCount);
                setLoadingData(false);

            });
        }
    
    }, [loadingData, pageSize, pageNumber, tableSortBy, tableSortDir]);

    function onPagingChange(newPageNumber, newPageSize) {
        setPageNumber(newPageNumber);
        setPageSize(newPageSize);
        setLoadingData(true);
    }
       

    function onTableSort(header, direction) {
        setTableSortBy(header);
        setTableSortDir(direction);
        setLoadingData(true);
    }

    function getLocations(policy) {
        var locations = [];
        if (!policy.useAllLocations) {            
            locations = policy.locationNames;
        } else {
            locations.push('All Locations');
        }
        return locations;
    }

    return (

        <UserAccess perform={requiredAction}
            yes={() => (
                <div className="row">

                    <Breadcrumbs items={props.breadcrumbs} />

                    <h1>Policy Management</h1>
                    <div className="intro-text">
                        <p>From here you can create and manage your policies.</p>
                    </div>

                    <div>
                        <LinkButton intent="primary" text="Add new policy" href="/policy/create" id="new-policy" />
                        <UserAccess perform="EmployeeDeclaration:Upload"
                            yes={() => (
                                    <LinkButton intent="primary" text="Upload declaration" href="/policy/upload-declaration" id="upload-declaration" />
                            )} />
                        
                    </div>

                    <ListingTable
                        id="listing-table-location"
                        headers={tableHeaders}
                        data={tableRows}
                        totalRecordCount={totalRecords}
                        onPagingChange={onPagingChange}
                        loadingData={loadingData}
                        noDataMessage={noDataMessage}
                        sortable
                        sortableHeaders={sortableHeaders}
                        sortedBy={tableSortBy}
                        sortedDir={tableSortDir}
                        onSort={onTableSort}
                    />
                </div>
            )}
            no={() => (
                <PageRestricted />
            )}
        />

        

    );

}
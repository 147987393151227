import { BlobServiceClient } from "@azure/storage-blob";
import { HelperFunctions } from "helpers";

export const BlobStorageService = {
	downloadFile(storageName, containerName, filename, token, downloadName) {
		return new Promise((resolve, reject) => {
			this.fetchFile(storageName, containerName, filename, token).then(function (contents) {
				HelperFunctions.downloadFile(contents, downloadName);
				resolve();
			}, function (error) {
				reject(error);
            })
		});
	},
	fetchFile(storageName, containerName, filename, token) {
		return new Promise((resolve, reject) => {
			const blobServiceClient = new BlobServiceClient(`${storageName}?${token}`);

			const containerClient = blobServiceClient.getContainerClient(containerName);
			const blobClient = containerClient.getBlobClient(filename);

			blobClient.download().then(function (downloadBlockBlobResponse) {
				downloadBlockBlobResponse.blobBody.then(function (contents) {
					resolve(contents);
				})
			}, function (error) {
				reject(error);
			});

		});
    }
}
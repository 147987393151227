import axios from 'axios';
import { authentication } from '../components/user-access/Authentication';

const ApiService = axios.create({
    timeout: 30000
});

ApiService.interceptors.request.use(function (config) {
    config.baseURL = window.env.API_URL;
    var isIE = !!window.MSInputMethodContext && !!document.documentMode;

    if (isIE) {
        config.headers.Pragma = 'no-cache';
    }

    var authConfig = authentication.getAccessToken();
    if (authConfig != null) {
        config.headers.Authorization = `Bearer ${authConfig.accessToken}`;
    }

    if (window.env.DEVELOP_MODE && config.url.includes(`/${window.env.DEVELOP_SERVICE}/`)) {
        config.baseURL = 'https://localhost:44342';
        config.url = config.url.replace(`/${window.env.DEVELOP_SERVICE}/`, '/');
    }

    return config;
}, function (error) {
    return Promise.reject(error);
});

ApiService.interceptors.response.use(function (response) {
    return response;
}, function (error) {
        const status = error.response ? error.response.status : null
        const originalRequest = error.config;

        const unauthorized = 401;
        const onHold = 402;
        const noSessionId = 412

        switch (status) {
            case unauthorized:
                return new Promise(function (resolve, reject) {
                    authentication.run(() => {
                        ApiService(originalRequest).then(function (response) {
                            resolve(response);
                        }, function (error) {
                            reject(error);
                        });
                    });
                });
            case noSessionId:
                authentication.rechallenge();
                return Promise.reject(error);
            case onHold:
                authentication.onHoldRedirect();
                return Promise.reject(error);
            default:
                return Promise.reject(error);
        }
       
});


export default ApiService;
import React, { useState, useEffect } from 'react';
import { Intent } from '@blueprintjs/core';
import { ButtonSave } from "components/buttons";
import { useDirtyData } from 'hooks/useDirtyData';
import { UserAccess } from 'components/user-access';
import { PageRestricted } from 'pages/errors/page-restricted';
import { ScheduleReoccurrenceSettings } from 'pages/schedules/shared/reoccurrence-settings';
import { ScheduleReports } from 'pages/schedules/shared/reports';
import { ScheduleReportParameters } from 'pages/schedules/shared/report-parameters';
import { ScheduleRecipients } from 'pages/schedules/shared/recipients';
import { AlertUnsavedData, NotificationInline, NotificationToaster } from "components/notifications";
import { SelfReportingService } from 'services';

import moment from "moment";


export function ScheduleEdit(props) {

    const scheduleModel = {
        name: "",
        startDate: moment().utc().add(1, "H").minute(0).format("YYYY-MM-DD HH:mm"),
        reoccurrenceSettings: {
            reoccurring: false
        },
        reports: [],
        reportInstances: [],
        recipients: []
    };

    const [originalSettings, setOriginalSettings] = useState(scheduleModel);
    const [loading, setLoading] = useState(true);
    const [saving, setSaving] = useState(false);
    const [schedule, setSchedule] = useState(scheduleModel);
    const isScheduleDirty = useDirtyData(originalSettings, schedule);
    const [disabled, setDisabled] = useState(false);
    const [scheduleSettingsValid, setScheduleSettingsValid] = useState(true);
    const [scheduleReportsValid, setScheduleReportsValid] = useState(true);
    const [scheduleParametersValid, setScheduleParametersValid] = useState(true);
    const [scheduleRecipientsValid, setScheduleRecipientsValid] = useState(true);

    const requiredAction = "ScheduleReports:Manage";

    useEffect(init, []);

    function init() {
        SelfReportingService.getSchedule(props.scheduleId).then(function (scheduleResponse) {
            setDisabled(scheduleResponse.status === "COMPLETE" || scheduleResponse.status === "RUNNING")
            setSchedule(scheduleResponse);
            setOriginalSettings(scheduleResponse);
            setLoading(false);
        }, function () {
            NotificationToaster.show(Intent.DANGER, "Could not retrieve schedule, please try again");
        });
    }

    function updateSchedule(updatedSchedule) {
        setSchedule(updatedSchedule);
    }

    function onValidationUpdate(updateFunction, valid) {
        updateFunction(valid);
    }

    function saveSchedule() {
        setSaving(true);

        SelfReportingService.saveSchedule(props.scheduleId, schedule).then(function () {
            NotificationToaster.show(Intent.SUCCESS, "Schedule updated successfully");
            setOriginalSettings(schedule);
        }, function (error) {
            NotificationToaster.show(Intent.DANGER, error);
        }).finally(function () {
            setSaving(false);
        });
    }

    return (

        <UserAccess perform={requiredAction}
            yes={() => (
                
                <div className="row">

                    <NotificationInline allowClose={false} show={schedule.status === "COMPLETE"} intent="primary" text="This schedule has finished so it cannot be edited anymore" />
                    <NotificationInline allowClose={false} show={schedule.status === "RUNNING"} intent="primary" text="You cannot make changes whilst the schedule is currently running" />

                    <ScheduleReoccurrenceSettings editing allowWeeklyRepeating={true} schedule={schedule} onScheduleUpdate={updateSchedule} onValidationUpdate={(valid) => { onValidationUpdate(setScheduleSettingsValid, valid); }} loading={loading} disabled={saving || disabled} />
                    <ScheduleReports schedule={schedule} onScheduleUpdate={updateSchedule} onValidationUpdate={(valid) => { onValidationUpdate(setScheduleReportsValid, valid); }} loading={loading} disabled={saving || disabled} />
                    <ScheduleReportParameters editing schedule={schedule} onScheduleUpdate={updateSchedule} onValidationUpdate={(valid) => { onValidationUpdate(setScheduleParametersValid, valid); }} loading={loading} disabled={saving || disabled} />
                    <ScheduleRecipients schedule={schedule} onScheduleUpdate={updateSchedule} onValidationUpdate={(valid) => { onValidationUpdate(setScheduleRecipientsValid, valid); }} loading={loading} disabled={saving || disabled} />

                    <AlertUnsavedData
                        isDirty={isScheduleDirty}>
                    </AlertUnsavedData>

                    <ButtonSave
                        id="user-role-save"
                        onClick={saveSchedule}
                        disabled={saving}
                        loading={loading}
                        simpleDisabled={disabled || !scheduleSettingsValid || !scheduleReportsValid || !scheduleParametersValid || !scheduleRecipientsValid}
                    ></ButtonSave>
                </div>
                 
            )}
            no={() => (
                <PageRestricted />
            )}
        />

    );

}
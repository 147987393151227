import { FormTextInput } from 'components/form-fields';
import { useValidation } from 'hooks/useValidation';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import React, { Fragment, useEffect } from 'react';

export function OcrStringLayout(props) {
    const [_, errors, validate] = useValidation();

    useEffect(() => {
        validateLayout();
    }, [props.layout.answer]);

    function validateLayout() {
        const required = props.layout.requiredAnswerCount > 0;
        const rules = [{ fieldName: 'answer', required: required }];
        validate(rules, props.layout);
    }

    function onValueChange(event) {
        const clonedLayout = { ...props.layout };

        clonedLayout.answer = event.target.value;

        props.onChange(clonedLayout);
    }

    return <Fragment>
        <div className={classNames({ "hide-in-print": !props.readonly, "hidden": props.readonly })}>
            <FormTextInput
                loading={props.loading}
                disabled={props.disabled}
                onChange={onValueChange}
                value={props.layout.answer ? props.layout.answer : ''}
                headingText={props.layout.secondaryLayoutTitle}
                dangerHelperText={errors.answer}
                maxlength={2000}
            />
        </div>
        <div className={classNames({ "show-in-print": !props.readonly, "visible": props.readonly })}>
            <h4 className={classNames({ "bp3-skeleton": props.loading })}>{props.layout.secondaryLayoutTitle ? props.layout.secondaryLayoutTitle : ''}</h4>
            <p className={classNames({ "show-in-print": !props.readonly, "visible": props.readonly, "bp3-skeleton": props.loading })}>{props.layout.answer ? props.layout.answer : ''}</p>
        </div>
    </Fragment>;
}

OcrStringLayout.propTypes = {
    loading: PropTypes.bool,
    layout: PropTypes.object.isRequired,
    onChange: PropTypes.func.isRequired,
    disabled: PropTypes.bool,
    readonly: PropTypes.bool
};

OcrStringLayout.defaultProps = {
    loading: false,
    disabled: false,
    readonly: false
};

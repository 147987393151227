import React, { useEffect, useState } from 'react';
import 'components/form-fields/FormFields.css';
import "moment/locale/en-gb";
import { UserService, LocationService } from "services";
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { FormGroupedCheckboxItems, FormHelperText } from 'components/form-fields';
import { Popover, Position, Intent, Divider, InputGroup, Tooltip } from "@blueprintjs/core";
import { ShowHide } from 'components/layout';
import { Button } from 'components/buttons';

export function FormLocationSelector(props) {
    const [locationList, setLocationList] = useState(null);
    const [locationHierarchyList, setLocationHierarchyList] = useState({
        location: null,
        hierarchy: null
    });
    const [loading, setLoading] = useState(true);
    const [searchTerm, setSearchTerm] = useState("");
    const [locationDropDownName, setLocationDropDownName] = useState("Select a location");
    const [hierarchyExists, setHierarchyExists] = useState(false);
    const groupIdentifier = "group";
    const locationIdentifier = "item";
    const [dropdownOpen, setDropdownOpen] = useState(false);

    useEffect(() => {
        if (loading &&
            props.businessArea != null &&
            props.useLocationDefaults != null &&
            props.useHierarchy != null &&
            props.setUseHierarchy != null &&
            props.locationDefaultsToTrue != null) {
            //Right we know we've got everything we need, this won't run till useLocationDefaults has been set, at this point we know the reduc has loaded
            setupLocationsAndHierarchy();
        }
    }, [props.businessArea, props.useLocationDefaults, props.useHierarchy, props.setUseHierarchy, props.locationDefaultsToTrue]);

    useEffect(() => {
        if (!loading &&
            props.businessArea) {
            setupLocationsAndHierarchy();
        }
    }, [props.businessArea, props.customLocations]);

    useEffect(() => {
        if (props.businessArea === "" &&
            props.useCustomLocations) {
            setupLocationsAndHierarchy();
        }
    }, [props.customLocations]);

    useEffect(() => {
        if (!loading && props.selectedLocations) {
            processSelectedLocationsChange(locationList);
        }
    }, [props.selectedLocations]);

    function processSelectedLocationsChange(locList) {

        if (locList != null) {
            checkItems(locList);
            setLocationList(locList);
        }

        //Just make sure selectedLocations are acctualy in the locList, as there could be inactive locations (the AlertListing page can do this)
        var selectedLocationsInList = props.selectedLocations.filter(l => findRecursiveItem(locList, l, locationIdentifier) !== null);

        if (selectedLocationsInList.length === 1) {
            var singleLocation = findRecursiveItem(locList, selectedLocationsInList[0], locationIdentifier);
            setLocationDropDownName(singleLocation.name);
        } else {
            if (props.singleSelection) {
                setLocationDropDownName(`Select a location`);
            } else {
                setLocationDropDownName(`Locations (${selectedLocationsInList.length} selected)`);
            }
        }
    }

    function setupLocationsAndHierarchy() {
        //Wait for this to load
        if (props.useCustomLocations && props.customLocations == null) {
            return;
        }

        //Define function to map the locations and hierarchy
        function processLocations(locHierarchyList) {

            setLocationHierarchyList(locHierarchyList);

            if (locHierarchyList?.hierarchy?.length > 0 || locHierarchyList?.location?.length > 0) {
                var hierarchy = false;

                if (locHierarchyList?.hierarchy?.length > 0 && locHierarchyList.hierarchy[0].items?.length !== locHierarchyList.location[0].items?.length) {
                    //Only true if Hierarchy is a different length (longer) than the Location list
                    setHierarchyExists(true);
                    hierarchy = true;
                } else if (props.useHierarchy) {
                    props.setUseHierarchy(false);
                }

                var selectedList = hierarchy && props.useHierarchy ? locHierarchyList.hierarchy : locHierarchyList.location;
                setLocationList(selectedList);

                if (props.useLocationDefaults && loading) {
                    var selectedLocations = [];
                    setSelectedLocations(selectedList, selectedLocations, props.locationDefaultsToTrue);
                    props.setSelectedLocations(selectedLocations);
                } else if (props.selectedLocations != null) {
                    //This could have loaded while this was running or maybe the businessArea's changed
                    processSelectedLocationsChange(selectedList);
                }
            }
        }


        if (props.useCustomLocations)
        {
            processLocations(props.customLocations);
        }
        else
        {
            LocationService.getLocationBusinessAreas().then(function (locBusAreas) {
                if (locBusAreas && locBusAreas.length > 0) {
                    var selectedLocBusAreas = locBusAreas.find(b => b.businessArea === props.businessArea);
                    if (selectedLocBusAreas?.locationBusinessAreaId) {
                        UserService.getLoggedInUserLocations(selectedLocBusAreas.locationBusinessAreaId, props.locationDefaultsToTrue, true).then(function (locHierarchyList) {
                            processLocations(locHierarchyList);
                        })
                    }
                }
            });
        }
        
        setLoading(false);
    }

    function setSelectedLocations(items, selectedLocations, checkedState) {
        items.map(function (item) {
            if (item?.type?.toLowerCase() === groupIdentifier) {
                setSelectedLocations(item.items, selectedLocations, checkedState)
            } else {
                if (checkedState) {
                    if (!selectedLocations?.some(i => i === item.id)) {
                        selectedLocations.push(item.id);
                    }
                } else {
                    var index = selectedLocations.indexOf(item.id);

                    if (index > -1) {
                        selectedLocations.splice(index, 1);
                    }
                }
            }
        });
    }

    function findRecursiveItem(structure, id, type) {
        var response = null;

        if (structure != null) {
            for (var i = 0; i < structure.length; i++) {
                var currentItem = structure[i];

                if (currentItem.type === type && currentItem.id === id) {
                    return currentItem;
                }

                if (currentItem.type.toLowerCase() === groupIdentifier) {
                    var innerResponse = findRecursiveItem(currentItem.items, id, type);
                    if (innerResponse != null) {
                        response = innerResponse;
                    }
                }

            }
        }

        return response;
    }

    function setChecked(item, innerItemsCheckedState) {
        item.checked = innerItemsCheckedState.all;
        item.partialChecked = !innerItemsCheckedState.all && innerItemsCheckedState.some;
    }

    function checkItems(items) {
        var checkedState = {
            all: true,
            some: false
        };

        if (items?.length > 0) {
            items.map(function (item) {
                if (item.type?.toLowerCase() === groupIdentifier) {
                    setChecked(item, checkItems(item.items));
                } else {
                    if (props.selectedLocations?.some(i => i === item.id)) {
                        item.checked = true;
                    } else {
                        item.checked = false;
                    }
                }

                if (!item.checked) {
                    checkedState.all = false;
                } else {
                    checkedState.some = true;
                }

                return item;
            });
        }

        return checkedState;
    }

    function applySearchTerm(locationItems, searchTermToApply) {

        var searchReturnedResults = false;
        var anyChildrenReturnedResults = false;

        if (locationItems == null) {
            return false;
        }

        for (var i = 0; i < locationItems.length; i++) {
            var currentItem = locationItems[i];
            currentItem.appearInSearch = false;

            if (currentItem.type === "item" && (currentItem.name.toLowerCase().indexOf(searchTermToApply.toLowerCase()) > -1)) {
                currentItem.appearInSearch = true;
                searchReturnedResults = true;
            }

            var childSearchReturnedResults = applySearchTerm(currentItem.items, searchTermToApply);

            if (childSearchReturnedResults) {
                currentItem.appearInSearch = true;
            }

            anyChildrenReturnedResults = anyChildrenReturnedResults || childSearchReturnedResults;
        }

        return searchReturnedResults || anyChildrenReturnedResults;
    }

    function filterItemsByActive(item) {
        return item.state == null || item.state.name === "Active";
    }

    function onItemSelect(id) {

        var clonedLocationList = [
            ...props.selectedLocations
        ];

        var index = clonedLocationList.indexOf(id);

        if (index > -1) {
            clonedLocationList.splice(index, 1);
        } else {
            clonedLocationList.push(id);
        }

        //This should kick off the useEffect where we'll do the update
        props.setSelectedLocations(clonedLocationList);

        if (props.singleSelection) {
            setDropdownOpen(false);
        }
    }

    function onGroupSelect(id) {

        var clonedLocationList = [
            ...props.selectedLocations
        ];

        var groupItems = findRecursiveItem(locationList, id, groupIdentifier);
        if (groupItems?.items) {
            setSelectedLocations(groupItems.items, clonedLocationList, !groupItems.checked);
            props.setSelectedLocations(clonedLocationList);
        }
    }

    function onGroupToggle(id) {

        var clonedItems = [
            ...locationList
        ];

        var group = findRecursiveItem(clonedItems, id, "group");
        group.expanded = !group.expanded;

        setLocationList(clonedItems);
    }

    function onSearchChange(item) {
        var newSearchTerm = item.target.value;

        applySearchTerm(locationList, newSearchTerm);

        setSearchTerm(newSearchTerm);
    }

    function onUseHierarchyChange() {
        var useHierarchy = !props.useHierarchy;
        var locList = useHierarchy ? locationHierarchyList.hierarchy : locationHierarchyList.location;
        props.setUseHierarchy(useHierarchy);
        checkItems(locList);
        setLocationList(locList);
    }

    function onLocationListOpened() {
        if (props.onLocationListOpened != null) {
            props.onLocationListOpened();
        }
    }

    function onLocationListClose() {
        if (props.onLocationListClose != null) {
            props.onLocationListClose();
        }
    }

    function onDropdownInteraction(shouldOpen) {
        setDropdownOpen(shouldOpen);
    }

    return (
        <div className="form-field">
            <ShowHide
                evaluator={props.headingText.length > 0}
                show={(
                    <h4 className={classNames({ "bp3-skeleton": props.loading })}>{props.headingText}</h4>
                )}
            >
            </ShowHide>
            <Popover
                onInteraction={onDropdownInteraction}
                isOpen={dropdownOpen}
                content={
                <div className={classNames("grouped-checkbox-list-container", { "bp3-skeleton": props.loading && loading })}>


                    <div className="location-selector-search-container">
                        <InputGroup
                            id="form-location-selector-search"
                            className="location-selector-search-input"
                            value={searchTerm}
                            onChange={onSearchChange}
                            large
                            placeholder="Search locations"
                            maxLength="50"
                            rightElement={(
                                <ShowHide
                                    evaluator={hierarchyExists}
                                    show={(
                                        <Tooltip position={Position.RIGHT} content="Toggle list view">
                                            <Button
                                                icon={props.useHierarchy ? "diagram-tree" : "list"}
                                                minimal={true}
                                                onClick={onUseHierarchyChange}
                                                tabIndex="-1"
                                            />
                                        </Tooltip>
                                    )}
                                />
                            )}
                        />
                    </div>

                    <Divider />

                    <div className="dropdown-list-scroll">
                        <FormGroupedCheckboxItems
                            items={locationList}
                            searchTerm={searchTerm}
                            groupsSelectable={!props.singleSelection}
                            onGroupToggle={onGroupToggle}
                            onItemSelect={onItemSelect}
                            onGroupSelect={onGroupSelect}
                            loading={loading || props.loading}
                            filter={filterItemsByActive}
                            disabled={props.disabled}
                            singleSelection={props.singleSelection}

                        />
                    </div>
                </div>
            } position={Position.BOTTOM_LEFT}
                minimal={true}
                canEscapeKeyClose={true}
                onOpened={onLocationListOpened}
                onClose={onLocationListClose}
            >
                <Button id={"location-selection"} className="bp3-button bp3-large btn-form btn-border grouped-checkbox-list-button" disabled={props.disabled} icon="map-marker" loading={props.loading} text={locationDropDownName} intent={Intent.NONE} minimal={true} large={false} onClick={() => { }} />
            </Popover>
            <FormHelperText loading={props.loading} danger={true}>{props.dangerHelperText}</FormHelperText>
        </div>
    );
}

FormLocationSelector.defaultProps = {
    loading: false,
    headingText: "",
    dangerHelperText: null,
    useCustomLocations: false,
    disabled: false,
    singleSelection: false,
    locationDefaultsToTrue: null
};

FormLocationSelector.propTypes = {
    headingText: PropTypes.string,
    dangerHelperText: PropTypes.string,
    businessArea: PropTypes.string.isRequired,
    loading: PropTypes.bool,
    selectedLocations: PropTypes.array.isRequired, 
    setSelectedLocations: PropTypes.func.isRequired,
    useHierarchy: PropTypes.bool.isRequired,
    setUseHierarchy: PropTypes.func,
    useCustomLocations: PropTypes.bool, //If the user wants to pass in a list of locations to show instead of a location permission list
    customLocations: PropTypes.object,
    useLocationDefaults: PropTypes.bool, //This should be set to true if you want this component to set the initial state
    locationDefaultsToTrue: PropTypes.bool, //This will only be used if useLocationDefaults is true, can default to null so don't want isRequired as that throws a warning
    onLocationListOpened: PropTypes.func,
    onLocationListClose: PropTypes.func,
    disabled: PropTypes.bool,
    singleSelection: PropTypes.bool
};
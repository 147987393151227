import React, { useState, useEffect } from 'react';

import { FormTextInput } from "components/form-fields";
import { Intent, Icon } from '@blueprintjs/core';
import { Breadcrumbs } from 'components/navigation';
import { UserAccess } from 'components/user-access';
import { PageRestricted } from 'pages/errors/page-restricted';
import { ShowHide } from 'components/layout';
import { Wizard } from "components/wizard";
import { LinkButton, Button } from 'components/buttons';
import { NotificationToaster, AlertUnsavedData } from "components/notifications";
import { HierarchyBusinessAreas } from 'pages/locations/hierarchies/edit/business-areas';
import { HierarchyFeatures } from 'pages/locations/hierarchies/edit/features';
import { HierarchyStructure } from 'components/hierarchy';
import { HierarchyService, LocationService } from 'services';
import { useDirtyData } from 'hooks/useDirtyData';

export function HierarchyCreate(props) {

    const hierarchyModel = {
        hierarchyName: "",
        businessAreas: [],
        hierarchyFeatures: []
    };

    const finalStep = 3;

    const [saving, setSaving] = useState(false);
    const [loading, setLoading] = useState(false);

    const [originalSettings, setOriginalSettings] = useState(hierarchyModel);
    const [hierarchySettings, setHierarchySettings] = useState(hierarchyModel);
    const [hierarchyStructure, setHierarchyStructure] = useState([]);
    const [wizardStep, setWizardStep] = useState(0);
    const [wizardValid, setWizardValid] = useState(false);
    const [wizardFinished, setWizardFinished] = useState(false);
    const [newHierachyId, setNewHierarchyId] = useState(0);
    const [locations, setLocations] = useState([]);
    const [inUseLocations, setInUseLocations] = useState([]);

    const isHierarchyDirty = useDirtyData(originalSettings, hierarchySettings);

    function calculateBusinessAreaClass(businessAreas, availableAreas) {

        var displayAreas = businessAreas.filter(function (ba) {
            return availableAreas.some(function (aa) {
                return aa.locationBusinessAreaId === ba.locationBusinessAreaId;
            })
        });

        var classArray = displayAreas.map(function (area) {
            return area.businessArea.toLowerCase();
        });

        return `${classArray.join("-")}-loc`;

    }

    function onWizardChange(step) {
        setWizardStep(step);

        if (step === 0) {
            setWizardValid(hierarchySettings.hierarchyName.length > 0);
        }

        if (step === 1) {
           
            LocationService.getLocationList(0, 1, "", "", "").then(function (locationResponse) {

                var usedLocations = [];
                usedLocations = getInUseLocations(hierarchyStructure, usedLocations);

                usedLocations = locationResponse.data.filter(function (location) {
                    return usedLocations.some(function (ul) {
                        return location.id === ul.locationId;
                    })
                });

                setInUseLocations(usedLocations);

            });
        }

        if (step === finalStep) {
            setLoading(true);

            var locationFilters = hierarchySettings.businessAreas.map(function (ba) {
                return {
                    "key": "BusinessAreas",
                    "value": ba.businessArea
                };
            });

            LocationService.getLocationList(0, 1, "", "", "", { "filters": locationFilters }).then(function (locationResponse) {
                var mappedLocationList = locationResponse.data.map((l) => {
                    return {
                        ...l,
                        class: calculateBusinessAreaClass(l.businessAreas, hierarchySettings.businessAreas)
                    };
                });
                setLocations(mappedLocationList);
                setLoading(false);
            });

        }

    }

    function getInUseLocations(hierarchy, usedLocations) {

        for (var i = 0; i < hierarchy.length; i++) {

            var currentGroup = hierarchy[i];

            if (currentGroup.groups && currentGroup.groups.length > 0) {
                usedLocations = getInUseLocations(currentGroup.groups, usedLocations);
            }

            if (currentGroup.locations && currentGroup.locations.length > 0) {
                usedLocations = usedLocations.concat(currentGroup.locations);
            }
        }

        return usedLocations;

    }

    useEffect(function () {

        var disabledAreasHierarchy = {
            ...hierarchySettings
        };

        disableInUseBusinessAreas(disabledAreasHierarchy);
        setHierarchySettings(disabledAreasHierarchy);

    }, [inUseLocations.length, hierarchySettings.hierarchyFeatures.length]);

    function disableInUseBusinessAreas(newHierarchySettings) {
        //Check to see if business areas are locked by features or by a location of that type being in use
        for (var i = 0; i < newHierarchySettings.businessAreas.length; i++) {

            var currentBusinessArea = newHierarchySettings.businessAreas[i];

            //Check there arent any features in use by this business area
            var featureInUse = newHierarchySettings.hierarchyFeatures.some(function (feature) {
                return feature.locationBusinessAreaId === currentBusinessArea.locationBusinessAreaId;
            });

            //Check there arent any locaitons in use by this business area only
            var locationInUse = inUseLocations.some(function (location) {

                //Get an intersection of business areas for this location, that have been switched on
                var filteredAreas = location.businessAreas.filter(value => newHierarchySettings.businessAreas.some(function (hsl) {
                    return hsl.locationBusinessAreaId === value.locationBusinessAreaId;
                }));

                if (filteredAreas.length === 1 && filteredAreas[0].locationBusinessAreaId === currentBusinessArea.locationBusinessAreaId) {
                    return true;
                }

                return false;

            });

            currentBusinessArea.disabled = featureInUse || locationInUse;

        }
    }

    function onWizardFinish() {
        setSaving(true);
        if (newHierachyId === 0) {
            HierarchyService.createLocationHierarchy(hierarchySettings).then(function (response) {
                setNewHierarchyId(response);
                saveStructure(response);
            },
                (error) => {
                    setSaving(false);
                    NotificationToaster.show(Intent.DANGER, error);
                });
        } else {
            saveStructure();
        }
    }

    function saveStructure(newId) {

        var hierarchyId = (newId == null ? newHierachyId : newId);

        HierarchyService.updateHierarchyStructure(hierarchyId, hierarchyStructure).then(function () {
            setOriginalSettings(hierarchySettings);
            setWizardFinished(true);
            setSaving(false);
        },
        (error) => {
            setSaving(false);
            NotificationToaster.show(Intent.WARNING, "You hierarchy has been created, but your structure has not been saved. Please try saving again.");
        });
    }

    function resetComponent() {
        setHierarchySettings(hierarchyModel);
        setHierarchyStructure([]);
        setOriginalSettings(hierarchyModel);
        setSaving(false);
        setWizardFinished(false);
        setNewHierarchyId(0);
    }

    function onHierarchyNameChange(e) {

        var newName = e.target.value;

        setHierarchySettings({
            ...hierarchySettings,
            hierarchyName: newName
        });

        if (wizardStep === 0) {
            setWizardValid(newName.length > 0);
        }
    }

    function onHierarchySettingsUpdate(newHierarchySettings) {
        setHierarchySettings(newHierarchySettings);
    }

    function onHierarchyStructureUpdate(newStructure) {
        setHierarchyStructure(newStructure);
    }

    function onBusinessAreasValidationUpdate(isValid) {
        if (wizardStep === 1) {
            setWizardValid(isValid);
        }
    }

    const requiredAction = "Locations:HierarchiesManage";

    return (

        <UserAccess perform={requiredAction}
            yes={() => (
                <div className="row">
                    <Breadcrumbs items={props.breadcrumbs} />
                    <ShowHide
                        evaluator={!wizardFinished}
                        show={(
                            <div>
                                <Wizard onStepChange={onWizardChange} onFinish={onWizardFinish} canProceed={wizardValid} disabled={saving}>

                                    <div>
                                        <h2 className="hierarchy-create-header">Firstly, lets give your location hierarchy a name</h2>
                                        <FormTextInput
                                            id="location-hierarchy-name"
                                            loading={false}
                                            value={hierarchySettings.hierarchyName}
                                            headingText="Location hierarchy name:"
                                            onChange={onHierarchyNameChange}
                                            disabled={false}
                                        />
                                    </div>

                                    <div>
                                        <h2 className="hierarchy-create-header">Which business area should this location hierarchy apply to?</h2>
                                        <HierarchyBusinessAreas onHierarchyUpdate={onHierarchySettingsUpdate} hierarchy={hierarchySettings} loading={loading} saving={saving} onValidationUpdate={onBusinessAreasValidationUpdate} />
                                    </div>

                                    <div>
                                        <h2 className="hierarchy-create-header">Which parts of vision would you like this location hierarchy's<br /> data to be applied to?</h2>
                                        <p>Here you can turn your location Hierarchy on or off for each of the dashboards listed.<br />The dashboards will group and compare results according to the hierarchy you create.</p>
                                        <HierarchyFeatures onHierarchyUpdate={onHierarchySettingsUpdate} hierarchy={hierarchySettings} loading={loading} saving={saving} permissionsChanging={false} />
                                    </div>

                                    <div>
                                        <HierarchyStructure onHierarchyUpdate={onHierarchyStructureUpdate} hierarchy={hierarchySettings} structure={hierarchyStructure} hierarchyId={0} loading={loading} saving={saving} locations={locations} onHierarchyDirtyChange={() => { }} />
                                    </div>

                                </Wizard>

                                <AlertUnsavedData
                                    isDirty={isHierarchyDirty}>
                                </AlertUnsavedData>

                            </div>
                        )}
                        hide={(
                            <div>
                                <div className="spacer-bottom" id="create-success-icon">
                                    <Icon icon="tick-circle" iconSize={40} intent={Intent.SUCCESS} />
                                </div>
                                <h2 id="create-success-name">Hierarchy successfully created</h2>
                                <p>You can edit the hierarchy from the locations management page.</p>
                                <div className="button-row" id="create-success-buttons">
                                    <LinkButton intent="primary" text="Location hierarchies" id="listing-hierarchies" href="/location/hierarchies" />
                                    <Button intent="primary" text="Add new hierarchy" id="new-hierarchy" onClick={resetComponent} />
                                </div>
                            </div>
                        )}
                    ></ShowHide>
                </div>
            )}
            no={() => (
                <PageRestricted />
            )}
        />

    );
}
import { Intent } from '@blueprintjs/core';
import { Button } from 'components/buttons';
import { FormNumericInput } from 'components/form-fields';
import { Modal } from 'components/notifications';
import { useValidation } from 'hooks/useValidation';
import PropTypes from 'prop-types';
import React, { Fragment, useEffect, useState } from 'react';
import { HelperFunctions } from 'helpers';

export function OcrVarianceTableForm(props) {
    const percentageMaxValue = 100;
    const decimalMaxValue = 1000000;

    const [varianceData, setVarianceData] = useState(null);

    const [isValid, errors, validate] = useValidation();

    useEffect(() => {
        if (props.varianceData) {
            setVarianceData({ ...props.varianceData });
        }
    }, [props.varianceData, props.modalOpen]);

    useEffect(() => {
        validateVarianceData();
    }, [varianceData]);

    useEffect(() => {
        HelperFunctions.executeIfNotNull(props.onValidationUpdate, isValid);
    }, [isValid]);

    function validateVarianceData() {
        if (varianceData) {
            const rules = getRules();
            validate(rules, varianceData);
        }
    }

    function getRules() {
        const rules = [];

        if (props.varianceData) {
            if (props.varianceData.previousEditable) {
                rules.push({ fieldName: 'previousVisit', required: true });
            }

            if (props.varianceData.currentEditable) {
                rules.push({ fieldName: 'currentVisit', required: true });
            }
        }

        return rules;
    }

    function selectPreviousVisitValue(previousVisit) {
        if (previousVisit === '') {
            previousVisit = null;
        }

        const varianceDataClone = { ...varianceData };
        varianceDataClone.previousVisit = previousVisit;

        setVarianceData(varianceDataClone);
    }

    function selectCurrentVisitValue(currentVisit) {
        if (currentVisit === '') {
            currentVisit = null;
        }

        const varianceDataClone = { ...varianceData };
        varianceDataClone.currentVisit = currentVisit;

        setVarianceData(varianceDataClone);
    }

    return <Fragment>
        <Modal
            isOpen={props.modalOpen}
            title={'Edit variance data'}
            onClose={props.onClose}
        >
            {varianceData && <div>
                <FormNumericInput
                    disabled={!varianceData.currentEditable}
                    onValueChange={selectCurrentVisitValue}
                    selectedValue={varianceData.currentVisit}
                    max={props.dataType.toUpperCase() === 'PERCENTAGE' ? percentageMaxValue : decimalMaxValue}
                    min={0}
                    allowDecimal={props.dataType.toUpperCase() === 'DECIMAL' || props.dataType.toUpperCase() === 'POUND'}
                    headingText={props.varianceData.context ? 'Current ' + props.varianceData.context : 'Current visit'}
                    dangerHelperText={errors.currentVisit}
                />
                <FormNumericInput
                    disabled={!varianceData.previousEditable}
                    onValueChange={selectPreviousVisitValue}
                    selectedValue={varianceData.previousVisit}
                    max={props.dataType.toUpperCase() === 'PERCENTAGE' ? percentageMaxValue : decimalMaxValue}
                    min={0}
                    allowDecimal={props.dataType.toUpperCase() === 'DECIMAL' || props.dataType.toUpperCase() === 'POUND'}
                    headingText={props.varianceData.context ? 'Previous ' + props.varianceData.context : 'Previous visit'}
                    dangerHelperText={errors.previousVisit}
                />
                <FormNumericInput
                    disabled={true}
                    onValueChange={() => null}
                    selectedValue={props.calculateVariance(varianceData)}
                    max={props.dataType.toUpperCase() === 'PERCENTAGE' ? percentageMaxValue : decimalMaxValue}
                    allowDecimal={props.dataType.toUpperCase() === 'DECIMAL' || props.dataType.toUpperCase() === 'POUND'}
                    headingText='Variance'
                />
            </div>}
            <div className="bp3-dialog-footer">
                <div className="bp3-dialog-footer-actions">
                    <Button text="Edit" intent={Intent.PRIMARY} onClick={() => props.onFinish(varianceData)} disabled={!isValid}></Button>
                    <Button text="Cancel" onClick={props.onClose}></Button>
                </div>
            </div>
        </Modal>
    </Fragment>
}

OcrVarianceTableForm.defaultProps = {
    varianceData: {},
    onValidationUpdate: null
};


OcrVarianceTableForm.propTypes = {
    varianceData: PropTypes.object,
    modalOpen: PropTypes.bool.isRequired,
    onFinish: PropTypes.func.isRequired,
    onClose: PropTypes.func.isRequired,
    calculateVariance: PropTypes.func.isRequired,
    dataType: PropTypes.string.isRequired,
    onValidationUpdate: PropTypes.func
};
import loggedInUserReducer from './LoggedInUserReducer';
import dirtyDataReducer from './DirtyDataReducer';
import FilteringReducer from './FilteringReducer';
import HierarchyReducer from './HierarchyReducer';
import RoutingReducer from './RoutingReducer';
import SiteReducer from './SiteReducer'
import { combineReducers } from 'redux';

export const allReducers = combineReducers({
    loggedInUser: loggedInUserReducer,
    route: RoutingReducer,
    dirtyData: dirtyDataReducer,
    filters: FilteringReducer,
    hierarchy: HierarchyReducer,
    site: SiteReducer
});
import React, { useState, useEffect } from 'react';
import { Intent } from '@blueprintjs/core';
import { useParams } from 'react-router-dom';
import axios from 'axios';

import { NotificationToaster, AlertUnsavedData, NotificationInline } from "components/notifications";
import { LinkButton, ButtonSave } from "components/buttons";
import { useDirtyData } from 'hooks/useDirtyData';
import { AccountService } from "services";
import { UserAccess, UserHasAccess } from 'components/user-access';
import { PageRestricted } from 'pages/errors/page-restricted';
import { IntegrationSettings } from 'pages/account/edit/integrations/credential-providers/shared';
import { ShowHide } from 'components/layout';

export function AccountIntegrationsEdit() {

    const { id } = useParams();

    const [originalSettings, setOriginalSettings] = useState({});
    const [settingsValid, setSettingsValid] = useState(false);
    const [loading, setLoading] = useState(true);
    const [saving, setSaving] = useState(false);
    const [integration, setIntegration] = useState({});
    const isIntegrationDirty = useDirtyData(originalSettings, integration);
    const [providers, setProviders] = useState([]);
    const [invalidIntegration, setInvalidIntegration] = useState(false);

    const requiredAction = "Integrations:Manage";
    const canAccessPage = UserHasAccess(requiredAction);

    useEffect(() => {
        if (loading && canAccessPage) {

            axios.all([
                AccountService.getIntegration(id),
                AccountService.getAvailableIntegrations()
            ]).then(axios.spread(function (integrationResponse, providersResponse) {
                setLoading(false);

                integrationResponse.services = integrationResponse.services.map(function (s) {
                    return s.id;
                });

                setIntegration(integrationResponse);
                setOriginalSettings(integrationResponse);
                setProviders(providersResponse.filter(function (p) {
                    return p.providerId === integrationResponse.providerId;
                }));

            }), function (error) {
                const noAccessCode = 403;

                if (error.status === noAccessCode) {
                    setInvalidIntegration(true);
                } else {
                    NotificationToaster.show(Intent.DANGER, "Unable to get the integration, please try again.");
                }
            });

        }

    }, [loading, id]);

    function updateIntegration(updatedIntegration) {
        setIntegration(updatedIntegration);
    }

    function onValidationUpdate(isValid) {
        setSettingsValid(isValid);
    }

    function saveIntegration() {
        setSaving(true);

        AccountService.updateIntegration(id, integration).then(
            () => {
                setSaving(false);
                NotificationToaster.show(Intent.SUCCESS, "Integration updated successfully");
                setOriginalSettings(integration);
            },
            (error) => {
                setSaving(false);
                NotificationToaster.show(Intent.DANGER, error);
            }
        );
    }

    return (

        <UserAccess perform={requiredAction}
            yes={() => (
                <ShowHide
                    evaluator={invalidIntegration}
                    show={(
                        <div>
                            <NotificationInline
                                allowClose={false}
                                show
                                text="You do not have permission to view this integration"
                                intent="danger">
                            </NotificationInline>  
                            <div>
                                <LinkButton intent="primary" text="Back to integration management" href="/account/integrations" id="return-integration-listing" />
                            </div>
                        </div>
                    )}
                    hide={(
                        <div>
                            <div>
                                <h2>Edit Integration</h2>
                                <IntegrationSettings editing settings={integration} loading={loading} saving={saving} providers={providers} onUpdate={updateIntegration} onValidationUpdate={onValidationUpdate} />
                                <ButtonSave onClick={saveIntegration} disabled={saving} simpleDisabled={!settingsValid} />
                                <AlertUnsavedData
                                    isDirty={isIntegrationDirty}>
                                </AlertUnsavedData>
                            </div>
                        </div>
                    )}
                />

            )}
            no={() => (
                <PageRestricted />
            )}
        />


    );
}
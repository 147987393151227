import React, { useState, useEffect } from 'react';
import axios from 'axios';

import { Intent, Icon } from '@blueprintjs/core';
import { NotificationToaster, AlertUnsavedData } from "components/notifications";
import { LinkButton } from "components/buttons";
import { ShowHide } from "components/layout";
import { Wizard } from "components/wizard";
import { useDirtyData } from 'hooks/useDirtyData';
import { UserService } from "services";
import { UserAccess } from 'components/user-access';
import { PageRestricted } from 'pages/errors/page-restricted';
import { UserLocationSettings } from 'pages/users/edit/shared';
import { DateToLocal } from 'components/dates';
import { ApiApplicationBasicSettings } from 'pages/account/edit/integrations/api-applications/shared';

export function ApiApplicationCreate(props) {

    const appModel = {
        "applicationName": "",
        "role": {}
    };

    const [originalSettings, setOriginalSettings] = useState(appModel);
    const [basicSettingsValid, setBasicSettingsValid] = useState(false);
    const [loading, setLoading] = useState(true);
    const [saving, setSaving] = useState(false);
    const [appCreated, setAppCreated] = useState(false);
    const [application, setApplication] = useState(appModel);
    const [userLocationPermissions, setUserLocationPermissions] = useState([]);
    const [wizardStep, setWizardStep] = useState(0);
    const [wizardValid, setWizardValid] = useState(false);
    const isUserDirty = useDirtyData(originalSettings, application);
    const [secretSettings, setSecretSettings] = useState({});

    const requiredAction = "Integrations:Manage";
    const basicSettingsStep = 0;
    const permissionsStep = 1;

    useEffect(initialLoad, []);
    function initialLoad() {
        setLoading(false);
    }

    function updateApplication(updatedApp) {
        setApplication(updatedApp);
    }

    function updateUserLocationPermissions(permissions) {
        setUserLocationPermissions(permissions);
    }

    function saveApplication() {
        setSaving(true);

        UserService.createApplication(application).then(
            (secretSettingsResponse) => {

                setSecretSettings(secretSettingsResponse);

                var userUpdates = [UserService.updateLocationPermissions(secretSettingsResponse.userId, userLocationPermissions)];

                axios.all(userUpdates).then(function () {
                    setSaving(false);
                    setAppCreated(true);
                }, function () {
                    setSaving(false);
                    setAppCreated(true);
                    NotificationToaster.show(Intent.WARNING, "The application has been created, but some of the settings could not be applied. Please edit the application to complete setup.");
                });

                setOriginalSettings(application);
            },
            (error) => {
                setSaving(false);
                NotificationToaster.show(Intent.DANGER, error);
            }
        );
    }

    function onBasicValidationUpdate(isValid) {
        setBasicSettingsValid(isValid);
    }

    function onWizardChange(step) {
        setWizardStep(step);
    }

    useEffect(setIfWizardValid, [wizardStep, basicSettingsValid]);

    function setIfWizardValid() {
        var valid = (wizardStep === basicSettingsStep && basicSettingsValid) || wizardStep === permissionsStep;
        setWizardValid(valid);
    }

    return (

        <UserAccess perform={requiredAction}
            yes={() => (

                <div className="row">
                    
                    <ShowHide
                        evaluator={!appCreated}
                        show={(
                            <div>
                                <Wizard onStepChange={onWizardChange} onFinish={saveApplication} canProceed={wizardValid} disabled={saving}>

                                    <div>
                                        <h1 id="header-new-user">Application details</h1>
                                        <ApiApplicationBasicSettings application={application} loading={loading} editing={false} onSettingsUpdate={updateApplication} saving={saving} onValidationUpdate={onBasicValidationUpdate} />
                                    </div>
                                    <div>
                                        <h1 id="header-new-user">What locations should the application have access to?</h1>
                                        <UserLocationSettings permissions={userLocationPermissions} onPermissionsUpdate={updateUserLocationPermissions} />
                                    </div>

                                </Wizard>
                                <AlertUnsavedData
                                    isDirty={isUserDirty}>
                                </AlertUnsavedData>

                            </div>
                        )}
                        hide={(
                            <div>
                                <div className="spacer-bottom" id="create-success-icon">
                                    <Icon icon="tick-circle" iconSize={40} intent={Intent.SUCCESS} />
                                </div>
                                <h2 id="create-success-name">Application created successfully</h2>
                                <div className="spacer-bottom">
                                    <div className="spacer-bottom">
                                        <p>Please use the following details to request a token and authenticate against the Vision public API.</p>
                                        <p> <strong>Client secrets cannot be viewed, except for immediately after creation. Be sure to save the secret before leaving the page.</strong></p>
                                    </div>
                                    <div className="form-field">
                                        <h4>Client ID</h4>
                                        <p>{secretSettings.clientId}</p>
                                    </div>
                                    <div className="form-field">
                                        <h4>Client Secret</h4>
                                        <p>{secretSettings.secret}</p>
                                    </div>
                                    <div className="form-field">
                                        <h4>Secret Expires</h4>
                                        <p><DateToLocal>{secretSettings.secretExpiry}</DateToLocal></p>
                                    </div>
                                </div>
                                <div className="button-row" id="create-success-buttons">
                                    <LinkButton intent="primary" text="Back to listing" id="listing-applications" href="/account/integrations/api-applications" />
                                </div>
                            </div>
                        )}
                    ></ShowHide>

                </div>

            )}
            no={() => (
                <PageRestricted />
            )}
        />


    );
}
import React from 'react';
import { Redirect, Route, Switch } from "react-router-dom";

import { PageRestricted } from 'pages/errors/page-restricted';
import { UserAccess } from 'components/user-access';
import { HorizontalLinkGroup } from 'components/navigation';
import { AccountIntegrationsListing } from 'pages/account/edit/integrations/credential-providers/listing';
import { AccountIntegrationsCreate } from 'pages/account/edit/integrations/credential-providers/create';
import { AccountIntegrationsEdit } from 'pages/account/edit/integrations/credential-providers/edit';
import { AccessProviders } from 'pages/account/edit/integrations/access-providers';
import { ApiApplicationsListing } from 'pages/account/edit/integrations/api-applications/listing';
import { ApiApplicationCreate } from 'pages/account/edit/integrations/api-applications/create';
import { ApiApplicationEdit } from 'pages/account/edit/integrations/api-applications/edit';


export function Integrations() {

    const requiredAction = "Integrations:Manage";

    const routes = [
        { location: "/account/integrations/access", title: "Access Providers" },
        { location: "/account/integrations/credentials", title: "Credential Providers" },
        { location: "/account/integrations/api-applications", title: "API Applications" }
    ];

    return (
        <UserAccess perform={requiredAction}
            yes={() => (
                <div className="row">

                    <h2>Integrations</h2>
                    <div className="intro-text">
                        <p>From here you manage global integrations to import and retrieve data into your account.</p>
                       
                    </div>

                    <div className="spacer-bottom">
                        <HorizontalLinkGroup links={routes} exactRouteMatch={false}></HorizontalLinkGroup>

                    </div>

                    <Switch>
                        <Route path="/account/integrations/api-applications/:id(\d+)">
                            <ApiApplicationEdit />
                        </Route>
                        <Route exact path="/account/integrations/api-applications/create">
                            <ApiApplicationCreate />
                        </Route>
                        <Route exact path="/account/integrations/api-applications">
                            <ApiApplicationsListing />
                        </Route>
                        <Route path="/account/integrations/credentials/create/:id">
                                <AccountIntegrationsCreate />
                        </Route>
                        <Route exact path="/account/integrations/credentials/create">
                            <AccountIntegrationsCreate />
                        </Route>
                        <Route path="/account/integrations/credentials/:id(\d+)">
                            <AccountIntegrationsEdit />
                        </Route>
                        <Route exact path="/account/integrations/credentials">
                            <AccountIntegrationsListing />
                        </Route>
                        <Route path="/account/integrations/access">
                            <AccessProviders />
                        </Route>
                        <Redirect exact from="/account/integrations" to="/account/integrations/access" />

                    </Switch>


                </div>
            )}
            no={() => (
                <div className="row">
                    <PageRestricted />
                </div>
            )}
        />
    );

}
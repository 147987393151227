const initialState = {
    dirty: false,
    attemptingRedirect: false,
    allowRedirect: false,
    task: null
}

const DirtyDataReducer = (state = initialState, action) => {
    switch (action.type) {
        case "RESET_DIRTY_FLAG":
            return {
                ...state,
                dirty: false
            };
        case "SET_DIRTY_FLAG":
            return {
                ...state,
                dirty: action.payload
            };
        case "BEGIN_REDIRECT":
            return {
                ...state,
                attemptingRedirect: true,
                task: action.payload
            }
        case "ALLOW_REDIRECT":
            return {
                ...state,
                attemptingRedirect: true,
                allowRedirect: true
            }
        case "CANCEL_REDIRECT":
            return {
                ...state,
                attemptingRedirect: false,
                allowRedirect: false,
                task: null
            }
        default:
            return state;
    }
}

export default DirtyDataReducer;
import React, { Fragment, useEffect, useState } from "react";
import { PageRestricted } from "pages/errors/page-restricted";
import { UserAccess } from "components/user-access";
import { JobService } from "services";
import { Modal, NotificationToaster } from "components/notifications";
import { Intent } from "@blueprintjs/core";
import { ShowHide } from "components/layout";
import classNames from "classnames";
import { Button, LinkButton } from "components/buttons";
import { JobReportSection, JobReportSignaturesSection } from "./shared";
import { useQuery } from "hooks/useQuery";
import { useParams } from "react-router-dom";
import { useSelector } from "react-redux";

export function JobReport() {
    const headerType = 'header';

    const defaultSection = {
        sectionType: 'Body',
        sectionTitle: '',
        questionGroups: []
    };

    const [report, setReport] = useState({
        reportId: 0,
        productName: '',
        sections: [defaultSection, defaultSection, defaultSection], // Have three sections in loading state
        employeeId: null,
        signoffResponse: null,
        reportFileResponses: []
    });
    const [loading, setLoading] = useState(true);
    const [signOffLoading, setSignOffLoading] = useState(false);
    const [signOffDialogOpen, setSignOffDialogOpen] = useState(false);
    const [signaturesExpanded, setSignaturesExpanded] = useState(false);

    const userDisplayName = useSelector(state => state.loggedInUser.displayName);

    const query = useQuery();

    const alertRedirectionKey = query.get('alertRedirectionKey');

    const { id } = useParams();

    useEffect(() => {
        loadReport();
    }, [id]);

    function loadReport() {
        if (id) {
            setLoading(true);
            JobService.getReport(id)
                .then((data) => {
                    setReport(formatReport(data));
                    setLoading(false);
                },
                    () => {
                        NotificationToaster.show(Intent.DANGER, "Could not load the report. Please try refreshing the page.");
                    }
                );
        }
    }

    function formatReport(data) {
        let currentSectionNumber = 1;

        // Format the sections of the report
        for (let section of data.sections) {
            if (!isHeaderSection(section)) {
                section.sectionNumber = currentSectionNumber;
                ++currentSectionNumber;
            }
        }

        if (data.signoffResponse?.signoffPerformed) {
            addSignOffQuestionsToReport(data);
        }

        return data;
    }

    function addSignOffQuestionsToReport(signedOffReport) {
        // If the report has been signed off, push the sign off response
        // into the header section.
        const signOffUser = signedOffReport.signoffResponse.signoffForename !== "" && signedOffReport.signoffResponse.signoffSurname ?
            `${signedOffReport.signoffResponse.signoffForename} ${signedOffReport.signoffResponse.signoffSurname}` :
            signedOffReport.signoffResponse.signoffEmail;

        const signOffQuestions = [
            {
                questionId: -1,
                questionTitle: 'Signed off by',
                answerType: 'String',
                responses: [signOffUser]
            },
            {
                questionId: -2,
                questionTitle: 'Date signed off',
                answerType: 'Date',
                responses: [signedOffReport.signoffResponse.signoffDate]
            }
        ];


        let headerSection = signedOffReport.sections
            ?.find(section => isHeaderSection(section));

        // If a header section does not exist, then create a new one.
        if (!headerSection) {
            headerSection = { ...defaultSection };
            headerSection.sectionType = 'Header';

            signedOffReport.sections.unshift(headerSection);
        }

        const signOffQuestionGroup = {
            questionGroupId: -1,
            questions: [...signOffQuestions]
        };

        headerSection.questionGroups.push(signOffQuestionGroup);
    }

    function expandOrHideAllSections(expanded) {
        const sections = report.sections.map(section => {
            return {
                ...section,
                isExpanded: expanded
            };
        });

        setReport({
            ...report,
            sections: sections
        });

        setSignaturesExpanded(expanded);
    }

    function onCollapsableClick(sectionIndex) {
        const reportClone = { ...report };

        const section = reportClone.sections[sectionIndex];
        section.isExpanded = !section.isExpanded;

        setReport(reportClone);
    }

    function onSignaturesCollapsableClick() {
        setSignaturesExpanded(!signaturesExpanded);
    }

    function isHeaderSection(section) {
        return section.sectionType.toLowerCase() === headerType;
    }

    function signOff() {
        setSignOffLoading(true);

        JobService.signOffEmployeeAssessment(report.reportId)
            .then(() => {
                NotificationToaster.show(Intent.SUCCESS, "You have successfully signed off the assessment.");
                loadReport();
                setSignOffDialogOpen(false);
            }, () => {
                NotificationToaster.show(Intent.DANGER, "Assessment could not be signed off. Please try again.");
                setSignOffDialogOpen(false);
            });
    }

    function openSignOff() {
        setSignOffDialogOpen(true);
    }

    function onSignOffDialogClosed() {
        setSignOffDialogOpen(false);
        setSignOffLoading(false);
    }

    function getSignatureImages() {
        return report.reportFileResponses?.filter(x => x.fileType === 'SignatureImage');
    }

    return (
        <UserAccess perform={["EmployeeAssessment:View"]}
            yes={() => (
                <Fragment>
                    <div className="row">

                        <h1 className={classNames({ 'bp3-skeleton': loading })}>{report.productName ? report.productName : 'Report'}</h1>

                        <div id="job-report-button-row" className="spacer-bottom button-row button-row-stacked-mobile button-row-placeholder">
                            <UserAccess perform={["Employees:View"]}
                                yes={() => (
                                    <Fragment>
                                        <ShowHide
                                            evaluator={!!report.employeeId}
                                            show={
                                                <LinkButton
                                                    intent="primary"
                                                    text="Driver profile"
                                                    id="link-job-report-employee"
                                                    loading={loading}
                                                    href={`${window.env.VISION_URL}/#/Drivers/RedirectToProfile?employeeId=${report.employeeId}&tab=tab-employee-assessments`}
                                                    externalLink={true} />
                                            }
                                        />
                                        <ShowHide
                                            evaluator={!!alertRedirectionKey}
                                            show={
                                                <LinkButton
                                                    intent="primary"
                                                    text="Back to alert"
                                                    id="link-job-report-alert"
                                                    loading={loading}
                                                    href={`${window.env.VISION_URL}/#/Alerts/${alertRedirectionKey}`}
                                                    externalLink={true} />
                                                }
                                        />
                                    </Fragment>
                                )}
                            />
                            <UserAccess perform={["EmployeeAssessment:SignOff"]}
                                yes={() => (
                                    <ShowHide
                                        evaluator={!!report?.signoffResponse?.signoffRequired && !report.signoffResponse?.signoffPerformed}
                                        show={
                                            <Button
                                                intent="primary"
                                                text="Sign off"
                                                id="button-job-report-sign-off"
                                                loading={loading}
                                                onClick={openSignOff}
                                            />
                                        }
                                    />
                                )}
                            />
                            <div className="pull-right pull-none-mobile">
                                <div className="button-row button-row-stacked-mobile">
                                    <Button
                                        text="Show all sections"
                                        id="button-job-report-show-all"
                                        loading={loading}
                                        onClick={() => expandOrHideAllSections(true)}
                                    />
                                    <Button
                                        text="Hide all sections"
                                        id="button-job-report-hide-all"
                                        loading={loading}
                                        onClick={() => expandOrHideAllSections(false)}
                                    />
                                </div>
                            </div>
                        </div>

                        {report.sections.map((section, index) => <JobReportSection
                            key={section.sectionId}
                            section={section}
                            loading={loading}
                            onCollapsableClick={() => onCollapsableClick(index)}
                            isHeaderSection={isHeaderSection(section)}
                        />)}

                        <ShowHide
                            evaluator={getSignatureImages().length > 0}
                            show={
                                <JobReportSignaturesSection
                                    signatures={getSignatureImages()}
                                    isExpanded={signaturesExpanded}
                                    onCollapsableClick={onSignaturesCollapsableClick}
                                    sectionNumber={report.sections.filter(x => x.sectionType !== 'Header').length + 1}
                                />
                            }
                        />
                    </div>

                    <Modal
                        isOpen={signOffDialogOpen}
                        title="Sign off assessment"
                        onClose={onSignOffDialogClosed}
                    >
                        <div className="bp3-dialog-body">
                            <p>{`By clicking approve I, ${userDisplayName}, am approving that the outcome of the employee assessment is satisfactory.`}</p>
                        </div>

                        <div className="bp3-dialog-footer">
                            <div className="bp3-dialog-footer-actions">
                                <Button id="button-job-report-approve" text="Approve" intent="primary" disabled={signOffLoading} onClick={signOff}></Button>
                                <Button id="button-job-report-cancel-approve" text="Cancel" disabled={signOffLoading} onClick={onSignOffDialogClosed}></Button>
                            </div>
                        </div>
                    </Modal>
                </Fragment>
            )}
            no={() => (
                <PageRestricted />
            )}
        />
    );
}

import React, { Fragment, useEffect, useState } from "react";
import axios from 'axios';
import PropTypes from 'prop-types';
import { FormSelect, FormNumericInput, FormDateRangeInput } from "components/form-fields";
import { useValidation } from "hooks/useValidation";
import { ShowHide } from "components/layout";
import { AlertUnsavedData, NotificationToaster, NotificationInline, Modal } from "components/notifications";
import { JobService, AccountService } from "services";
import { Intent } from '@blueprintjs/core';
import { ButtonSave, Button, LinkButton } from "components/buttons";
import { useDirtyData } from "hooks/useDirtyData";
import { useParams, useHistory } from 'react-router-dom';
import { Breadcrumbs } from "components/navigation";
import { UserAccess, UserHasAccess } from "components/user-access";
import { PageRestricted } from "pages/errors/page-restricted";
import { FailVisit } from 'pages/job/edit/shared';
import moment from "moment";
import { HelperFunctions } from 'helpers';
import { OcrPrevisitLocationSettings } from '.'

var _ = require('underscore');


export function OcrPrevisit(props) {

    const { jobId } = useParams();
    const history = useHistory();
    const defaultMonthsBack = -3;

    const defaultOcrPrevisit = {
        locations: [],
        jobId: jobId,
        id: null,
        startDate: moment().add(defaultMonthsBack, 'M').subtract(1, 'd').format("YYYY-MM-DD"),
        endDate: moment().subtract(1, 'd').format("YYYY-MM-DD"),
        agencyDrivers: null,
        agencyDriversInComplianceResults: null,
        leadIn: 0,
        leadOut: 0,
        awayDriversInDebrief: null,
        awayDriversInDcUpload: null,
        awayDriversInDownloadFrequency: null,
        hireVehicles: null,
        mileage: 0,
        vorInVehicleUpload: null,
        vorInDownloadFrequency: null,
        usePassFailCriteria: null,
        keepComments: null,
        agencyDriversUploadAtStartAndEndOfShift: null,
        jobState: '',
        isVisionDebriefUsed: null,
        vehicleComplianceDays: 0,
        driverComplianceDays: 0,
        includeAgencyForMissingDays: null,
        usePerformanceRatings: null
    };

    const defaultFailAppointmentReason = {
        failureReason: "",
        failureAdditionalComments: ""
    };

    const [ocrPrevisit, setOcrPrevisit] = useState(defaultOcrPrevisit);
    const [originalOcrPrevisit, setOriginalOcrPrevisit] = useState(defaultOcrPrevisit)
    const [saving, setSaving] = useState(false);
    const [postSave, setPostSave] = useState(false);
    const [isCreating, setIsCreating] = useState(true);
    const [invalidAccount, setInvalidAccount] = useState(false);

    const [jobState, setJobState] = useState('');
    const [jobNote, setJobNote] = useState('');
    const [isReady, setIsReady] = useState(false);

    const [isFailed, setIsFailed] = useState(false);
    const [showFailJobModal, setShowFailJobModal] = useState(false);
    const [failAppointmentReason, setFailAppointmentReason] = useState(defaultFailAppointmentReason);
    const [showSaveModal, setShowSaveModal] = useState(false);

    const [isSavingModalValid, savingModalErrors, saveModalValidate] = useValidation();

    const [locationHierarchies, setLocationHierarchies] = useState({
        hierarchyGroups:[],
        locations: []
    });
    const [originalValues, setOriginalValues] = useState([]);

    const isDataDirty = useDirtyData(originalOcrPrevisit, ocrPrevisit);
    const isLocationsDirty = useDirtyData(originalValues, locationHierarchies);

    const maxMileage = 50;
    const maxLead = 60;
    const recheckStatusInterval = 20000;
    const maxVehicleComplianceDays = 90;
    const maxDriverComplianceDays = 28;

    const readyJobStates = [
        'ready',
        'in progress'
    ]

    const [isValid, errors, validate] = useValidation();
    const [isLocationHierarchyValid, locationHierarchyerrors, locationHierarchyValidate] = useValidation();
    const [loading, setLoading] = useState(true);

    const options = [{ "id": true, "name": 'Yes' }, { "id": false, "name": 'No' }];
    const driverLocationOptions = [{ "id": false, "name": 'Driver' }, { "id": true, "name": 'Location' }];
    const requiredAction = "Internal:OCR";
    const canAccessPage = UserHasAccess(requiredAction);

    useEffect(() => {
        const timer = setInterval(() => {
            if (ocrPrevisit && ocrPrevisit.ocrPrevisitId && !isDataDirty && !isLocationsDirty &&  !isFinished()) {
                getJobState();
            }
        }, recheckStatusInterval);
        // clearing interval
        return () => clearInterval(timer);

        function getJobState() {
            JobService.getOcrPrevisit(jobId).then(js => {
                if (js && js.jobState) {
                    setJobState(js.jobState);
                }
                //We have got he job state post the save to clear PostSave flag
                setPostSave(false);
            });
        }
    });

    useEffect(initialLoad, []);

    function initialLoad() {

        if (!canAccessPage) {
            return;
        }

        axios.all([JobService.getOcrPrevisit(jobId),
            AccountService.getTachoSettings(), JobService.getJob(jobId)
        ])
            .then(axios.spread(function (previsit, accountSetting, job) {
                setPostSave(false);

                var perm = {
                    "hierarchyGroups": previsit.hierarchyGroups.map(function (t) { return t.id }),
                    "locations": previsit.locations.map(function (t) { return t.id })
                };

                setLocationHierarchies(perm);
                setOriginalValues(HelperFunctions.deepClone(perm));

                if (job) {
                    setJobNote(job.jobNote);
                }
                if (previsit && previsit.ocrPrevisitId !== 0) {
                    setOcrPrevisit(previsit);
                    setOriginalOcrPrevisit(previsit);
                    setIsCreating(false);
                    setJobState(previsit.jobState);
                    setLoading(false);
                } else {
                    if (accountSetting) {
                        const updatedSettings = {
                            ...ocrPrevisit,
                            vehicleComplianceDays: accountSetting.vehicleComplianceDays,
                            driverComplianceDays: accountSetting.driverComplianceDays
                        }

                        setOcrPrevisit(updatedSettings);
                    }
                    setIsCreating(true);
                    setLoading(false);
                }
            }), (error) => {
                const noAccessCode = 403;

                if (error && error.status === noAccessCode) {
                    setInvalidAccount(true);
                }
                else {
                    NotificationToaster.show(Intent.DANGER, "Could not load settings. Please refresh the page.");
                }
            });
    }

    useEffect(() => {
        const formattedJobState = jobState?.toLowerCase();

        setIsReady(readyJobStates.includes(formattedJobState));
        setIsFailed(formattedJobState === "failed appointment");
    }, [jobState]);

    function saveSettings(reopenReview) {
        setSaving(true);

        const updatedSettings = {
            ...ocrPrevisit,
            hierarchyGroups: locationHierarchies.hierarchyGroups.map(function (t) { return { id: t } }),
            locations: locationHierarchies.locations.map(function (t) { return { id: t } }),
            jobState: reopenReview === true ? "Ready" : jobState
        }

        setOcrPrevisit(updatedSettings);
        setJobState(updatedSettings.jobState);

        save(updatedSettings)
            .then(
                () => {
                    setSaving(false);
                    setOriginalOcrPrevisit(updatedSettings);
                    setOriginalValues(HelperFunctions.deepClone(locationHierarchies));
                    setShowSaveModal(false);

                    if (reopenReview === true) {
                        setJobState('Ready');
                    } else {
                        setPostSave(true);
                    }

                    NotificationToaster.show(Intent.SUCCESS, "Settings updated successfully.");
                    if (isCreating) {
                        initialLoad();
                    }
                },
                () => {
                    setSaving(false);
                    NotificationToaster.show(Intent.DANGER, "Could not save the settings. Please try again.");
                }
            );
    }

    function save(updatedSettings) {
        if (isCreating) {
            return JobService.createPrevisit(updatedSettings);
        }
        else {
            return JobService.savePrevisit(updatedSettings);
        }
    }

    function onReset() {
        setIsCreating(false);
        setLoading(true);
        initialLoad();
    }

    function onFailJob() {
        setShowFailJobModal(true);
    }

    function onFailVisitSave() {
        setOriginalOcrPrevisit(ocrPrevisit);
        history.push(`/reviews/compliance-review/${jobId}`);
    }

    function onFailVisitClose() {
        setShowFailJobModal(false);
        setFailAppointmentReason(defaultFailAppointmentReason);
    }

    function onDateRangeChange(item) {
        const updatedSettings = {
            ...ocrPrevisit,
            startDate: (item.startDate.date ? item.startDate.date.format("YYYY-MM-DD") : ""),
            endDate: (item.endDate.date ? item.endDate.date.format("YYYY-MM-DD") : "")
        }
        setOcrPrevisit(updatedSettings);
    }

    function onLocationsUpdate(newLocations) {
        setLocationHierarchies(newLocations);
    }

    function onReopenReview() {
        //If a job goes back from Done to Reopen then there is code in stp_Merge_Ocr_Previsit to ensure this just updates the job state and nothing else
        saveSettings(true);
    }

    function onSave() {
        if (isUpdate()) {
            setShowSaveModal(true);
        } else {
            saveSettings();
        }
    }

    function isUpdate() {
        return ocrPrevisit.ocrPrevisitId !== undefined;
    }

    function noLocationOrHierarchy() {
        return (!locationHierarchies.locations || locationHierarchies.locations.length === 0) && (!locationHierarchies.hierarchyGroups || locationHierarchies.hierarchyGroups.length === 0);
    }

    function isFinished() {
        return jobState === 'Done' || jobState === 'Done (Incomplete)' || jobState === 'Cancelled';
    }
    function onAgencyDriversChange(item) {
        //default agencyDriversInComplianceResults to yes if agency drivers selected. And set to No if no agency drivers.
        const updatedSettings = {
            ...ocrPrevisit,
            agencyDrivers: item.id,
            agencyDriversInComplianceResults: item.id,
            includeAgencyForMissingDays: item.id,
            agencyDriversUploadAtStartAndEndOfShift: item.id
        }

        setOcrPrevisit(updatedSettings);
    }
    function onAgencyDriversUploadAtStartAndEndOfShiftChange(item) {
        const updatedSettings = {
            ...ocrPrevisit,
            agencyDriversUploadAtStartAndEndOfShift: item.id
        }

        setOcrPrevisit(updatedSettings);
    }
    function onAgencyDriversInComplianceResultsChange(item) {
        const updatedSettings = {
            ...ocrPrevisit,
            agencyDriversInComplianceResults: item.id,
            includeAgencyForMissingDays: item.id,
        }

        setOcrPrevisit(updatedSettings);
    }

    function onAreDomesticDriversRequiredToRecordRestChange(item) {
        const updatedSettings = {
            ...ocrPrevisit,
            areDomesticDriversRequiredToRecordRest: item.id
        };

        setOcrPrevisit(updatedSettings);
    }

    function onLeadInChange(item) {
        const updatedSettings = {
            ...ocrPrevisit,
            leadIn: item
        }

        setOcrPrevisit(updatedSettings);
    }

    function onLeadOutChange(item) {
        const updatedSettings = {
            ...ocrPrevisit,
            leadOut: item
        }

        setOcrPrevisit(updatedSettings);
    }

    function onAwayDriversInDebriefChange(item) {
        const updatedSettings = {
            ...ocrPrevisit,
            awayDriversInDebrief: item.id
        }

        setOcrPrevisit(updatedSettings);
    }

    function onAwayDriversInDcUploadChange(item) {
        const updatedSettings = {
            ...ocrPrevisit,
            awayDriversInDcUpload: item.id
        }

        setOcrPrevisit(updatedSettings);
    }
    function onAwayDriversInDownloadFrequencyChange(item) {
        const updatedSettings = {
            ...ocrPrevisit,
            awayDriversInDownloadFrequency: item.id
        }

        setOcrPrevisit(updatedSettings);
    }
    function onHireVehiclesChange(item) {
        const updatedSettings = {
            ...ocrPrevisit,
            hireVehicles: item.id
        }

        setOcrPrevisit(updatedSettings);
    }
    function onMileageChange(item) {
        const updatedSettings = {
            ...ocrPrevisit,
            mileage: item
        }

        setOcrPrevisit(updatedSettings);
    }
    function onVorInDownloadFrequencyChange(item) {
        const updatedSettings = {
            ...ocrPrevisit,
            vorInDownloadFrequency: item.id
        }

        setOcrPrevisit(updatedSettings);
    }
    function onVorInVehicleUploadChange(item) {
        const updatedSettings = {
            ...ocrPrevisit,
            vorInVehicleUpload: item.id
        }

        setOcrPrevisit(updatedSettings);
    }
    function onUsePassFailCriteriaChange(item) {
        const updatedSettings = {
            ...ocrPrevisit,
            usePassFailCriteria: item.id
        }

        setOcrPrevisit(updatedSettings);
    }

    function onUsePerformanceRatingsChange(item) {
        const updatedSettings = {
            ...ocrPrevisit,
            usePerformanceRatings: item.id
        };

        setOcrPrevisit(updatedSettings);
    }

    function onIsVisionDebriefUsedChange(item) {
        const updatedSettings = {
            ...ocrPrevisit,
            isVisionDebriefUsed: item.id
        }
        setOcrPrevisit(updatedSettings);
    }

    function onKeepCommentsChange(item) {
        const updatedSettings = {
            ...ocrPrevisit,
            keepComments: item.id
        }

        setOcrPrevisit(updatedSettings);
    }

    function onLocationReportTypeChange(item) {
        const updatedSettings = {
            ...ocrPrevisit,
            locationReportType: item.id
        }

        setOcrPrevisit(updatedSettings);
    }
    
    function onIncludeAgencyForMissingDaysChange(item) {
        const updatedSettings = {
            ...ocrPrevisit,
            includeAgencyForMissingDays: item.id
        }

        setOcrPrevisit(updatedSettings);
    }

    function onVehicleComplianceDaysChange(item) {
        const updatedSettings = {
            ...ocrPrevisit,
            vehicleComplianceDays: item
        }

        setOcrPrevisit(updatedSettings);
    }

    function onDriverComplianceDaysChange(item) {
        const updatedSettings = {
            ...ocrPrevisit,
            driverComplianceDays: item
        }

        setOcrPrevisit(updatedSettings);
    }

    useEffect(updateValidationRules, [ocrPrevisit]);

    function updateValidationRules() {
        const rules = [
            { fieldName: "agencyDrivers", required: true },
            { fieldName: "agencyDriversInComplianceResults", required: true },
            { fieldName: "leadIn", required: true, minValue: 0, maxValue: maxLead },
            { fieldName: "leadOut", required: true, minValue: 0, maxValue: maxLead },
            { fieldName: "startDate", required: true },
            { fieldName: "endDate", required: true },
            { fieldName: "awayDriversInDebrief", required: true },
            { fieldName: "awayDriversInDcUpload", required: true },
            { fieldName: "awayDriversInDownloadFrequency", required: true },
            { fieldName: "mileage", required: true, minValue: 0, maxValue: maxMileage },
            { fieldName: "vorInDownloadFrequency", required: true },
            { fieldName: "vorInVehicleUpload", required: true },
            { fieldName: "usePassFailCriteria", required: true },
            { fieldName: "agencyDriversUploadAtStartAndEndOfShift", required: true },
            { fieldName: "isVisionDebriefUsed", required: true },
            { fieldName: "hireVehicles", required: true },
            { fieldName: "vehicleComplianceDays", required: true, minValue: 0, maxValue: maxVehicleComplianceDays },
            { fieldName: "driverComplianceDays", required: true, minValue: 0, maxValue: maxDriverComplianceDays },
            { fieldName: "includeAgencyForMissingDays", required: true },
            { fieldName: "locationReportType", required: true },
            { fieldName: "areDomesticDriversRequiredToRecordRest", required: true },
            { fieldName: "usePerformanceRatings", required: true }
        ];

        const externalRules = [];

        validate(rules, ocrPrevisit, externalRules);
    }

    useEffect(validateSaveModal, [ocrPrevisit]);

    function validateSaveModal() {
        const rules = [
            { fieldName: "keepComments", required: true }
        ];

        saveModalValidate(rules, ocrPrevisit);
    }

    useEffect(validateLocationHierarchy, [locationHierarchies]);

    function validateLocationHierarchy() {
        const rules = [];

        if (noLocationOrHierarchy()) {
            var externalRule = {
                fieldName: "locations",
                valid: false,
                errorMessage: 'A location or area must be selected'
            };
            var externalRules = [externalRule];
        }
        locationHierarchyValidate(rules, locationHierarchies, externalRules);
    }

    return (

        <UserAccess perform={[requiredAction]}
            yes={() => (
                <div className="row">
                    <Breadcrumbs items={props.breadcrumbs} />
                    <ShowHide
                        evaluator={invalidAccount}
                        show={(
                            <NotificationInline
                                allowClose={false}
                                show
                                text="You do not have permission to view this compliance review."
                                intent="danger"
                            />
                        )}
                        hide={(
                            <ShowHide
                                evaluator={!isFailed}
                                show={(
                                    <Fragment>
                                        <h1 id="header-ocr-previsit-create-amber">CR Pre-visit Settings</h1>

                                        <ShowHide
                                            evaluator={jobNote !== "" && jobNote !== null}
                                            show={(
                                                <NotificationInline show={true} allowClose={false} intent="info" text={"Notes: " + jobNote} />
                                            )}
                                        />
                                        <FormDateRangeInput
                                            onChange={onDateRangeChange}
                                            startDate={ocrPrevisit.startDate}
                                            endDate={ocrPrevisit.endDate}
                                            loading={loading}
                                            disabled={saving || isFinished()}
                                            headingText="Date range:"
                                            dangerHelperText={errors.startDate || errors.endDate}
                                            maxDate={moment().subtract(1, 'd').toDate()}
                                        ></FormDateRangeInput>
                                        <FormSelect
                                            disabled={saving || isFinished()}
                                            items={driverLocationOptions}
                                            onItemSelect={onLocationReportTypeChange}
                                            selectedValue={ocrPrevisit.locationReportType}
                                            loading={loading}
                                            headingText='Would you like the results to be shown by driver or by location:'
                                            dangerHelperText={errors.locationReportType}
                                        ></FormSelect>
                                        <OcrPrevisitLocationSettings
                                            disabled={saving || isFinished()}
                                            locationHierarchies={locationHierarchies}
                                            loading={loading}
                                            saving={saving}
                                            onUpdate={onLocationsUpdate}
                                            warning={locationHierarchyerrors.name} />
                                        <FormSelect
                                            disabled={saving || isFinished()}
                                            items={options}
                                            onItemSelect={onAgencyDriversChange}
                                            selectedValue={ocrPrevisit.agencyDrivers}
                                            loading={loading}
                                            headingText='Are agency drivers used:'
                                            dangerHelperText={errors.agencyDrivers}
                                        ></FormSelect>
                                        <ShowHide
                                            evaluator={(ocrPrevisit.agencyDrivers !== null && ocrPrevisit.agencyDrivers)}
                                            show={(
                                                <Fragment>
                                                    <FormSelect
                                                        disabled={saving || isFinished()}
                                                        items={options}
                                                        onItemSelect={onAgencyDriversInComplianceResultsChange}
                                                        selectedValue={ocrPrevisit.agencyDriversInComplianceResults}
                                                        loading={loading}
                                                        headingText='Are agency drivers to be included in compliance results:'
                                                        dangerHelperText={errors.agencyDriversInComplianceResults}
                                                    ></FormSelect>
                                                    <ShowHide
                                                        evaluator={(ocrPrevisit.agencyDriversInComplianceResults !== null && ocrPrevisit.agencyDriversInComplianceResults)}
                                                        show={(
                                                            <FormSelect
                                                                disabled={saving || isFinished()}
                                                                items={options}
                                                                onItemSelect={onIncludeAgencyForMissingDaysChange}
                                                                selectedValue={ocrPrevisit.includeAgencyForMissingDays}
                                                                loading={loading}
                                                                headingText='Are agency drivers included in missing data section:'
                                                                dangerHelperText={errors.includeAgencyForMissingDays}
                                                            ></FormSelect>
                                                        )} />

                                                    <FormSelect
                                                        disabled={saving || isFinished()}
                                                        items={options}
                                                        onItemSelect={onAgencyDriversUploadAtStartAndEndOfShiftChange}
                                                        selectedValue={ocrPrevisit.agencyDriversUploadAtStartAndEndOfShift}
                                                        loading={loading}
                                                        headingText='Are agency drivers required to upload at the start and end of each shift:'
                                                        dangerHelperText={errors.agencyDriversUploadAtStartAndEndOfShift}
                                                    ></FormSelect>
                                                </Fragment>
                                            )} />
                                        <FormSelect
                                            disabled={saving || isFinished()}
                                            items={options}
                                            onItemSelect={onAreDomesticDriversRequiredToRecordRestChange}
                                            selectedValue={ocrPrevisit.areDomesticDriversRequiredToRecordRest}
                                            loading={loading}
                                            headingText='Are domestic drivers required to record overnight rest:'
                                            dangerHelperText={errors.areDomesticDriversRequiredToRecordRest}
                                        ></FormSelect>
                                        <FormNumericInput
                                            disabled={saving || isFinished()}
                                            min={0}
                                            max={maxLead}
                                            selectedValue={ocrPrevisit.leadIn}
                                            onValueChange={onLeadInChange}
                                            headingText={"Add lead in parameters:"}
                                            dangerHelperText={errors.leadIn}
                                            loading={loading}
                                        ></FormNumericInput>
                                        <FormNumericInput
                                            disabled={saving || isFinished()}
                                            min={0}
                                            max={maxLead}
                                            selectedValue={ocrPrevisit.leadOut}
                                            onValueChange={onLeadOutChange}
                                            headingText={"Add lead out parameters:"}
                                            dangerHelperText={errors.leadOut}
                                            loading={loading}
                                        ></FormNumericInput>
                                        <FormSelect
                                            disabled={saving || isFinished()}
                                            items={options}
                                            onItemSelect={onAwayDriversInDebriefChange}
                                            selectedValue={ocrPrevisit.awayDriversInDebrief}
                                            loading={loading}
                                            headingText='Should away drivers be included in debrief results:'
                                            dangerHelperText={errors.awayDriversInDebrief}
                                        ></FormSelect>
                                        <FormSelect
                                            disabled={saving || isFinished()}
                                            items={options}
                                            onItemSelect={onAwayDriversInDcUploadChange}
                                            selectedValue={ocrPrevisit.awayDriversInDcUpload}
                                            loading={loading}
                                            headingText='Should away drivers be included in DC upload results:'
                                            dangerHelperText={errors.awayDriversInDcUpload}
                                        ></FormSelect>
                                        <FormSelect
                                            disabled={saving || isFinished()}
                                            items={options}
                                            onItemSelect={onAwayDriversInDownloadFrequencyChange}
                                            selectedValue={ocrPrevisit.awayDriversInDownloadFrequency}
                                            loading={loading}
                                            headingText='Should away drivers be included in DC upload frequency results:'
                                            dangerHelperText={errors.awayDriversInDownloadFrequency}
                                        ></FormSelect>
                                        <FormSelect
                                            disabled={saving || isFinished()}
                                            items={options}
                                            onItemSelect={onHireVehiclesChange}
                                            selectedValue={ocrPrevisit.hireVehicles}
                                            loading={loading}
                                            headingText='Are hire vehicles used:'
                                            dangerHelperText={errors.hireVehicles}
                                        ></FormSelect>
                                        <FormNumericInput
                                            disabled={saving || isFinished()}
                                            min={0}
                                            max={maxMileage}
                                            selectedValue={ocrPrevisit.mileage}
                                            onValueChange={onMileageChange}
                                            headingText={"Add the KM threshold for driving without a card inserted:"}
                                            dangerHelperText={errors.mileage}
                                            loading={loading}
                                        ></FormNumericInput>
                                        <FormSelect
                                            disabled={saving || isFinished()}
                                            items={options}
                                            onItemSelect={onVorInDownloadFrequencyChange}
                                            selectedValue={ocrPrevisit.vorInDownloadFrequency}
                                            loading={loading}
                                            headingText='Are VOR vehicles included in VU download frequency results:'
                                            dangerHelperText={errors.vorInDownloadFrequency}
                                        ></FormSelect>
                                        <FormSelect
                                            disabled={saving || isFinished()}
                                            items={options}
                                            onItemSelect={onVorInVehicleUploadChange}
                                            selectedValue={ocrPrevisit.vorInVehicleUpload}
                                            loading={loading}
                                            headingText='Are VOR vehicles included in the VU download results:'
                                            dangerHelperText={errors.vorInVehicleUpload}
                                        ></FormSelect>
                                        <FormSelect
                                            disabled={saving || isFinished()}
                                            items={options}
                                            onItemSelect={onUsePassFailCriteriaChange}
                                            selectedValue={ocrPrevisit.usePassFailCriteria}
                                            loading={loading}
                                            headingText='Should pass/fail criteria be included for this review:'
                                            dangerHelperText={errors.usePassFailCriteria}
                                        ></FormSelect>
                                        <FormSelect
                                            disabled={saving || isFinished()}
                                            items={options}
                                            onItemSelect={onUsePerformanceRatingsChange}
                                            selectedValue={ocrPrevisit.usePerformanceRatings}
                                            loading={loading}
                                            headingText='Are performance ratings set:'
                                            dangerHelperText={errors.usePerformanceRatings}
                                        ></FormSelect>
                                        <FormSelect
                                            disabled={saving || isFinished()}
                                            items={options}
                                            onItemSelect={onIsVisionDebriefUsedChange}
                                            selectedValue={ocrPrevisit.isVisionDebriefUsed}
                                            loading={loading}
                                            headingText='Is Vision debrief being used:'
                                            dangerHelperText={errors.isVisionDebriefUsed}
                                        ></FormSelect>
                                        <FormNumericInput
                                            disabled={saving || isFinished()}
                                            min={0}
                                            max={maxDriverComplianceDays}
                                            selectedValue={ocrPrevisit.driverComplianceDays}
                                            onValueChange={onDriverComplianceDaysChange}
                                            headingText={"Driver card upload:"}
                                            dangerHelperText={errors.driverComplianceDays}
                                            loading={loading}
                                        ></FormNumericInput>
                                        <FormNumericInput
                                            disabled={saving || isFinished()}
                                            min={0}
                                            max={maxVehicleComplianceDays}
                                            selectedValue={ocrPrevisit.vehicleComplianceDays}
                                            onValueChange={onVehicleComplianceDaysChange}
                                            headingText={"Vehicle unit download:"}
                                            dangerHelperText={errors.vehicleComplianceDays}
                                            loading={loading}
                                        ></FormNumericInput>
                                        <ShowHide
                                            evaluator={isDataDirty || isLocationsDirty}
                                            show={(
                                                <NotificationInline
                                                    id="notification-previsit-create"
                                                    loading={loading}
                                                    show={true}
                                                    text="Once saved Vision will collect the data required for the review."
                                                    intent="info">
                                                </NotificationInline>
                                            )} />
                                        <ShowHide
                                            evaluator={isUpdate() && !isDataDirty && !isLocationsDirty && (postSave || !isReady) && !isFinished()}
                                            show={(
                                                <NotificationInline
                                                    id="notification-previsit-snap-creation-in-process"
                                                    loading={loading}
                                                    show={true}
                                                    text="The data you have requested is being collected and will be ready shortly."
                                                    intent="info">
                                                </NotificationInline>
                                            )} />
                                        <div className="button-row" id="create-success-buttons">
                                            <ShowHide
                                                evaluator={isCreating || isFinished()}
                                                hide={(
                                                    <Fragment>
                                                        <Button text="Cancel changes" intent="danger" disabled={saving || (!isDataDirty && !isLocationsDirty)} onClick={onReset}></Button>
                                                        <Button
                                                            text="Fail appointment"
                                                            intent="danger"
                                                            onClick={onFailJob}
                                                            disabled={saving}
                                                        />
                                                    </Fragment>
                                                )} />

                                            <ShowHide
                                                evaluator={jobState === 'Done' || jobState === 'Done (Incomplete)'}
                                                hide={(
                                                    <ButtonSave
                                                        loading={loading}
                                                        onClick={onSave}
                                                        disabled={saving}
                                                        simpleDisabled={!isLocationHierarchyValid || !isValid}
                                                    ></ButtonSave>
                                                )}
                                                show={(
                                                    <Button
                                                        text="Reopen review"
                                                        intent="primary"
                                                        onClick={onReopenReview}
                                                        disabled={ocrPrevisit.nextJobId && ocrPrevisit.nextJobId > 0}
                                                    />
                                                )}
                                            />
                                            <ShowHide
                                                evaluator={isCreating || isFinished()}
                                                hide={(
                                                    <LinkButton
                                                        intent="primary"
                                                        text="Continue"
                                                        id="link-continue-ocr-review"
                                                        disabled={!isReady || saving || isDataDirty || isLocationsDirty || postSave}
                                                        href={"/reviews/compliance-review/" + jobId} />
                                                )} />
                                        </div>

                                        <Modal
                                            isOpen={showSaveModal}
                                            title={'Would you like to keep existing comments?'}
                                            showCloseButton={false}
                                            clickOutsideClose={false}
                                        >
                                            <div>

                                                <div className="spacer-bottom">
                                                    <p><em><strong>Please note:</strong> saving the previsit will result in the data for the review being regenerated.</em></p>
                                                </div>
                                                <FormSelect
                                                    disabled={saving}
                                                    items={options}
                                                    onItemSelect={onKeepCommentsChange}
                                                    selectedValue={ocrPrevisit.keepComments}
                                                    loading={loading}
                                                    headingText='Keep comments:'
                                                    dangerHelperText={savingModalErrors.keepComments}
                                                    helperText='Do you wish to keep any existing comments that have been added?'
                                                ></FormSelect>
                                                <NotificationInline
                                                    id="notification-previsit-review-comments"
                                                    loading={loading}
                                                    show={ocrPrevisit.keepComments}
                                                    text="Please ensure any existing comments are reviewed as the underlying data may have changed."
                                                    intent="info">
                                                </NotificationInline>
                                            </div>
                                            <div className="bp3-dialog-footer">
                                                <div className="bp3-dialog-footer-actions">
                                                    <ButtonSave text="Save" onClick={saveSettings} simpleDisabled={!isSavingModalValid} disabled={saving}></ButtonSave>
                                                    <Button text="Cancel" onClick={() => setShowSaveModal(false)} disabled={saving}></Button>
                                                </div>
                                            </div>
                                        </Modal>

                                        <FailVisit
                                            jobFailed={isFailed}
                                            failAppointmentReason={failAppointmentReason}
                                            jobId={jobId}
                                            showFailedAppointment={showFailJobModal}
                                            loading={loading}
                                            onFailVisitClose={onFailVisitClose}
                                            onFailVisitSave={onFailVisitSave}
                                        />

                                        <AlertUnsavedData
                                            isDirty={isDataDirty}
                                        ></AlertUnsavedData>
                                    </Fragment>
                                )}
                                hide={(
                                    <NotificationInline
                                        allowClose={false}
                                        show
                                        text="This compliance review has been failed and can no longer be changed."
                                        intent="danger"
                                    />
                                )}
                            />
                        )}
                    />
                </div>
            )}
            no={() => (
                <PageRestricted />
            )}
        />
    );
}

OcrPrevisit.propTypes = {
    breadcrumbs: PropTypes.array.isRequired
};
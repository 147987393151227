import React, { useEffect, useState, Fragment, useRef } from "react";
import { Intent, Alignment, Icon, Divider } from "@blueprintjs/core";
import { PowerBIEmbed } from 'powerbi-client-react';
import { models } from 'powerbi-client';
import { useParams } from 'react-router-dom';
import axios from 'axios';
import classNames from 'classnames';
import { useSelector } from 'react-redux';

import _ from 'underscore';

import { Button, LinkButton } from "components/buttons";
import { FormSelect, FormSwitch, FormTextInput } from "components/form-fields";
import { BlobStorageService, DashboardService } from "services";
import { ShowHide } from "components/layout";
import { Page404 } from "pages/errors/page-404";
import { AlertConfirm, MediaViewer, NotificationInline, NotificationToaster } from "components/notifications";

import './Dashboard.css';
import { Tooltip } from "components/tooltip";
import { UserAccess, UserHasAccess } from "components/user-access";
import { Tabs } from "components/navigation";

export function Dashboard() {

	const { id } = useParams();
	const defaultRatio = 58.8;
	const defaultConfig = {
		type: 'report',
		id: undefined,
		embedUrl: undefined,
		accessToken: undefined,
		tokenType: models.TokenType.Embed,
		settings: {
			filterPaneEnabled: false,
			navContentPaneEnabled: false,
		}
	};

	const [dashboardConfig, setDashboardConfig] = useState(defaultConfig);
	const [loading, setLoading] = useState(true);
	const [saving, setSaving] = useState(false);
	const [customViews, setCustomViews] = useState([]);
	const [customFilters, setCustomFilters] = useState([]);
	const [customSlicers, setCustomSlicers] = useState([]);
	const [selectedDashboard, setSelectedDashboard] = useState(null);
	const [reportLoaded, setReportLoaded] = useState(false);
	const [reportRendered, setReportRendered] = useState(false);
	const [dashboardName, setDashboardName] = useState("Dashboard");
	const [selectedCustomViewId, setSelectedCustomViewId] = useState(-1);
	const [selectedCustomView, setSelectedCustomView] = useState({});
	const [trainingVideo, setTrainingVideo] = useState(null);
	const [userGuideAvailable, setUserGuideAvailable] = useState(false);
	const [generatingUserGuide, setGeneratingUserGuide] = useState(false);
	const [dashboardRatio, setDashboardRatio] = useState(defaultRatio);
	const [showBorder, setShowBorder] = useState(true);
	const [applyingView, setApplyingView] = useState(true);
	const [invalidDashboard, setInvalidDashboard] = useState(false);
	const [manageViewAction, setManageViewAction] = useState("");
	const [editingView, setEditingView] = useState(false);
	const [showDeleteConfirm, setShowDeleteConfirm] = useState(false);
	const [showTrainingModal, setShowTrainingModal] = useState(false);
	const [invalidCheckComplete, setInvalidCheckComplete] = useState(false);
	const [dashboardTabs, setDashboardTabs] = useState([]);
	const invalidCheck = useRef(false);
	const tabsRef = useRef([]);

	const tokenExpiry = useRef(null);
	const dashboardId = useRef(null);

	const memberNumber = useSelector(state => state.loggedInUser.memberNumber);
	const emailAddress = useSelector(state => state.loggedInUser.email);
	const isInternal = UserHasAccess("Internal");


	function initialLoad() {

		invalidCheck.current = false;

		setDashboardConfig(defaultConfig);
		setLoading(true);
		setCustomViews([]);
		setCustomFilters([]);
		setCustomSlicers([]);
		setDashboardTabs([]);
		setSelectedDashboard(null);
		setReportRendered(false);
		setReportLoaded(false);
		setDashboardName("Dashboard");
		setSelectedCustomViewId(-1);
		setDashboardRatio(defaultRatio);
		setShowBorder(true);
		setInvalidDashboard(false);
		setInvalidCheckComplete(false);
		setUserGuideAvailable(false);
		setGeneratingUserGuide(false);
		setManageViewAction("");
		setSelectedCustomView({});
		tabsRef.current = null;

		DashboardService.getDashboards().then(function (dashboardsResponse) {

			var dashboardToShow = dashboardsResponse.find(function (d) {
				return d.key === id;
			});

			if (dashboardToShow != null) {
				setSelectedDashboard(dashboardToShow);
				setDashboardName(dashboardToShow.dashboardName);
				setShowBorder(dashboardToShow.showBorder);
				setUserGuideAvailable(dashboardToShow.hasUserGuide);
				setManageViewAction(dashboardToShow.manageAction);

				if (dashboardToShow.height != null) {
					setDashboardRatio(dashboardToShow.height);
				}

				if (dashboardToShow.trainingUrl != null && dashboardToShow.trainingUrl !== "") {
					setTrainingVideo(dashboardToShow.trainingUrl);
				}

				dashboardId.current = dashboardToShow.dashboardId;

				axios.all([
					DashboardService.getEmbedToken(dashboardToShow.dashboardId),
					DashboardService.getCustomFilters(dashboardToShow.dashboardId),
					DashboardService.getCustomSlicers(dashboardToShow.dashboardId),
					DashboardService.getCustomViews(dashboardToShow.dashboardId)
				]).then(axios.spread(function (config, filterResponse, slicerResponse, viewsResponse) {

					var fullConfig = {
						...dashboardConfig,
						id: dashboardToShow.reportId,
						embedUrl: config.embedUrl,
						accessToken: config.embedToken,
						settings: {
							filterPaneEnabled: false,
							navContentPaneEnabled: dashboardToShow.showNavigation,
						}
					};

					tokenExpiry.current = config.expiry;
					var groupedData = _.chain(slicerResponse).groupBy(function (s) {
						return s.pageName + s.visualTitle;
					}).map(function (s) {
						return {
							'pageName': s[0].pageName,
							'visualTitle': s[0].visualTitle,
							'filter': _.pluck(_.filter(s, function (s1) {
								return s1.filter != null;
							}), 'filter')
						};
					}).value();

					var defaultView = viewsResponse.find(function (v) {
						return v.isDefault;
					});

					setDashboardConfig(fullConfig);
					setCustomViews(viewsResponse);
					setCustomFilters(filterResponse);
					setCustomSlicers(groupedData);
					setSelectedCustomViewId(defaultView.id);
					setLoading(false);

				}), () => {
					NotificationToaster.show(Intent.DANGER, "Could not load the dashboard. Please refresh the page.");
				});

			} else {
				setLoading(false);
            }
		});
	}

	useEffect(initialLoad, [id]);

	useEffect(() => {

		const tokenCheckInterval = 5000;
		const intervalId = setInterval(() => {

			if (tokenExpiry.current == null) {
				return;
			}

			var currentTimestamp = Math.floor(Date.now() / 1000);

			if (tokenExpiry.current <= currentTimestamp) {
				tokenExpiry.current = null;
				fetchNewToken();
			}

		}, tokenCheckInterval);

		return () => clearInterval(intervalId);

	}, []);

	function onCustomViewChange(item) {
		setSelectedCustomViewId(item.id);
    }

	function onFullscreen() {
		window.report.fullscreen();
	}

	function fetchNewToken() {
		DashboardService.getEmbedToken(dashboardId.current).then(function (config) {
			window.report.setAccessToken(config.embedToken);
			tokenExpiry.current = config.token;
		});
	}

	function onReset() {

		var defaultView = customViews.find(function (v) {
			return v.isDefault;
		});

		if (defaultView.id === selectedCustomViewId) {
			applyViewToDashboard();
		} else {
			setSelectedCustomViewId(defaultView.id);
		}
	}

	function applyViewToDashboard() {

		if (!reportRendered || !invalidCheck.current) {
			return;
		}

		setApplyingView(true);

		var view = customViews.find(x => x.id === selectedCustomViewId);

		if (view == null) {
			return;
		}

		var defaultView = view.id === -1;
		var bookmarkState = view.state;

		if (bookmarkState == null) {
			window.report.bookmarksManager.getBookmarks().then(function (bookmarks) {

				var initialBookmark = bookmarks.find(function (b) {
					return b.displayName.toLowerCase() === "initial";
				});

				applyBookmark(initialBookmark.state, defaultView);
			});
		} else {
			applyBookmark(bookmarkState, defaultView);
        }
	}

	useEffect(applyViewToDashboard, [selectedCustomViewId, customViews, reportRendered, invalidCheckComplete]);

	useEffect(function () {
		tabsRef.current = dashboardTabs;
	}, [dashboardTabs])

	function applyBookmark(bookmarkState, isDefaultView) {
		window.report.bookmarksManager.applyState(bookmarkState).then(function () {

			if (customFilters.length > 0) {
				window.report.setFilters(customFilters);
			}

			if (customSlicers.length > 0 && isDefaultView) {
				window.report.getPages().then(function (pages) {

					var slicerPromises = [];

					for (var i = 0; i < customSlicers.length; i++) {
						var slicer = customSlicers[i];
						slicerPromises.push(applySlicer(slicer, pages))
					}

					axios.all(slicerPromises).then(function () {
						setApplyingView(false);
					})
				})
			} else {
				setApplyingView(false);
			}
		}).catch(function () {
			applyViewToDashboard();
        });
    }

	function applySlicer(slicer, pages) {

		return new Promise((resolve, reject) => {

			var activePage = pages.find(function (page) {
				return page.displayName === slicer.pageName;
			});

			if (activePage != null) {

				activePage.getVisuals().then(function (visuals) {

					var visualSlicer = visuals.filter(function (visual) {
						return visual.type === "slicer" && visual.title === slicer.visualTitle;
					});

					if (visualSlicer.length > 0) {
						visualSlicer[0].setSlicerState({ filters: slicer.filter }).then(resolve, reject);
					} else {
						resolve();
                    }
				}, reject);
			} else {
				resolve();
			}
		});
    }

	function applyBookmarkStates() {

		if (!reportRendered || customViews.length === 0) {
			return;
		}

		window.report.bookmarksManager.getBookmarks().then(function (bookmarks) {

			var initialBookmark = bookmarks.find(function (b) {
				return b.displayName.toLowerCase() === "initial";
			});

			if (initialBookmark != null && customViews[0].state == null) {

				var clonedViews = [
					...customViews
				];

				clonedViews[0].state = initialBookmark.state;
				setCustomViews(clonedViews);
			}
		});

	}

	useEffect(applyBookmarkStates, [reportLoaded, customViews.length]);

	function onReportRendered() {
		setReportRendered(true);
    }

	function onReportLoad() {
		setReportLoaded(true);
	}

	useEffect(function () {

		if (reportRendered && reportLoaded && selectedDashboard != null && selectedDashboard.externalNavigation) {
			window.report.getPages().then(function (pages) {
				setDashboardTabs(pages.filter(x => x.visibility === 0).map(function (p, index) {

					return {
						title: p.displayName,
						key: p.name,
						width: p.defaultSize.width,
						height: p.defaultSize.height,
						selected: index === 0
					};
				}));
			});
		}

	}, [reportRendered, reportLoaded, selectedDashboard, selectedDashboard?.externalNavigation])

	function checkDashboardPermissions() {

		if (!invalidCheck.current && reportRendered && reportLoaded) {
			var tokenEmailAddress = isInternal ? 'visioninternaluser' : emailAddress;
			var expectedUsername = `Username\r\n^${tokenEmailAddress}^${memberNumber}^\r\n`.toLowerCase();

			window.report.getPages().then(function (pages) {

				if (pages && pages.length > 0) {
					var page = pages[0];

					page.getVisuals().then(function (visuals) {

						var userField = visuals.find(x => x.title.toLowerCase() === 'username');
						if (userField != null) {
							userField.exportData().then(function (e) {
								invalidCheck.current = true;
								setInvalidCheckComplete(true);

								var matchingCredentials = expectedUsername !== e.data.toLowerCase();
								setInvalidDashboard(matchingCredentials);

								if (!matchingCredentials) {
									DashboardService.logError(expectedUsername, e.data.toLowerCase(), "Usernames do not match", selectedDashboard.dashboardId);
								}

							}, function () {
								setInvalidDashboard(true);
								DashboardService.logError(expectedUsername, null, "Could not export username", selectedDashboard.dashboardId);
							})
						} else {
							setInvalidDashboard(true);
							DashboardService.logError(expectedUsername, null, "Could not find username field", selectedDashboard.dashboardId);
						}
					}, function () {
						setInvalidDashboard(true);
						DashboardService.logError(expectedUsername, null, "Could not get page visuals", selectedDashboard.dashboardId);
					});
				}
			}, function () {
				setInvalidDashboard(true);
				DashboardService.logError(expectedUsername, null, "Could not get report pages", selectedDashboard.dashboardId);
			});
		}
	}

	useEffect(checkDashboardPermissions, [reportLoaded, reportRendered]);

	function onPrint() {
		window.report.print();
	}

	function onShowTraining() {
		setShowTrainingModal(true);
	}

	function onHideTrainingModal() {
		setShowTrainingModal(false);

	}

	function onViewAdd() {
		setSelectedCustomView({
			id: 0,
			name: '',
			isDefault: false
		});
		setEditingView(true);
	}

	function onViewEdit() {
		var viewToEdit = customViews.find(function (v) {
			return v.id === selectedCustomViewId;
		});

		setSelectedCustomView({
			...viewToEdit
		});
		setEditingView(true);
	}

	function onViewDelete() {
		setShowDeleteConfirm(true);
	}

	function onViewNameChange(e) {
		setSelectedCustomView({
			...selectedCustomView,
			name: e.target.value
		})
	}

	function onDefaultChange(item) {
		setSelectedCustomView({
			...selectedCustomView,
			isDefault: item.currentTarget.checked
		})
	}

	function onViewSave() {

		setSaving(true);

		if (selectedCustomView.id === 0) {

			window.report.bookmarksManager.capture().then(function (s) {

				var viewWithState = {
					...selectedCustomView,
					state: s.state
                }

				DashboardService.createCustomView(selectedDashboard.dashboardId, viewWithState).then(function (viewId) {
					NotificationToaster.show(Intent.SUCCESS, "Custom view successfully created.");
					setEditingView(false);
					refreshCustomViews(viewId);
				}, function () {
					NotificationToaster.show(Intent.DANGER, "Could not create your custom view. Please try again.");
				}).finally(function () {
					setSaving(false);
				});
			}, function () {
				NotificationToaster.show(Intent.DANGER, "Could not create your custom view. Please try again.");
				setSaving(false);
            });

			
		} else {
			DashboardService.updateCustomView(selectedDashboard.dashboardId, selectedCustomViewId, selectedCustomView).then(function () {
				NotificationToaster.show(Intent.SUCCESS, "Custom view successfully updated.");
				setEditingView(false);
				refreshCustomViews();
			}, function () {
				NotificationToaster.show(Intent.DANGER, "Could not update your custom view. Please try again.");
			}).finally(function () {
				setSaving(false);
			});
		}
		setEditingView(false);
	}

	function onViewCancel() {
		setEditingView(false);
	}

	function onDeleteConfirm() {
		DashboardService.deleteCustomView(selectedDashboard.dashboardId, selectedCustomViewId).then(function () {
			NotificationToaster.show(Intent.SUCCESS, "Custom view successfully deleted.");
			refreshCustomViews(-1);
			setShowDeleteConfirm(false);
		}, function () {
			NotificationToaster.show(Intent.DANGER, "Could not delete your custom view. Please try again.");
		})
	}

	function onDeleteCancel() {
		setShowDeleteConfirm(false);
	}

	function refreshCustomViews(newViewId) {
		DashboardService.getCustomViews(selectedDashboard.dashboardId).then(function (viewsResponse) {

			setCustomViews(viewsResponse);
			if (newViewId != null) {
				setSelectedCustomViewId(newViewId)
            }
        })
	}

	function onTabClick(tabIndex) {
		var newTab = dashboardTabs[tabIndex];

		window.report.setPage(newTab.key);
		setDashboardRatio((newTab.height / newTab.width) * 100);

		var clonedTabs = [
			...dashboardTabs
		];

		setDashboardTabs(clonedTabs.map(function (t, index) {
			return {
				...t,
				selected: index === tabIndex
			}
		}));

	}

	function onPageChange(e) {

		if (tabsRef.current == null) {
			return;
		}

		var newPageKey = e.detail.newPage.name;

		var newIndex = tabsRef.current.findIndex(function (t) {
			return t.key === newPageKey && !t.selected;
		});

		if (newIndex !== -1) {

			setDashboardRatio((tabsRef.current[newIndex].height / tabsRef.current[newIndex].width) * 100);

			var clonedTabs = [
				...tabsRef.current
			];

			setDashboardTabs(clonedTabs.map(function (t, index) {
				return {
					...t,
					selected: index === newIndex
				}
			}));
		}
	}

	function onGetUserGuide() {
		setGeneratingUserGuide(true);
		DashboardService.downloadUserGuide(selectedDashboard.dashboardId).then(function (downloadToken) {
			BlobStorageService.downloadFile(downloadToken.storageName, downloadToken.containerName, downloadToken.fileName, downloadToken.token, downloadToken.downloadName).then(function () {
				NotificationToaster.show(Intent.SUCCESS, "User guide generated successfully. Download will now begin.");
			}, function () {
				NotificationToaster.show(Intent.DANGER, "Could not download user guide. Please try again.");
			}).finally(function () {
				setGeneratingUserGuide(false);
            });
		}, function () {
			NotificationToaster.show(Intent.DANGER, "Could not download user guide. Please try again.");
			setGeneratingUserGuide(false);
        });
    }


	return (
		<ShowHide
			evaluator={!loading && selectedDashboard == null}
			show={(
				<Page404 />
			)}
			hide={(
				<div className="row">

					<h1 className={classNames({ "bp3-skeleton": loading })}>{dashboardName}</h1>

					<ShowHide
						evaluator={invalidDashboard}
						hide={(
							<Fragment>

								<div className="inline-items inline-items-space spacer-bottom">
									<div className="inline-item">
										<div className="inline-items">
											<label className={classNames({ "bp3-skeleton": loading })}>Select a view</label>
											
											<FormSelect loading={loading} items={customViews} selectedValue={selectedCustomViewId} onItemSelect={onCustomViewChange} disabled={!reportRendered || applyingView} />
											<UserAccess
												perform={manageViewAction}
												yes={() => (
													<div className="button-row">
														<Tooltip content="Custom views allow you to create bespoke dashboard layouts for everyone in your organisation" disabled={loading}>
															<Icon icon="help" className={classNames({ "bp3-skeleton": loading })} />
														</Tooltip>
														<Divider />
														<ShowHide
															evaluator={selectedCustomViewId !== -1}
															show={(
																<Fragment>
																	<Tooltip content="Edit view" disabled={!reportRendered || applyingView}>
																		<Button loading={loading} minimal large={false} icon="edit" onClick={onViewEdit} disabled={!reportRendered || applyingView} />
																	</Tooltip>
																	<Tooltip content="Delete view" disabled={!reportRendered || applyingView}>
																		<Button loading={loading} minimal large={false} icon="trash" onClick={onViewDelete} disabled={!reportRendered || applyingView} />
																	</Tooltip>
																</Fragment>

															)}
														/>
														<Tooltip content="Create new view" disabled={!reportRendered || applyingView}>
															<Button loading={loading} minimal large={false} icon="plus" onClick={onViewAdd} disabled={!reportRendered || applyingView} />
														</Tooltip>

													</div>
												)}
											/>
											
										</div>
									</div>
									<div className="inline-item">
										<div className="button-row">
											<Tooltip content="Reset" disabled={!reportRendered || applyingView}>
												<Button loading={loading} icon="reset" iconOnly minimal large={false} intent="primary" onClick={onReset} disabled={!reportRendered || applyingView} />
											</Tooltip>
											<Tooltip content="Full screen" disabled={!reportRendered || applyingView}>
												<Button loading={loading} icon="maximize" iconOnly minimal large={false} intent="primary" onClick={onFullscreen} disabled={!reportRendered || applyingView} />
											</Tooltip>
											<Tooltip content="Print" disabled={!reportRendered || applyingView}>
												<Button loading={loading} icon="print" iconOnly minimal large={false} intent="primary" onClick={onPrint} disabled={!reportRendered || applyingView} />
											</Tooltip>
											<ShowHide
												evaluator={selectedDashboard != null && selectedDashboard.trainingUrl != null && selectedDashboard.trainingUrl !== ""}
												show={(
													<Tooltip content="Watch tutorial" disabled={!reportRendered}>
														<Button loading={loading} icon="learning" iconOnly minimal large={false} intent="primary" onClick={onShowTraining} disabled={!reportRendered || applyingView} />
													</Tooltip>
												)}
											/>
											<ShowHide
												evaluator={userGuideAvailable}
												show={(
													<Tooltip content="Download user guide" disabled={!reportRendered || generatingUserGuide}>
														<Button loading={loading} icon="learning" iconOnly minimal large={false} intent="primary" onClick={onGetUserGuide} disabled={!reportRendered || generatingUserGuide} />
													</Tooltip>
												)}
											/>
											<ShowHide
												evaluator={selectedDashboard != null && selectedDashboard.showTachoCodes}
												show={(
													<Tooltip content="Tacho Codes" disabled={!reportRendered}>
														<LinkButton loading={loading} icon="manual" iconOnly minimal large={false} intent="primary" href={`${window.env.VISION_URL}/TachoCodes`} externalLink openInNewTab disabled={!reportRendered} />
													</Tooltip>
												)}
											/>
										</div>
									</div>
								</div>
								<Tabs tabs={dashboardTabs} onClick={onTabClick} loading={loading} />
								<div className={classNames("dashboard-container", { "dashboard-container-border": showBorder, "bp3-skeleton": loading })} style={{ paddingTop: `${dashboardRatio}%` }}>
									<PowerBIEmbed
										embedConfig={dashboardConfig}
										eventHandlers={
											new Map([
												['loaded', onReportLoad],
												['rendered', onReportRendered],
												['pageChanged', onPageChange]
											])
										}
										getEmbeddedComponent={(embeddedReport) => {
											window.report = embeddedReport;
										}}
									/>
								</div>
								<AlertConfirm
									title="Manage View"
									isOpen={editingView}
									onConfirm={onViewSave}
									onCancel={onViewCancel}
									confirmButtonIntent="primary"
									confirmButtonText="Save"
									saving={saving}
								>
									<FormTextInput id="new-group-name" headingText="What would you like to call the custom view?" onChange={onViewNameChange} value={selectedCustomView.name} />
									<FormSwitch label="Set as default view for all users?" inline={true} alignment={Alignment.RIGHT} checked={selectedCustomView.isDefault} onChange={onDefaultChange} />
								</AlertConfirm>
								<AlertConfirm
									title="Please confirm"
									isOpen={showDeleteConfirm}
									onConfirm={onDeleteConfirm}
									onCancel={onDeleteCancel}
								>
									<p>Are you sure you want to delete this dashboard view?</p>
								</AlertConfirm>
								<MediaViewer isOpen={showTrainingModal} mediaSource={trainingVideo} clickOutsideClose={false} onClose={onHideTrainingModal} />
							</Fragment>
						)}
						show={(
							<NotificationInline intent="danger" show allowClose={false} text="An error has occurred whilst loading the dashboard, please refresh the page to try again." />
						)}

					/>


					
				</div>
			)}
		/>
        
    );
}

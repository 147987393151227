import React, { } from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import 'components/form-fields/FormFields.css';


export function FormHeading(props) {
	if (!props.children || props.children === '') {
		return null;
	}

	const Heading = props.headingLevel;
	return (
		<Heading className={classNames({ "bp3-skeleton": props.loading }, props.className)}>{props.children}</Heading>
	);
}

FormHeading.defaultProps = {
	children: <></>,
	loading: false
};

FormHeading.propTypes = {
	children: PropTypes.node,
	loading: PropTypes.bool,
	headingLevel: PropTypes.oneOf(['h1', 'h2', 'h3', 'h4', 'h5', 'h6']).isRequired,
	isLoading: PropTypes.bool
};
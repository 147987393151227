import React, { useState } from "react";
import { AuditingTable } from "components/auditing";
import { UserAccess } from "components/user-access";
import { PageRestricted } from "pages/errors/page-restricted";
import { RiskService } from "services/RiskService";


export function RiskSettingsHistory(props) {

    const requiredAction = "Risk:Manage"; 
    const noDataMessage = "No changes have been made to your risk settings for the selected date period";
    const [tableData, setTableData] = useState(null);
    const [tableRecordCount, setTableRecordCount] = useState(0);

    function onTableChange(pageSize, pageNumber, startDate, endDate) {
        RiskService.getChangeLog(pageSize, pageNumber, startDate, endDate).then(r => {
            setTableData(r.data);
            setTableRecordCount(r.totalCount);
        });
    }

    return (
        <UserAccess perform={requiredAction} 
            yes={() => ( 
                <AuditingTable data={tableData} totalCount={tableRecordCount} onChange={onTableChange} noDataMessage={noDataMessage}></AuditingTable>
            )} 
            no={() => ( 
                <div className="row"> 
                    <PageRestricted /> 
                </div> 
            )} 
        /> 
    );
}
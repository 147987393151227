import React from 'react';
import { PageRestricted } from 'pages/errors/page-restricted';
import { RiskProfile } from 'pages/risk/shared';
import { UserAccess } from 'components/user-access';



export function RiskEmployeeProfile(props) {

    function mapEmployeeInfo(profileResponse) {
        return [
            {
                header: "Employee Type",
                value: profileResponse.employeeType,
                key: 'risk-period-dates'
            },
            {
                header: "Employee Status",
                value: profileResponse.employeeStatus,
                key: 'current-events'
            },
            {
                header: "Employee Location",
                value: profileResponse.employeeLocation,
                key: 'risk-employee-count'
            }
        ];
    }

    return (
        <UserAccess perform={["Risk:View"]}
            yes={() => (
                <RiskProfile 
                    title="Employee Risk"
                    riskEntityType="Employee"
                    riskEntityController="Employees"
                    profileResponseMapper={mapEmployeeInfo}
                    breadcrumbs={props.breadcrumbs}
                />
            )}
            no={() => (
                <PageRestricted />
            )}
        />

    );

}
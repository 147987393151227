import React, { useEffect, useState } from "react";
import { KeyBlock } from "components/key/KeyBlock";
import PropTypes from "prop-types";
import { Position } from "@blueprintjs/core";

export function AssetStatus(props) {
    const { statusId, ownershipId, isLoading, statusAttributeMappings, ownershipAttributeMappings, tooltipPosition } = props;

    const [tooltipText, setTooltipText] = useState("");
    const [statusColour, setStatusColour] = useState("FF7454");
    const [ownershipClassName, setOwnershipClassName] = useState("");
    const [ownershipIcon, setOwnershipIcon] = useState("");

    useEffect(() => {
        if (statusId && ownershipId && statusAttributeMappings && ownershipAttributeMappings && statusAttributeMappings.length > 0 && ownershipAttributeMappings.length > 0) {
            let statusAttribute = statusAttributeMappings.find(o => {
                return o.id === statusId
            });

            let ownershipAttributes = ownershipAttributeMappings.find(o => {
                return o.id === ownershipId
            });

            if (statusAttribute) {
                setStatusColour(statusAttribute.colours);
            }

            if (ownershipAttributes) {
                setOwnershipClassName(ownershipAttributes.className);
                setOwnershipIcon(ownershipAttributes.icon);
            }

            setTooltipText(`${statusAttribute.name} ${ownershipAttributes.name}`);
        }
    }, [statusId, ownershipId, statusAttributeMappings, ownershipAttributeMappings]);

    return (
        <div className="no-margin">
            <KeyBlock rounded={true} icon={ownershipIcon} className={ownershipClassName} colours={statusColour} size={8} loading={isLoading} toolTipText={tooltipText} tooltipPosition={tooltipPosition} />
        </div>
    );
}

AssetStatus.defaultProps = {
    statusId: 0,
    ownershipId: 0,
    isLoading: false,
    statusAttributeMappings: [],
    ownershipAttributeMappings: [],
    tooltipPosition: Position.TOP
};

AssetStatus.propTypes = {
    statusId: PropTypes.number,
    ownershipId: PropTypes.number,
    isLoading: PropTypes.bool,
    statusAttributeMappings: PropTypes.array,
    ownershipAttributeMappings: PropTypes.array,
    tooltipPosition: PropTypes.string
};
import React, { useEffect } from 'react';
import PropTypes, { array } from 'prop-types';
import { Popover, Button, Menu, MenuItem, Position, Intent } from "@blueprintjs/core";
import { useSelector, useDispatch } from 'react-redux';
import { SetFiltering } from 'state/actions';
import { ShowHide } from 'components/layout';
import { HelperFunctions } from 'helpers'
import './Filter.css';


export function Filter(props) {

    const selectedOptions = useSelector(state => state.filters[props.filterName]);

    const dispatch = useDispatch();

    useEffect(() => {
        if (props.defaultFilterValues.length !== 0) {
            dispatch(SetFiltering(props.filterName, props.defaultFilterValues));
        }
    }, []);

    useEffect(function () {
        props.onUpdate(selectedOptions);
    }, [selectedOptions.length]);

    function onItemSelect(filterName, selectedItem) {

        var clonedSelected = [
            ...selectedOptions
        ];

        if (isItemSelected(filterName, selectedItem.id)) {
            clonedSelected = clonedSelected.filter(function (instance) {
                return instance.name.toLowerCase() !== filterName.toLowerCase() 
                    || instance.value.toString().toLowerCase() !== selectedItem.id.toString().toLowerCase();
            });
        } else {
            clonedSelected.push({
                name: filterName,
                displayName: selectedItem.name,
                value: selectedItem.id
            });
        }
        dispatch(SetFiltering(props.filterName, clonedSelected));
    }

    function clearFilters() {
        dispatch(SetFiltering(props.filterName, []));
    }

    function setFilterText(filterName, displayName) {

        if (selectedOptions.lenth === 0) {
            return displayName != null ? displayName : filterName;
        }

        var filterSelectedOptions = selectedOptions.filter(function (instance) {
            return instance.name.toLowerCase() === filterName.toLowerCase();
        });

        switch (filterSelectedOptions.length) {
            case 0:
                return displayName != null ? displayName : filterName;
            case 1:
                return filterSelectedOptions[0].displayName;
            default:
                return `${filterSelectedOptions[0].displayName} (+${filterSelectedOptions.length - 1})`
        }
    }

    function isItemSelected(filterName, selectedItemValue) {
        return selectedOptions.filter(function (instance) {
            return instance.name.toLowerCase() === filterName.toLowerCase() && instance.value.toString().toLowerCase() === selectedItemValue.toString().toLowerCase();
        }).length > 0;
    }

    function generateDisplayIcon(name, id) {

        if (selectedOptions.length === 0) {
            return null;
        }

        return isItemSelected(name, id) ? "tick" : ""
    }

    return (
        <ShowHide
            evaluator={props.visible}
            show={(
                <div className="filter-container">
                    <div className="filter-row">
           
                        <div className="inline-items">
                            {
                                props.filters != null && props.filters.map(function (filter) {
                                    return (

                                        <Popover key={filter.name} minimal popoverClassName="dropdown-list-behaviour" position={Position.BOTTOM_LEFT}>
                                            <Button text={setFilterText(filter.name, filter.displayName)} disabled={props.disabled} rightIcon="chevron-down" minimal id={`filter-${HelperFunctions.kebabCase(filter.name)}-btn`} />
                                            <Menu id={`filter-${HelperFunctions.kebabCase(filter.name)}-menu`}>
                                                {filter.items.map(item => {
                                                    return (
                                                        <MenuItem
                                                            key={item.name}
                                                            text={item.name}
                                                            onClick={() => { onItemSelect(filter.name, item) }}
                                                            icon={generateDisplayIcon(filter.name, item.id)}
                                                        />
                                                    )
                                                })}
                                            </Menu>
                                        </Popover>
                                    );
                                })
                            }
                            <ShowHide
                                evaluator={selectedOptions.length > 0}
                                show={(
                                    <Button text="Clear filters" intent={Intent.DANGER} minimal={true} onClick={clearFilters} icon="cross" disabled={props.disabled}></Button>
                                )}
                            />
                        </div>
                    </div>
                </div>
            )}
        />
        
    );
}

Filter.defaultProps = {
    id: null,
    loading: false,
    disabled: false,
    visible: false,
    defaultFilterValues: []
};

Filter.propTypes = {
    filterName: PropTypes.string.isRequired,
    id: PropTypes.string,
    loading: PropTypes.bool,
    onUpdate: PropTypes.func.isRequired,
    disabled: PropTypes.bool,
    visible: PropTypes.bool,
    defaultFilterValues: PropTypes.arrayOf(array)
};
import React, { Fragment, useEffect, useState } from "react";
import moment from "moment";
import PropTypes from 'prop-types';

import { ShowHide } from "components/layout";
import { StatusBlock } from 'components/status';
import { FormRadio, FormDateInput, FormSwitch, FormTextInput, FormHelperText } from "components/form-fields";
import { Table } from 'components/listing';
import { useValidation } from "hooks/useValidation";


export function DebriefGeneralSettings(props) {

    const [isValid, errors, validate] = useValidation();
    const [levelsValid, setLevelsValid] = useState(true);
    useEffect(validateSettings, [props.settings]);
    useEffect(() => props.onValidationUpdate(isValid && levelsValid), [isValid, levelsValid]);

    function validateSettings() {
        let rules = [];

        if (props.settings.customStartDate) {
            rules.push({ fieldName: "startDate", required: true });
        }

        var areLevelsValid = true;

        props.settings.levels.forEach(function (level) {
            if (level.name == null || level.name.length === 0) {
                areLevelsValid = false;
            }
        });

        setLevelsValid(areLevelsValid);

        validate(rules, props.settings);
    }

    function onCustomStartDateChange(item) {
        props.onUpdate({
            ...props.settings,
            customStartDate: item.currentTarget.value === "date"
        });
    }

    function onStartDateChange(item) {
        props.onUpdate({
            ...props.settings,
            startDate: item ? moment(item).format("YYYY-MM-DD") : null
        });
    }

    function onStartDateOverride(item) {
        props.onUpdate({
            ...props.settings,
            customStartDateOverideAllowed: item.currentTarget.checked
        });
    }

    function onAllowDebriefLevels(item) {
        props.onUpdate({
            ...props.settings,
            debriefLevelsActive: item.currentTarget.checked
        });
    }

    function onDebriefLevelsMandatory(item) {
        props.onUpdate({
            ...props.settings,
            debriefLevelsMandatory: item.currentTarget.checked
        });
    }

    function onLevelNameChange(id, item) {

        var levels = [
            ...props.settings.levels
        ];

        var level = levels.find(l => l.id === id);
        level.name = item.target.value;

        props.onUpdate({
            ...props.settings,
            levels: levels
        });
    }

    function formatParamTableHeaders() {

        let paramHeaders = ["Debrief Level", "Debrief Level Name"];

        return {
            headers: paramHeaders.map((value) => {
                return {
                    key: value,
                    value: value
                }
            })
        };
    }

    function formatParamTable() {
        return props.settings.levels.map(function (level) {
            return {
                cells: [
                    <span className="debrief-level-status"><StatusBlock background={level.background}>{level.id}</StatusBlock></span>,
                    <FormTextInput loading={props.loading} disabled={props.saving} value={level.name} id={`input-debrief-level-${level.id}`} onChange={(ev) => { onLevelNameChange(level.id, ev) }} />
                ],
                key: level.id
            };
        });
    }

    return (
        <div>

            <FormRadio
                disabled={props.saving}
                onChange={onCustomStartDateChange}
                headingText="How should the debrief start date be calculated:"
                selectedValue={props.settings.customStartDate ? "date" : "default"}
                loading={props.loading}
                options={[{ value: "default", label: "Use default debrief start date" }, { value: "date", label: "Set debrief start date for the account" }]}
                id="input-debrief-use-custom-date"
            ></FormRadio>

            <ShowHide
                evaluator={props.settings.customStartDate}
                show={(
                    <Fragment>
                        <FormDateInput
                            disabled={props.saving}
                            headingText="Start date:"
                            onChange={onStartDateChange}
                            value={props.settings.startDate}
                            loading={props.loading}
                            minDate={moment("2010-01-01").toDate()}
                            id="input-debrief-custom-date"
                            dangerHelperText={errors.startDate}
                        ></FormDateInput>

                        <FormSwitch
                            disabled={props.saving}
                            label="Allow start date to be overriden:"
                            onChange={onStartDateOverride}
                            checked={props.settings.customStartDateOverideAllowed}
                            loading={props.loading}
                            id="input-start-date-overide"
                            helperText="Selecting yes will allow users of the debrief to amend the date set for the first debrief undertaken. Selecting no will ensure that the date is fixed for all drivers unless the start date on their driver profile is later than the date set."
                        ></FormSwitch>

                    </Fragment>
                )}
            />

            <h3>Debrief levels</h3>

            <FormSwitch
                disabled={props.saving}
                label="Enable debrief levels:"
                onChange={onAllowDebriefLevels}
                checked={props.settings.debriefLevelsActive}
                loading={props.loading}
                id="input-enable-debrief-levels"
                helperText="Determines whether a debrief level can be set during the debrief process. Setting this to 'off' will remove the debrief level selection."
            ></FormSwitch>

            <ShowHide
                evaluator={props.settings.debriefLevelsActive}
                show={(
                    <Fragment>
                        <FormSwitch
                            disabled={props.saving}
                            label="Make debrief levels mandatory:"
                            onChange={onDebriefLevelsMandatory}
                            checked={props.settings.debriefLevelsMandatory}
                            loading={props.loading}
                            id="input-debrief-levels-mandatory"
                            helperText="If enabled, a debrief level must be selected when performing a debrief."
                        ></FormSwitch>

                        <div className='spacer-bottom'>
                            <h4>Configure debrief level names:</h4>
                            <Table
                                headerData={formatParamTableHeaders()}
                                data={formatParamTable()}
                                loadingData={false}
                                fitToContent={false}
                            />
                            <ShowHide
                                evaluator={!levelsValid}
                                show={(
                                    <div className="form-field">
                                        <FormHelperText loading={props.loading} danger={true}>All levels must have a name</FormHelperText>
                                    </div>
                                )}
                            />
                        </div>

                    </Fragment>
                )}
            />

        </div>
    );
}

DebriefGeneralSettings.defaultProps = {
    disabled: false,
    loading: false,
};

DebriefGeneralSettings.propTypes = {
    settings: PropTypes.object.isRequired,
    saving: PropTypes.bool,
    loading: PropTypes.bool,
    onValidationUpdate: PropTypes.func.isRequired,
    onUpdate: PropTypes.func.isRequired,
};
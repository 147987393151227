import React, { useState, useEffect } from 'react';
import { Intent } from '@blueprintjs/core';

import { Tooltip } from 'components/tooltip';
import { Breadcrumbs } from 'components/navigation';
import { PageRestricted } from 'pages/errors/page-restricted';
import { UserAccess } from 'components/user-access';
import { FormTextInput, FormSelect } from "components/form-fields";
import { ButtonSave, Button } from 'components/buttons'
import { ListingTable } from "components/listing";
import { ShowHide } from 'components/layout';
import { GlobalSearchService, UserService } from 'services';
import { NotificationToaster } from "components/notifications";
import moment from 'moment';


export function GlobalSearch(props) {

    const [hasSearched, setHasSearched] = useState(false);
    const [searchTerm, setSearchTerm] = useState("");
    const [entityType, setEntityType] = useState({});
    const [loadingData, setLoadingData] = useState(false);
    const requiredAction = "Internal";
    const entities = [
        { id: "user", name: "Users" },
        { id: "asset", name: "Assets" },
        { id: "employee", name: "Employees" },
        { id: "job", name: "Jobs" }
    ]
    const [formValid, setFormValid] = useState(false);

    const [tableRows, setTableRows] = useState([]);
    const [tableHeaders, setTableHeaders] = useState([]);
    const [totalRecords, setTotalRecords] = useState(0);
    const noDataMessage = "No results found";
    const defaultPageNumber = 1;
    const defaultPageSize = 25;
    const minSearchLength = 3;
    const minJobSearchLength = 9;

    useEffect(() => {
        if (entityType?.id?.toUpperCase() === 'EMPLOYEE') {
            setTableHeaders(["Identifier", "Name", "Status", "Type", "Reference", "Card number", "License number", "Start date", "End date", "Account", "Location", "Actions"]);
        } else if (entityType?.id?.toUpperCase() === 'ASSET') {
            setTableHeaders(["Identifier", "Reg/ID", "Status", "Type", "Ownership", "Chassis number", "Fleet number", "Start date", "End date", "Account", "Location", "On hold", "Actions"]);
        } else if (entityType?.id?.toUpperCase() === 'JOB') {
            setTableHeaders(["Identifier", "Status", "Product", "Due date", "Scheduled date", "Account", "Location", "Postcode","Internal Id","Asset Type", "Registration", "Actions"]);
        } else {
            setTableHeaders(["Identifier", "Info", "Account", "Actions"]);
        }

        setTableRows([]);
        setTotalRecords(0);
        setHasSearched(false);
    }, [entityType])

    function onPagingChange(newPageNumber, newPageSize) {
        fetchSearchResults(newPageNumber, newPageSize);
    }

    function onSearchTextChange(event) {
        setSearchTerm(event.target.value);
    }

    function onSearch() {
        fetchSearchResults(defaultPageNumber, defaultPageSize);
    }

    function fetchSearchResults(pageNumber, pageSize) {

        setLoadingData(true);

        GlobalSearchService.search(entityType.id, pageSize, pageNumber, searchTerm).then(function (response) {

            setHasSearched(true);
            setLoadingData(false);

            if (response.data != null) {

                var mappedRows = response.data.map(function (row) {
                    return formatSearchResponse(row);
                });

                setTableRows(mappedRows);
            }

            setTotalRecords(response.totalCount);
        }, function (error) {
            NotificationToaster.show(Intent.DANGER, error);
            setLoadingData(false);
        });

    }

    function formatSearchResponse(row) {
        if (entityType.id.toUpperCase() === 'EMPLOYEE') {
            return [
                row.entityId,
                row.fullName,
                row.status.name,
                row.type?.name,
                <span className='break-word'>{row.reference}</span>,
                row.cardNumber,
                row.employeeLicenceNumber,
                formatDate(row.startDate),
                formatDate(row.endDate),
                `${row.accountName} - ${row.memberNumber}`,
                row.location?.name,
                <Tooltip content="Switch to employee profile"><Button onClick={() => switchToAccount(row.accountId, row.entityId, false)} icon="swap-horizontal" iconOnly={true} large={false} minimal={true}></Button></Tooltip>];
        } else if (entityType.id.toUpperCase() === 'ASSET') {
            return [
                row.entityId,
                row.registration,
                row.assetStatus?.name,
                row.assetType?.name,
                row.assetOwnership?.name,
                row.chassisNumber,
                <span className='break-word'>{row.fleetNumber}</span>,
                formatDate(row.startDate),
                formatDate(row.endDate),
                `${row.accountName} - ${row.memberNumber}`,
                row.location?.name,
                formatBool(row.onHold),
                <Tooltip content={switchTooltip("asset", row.onHold)}><Button onClick={() => switchToAccount(row.accountId, row.entityId, row.onHold)} icon="swap-horizontal" iconOnly={true} large={false} minimal={true}></Button></Tooltip>];
        } else if (entityType.id.toUpperCase() === 'JOB') {
            return [
                row.entityId,
                row.jobStatus,
                row.productName,
                formatDate(row.dueDate),
                formatDate(row.scheduledDate),
                `${row.accountName} - ${row.memberNumber}`,
                row.locationName,
                row.postCode,
                row.jobPubsId,
                row.assetType,
                row.registation,
                <Tooltip key="job-tooltip" content="Switch to job details"><Button key="job-button" onClick={() => switchToAccount(row.accountId, row.entityId, false)} icon="swap-horizontal" iconOnly={true} large={false} minimal={true}></Button></Tooltip>];
        } else {
            return [row.entityName, row.entityMetadata, `${row.accountName} - ${row.memberNumber}`, <Tooltip content="Switch to account">
                <Button onClick={() => switchToAccount(row.accountId, row.entityId, false)} icon="swap-horizontal" iconOnly={true} large={false} minimal={true}></Button>
            </Tooltip>];
        }
    }

    function formatDate(date) {
        return date ? moment(date).format('DD/MM/YYYY') : '';
    }

    function formatBool(bool) {
        return bool ? 'True' : 'False';
    }

    function onEntityTypeSelect(item) {
        setEntityType(item);
    }

    function switchTooltip(entityTypeToSwitch, onHold) {
        return onHold ? `Switch to holding area` : `Switch to ${entityTypeToSwitch} profile`
    }

    function switchToAccount(accountId, entityId, onHold) {
        UserService.switchAccount(accountId).then(function () {
            switch (entityType.id.toUpperCase()) {

                case "USER":
                    window.location.href = `/user/${entityId}`;
                    break;
                case "EMPLOYEE":
                    window.location.href = `${window.env.VISION_URL}/#/Drivers/RedirectToProfile?employeeId=${entityId}`;
                    break;
                case "ASSET":
                    if (onHold) {
                        window.location.href = `${window.env.VISION_URL}/#/Alerts/NEWVEHICLE`;
                    } else {
                        window.location.href = `${window.env.VISION_URL}/#/Fleet/RedirectToProfile?assetId=${entityId}`;
                    }
                    break;
                case "JOB":
                    window.location.href = `/job/${entityId}/job-details`;
                    break;
                default:
                    window.location.reload();
                    break;
            }
        });
    }

    useEffect(function () {
        var valid = searchTerm.trim().length > minSearchLength && entityType.id != null;

        //check if the search term is an integer
        if (valid && entityType.id !== undefined && entityType.id.toUpperCase() === 'JOB') {
            valid = !isNaN(searchTerm) && parseInt(searchTerm) === parseFloat(searchTerm) && searchTerm.indexOf('.') === -1 && searchTerm.length <= minJobSearchLength;
        }

        setFormValid(valid);
    }, [searchTerm, entityType])

    return (
        <UserAccess perform={requiredAction}
            yes={() => (
                <div className="row">

                    <Breadcrumbs items={props.breadcrumbs} />

                    <h1>Global Search</h1>
                    <div className="intro-text">
                        <p>This page is designed to help you locate the account/member that certain entities belong to in Vision.</p>
                    </div>

                    <div className="inline-items">
                        <FormTextInput placeholder="Search Term" onChange={onSearchTextChange} value={searchTerm} large disabled={loadingData} id="entity-search-field" />

                        <FormSelect
                            disabled={loadingData}
                            items={entities}
                            onItemSelect={onEntityTypeSelect}
                            selectedValue={entityType.id}
                            large={true}
                            placeholder="Search type"
                        ></FormSelect>

                        <ButtonSave text="Search" icon="search" intent="primary" onClick={onSearch} disabled={loadingData} simpleDisabled={!formValid} />
                    </div>

                    <ShowHide
                        evaluator={hasSearched}
                        show={(
                            <ListingTable
                                id="listing-table-seach"
                                headers={tableHeaders}
                                data={tableRows}
                                totalRecordCount={totalRecords}
                                onPagingChange={onPagingChange}
                                loadingData={loadingData}
                                noDataMessage={noDataMessage}
                                sortable
                            />

                        )}
                    />
                </div>
            )}
            no={() => (
                <PageRestricted />
            )}
        />
    );

}
import { Toaster, Position, Intent } from '@blueprintjs/core';

let toaster;

const NotificationToastSettings = {
    danger: {
        intent: Intent.DANGER,
        icon: "warning-sign"
    },
    warning: {
        intent: Intent.WARNING,
        icon: "warning-sign"
    },
    success: {
        intent: Intent.SUCCESS,
        icon: "tick"
    }
}

export const NotificationToaster = {
    show: (intent, message, usePortal = true) => {
        if (!toaster) {
            toaster = Toaster.create({
                position: Position.BOTTOM_RIGHT
            });
        }
        let toastSettings = NotificationToastSettings[intent];
        if (toastSettings == null) {
            toastSettings = NotificationToastSettings[Intent.SUCCESS];
        }
        toaster.show({
            message: message,
            intent: toastSettings.intent,
            icon: toastSettings.icon,
            usePortal: usePortal
        });
    }
}




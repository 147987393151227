import { FormDateInput } from 'components/form-fields';
import { DateToLocal } from 'components/dates';
import { useValidation } from 'hooks/useValidation';
import moment from 'moment';
import PropTypes from 'prop-types';
import React, { useEffect, useState, Fragment } from 'react';
import classNames from 'classnames';
import { ShowHide } from 'components/layout';
import './Ocr.css';

export function OcrDateRangeEditableLayout(props) {
    const [_, errors, validate] = useValidation();
    const [toDate, setToDate] = useState(null);
    const ThirteenWeekPeriod = 13;

    useEffect(() => {
        validateLayout();
        setToDate(props.layout.answer ? moment(props.layout.answer).add(ThirteenWeekPeriod, 'weeks').format("YYYY-MM-DD"): null);
    }, [props.layout.answer]);

    function validateLayout() {
        const required = props.layout.requiredAnswerCount > 0;
        const rules = [{ fieldName: 'answer', required: required }];
        validate(rules, props.layout);
    }

    function onValueChange(value) {
        const clonedLayout = { ...props.layout };

        clonedLayout.answer = value ? moment(value).format("YYYY-MM-DD") : null;
        setToDate(value ? moment(value).add(ThirteenWeekPeriod, 'weeks').format("YYYY-MM-DD") : null);
        props.onChange(clonedLayout);
    }

    return <Fragment>
        <div className={classNames('form-row field-combination')}>
            <Fragment>
                <div className={classNames({ "hide-in-print": !props.readonly, "hidden": props.readonly })}>
                    <FormDateInput
                        loading={props.loading}
                        onChange={onValueChange}
                        value={props.layout.answer ? moment(props.layout.answer).toString() : null}
                        headingText={props.layout.secondaryLayoutTitle}
                        disabled={props.disabled}
                        dangerHelperText={errors.answer} />

                        <ShowHide
                        evaluator={props.layout.answer}
                            show={(
                                <Fragment>
                                    {<div className={classNames('form-field ocr-shift-left')}><div className={classNames('bp3-input-group')}>to <DateToLocal format='DD/MM/YYYY' >{toDate}</DateToLocal ></div></div>}
                                </Fragment>
                            )}
                        >
                    </ShowHide>
                </div>
                <div className={classNames({ "show-in-print": !props.readonly, "visible": props.readonly, "bp3-skeleton": props.loading })}>
                    <h4>{props.layout.secondaryLayoutTitle}</h4>
                    <div className='form-field'>
                        <span><DateToLocal format='DD/MM/YYYY'>{moment(props.layout.answer).format("YYYY-MM-DD")}</DateToLocal> to {<DateToLocal format='DD/MM/YYYY'>{toDate}</DateToLocal>}</span>
                    </div>
                </div>
            </Fragment>           
        </div>
        </Fragment>;
}

OcrDateRangeEditableLayout.propTypes = {
    loading: PropTypes.bool,
    layout: PropTypes.object.isRequired,
    onChange: PropTypes.func.isRequired,
    disabled: PropTypes.bool,
    readonly: PropTypes.bool
};

OcrDateRangeEditableLayout.defaultProps = {
    loading: false,
    disabled: false,
    readonly: false
};

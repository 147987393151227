import React, { useEffect, useState } from "react";
import PropTypes from 'prop-types';
import { Intent } from "@blueprintjs/core";
import { FormNumericInput, FormSelect, FormSuggest, FormTextInput } from "components/form-fields";
import { AssetService } from "services/AssetService";
import { NotificationToaster } from "components/notifications";
import { CollisionsService } from "services";

export function CollisionSettings(props) {

    const unknownOption = [{ id: null, name: "Unknown" }];
    const yesNoOptions = [
        ...unknownOption,
        { id: true, name: "Yes" },
        { id: false, name: "No" }
    ];
    const [injuryOptions, setInjuryOptions] = useState([]);
    const [damageOptions, setDamageOptions] = useState([]);
    const [assetList, setAssetList] = useState([]);

    useEffect(onInit, []);

    function onInit() {
        CollisionsService.getCollisionOptions().then(function (options) {
            setInjuryOptions([
                ...unknownOption,
                ...options.injuryLevels
            ]);

            setDamageOptions([
                ...unknownOption,
                ...options.damageLevels
            ]);
        });
    }

    function onAssetSearch(query) {

        var searchRequest = {
            requestCount: 50,
            pageNumber: 1,
            searchTerm: query,
            filters: [
                { key: "status", value: "active"}
            ]
        }

        AssetService.searchAssets(searchRequest).then(function (driverResponse) {
            setAssetList(driverResponse.data.map(function (d) {
                return {
                    id: d.assetId,
                    name: `${d.assetRegistration} - ${d.location.name}`
                }
            }));
        }, function () {
            NotificationToaster.show(Intent.WARNING, "Could not search for a specific employee, please try agian.");
            setAssetList([]);
        });
    }


    function onCollisionUpdate(field, value, convertNulls = false) {

        if (value === "" && convertNulls === true) {
            value = null;
        }

        props.onCollisionUpdate(field, value);
    }

    useEffect(function () {

        if (props.selectedAsset != null) {
            setAssetList([props.selectedAsset])
        }

    }, [props.selectedAsset]);

    return (
        <div>
            <FormSuggest
                loading={props.loading}
                disabled={props.saving}
                items={assetList}
                onItemSelect={(item) => onCollisionUpdate("assetId", item.id)}
                headingText="Asset involved in the collision:"
                selectedValue={props.collision.assetId}
                dangerHelperText={props.errors.assetId}
                onFilter={onAssetSearch}
                debounceTime={500}
                id="collision-asset"
            />

            <FormTextInput
                headingText="Area of damage:"
                onChange={(event) => onCollisionUpdate("areaOfDamage", event.target.value)}
                value={props.collision.areaOfDamage}
                loading={props.loading}
                disabled={props.saving}
                maxLength={255}
                id="collision-area-of-damage"
            />

            <FormSelect
                headingText="Was the driver at fault?"
                items={yesNoOptions}
                onItemSelect={(item) => onCollisionUpdate("driverAtFault", item.id)}
                selectedValue={props.collision.driverAtFault}
                loading={props.loading}
                disabled={props.saving}
                id="collision-driver-at-fault"
            />

            <FormSelect
                headingText="Was there third party involvement?"
                items={yesNoOptions}
                onItemSelect={(item) => onCollisionUpdate("thirdPartyInvolvement", item.id)}
                selectedValue={props.collision.thirdPartyInvolvement}
                loading={props.loading}
                disabled={props.saving}
                id="collision-third-party-involvement"
            />

            <FormSelect
                headingText="Injuries obtained:"
                items={injuryOptions}
                onItemSelect={(item) => onCollisionUpdate("collisionInjuryLevelId", item.id)}
                selectedValue={props.collision.collisionInjuryLevelId}
                loading={props.loading}
                disabled={props.saving}
                id="collision-injury-level"
            />

            <FormSelect
                headingText="Amount of damage caused:"
                items={damageOptions}
                onItemSelect={(item) => onCollisionUpdate("collisionDamageLevelId", item.id)}
                selectedValue={props.collision.collisionDamageLevelId}
                loading={props.loading}
                disabled={props.saving}
                id="collision-damage-level"
            />

            <FormNumericInput
                allowDecimal
                min={0}
                max={10000000}
                selectedValue={props.collision.repairCost}
                onValueChange={(item) => onCollisionUpdate("repairCost", item, true)}
                headingText="Repair cost:"
                id="collision-repair-cost"
                prefixText="&pound;"
                loading={props.loading}
                disabled={props.saving}
            />

            <FormNumericInput
                allowDecimal
                min={0}
                max={10000000}
                selectedValue={props.collision.totalCost}
                onValueChange={(item) => onCollisionUpdate("totalCost", item, true)}
                headingText="Total cost:"
                id="collision-total-cost"
                prefixText="&pound;"
                loading={props.loading}
                disabled={props.saving}
            />

        </div>
    );
}

CollisionSettings.defaultProps = {
    selectedAsset: null
}

CollisionSettings.propTypes = {
    loading: PropTypes.bool.isRequired,
    saving: PropTypes.bool.isRequired,
    collision: PropTypes.object.isRequired,
    onCollisionUpdate: PropTypes.func.isRequired,
    errors: PropTypes.array.isRequired,
    selectedAsset: PropTypes.object
};
import React, {useEffect, useState} from "react";
import PropTypes from "prop-types";
import { Dropdown } from "components/dropdown";
import { MenuItem, Intent, Position } from "@blueprintjs/core";
import { Tooltip } from "components/tooltip";
import { AssetService } from "services/AssetService";
import { BlobStorageService } from "services/BlobStorageService";
import { NotificationToaster } from "components/notifications";

export function GetFilesButton(props) {
    const [dropdownItems, setDropdownItems] = useState([]);
    const [fileDownloading, setFileDownloading] = useState(false);

    function throwGetFileError() {
        setFileDownloading(false);
        NotificationToaster.show(Intent.DANGER, "Unable to download files", false);
    }

    function getFile(uploadId) {
        setFileDownloading(true);
        AssetService.getDownloadLinkForVehicleUnitFile(uploadId)
            .then((response) => {
                NotificationToaster.show(Intent.SUCCESS, "Your file will be downloaded shortly", false);
                BlobStorageService.downloadFile(response.storageName, response.containerName, response.fileName, response.token, response.downloadName)
                    .then(() => {
                        setFileDownloading(false);
                    }, throwGetFileError);
            }, throwGetFileError
        );
    }

    useEffect(() => {
        setDropdownItems(props.uploadsList.map((item) => {
            return <MenuItem key={item.uploadId} text={item.date} onClick={() => getFile(item.uploadId)} />
        }));
    }, [props.uploadsList]);

    return (
        <div className="get-files-button">
            <Tooltip content="Click to retrieve the file list" position={Position.TOP}>
                <Dropdown 
                    items={dropdownItems} 
                    intent="Primary" 
                    text="Get Files" 
                    disabled={fileDownloading || props.uploadsList.length === 0 || props.disabled}
                    popoverUsePortal={false} />
            </Tooltip>
        </div>
    );

};

GetFilesButton.defaultProps = {
    uploadsList: [],
    disabled: false
};

GetFilesButton.propTypes = {
    uploadsList : PropTypes.array,
    disabled : PropTypes.bool
};
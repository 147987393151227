import React from 'react';

import 'pages/layouts/Layouts.css';
import { ShowHide } from '../../components/layout';

export function LoginLayout(props) {

    const environment = window.env.DISPLAY_ENVIRONMENT;

    return (
        <div id="login-screen">
            <ShowHide
                evaluator={environment === ''}
                show={(
                    <nav id="login-nav">
                        <img alt="Vision" className="logo" src="https://logukssotemplates.z33.web.core.windows.net/assets/vision-logo.svg" />
                    </nav>
                )}
                hide={(
                    <nav id="login-nav" className={environment}>
                        <img alt="Vision" className="logo" src="/images/vision-logo-white.svg" />
                    </nav>
                )}
            />
            

            <div id="login-container">
                <div className="panel">
                    {props.children}
                </div>
            </div>

        </div>

    );
}
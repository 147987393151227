import React, { useState, useEffect } from 'react';

import { Breadcrumbs } from 'components/navigation';
import { ActiveStatus } from 'components/status';
import { ArrayToEllipsesString } from 'components/formatting';
import { DateToLocal } from 'components/dates';
import { LinkButton } from 'components/buttons'
import { ListingTable } from "components/listing";
import { PageRestricted } from 'pages/errors/page-restricted';
import { UserAccess, UserHasAccess } from 'components/user-access';

import { SystemMessagesService, OptionsService } from 'services';

import axios from 'axios';
import { Link } from 'react-router-dom';

export function SystemMessageListing(props) {

    const pageable = false;

    const [totalRecords, setTotalRecords] = useState(0);
    const [tableRows, setTableRows] = useState([]);
    const [loadingData, setLoadingData] = useState(true);
    const noDataMessage = "There are no system messages, please click 'Add new system message' to get started.";
    const tableHeaders = ["Status", "Message", "Start Date", "End Date", "Message Type"];
    const requiredAction = "Internal";
    const canAccessPage = UserHasAccess(requiredAction);

    useEffect(() => {
        if (loadingData && canAccessPage) {

            axios.all([OptionsService.getSystemMessageTypes(), SystemMessagesService.getAllSystemMessages(false)]).then(axios.spread(function (systemMessageTypesResponse, systemMessagesResponse) {
                let data = [];

                if (systemMessagesResponse != null) {
                    data = systemMessagesResponse.map((d) => {
                        const systemMessageType = systemMessageTypesResponse.filter(x => x.systemMessageTypeId === d.systemMessageTypeID)[0];
                        const messageType = systemMessageType ? systemMessageType.systemMessageTypeName : '';

                        return [
                            <ActiveStatus key={d.systemMessageID} isActive={d.active} />,
                            <Link key={d.systemMessageID} to={`/internal-admin/system-messages/${d.systemMessageID}`}>
                                <ArrayToEllipsesString key={d.systemMessageID} length={100}>{d.message}</ArrayToEllipsesString>
                            </Link>,
                            <DateToLocal key={d.systemMessageID} format="DD/MM/YYYY HH:mm">{d.startDate}</DateToLocal>,
                            <DateToLocal key={d.systemMessageID} format="DD/MM/YYYY HH:mm">{d.endDate}</DateToLocal>,
                            <span key={d.systemMessageID}>{messageType}</span>
                        ];
                    })
                }

                setTableRows(data);
                setTotalRecords(systemMessagesResponse.length);
                setLoadingData(false);
            }))
        }

    }, [loadingData]);


    return (

        <UserAccess perform={requiredAction}
            yes={() => (
                <div className="row">

                    <Breadcrumbs items={props.breadcrumbs} />

                    <h1>System Message Management</h1>
                    <div className="intro-text">
                        <p>From here you can create and manage system messages.</p>
                    </div>

                    <div className="spacer-bottom">
                        <LinkButton intent="primary" text="Add new system message" href="/internal-admin/system-messages/create" id="new-system-message" />
                    </div>

                    <ListingTable
                        id="listing-table-location"
                        headers={tableHeaders}
                        data={tableRows}
                        totalRecordCount={totalRecords}
                        loadingData={loadingData}
                        noDataMessage={noDataMessage}
                        pageable={pageable}
                    />
                </div>
            )}
            no={() => (
                <PageRestricted />
            )}
        />
    );
}
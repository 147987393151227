import ApiService from "services/ApiService";

const badRequestCode = 400;

export const JourneyTypesService = {
    getAllJourneyTypes() {
        return new Promise((resolve, reject) => {
			ApiService.get('/asset/JourneyTypes').then(
				(result) => {
					resolve(result.data);
				},
				(error) => {
					reject(error);
				});
		});
    },
    createJourneyTypes(journeyType) {
        return new Promise((resolve, reject) => {
			ApiService.post('/asset/JourneyTypes', journeyType).then(
				(result) => {
					resolve(result.data);
				},
				(error) => {
					if (error.response.status === badRequestCode) {
						reject(error.response.data);
					} else {
						reject("Unable to create journey type, please try again.");
					}
				});
		});
    },
    updateJourneyTypes(journeyTypeId, journeyType) {
        return new Promise((resolve, reject) => {
			ApiService.put(`/asset/JourneyTypes/${journeyTypeId}`, journeyType).then(
				(result) => {
					resolve(result.data);
				},
				(error) => {
					if (error.response.status === badRequestCode) {
						reject(error.response.data);
					} else {
						reject("Unable to update journey type, please try again.");
					}
				});
		});
    },
    deleteJourneyTypes(journeyTypeId) {
        return new Promise((resolve, reject) => {
			ApiService.delete(`/asset/JourneyTypes/${journeyTypeId}`).then(
				(result) => {
					resolve(result.data);
				},
				(error) => {
					if (error.response.status === badRequestCode) {
						reject(error.response.data);
					} else {
						reject("Unable to delete journey type, please try again.");
					}
				});
		});
    },
	getChangeLog(pageSize, pageNumber, startDate, endDate) {
        return new Promise((resolve, reject) => {
			ApiService.get(`/asset/JourneyTypes/changelog?resultCount=${pageSize}&pageNumber=${pageNumber}&startDate=${startDate}&endDate=${endDate}`).then(
				(result) => {
					resolve(result.data);
				},
				(error) => {
					reject(error);
				});
		});
    }
}
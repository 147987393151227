import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Popover, Position, Classes } from "@blueprintjs/core";
import moment from "moment";
import 'components/form-fields/FormFields.css';
import { DatePicker, Classes as DateClasses } from '@blueprintjs/datetime';
import MomentLocaleUtils from "react-day-picker/moment";
import { ShowHide } from 'components/layout';
import { Button } from 'components/buttons';

export function FormDatePeriodSelector(props) {
    const dateLocale = "en-gb";
    const monthsInPastDefault = 30;
    const yearsInFutureDefault = 5;
    const minDateDefault = moment().subtract(monthsInPastDefault, 'months').toDate();
    const maxDateDefault = moment().add(yearsInFutureDefault, 'years').toDate();
    const [periodStart, setPeriodStart] = useState(null);
    const [periodEnd, setPeriodEnd] = useState(null);
    const [periodOffset, setPeriodOffset] = useState(null);
    const [baseDate, setBaseDate] = useState(props.startDate);
    const [initialOffset, setInitialOffset] = useState(null);
    const [minDate, setMinDate] = useState(minDateDefault);
    const [maxDate, setMaxDate] = useState(maxDateDefault);

    function renderDay(day) {
        const date = day.getDate();
        return (
            <div className={DateClasses.DATEPICKER_DAY_WRAPPER} ><div className={Classes.POPOVER_DISMISS}>{date}</div></div>
        );
    }

    const datePickerPopover = (
        <DatePicker
            value={new Date(periodStart)}
            onChange={onCalendarDaySelect}
            locale={dateLocale}
            localeUtils={MomentLocaleUtils}
            minDate={minDate}
            maxDate={maxDate}
            modifiers={props.calendarModifiers}
            dayPickerProps={{ renderDay: renderDay }}
            highlightCurrentDay={false}
        />
    );

    function movePeriod(amount) {
        setPeriodOffset(offset => (offset + amount));
    }

    function resetPeriod() {
        setPeriodOffset(initialOffset);
        setBaseDate(props.startDate);
    }

    function onDatesChange() {
        if (periodStart != null && periodEnd != null && props.onChange != null) {
            props.onChange(periodStart, periodEnd, periodOffset);
        }
        if (props.periodType === 'day' && periodStart != null && props.onChange != null) {
            props.onChange(periodStart);
        }
    }

    useEffect(function () {
        setBaseDate(props.startDate);
    }, [props.startDate]);

    useEffect(function () {
        if (initialOffset == null) {
            setInitialOffset(props.initialPeriodOffset);
        }
    }, [props.initialPeriodOffset]);
    useEffect(resetPeriod, [initialOffset]);
    useEffect(recalculatePeriod, [baseDate, periodOffset, props.periodsToShow]);
    useEffect(onDatesChange, [periodStart, periodEnd]);

    function recalculatePeriod(selectedDate = null) {
        if (selectedDate != null) {
            setBaseDate(selectedDate);
            setPeriodOffset(initialOffset);
            return;
        }

        if (!baseDate) {
            return;
        }

        var calculationStartPeriod = periodOffset;

        switch (props.periodType) {
            case "445":
                recalculateFourFourFivePeriod(calculationStartPeriod);
                break;
            case "day":
                var initialPeriodStartForDay = moment(baseDate);
                setPeriodStart(initialPeriodStartForDay.add("d", calculationStartPeriod).format(props.dateFormat));
                break;
            case "weeks":
                var initalPeriodStart = moment(baseDate).startOf('isoWeek');
                var periodStartDate = initalPeriodStart.add(calculationStartPeriod, 'W');

                setPeriodStart(periodStartDate.format(props.dateFormat));
                var periodEndDate = moment(periodStartDate);
                periodEndDate.add(props.periodsToShow, 'W');
                periodEndDate.add(-1, 'd');
                setPeriodEnd(periodEndDate.format(props.dateFormat));
                break;
            default:
                //months
                var monthInitialPeriodStart = moment(baseDate).startOf('month');
                var monthPeriodStartDate = monthInitialPeriodStart.add(calculationStartPeriod, 'M');

                setPeriodStart(monthPeriodStartDate.format(props.dateFormat));

                var monthPeriodEndDate = moment(monthPeriodStartDate);
                monthPeriodEndDate.add(props.periodsToShow, 'M');
                monthPeriodEndDate.add(-1, 'd');
                setPeriodEnd(monthPeriodEndDate.format(props.dateFormat));
        }
    }

    function recalculateFourFourFivePeriod(calculationStartPeriod) {
        var startIsoWeek = moment(props.startDate).startOf('isoWeek');
        var firstIsoWeekOfYear = moment(startIsoWeek).isoWeek(1);

        var fourFourFiveStart = moment(firstIsoWeekOfYear);
        var fourFourFiveNextStart = moment(firstIsoWeekOfYear).add(4, 'W');
        var fourFourFiveEnd = moment(fourFourFiveNextStart).add(-1, 'd')

        var periodWeeks = 9;

        //Work out the current period we are in

        while (fourFourFiveEnd.isBefore(startIsoWeek)) {

            fourFourFiveStart = moment(fourFourFiveNextStart);

            if (periodWeeks > 5) {
                fourFourFiveNextStart.add(4, 'w');
                periodWeeks -= 4;
            } else {

                if (fourFourFiveStart.isoWeek() === 48 && fourFourFiveStart.isoWeeksInYear() === 53) {
                    fourFourFiveNextStart.add(6, 'w');
                } else {
                    fourFourFiveNextStart.add(5, 'w');
                }

                periodWeeks = 13;
            }

            fourFourFiveEnd = moment(fourFourFiveNextStart).add(-1, 'd');
        }

        //If going backwards, start subtracting 445 periods

        if (calculationStartPeriod < 0) {

            if (periodWeeks === 13) {
                periodWeeks = 5;
            } else {
                periodWeeks += 4;
            }

            for (var i = 0; i > calculationStartPeriod; i--) {

                fourFourFiveNextStart = moment(fourFourFiveStart);

                if (periodWeeks >= 13) {
                    fourFourFiveStart.add(-5, 'w');
                    if (fourFourFiveStart.isoWeek() === 49 && fourFourFiveStart.isoWeeksInYear() === 53) {
                        fourFourFiveStart.add(-1, 'w');
                    }

                    periodWeeks = 5;
                } else {

                    fourFourFiveStart.add(-4, 'w');
                    periodWeeks += 4;
                }

                fourFourFiveEnd = moment(fourFourFiveNextStart).add(-1, 'd');
            }

        } else if (calculationStartPeriod > 0) {

            //If moving forwards, start adding 445 periods

            for (var i = 0; i < calculationStartPeriod; i++) {

                fourFourFiveStart = moment(fourFourFiveNextStart);

                if (periodWeeks > 5) {
                    fourFourFiveNextStart.add(4, 'w');
                    periodWeeks -= 4;
                } else {

                    if (fourFourFiveStart.isoWeek() === 48 && fourFourFiveStart.isoWeeksInYear() === 53) {
                        fourFourFiveNextStart.add(6, 'w');
                    } else {
                        fourFourFiveNextStart.add(5, 'w');
                    }

                    periodWeeks = 13;
                }

                fourFourFiveEnd = moment(fourFourFiveNextStart).add(-1, 'd');
            }

        }

        setPeriodStart(fourFourFiveStart.format(props.dateFormat));
        setPeriodEnd(fourFourFiveEnd.format(props.dateFormat));
    }

    function setMinDateFromProps() {
        const newMinDate = props.minDate ? props.minDate : minDateDefault;
        setMinDate(newMinDate);
    }
    function setMaxDateFromProps() {
        const newMaxDate = props.maxDate ? props.maxDate : maxDateDefault;
        setMaxDate(newMaxDate);
    }

    function onCalendarDaySelect(selectedDate, isUserChange) {

        if (!isUserChange) {
            return;
        }

        recalculatePeriod(selectedDate);
    }

    useEffect(setMinDateFromProps, [props.minDate]);
    useEffect(setMaxDateFromProps, [props.maxDate]);

    return (
        <div className="form-field">
            <Button text={props.showText ? "Previous" : ""} intent="secondary" large={false} minimal={true} icon="fast-backward" onClick={() => movePeriod(-1)} disabled={props.disabled || (props.minPeriods != null && periodOffset <= props.minPeriods)}></Button>
            <ShowHide
                evaluator={props.showCalendar}
                show={(
                    <Popover content={datePickerPopover} position={Position.BOTTOM} usePortal={props.usePortal} >
                        <Button disabled={props.disabled} icon="calendar" intent="secondary" iconOnly minimal large={false} onClick={() => { }} />
                    </Popover>
                )}
            />
            <ShowHide
                evaluator={props.periodType === 'day'}
                show={<span className="form-period-display">{periodStart}</span>}
                hide={<span className="form-period-display">{periodStart} - {periodEnd}</span>}
            />
            <Button text={props.showText ? "Next" : ""} intent="secondary" large={false} minimal={true} icon="fast-forward" onClick={() => movePeriod(1)} disabled={props.disabled || (props.maxPeriods != null && periodOffset >= props.maxPeriods)}></Button>
            <ShowHide evaluator={props.showResetButton} 
            show={<Button text={props.showText ? "Reset" : ""} intent="secondary" large={false} minimal={true} icon="reset" onClick={resetPeriod} disabled={props.disabled}></Button>}
            />
        </div>
    );
}

FormDatePeriodSelector.defaultProps = {
    id: null,
    startDate: null,
    minPeriods: null,
    maxPeriods: null,
    periodType: "months",
    periodsToShow: 1,
    initialPeriodOffset: 0,
    dateFormat: "DD/MM/YYYY",
    showCalendar: false,
    calendarMinDate: null,
    calendarMaxDate: null,
    calendarModifiers: null,
    showText: true,
    usePortal: true,
    showResetButton: true
};

FormDatePeriodSelector.propTypes = {
    id: PropTypes.string,
    startDate: PropTypes.string,
    minPeriods: PropTypes.number,
    maxPeriods: PropTypes.number,
    periodType: PropTypes.string,
    periodsToShow: PropTypes.number,
    initialPeriodOffset: PropTypes.number,
    onChange: PropTypes.func.isRequired,
    dateFormat: PropTypes.string,
    showCalendar: PropTypes.bool,
    calendarMinDate: PropTypes.instanceOf(Date),
    calendarMaxDate: PropTypes.instanceOf(Date),
    calendarModifiers: PropTypes.object,
    showText: PropTypes.bool,
    usePortal: PropTypes.bool,
    showResetButton: PropTypes.bool
};
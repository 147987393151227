import React, { useState, useEffect, Fragment } from 'react';
import axios from 'axios';
import { useParams } from "react-router-dom";
import { Intent } from '@blueprintjs/core';
import { LocationService } from 'services';
import { NotificationToaster, AlertUnsavedData } from "components/notifications";
import { ButtonSave } from "components/buttons";
import { useDirtyData } from "hooks/useDirtyData";
import { UserAccess } from 'components/user-access';
import { PageRestricted } from 'pages/errors/page-restricted';
import { LocationNotesSettings } from 'pages/locations/shared';
import { ShowHide } from "components/layout";

export function BusinessAreaLocationNotes(props) {

    const requiredAction = "Internal";
    const { id } = useParams();

    const [loading, setLoading] = useState(true);

    const [saving, setSaving] = useState(false);
    const [allowIsDirty, setAllowIsDirty] = useState(false);
    const [businessAreaTabs, setBusinessAreaTabs] = useState([]);
    const [notes, setNotes] = useState([]);
    const [originalNotes, setOriginalNotes] = useState([]);
    const isDataDirty = useDirtyData(originalNotes, notes);

    useEffect(initialLoad, [id]);

    function initialLoad() {
        axios.all([LocationService.getLocationAdditionalInformation(id),
        LocationService.getLocationBusinessAreas()
        ])
            .then(axios.spread(function (additionalInfo, businessAreaResponse) {
                var external = businessAreaResponse.filter(isExternalBusinessArea);
                var tabs = [];
                var initialNotes = [];
                additionalInfo.businessAreaAdditionalDetails.forEach(function (locationBusinessArea) {
                    //For the moment only show Tacho
                    if (locationBusinessArea.locationBusinessAreaId === 1) {
                        var externalbusinessArea = external.find(e => e.locationBusinessAreaId === locationBusinessArea.locationBusinessAreaId);
                        if (externalbusinessArea) {
                            tabs.push({
                                title: `${externalbusinessArea.businessArea} Notes`, key: `${externalbusinessArea.businessArea}`, notes: locationBusinessArea.notes, locationBusinessAreaId: externalbusinessArea.locationBusinessAreaId
                            });
                            initialNotes.push(locationBusinessArea.notes);
                        }
                    }
                });
                setBusinessAreaTabs(tabs);
                setNotes(initialNotes);
                setOriginalNotes(initialNotes);
                setLoading(false);
            }), (error) => {
                NotificationToaster.show(Intent.DANGER, "Could not get the notes. Please refresh the page.");
            });
    }

    function isExternalBusinessArea(businessArea) {
        return !businessArea.internal;
    }


    function saveLocationNotes() {
        setSaving(true);

        var tabNotes = [];
        businessAreaTabs.forEach(object => {

            var tabNote = {
                locationBusinessAreaId: object.locationBusinessAreaId,
                locationId: id,
                notes: object.notes
            }
            tabNotes.push(tabNote);
        })

        LocationService.saveNotes(id, tabNotes).then(
            () => {
                setSaving(false);
                setOriginalNotes(notes);
                NotificationToaster.show(Intent.SUCCESS, "Notes updated successfully.")
            },
            (error) => {
                setSaving(false);
                setAllowIsDirty(true);
                NotificationToaster.show(Intent.DANGER, "Could not save notes. Please try again.");
            }
        );
    }

    function updateNotes(setting) {
        setNotes(setting);
    }

    function updateBusinessAreaTabs(businessAreaTabsUpdate) {
        setBusinessAreaTabs(businessAreaTabsUpdate);
    }

    return (

        <UserAccess perform={requiredAction}
            yes={() => (
                <div className="row">


                    <ShowHide
                        evaluator={businessAreaTabs.length > 0}
                        show={(
                            <Fragment>
                                <LocationNotesSettings
                                    notesSettings={notes}
                                    businessAreaTabs={businessAreaTabs}
                                    onNotesUpdate={updateNotes}
                                    onBusinessAreaTabsUpdate={updateBusinessAreaTabs}
                                    saving={saving}
                                    loading={loading}
                                />

                                <AlertUnsavedData
                                    isDirty={isDataDirty && !allowIsDirty}>
                                </AlertUnsavedData>
                                <ButtonSave
                                    id="location-note-save"
                                    onClick={saveLocationNotes}
                                    disabled={saving}
                                    loading={loading}
                                ></ButtonSave>
                            </Fragment >
                        )}
                        hide={(
                            <div className="spacer-bottom">
                                <div className="spacer-bottom">
                                    <p>There are no notes for the selected business areas.</p>
                                </div>
                            </div>
                        )}
                    />
                </div>

            )}
            no={() => (
                <div className="row">
                    <PageRestricted />
                </div>
            )}
        />
    );
}
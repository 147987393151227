import React, { useState, useEffect, Fragment, useMemo } from 'react';
import axios from 'axios';
import moment from "moment";
import { Intent, Icon } from '@blueprintjs/core';

import { Breadcrumbs } from 'components/navigation';
import { NotificationToaster, AlertUnsavedData } from "components/notifications";
import { LinkButton, Button, ButtonSave } from "components/buttons";
import { ShowHide } from "components/layout";
import { Wizard } from "components/wizard";
import { UserAccess, UserHasAccess } from 'components/user-access';
import { useDirtyData } from 'hooks/useDirtyData';
import { AccountService, LocationService, UserService } from "services";
import { PageRestricted } from 'pages/errors/page-restricted';
import { LocationSettings, LocationAddressSettings, AdditionalInfoSettings, BusinessAreaOperatingHoursSettings, LocationNotesSettings, LocationContactSettings } from 'pages/locations/shared';
import { HelperFunctions } from 'helpers';
import { ListingTable } from "components/listing";

var _ = require('underscore');


export function LocationCreate(props) {

    const isInternal = UserHasAccess("Internal");
    const inductionDateTime = "01 Jan 2000";

    const generalSettingsModel = useMemo(() => {

        if (isInternal) {
            return {
                name: "",
                state: { id: 1 },
                businessAreas: [],
                reference: ""
            };
        }
        else {
            return {
                name: "",
                state: { id: 1 },
                businessAreas: [
                    {
                        "locationBusinessAreaId": 1
                    }
                ],
                reference: ""
            };
        }

    }, []);

    const tachoSettingsModel = useMemo(() => {
        return {
            useTachoAnalysisDefaults: true,
            tachoAnalysisStartDate: null,
            optOutNightWork: null,
            workingTimeReferencePeriod: null,
            workingTimeRollingReference: null,
            workingTimeReferenceFull: null,
            workingTimeReferenceStartDate: null,
            workingTimeVehicleOperation: null,
            analysePeriodOfAvailability: null,
            leadInAdjustment: null
        };
    }, []);

    const addressModel = useMemo(() => {
        return {
            locationId: 0,
            addressLine1: "",
            addressLine2: "",
            addressLine3: "",
            postTown: "",
            county: "",
            postCode: "",
            phoneNumber: ""
        };
    }, []);

    const additionalInfoModel = useMemo(() => {
        return {
            locationId: null,
            weighPadAvailable: null,
            inductionRequired: false,
            inductionDurationTime: moment(inductionDateTime).startOf('day').format(timeFormat),
            inductionTime: 0
        };
    }, []);

    const operatingHoursModel = useMemo(() => {
        return [
            {
                "operatingHourId": -1,
                "locationBusinessAreaId": 1,
                "dayOfWeek": 1,
                "startTime1": null,
                "startTime2": null,
                "endTime1": null,
                "endTime2": null,
                "closedAllDay": false,
                "openAllDay": true,
                "day": 'Monday',
                valid: true,
                errorMessage: '',
                "range2Disabled": true
            },
            {
                "operatingHourId": -2,
                "locationBusinessAreaId": 1,
                "dayOfWeek": 2,
                "startTime1": null,
                "startTime2": null,
                "endTime1": null,
                "endTime2": null,
                "closedAllDay": false,
                "openAllDay": true,
                "day": 'Tuesday',
                valid: true,
                errorMessage: '',
                "range2Disabled": true
            },
            {
                "operatingHourId": -3,
                "locationBusinessAreaId": 1,
                "dayOfWeek": 3,
                "startTime1": null,
                "startTime2": null,
                "endTime1": null,
                "endTime2": null,
                "closedAllDay": false,
                "openAllDay": true,
                "day": 'Wednesday',
                valid: true,
                errorMessage: '',
                "range2Disabled": true
            },
            {
                "operatingHourId": -4,
                "locationBusinessAreaId": 1,
                "dayOfWeek": 4,
                "startTime1": null,
                "startTime2": null,
                "endTime1": null,
                "endTime2": null,
                "closedAllDay": false,
                "openAllDay": true,
                "day": 'Thursday',
                valid: true,
                errorMessage: '',
                "range2Disabled": true
            },
            {
                "operatingHourId": -5,
                "locationBusinessAreaId": 1,
                "dayOfWeek": 5,
                "startTime1": null,
                "startTime2": null,
                "endTime1": null,
                "endTime2": null,
                "closedAllDay": false,
                "openAllDay": true,
                "day": 'Friday',
                valid: true,
                errorMessage: '',
                "range2Disabled": true
            },
            {
                "operatingHourId": -6,
                "locationBusinessAreaId": 1,
                "dayOfWeek": 6,
                "startTime1": null,
                "startTime2": null,
                "endTime1": null,
                "endTime2": null,
                "closedAllDay": false,
                "openAllDay": true,
                "day": 'Saturday',
                valid: true,
                errorMessage: '',
                "range2Disabled": true
            },
            {
                "operatingHourId": 0,
                "locationBusinessAreaId": 1,
                "dayOfWeek": 0,
                "startTime1": null,
                "startTime2": null,
                "endTime1": null,
                "endTime2": null,
                "closedAllDay": false,
                "openAllDay": true,
                "day": 'Sunday',
                valid: true,
                errorMessage: '',
                "range2Disabled": true
            }];
    }, []);

    const contactModel = {
        users: [],
        locationContactTypeId: null
    };
    const timeFormat = "YYYY-MM-DD HH:mm:ss";
    const zeroTime = '2020-01-01 00:00:00'

    const [originalGeneralSettings, setOriginalGeneralSettings] = useState(generalSettingsModel);
    const [originalTachoSettings, setOriginalTachoSettings] = useState(tachoSettingsModel);
    const [originalAddressSettings, setOriginalAddressSettings] = useState(addressModel);
    const [originalAdditionalInfoSettings, setOriginalAdditionalInfoSettings] = useState(additionalInfoModel);
    const [originalBusinessAreaAdditionalDetails, setOriginalBusinessAreaAdditionalDetails] = useState([]);

    const [basicSettingsValid, setBasicSettingsValid] = useState(false);
    const [addressSettingValid, setAddressSettingValid] = useState(false);
    const [additionalInfoValid, setAdditionalInfoValid] = useState(false);
    const [openingHoursValid, setOpeningHoursValid] = useState(false);
    const [contactValid, setContactValid] = useState(false);

    const [areas, setAreas] = useState([]);
    const [businessAreas, setBusinessAreas] = useState([]);
    const [user, setUser] = useState({});
    const [loading, setLoading] = useState(true);
    const [saving, setSaving] = useState(false);
    const [locationCreated, setLocationCreated] = useState(false);
    const [visitingHoursBusinessAreaTabs, setVisitingHoursBusinessAreaTabs] = useState([]);
    const [notesBusinessAreaTabs, setNotesBusinessAreaTabs] = useState([]);
    const [businessAreaAdditionalDetails, setBusinessAreaAdditionalDetails] = useState([]);

    const [accountTachoSettings, setAccountTachoSettings] = useState({});
    const [generalSettings, setGeneralSettings] = useState(originalGeneralSettings);
    const [tachoSettings, setTachoSettings] = useState(originalTachoSettings);
    const [addressSettings, setAddressSettings] = useState(originalAddressSettings);
    const [additionalInfoSettings, setAdditionalInfoSettings] = useState(originalAdditionalInfoSettings);
    const [notes, setNotes] = useState([]);
    const [newContacts, setNewContacts] = useState(contactModel);
    const [contacts, setContacts] = useState([]);
    const [excludeContactTypes, setExcludeContactTypes] = useState([]);
    const [addingContact, setAddingContact] = useState(false);
    const [currentBusinessAreaCount, setCurrentBusinessAreaCount] = useState(-1);
    const [currentAreaCount, setCurrentAreaCount] = useState(-1);

    const areTachoSettingsDirty = useDirtyData(originalTachoSettings, tachoSettings);
    const areGeneralSettingsDirty = useDirtyData(originalGeneralSettings, generalSettings);
    const areAddressSettingsDirty = useDirtyData(originalAddressSettings, addressSettings);
    const areAdditionalInfoSettingsDirty = useDirtyData(originalAdditionalInfoSettings, additionalInfoSettings);
    //We could check each tab, but I think this is enough for now
    const areBusinessAreaAdditionalDetailsDirty = useDirtyData(originalBusinessAreaAdditionalDetails, businessAreaAdditionalDetails);

    const [steps, setSteps] = useState([]);
    const [wizardValid, setWizardValid] = useState(false);

    const noDataMessage = "You have no contacts.";
    const tableHeaders = ["Name", "Contact Type", "Actions"];
    const [tableRows, setTableRows] = useState([]);
    const [totalContacts, setTotalContacts] = useState(0);

    const requiredAction = "Locations:Manage";
    const canAccessPage = UserHasAccess(requiredAction);

    const [wizardStep, setWizardStep] = useState(0);

    var two = 2;
    var three = 3;
    var four = 4;
    var five = 5;
    const basicSettingsStep = 0;
    const addressStep = isInternal ? 1 : -1;
    const visitingHourStep = isInternal ? two: -1;
    var additionalInfoStep = isInternal ? three : -1;
    var contactsStep = isInternal ? four : 1;
    var notesStep = isInternal ? five : two;

    useEffect(initialLoad, []);

    function initialLoad() {

        if (!canAccessPage) {
            return;
        }

        setAllWizardSteps();

        axios.all([UserService.getLoggedInUser(), LocationService.getLocationBusinessAreas(), AccountService.getTachoSettings()]).then(axios.spread(function (userDetails, areasResponse, settings) {

            setUser(userDetails);
            setAreas(areasResponse);

            var allowedAreas = ["Tacho", "VIS", "Audit"];

            var mappedAreas = _.map(areasResponse, function (item) {
                return { id: item.locationBusinessAreaId, name: item.businessArea, checked: false, disabled: !item.internal && allowedAreas.indexOf(item.businessArea) === -1 };
            });

            setBusinessAreas(mappedAreas);
            setAccountTachoSettings(settings);

            let inheritedSettings = {
                ...tachoSettingsModel,
                tachoAnalysisStartDate: settings.tachoAnalysisStartDate,
                optOutNightWork: settings.optOutNightWork,
                workingTimeReferencePeriod: settings.workingTimeReferencePeriod,
                workingTimeRollingReference: settings.workingTimeRollingReference,
                workingTimeReferenceFull: settings.workingTimeReferenceFull,
                workingTimeReferenceStartDate: settings.workingTimeReferenceStartDate,
                workingTimeVehicleOperation: settings.workingTimeVehicleOperation
            };

            setOriginalTachoSettings(inheritedSettings);
            setTachoSettings(inheritedSettings);
            setOriginalAddressSettings(addressModel);
            setAddressSettings(addressModel);
            setAdditionalInfoSettings(additionalInfoModel);
            setOriginalAdditionalInfoSettings(additionalInfoModel);
            setLoading(false);
        },
            () => {
                NotificationToaster.show(Intent.DANGER, "Could not load settings. Please refresh the page.");
            }
        ));
    }

    useEffect(setAllWizardSteps, [generalSettings, accountTachoSettings, tachoSettings, businessAreas, addressSettings, loading, visitingHoursBusinessAreaTabs, businessAreaAdditionalDetails, newContacts, tableRows, contactValid, additionalInfoSettings, addingContact]);

    useEffect(function () {
        //This gets called a lot so we need to make sure we only do this when there is something that has changed
        if (areas.length === 0 || (currentAreaCount === areas.length && currentBusinessAreaCount === generalSettings.businessAreas.length)) {
            return;
        }
        setCurrentAreaCount(areas.length);
        setCurrentBusinessAreaCount(generalSettings.businessAreas.length);

        var externalAreas = areas.filter((item) => !item.internal);

        var clonedVisitingHoursBusinessAreaTabs = HelperFunctions.deepClone(visitingHoursBusinessAreaTabs);
        var clonedNotesBusinessAreaTabs = HelperFunctions.deepClone(notesBusinessAreaTabs);
        var clonedbusinessAreaAdditionalDetails = HelperFunctions.deepClone(businessAreaAdditionalDetails);

        const selectedExternalBusinessAreas = generalSettings.businessAreas.filter((obj1) => {
            return externalAreas.some((obj2) => obj1.locationBusinessAreaId === obj2.locationBusinessAreaId);
        });

        const currentBusinessAreaTabs = clonedNotesBusinessAreaTabs.map((obj) => obj.key);
        clonedVisitingHoursBusinessAreaTabs = clonedVisitingHoursBusinessAreaTabs.filter((obj1) => {
            return selectedExternalBusinessAreas.some((obj2) => obj1.locationBusinessAreaId === obj2.locationBusinessAreaId);
        });
        clonedNotesBusinessAreaTabs = clonedNotesBusinessAreaTabs.filter((obj1) => {
            return selectedExternalBusinessAreas.some((obj2) => obj1.locationBusinessAreaId === obj2.locationBusinessAreaId);
        });
        clonedbusinessAreaAdditionalDetails = clonedbusinessAreaAdditionalDetails.filter((obj1) => {
            return selectedExternalBusinessAreas.some((obj2) => obj1.locationBusinessAreaId === obj2.locationBusinessAreaId);
        });

        // Filter mainCollection based on excludeCollection
        const excludedAreas = areas.filter(mainItem => {
            // Check if mainItem's id is in excludeCollection
            const isExcluded = generalSettings.businessAreas.some(excludeItem => excludeItem.locationBusinessAreaId === mainItem.locationBusinessAreaId);

            // If it is, return false to exclude mainItem from filteredCollection
            // If it's not, return true to keep mainItem in filteredCollection
            return !isExcluded;
        });

        var excludedTypes = excludedAreas.map(fc => fc.businessArea.toUpperCase());
        excludedTypes.push('WALKAROUND');
        setExcludeContactTypes(excludedTypes);

        var tabsToAdd = selectedExternalBusinessAreas.filter(item => currentBusinessAreaTabs.indexOf(item.businessArea) === -1);

        tabsToAdd.forEach(function (tabToAdd) {
            //For the moment we only have tacho visiting hours
            if (tabToAdd.businessArea === "Tacho") {
                clonedVisitingHoursBusinessAreaTabs.push({
                    title: `${tabToAdd.businessArea} Visiting Hours`, key: `${tabToAdd.businessArea}`, operatingHours: operatingHoursModel, locationBusinessAreaId: tabToAdd.locationBusinessAreaId
                });

                clonedNotesBusinessAreaTabs.push({
                    title: `${tabToAdd.businessArea} Notes`, key: `${tabToAdd.businessArea}`, notes: "", locationBusinessAreaId: tabToAdd.locationBusinessAreaId
                });

                clonedbusinessAreaAdditionalDetails.push({
                    locationBusinessAreaId: tabToAdd.locationBusinessAreaId, notes: "", operatingHours: operatingHoursModel
                });
            }
        });
        setVisitingHoursBusinessAreaTabs(clonedVisitingHoursBusinessAreaTabs);
        setNotesBusinessAreaTabs(clonedNotesBusinessAreaTabs);
        setBusinessAreaAdditionalDetails(clonedbusinessAreaAdditionalDetails);
        setOriginalBusinessAreaAdditionalDetails(clonedbusinessAreaAdditionalDetails);

        if (isInternal) {
            additionalInfoStep = clonedVisitingHoursBusinessAreaTabs.length !== 0 ? three : two;
            contactsStep = clonedVisitingHoursBusinessAreaTabs.length !== 0 ? four : three;
            notesStep = clonedVisitingHoursBusinessAreaTabs.length !== 0 ? five : four;
        }

    }, [generalSettings.businessAreas, areas])

    function saveLocation() {
        setSaving(true);

        LocationService.createLocation(generalSettings).then(
            (locationId) => {
                updateLocationAdditionalInfoForSaving(locationId);
                updateOperatingHoursForSaving();
                addressSettings.postCode = String(addressSettings.postCode).toUpperCase();

                var locationUpdates = [LocationService.putTachoSettings(locationId, tachoSettings)];

                if (isInternal && areAdditionalInfoSettingsDirty) {
                    locationUpdates.push(LocationService.saveAdditionalInfo(locationId, additionalInfoSettings));
                }

                if (isInternal && areAddressSettingsDirty) {
                    locationUpdates.push(LocationService.saveAdditionalInfoAddress(locationId, addressSettings));
                }

                if (isInternal && visitingHoursBusinessAreaTabs.length > 0 && areBusinessAreaAdditionalDetailsDirty) {
                    locationUpdates.push(LocationService.saveOperatingHours(locationId, businessAreaAdditionalDetails));
                }

                if (isInternal && notesBusinessAreaTabs.length > 0) {
                    var tabNotes = getTabNotes(locationId);
                    if (tabNotes.length > 0) {
                        locationUpdates.push(LocationService.saveNotes(locationId, getTabNotes(locationId)));
                    }
                }

                if (contacts.length > 0) {
                    var objContacts = contacts.map(function (contact) {
                        return {
                            "UserId": contact.userId,
                            "LocationId": locationId,
                            "LocationContactTypeId": contact.locationContactTypeId
                        };
                    });

                    locationUpdates.push(LocationService.createContacts(objContacts));
                }

                axios.all(locationUpdates).then(function () {
                    setLocationCreated(true);
                    setSaving(false);

                    setOriginalTachoSettings(tachoSettingsModel);
                    setOriginalGeneralSettings(generalSettingsModel);
                }, function () {
                    setSaving(false);
                    setLocationCreated(true);
                    NotificationToaster.show(Intent.WARNING, "Your location has been created, but some settings haven't been saved.");
                });
            },
            (error) => {
                setSaving(false);
                NotificationToaster.show(Intent.DANGER, error);
            }
        );
    }

    function updateLocationAdditionalInfoForSaving(locationId) {
        if (additionalInfoSettings.inductionRequired) {
            var duration = moment(additionalInfoSettings.inductionDurationTime, timeFormat);
            additionalInfoSettings.inductionTime = (duration.hours() * 60) + duration.minutes();
        }
        else {
            additionalInfoSettings.inductionTime = 0;
            additionalInfoSettings.inductionDurationTime = moment(inductionDateTime).startOf('day').format(timeFormat);
        }
        additionalInfoSettings.locationId = locationId;
    }

    function updateOperatingHoursForSaving() {
        businessAreaAdditionalDetails.forEach(function (businessAreaAdditionalDetail) {
            businessAreaAdditionalDetail.operatingHours.forEach(function (operatingHour) {
                //if end date is not set then the only valid condition is start date is not set and so pass null. Otherwise range could be 00:00 to endDate
                operatingHour.startTime1 = isNotSet(operatingHour.startDate) && isNotSet(operatingHour.endDate) ? null : operatingHour.startDate.substr(operatingHour.startDate.length - 8);
                operatingHour.endTime1 = isNotSet(operatingHour.endDate) ? null : operatingHour.endDate.substr(operatingHour.endDate.length - 8);
                operatingHour.startTime2 = isNotSet(operatingHour.startDate1) && isNotSet(operatingHour.endDate1) ? null : operatingHour.startDate1.substr(operatingHour.startDate1.length - 8);
                operatingHour.endTime2 = isNotSet(operatingHour.endDate1) ? null : operatingHour.endDate1.substr(operatingHour.endDate1.length - 8);
            });
        });

    }
    function getTabNotes(locationId) {
        var tabNotes = [];
        notesBusinessAreaTabs.forEach(object => {
            if (object.notes && object.notes.length > 0) {
                var tabNote = {
                    locationBusinessAreaId: object.locationBusinessAreaId,
                    locationId: locationId,
                    notes: object.notes
                }
                tabNotes.push(tabNote);
            }
        })

        return tabNotes;
    }
    function isNotSet(date) {
        return date === undefined || date === null || date === zeroTime;
    }
    function resetComponent() {

        setGeneralSettings(generalSettingsModel);
        initialLoad();

        setSaving(false);
        setLocationCreated(false);
    }

    function noContactsForBusinessArea(businessAreaName) {
        return !contacts.some((item) => item.contactType === businessAreaName);
    }

    function updateGeneralSettings(setting) {
        setGeneralSettings(setting.generalSettings);
        setTachoSettings(setting.tachoSettings);
        setAccountTachoSettings(setting.accountTachoSettings);
    }
    function updateAddressSettings(setting) {
        setAddressSettings(setting);
    }
    function updateAdditionalInfoSettings(setting) {
        setAdditionalInfoSettings(setting);
    }
    function updateOpeningHourSettings(setting) {
        setBusinessAreaAdditionalDetails(setting);
    }
    function updateNotes(setting) {
        setNotes(setting);
    }

    function updateNotesBusinessAreaTabs(businessAreaTabs) {
        setNotesBusinessAreaTabs(businessAreaTabs);
    }

    function updateLocationContactSettings(setting) {
        setNewContacts(setting);
    }

    // set validation
    function onBasicSettingValidationUpdate(isValid) {
        setBasicSettingsValid(isValid);
    }
    function onAddressSettingValidationUpdate(isValid) {
        setAddressSettingValid(isValid)
    }
    function onAdditionalInfoSettingValidationUpdate(isValid) {
        setAdditionalInfoValid(isValid)
    }
    function onOpeningHoursSettingValidationUpdate(isValid) {
        setOpeningHoursValid(isValid)
    }

    function onLocationContactSettingValidationUpdate(isValid) {
        setContactValid(isValid);
    }

    function onWizardChange(step) {
        if (!isInternal && step === addressStep) {
            step = wizardStep === basicSettingsStep ? contactsStep : basicSettingsStep;
        }


        if (visitingHoursBusinessAreaTabs.length === 0 && step === visitingHourStep) {
                step = wizardStep === addressStep ? additionalInfoStep : addressStep;
        }
        setWizardStep(step);
    }

    useEffect(setIfWizardValid, [wizardStep, basicSettingsValid, addressSettingValid, areAddressSettingsDirty, openingHoursValid, additionalInfoValid, areAdditionalInfoSettingsDirty]);

    function setIfWizardValid() {
        var valid = (wizardStep === basicSettingsStep && basicSettingsValid) ||
            (wizardStep === addressStep && (addressSettingValid || !areAddressSettingsDirty)) ||
            (wizardStep === visitingHourStep && openingHoursValid) ||
            (wizardStep === additionalInfoStep && (additionalInfoValid || !areAdditionalInfoSettingsDirty)) ||
            wizardStep === contactsStep ||
            wizardStep === notesStep;
        setWizardValid(valid);
    }
    function deleteUser(userId, locationContactTypeId) {
        var clonedContacts = [
            ...contacts
        ];

        clonedContacts = clonedContacts.filter((item) => item.userId !== userId || item.locationContactTypeId !== locationContactTypeId);
        setContacts(clonedContacts);
        setTotalContacts(clonedContacts.length);
    }

    function saveContact() {
        var clonedContacts = [
            ...contacts
        ];       

        newContacts.users.forEach(function (con) {
            var existingContactidx = clonedContacts.findIndex((item) => item.userId === con.id && item.locationContactTypeId === newContacts.locationContactTypeId);

            if (existingContactidx === -1) {
                clonedContacts.push({ "userId": con.id, "locationContactTypeId": newContacts.locationContactTypeId, "userName": con.name, "contactType": newContacts.contactTypeName });                
            }
            else {
                NotificationToaster.show(Intent.WARNING, 'The user ' + con.name + ' is already added.');
            }

        });
        setAddingContact(false);
        setNewContacts(contactModel);
        setContacts(clonedContacts);
    }

    useEffect(onContactsChange, [contacts]);
    function onContactsChange() {
        let data = [];

        if (contacts.length > 0) {
            setTotalContacts(contacts.length);
            data = contacts.map((d) => {

                return [d.userName, d.contactType,
                    <div className="button-row-small" key={d.userId}>
                    <Button onClick={() => deleteUser(d.userId, d.locationContactTypeId)} icon="trash" key={d.userId} iconOnly={true} text="Delete User" large={false} minimal={true}></Button>
                </div>];
            })
        }

        setTableRows(data);
    }

    function addNewContact() {
        setAddingContact(true);
    }

    function setAllWizardSteps() {
        const stepArray = [
            {
                key: 'step1',
                step: <div>
                    <h1 id="header-new-location">Add New Location</h1>
                    <LocationSettings
                        generalSettings={{ generalSettings: generalSettings, accountTachoSettings: accountTachoSettings, tachoSettings: tachoSettings, businessAreas: businessAreas }}
                        user={user}
                        onSettingsUpdate={updateGeneralSettings}
                        onValidationUpdate={onBasicSettingValidationUpdate}
                        saving={saving}
                        editing={false}
                        loading={loading}
                        canRemoveBusinessArea={noContactsForBusinessArea}
                    />
                </div>
            }
        ];

        if (isInternal) {
            stepArray.push({
                key: 'step2',
                step: <div>
                    <h1 id="header-new-location-address">Location Address</h1>
                    <LocationAddressSettings
                        addressSettings={{ address: addressSettings }}
                        onAddressSettingsUpdate={updateAddressSettings}
                        onValidationUpdate={onAddressSettingValidationUpdate}
                        saving={saving}
                        loading={loading}
                    />
                </div>
            });
        }

        if (isInternal && visitingHoursBusinessAreaTabs.length > 0) {
            stepArray.push({
                key: 'step3',
                step: <div>
                    <h1 id="header-new-visiting-hours">Location Visiting Hours</h1>
                    <BusinessAreaOperatingHoursSettings
                        businessAreaAdditionalDetails={businessAreaAdditionalDetails}
                        businessAreaAdditionalTabs={visitingHoursBusinessAreaTabs}
                        onOperatingHoursSettingsUpdate={updateOpeningHourSettings}
                        onValidationUpdate={onOpeningHoursSettingValidationUpdate}
                        saving={saving}
                        loading={loading}
                    />
                </div>
            });
        }

        if (isInternal) {
            stepArray.push({
                key: 'step4',
                step: <div>
                    <h1 id="header-new-visiting-additional-info">Additional Info</h1>
                    <AdditionalInfoSettings
                        additionalInfoSettings={{ additionalInfo: additionalInfoSettings }}
                        originalInductionTime={'2000-01-01 00:00:00'}
                        onAdditionalInfoSettingsUpdate={updateAdditionalInfoSettings}
                        onValidationUpdate={onAdditionalInfoSettingValidationUpdate}
                        saving={saving}
                        loading={loading}
                    />
                </div>
            });
        }

        stepArray.push({
            key: 'step5',
            step: <div>
                <h1 id="header-new-visiting-contacts">Contacts</h1>
                <ShowHide
                    evaluator={addingContact}
                    show={(
                        <Fragment>
                            <LocationContactSettings
                                contacts={newContacts}
                                excludeContactTypes={excludeContactTypes}
                                locationId={-1}
                                onLocationContactSettingsUpdate={updateLocationContactSettings}
                                onValidationUpdate={onLocationContactSettingValidationUpdate}
                                saving={saving}
                                loading={loading}
                                onContactsUpdate={updateLocationContactSettings}
                            />
                            <div className="form-field">
                                <ButtonSave onClick={saveContact} disabled={saving} simpleDisabled={!contactValid} text="Add" />
                            </div>
                        </Fragment>

                    )}
                    hide={(
                        <Fragment>
                            <div className="intro-text">
                                <p>Configure contacts related to this location. Contacts should be users who are responsible for performing tasks related to the area they have been assigned to.</p>
                            </div>

                            <div className="spacer-bottom">
                                <Button text="Add new contacts" intent="primary" onClick={() => addNewContact()} disabled={props.disabled}></Button>
                            </div>
                        </Fragment>
                    )}
                ></ShowHide>
                <div className="spacer-bottom">
                    <ListingTable
                        id="listing-table-declaration"
                        headers={tableHeaders}
                        data={tableRows}
                        loadingData={loading}
                        noDataMessage={noDataMessage}
                        pageable={false}
                        totalRecordCount={totalContacts}
                        disabled={props.disabled}
                    />
                </div>
            </div>
        });

        if (isInternal && notesBusinessAreaTabs.length > 0) {
            stepArray.push({
                key: 'step6',
                step: <div>
                    <h1 id="header-new-visiting-notes">Internal Notes</h1>
                    <LocationNotesSettings
                        notesSettings={notes}
                        businessAreaTabs={notesBusinessAreaTabs}
                        onNotesUpdate={updateNotes}
                        onBusinessAreaTabsUpdate={updateNotesBusinessAreaTabs}
                        saving={saving}
                        loading={loading}
                    />
                </div>
            });
        }

        setSteps(stepArray);
    }
    return (

        <UserAccess perform={requiredAction}
            yes={() => (
                <div className="row">

                    <Breadcrumbs items={props.breadcrumbs} />

                    <ShowHide
                        evaluator={!locationCreated}
                        show={(
                            <div>
                                <Wizard loading={props.loading} onStepChange={onWizardChange} onFinish={saveLocation} canProceed={wizardValid} disabled={saving}>
                                    {steps.map(x => <Fragment key={x.key}>{x.step}</Fragment>)}
                                </Wizard>
                                <AlertUnsavedData
                                    isDirty={areTachoSettingsDirty || areGeneralSettingsDirty}>
                                </AlertUnsavedData>
                            </div>
                        )}
                        hide={(
                            <div>
                                <div className="spacer-bottom" id="create-success-icon">
                                    <Icon icon="tick-circle" iconSize={40} intent={Intent.SUCCESS} />
                                </div>
                                <h2 id="create-success-name">{generalSettings.name} has been added as a location</h2>
                                <div className="spacer-bottom">
                                    <p>You can now go back to Location Management or add another location.</p>
                                </div>
                                <div className="button-row" id="create-success-buttons">
                                    <LinkButton intent="primary" text="Location management" id="listing-location" href="/location" />
                                    <Button intent="primary" text="Add new location" id="new-location" onClick={resetComponent} />
                                </div>
                            </div>
                        )}
                    ></ShowHide>

                </div>
            )}
            no={() => (
                <PageRestricted />
            )}
        />
    );
}

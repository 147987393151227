import React, { useState, useEffect, useRef } from 'react';
import { Link } from "react-router-dom";
import { Intent } from '@blueprintjs/core';


import { LinkButton, Button } from 'components/buttons'
import { ListingTable } from "components/listing";
import { AlertConfirm, NotificationToaster } from "components/notifications";
import { DateToLocal } from "components/dates";
import { PageRestricted } from 'pages/errors/page-restricted';
import { UserAccess, UserHasAccess } from 'components/user-access';
import { UserService } from 'services';
import { useDebounce } from 'hooks/useDebounce';
import { ShowHide } from 'components/layout';
import { FormTextInput } from 'components/form-fields';


export function ApiApplicationsListing() {

    const defaultRecordSize = 25;
    const debounceTimeout = 750;

    const [selectedAppId, setSelectedAppId] = useState(0);
    const [totalRecords, setTotalRecords] = useState(0);
    const [rawTableData, setRawTableData] = useState([]);
    const [tableRows, setTableRows] = useState([]);
    const [pageNumber, setPageNumber] = useState(1);
    const [pageSize, setPageSize] = useState(defaultRecordSize);
    const [searchTerm, setSearchTerm] = useState("");
    const [loadingData, setLoadingData] = useState(false);
    const [noDataMessage, setNoDataMessage] = useState("You have no applications set up, please click 'Add new application' to get started.");
    const tableHeaders = ["Application Name", "Client ID", "Secret", "Secret Expiry", "Actions"];
    const sortableHeaders = ["Application Name", "Secret Expiry"];
    const [tableSortBy, setTableSortBy] = useState("Application Name");
    const [tableSortDir, setTableSortDir] = useState("A");
    const [showDeleteConfirm, setShowDeleteConfirm] = useState(false);
    const inputSearchRef = useRef(null);

    const debouncedSearchTerm = useDebounce(searchTerm, debounceTimeout);

    const currentPageNumber = useRef();
    const currentPageSize = useRef();
    const currentSearchTerm = useRef();
    const currentTableSortBy = useRef();
    const currentTableSortDir = useRef();

    const requiredAction = "Integrations:Manage";
    const canAccessPage = UserHasAccess(requiredAction);

    useEffect(() => {
        setPageNumber(1);
        setNoDataMessage("No results found, please try a different search term.");
    }, [debouncedSearchTerm]);

    function fetchTableData(forceRefresh) {

        if (canAccessPage) {

            if (
                currentPageNumber.current === pageNumber &&
                currentPageSize.current === pageSize &&
                currentSearchTerm.current === debouncedSearchTerm &&
                currentTableSortBy.current === tableSortBy &&
                currentTableSortDir.current === tableSortDir &&
                !forceRefresh
            ) {
                //If none of the values have changed, then 2 have been updated at once, so prevent the listing from performing another call.
                return;
            }

            currentPageNumber.current = pageNumber;
            currentPageSize.current = pageSize;
            currentSearchTerm.current = debouncedSearchTerm;
            currentTableSortBy.current = tableSortBy;
            currentTableSortDir.current = tableSortDir;

            setLoadingData(true);
            UserService.getApplicationList(pageSize, pageNumber, debouncedSearchTerm, tableSortBy, tableSortDir).then(function (r) {
                setRawTableData(r.data);
                setTotalRecords(r.totalCount);
                setLoadingData(false);
                setTimeout(function () {
                    if (inputSearchRef.current != null) {
                        inputSearchRef.current.focus();
                    }
                });

            });
        }

    }


    useEffect(() => {
        fetchTableData(false);
    }, [pageSize, pageNumber, tableSortBy, tableSortDir, debouncedSearchTerm]);


    useEffect(() => {

        let data = [];

        if (rawTableData != null) {
            data = rawTableData.map((d) => {

                return [
                    <Link to={`/account/integrations/api-applications/${d.userId}`}>{d.applicationName}</Link>,
                    d.clientId,
                    `${d.secretHint}************`,
                    <DateToLocal>{d.secretExpiry}</DateToLocal>,
                    <div className="button-row-small">
                        <Button onClick={() => deleteApplication(d.userId)} icon="trash" iconOnly={true} text="Delete Application" large={false} minimal={true}></Button>
                    </div>
                ];

            })
        }

        setTableRows(data);
    }, [rawTableData]);

    function onTableSort(header, direction) {
        setTableSortDir(direction);
        setTableSortBy(header);
    }

    function onSearchChange(item) {
        setSearchTerm(item.target.value);
    }

    function onPagingChange(newPageNumber, newPageSize) {
        setPageNumber(newPageNumber);
        setPageSize(newPageSize);
    }

    function deleteApplication(id) {
        setSelectedAppId(id);
        setShowDeleteConfirm(true);
    }

    function onDeleteConfirm() {

        setShowDeleteConfirm(false);
        
        UserService.deleteApplication(selectedAppId).then(function () {
            NotificationToaster.show(Intent.SUCCESS, "Application has been successfully deleted");
            fetchTableData(true);
            setSelectedAppId(0);
        }, function () {
            NotificationToaster.show(Intent.DANGER, "Unable to delete the application, please try again.");
            setSelectedAppId(0);
        });

    }

    function onDeleteCancel() {
        setSelectedAppId(0);
        setShowDeleteConfirm(false);
    }

    return (
        <UserAccess perform={requiredAction}
            yes={() => (
                <div>

                    <div className="intro-text">
                        <p>API applications allow you to generate credentials to retreive data from your Vision account by querying Vision's public API service.</p>
                    </div>

                    <div className="spacer-bottom">
                        <LinkButton intent="primary" text="Add new application" href="/account/integrations/api-applications/create" id="new-account-application" loading={loadingData} />
                    </div>

                    <ShowHide
                        evaluator={tableRows.length === 0 && !loadingData && debouncedSearchTerm === ""}
                        hide={(
                            <div className="pull-left">
                                    <FormTextInput inputRef={inputSearchRef} placeholder="Application Search" onChange={onSearchChange} value={searchTerm} large disabled={loadingData} icon="search" id="application-search-field" />
                            </div>
                        )}
                    />

                    <ListingTable
                        id="listing-table-applications"
                        headers={tableHeaders}
                        data={tableRows}
                        totalRecordCount={totalRecords}
                        onPagingChange={onPagingChange}
                        loadingData={loadingData}
                        noDataMessage={noDataMessage}
                        sortable
                        sortableHeaders={sortableHeaders}
                        sortedBy={tableSortBy}
                        sortedDir={tableSortDir}
                        onSort={onTableSort}
                    />

                    <AlertConfirm
                        title="Please confirm"
                        isOpen={showDeleteConfirm}
                        onConfirm={onDeleteConfirm}
                        onCancel={onDeleteCancel}
                    >
                        <p>Are you sure you want to delete this application?</p>
                    </AlertConfirm>

                </div>
            )}
            no={() => (
                <div className="row">
                    <PageRestricted />
                </div>
            )}
        />
    );

}
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Collapsable } from 'components/layout';
import { HelperFunctions } from 'helpers';
import { Button } from 'components/buttons'
import { SelfReportingService } from 'services';
import { ReportParameters } from 'components/settings';

export function ScheduleReportParameters(props) {

    const [fixedStartDate, setFixedStartDate] = useState(null);
    const [fixedEndDate, setFixedEndDate] = useState(null);

    useEffect(setupReportInstances, [props.schedule.reports]);

    function setupReportInstances() {

        var newInstances = [
            ...props.schedule.reportInstances
        ];

        var promiseArray = [];

        props.schedule.reports.forEach((reportId) => {
            if (checkReportInstanceOccurences(reportId) === 0) {
                promiseArray.push(SelfReportingService.getReport(reportId, false).then(r => {

                    var reportParams = r.reportParameters.map(function (rp) {
                        if (rp.parameterType.key === "LocationList" || rp.parameterType.key === "DriverList") {
                            return {
                                ...rp,
                                hidden: true
                            }
                        } else {
                            return rp;
                        }
                    });

                    newInstances.push({
                        reportId: r.reportId,
                        name: r.name,
                        description: r.description,
                        duplicate: false,
                        valid: false,
                        parameters: {
                            reportType: null,
                            parameters: reportParams.map(function (rp) {
                                return { "Key": rp.name, "Value": null, "Type": rp.parameterType.key }
                            })
                        },
                        reportParameters: reportParams,
                        formats: r.reportTypes.map(function (rt) {
                            return { value: rt.id, label: rt.name }
                        })
                    });
                }));
            }
        });


        Promise.all(promiseArray).then(() => {

            //Remove reports that might have been unticked
            newInstances = newInstances.filter(function (instance) {
                return props.schedule.reports.filter(function (r) {
                    return instance.reportId === r;
                }).length > 0;
            });

            props.onScheduleUpdate({
                ...props.schedule,
                reportInstances: newInstances
            });
        });

    }

    function checkReportInstanceOccurences(reportId) {
        var occurences = props.schedule.reportInstances.filter(function (instance) {
            return instance.reportId === reportId;
        });

        return occurences.length;
    }

    function renderHeaderButtons(reportInstance) {
        if (reportInstance.duplicate) {
            return (
                <Button
                    onClick={(e) => {
                        removeReport(reportInstance, e)
                    }}
                    icon="trash"
                    iconOnly={true}
                    text="Delete Report"
                    large={false}
                    minimal={true}>
                </Button>
            );
        } else {
            return (
                <Button
                    onClick={(e) => {
                        duplicateReport(reportInstance, e)
                    }}
                    icon="duplicate"
                    iconOnly={true}
                    text="Duplicate Report"
                    large={false}
                    minimal={true}>
                </Button>
            );
        }
    }

    function duplicateReport(reportInstance, e) {
        e.stopPropagation();

        var newClone = {
            ...HelperFunctions.deepClone(reportInstance),
            duplicate: true,
            name: `${reportInstance.name} (${checkReportInstanceOccurences(reportInstance.reportId)})`
        }

        props.onScheduleUpdate({
            ...props.schedule,
            reportInstances: [
                ...props.schedule.reportInstances,
                newClone
            ]
        });

    }

    function removeReport(reportInstance, e) {
        e.stopPropagation();

        var newInstances = [
            ...props.schedule.reportInstances
        ];

        newInstances = newInstances.filter(function (instance) {
            return instance.name !== reportInstance.name;
        });

        props.onScheduleUpdate({
            ...props.schedule,
            reportInstances: newInstances
        })

    }

    function sortReportsByTitle(a, b) {

        if(a.name && b.name){
            var nameA = a.name.toUpperCase();
            var nameB = b.name.toUpperCase();
            if (nameA < nameB) {
                return -1;
            }
            if (nameA > nameB) {
                return 1;
            }
        }

        // names must be equal
        return 0;

    }

    function onCollapsableClick(index) {
        const cloneSchedule = { ...props.schedule };

        const report = cloneSchedule.reportInstances[index];
        report.isExpanded = !report.isExpanded;

        props.onScheduleUpdate(cloneSchedule);
    }

    function onParamUpdate(report, params) {

        var newInstances = [
            ...props.schedule.reportInstances
        ];

        var instance = newInstances.find(function (f) {
            return f.name === report.name;
        });

        instance.parameters.parameters = params;

        props.onScheduleUpdate({
            ...props.schedule,
            reportInstances: newInstances
        });

    }

    function onFormatUpdate(report, format) {

        var newInstances = [
            ...props.schedule.reportInstances
        ];

        var instance = newInstances.find(function (f) {
            return f.name === report.name;
        });

        instance.parameters.reportType = format;

        props.onScheduleUpdate({
            ...props.schedule,
            reportInstances: newInstances
        });

    }

    function onValidationUpdate(report, isValid) {

        var newInstances = [
            ...props.schedule.reportInstances
        ];

        var instance = newInstances.find(function (f) {
            return f.name === report.name;
        });

        instance.valid = isValid;

        props.onScheduleUpdate({
            ...props.schedule,
            reportInstances: newInstances
        });

    }

    function areParamsValid() {

        var allValid = true;

        props.schedule.reportInstances.forEach(function (ri) {
            allValid = allValid && ri.valid;
        })

        props.onValidationUpdate(allValid);

        if (props.schedule.reoccurrenceSettings.cycle != null && props.schedule.reoccurrenceSettings.cycle.type === "445") {
            setFixedStartDate(props.schedule.reoccurrenceSettings.cycle.frequencyPeriodStart);

            var endDate = props.schedule.reoccurrenceSettings.cycle.frequencyPeriodEnd;

            if (endDate == null) {
                endDate = SelfReportingService.calculate445EndDate(props.schedule.reoccurrenceSettings.cycle.frequencyPeriodStart).format("YYYY-MM-DD");
            }

            setFixedEndDate(endDate);
        }

    }

    useEffect(areParamsValid, [props.schedule]);

    return (
        <div className="spacer-top">
            <h2>Schedule Report Parameters</h2>
            {props.schedule.reportInstances.sort(sortReportsByTitle).map((r, index) => (
                <Collapsable
                    key={index}
                    title={r.name}
                    headerButtons={renderHeaderButtons(r)}
                    disabled={props.disabled}
                    onClick={(() => onCollapsableClick(index))}
                    expanded={r.isExpanded}
                >
                    <div className="collapsable-content">
                        <p>{r.description}</p>
                        <ReportParameters
                            disabled={props.disabled}
                            parameters={r.reportParameters}
                            fileTypes={r.formats}
                            onParamUpdate={(params) => {
                                onParamUpdate(r, params);
                            }}
                            onFormatUpdate={(format) => {
                                onFormatUpdate(r, format);
                            }}
                            onValidationUpdate={(isValid) => {
                                onValidationUpdate(r, isValid);
                            }}
                            filterRequired={false}
                            values={r.parameters}
                            fixedStartDate={fixedStartDate}
                            fixedEndDate={fixedEndDate}
                            editing={props.editing && r.reportInstanceId !== 0 && r.reportInstanceId != null && !r.duplicate}
                        />
                    </div>
                </Collapsable>
            ))}
        </div>
    );
}

ScheduleReportParameters.defaultProps = {
    disabled: false,
    loading: true,
    editing: false
};

ScheduleReportParameters.propTypes = {
    disabled: PropTypes.bool,
    onValidationUpdate: PropTypes.func.isRequired,
    onScheduleUpdate: PropTypes.func.isRequired,
    schedule: PropTypes.object.isRequired,
    loading: PropTypes.bool,
    editing: PropTypes.bool
};
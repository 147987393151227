import React, {useState} from 'react';
import { Modal, NotificationToaster, NotificationInline } from 'components/notifications';
import { Button } from 'components/buttons';
import moment from 'moment';
import { AssetService } from 'services';
import { Intent } from "@blueprintjs/core";
import PropTypes from "prop-types";

export function UnassignDriverModal(props) {

    const [unassignDriverModalIsSaving, setUnassignDriverModalIsSaving] = useState(false);

    const bufferSeconds = 5;
    const apiDateFormat = "YYYY-MM-DDTHH:mm:ss";

    function handleUnassignDriver()
    {
        setUnassignDriverModalIsSaving(true);
        let startDate = moment(props.cardIw.startDate).add(-1 * bufferSeconds, 'second').format(apiDateFormat);
        let endDate = moment(props.cardIw.endDate).add(bufferSeconds, 'second').format(apiDateFormat);
        AssetService.unassignDriverFromCardIw(props.assetId, startDate, endDate)
        .then(() => {
            NotificationToaster.show(Intent.SUCCESS, "Driver unassigned successfully.", false);
            setUnassignDriverModalIsSaving(false);
            props.onClose(true);
            if (props.onSave) {
                props.onSave();
            }
        }, (error) => {
            NotificationToaster.show(Intent.DANGER, error, false);
            setUnassignDriverModalIsSaving(false);
        });
    }
    function cancelUnassignDriver(){
        props.onClose(false);
    }

    return (
        <Modal 
            title="Unassign Driver"
            isOpen={props.isOpen}
            isCloseButtonShown={true}
            onClose={cancelUnassignDriver}
            canClickOutsideToClose={true}
            updateStateOnClose={false}
        >
            <NotificationInline allowClose={false} text={"Unassigning a driver will revert the data to it's original uploaded state."} intent="warning" show={true} />
            <p>Are you sure you want to Unassign the driver from the shift?</p>
            <div className="button-row">
                <Button text="Unassign Driver" intent="Primary" onClick={handleUnassignDriver} disabled={unassignDriverModalIsSaving} />
                <Button text="Cancel" intent="Secondary" onClick={cancelUnassignDriver} disabled={unassignDriverModalIsSaving} />
            </div>
        </Modal>
    );
}

UnassignDriverModal.defaultProps = {

}

UnassignDriverModal.propTypes = {
    cardIw: PropTypes.object.isRequired,
    assetId: PropTypes.string,
    isOpen: PropTypes.bool.isRequired,
    onClose: PropTypes.func.isRequired,
    onSave: PropTypes.func
}
import { UserService } from 'services';
import { EmployeeService } from './EmployeeService';
import { AssetService } from './AssetService';
import { JobService } from './JobService';

export const GlobalSearchService = {

    search(entityType, pageSize, pageNumber, searchTerm) {

        searchTerm = encodeURIComponent(searchTerm);

        switch (entityType.toUpperCase()) {

            case "USER":
                return UserService.internalSearchUsers(pageSize, pageNumber, searchTerm);
            case "EMPLOYEE":
                return EmployeeService.internalSearchEmployees(pageSize, pageNumber, searchTerm);
            case "ASSET":
                return AssetService.internalSearchAssets(pageSize, pageNumber, searchTerm);
            case "JOB":
                return JobService.internalSearchJobs(pageSize, pageNumber, searchTerm);
            default:
                return Promise.reject("Entity type not recognised")
        }

    }
}

import React, { Fragment, useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { DataDisplayTable } from 'components/data-display';
import { FormHeading, FormTextInput } from 'components/form-fields';
import { ListingTable } from 'components/listing';
import { Breadcrumbs } from 'components/navigation';
import { StatusBlock } from 'components/status';
import { TrendStatus } from 'components/status/TrendStatus';
import { HelperFunctions } from 'helpers';
import { useDebounce } from 'hooks/useDebounce';
import { RiskSelector } from 'pages/risk/shared/RiskSelector';
import { useSelector } from 'react-redux';
import { Numeric } from 'components/formatting';
import { RiskService } from 'services/RiskService';

export function RiskListing(props) {
    const defaultRecordSize = 25;
    const debounceTimeout = 750;
    const statsPerRow = 4;

    const [loading, setLoading] = useState(props.loading);
    const [locationsChanged, setLocationsChanged] = useState(true);
    const [periodStats, setPeriodStats] = useState([]);
    const [tableRows, setTableRows] = useState([]);
    const [totalRecords, setTotalRecords] = useState(0);
    const [periodNumber, setPeriodNumber] = useState(0);
    const [locationList, setLocationList] = useState({});

    const [pageNumber, setPageNumber] = useState(1);
    const [pageSize, setPageSize] = useState(defaultRecordSize);
    const [searchTerm, setSearchTerm] = useState("");
    const [tableSortBy, setTableSortBy] = useState(props.sortableHeaders[1]);
    const [tableSortDir, setTableSortDir] = useState("D");

    const debouncedSearchTerm = useDebounce(searchTerm, debounceTimeout);
    const currentPageNumber = useRef();
    const currentPageSize = useRef();
    const currentSearchTerm = useRef();
    const currentTableSortBy = useRef();
    const currentTableSortDir = useRef();

    const searchOptions = useSelector(state => state.filters["riskSearch"]);

    useEffect(function () {
        if (searchOptions.period != null) {
            setPeriodNumber(searchOptions.period);
            setLocationList(searchOptions.locations);
        }
    }, [searchOptions]);

    function onSearchLoad(searchPreferences) {

        setLocationList(searchPreferences.locations);
        setPeriodNumber(searchPreferences.period);
    }

    useEffect(function () {
        if (locationList.length > 0 && periodNumber !== 0) {
            fetchListing(true);
        }
    }, [locationList, periodNumber])

    function fetchListing(forceRefresh = false) {
        if (periodNumber === 0) {
            return;
        }

        if (currentPageNumber.current === pageNumber &&
            currentPageSize.current === pageSize &&
            currentSearchTerm.current === debouncedSearchTerm &&
            currentTableSortBy.current === tableSortBy.sortName &&
            currentTableSortDir.current === tableSortDir &&
            !forceRefresh
        ) {
            return;
        }

        currentPageNumber.current = pageNumber;
        currentPageSize.current = pageSize;
        currentSearchTerm.current = debouncedSearchTerm;
        currentTableSortBy.current = tableSortBy.sortName;
        currentTableSortDir.current = tableSortDir;

        setLoading(true);
        
        RiskService.getEntityListing(pageSize, pageNumber, debouncedSearchTerm, tableSortBy.sortName, tableSortDir, locationList, periodNumber, props.riskEntityController)
            .then(mapListingData)
            .finally(function () {
                setLoading(false);
                setLocationsChanged(false);
            });
    }

    function mapListingData(response) {
        var stats = [
            {
                header: "Risk Period",
                value: <span className="inline-items">
                    <h3>#{response.period?.number}</h3> 
                    <em style={{ 'fontSize': '13px' }}>
                        {HelperFunctions.formatDate(response.period?.startDate, "DD/MM/YYYY")} - {HelperFunctions.formatDate(response.period?.endDate, "DD/MM/YYYY")}
                    </em>
                </span>,
                key: 'risk-period-dates'
            },
            {
                header: "Worst Performing Location",
                value: response.worstLocation,
                key: 'current-events'
            },
            {
                header: "Best Performing Location",
                value: response.bestLocation,
                key: `risk-entity-count`
            }
        ];

        response.bandCounts?.forEach(function (band) {
            let header = `${band.name} `;

            switch (props.riskEntityController) { 
                case "Assets":
                    header += "Assets";
                    break;
                case "Employees":
                    header += "Employees";
                    break;
            }

            stats.push({
                header: header,
                value: <Numeric>{band.count}</Numeric>,
                key: `${HelperFunctions.kebabCase(band.name)}-count`
            })
        });

        setPeriodStats(stats);
        setTotalRecords(response.totalCount);

        setTableRows(response?.data?.map(function (e) {
            var tableRowsTemp = [];
            var entityId = 0;

            switch (props.riskEntityController) {
                case "Assets":
                    tableRowsTemp.push(<Link key={`link-${e.assetId}`} to={`/risk/asset/${e.assetId}`}>{e.assetRegistration}</Link>);
                    entityId = e.assetId;
                    break;
                case "Employees":
                    tableRowsTemp.push(<Link key={`link-${e.employeeId}`} to={`/risk/employee/${e.employeeId}`}>{e.employeeName}</Link>);
                    entityId = e.employeeId;
                    break;
            }

            tableRowsTemp.push(generateStatusBlock(e.score));
            tableRowsTemp.push(<TrendStatus trend={e.trend} key={`trend-${entityId}`} />);
            tableRowsTemp.push(<span key={`position-location-${entityId}`}><Numeric>{e.location?.position}</Numeric>/<Numeric>{e.location?.total}</Numeric></span>);
            tableRowsTemp.push(<span key={`position-company-${entityId}`}><Numeric>{e.company?.position}</Numeric>/<Numeric>{e.company?.total}</Numeric></span>);
            tableRowsTemp.push(e.location?.name);

            return tableRowsTemp;
        }));
    }

    function generateStatusBlock(riskScore) {

        if (riskScore?.band == null) {
            return <StatusBlock background="EFEFEF">No score available</StatusBlock>
        }

        return <StatusBlock background={riskScore.bandColour}><Numeric>{riskScore.total}</Numeric> - {riskScore.band}</StatusBlock>;
    }

    function onPagingChange(newPageNumber, newPageSize) {
        setPageNumber(newPageNumber);
        setPageSize(newPageSize);
    }

    function onTableSort(header, direction) {
        setTableSortDir(direction);
        setTableSortBy(header);
    }

    function onSearchChange(item) {
        setSearchTerm(item.target.value);
    }

    useEffect(() => {
        fetchListing();
    }, [pageSize, pageNumber, tableSortBy, tableSortDir, debouncedSearchTerm]);

    return (
        <Fragment>
            <div className="row">
                <Breadcrumbs items={props.breadcrumbs} />
                <FormHeading headingLevel="h1">{props.title}</FormHeading>

                <RiskSelector onLoaded={onSearchLoad} disabled={loading} />


                <div className="spacer-bottom">
                    <DataDisplayTable
                        data={periodStats}
                        displayInRows={false}
                        loading={loading && locationsChanged}
                        columnsPerRow={statsPerRow}
                    />
                </div>

                <div className="pull-left">
                    <FormTextInput 
                        placeholder={props.searchInputPlaceholder} 
                        disabled={loading} 
                        onChange={onSearchChange} 
                        value={searchTerm} 
                        large 
                        icon="search" 
                        id="risk-entity-search-field" 
                    />
                </div>

                <ListingTable
                    id="listing-table-risk-entity"
                    headers={props.tableHeaders}
                    data={tableRows}
                    totalRecordCount={totalRecords}
                    onPagingChange={onPagingChange}
                    loadingData={loading}
                    noDataMessage={"No results were found for your search criteria"}
                    sortable
                    sortableHeaders={props.sortableHeaders}
                    sortedBy={tableSortBy}
                    sortedDir={tableSortDir}
                    onSort={onTableSort}
                />
            </div>
        </Fragment>   
    );
}

RiskListing.defaultProps = {
    searchInputPlaceholder: "Entity Search"
};

RiskListing.propTypes = {
    title: PropTypes.string,
    riskEntityController: PropTypes.string,
    searchInputPlaceholder: PropTypes.string,
    sortableHeaders: PropTypes.array,
    tableHeaders: PropTypes.array,
    breadcrumbs: PropTypes.array
};
import ApiService from 'services/ApiService';

const badRequestCode = 400;

export const DebriefService = {
	getDebriefSettings() {
		return new Promise((resolve, reject) => {
			ApiService.get(`/employee/DebriefSettings`).then(
				(result) => {
					resolve(result.data);
				},
				(error) => {
					reject(error);
				});
		});
	},
	saveDebriefSettings(settings) {
		return new Promise((resolve, reject) => {
			ApiService.put('/employee/DebriefSettings', settings).then(
				(response) => {
					resolve(response);
				},
				(error) => {
					if (error.response.status === badRequestCode) {
						reject(error.response);
					} else {
						reject("Unable to update settings. Please try again later.");
					}
				});
		});
	}
}


const initialState = {
    selectedGroup: null,
    selectedLocation: null,
    selectedGroupName: "",
    action: null
}

const HierarchyReducer = (state = initialState, action) => {
    switch (action.type) {
        case "ADD_HIERARCHY_GROUP":
            return {
                ...state,
                action: "ADD",
                selectedGroup: action.payload
            };
        case "DELETE_HIERARCHY_GROUP":
            return {
                ...state,
                action: "DELETE",
                selectedGroup: action.payload
            };
        case "EDIT_HIERARCHY_GROUP":
            return {
                ...state,
                action: "EDIT",
                selectedGroup: action.payload.group,
                selectedGroupName: action.payload.groupName
            };
        case "DELETE_LOCATION_FROM_GROUP":
            return {
                ...state,
                action: "DELETELOCATION",
                selectedGroup: action.payload.group,
                selectedLocation: action.payload.location
            };
        case "CLEAR_HIERARCHY_ACTION":
            return {
                ...state,
                ...initialState
            };
        default:
            return state;
    }
}

export default HierarchyReducer;
import ApiService from 'services/ApiService'
import { authentication } from 'components/user-access/Authentication'
import store from 'index';
import { SetUserActions, SetDisplayName, SetUserMemberNumber, SetUserMemberName, SetUserID, SetUserEmail, SetUserAccountId, SetUserPreviousLoginDate } from 'state/actions';
import ApiRequestService from "services/ApiRequestService";
import moment from "moment";

const badRequestCode = 400;
const forbiddenCode = 403;
const dateLocale = "en-gb";

export const UserService = {

	getLoggedInUser() {

		let token = authentication.getAccessToken();

		if (token == null) {
			return {};
		}

		let claims = token.idTokenClaims;

		return new Promise((resolve, reject) => {
			ApiService.get(`/user/Me`).then(
				(result) => {

					var user = {
						...result.data,
						"isInternal": claims.extension_IsInternal
					};

					var userActions = user.actions;
					if(user.isInternal) {
						userActions.push("internal");
					}

					var displayName = user.firstName !== "" && user.lastName !== "" ? `${user.firstName} ${user.lastName}` : user.emailAddress;

					store.dispatch(SetUserActions(userActions));
					store.dispatch(SetDisplayName(displayName));
					store.dispatch(SetUserMemberNumber(user.memberNumber));
					store.dispatch(SetUserMemberName(user.accountName));
					store.dispatch(SetUserID(user.userId));
					store.dispatch(SetUserEmail(user.emailAddress));
					store.dispatch(SetUserAccountId(user.accountId));
					store.dispatch(SetUserPreviousLoginDate(moment.utc(user.previousLoginDate).locale(dateLocale)));
					resolve(user);
				},
				(error) => {
					reject(error);
				});
		});
	},
	getLoggedInUserLocations(businessAreaId, defaultChecked = false, hierarchyAndLocation = false) {
		return new Promise((resolve, reject) => {
			ApiService.get(`/user/me/locationpermissions/${businessAreaId}`).then(
				(result) => {
					var mappedPermissions = result.data.map(function (g) {
						return mapUserLocations(g, defaultChecked)
					});

					if (hierarchyAndLocation && result?.data?.length > 0) {
						//This will return an object containing both the hierarchy list and the straight location list
						var mappedPermissionsNoHierarchy = result.data.map(function (g) {
							return mapUserLocationsNoHierarchy(g, defaultChecked)
						});

						resolve({
							location: mappedPermissionsNoHierarchy,
							hierarchy: mappedPermissions
						});
					} else {
						resolve(mappedPermissions);
                    }
				},
				(error) => {
					reject(error.response);
				});
		});
    },
	getUserBasicInfo(id) {
		return new Promise((resolve, reject) => {
			ApiService.get(`/user/users/${id}`).then(
				(result) => {
					resolve(result.data);
				},
				(error) => {
					reject(error.response);
				});
		});
	},
	putUserBasicInfo(user, ownUserAccount = false) {
		return new Promise((resolve, reject) => {

			var url = ownUserAccount ? '/user/me' : `/user/users/${user.userId}`

			ApiService.put(url, user).then(
				(result) => {
					resolve(result.data);
				},
				(error) => {
					reject(error);
				});
		});
	},
	createUser(user) {
		return new Promise((resolve, reject) => {
			ApiService.post(`/user/users`, user).then(
				(result) => {
					resolve(result.data);
				},
				(error) => {
					if (error.response.status === badRequestCode) {
						reject(error.response.data);
					} else {
						reject("Unable to create user, please try again.");
					}
				});
		});
	},
	deleteUser(userId) {
		return new Promise((resolve, reject) => {
			ApiService.delete(`/user/users/${userId}`).then(
				(result) => {
					resolve(result.data);
				},
				() => {
					reject("Unable to delete the user, please try again.");
				});
		});
	},
	switchAccount(accountId) {
		return new Promise((resolve, reject) => {
			ApiService.put(`/user/Account/Switch?accountId=${accountId}`).then(
				(result) => {
					resolve();
				},
				(error) => {
					reject(error.response);
				});
		});
	},
	getRoles() {
		return new Promise((resolve, reject) => {
			ApiService.get(`/user/Roles`).then(
				(result) => {
					resolve(result.data);
				},
				(error) => {
					reject(error.response);
				});
		});
	},
	getActions() {
		return new Promise((resolve, reject) => {
			ApiService.get(`/user/Actions`).then(
				(result) => {
					resolve(result.data);
				},
				(error) => {
					reject(error.response);
				});
		});
	},
	getActionRestrictions(userId) {
		return new Promise((resolve, reject) => {
			ApiService.get(`/user/Actions/Restrictions/${userId}`).then(
				(result) => {
					resolve(result.data);
				},
				(error) => {
					reject(error.response);
				});
		});
	},
	restrictActions(userId, actions) {
		return new Promise((resolve, reject) => {
			ApiService.put(`/user/Actions/Restrictions/${userId}`, actions).then(
				(result) => {
					resolve(result.data);
				},
				(error) => {
					reject(error.response);
				});
		});
	},
	getChangelog(locationId, pageSize, pageNumber, startDate, endDate) {
		return new Promise((resolve, reject) => {
			ApiService.get(`/user/changelog/${locationId}?resultCount=${pageSize}&pageNumber=${pageNumber}&startDate=${startDate}&endDate=${endDate}`).then(
				(result) => {
					resolve(result.data);
				},
				(error) => {
					reject(error);
				});
		});

	},
	getUserList(pageSize, pageNumber, searchTerm, sortField, sortDir, filters) {
		return ApiRequestService.get('user', `Users?requestCount=${pageSize}&pageNumber=${pageNumber}&searchTerm=${encodeURIComponent(searchTerm)}&sortField=${encodeURI(sortField)}&sortDirection=${sortDir}&${setUpFilters(filters)}`);
	},
	getUserListWebsite(pageSize, pageNumber, searchTerm, sortField, sortDir, filters) {
		return ApiRequestService.get('user', `Users/Website?requestCount=${pageSize}&pageNumber=${pageNumber}&searchTerm=${encodeURIComponent(searchTerm)}&sortField=${encodeURI(sortField)}&sortDirection=${sortDir}&${setUpFilters(filters)}`);
	},
	getUsersWithAction(groupName) {
		return new Promise((resolve, reject) => {
			ApiRequestService.get('user', `Users/${groupName}/UsersInGroup`).then(
				(result) => {
					resolve(result);
				},
				(error) => {
					reject(error);
				});
		});
	},
	getExternalContactsEmailList(pageSize, searchTerm) {
		return ApiRequestService.get('user', `ExternalContact/GetEmailList?requestCount=${pageSize}&searchTerm=${encodeURIComponent(searchTerm)}`);
	},
	getAccountAccess(id) {
		return new Promise((resolve, reject) => {
			ApiService.get(`/user/AccountAccess/${id}`).then(
				(result) => {
					resolve(result.data);
				},
				(error) => {
					reject(error.response);
				});
		});
	},
	getAllAccounts(invalidPermission) {
		
		if(!invalidPermission){
			return new Promise((resolve, reject) => {
				ApiService.get(`/user/me/Accounts`).then(
					(result) => {
						resolve(result.data);
					},
					(error) => {
						reject(error.response);
					});
			});
		} else {
			return new Promise((resolve) => {
				resolve();
			});
		}
	},
	putAccountAccess(userId, accountAccess) {
		return new Promise((resolve, reject) => {
			ApiService.put(`/user/AccountAccess/${userId}`, accountAccess).then(
				(result) => {
					resolve(result.data);
				},
				(error) => {
					reject(error);
				});
		});
	},
	getUserPreferences(userId, ownUserAccount = false) {

		var url = ownUserAccount ? '/user/me/Preferences' : `/user/Preferences/${userId}`;

		return new Promise((resolve, reject) => {
			ApiService.get(url).then(
				(result) => {
					resolve(result.data);
				},
				(error) => {
					reject(error.response);
				});
		});
	},
	saveUserPreferences(userId, preferences, ownUserAccount = false) {

		var url = ownUserAccount ? '/user/me/Preferences' : `/user/Preferences/${userId}`;

		return new Promise((resolve, reject) => {
			ApiService.put(url, preferences).then(
				(result) => {
					resolve(result.data);
				},
				(error) => {
					reject(error);
				});
		});
	},
	confirmUser(userId, confirmRequest) {
		return new Promise((resolve, reject) => {
			ApiService.put(`/user/Register/${userId}`, confirmRequest).then(
				(result) => {
					resolve(result.data);
				},
				(error) => {
					reject(error.response);
				});
		});
	},
	requestReset(email) {
		return new Promise((resolve, reject) => {
			ApiService.post(`/user/PasswordReset`, { emailAddress: email }).then(
				(result) => {
					resolve(result.data);
				},
				(error) => {
					reject(error.response);
				});
		});
	},
	resetPassword(userId, confirmRequest) {
		return new Promise((resolve, reject) => {
			ApiService.put(`/user/PasswordReset/${userId}`, confirmRequest).then(
				(result) => {
					resolve(result.data);
				},
				(error) => {
					reject(error.response);
				});
		});
	},
	registerDriver(user) {
		return new Promise((resolve, reject) => {
			ApiService.post(`/user/Drivers`, user).then(
				(result) => {
					resolve(result.data);
				},
				(error) => {
					reject(error.response);
				});
		});
	},
	verifyExternalContact(externalContactId, verificationRequest) {
		return new Promise((resolve, reject) => {
			ApiService.patch(`/user/Register/${externalContactId}`, verificationRequest).then(
				(result) => {
					resolve(result.data);
				},
				(error) => {
					reject(error.response);
				});
		});
	},
	bulkMoveUsers(accountId) {
		return new Promise((resolve, reject) => {
			ApiService.post(`/user/BulkMove`, {
				accountId: accountId
			}).then(
				(result) => {
					resolve(result.data);
				},
				(error) => {
					reject(error.response);
				});
		});
    },
	signOut() {

		ApiService.delete(`/user/Session`).then(
			() => {
				authentication.signOut();
			});
	},
	getLocationPermissions(userId) {
		return new Promise((resolve, reject) => {
			ApiService.get(`/user/LocationPermissions/${userId}`).then(
				(result) => {
					resolve(result.data);
				},
				(error) => {
					reject(error);
				});
		});
	},
	updateLocationPermissions(userId, permissions) {
		return new Promise((resolve, reject) => {
			ApiService.put(`/user/LocationPermissions/${userId}`, permissions)
				.then(function (response) {
					resolve(response);
				})
				.catch(function (error) {
					if (error.response.status === badRequestCode) {
						reject(error.response.data);
					} else {
						reject("Unable to update permissions. Please try again later.");
					}
				});
		});
	},
	internalSearchUsers(pageSize, pageNumber, searchTerm) {
		return new Promise((resolve, reject) => {
			ApiService.get(`/user/Search?requestCount=${pageSize}&pageNumber=${pageNumber}&searchTerm=${searchTerm}`)
				.then(function (response) {
					resolve(response.data);
				})
				.catch(function () {
					reject("Unable to search users. Please try again.");
				});
		});
	},
	recalculatePrimaryContacts() {
		return ApiRequestService.post('user', `Users/RecalculatePrimaryContacts`);
    },
	updateEmailAddress(userId, request) {
		return ApiRequestService.patch('user', `Users/${userId}/EmailAddress`, request).catch(function (error){
			if (error.status !== badRequestCode && error.status !== forbiddenCode) {
				error.data = "Unable to update the email address, please try again.";
			}

			throw (error.data);
		});
	},
	getApplicationList(pageSize, pageNumber, searchTerm, sortField, sortDir) {

		return new Promise((resolve, reject) => {
			ApiService.get(`/user/Applications?requestCount=${pageSize}&pageNumber=${pageNumber}&searchTerm=${encodeURIComponent(searchTerm)}&sortField=${encodeURI(sortField)}&sortDirection=${sortDir}`).then(
				(result) => {
					resolve(result.data);
				},
				(error) => {
					reject(error);
				});
		});
	},
	getApplication(appId) {
		return new Promise((resolve, reject) => {
			ApiService.get(`/user/Applications/${appId}`).then(
				(result) => {
					resolve(result.data);
				},
				(error) => {
					reject(error.response);
				});
		});
    },
	createApplication(app) {
		return new Promise((resolve, reject) => {
			ApiService.post(`/user/applications`, app).then(
				(result) => {
					resolve(result.data);
				},
				(error) => {
					if (error.response.status === badRequestCode) {
						reject(error.response.data);
					} else {
						reject("Unable to create application, please try again.");
					}
				});
		});
	},
	saveApplication(appId, app) {
		return new Promise((resolve, reject) => {
			ApiService.put(`/user/applications/${appId}`, app).then(
				(result) => {
					resolve(result.data);
				},
				(error) => {
					if (error.response.status === badRequestCode) {
						reject(error.response.data);
					} else {
						reject("Unable to save application, please try again.");
					}
				});
		});
    },
	deleteApplication(appId) {
		return new Promise((resolve, reject) => {
			ApiService.delete(`/user/applications/${appId}`).then(
				(result) => {
					resolve(result.data);
				},
				() => {
					reject("Unable to delete the application, please try again.");
				});
		});
	},
	generateApplicationSecret(appId) {
		return new Promise((resolve, reject) => {
			ApiService.post(`/user/applications/${appId}/secret`).then(
				(result) => {
					resolve(result.data);
				},
				() => {
					reject("Could not create a new secret, please try again.");
				});
		});
    }
}


function getAllLocations(locations, group, defaultChecked) {

	if (group.groups != null) {
		group.groups.map(function (g) {
			return getAllLocations(locations, g, defaultChecked);
		});
	}

	if (group.locations !== null) {
		locations.push(...group.locations.filter(function (l) {
			//We want a distinct list
			return !locations.some(loc => loc.id === l.id)
		}).map(function (l) {
			return {
				...l,
				type: "item",
				checked: defaultChecked
			}
		}));
	}

	return locations;
}


function mapUserLocationsNoHierarchy(group, defaultChecked) {

	var locations = getAllLocations([], group, defaultChecked);

	//Need to reorder the locations
	locations = locations.sort((a, b) => {
		if (a.name > b.name) return 1;
		if (a.name < b.name) return -1;
		return 0;
	});

	return {
		name: group.hierarchyGroupName,
		id: group.hierarchyGroupId,
		items: locations,
		expanded: true,
		type: "group",
		checked: defaultChecked
	}
}

function mapUserLocations(group, defaultChecked) {

	var childGroups = [];

	if (group.groups != null) {
		childGroups = group.groups.map(function (g) {
			return mapUserLocations(g, defaultChecked);
		});
	}

	return {
		name: group.hierarchyGroupName,
		id: group.hierarchyGroupId,
		items: [
			...childGroups,
			...(group.locations == null ? [] : group.locations.map(function (l) {
				return {
					...l,
					type: "item",
					checked: defaultChecked
                }
			}))
		],
		expanded: true,
		type: "group",
		checked: defaultChecked
	}
}

function setUpFilters(filters) {
	var queryFilters = "";
	filters.forEach(function (element, index) {
		queryFilters += `filters[${index}].Key=${encodeURIComponent(element.name)}&filters[${index}].Value=${encodeURIComponent(element.value)}&`
	});

	return queryFilters;
}
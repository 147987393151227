import React, { useState } from "react";
import PropTypes from "prop-types";
import { Modal, NotificationToaster } from "components/notifications";
import { Button } from "components/buttons";
import { AssetService } from "services";
import { Intent } from "@blueprintjs/core";

export function UnassignJourneyModal(props) {
    const { isOpen, onClose, assetId, cardIw } = props;
    const [isSaving, setIsSaving] = useState(false);

    function unassignJourney() {
        setIsSaving(true);
        AssetService.unassignJourney(assetId, cardIw.assignedJourneysId)
            .then(() => {
                NotificationToaster.show(Intent.SUCCESS, "Journey unassigned successfully.");
                onClose();
                if (props.onSave) {
                    props.onSave();
                }
            }, () => {
                NotificationToaster.show(Intent.DANGER, "Unable to unassign journey. Please try again later.");
            }).finally(() => {
                setIsSaving(false);
            });
    }

    function closeUnassignJourneyModal() {
        setIsSaving(false);
        onClose();
    }

    return (
        <Modal
            title="Unassign Journey"
            isOpen={isOpen}
            isCloseButtonShown={true}
            onClose={closeUnassignJourneyModal}
            canClickOutsideToClose={true}
            updateStateOnClose={false}
        >
            <p>Are you sure you want to Unassign the journey from the shift?</p>
            <div className="button-row">
                <Button text="Unassign Journey" intent="Primary" onClick={unassignJourney} disabled={isSaving} />
                <Button text="Cancel" intent="Secondary" onClick={closeUnassignJourneyModal} disabled={isSaving} />
            </div>
        </Modal>
    );
}

UnassignJourneyModal.defaultProps = {

}

UnassignJourneyModal.propTypes = {
    isOpen: PropTypes.bool.isRequired,
    onClose: PropTypes.func.isRequired,
    assetId: PropTypes.string.isRequired,
    cardIw: PropTypes.object.isRequired,
    onSave: PropTypes.func
}
import React, { useState, useEffect, Fragment } from 'react';
import { useParams } from "react-router-dom";
import { Intent } from '@blueprintjs/core';
import { LocationService } from 'services';
import { NotificationToaster, AlertUnsavedData } from "components/notifications";
import { ButtonSave } from "components/buttons";
import { useDirtyData } from "hooks/useDirtyData";
import { UserAccess } from 'components/user-access';
import { PageRestricted } from 'pages/errors/page-restricted';
import { LocationAddressSettings } from 'pages/locations/shared';
import PropTypes from 'prop-types';
import { ShowHide } from "components/layout";

var _ = require('underscore');

export function LocationAddress(props) {

    const requiredAction = "Internal";
    const { id } = useParams();

    const addressModel = {
        locationId: 0,
        addressLine1: "",
        addressLine2: "",
        addressLine3: "",
        postTown: "",
        county: "",
        postCode: "",
        phoneNumber: ""
    };

    const [address, setAddress] = useState(addressModel);
    const [originalAddress, setOriginalAddress] = useState(address);
    const [loading, setLoading] = useState(true);
    const [isValid, setIsValid] = useState(false);
    const [saving, setSaving] = useState(false);
    const [allowIsDirty, setAllowIsDirty] = useState(false);

    const isDataDirty = useDirtyData(originalAddress, address);

    useEffect(initialLoad, []);

    function initialLoad() {
        LocationService.getLocationAdditionalInformation(id).then(
            (locationAdditionalInfo) => {
                if (locationAdditionalInfo.address) {
                    setAddress(locationAdditionalInfo.address);
                    setOriginalAddress(_.extend({}, locationAdditionalInfo.address));
                }

                setLoading(false);
            },
            (error) => {
                NotificationToaster.show(Intent.DANGER, "Could not get the address information. Please refresh the page.")
            }
        );
    }

    function saveLocationAddress() {
        setSaving(true);
        if (!isValid) {
            setSaving(false);
            NotificationToaster.show(Intent.WARNING, "There are errors on the page that need your attention");
            return;
        }

        address.postCode = String(address.postCode).toUpperCase();

        LocationService.saveAdditionalInfoAddress(id, address).then(
            () => {
                setSaving(false);
                setOriginalAddress(_.extend({}, address));

                NotificationToaster.show(Intent.SUCCESS, "Address updated successfully.")
            },
            (error) => {
                setSaving(false);
                //For Validation errors this doesn't return a useful message, just System error, so won't bother checking.
                setAllowIsDirty(true);
                NotificationToaster.show(Intent.DANGER, "Could not save settings. Please try again.");
            }
        );
    }

    function updateAddressSettings(setting) {
        setAddress(setting);
    }

    function onAddressSettingValidationUpdate(onIsValid) {
        setIsValid(onIsValid)
    }

    return (

        <UserAccess perform={requiredAction}
            yes={() => (
                <div className="row">
                    <Fragment>
                        <h2>Location Address</h2>
                        <LocationAddressSettings
                            addressSettings={{ address }}
                            onAddressSettingsUpdate={updateAddressSettings}
                            onValidationUpdate={onAddressSettingValidationUpdate}
                            saving={saving}
                            loading={loading}
                            readOnly={props.readOnly}
                        />
                        <AlertUnsavedData
                            isDirty={isDataDirty && !allowIsDirty}>
                        </AlertUnsavedData>
                        <ShowHide
                            evaluator={props.readonly}
                            hide={(
                                <ButtonSave
                                    id="location-address-save"
                                    onClick={saveLocationAddress}
                                    disabled={saving}
                                    loading={loading}
                                ></ButtonSave>
                            )}
                        >
                        </ShowHide>

                    </Fragment>
                </div>
            )}
            no={() => (
                <div className="row">
                    <PageRestricted />
                </div>
            )}
        />
    );
}
LocationAddress.defaultProps = {
    readOnly: false
}

LocationAddress.propTypes = {
    readOnly: PropTypes.bool
};
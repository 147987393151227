import React, { useState, useEffect } from 'react';
import axios from 'axios';

import { Intent } from '@blueprintjs/core';
import { ButtonSave } from "components/buttons";
import { NotificationToaster, AlertUnsavedData, AlertBasic } from "components/notifications";
import { FormTextInput } from "components/form-fields";
import { HierarchyBusinessAreas } from 'pages/locations/hierarchies/edit/business-areas';
import { HierarchyFeatures } from 'pages/locations/hierarchies/edit/features';
import { useDirtyData } from "hooks/useDirtyData";
import { HierarchyService, LocationService } from "services";
import { UserPermissionsSelection } from "pages/locations/hierarchies/edit/shared";
import { ShowHide } from 'components/layout';

export function HierarchySettings(props) {

    const hierarchyModel = {
        hierarchyName: "",
        businessAreas: [],
        hierarchyFeatures: []
    };

    const [hierarchySettings, setHierarchySettings] = useState(hierarchyModel);
    const [originalValues, setOriginalValues] = useState({});
    const [saving, setSaving] = useState(false);
    const [featurePermissionsChanging, setFeaturePermissionsChanging] = useState(false);
    const [featurePermissionSwitchedOff, setFeaturePermissionSwitchedOff] = useState("");
    const [inUseLocations, setInUseLocations] = useState([]);
    const [convertUserPermissions, setConvertUserPermissions] = useState(null);
    const [showPermissionFeatureAlert, setShowPermissionFeatureAlert] = useState(false);

    const isDataDirty = useDirtyData(originalValues, hierarchySettings);

    useEffect(function () {

        var newHierarchy = {
            ...props.hierarchy
        };

        disableInUseBusinessAreas(newHierarchy);

        setHierarchySettings(newHierarchy);
        setOriginalValues(newHierarchy);
    }, [props.hierarchy]);

    useEffect(function () {

        //Get the structure on load we so can pull out the used locations

        axios.all([
            HierarchyService.getHierarchyStructure(props.hierarchyId),
            LocationService.getLocationList(0, 1, "", "", "")
        ]).then(axios.spread(function (structureResponse, locationResponse) {

            var usedLocations = [];
            usedLocations = getInUseLocations(structureResponse, usedLocations);

            usedLocations = locationResponse.data.filter(function (location) {
                return usedLocations.some(function (ul) {
                    return location.id === ul.locationId;
                })
            });

            setInUseLocations(usedLocations);

        }));

    }, []);

    function getInUseLocations(hierarchy, usedLocations) {

        for (var i = 0; i < hierarchy.length; i++) {

            var currentGroup = hierarchy[i];

            if (currentGroup.groups && currentGroup.groups.length > 0) {
                usedLocations = getInUseLocations(currentGroup.groups, usedLocations);
            }

            if (currentGroup.locations && currentGroup.locations.length > 0) {
                usedLocations = usedLocations.concat(currentGroup.locations);
            }
        }

        return usedLocations;

    }

    function onHierarchySettingsUpdate(newHierarchySettings) {

        if (originalValues.hierarchyFeatures != null) {
            var oldPermissionFeatures = originalValues.hierarchyFeatures.filter(filterByPermissionsFeature);
            var newPermissionFeatures = newHierarchySettings.hierarchyFeatures.filter(filterByPermissionsFeature);

            //Check all the old permissions are still in the new one, otherwise show banner
            var downgradingPermissions = oldPermissionFeatures.filter(function (feature) {
                return !newPermissionFeatures.some(function (newFeature) {
                    return newFeature.hierarchyFeatureId === feature.hierarchyFeatureId;
                });
            });

            var switchedOffFeaturesNames = downgradingPermissions.map(function (f) {
                return f.featureName;
            }).join(", ");

            setFeaturePermissionSwitchedOff(switchedOffFeaturesNames);
            setFeaturePermissionsChanging(downgradingPermissions.length > 0);
            setShowPermissionFeatureAlert(downgradingPermissions.length > 0);
        }

        disableInUseBusinessAreas(newHierarchySettings);

        setHierarchySettings(newHierarchySettings);

    }

    useEffect(function () {

        var disabledAreasHierarchy = {
            ...props.hierarchy
        };

        disableInUseBusinessAreas(disabledAreasHierarchy);
        setHierarchySettings(disabledAreasHierarchy);

    }, [inUseLocations.length]);

    function disableInUseBusinessAreas(newHierarchySettings) {
        //Check to see if business areas are locked by features or by a location of that type being in use
        for (var i = 0; i < newHierarchySettings.businessAreas.length; i++) {

            var currentBusinessArea = newHierarchySettings.businessAreas[i];

            //Check there arent any features in use by this business area
            var featureInUse = newHierarchySettings.hierarchyFeatures.some(function (feature) {
                return feature.locationBusinessAreaId === currentBusinessArea.locationBusinessAreaId;
            });

            //Check there arent any locaitons in use by this business area only
            var locationInUse = inUseLocations.some(function (location) {

                //Get an intersection of business areas for this location, that have been switched on
                const filteredAreas = location.businessAreas.filter(value => newHierarchySettings.businessAreas.some(function (hsl) {
                    return hsl.locationBusinessAreaId === value.locationBusinessAreaId;
                }));

                if (filteredAreas.length === 1 && filteredAreas[0].locationBusinessAreaId === currentBusinessArea.locationBusinessAreaId) {
                    return true;
                }

                return false;

            });

            currentBusinessArea.disabled = featureInUse || locationInUse;
        }
    }

    function filterByPermissionsFeature(feature) {
        return feature.featureType != null && feature.featureType.toUpperCase() === "PERMISSIONS";
    }

    function onHierarchyNameChange(e) {

        var newName = e.target.value;

        setHierarchySettings({
            ...hierarchySettings,
            hierarchyName: newName
        });
    }

    function onBusinessAreasValidationUpdate() {

    }

    function onConvertUserPermissionsChange(value) {
        setConvertUserPermissions(value);
    }

    function closeAlertModal() {
        setShowPermissionFeatureAlert(false);
    }

    function onHierarchySave() {
        setSaving(true);

        HierarchyService.updateHierarchySettings(props.hierarchyId, hierarchySettings, convertUserPermissions).then(
            () => {
                setSaving(false);
                setOriginalValues({
                    ...hierarchySettings
                });

                props.onSettingSave(hierarchySettings);
                setFeaturePermissionsChanging(false);
                setFeaturePermissionSwitchedOff("");
                setConvertUserPermissions(null);
                NotificationToaster.show(Intent.SUCCESS, "Hierarchy settings updated successfully.");
            },
            (error) => {
                setSaving(false);
                const badRequestCode = 400;
                if (error.response.status === badRequestCode) {
                    NotificationToaster.show(Intent.WARNING, error.response.data);
                } else {
                    NotificationToaster.show(Intent.DANGER, "Could not update the hierarchy settings. Please try again.");
                }
            }
        );
    }
  
    return (
        <div>
            <div>
                <FormTextInput
                    id="location-hierarchy-name"
                    loading={false}
                    value={hierarchySettings.hierarchyName}
                    headingText="Location hierarchy name:"
                    onChange={onHierarchyNameChange}
                    disabled={false}
                />
            </div>

            <div>
                <HierarchyBusinessAreas onHierarchyUpdate={onHierarchySettingsUpdate} hierarchy={hierarchySettings} saving={saving} onValidationUpdate={onBusinessAreasValidationUpdate} />
            </div>

            <div>
                <p>Here you can turn your location Hierarchy on or off for each of the dashboards listed.<br />The dashboards will group and compare results according to the hierarchy you create.</p>
                <HierarchyFeatures onHierarchyUpdate={onHierarchySettingsUpdate} hierarchy={hierarchySettings} permissionsChanging={featurePermissionsChanging}  />
            </div>

            <ShowHide
                evaluator={featurePermissionsChanging}
                show={(
                    <div className="spacer-bottom">
                        <UserPermissionsSelection alertText={`You are about to remove the ${featurePermissionSwitchedOff} feature which control users permissions. What would you like to happen to users who inherit locations through a hierarchy group?`} onConvertUserPermissionsChange={onConvertUserPermissionsChange} value={convertUserPermissions == null ? "" : convertUserPermissions.toString()} />
                    </div>
                )}
            />

            <AlertUnsavedData
                isDirty={isDataDirty}>
            </AlertUnsavedData>

            <AlertBasic isOpen={showPermissionFeatureAlert} intent={Intent.PRIMARY} handleAlertConfirm={closeAlertModal}>
                <p>You are switching off a feature that controls user's permissions, please select what should happen to these users at the bottom of the page.</p>
            </AlertBasic>

            <ButtonSave
                id="hierarchy-settings-save"
                onClick={onHierarchySave}
                disabled={saving}
                loading={props.loading}
                simpleDisabled={(featurePermissionsChanging && convertUserPermissions == null) || hierarchySettings.businessAreas.length === 0}
            ></ButtonSave>
        </div>
    );

}
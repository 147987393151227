import React, { Fragment, useState, useEffect } from 'react';
import { Icon, Intent } from '@blueprintjs/core';
import PropTypes from 'prop-types';
import { Link } from "react-router-dom";

import { ListingTable } from "components/listing";
import { SelfReportingService } from "services";
import { DateToLocal } from "components/dates";
import { Button } from "components/buttons";
import { Tooltip } from 'components/tooltip';
import { AlertConfirm, NotificationToaster } from "components/notifications";
import { UserHasAccess } from 'components/user-access';

export function ScheduleRuns(props) {

    const initialTableHeaders = ["Name", "Run date", "Re-run", "Recipients"];
    const [tableRows, setTableRows] = useState([]);
    const [loadingData, setLoadingData] = useState(true);
    const noDataMessage = "It looks like this schedule has not run yet.";
    const [showRerunConfirm, setShowRerunConfirm] = useState(false);
    const [selectedScheduleId, setSelectedScheduleId] = useState(0);
    const isInternal = UserHasAccess("Internal");
    const [tableHeaders, setTableHeaders] = useState(initialTableHeaders);

    function onRerunSchedule(scheduleId) {
        setSelectedScheduleId(scheduleId);
        setShowRerunConfirm(true);
    }

    function onRerunConfirm() {
        setShowRerunConfirm(false);
        setLoadingData(true);

        SelfReportingService.rerunSchedule(selectedScheduleId).then(function () {
            setSelectedScheduleId(0);
            NotificationToaster.show(Intent.SUCCESS, "Schedule re-run successfully created");
            initialise();
        }, function () {
            setSelectedScheduleId(0);
            setLoadingData(false);
            NotificationToaster.show(Intent.DANGER, "Unable to re-run the schedule, please try again.");
        });

    }

    function onRerunCancel() {
        setSelectedScheduleId(0);
        setShowRerunConfirm(false);
    }

    useEffect(initialise, []);

    function initialise() {

        if (isInternal) {
            setTableHeaders([
                ...initialTableHeaders,
                "Actions"
            ]);
        }

        SelfReportingService.getScheduleRuns(props.scheduleId).then(listingResponse => {

            let data = [];

            if (listingResponse != null) {
                data = listingResponse.map((d) => {
                    var row = [
                        d.hasErrors ?
                            <div className="inline-items">
                                <span className="inline-item">
                                    <Link to={`/schedule/${props.scheduleId}/runs/${d.scheduleInstanceId}`}>{d.scheduleInstanceName}</Link>
                                </span>
                                <Tooltip className="inline-item" content="">
                                    <Link to={`/schedule/${props.scheduleId}/runs/${d.scheduleInstanceId}`}><Icon icon="warning-sign" intent="danger" /></Link>Not all reports were generated successfully on this run
                                </Tooltip>
                            </div> :
                            <Link to={`/schedule/${props.scheduleId}/runs/${d.scheduleInstanceId}`}>{d.scheduleInstanceName}</Link>,
                        <DateToLocal>{d.runDate}</DateToLocal>,
                        d.rerun ? "Yes" : "No",
                        d.emailAddress == null || d.emailAddress === "" ? "All" : d.emailAddress,
                        
                    ];

                    if (isInternal) {
                        row.push(d.rerun ?
                            <Fragment /> :
                            <div className="button-row-small">
                                <Tooltip content="Re-run schedule for all users">
                                    <Button onClick={() => { onRerunSchedule(d.scheduleInstanceId) }} icon="refresh" iconOnly={true} large={false} minimal={true}></Button>
                                </Tooltip>
                            </div>);
                    }

                    return row;
                })
            }

            setTableRows(data);
            setLoadingData(false);
        });
    }

    return (

        <div className="row">

            <ListingTable
                id="listing-table-schedule-runs"
                headers={tableHeaders}
                loadingData={loadingData}
                noDataMessage={noDataMessage}
                data={tableRows}
                totalRecordCount={tableRows.length}
                pageable={false}
            />

            <AlertConfirm
                title="Please confirm"
                isOpen={showRerunConfirm}
                onConfirm={onRerunConfirm}
                onCancel={onRerunCancel}
            >
                <p>Are you sure you want to re-run this schedule? This will re-create the reports for all users and email them again.</p>
            </AlertConfirm>

        </div>

    );

}

ScheduleRuns.propTypes = {
    scheduleId: PropTypes.number.isRequired
};
import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { Button as BPButton, Intent } from "@blueprintjs/core";

export function Button(props) {

    let intent = Intent.NONE;
    if (props.intent) {
        switch (props.intent.toLowerCase()) {
            case "save":
            case "info":
            case "primary":
                intent = Intent.PRIMARY;
                break;
            case "warning":
                intent = Intent.WARNING;
                break;
            case "danger":
            case "delete":
                intent = Intent.DANGER;
                break;
            case "secondary":
                intent = "secondary"
                break;
            default:
                intent = Intent.NONE;
        }
    }

    return (
        <BPButton
            className={classNames('btn-form', { "bp3-skeleton": props.loading }, props.className)}
            onClick={props.onClick}
            icon={props.icon}
            disabled={props.disabled}
            minimal={props.minimal}
            large={props.large}
            intent={intent}
            id={props.id}
            alignText={props.alignText}
            rightIcon={props.rightIcon}
        >{!props.iconOnly && props.text}</BPButton>
    );
}

Button.defaultProps = {
    id: null,
    intent: "",
    loading: false,
    disabled: false,
    icon: null,
    iconOnly: false,
    text: "",
    minimal: false,
    large: true,
    alignText: null,
    rightIcon: null
};

Button.propTypes = {
    id: PropTypes.string,
    intent: PropTypes.string,
    loading: PropTypes.bool,
    onClick: PropTypes.func.isRequired,
    disabled: PropTypes.bool,
    icon: PropTypes.string,
    iconOnly: PropTypes.bool,
    text: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.object
    ]),
    minimal: PropTypes.bool,
    large: PropTypes.bool,
    className: PropTypes.string,
    alignText: PropTypes.string,
    rightIcon: PropTypes.string
};
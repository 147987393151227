import React, { useState, useEffect } from 'react';
import { Intent } from '@blueprintjs/core';
import { UserService } from 'services';
import { NotificationToaster, AlertUnsavedData, NotificationInline } from "components/notifications";
import { ButtonSave } from "components/buttons";
import { useDirtyData } from "hooks/useDirtyData";
import { UserBasicSettings } from "components/settings";
import { HelperFunctions } from 'helpers';
import { ShowHide } from 'components/layout';

var _ = require('underscore');

export function UserGeneralSettings(props) {

    const [basicSettingsValid, setBasicSettingsValid] = useState(true);
    const [originalValues, setOriginalValues] = useState({});
    const [user, setUser] = useState({
        role: {}
    });
    const [saving, setSaving] = useState(false);
    const [isPending, setIsPending] = useState(false);

    const isDataDirty = useDirtyData(originalValues, user);

    useEffect(() => {
        setUser(props.user);
        setOriginalValues(_.extend({}, props.user));

        if (props.user != null) {
            setIsPending(props.user.status === "Pending");
        }

    }, [props.user]);

    function onSettingsUpdate(userUpdate) {

        if (userUpdate.pendingEmailAddress !== user.pendingEmailAddress) {
            HelperFunctions.executeIfNotNull(props.onSave, userUpdate);
            setOriginalValues({
                ...originalValues,
                pendingEmailAddress: userUpdate.pendingEmailAddress
            });
        }

        setUser(userUpdate);
    }

    function saveUser() {
        setSaving(true);

        if (!basicSettingsValid) {
            NotificationToaster.show(Intent.WARNING, "Settings not valid. Please check your settings and try again.");
            setSaving(false);
            return;
        }

        UserService.putUserBasicInfo(user, props.editingOwnAccount).then(
            () => {
                setSaving(false);
                setOriginalValues(_.extend({}, user));
                NotificationToaster.show(Intent.SUCCESS, "User updated successfully.");
                HelperFunctions.executeIfNotNull(props.onSave, user);
            },
            (error) => {
                setSaving(false);
                const badRequestCode = 400;
                if (error.response.status === badRequestCode) {
                    NotificationToaster.show(Intent.WARNING, error.response.message);
                } else {
                    NotificationToaster.show(Intent.DANGER, "Could not update the user. Please try again.");
                }
            }
        );
    }

    function onBasicSettingsValidationChange(isValid) {
        setBasicSettingsValid(isValid);
    }

    return (
        <div className="row">
            <h2>User Details</h2>

            <NotificationInline
                show={props.ssoAccount}
                text="This user's name is handled internally by your IT team and cannot be set through Vision"
                intent="info"
            ></NotificationInline>

            <UserBasicSettings
                user={user}
                loading={props.loading}
                editing={true}
                onSettingsUpdate={onSettingsUpdate}
                saving={saving}
                ssoAccount={props.ssoAccount}
                onValidationUpdate={onBasicSettingsValidationChange}
                pending={isPending}
            />

            <AlertUnsavedData
                isDirty={isDataDirty}>
            </AlertUnsavedData>

            <ShowHide
                evaluator={!isPending && !props.ssoAccount}
                show={(
                    <ButtonSave
                        id="user-role-save"
                        onClick={saveUser}
                        disabled={saving}
                        loading={props.loading}
                    ></ButtonSave>
                )}
            />

        </div>
    );
}
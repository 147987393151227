import React, { useState, useEffect, useRef, Fragment } from "react";
import { ListingTable } from "components/listing";
import { SelfReportingService, UserService } from "services";
import { UserAccess } from "components/user-access";
import { PageRestricted } from "pages/errors/page-restricted";
import { Breadcrumbs } from "components/navigation";
import { NotificationToaster } from "components/notifications";
import { ShowHide } from "components/layout";
import { Intent } from "@blueprintjs/core";
import PropTypes from "prop-types";
import { Button } from "components/buttons";
import { useDebounce } from 'hooks/useDebounce';
import { FormTextInput } from "components/form-fields";

export function ErroredSchedules(props) {
    const defaultRecordSize = 25;
    const debounceTimeout = 750;

    const [totalRecords, setTotalRecords] = useState(0);
    const [tableRows, setTableRows] = useState([]);
    const [loadingData, setLoadingData] = useState(true);
    const noDataMessage = "The schedule does not have any errors";
    const [pageNumber, setPageNumber] = useState(1);
    const [pageSize, setPageSize] = useState(defaultRecordSize);
    const requiredAction = "Internal";
    const [searchTerm, setSearchTerm] = useState("");
    const debouncedSearchTerm = useDebounce(searchTerm, debounceTimeout);
    const inputSearchRef = useRef(null);

  const tableHeaders = ["Member Number", "Schedule Name", "Error Messages", "Actions"];

    useEffect(initialise, [pageSize, pageNumber, debouncedSearchTerm]);

  function initialise() {
      setLoadingData(true);
      SelfReportingService.getErroredSchedules(pageSize, pageNumber, debouncedSearchTerm).then(
      (listingResponse) => {
        let data = [];

        if (listingResponse != null) {
          setTotalRecords(listingResponse.totalCount);
          data = listingResponse.data.map((d) => {
            return [d.memberNumber, 
              d.scheduleName, 
                <span key={`errors-${d.scheduleId}`}>{
                    d.errorReasons.map((e, i) => {
                        return (<span key={i}>&bull;&nbsp; {e} <br/></span>);
                    })
                }</span>,
                <Button key={`switch-schedule-${d.scheduleId}`} onClick={() => switchToSchedule(d.accountId, d.scheduleId, d.lastInstanceId)} icon="swap-horizontal" iconOnly={true} text="Switch to schedule" large={false} minimal={true}></Button>
            ];
          });
        }

        setTableRows(data);
        setLoadingData(false);

        setTimeout(function () {
            if (inputSearchRef.current != null) {
                inputSearchRef.current.focus();
            }
        });

      },
      function () {
        setLoadingData(false);
        NotificationToaster.show(
          Intent.DANGER,
          "Could not retrieve errored schedules, please try again."
        );
      }
    );
  }

  function onPagingChange(newPageNumber, newPageSize) {
    setPageNumber(newPageNumber);
    setPageSize(newPageSize);
  }

    function switchToSchedule(accountId, scheduleId, instanceId) {
        UserService.switchAccount(accountId).then(function () {
            if (instanceId == null) {
                window.location.href = `/schedule/${scheduleId}`;
            } else {
                window.location.href = `/schedule/${scheduleId}/runs/${instanceId}`;
            }
        }, function () {
            NotificationToaster.show(
                Intent.DANGER,
                "Could not switch account, please try again."
            );
        });
    }

    function onSearchChange(item) {
        setSearchTerm(item.target.value);
    }

  return (
    <UserAccess
      perform={requiredAction}
      yes={() => (
        <div className="row">
          <Breadcrumbs items={props.breadcrumbs} />

          <h1>Errored Schedules</h1>
          <div className="intro-text">
            <p>From here you can see all the schedules that have failed</p>
              </div>

            <ShowHide
                evaluator={tableRows.length === 0 && !loadingData && debouncedSearchTerm === ""}
                hide={(
                    <Fragment>
                        <div className="pull-left">
                            <div className="inline-items">
                                <FormTextInput inputRef={inputSearchRef} placeholder="Member Number Search" onChange={onSearchChange} value={searchTerm} large disabled={loadingData} icon="search" id="scheduler-search-field" />
                            </div>
                        </div>
                    </Fragment>
                )}
            />

          <ListingTable
            id="listing-table-errored-schedules"
            headers={tableHeaders}
            onPagingChange={onPagingChange}
            loadingData={loadingData}
            noDataMessage={noDataMessage}
            data={tableRows}
            totalRecordCount={totalRecords}
            pageable={true}
          />

        </div>
      )}
      no={() => <PageRestricted />}
    />
  );
}

ErroredSchedules.propTypes = {
  breadcrumbs: PropTypes.array,
};

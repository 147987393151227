import { Intent, Spinner, Tag, Tooltip } from '@blueprintjs/core';
import axios from 'axios';
import classNames from 'classnames';
import { Button, ButtonSave } from 'components/buttons';
import { FormDateInput, FormSelect, FormTextInput } from 'components/form-fields';
import { ShowHide } from 'components/layout';
import { AlertConfirm, AlertUnsavedData, NotificationInline, NotificationToaster } from 'components/notifications';
import { HelperFunctions } from 'helpers';
import { useDebounce } from 'hooks/useDebounce';
import { useDirtyData } from 'hooks/useDirtyData';
import { useValidation } from 'hooks/useValidation';
import moment from 'moment';
import React, { Fragment, useState, useEffect } from 'react';
import { AccountService, UserService } from 'services';

export function AccountSsoConfiguration(props) {
    const authenticationProtocolOptions = [
        {
            id: 'SAML',
            name: 'SAML'
        },
        {
            id: 'OIDC',
            name: 'OIDC'
        }
    ];

    const defaultSsoConfig = {
        ssoEnabled: false,
        policyConfig: null
    };

    const defaultClaimNames = {
        saml: {
            emailClaim: 'http://schemas.xmlsoap.org/ws/2005/05/identity/claims/emailaddress',
            firstNameClaim: 'http://schemas.xmlsoap.org/ws/2005/05/identity/claims/givenname',
            lastNameClaim: 'http://schemas.xmlsoap.org/ws/2005/05/identity/claims/surname',
        },
        oidc: {
            emailClaim: 'email',
            firstNameClaim: 'given_name',
            lastNameClaim: 'family_name'
        }
    };

    const debounceTimeout = 750;

    const [ssoConfig, setSsoConfig] = useState(defaultSsoConfig);
    const [originalSsoConfig, setOriginalSsoConfig] = useState(defaultSsoConfig);
    const [userRoles, setUserRoles] = useState([]);
    const [saving, setSaving] = useState(false);
    const [loading, setLoading] = useState(true);
    const [loadingSsoKeyAvailability, setLoadingSsoKeyAvailability] = useState(false);
    const [ssoKey, setSsoKey] = useState('');
    const [ssoKeyAvailability, setSsoKeyAvailability] = useState(true);
    const [ssoKeyIcon, setSsoKeyIcon] = useState('');
    const [isEditingSecret, setIsEditingSecret] = useState(false);
    const [confirmationMessage, setConfirmationMessage] = useState(null);
    const [configToSave, setConfigToSave] = useState(null);
    const [successfulSaveMessage, setSuccessfulSaveMessage] = useState(null);
    const [confirmationButtonIntent, setConfirmationButtonIntent] = useState(null);

    const isDataDirty = useDirtyData(originalSsoConfig, ssoConfig);

    const [isValid, errors, validate] = useValidation();

    const debouncedSsoKey = useDebounce(ssoKey, debounceTimeout);

    useEffect(initialLoad, []);
    useEffect(loadSsoKeyAvailability, [debouncedSsoKey]);
    useEffect(validateModel, [ssoConfig]);

    useEffect(() => {
        if (!debouncedSsoKey) {
            setSsoKeyIcon(null);
            return;
        }

        setSsoKeyIcon(ssoKeyAvailability ? 'tick' : 'issue');
    }, [debouncedSsoKey, ssoKeyAvailability])

    function initialLoad() {
        axios.all([
            AccountService.getSsoConfig(),
            UserService.getRoles()
        ]).then(axios.spread((ssoData, roles) => {
            setLoading(false);
            setSsoConfig(ssoData);
            setOriginalSsoConfig(ssoData);
            setUserRoles(roles.filter(x => x.settable));
        }), () => {
            NotificationToaster.show(Intent.DANGER, 'Could not load the SSO configuration. Please try again.');
        });
    }

    function loadSsoKeyAvailability() {
        if (!debouncedSsoKey || errors["ssoKey"]) {
            setSsoKeyAvailability(true);
            return;
        }

        setLoadingSsoKeyAvailability(true);

        AccountService.checkSsoKeyIsAvailable(debouncedSsoKey)
            .then((data) => {
                setSsoKeyAvailability(data.ssoKeyAvailability); // Add error message when false, check sso key with non-alphanumeric characters
                setLoadingSsoKeyAvailability(false);
            }, () => {
                NotificationToaster.show(Intent.DANGER, 'We have been unable to verify whether the SSO key is available. Please try again.');
                setSsoKeyAvailability(false);
                setLoadingSsoKeyAvailability(false);
            });
    }


    function validateModel() {
        const rules = getRules();
        validate(rules, ssoConfig.policyConfig);
    }

    function getRules() {
        if (!ssoConfig.policyConfig) {
            setSsoConfig({
                ...ssoConfig,
                policyConfig: {}
            })
            return [];
        }

        let rules = [
            { fieldName: "ssoKey", required: true, minValue: 1, maxValue: 50, type: 'alphanumeric' },
            { fieldName: "metadataUrl", required: true, minValue: 1, maxValue: 255, type: 'url' },
            { fieldName: "defaultRole", required: true },
            { fieldName: "emailClaim", required: true, minValue: 1, maxValue: 100 },
            { fieldName: "firstNameClaim", required: true, minValue: 1, maxValue: 100 },
            { fieldName: "lastNameClaim", required: true, minValue: 1, maxValue: 100 },
        ];

        if (ssoConfig.policyConfig?.authenticationProtocol === 'OIDC') {
            rules.push({ fieldName: "clientId", required: true, minValue: 1, maxValue: 100 });
            rules.push({ fieldName: "clientSecret", required: true, minValue: 1, maxValue: 100 });
        }

        return rules;
    }

    function renderReturnUrlText() {
        if (ssoConfig.policyConfig?.authenticationProtocol === 'SAML') {
            return `The SSO policy for your account will be setup in our system with the following metadata URL https://${window.env.SSODOMAIN}/${window.env.SSOTENANT}/B2C_1A_SSO/samlp/metadata?idptp=LOGIN. Please use this URL when setting up your internal SSO configuration.`;
        }

        if (ssoConfig.policyConfig?.authenticationProtocol === 'OIDC') {
            return `Please configure your internal SSO system to allow the following return URL https://${window.env.SSODOMAIN}/${window.env.SSOTENANT}/B2C_1A_SSO_${ssoConfig.policyConfig?.ssoKey}/oauth2/authresp.`;
        }

        return '';
    }

    function updatePolicyConfig(newPolicyConfig) {
        setSsoConfig({
            ...ssoConfig,
            policyConfig: newPolicyConfig
        });
    }

    function onAuthenticationProtocolChange(protocol) {
        const policyConfig = {
            ...ssoConfig.policyConfig,
            authenticationProtocol: protocol.id
        };

        // Set the defaults for the claims if they are not already set
        var otherProtocolId = protocol.id === 'SAML' ? 'oidc' : 'saml';
        var protocolDefaults = defaultClaimNames[protocol.id.toLowerCase()];
        var otherProtocolDefaults = defaultClaimNames[otherProtocolId];

        for (let defaultProperty in protocolDefaults) {
            policyConfig[defaultProperty] = !policyConfig[defaultProperty] || policyConfig[defaultProperty] === otherProtocolDefaults[defaultProperty] ? protocolDefaults[defaultProperty] : policyConfig[defaultProperty];
        }

        updatePolicyConfig(policyConfig);
    }

    function onSsoKeyChange(event) {
        const policyConfig = {
            ...ssoConfig.policyConfig,
            ssoKey: event.target.value
        };

        setSsoKey(event.target.value);

        updatePolicyConfig(policyConfig);
    }

    function onUserRoleChange(role) {
        const policyConfig = {
            ...ssoConfig.policyConfig,
            defaultRole: role
        };

        updatePolicyConfig(policyConfig);
    }

    function onMetadataUrlChange(event) {
        const policyConfig = {
            ...ssoConfig.policyConfig,
            metadataUrl: event.target.value
        };

        updatePolicyConfig(policyConfig);
    }

    function onEmailClaimNameChange(event) {
        const policyConfig = {
            ...ssoConfig.policyConfig,
            emailClaim: event.target.value
        };

        updatePolicyConfig(policyConfig);
    }

    function onFirstNameClaimChange(event) {
        const policyConfig = {
            ...ssoConfig.policyConfig,
            firstNameClaim: event.target.value
        };

        updatePolicyConfig(policyConfig);
    }

    function onLastNameClaimChange(event) {
        const policyConfig = {
            ...ssoConfig.policyConfig,
            lastNameClaim: event.target.value
        };

        updatePolicyConfig(policyConfig);
    }

    function onClientIdChange(event) {
        const policyConfig = {
            ...ssoConfig.policyConfig,
            clientId: event.target.value
        };

        updatePolicyConfig(policyConfig);
    }

    function onClientSecretChange(event) {
        const policyConfig = {
            ...ssoConfig.policyConfig,
            clientSecret: event.target.value
        };

        updatePolicyConfig(policyConfig);
    }

    function editClientSecret() {
        const policyConfig = {
            ...ssoConfig.policyConfig,
            clientSecret: null
        };

        updatePolicyConfig(policyConfig);

        setIsEditingSecret(true);
    }

    function onClientSecretExpiryChange(item) {
        const policyConfig = {
            ...ssoConfig.policyConfig,
            clientSecretExpiry: item ? moment(item).format("YYYY-MM-DD") : null
        };

        updatePolicyConfig(policyConfig);
    }

    function saveConfig() {
        const clonedConfig = HelperFunctions.deepClone(ssoConfig);

        if (ssoConfig.policyConfig && ssoConfig.policyConfig.authenticationProtocol !== 'OIDC') {
            clonedConfig.policyConfig.clientId = null;
            clonedConfig.policyConfig.clientSecret = null;
            clonedConfig.policyConfig.clientSecretExpiry = null;
        }

        setSaving(true);

        AccountService.updateSsoConfig(clonedConfig)
            .then(() => {
                setSaving(false);
                setIsEditingSecret(false);
                const shortenedSecretLength = 3;

                if (clonedConfig.policyConfig?.clientSecret && clonedConfig.policyConfig.clientSecret.length > shortenedSecretLength) {
                    clonedConfig.policyConfig.clientSecret = clonedConfig.policyConfig.clientSecret.substring(0, shortenedSecretLength);
                }

                setOriginalSsoConfig(clonedConfig);
                setSsoConfig(clonedConfig);
                NotificationToaster.show(Intent.SUCCESS, 'The SSO configuration has been saved successfully.');
            }, () => {
                NotificationToaster.show(Intent.DANGER, 'Could not save the SSO configuration. Please try again.');
                setSaving(false);
            });
    }

    function unpublishConfig() {
        setConfigToSave({
            ...originalSsoConfig,
            ssoEnabled: false
        });

        setConfirmationMessage('Are you sure you want to unpublish the SSO configuration? Users will be able to sign in through either Vision\'s standard login page or your SSO provider.');
        setSuccessfulSaveMessage('The SSO configuration has been unpublished.');
        setConfirmationButtonIntent('DANGER');
    }

    function publishConfig() {
        setConfigToSave({
            ...originalSsoConfig,
            ssoEnabled: true
        });

        setConfirmationMessage('Are you sure you want to publish the SSO configuration? Users will not be able to sign in through Vision\'s standard login page and instead will be forced to sign in through your SSO provider.');
        setSuccessfulSaveMessage('The SSO configuration has been published.');
        setConfirmationButtonIntent('PRIMARY');
    }

    function deleteConfig() {
        setConfigToSave({
            ssoEnabled: false,
            policyConfig: null
        });
        setConfirmationMessage('Are you sure you want to delete the SSO configuration? All users will have to sign in through Vision\'s standard login page.');
        setSuccessfulSaveMessage('The SSO configuration has been deleted.');
        setConfirmationButtonIntent('DANGER');
    }

    function onConfirm() {
        setSaving(true);
        setConfigToSave(null);

        AccountService.updateSsoConfig(configToSave)
            .then(() => {
                setSaving(false);
                setOriginalSsoConfig(configToSave);
                setSsoConfig(configToSave);
                NotificationToaster.show(Intent.SUCCESS, successfulSaveMessage);
            }, () => {
                NotificationToaster.show(Intent.DANGER, 'Could not update the SSO configuration. Please try again.');
                setSaving(false);
            });
    }

    function onConfirmationCancel() {
        setConfigToSave(null);
    }

    return <div className="row">
        <div className='inline-items spacer-bottom'>
            <h2>SSO Configuration</h2>
            <Tag
                large
                intent={ssoConfig.ssoEnabled ? 'SUCCESS' : 'NONE'}
            >
                {ssoConfig.ssoEnabled ? 'Published' : 'Draft'}
            </Tag>
        </div>
        <FormTextInput
            id="sso-key"
            loading={loading}
            value={ssoConfig.policyConfig?.ssoKey ?? ''}
            headingText="Enter a SSO key:"
            onChange={onSsoKeyChange}
            disabled={saving || !!originalSsoConfig.policyConfig}
            helperText={"This is a unique key for your SSO configuration. This key will form the end of the login URL for vision and cannot be changed once the configuration is saved."}
            icon={ssoKeyIcon}
            showLoader={loadingSsoKeyAvailability}
            dangerHelperText={!ssoKeyAvailability ? 'The SSO key is not available. Please select a different one.' : errors.ssoKey}
        >
        </FormTextInput>
        <NotificationInline
            id="sso-key-notification"
            loading={loading}
            show={!!ssoConfig.policyConfig?.ssoKey}
            text={`The users for your account will need to navigate to ${window.env.VISION_URL}/sso/login/${ssoConfig.policyConfig?.ssoKey} when logging into vision.`}
            intent={Intent.PRIMARY}
            allowClose={false}>
        </NotificationInline>
        <FormSelect
            id="sso-authentication-protocol"
            loading={loading}
            disabled={saving}
            items={authenticationProtocolOptions}
            onItemSelect={onAuthenticationProtocolChange}
            placeholder="Select an authentication protocol:"
            headingText="The authentication protocol the SSO process should use"
            dangerHelperText={errors.authenticationProtocol}
            selectedValue={ssoConfig.policyConfig?.authenticationProtocol}
        >
        </FormSelect>
        <NotificationInline
            id="sso-return-url-notification"
            loading={loading}
            show={!!ssoConfig.policyConfig?.ssoKey && !!ssoConfig.policyConfig?.authenticationProtocol}
            text={renderReturnUrlText()}
            intent={Intent.PRIMARY}
            allowClose={false}>
        </NotificationInline>
        <FormTextInput
            id="sso-metadata-url"
            loading={loading}
            value={ssoConfig.policyConfig?.metadataUrl}
            headingText="Metadata URL:"
            onChange={onMetadataUrlChange}
            disabled={saving}
            dangerHelperText={errors.metadataUrl}
            wide={true}
        >
        </FormTextInput>
        <FormSelect
            id="sso-default-role"
            loading={loading}
            disabled={saving}
            items={userRoles}
            onItemSelect={onUserRoleChange}
            placeholder="Select a default user role"
            headingText="The default user role:"
            helperText="This will be the role that any users who do not currently exist in vision will be given when they sign in for the first time"
            dangerHelperText={errors.defaultRole}
            selectedValue={ssoConfig.policyConfig?.defaultRole?.id}
        >
        </FormSelect>
        <FormTextInput
            id="sso-email-claim"
            loading={loading}
            value={ssoConfig.policyConfig?.emailClaim}
            headingText="Enter the name for the email address claim:"
            onChange={onEmailClaimNameChange}
            disabled={saving}
            helperText="This should be set to the name of the claim which your identity provider uses when returning the email address of the user"
            wide={true}
            dangerHelperText={errors.emailClaim}
        >
        </FormTextInput>
        <FormTextInput
            id="sso-first-name-claim"
            loading={loading}
            value={ssoConfig.policyConfig?.firstNameClaim}
            headingText="Enter the name for the forename claim:"
            onChange={onFirstNameClaimChange}
            disabled={saving}
            helperText="This should be set to the name of the claim which your identity provider uses when returning the forename of the user"
            wide={true}
            dangerHelperText={errors.firstNameClaim}
        >
        </FormTextInput>
        <FormTextInput
            id="sso-last-name-claim"
            loading={loading}
            value={ssoConfig.policyConfig?.lastNameClaim}
            headingText="Enter the name for the surname claim:"
            onChange={onLastNameClaimChange}
            disabled={saving}
            helperText="This should be set to the name of the claim which your identity provider uses when returning the surname of the user"
            wide={true}
            dangerHelperText={errors.lastNameClaim}
        >
        </FormTextInput>
        <ShowHide
            evaluator={ssoConfig.policyConfig?.authenticationProtocol === 'OIDC'}
            show={(
                <Fragment>
                    <FormTextInput
                        id="sso-client-id"
                        loading={loading}
                        value={ssoConfig.policyConfig?.clientId}
                        headingText="Client ID:"
                        onChange={onClientIdChange}
                        disabled={saving}
                        wide={true}
                        dangerHelperText={errors.clientId}
                    >
                    </FormTextInput>
                    <ShowHide
                        evaluator={!!originalSsoConfig.policyConfig && originalSsoConfig.policyConfig?.authenticationProtocol === 'OIDC' && !isEditingSecret}
                        show={(
                            <div className={classNames("form-field", { "bp3-skeleton": loading })}>
                                <h4>Client Secret</h4>
                                <div className="inline-items">
                                    <p>{ssoConfig.policyConfig?.clientSecret}************</p>
                                    <Tooltip content="Update secret">
                                        <Button minimal large={false} icon="edit" iconOnly text="Edit client secret" intent="none" onClick={editClientSecret} />
                                    </Tooltip>
                                </div>
                            </div>
                        )}
                        hide={(
                            <FormTextInput
                                id="sso-client-secret"
                                loading={loading}
                                value={ssoConfig.policyConfig?.clientSecret}
                                headingText="Client secret:"
                                onChange={onClientSecretChange}
                                disabled={saving}
                                wide={true}
                                dangerHelperText={errors.clientSecret}
                                type={'password'}
                            >
                            </FormTextInput>
                        )}
                    />
                    <FormDateInput
                        id="sso-expiry-date"
                        disabled={saving}
                        headingText="Client secret expiry date:"
                        onChange={onClientSecretExpiryChange}
                        value={ssoConfig.policyConfig?.clientSecretExpiry}
                        loading={loading}
                        minDate={moment("2000-01-01").toDate()}
                        helperText={'This is an optional field to allow you to monitor when your client secret will expiry. Before this date, you should update the client secret through this page.'}
                    ></FormDateInput>
                </Fragment>
            )}
        />
        <AlertUnsavedData
            isDirty={isDataDirty}>
        </AlertUnsavedData>
        <div className='button-row'>
            <ButtonSave
                onClick={saveConfig}
                simpleDisabled={!isValid || !ssoKeyAvailability || saving}
                loading={loading}
            ></ButtonSave>
            <ShowHide
                evaluator={!!originalSsoConfig.policyConfig}
                show={<ShowHide
                    evaluator={originalSsoConfig.ssoEnabled}
                    show={
                        <Button
                            onClick={unpublishConfig}
                            disabled={isDataDirty || saving}
                            loading={loading}
                            intent='DANGER'
                            text='Unpublish'
                            icon='import'

                        />
                    }
                    hide={
                        <Button
                            onClick={publishConfig}
                            disabled={isDataDirty || saving}
                            loading={loading}
                            intent='PRIMARY'
                            text='Publish'
                            icon='export'
                        />
                    }
                />}
            />

            <Button
                onClick={deleteConfig}
                icon="trash"
                text="Delete"
                loading={loading}
                disabled={!originalSsoConfig.policyConfig || saving}
                intent='DANGER'
            />
            <ShowHide
                evaluator={saving}
                show={(
                    <div className="save-loader">
                        <Spinner size="25" />
                    </div>
                )}
            />
        </div>
        <AlertConfirm
            title="Please confirm"
            isOpen={!!configToSave}
            onConfirm={onConfirm}
            onCancel={onConfirmationCancel}
            confirmButtonIntent={confirmationButtonIntent}
        >
            <p>{confirmationMessage}</p>
        </AlertConfirm>
    </div>;
}
import React, { Fragment } from 'react';
import classNames from 'classnames';
import { RadioGroup, Radio } from "@blueprintjs/core";
import PropTypes from 'prop-types';
import { ShowHide } from 'components/layout';
import { FormHelperText } from "components/form-fields";
import 'components/form-fields/FormFields.css';

export function FormRadio(props) {
   
    return (
        <div className="form-field" id={props.id}>
            <ShowHide
                evaluator={props.headingText != null}
                show={(
                    <h4 className={classNames({ "bp3-skeleton": props.loading })}>{props.headingText}</h4>
                )}
            />
            <FormHelperText loading={props.loading}>{props.helperText}</FormHelperText>
            <RadioGroup
                disabled={props.disabled}
                onChange={props.onChange}
                selectedValue={props.selectedValue}
            >
                {props.options.map((option) => {
                    return (
                        <Fragment key={option.value}>
                            <Radio className={classNames({ "bp3-skeleton": props.loading })} onChange={props.onChange} checked={props.selectedValue === option.value} label={option.label} value={option.value} disabled={props.disabled || option.disabled} />
                            <ShowHide
                                evaluator={props.selectedValue === option.value && option.onSelectRender != null}
                                show={option.onSelectRender}
                            />
                            <FormHelperText loading={props.loading}>{option.helperText}</FormHelperText>
                        </Fragment>
                    );
                })}
            </RadioGroup>
            <FormHelperText loading={props.loading} danger={true}>{props.dangerHelperText}</FormHelperText>
        </div>
    );
}

FormRadio.defaultProps = {
    id: null,
    options: [{ value: true, label: "Yes" }, { value: false, label: "No" }],
    loading: false,
    headingText: null,
    helperText: null,
    disabled: false,
    selectedValue: null,
    dangerHelperText: null
};

FormRadio.propTypes = {
    id: PropTypes.string,
    options: PropTypes.array.isRequired,
    loading: PropTypes.bool,
    headingText: PropTypes.string,
    helperText: PropTypes.node,
    disabled: PropTypes.bool,
    onChange: PropTypes.func.isRequired,
    selectedValue: PropTypes.any,
    dangerHelperText: PropTypes.node
};
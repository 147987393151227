const initialState = {
    fullWidth: false
}

const SiteReducer = (state = initialState, action) => {
    switch (action.type) {
        case "SITE_FULL_WIDTH":
            return {
                ...state,
                fullWidth: true,
            };
        case "SITE_DEFAULT_WIDTH":
            return {
                ...state,
                fullWidth: false,
            }
        default:
            return state;
    }
}

export default SiteReducer;
import React, { useEffect, useState } from 'react';
import { useDirtyData } from 'hooks/useDirtyData';
import { AlertUnsavedData, Modal, NotificationToaster } from 'components/notifications';
import { Tabs } from 'components/navigation';
import { ActivitiesTab } from 'pages/fleet/day-detail-modal/activities-tab';
import { SpeedDataTab } from 'pages/fleet/day-detail-modal/speed-data-tab';
import { HistoryTab } from 'pages/fleet/day-detail-modal/history-tab';
import { NotesTab } from 'pages/fleet/day-detail-modal/notes-tab';
import { AssetService } from 'services';
import PropTypes from "prop-types";
import { Intent } from '@blueprintjs/core';
import { AssetStatus } from 'components/status/AssetStatus';
import { ShowHide } from 'components/layout';
import { FormDatePeriodSelector, FormHeading } from 'components/form-fields';
import { DateToLocal } from 'components/dates';
import './DayDetailModal.css';
import moment from 'moment';

export function DayDetailModal(props) {
    const { showDayDetailModal, closeDayDetailModal, assetId, date, statusAttributeMappings, ownershipAttributeMappings, openTabName, lastSearchPayload, vehicleActivityTypes, reloadCalendar } = props;
    const periodSelectorDateFormat = "DD MMM YYYY";
    const defaultTabsLength = 3;
    const tabLengthWithSpeedData = 4
    const defaultRecordSize = 25;
    const [assetStatusId, setAssetStatusId] = useState(0);
    const [assetOwnershipId, setAssetOwnershipId] = useState(0);
    const [assetRegistration, setAssetRegistration] = useState("");
    const [dayDetailDate, setDayDetailDate] = useState("");
    const [tachoDataForDayDetail, setTachoDataForDayDetail] = useState({});

    const [harshBrakingData, setHarshBrakingData] = useState([]);
    const [historyData, setHistoryData] = useState([])
    const [historyPageNumber, setHistoryPageNumber] = useState(1);
    const [historyPageSize, setHistoryPageSize] = useState(defaultRecordSize);
    const [dayDetailTitle, setDayDetailTitle] = useState(<></>)
    const [note, setNote] = useState("");

    const [selectedTab, setSelectedTab] = useState("ACTIVITIES");
    const [tabs, setTabs] = useState([
        { title: "Activities", selected: true, key: "ACTIVITIES" },
        { title: "History", selected: false, key: "HISTORY" },
        { title: "Notes", selected: false, key: "NOTES" }
    ]);
    const [showAlert, setShowAlert] = useState(false);
    const [initialNoteValue, setInitialNoteValue] = useState(note);
    const [currentNoteValue, setCurrentNoteValue] = useState(note);
    const isNotesDataDirty = useDirtyData(initialNoteValue, currentNoteValue);

    const [isTachoDataLoading, setIsTachoDataLoading] = useState(true);
    const [isHarshBrakingLoading, setIsHarshBrakingLoading] = useState(true);
    const [isHistoryLoading, setIsHistoryLoading] = useState(true);
    const [isNotesLoading, setIsNotesLoading] = useState(true);

    useEffect(() => {
        setInitialNoteValue(note);
        setCurrentNoteValue(note);
    }, [note])

    useEffect(() => {
        if (assetId && dayDetailDate && showDayDetailModal) {
            callTachDataForDayDetail();
            callHarshDataForDayDetail();
            callNoteForDayDetail();
            callHistoryDataForDayDetail();
        }
    }, [assetId, dayDetailDate, showDayDetailModal]);

    useEffect(() => {
        if (!showDayDetailModal) {
            resetTabs();
        }
    }, [showDayDetailModal]);

    useEffect(() => {
        if (openTabName && openTabName.length > 0) {
            selectTab(openTabName);
        }
    }, [isTachoDataLoading, openTabName]);

    useEffect(() => {
        if (note) {
            setTabs(tabs.map(function (t) {
                if (t.key === "NOTES") {
                    return {
                        ...t,
                        icon: "comment"
                    }
                }
                return t;
            }));
        }
        else {
            setTabs(tabs.map(function (t) {
                if (t.key === "NOTES") {
                    return {
                        title: t.title,
                        selected: t.selected,
                        key: t.key
                    }
                }
                return t;
            }));
        }
    }, [note]);

    useEffect(() => {
        if (assetId && dayDetailDate && showDayDetailModal) {
            callHistoryDataForDayDetail();
        }
    }, [historyPageSize, historyPageNumber]);

    useEffect(() => {
        setDayDetailTitle(
            <div className="inline-items">
                <FormHeading headingLevel="h2">Day Detail</FormHeading>
                <FormHeading headingLevel="h2">{<DateToLocal format='DD MMM YYYY'>{dayDetailDate}</DateToLocal>}</FormHeading>
                <AssetStatus statusId={assetStatusId} ownershipId={assetOwnershipId} isLoading={isTachoDataLoading} statusAttributeMappings={statusAttributeMappings} ownershipAttributeMappings={ownershipAttributeMappings} />
                <FormHeading headingLevel="h2" isLoading={isTachoDataLoading}><a href={`${window.env.VISION_URL}/#/Fleet/RedirectToProfile?assetId=${assetId}`}>{assetRegistration}</a></FormHeading>
            </div>)
    }, [assetStatusId, assetOwnershipId, assetRegistration, isTachoDataLoading, statusAttributeMappings, ownershipAttributeMappings, dayDetailDate]);

    function onDayChange(newDate) {
        setDayDetailDate(moment(newDate).format("YYYY-MM-DD"));
    }

    function onTabClick(index) {
        setTabs(tabs.map(function (t, i) {
            return {
                ...t,
                selected: index === i
            }
        }));

        setSelectedTab(tabs[index].key);
    }

    function resetTabs() {
        setSelectedTab("ACTIVITIES");
        setTabs([
            { title: "Activities", selected: true, key: "ACTIVITIES" },
            { title: "History", selected: false, key: "HISTORY" },
            { title: "Notes", selected: false, key: "NOTES" }
        ]);
        setNote("");
        setInitialNoteValue("");
        setCurrentNoteValue("");
        setAssetStatusId(0);
        setAssetOwnershipId(0);
        setAssetRegistration("");
        setTachoDataForDayDetail({});
        setHarshBrakingData([]);
        setHistoryData([]);
        setDayDetailDate(null);
    }

    function handleCloseModal() {
        if (isNotesDataDirty) {
            setShowAlert(true);
        }
        else {
            resetTabs();
            closeDayDetailModal();
        }
    }

    function handleConfirmLeave() {
        setShowAlert(false);
        resetTabs();
        closeDayDetailModal();
    }

    function handleCancelLeave() {
        setShowAlert(false);
    }

    function callUpdateTachoAndHistoryData() {
        callTachDataForDayDetail();
        callHistoryDataForDayDetail();
    }

    function callTachDataForDayDetail() {
        setIsTachoDataLoading(true);
        AssetService.getTachoDataForAsset(assetId, dayDetailDate).then((response) => {
            if (response != null) {
                setTachoDataForDayDetail(response);
                setAssetStatusId(response.vehicleStatus);
                setAssetOwnershipId(response.vehicleOwnership);
                setAssetRegistration(response.vehicleRegistration);

                if (response.hasSpeedData && tabs.length === defaultTabsLength) {
                    setTabs([
                        { title: "Activities", selected: true, key: "ACTIVITIES" },
                        { title: "Speed Data", selected: false, key: "SPEED_DATA" },
                        { title: "History", selected: false, key: "HISTORY" },
                        { title: "Notes", selected: false, key: "NOTES" }
                    ]);
                }
                else if (!response.hasSpeedData && tabs.length === tabLengthWithSpeedData) {
                    setSelectedTab("ACTIVITIES");
                    setTabs([
                        { title: "Activities", selected: true, key: "ACTIVITIES" },
                        { title: "History", selected: false, key: "HISTORY" },
                        { title: "Notes", selected: false, key: "NOTES" }
                    ]);
                }
            }
        }).catch(() => {
            NotificationToaster.show(Intent.DANGER, "Failed to get tacho data");
        }).finally(() => {
            setIsTachoDataLoading(false);
        });
    }

    function callHarshDataForDayDetail() {
        setIsHarshBrakingLoading(true);
        AssetService.getHarshBrakingDataForAsset(assetId, dayDetailDate).then((response) => {
            if (response != null) {
                setHarshBrakingData(response);
            }
        }).catch(() => {
            NotificationToaster.show(Intent.DANGER, "Failed to get harsh braking data", false);
        })
            .finally(() => {
                setIsHarshBrakingLoading(false);
            });
    }

    function callNoteForDayDetail() {
        setIsNotesLoading(true);
        AssetService.getNote(assetId, dayDetailDate).then((response) => {
            const newNote = response && response.note ? response.note : "";
            setNote(newNote);
            setInitialNoteValue(newNote);
            setCurrentNoteValue(newNote);
        }).catch(() => {
            NotificationToaster.show(Intent.DANGER, `Failed to get note`, false);
        }).finally(() => {
            setIsNotesLoading(false);
        });
    }

    function callHistoryDataForDayDetail() {
        setIsHistoryLoading(true);
        AssetService.getAuditTrailForAsset(assetId, dayDetailDate, historyPageNumber, historyPageSize)
            .then((response) => {
                setHistoryData(response.reverse());
            })
            .catch(() => {
                NotificationToaster.show(Intent.DANGER, "Failed to load change log", false);
            }).finally(() => {
                setIsHistoryLoading(false);
            });
    }

    function selectTab(tabName) {
        let tabIndex = tabs.findIndex(t => t.key === tabName);
        if (tabIndex >= 0) {
            onTabClick(tabIndex);
        }
    }

    function reloadForNotes(noteValue) {
        //This would mean the note has been saved or deleted
        if (reloadCalendar) {
            reloadCalendar()
        }
        callHistoryDataForDayDetail();
        setInitialNoteValue(noteValue);
        setNote(noteValue);
    }

    function onHistoryPagingChange(newPageNumber, newPageSize) {
        setHistoryPageNumber(newPageNumber);
        setHistoryPageSize(newPageSize);
    }

    return (
        <Modal
            isOpen={showDayDetailModal}
            onClose={handleCloseModal}
            title={dayDetailTitle}
            icon="warning-sign"
            intent={Intent.DANGER}
            confirmButtonText="Dismiss"
            className="day-detail-modal"
            updateStateOnClose={false}
            clickOutsideClose={!isTachoDataLoading}
        >
            <div className='align-tabs-and-date'>
                <Tabs tabs={tabs} onClick={onTabClick} loading={isTachoDataLoading || isHarshBrakingLoading || isHistoryLoading || isNotesLoading} />
                <FormDatePeriodSelector
                    id="listing-period-new"
                    periodType="day"
                    periodsToShow={0}
                    startDate={date}
                    onChange={onDayChange}
                    dateFormat={periodSelectorDateFormat}
                    showCalendar={true}
                    showText={false}
                    usePortal={false}
                    showResetButton={false}
                />
            </div>

            <ShowHide
                evaluator={selectedTab === "ACTIVITIES"}
                show={<ActivitiesTab
                    lastSearchPayload={lastSearchPayload}
                    activitiesApiResponse={tachoDataForDayDetail}
                    harshBrakingApiResponse={harshBrakingData}
                    isLoading={isTachoDataLoading}
                    vehicleActivityTypes={vehicleActivityTypes}
                    assetDetails={{ assetId, assetStatusId, assetOwnershipId, assetRegistration, statusAttributeMappings, ownershipAttributeMappings }}
                    date={dayDetailDate}
                    refreshTachoAndHistoryData={callUpdateTachoAndHistoryData}
                    modalOpen={showDayDetailModal}
                    reloadCalendar={reloadCalendar}
                />
                }
            />

            <ShowHide
                evaluator={selectedTab === "SPEED_DATA"}
                show={<SpeedDataTab
                    assetId={assetId}
                    date={dayDetailDate}
                    harshBrakingData={harshBrakingData}
                    tachoData={tachoDataForDayDetail}
                    isHarshBrakingLoading={isHarshBrakingLoading}
                />
                }
            />

            <ShowHide
                evaluator={selectedTab === "HISTORY"}
                show={<HistoryTab
                    historyData={historyData}
                    isLoading={isHistoryLoading}
                    onPagingChange={onHistoryPagingChange} />
                }
            />

            <ShowHide
                evaluator={selectedTab === "NOTES"}
                show={<NotesTab
                    assetId={assetId}
                    date={dayDetailDate}
                    note={currentNoteValue}
                    setNote={setCurrentNoteValue}
                    isLoading={isNotesLoading && isTachoDataLoading}
                    reloadContainer={reloadForNotes}
                />
                }
            />

            {showAlert && (
                <AlertUnsavedData
                    isDirty={isNotesDataDirty}
                    isDirtySamePage={isNotesDataDirty}
                    onConfirmLeave={handleConfirmLeave}
                    onCancelLeave={handleCancelLeave}
                />
            )}
        </Modal>
    )
}

DayDetailModal.defaultProps = {
    showDayDetailModal: false,
    closeDayDetailModal: () => { },
    assetId: 0,
    date: "",
    statusAttributeMappings: [],
    ownershipAttributeMappings: []
};

DayDetailModal.propTypes = {
    showDayDetailModal: PropTypes.bool.isRequired,
    closeDayDetailModal: PropTypes.func,
    assetId: PropTypes.number,
    date: PropTypes.string,
    statusAttributeMappings: PropTypes.array,
    ownershipAttributeMappings: PropTypes.array,
    vehicleActivityTypes: PropTypes.array.isRequired,
    lastSearchPayload: PropTypes.object,
    openTabName: PropTypes.string,
    reloadCalendar: PropTypes.func
};
import React, { useState, useEffect } from 'react';
import { Intent, Icon } from '@blueprintjs/core';
import { Breadcrumbs } from 'components/navigation';
import { NotificationToaster, AlertUnsavedData } from "components/notifications";
import { LinkButton, Button } from "components/buttons";
import { ShowHide } from "components/layout";
import { Wizard } from "components/wizard";
import { PolicySettings } from 'components/settings';
import { UserAccess, UserHasAccess } from 'components/user-access';
import { EmployeeService } from "services";
import { PageRestricted } from 'pages/errors/page-restricted';
import { useDirtyData } from "hooks/useDirtyData";
import { DeclarationSettings } from '../../../components/settings/DeclarationSettings';
import moment from 'moment';


export function PolicyCreate(props) {

    const policyModel = {
        name: "",
        startDate: moment().format('YYYY-MM-DD'),
        endDate: "",
        employeeCategorys: [],
        employeeTypes: [],
        locations: [],
        resendSchedule: {
            reoccurring: false
        },
        resendPeriodId: null,
        useAllLocations: true,
        policyDocuments: [],
        sendDebriefAlert: true,
        debriefAlertDelayDays: 0
    };

    const [originalPolicySettings, setOriginalPolicySettings] = useState(policyModel);
    const [policySettingValid, setPolicySettingValid] = useState(true);
    const [policyCreated, setPolicyCreated] = useState(false);
    const [policySettings, setPolicySettings] = useState(originalPolicySettings);
    const isPolicyDataDirty = useDirtyData(originalPolicySettings, policySettings);

    const [loading, setLoading] = useState(true);
    const [saving, setSaving] = useState(false);
    const requiredAction = "EmployeeDeclaration:Manage";
    const canAccessPage = UserHasAccess(requiredAction);

    const [wizardStep, setWizardStep] = useState(0);
    const [wizardValid, setWizardValid] = useState(false);
    useEffect(initialLoad, []);

    function initialLoad() {

        if (!canAccessPage) {
            return;
        }

        setLoading(false);
    }

    function onPolicySettingValidationChange(isValid) {
        setPolicySettingValid(isValid);
    }

    function onPolicySettingsChange(item) {
        setPolicySettings({
            ...policySettings,
            ...item
        })
    }

    function savePolicy() {
        setSaving(true);

        if (!policySettingValid) {
            NotificationToaster.show(Intent.WARNING, "There are errors on the page that need your attention");
            setSaving(false);
            return;
        }

        EmployeeService.createPolicy(policySettings).then(
            () => {
                setPolicyCreated(true);
                setSaving(false);
                setOriginalPolicySettings(policyModel);
            },
            () => {
                setSaving(false);
                NotificationToaster.show(Intent.DANGER, "Could not create the policy. Please try again.");
            }
        );
    }

    function resetComponent() {

        setPolicySettings(policyModel);
        initialLoad();
        setSaving(false);
        setPolicyCreated(false);
        setWizardStep(0);
    }

    function onWizardChange(step) {
        setWizardStep(step);
    }

    useEffect(setIfWizardValid, [wizardStep, policySettingValid]);

    function setIfWizardValid() {
        var valid = (wizardStep === 0 && policySettingValid) || (wizardStep === 1 && policySettingValid);
        setWizardValid(valid);
    }

    return (

        <UserAccess perform={requiredAction}
            yes={() => (
                <div className="row">

                    <Breadcrumbs items={props.breadcrumbs} />

                    <ShowHide
                        evaluator={!policyCreated}
                        show={(
                            <div>
                                <Wizard onStepChange={onWizardChange} onFinish={savePolicy} canProceed={wizardValid} disabled={saving}>

                                    <div>
                                        <h1 id="header-new-user">Add a New Policy</h1>
                                        <PolicySettings
                                            saving={saving}
                                            policy={policySettings}
                                            onSettingsUpdate={onPolicySettingsChange}
                                            onValidationUpdate={onPolicySettingValidationChange}
                                            loading={loading}
                                        />
                                    </div>
                                    <div>
                                        <h1 id="header-new-user">What declarations would you like {policySettings.name} to have?</h1>
                                        <DeclarationSettings
                                            disabled={saving}
                                            declarations={policySettings}
                                            onSettingsUpdate={onPolicySettingsChange}
                                            onValidationUpdate={onPolicySettingValidationChange}
                                            loading={loading}
                                        />
                                    </div>

                                </Wizard>
                                <AlertUnsavedData
                                    isDirty={isPolicyDataDirty}>
                                </AlertUnsavedData>
                            </div>
                        )}
                        hide={(
                            <div>
                                <div className="spacer-bottom" id="create-success-icon">
                                    <Icon icon="tick-circle" iconSize={40} intent={Intent.SUCCESS} />
                                </div>
                                <h2 id="create-success-name">The policy {policySettings.name} has been added</h2>
                                <div className="spacer-bottom">
                                    <p>You can now go back to Policy Management or add another policy.</p>
                                </div>
                                <div className="button-row" id="create-success-buttons">
                                    <LinkButton intent="primary" text="Policy management" id="listing-policy" href="/policy" />
                                    <Button intent="primary" text="Add new policy" id="new-policy" onClick={resetComponent} />
                                </div>
                            </div>
                        )}
                    ></ShowHide>

                </div>
            )}
            no={() => (
                <PageRestricted />
            )}
        />
    );
}
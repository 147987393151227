import React, { useState } from 'react';
import { AuditingTable } from "components/auditing";
import { JobService } from "services";
import { NotificationToaster } from "components/notifications";
import { Intent } from '@blueprintjs/core';
import { DateToLocal } from 'components/dates';

export function JobChangelog(props) {

    const noDataMessage = "It looks like no previous changes have been made to your job.";
    const [tableData, setTableData] = useState(null);
    const [tableRecordCount, setTableRecordCount] = useState(0);
    const [loading, setLoading] = useState(true);

    function onTableChange(pageSize, pageNumber, startDate, endDate) {
        setLoading(true);
        JobService.getChangelog(props.jobId, pageSize, pageNumber, startDate, endDate).then(r => {
            var formattedData = r.data.map(d => [<DateToLocal key={d.date} >{d.date}</DateToLocal>, d.username, d.propertyFriendlyName,
                (d.dataType === 'datetime') ? <DateToLocal inputFormat={"DD/MM/YYYY HH:mm"} hideTimeIfMidnight={d.propertyFriendlyName === 'Completed Date'}>{d.oldValue}</DateToLocal> : d.oldValue,
                (d.dataType === 'datetime') ? <DateToLocal inputFormat={"DD/MM/YYYY HH:mm"} hideTimeIfMidnight={d.propertyFriendlyName === 'Completed Date'}>{d.newValue}</DateToLocal> : d.newValue,
            ]);
            setTableData(formattedData);
            setTableRecordCount(r.totalCount);
            setLoading(false);
        }, (error) => {
            NotificationToaster.show(Intent.DANGER, error);
        });
    }

    return (
        <AuditingTable data={tableData}
            totalCount={tableRecordCount}
            onChange={onTableChange}
            noDataMessage={noDataMessage}
            dataIsFormatted={true}
            tableHeaders={["Date", "User", "Setting", "Old Value", "New Value"]}
            hideHeader={true}
            loading={loading}
            pageSize={100}></AuditingTable>
    );
}
import React, { Fragment, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useParams } from 'react-router-dom';

import { UserAccess, UserHasAccess } from 'components/user-access';
import { Tabs } from 'components/navigation';
import { LinkButton, ButtonSave } from 'components/buttons';
import { Collapsable, ShowHide } from "components/layout";
import { PageRestricted } from 'pages/errors/page-restricted';
import { DataDisplayTable } from 'components/data-display';
import { NotificationInline, NotificationToaster } from "components/notifications";
import { DateToLocal } from "components/dates";
import { SelfReportingService } from "services";

import { ListingTable } from 'components/listing';
import { Intent, NonIdealState } from '@blueprintjs/core';

export function ScheduleRunView(props) {

    const { id, runId } = useParams();
    const requiredAction = "ScheduleReports:Manage";
    const canAccessPage = UserHasAccess(requiredAction);

    const [scheduleInstance, setScheduleInstance] = useState({});
    const [loadingData, setLoadingData] = useState(true);
    const [invalidInstance, setInvalidInstance] = useState(false);
    const [headerSections, setHeaderSections] = useState([]);
    const [reports, setReports] = useState([]);
    const [tabs, setTabs] = useState([{ title: "Details", key: "DETAILS" }, { title: "Stats", key: "STATS" }, { title: "Errors", key: "ERRORS" }]);
    const [selectedTab, setSelectedTab] = useState("");
    const [statsTable, setStatsTable] = useState([]);
    const [errorsTable, setErrorsTable] = useState([]);
    const [statsSortDir, setStatsSortDir] = useState("A");
    const [statsSortColumn, setStatsSortColumn] = useState("Total Reports")

    const statsTableHeaders = ["Email Address", "Total Reports", "Reports Downloaded", "Errored Reports", "Awaiting Creation"]

    useEffect(() => {

        if (!canAccessPage) {
            return;
        }

        if (loadingData) {

            SelfReportingService.getScheduleRun(id, runId).then(r => {
                setScheduleInstance(r);

                const errorTabIndex = 2;
                const detailsTabIndex = 0;

                if (r.errors != null && (r.errors.failedReports || r.errors.errorMessages.length > 0)) {
                    onTabClick(errorTabIndex);
                } else {
                    onTabClick(detailsTabIndex);
                }

                var headers = [
                    { header: "Report generation date", value: <DateToLocal>{r.runDate}</DateToLocal> }
                ];

                setHeaderSections(headers);
                setReports(r.reports.map(function (report, index) {
                    return {
                        ...report,
                        expanded: index === 0 ? true : false
                    };
                }));


                if (r.stats != null) {
                    setStatsTable(r.stats.map((d) => {
                        return [
                            d.emailAddress,
                            d.totalReports,
                            d.reportsDownloaded,
                            d.reportsErrored,
                            d.reportsPending + d.reportingRunning
                        ];
                    }));
                }

                if (r.errors != null && r.errors.errorMessages != null) {
                    setErrorsTable(r.errors.errorMessages.map((d) => {
                        return [
                            d
                        ];
                    }));
                }

                setLoadingData(false);

            }, (error) => {
                const noAccessCode = 403;

                if (error.status === noAccessCode) {
                    setInvalidInstance(true);
                }
            });
        }
    }, [loadingData, id]);

    function onCollapsableClick(index) {
        var clonedReports = [
            ...reports
        ];

        var selectedSection = clonedReports[index];
        selectedSection.expanded = !selectedSection.expanded;

        setReports(clonedReports);

    }

    function onTabClick(index) {
        var clonedTabs = [
            ...tabs
        ];

        clonedTabs = clonedTabs.map(function (t, i) {
            return {
                ...t,
                selected: index === i
            }
        });

        setTabs(clonedTabs);
        setSelectedTab(tabs[index].key);
    }

    function onFailedReportsRetry() {

        SelfReportingService.retrySchedule(id, runId).then(function () {
            var instanceErrors = {
                ...scheduleInstance.errors
            };

            instanceErrors.reportsGenerating = true;

            setScheduleInstance({
                ...scheduleInstance,
                errors: instanceErrors
            });
        }, function () {
            NotificationToaster.show(Intent.DANGER, "Unable to fetch schedule information, please try again.");
        });
        
    }

    function onStatsSort(column, direction) {
        setStatsSortColumn(column);
        setStatsSortDir(direction);

        var sortedStats = [
            ...statsTable
        ];

        var columnIndex = statsTableHeaders.indexOf(column)
        sortedStats.sort(function (a, b) {
            return sortItems(a, b, columnIndex, direction)
        });
        setStatsTable(sortedStats);
    }

    function sortItems(originalValue, compareValue, column, direction) {

        if (direction === "A") {
            return originalValue[column] < compareValue[column] ? 1 : -1;
        } else {
            return originalValue[column] > compareValue[column] ? 1 : -1;
        }
    }

    return (

        <UserAccess perform={requiredAction}
            yes={() => (

                <ShowHide
                    evaluator={invalidInstance}
                    show={(
                        <div className="row">
                            <NotificationInline
                                allowClose={false}
                                show
                                text="You do not have permission to view this report"
                                intent="danger">
                            </NotificationInline>
                            <div>
                                <LinkButton intent="primary" text="Back to schedule" href="/schedule" id="return-schedule-runs" />
                            </div>
                        </div>
                    )}
                    hide={(
                        <Fragment>
                            <div className="row">
                                <h1>{scheduleInstance.instanceName}</h1>

                                <div className="spacer-bottom">
                                    <Tabs tabs={tabs} onClick={onTabClick} loading={loadingData} />
                                </div>


                                <ShowHide
                                    evaluator={selectedTab === "DETAILS"}
                                    show={(
                                        <Fragment>
                                            <DataDisplayTable
                                                data={headerSections}
                                                loading={props.loadingData}
                                                displayInRows={false}
                                            />

                                            {
                                                reports && reports.map((report, index) => {
                                                        return (
                                                            <Collapsable
                                                                key={report.reportGenerationId}
                                                                title={report.reportName}
                                                                fullWidth
                                                                loading={loadingData}
                                                                onClick={() => onCollapsableClick(index)}
                                                                expanded={report.expanded}
                                                            >
                                                                <div className="spacer-bottom">
                                                                    <DataDisplayTable
                                                                        displayInRows
                                                                        data={report.parameters.map((p) => {
                                                                            return { header: p.name, value: p.value }
                                                                        })}
                                                                        loading={props.loadingData}
                                                                    />
                                                                </div>

                                                            </Collapsable>);
                                                    })
                                            }
                                        </Fragment>
                                    )}
                                />

                                <ShowHide
                                    evaluator={selectedTab === "STATS"}
                                    show={(
                                        <Fragment>
                                            <ListingTable
                                                id="listing-table-schedule-run-stats"
                                                headers={statsTableHeaders}
                                                loadingData={loadingData}
                                                noDataMessage={"No stats are available for this run"}
                                                data={statsTable}
                                                totalRecordCount={statsTable.length}
                                                sortable
                                                sortableHeaders={["Email Address", "Total Reports", "Reports Downloaded", "Errored Reports", "Awaiting Creation"]}
                                                sortedBy={statsSortColumn}
                                                sortedDir={statsSortDir}
                                                onSort={onStatsSort}
                                                pageable={false}
                                            />

                                        </Fragment>
                                    )}
                                />

                                <ShowHide
                                    evaluator={selectedTab === "ERRORS"}
                                    show={(

                                        <ShowHide
                                                evaluator={scheduleInstance.instanceComplete}
                                                show={(
                                                    <Fragment>

                                                        <ShowHide
                                                            evaluator={scheduleInstance.errors?.failedReports || scheduleInstance.errors?.reportsGenerating}
                                                            show={(
                                                                <div className="spacer-bottom">
                                                                    <h2>Report Creation Errors</h2>

                                                                    <ShowHide
                                                                        evaluator={scheduleInstance.errors?.reportsGenerating}
                                                                        show={(
                                                                            <div className="spacer-top">
                                                                                <NotificationInline allowClose={false} intent="primary" text={"Failed reports are being regenerated, this may take some time, progress can be tracked on the stats tab"} show />
                                                                            </div>
                                                                        )}
                                                                        hide={(
                                                                            <div className="spacer-top">
                                                                                <NotificationInline allowClose={false} intent="danger" text={"A number of reports were unable to be generated for the users of this schedule. Please click the button below to try and regenerate the reports for your user. Emails will not be sent to the user on completion, but will be available through view screen on Vision."} show />
                                                                            </div>
                                                                        )}
                                                                    />

                                                                    <ButtonSave icon="refresh" text="Regenerate failed reports" disabled={scheduleInstance.errors?.reportsGenerating} onClick={onFailedReportsRetry} />


                                                                </div>
                                                            )}
                                                        />

                                                        <ShowHide
                                                            evaluator={errorsTable.length > 0}
                                                            show={(
                                                                <div className="spacer-bottom">
                                                                    <h2>General Errors</h2>
                                                                    <div className="intro-text">
                                                                        <p>These errors can be fixed by managing the users or reports included in the schedule.</p>
                                                                        <ListingTable
                                                                            id="listing-table-schedule-general-errors"
                                                                            headers={["Error Message"]}
                                                                            loadingData={loadingData}
                                                                            data={errorsTable}
                                                                            totalRecordCount={errorsTable.length}
                                                                            pageable={false}
                                                                        />
                                                                    </div>

                                                                </div>
                                                            )}
                                                        />

                                                        <ShowHide
                                                            evaluator={errorsTable.length === 0 && !scheduleInstance.errors?.failedReports && !scheduleInstance.errors?.reportsGenerating}
                                                            show={(
                                                                <div className="spacer-bottom">
                                                                    <NonIdealState
                                                                        title="Nothing to see here..."
                                                                        description="Your schedule ran successfully without any errors!"
                                                                    />
                                                                </div>
                                                            )}
                                                        />



                                                    </Fragment>
                                                )}
                                                hide={(
                                                    <NotificationInline allowClose={false} intent="primary" text={"Your schedule is still running. Error details will be available once all reports have been generated. You can track the current progress of the schedule via the stats tab."} show />
                                                )}
                                            />

                                            

                                            
                                    )}
                                />

                            </div>
                        </Fragment>
                    )}
                />


            )}
            no={() => (
                <PageRestricted />
            )}
        />

    );

}

ScheduleRunView.propTypes = {
    scheduleId: PropTypes.number.isRequired
};
import React, { useState, useRef, useEffect, Fragment } from 'react';
import { Link } from "react-router-dom";

import { useDebounce } from 'hooks/useDebounce';
import { Breadcrumbs } from 'components/navigation';
import { FormTextInput } from "components/form-fields";
import { ListingTable } from "components/listing";
import { ShowHide } from "components/layout";
import { PageRestricted } from 'pages/errors/page-restricted';
import { UserAccess, UserHasAccess } from 'components/user-access';
import { CustomDashboardService } from 'services/CustomDashboardService';
import { LinkButton } from 'components/buttons';

export function CustomDashboardListing(props) {

    const defaultRecordSize = 25;
    const debounceTimeout = 750;

    const [totalRecords, setTotalRecords] = useState(0);
    const [tableRows, setTableRows] = useState([]);
    const [pageNumber, setPageNumber] = useState(1);
    const [pageSize, setPageSize] = useState(defaultRecordSize);
    const [searchTerm, setSearchTerm] = useState("");
    const [loadingData, setLoadingData] = useState(true);
    const [noDataMessage, setNoDataMessage] = useState("You have no custom dashboards set up, please click 'Add new dashboard' to get started.");
    const tableHeaders = ["Name", "Description"];
    const debouncedSearchTerm = useDebounce(searchTerm, debounceTimeout);
    const inputRef = useRef(null);
    const requiredAction = "internal";
    const canAccessPage = UserHasAccess(requiredAction);

    const currentPageNumber = useRef();
    const currentPageSize = useRef();
    const currentSearchTerm = useRef();

    useEffect(() => {
        setPageNumber(1);
        setNoDataMessage("No results found, please try a different search term.");
        setLoadingData(true);
    }, [debouncedSearchTerm]);

    useEffect(() => {
        fetchTableData();
    }, [loadingData, pageSize, pageNumber, debouncedSearchTerm]);

    function fetchTableData() {

        debugger;
        if (canAccessPage) {
            if (
                currentPageNumber.current === pageNumber &&
                currentPageSize.current === pageSize &&
                currentSearchTerm.current === debouncedSearchTerm
            ) {
                //If none of the values have changed, then 2 have been updated at once, so prevent the listing from performing another call.
                return;
            }

            currentPageNumber.current = pageNumber;
            currentPageSize.current = pageSize;
            currentSearchTerm.current = debouncedSearchTerm;

            CustomDashboardService.getDashboards(pageSize, pageNumber, debouncedSearchTerm).then(r => {
                const results = r.data;
                let data = [];

                if (results != null) {
                    data = results.map((d) => {
                        return [
                            (<Link key={d.dashboardId} to={`/dashboards/custom/${d.dashboardId}`}>{d.dashboardName}</Link>),
                            d.description
                        ];
                    });
                }

                setTableRows(data);
                setTotalRecords(r.totalCount);
                setLoadingData(false);
                setTimeout(function () {
                    if (inputRef.current != null) {
                        inputRef.current.focus();
                    }
                });

            });
        }
    }

    function onPagingChange(newPageNumber, newPageSize) {
        setPageNumber(newPageNumber);
        setPageSize(newPageSize);
    }

    function onSearchChange(item) {
        setSearchTerm(item.target.value);
    }

    return (
        <UserAccess perform={requiredAction}
            yes={() => (
                <div className="row">

                    <Breadcrumbs items={props.breadcrumbs} />

                    <h1>Custom dashboards</h1>
                    <div className="intro-text">
                        <p>In this section you can add, edit and view your custom dashboards.</p>
                    </div>

                    <div className="button-row button-row-stacked-mobile spacer-bottom">
                        <div>
                            <LinkButton intent="primary" text="Add custom dashboard" href="/dashboards/custom/create" id="new-dashboard" />
                        </div>
                    </div>

                    <ShowHide
                        evaluator={tableRows.length === 0 && !loadingData && debouncedSearchTerm === ""}
                        hide={(
                            <Fragment>
                                <div className="pull-left">
                                    <div className="inline-items">
                                        <FormTextInput inputRef={inputRef} placeholder="Dashboard Search" onChange={onSearchChange} value={searchTerm} large disabled={loadingData} icon="search" id="dashboard-search-field" />
                                    </div>
                                </div>
                            </Fragment>
                        )}
                    />

                    <ListingTable
                        id="listing-table-custom-dashboards"
                        headers={tableHeaders}
                        data={tableRows}
                        totalRecordCount={totalRecords}
                        onPagingChange={onPagingChange}
                        loadingData={loadingData}
                        noDataMessage={noDataMessage}
                    />
                </div>
            )}
            no={() => (
                <PageRestricted />
            )}
        />
    );
}
import classNames from 'classnames';
import { Expandable, ShowHide } from 'components/layout';
import { EditTable } from 'components/listing';
import PropTypes from 'prop-types';
import React, { Fragment, useState } from 'react';
import { OcrVarianceTableForm } from '.';
import { Money } from 'components/formatting/Money';

export function OcrVarianceTableLayout(props) {
    const successfulCellClassName = 'cell-good';
    const unsuccessfulCellClassName = 'cell-danger';
    const maxDecimalPlaces = 2;
    const percentageRowIdx = 2;
    const oneHundred = 100;

    const [rowIndexBeingEdited, setRowIndexBeingEdited] = useState(null);
    const [rowValidation, setRowValidation] = useState([]);

    function formatRows() {
        if (!props.layout.varianceTable.data) {
            return [];
        }

        var rows = props.layout.varianceTable.data.map((row, rowIndex) => {
            const canEditRow = (row.previousEditable || row.currentEditable) && !props.readonly;

            //If precentage row then we know we have not done and done. So first row bigger is worse and second row bigger is better.
            if (props.addPercentageRow && rowIndex === 1) {
                row.biggerCurrentIsBetter = true;
            }

            row.context = props.layout.table.columns[0].context;

            var rowValid = true;
            const foundValidValue = rowValidation.find(e => e.id === rowIndex);

            if (foundValidValue) {
                rowValid = foundValidValue.value
            }

            return {
                key: rowIndex,
                cells: formatRow(row, false),
                isEditable: canEditRow,
                validity: canEditRow ? getRowValidityIndicator(row, rowIndex) : <Fragment />,
                valid: rowValid
            };
        });

        // Add percentage row if needed.
        if (props.addPercentageRow && props.layout.varianceTable.data.length === percentageRowIdx) {
            rows.push({
                key: 2,
                cells: formatRow(getPercentageRow(), true),
                isEditable: false,
                validity: <Fragment />,
                valid: true
            });
        }

        return rows;
    }

    function formatRow(row, percentageRow) {
        const cells = [];

        if (row.title || props.layout.varianceTable.titleColumn) {
            cells.push(row.title);
        }
                

        const variance = (props.showVariance || percentageRow) ? calculateVariance(row) : '';
        const varianceClassName = (props.showVariance || percentageRow) ? getVarianceClassName(row, variance) : '';


        cells.push(formatCell(row.dataType, row.currentVisit, varianceClassName));
        cells.push(formatCell(row.dataType, row.previousVisit));
        cells.push(formatCell(row.dataType, variance, varianceClassName));
        return cells;
    }

    function calculateVariance(row) {
        if (!row ||
            row.currentVisit == null ||
            row.previousVisit == null) {
            return null;
        }

        const variance = row.currentVisit - row.previousVisit;
        let formattedVariance = null;

        switch (row.dataType.toUpperCase()) {
            case 'DECIMAL':
            case 'PERCENTAGE':
            case 'FIXED':
            case 'POUND':
                formattedVariance = +variance.toFixed(maxDecimalPlaces);
                break;
            default:
                formattedVariance = Math.round(variance);
                break;
        }

        return formattedVariance;
    }

    function getVarianceClassName(row, variance) {
        let className = '';

        if (row.biggerCurrentIsBetter) {
            if (variance > 0) {
                className = successfulCellClassName;
            } else if (variance < 0) {
                className = unsuccessfulCellClassName;
            }
        } else {
            if (variance > 0) {
                className = unsuccessfulCellClassName;
            } else if (variance < 0) {
                className = successfulCellClassName;
            }
        }

        return className;
    }

    function onEditFinish(varianceData) {
        const dataClone = [...props.layout.varianceTable.data];
        dataClone[rowIndexBeingEdited] = varianceData;

        const varianceTableClone = {
            ...props.layout.varianceTable,
            data: dataClone
        };

        props.onChange({
            ...props.layout,
            varianceTable: varianceTableClone
        });

        setRowIndexBeingEdited(null);
    }

    function onEditClose() {
        setRowIndexBeingEdited(null);
    }

    function onEditClick(rowIndex) {
        setRowIndexBeingEdited(rowIndex);
    }

    function formatCell(dataType, value, className) {
        return <Fragment>
            <ShowHide
                evaluator={dataType.toUpperCase() === 'POUND'}
                show={(
                    <span className={className}><Money>{value}</Money></span>
                )}
                hide={(<span className={classNames(className, { 'cell-percentage-suffix': dataType.toUpperCase() === 'PERCENTAGE' })}>{value == null ? '-' : value}</span>)}
            />
            
        </Fragment>;
    }

    function formatHeaders() {
        const headers = [];

        if (props.layout.varianceTable.titleColumn || props.layout.varianceTable.data.some(row => row.title)) {
            headers.push(props.layout.varianceTable.titleColumn);
        }

        headers.push(props.layout.table.columns[0].context ? 'Current ' + props.layout.table.columns[0].context : 'Current visit');
        headers.push(props.layout.table.columns[1].context ? 'Previous ' + props.layout.table.columns[1].context : 'Previous visit');
        headers.push('Variance');

        return {
            headers: headers.map(header => {
                return {
                    key: header,
                    value: header
                };
            })
        };
    }

    function onRowValidChange(rowIndex, isValid) {
        setRowValidation(function (prevState) {

            var clonedArray = [
                ...prevState
            ];

            const foundValue = clonedArray.find(e => e.id === rowIndex);

            if (foundValue) {
                foundValue.value = isValid;
            } else {
                clonedArray.push({ id: rowIndex, value: isValid });
            }

            return clonedArray;
        });
    }

    function getRowValidityIndicator(row, rowIndex) {
        return <OcrVarianceTableForm
            varianceData={row}
            modalOpen={rowIndexBeingEdited === rowIndex}
            onFinish={onEditFinish}
            onClose={onEditClose}
            calculateVariance={calculateVariance}
            dataType={row.dataType}
            onValidationUpdate={(isValid) => { onRowValidChange(rowIndex, isValid) }}
        />;
    }

    function getPercentageRow() {
        var current = getPercentage(props.layout.varianceTable.data, 'currentVisit');
        var previous = getPercentage(props.layout.varianceTable.data, 'previousVisit');
        var mainTitle = (props.layout.layoutTitle) ? props.layout.layoutTitle : '';
        var title = (props.layout.secondaryLayoutTitle) ? props.layout.secondaryLayoutTitle : mainTitle;

        return {
            currentVisit: current == null ? null : current.toFixed(maxDecimalPlaces),
            previousVisit: previous == null ? null : previous.toFixed(maxDecimalPlaces),
            title: 'Percentage ' + title.toLowerCase(),
            dataType: 'PERCENTAGE',
            biggerCurrentIsBetter: true
        };
    }

    function getPercentage(data, type) {
        if (data.length < percentageRowIdx || data[0][type] == null || data[1][type] == null) {
            return null;
        }
        var done = parseInt(data[1][type]);
        var notdone = parseInt(data[0][type]);

        if (done + notdone === 0) {
            return null;
        }

        return ((done / (done + notdone)) * oneHundred);
    }

    function renderTable() {
        return <EditTable
            headerData={formatHeaders()}
            data={formatRows()}
            loadingData={props.loading}
            noDataHeading=''
            noDataMessage={props.layout.varianceTable.emptyMessage}
            onEditRowClick={onEditClick}
            disabled={props.disabled || props.loading || props.readonly}
            fitToContent={true}
        />
    }

    return <div className='spacer-bottom'>
        <ShowHide
            evaluator={!!props.layout.secondaryLayoutTitle}
            show={<h4>{props.layout.secondaryLayoutTitle}</h4>}
        />
        <ShowHide
            evaluator={props.tableExpandable}
            hide={renderTable()}
            show={
                <Expandable
                    expanded={props.tableExpanded}
                    loading={props.loading}
                >
                    {renderTable()}
                </Expandable>
            }
        />
    </div>;
}

OcrVarianceTableLayout.propTypes = {
    loading: PropTypes.bool,
    layout: PropTypes.object.isRequired,
    onChange: PropTypes.func.isRequired,
    disabled: PropTypes.bool,
    addPercentageRow: PropTypes.bool,
    readonly: PropTypes.bool,
    tableExpanded: PropTypes.bool,
    tableExpandable: PropTypes.bool,
    showVariance: PropTypes.bool
};

OcrVarianceTableLayout.defaultProps = {
    loading: false,
    disabled: false,
    addPercentageRow: false,
    readonly: false,
    tableExpanded: false,
    tableExpandable: false,
    showVariance: true
};

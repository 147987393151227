import { Intent } from '@blueprintjs/core';
import { ButtonSave } from 'components/buttons';
import { FormSwitch } from 'components/form-fields';
import { AlertUnsavedData, NotificationToaster } from 'components/notifications';
import { useDirtyData } from 'hooks/useDirtyData';
import React, { useEffect, useState } from 'react';
import { EmployeeService } from 'services';

export function EmployeeTrainingSettings() {
    const defaultSettings = {
        dcpcActive: false
    };

    const [trainingSettings, setTrainingSettings] = useState(defaultSettings);
    const [originalSettings, setOriginalSettings] = useState(defaultSettings);
    const [loading, setLoading] = useState(true);
    const [saving, setSaving] = useState(false);

    const isDataDirty = useDirtyData(originalSettings, trainingSettings);

    useEffect(initialLoad, []);

    function initialLoad() {
        EmployeeService.getTrainingSettings().then(
            (result) => {
                setTrainingSettings(result);
                setOriginalSettings(result);
                setLoading(false);
            },
            () => {
                NotificationToaster.show(Intent.DANGER, "Could not load settings. Please refresh the page.");
            });
    }

    function saveSettings() {
        setSaving(true);

        EmployeeService.saveTrainingSettings(trainingSettings)
            .then(
                () => {
                    setOriginalSettings(trainingSettings);
                    NotificationToaster.show(Intent.SUCCESS, "Settings updated successfully.");
                    setSaving(false);
                },
                () => {
                    NotificationToaster.show(Intent.DANGER, "Could not save settings. Please try again.");
                    setSaving(false);
                },
            );
    }

    function onDcpcActiveUpdate(event) {
        const settingsClone = { ...trainingSettings };

        settingsClone.dcpcActive = event.target.checked;

        setTrainingSettings(settingsClone);
    }

    return (
        <div className="row">
            <h2>Training Settings</h2>
            <FormSwitch
                disabled={saving}
                headingText="Enable DCPC tracking"
                onChange={onDcpcActiveUpdate}
                loading={loading}
                helperText="Enabling this setting will result in Vision tracking DCPC training for all core employees who are either active or away. If this setting is disabled, then Vision will not track DCPC training for any employees."
                checked={trainingSettings.dcpcActive}
                id="dcpc-tracker-switch"
            ></FormSwitch>

            <AlertUnsavedData
                isDirty={isDataDirty}>
            </AlertUnsavedData>
            <ButtonSave
                onClick={saveSettings}
                disabled={saving}
                loading={loading}
            ></ButtonSave>
        </div>
    );
}
import React, { useState, useRef, useEffect, Fragment } from 'react';
import { Link } from "react-router-dom";
import { useDebounce } from 'hooks/useDebounce';

import { FormTextInput } from "components/form-fields";
import { Breadcrumbs } from 'components/navigation';
import { LinkButton } from 'components/buttons'
import { ListingTable } from "components/listing";
import { ShowHide } from "components/layout";
import { SelfReportingService } from 'services';
import { DateToLocal } from "components/dates";
import { PageRestricted } from 'pages/errors/page-restricted';
import { UserAccess, UserHasAccess } from 'components/user-access';


export function SelfReportingAdminListing(props) {

    const defaultRecordSize = 25;
    const debounceTimeout = 750;

    const [initalSetupComplete, setInitalSetupComplete] = useState(false);
    const [totalRecords, setTotalRecords] = useState(0);
    const [rawTableData, setRawTableData] = useState([]);
    const [tableRows, setTableRows] = useState([]);
    const [pageNumber, setPageNumber] = useState(1);
    const [pageSize, setPageSize] = useState(defaultRecordSize);
    const [searchTerm, setSearchTerm] = useState("");

    const [loadingData, setLoadingData] = useState(false);
    const [noDataMessage, setNoDataMessage] = useState("You have no reports set up, please click 'Add new report' to get started.");
    const tableHeaders = ["Report Name", "Report Groups", "Created", "Last Updated"];
    const sortableHeaders = ["Report Name", "Created", "Last Updated"];
    const [tableSortBy, setTableSortBy] = useState("Report Name");
    const [tableSortDir, setTableSortDir] = useState("A");

    const debouncedSearchTerm = useDebounce(searchTerm, debounceTimeout);
    const inputSearchRef = useRef(null);

    const currentPageNumber = useRef();
    const currentSearchTerm = useRef();
    const currentPageSize = useRef();
    const currentTableSortBy = useRef();
    const currentTableSortDir = useRef();


    const requiredAction = "Internal";
    const canAccessPage = UserHasAccess(requiredAction);

    useEffect(() => {
        setPageNumber(1);
        setNoDataMessage("No results found, please try a different search term.");
    }, [debouncedSearchTerm]);


    function fetchTableData(forceRefresh) {
        if (canAccessPage && initalSetupComplete) {

            if (
                currentPageNumber.current === pageNumber &&
                currentPageSize.current === pageSize &&
                currentSearchTerm.current === debouncedSearchTerm &&
                currentTableSortBy.current === tableSortBy &&
                currentTableSortDir.current === tableSortDir &&
                !forceRefresh
            ) {
                //If none of the values have changed, then 2 have been updated at once, so prevent the listing from performing another call.
                return;
            }

            currentPageNumber.current = pageNumber;
            currentPageSize.current = pageSize;
            currentSearchTerm.current = debouncedSearchTerm;
            currentTableSortBy.current = tableSortBy;
            currentTableSortDir.current = tableSortDir;

            setLoadingData(true);
            SelfReportingService.getReportList(pageSize, pageNumber, debouncedSearchTerm, tableSortBy, tableSortDir).then(function (r) {
                setRawTableData(r.data);
                setTotalRecords(r.totalCount);
                setLoadingData(false);
                
                setTimeout(function () {
                    if (inputSearchRef.current != null) {
                        inputSearchRef.current.focus();
                    }
                });
            });
        }
    }

    useEffect(() => {
        fetchTableData(false);
    }, [pageSize, pageNumber, tableSortBy, tableSortDir, debouncedSearchTerm, initalSetupComplete]);

    useEffect(() => {
        setInitalSetupComplete(true);
    }, []);

    useEffect(() => {

        let data = [];

        if (rawTableData != null) {
            data = rawTableData.map((d) => {

                var reportGroups = d.reportGroups.map(b => {
                    return b.name;
                });

                return [
                    <Link to={`/internal-admin/self-service-reports/${d.reportId}`}>{d.name}</Link>,
                    reportGroups.join(", "),
                    <DateToLocal>{d.createdDate}</DateToLocal>,
                    <DateToLocal>{d.modifyDate}</DateToLocal>
                ];
            })
        }

        setTableRows(data);
    }, [rawTableData]);


    function onTableSort(header, direction) {
        setTableSortDir(direction);
        setTableSortBy(header);
    }

    function onSearchChange(item) {
        setSearchTerm(item.target.value);
    }

    function onPagingChange(newPageNumber, newPageSize) {
        setPageNumber(newPageNumber);
        setPageSize(newPageSize);
    }

    return (
        <UserAccess perform={requiredAction}
            yes={() => (
                <div className="row">

                    <Breadcrumbs items={props.breadcrumbs} />

                    <h1>Report Management</h1>
                    <div className="intro-text">
                        <p>From here you can manage the self service reports that appear in Vision</p>
                    </div>

                    <div className="spacer-bottom">
                        <LinkButton intent="primary" text="Add new report" href="/internal-admin/self-service-reports/create" id="new-report" />
                    </div>

                    <ShowHide
                        evaluator={tableRows.length === 0 && !loadingData && debouncedSearchTerm === "" && initalSetupComplete}
                        hide={(
                            <Fragment>
                                <div className="pull-left">
                                    <div className="inline-items">
                                        <FormTextInput inputRef={inputSearchRef} placeholder="Report Search" onChange={onSearchChange} value={searchTerm} large disabled={loadingData} icon="search" id="user-search-field" />
                                    </div>
                                </div>
                            </Fragment>
                        )}
                    />

                    <ListingTable
                        id="listing-table-user"
                        headers={tableHeaders}
                        data={tableRows}
                        totalRecordCount={totalRecords}
                        onPagingChange={onPagingChange}
                        loadingData={loadingData}
                        noDataMessage={noDataMessage}
                        sortable
                        sortableHeaders={sortableHeaders}
                        sortedBy={tableSortBy}
                        sortedDir={tableSortDir}
                        onSort={onTableSort}
                    />

                </div>
            )}
            no={() => (
                <PageRestricted />
            )}
        />
    );

}
import React, { Fragment, useState, useEffect, useRef } from 'react';
import { AlertUnsavedData, NotificationToaster } from "components/notifications";
import { ButtonSave, Button } from "components/buttons";
import { useDirtyData } from "hooks/useDirtyData";
import { UserBasicSettings } from "components/settings";
import { ShowHide } from 'components/layout';
import { ListingTable } from "components/listing";
import { FormSwitch } from "components/form-fields";
import { Alignment, Intent } from "@blueprintjs/core";

import PropTypes from 'prop-types';
import moment from "moment";

var _ = require('underscore');

export function ExternalContactSetting(props) {

    const externalContactModel = {
        emailAddress: "",
        firstName: "",
        lastName: "",
        phoneNumber: "",
        secondaryPhoneNumber: "",
        verified: true,
        status: "",
        createDate: moment().utc().format("YYYY-MM-DD HH:mm"),
        locations: [],
        userType: "External",
        notificationUpcomingVisit: true,
        notificationUpcomingVisitAlteration: true,
        notificationReport: true,
        LocationExternalContactIdsToDelete: []
    };

    const [externalContactValid, setExternalContactValid] = useState(true);
    const [originalValues, setOriginalValues] = useState({});
    const [externalContact, setExternalContact] = useState(externalContactModel);
    const [tableHeaders] = useState(["Location Name", "Contact Type"]);
    const [tableRows, setTableRows] = useState([]);
    const inputRef = useRef(null);
    const isDataDirty = useDirtyData(originalValues, externalContact);

    useEffect(init, [props.loading]);

    function init() {
        if (props.loading) {
            return;
        }

        if (props.editing) {
            props.externalContact.LocationExternalContactIdsToDelete = [];
            setExternalContact(props.externalContact);
            setOriginalValues(_.extend({}, props.externalContact));
        } else {
            setExternalContact(externalContactModel);
            setOriginalValues(_.extend({}, externalContactModel));
            setTimeout(() => {
                if (inputRef.current != null) {
                    inputRef.current.focus();
                }
            });
        }
    }

    function onSettingsUpdate(userUpdate) {
        setExternalContact((prevExternalContact) => {
            return {
                ...prevExternalContact,
                emailAddress: userUpdate.emailAddress,
                firstName: userUpdate.firstName,
                lastName: userUpdate.lastName,
                phoneNumber: userUpdate.phoneNumber,
                secondaryPhoneNumber: userUpdate.secondaryPhoneNumber
            }
        });
    }

    function onExternalContactValidationChange(isValid) {
        setExternalContactValid(isValid);
    }

    function onNotificationUpcomingVisitChange(event) {
        let checked = event.target.checked;
        setExternalContact((prevExternalContact) => {
            return {
                ...prevExternalContact,
                notificationUpcomingVisit: checked
            }
        });
    }

    function onNotificationUpcomingVisitAlterationChange(event) {
        let checked = event.target.checked;
        setExternalContact((prevExternalContact) => {
            return {
                ...prevExternalContact,
                notificationUpcomingVisitAlteration: checked
            }
        });
    }

    function deleteLocation(locationExternalContactId) {
        setExternalContact((prevExternalContact) => {
            return {
                ...prevExternalContact,
                locations: prevExternalContact.locations.filter(function (location) { return location.locationExternalContactId !== locationExternalContactId }),
                LocationExternalContactIdsToDelete: [...prevExternalContact.LocationExternalContactIdsToDelete, locationExternalContactId]
            }
        });
    }

    function onSave() {
        props.onExternalContactSave(externalContact).then(
            (successMessage) => {
                NotificationToaster.show(Intent.SUCCESS, successMessage);
                if (props.editing) {
                    setOriginalValues(_.extend({}, {
                        ...externalContact,
                        LocationExternalContactIdsToDelete: []
                    }));
                    setExternalContact((prevExternalContact) => {
                        return {
                            ...prevExternalContact,
                            LocationExternalContactIdsToDelete: []
                        }
                    });
                } else {
                    setExternalContact(externalContactModel);
                    if (inputRef.current != null) {
                        inputRef.current.focus();
                    }
                }
            },
            (error) => {
                NotificationToaster.show(Intent.DANGER, error);
            });
    }

    useEffect(() => {
        let data = [];
        if (externalContact.locations != null && externalContact.locations.length > 0) {
            data = externalContact.locations.map((d) => {
                return [
                    d.name,
                    d.contactTypeName,
                    <div className="button-row-small" key={"actions-" + d.id}>
                        <Button disabled={!externalContact.verified} onClick={() => deleteLocation(d.locationExternalContactId)} icon="trash" iconOnly={true} text="Delete External Contact" large={false} minimal={true}></Button>
                    </div>];
            })
        }
        setTableRows(data);
    }, [externalContact.locations]);

    return (
        <div>

            <UserBasicSettings
                user={externalContact}
                loading={props.loading}
                editing={props.editing}
                onSettingsUpdate={onSettingsUpdate}
                saving={props.saving}
                ssoAccount={false}
                onValidationUpdate={onExternalContactValidationChange}
                pending={!externalContact.verified}
                externalUser={true}
                inputRef={inputRef}
            />

            <AlertUnsavedData
                isDirty={isDataDirty}>
            </AlertUnsavedData>


            <div className="form-field">
                <h4>Selected notifications:</h4>

                <FormSwitch
                    id="notification-upcoming-visit"
                    disabled={props.saving || !externalContact.verified}
                    label="Receive daily upcoming visit emails"
                    checked={externalContact.notificationUpcomingVisit}
                    onChange={onNotificationUpcomingVisitChange}
                    inline={true}
                    alignment={Alignment.RIGHT}
                    loading={props.loading}
                />
                <FormSwitch
                    id="notification-upcoming-visit-alteration"
                    disabled={props.saving || !externalContact.verified}
                    label="Receive visit notification emails"
                    checked={externalContact.notificationUpcomingVisitAlteration}
                    onChange={onNotificationUpcomingVisitAlterationChange}
                    inline={true}
                    alignment={Alignment.RIGHT}
                    loading={props.loading}
                />

                <ShowHide
                    evaluator={props.editing}
                    show={(
                        <Fragment>
                            <h4>
                                External Contact used at the following locations:
                            </h4>

                            <ListingTable
                                id="listing-table-location-contact"
                                headers={tableHeaders}
                                loadingData={props.loading}
                                data={tableRows}
                                totalRecordCount={externalContact.locations.length}
                                pageable={false}
                                noDataHeading={"This external contact has not been assigned to any locations"}
                            />
                        </Fragment>
                    )}
                />
                
            </div>

            <ShowHide
                evaluator={externalContact.verified}
                show={(
                    <ButtonSave
                        id="user-role-save"
                        onClick={onSave}
                        disabled={props.saving}
                        simpleDisabled={!externalContactValid}
                        loading={props.loading}
                    ></ButtonSave>
                )}
            />

        </div>
    );
}
ExternalContactSetting.defaultProps = {
    saving: false,
    editing: false,
    loading: true
};

ExternalContactSetting.propTypes = {
    externalContact: PropTypes.object,
    saving: PropTypes.bool,
    editing: PropTypes.bool.isRequired,
    loading: PropTypes.bool.isRequired,
    onExternalContactSave: PropTypes.func.isRequired
};
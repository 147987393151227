import React, { useEffect, useState, Fragment } from "react";
import { Intent } from "@blueprintjs/core";
import { PowerBIEmbed } from 'powerbi-client-react';
import { models } from 'powerbi-client';
import { useParams } from 'react-router-dom';
import axios from 'axios';
import classNames from 'classnames';

import { Button, ButtonSave } from "components/buttons";
import { ShowHide } from "components/layout";
import { Page404 } from "pages/errors/page-404";
import { NotificationInline, NotificationToaster } from "components/notifications";

import { Tooltip } from "components/tooltip";
import { CustomDashboardService } from "services/CustomDashboardService";
import { Breadcrumbs } from "components/navigation";

export function CustomDashboard(props) {

	const { id } = useParams();
	const defaultRatio = 58.8;
	const defaultConfig = {
		type: 'report',
		id: null,
		embedUrl: null,
		accessToken: null,
		tokenType: models.TokenType.Embed,
		settings: {
			filterPaneEnabled: false,
			navContentPaneEnabled: false,
		}
	};

	const [dashboardConfig, setDashboardConfig] = useState(defaultConfig);
	const [loading, setLoading] = useState(true);
	const [selectedDashboard, setSelectedDashboard] = useState(null);
	const [reportRendered, setReportRendered] = useState(false);
	const [dashboardName, setDashboardName] = useState("Dashboard");
	const [dashboardDescription, setDashboardDescription] = useState("Dashboard description");
	const [invalidDashboard, setInvalidDashboard] = useState(false);
	const [dashboardViewMode, setDashboardViewMode] = useState("view");

	function initialLoad() {

		setDashboardConfig(defaultConfig);
		setLoading(true);
		setSelectedDashboard(null);
		setReportRendered(false);
		setInvalidDashboard(false);
		setDashboardViewMode("view");

		axios.all([
			CustomDashboardService.getEmbedToken(id),
			CustomDashboardService.getDashboard(id),
		]).then(axios.spread(function (embedTokenConfig, customConfig) {

			const fullConfig = {
				...customConfig,
				id: customConfig.reportId,
				embedUrl: embedTokenConfig.embedUrl,
				accessToken: embedTokenConfig.embedToken,
				permissions: models.Permissions.ReadWrite,
				viewMode: models.ViewMode.View,
				settings: {
					filterPaneEnabled: false,
					navContentPaneEnabled: true,
				}
			};

			setDashboardName(customConfig.dashboardName);
			setDashboardDescription(customConfig.description);

			setDashboardConfig(fullConfig);
			setSelectedDashboard(customConfig);
			setLoading(false);

		}), () => {
			NotificationToaster.show(Intent.DANGER, "Could not load the dashboard. Please refresh the page.");
		});
	}

	useEffect(initialLoad, [id]);

	function onPrint() {
		window.report.print();
	}

	function onFullscreen() {
		window.report.fullscreen();
	}

	function onReportRendered() {
		setReportRendered(true);
	}

	function onEdit() {
		const newMode = 'edit';
		setDashboardViewMode(newMode);

		window.report.switchMode(newMode);
	}

	function onSave() {
		window.report.save();

		NotificationToaster.show(Intent.SUCCESS, "The custom dashboard has saved successfully.");

		const newMode = 'view';
		setDashboardViewMode(newMode);

		window.report.switchMode(newMode);
	}

	return (
		<ShowHide
			evaluator={!loading && selectedDashboard == null}
			show={(
				<Page404 />
			)}
			hide={(
				<div className="row">

					<Breadcrumbs items={props.breadcrumbs} />

					<h1 className={classNames({ "bp3-skeleton": loading })}>{dashboardName}</h1>
					<div className="intro-text">
						<p className={classNames({ "bp3-skeleton": loading })}>{dashboardDescription}</p>
					</div>

					<ShowHide
						evaluator={invalidDashboard}
						hide={(
							<Fragment>

								<div className="inline-items inline-items-space spacer-bottom">
									<div className="inline-item">
										<div className="button-row">
											<Tooltip content="Full screen" disabled={!reportRendered}>
												<Button loading={loading} icon="maximize" iconOnly minimal large={false} intent="primary" onClick={onFullscreen} disabled={!reportRendered} />
											</Tooltip>
											<Tooltip content="Print" disabled={!reportRendered}>
												<Button loading={loading} icon="print" iconOnly minimal large={false} intent="primary" onClick={onPrint} disabled={!reportRendered} />
											</Tooltip>
											<ShowHide
												evaluator={dashboardViewMode === 'view'}
												show={(<Tooltip content={'edit'} disabled={!reportRendered}>
													<Button loading={loading} icon="edit" iconOnly minimal large={false} intent="primary" onClick={onEdit} disabled={!reportRendered} />
												</Tooltip>)}
												hide={(<Tooltip content={'save'} disabled={!reportRendered}>
													<ButtonSave loading={loading} iconOnly minimal large={false} intent="primary" onClick={onSave} disabled={!reportRendered} />
												</Tooltip>)}
											/>
										</div>
									</div>
								</div>
								<div className={classNames("dashboard-container", "dashboard-container-border", { "bp3-skeleton": loading })} style={{ paddingTop: `${defaultRatio}%` }}>
									<PowerBIEmbed
										embedConfig={dashboardConfig}
										eventHandlers={
											new Map([
												['rendered', onReportRendered],
											])
										}
										getEmbeddedComponent={(embeddedReport) => {
											window.report = embeddedReport;
										}}
									/>
								</div>
							</Fragment>
						)}
						show={(
							<NotificationInline intent="danger" show allowClose={false} text="An error has occurred whilst loading the custom dashboard, please refresh the page to try again." />
						)}

					/>
				</div>
			)}
		/>

	);
}

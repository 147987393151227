import ApiService from 'services/ApiService'
import { HelperFunctions } from "helpers";

const badRequestCode = 400;

export const AccountService = {
	getAllAccounts() {
		return new Promise((resolve, reject) => {
			ApiService.get('/account/Accounts?sortField=MEMBER NUMBER').then(
				(result) => {
					resolve(result.data);
				},
				(error) => {
					reject(error);
				});
		});
	},
	getGeneralSettings() {
		return new Promise((resolve, reject) => {
			ApiService.get('/account/generalSettings').then(
				(result) => {
					resolve(result.data);
				},
				(error) => {
					reject(error);
				});
		});
	},
	saveGeneralSettings(settings) {
		return new Promise((resolve, reject) => {
			ApiService.put('/account/generalSettings', settings)
				.then(function (response) {
					resolve(response);
				})
				.catch(function (error) {
					reject(error);
				});
		});
	},
	saveInternalConfiguration(settings) {
		return new Promise((resolve, reject) => {
			ApiService.put('/account/internalConfiguration', settings)
				.then(function (response) {
					resolve(response);
				})
				.catch(function (error) {
					reject(error);
				});
		});
	},
	getSectors() {
		return new Promise((resolve, reject) => {
			ApiService.get('/account/Sector').then(
				(result) => {
					resolve(sortSectors(result.data));
				},
				(error) => {
					reject(error);
				});
		});
	},
	getTachoSettings() {
		return new Promise((resolve, reject) => {
			ApiService.get('/account/TachoSettings').then(
				(result) => {
					resolve(result.data);
				},
				(error) => {
					reject(error);
				});
		});
	},
	getTachoOptions() {
		return new Promise((resolve, reject) => {
			ApiService.get('/account/Options/Tacho').then(
				(result) => {
					resolve(result.data);
				},
				(error) => {
					reject(error);
				});
		});
	},
	putTachoSettings(settings) {
		return new Promise((resolve, reject) => {
			ApiService.put('/account/TachoSettings', settings).then(
				(result) => {
					resolve(result.data);
				},
				(error) => {
					reject(error);
				});
		});
	},
	getChangelog(pageSize, pageNumber, startDate, endDate) {
		return new Promise((resolve, reject) => {
			ApiService.get(`/account/changelog?resultCount=${pageSize}&pageNumber=${pageNumber}&startDate=${startDate}&endDate=${endDate}`).then(
				(result) => {
					resolve(result.data);
				},
				(error) => {
					reject(error);
				});
		});

	},
	getInternalConfiguration() {
		return new Promise((resolve, reject) => {
			ApiService.get(`/account/internalConfiguration`).then(
				(result) => {
					resolve(result.data);
				},
				(error) => {
					reject(error);
				});
		});
	},
	getFeatures() {
		return new Promise((resolve, reject) => {
			ApiService.get(`/account/feature`).then(
				(result) => {
					resolve(result.data);
				},
				(error) => {
					reject(error);
				});
		});
	},
	getRestrictionTemplates() {
		return new Promise((resolve, reject) => {
			ApiService.get(`/account/RestrictionTemplate`).then(
				(result) => {
					resolve(result.data);
				},
				(error) => {
					reject(error);
				});
		});
		
	},
	saveRestrictionTemplate(template) {
		return new Promise((resolve, reject) => {
			ApiService.post(`/account/RestrictionTemplate`, template).then(
				(result) => {
					resolve(result.data);
				},
				(error) => {
					if (error.response.status === badRequestCode) {
						reject(error.response.data);
					} else {
						reject("Unable to create template, please try again.");
					}
				});
		});
	},
	deleteRestrictionTemplate(templateId) {
		return new Promise((resolve, reject) => {
			ApiService.delete(`/account/RestrictionTemplate/${templateId}`).then(
				(result) => {
					resolve(result.data);
				},
				(error) => {
					reject("Unable to delete template, please try again.");
				});
		});
	},
	getIntegrations() {
		return new Promise((resolve, reject) => {
			ApiService.get(`/account/Integrations`).then(
				(result) => {
					resolve(result.data);
				},
				(error) => {
					reject(error);
				});
		});
	},
	getIntegration(integrationId) {
		return new Promise((resolve, reject) => {
			ApiService.get(`/account/Integrations/${integrationId}`).then(
				(result) => {
					resolve(result.data);
				},
				(error) => {
					reject(error);
				});
		});
	},
	updateIntegration(integrationId, integration) {
		return new Promise((resolve, reject) => {
			ApiService.put(`/account/Integrations/${integrationId}`, integration).then(
				(result) => {
					resolve(result.data);
				},
				(error) => {
					if (error.response.status === badRequestCode) {
						reject(error.response.data);
					} else {
						reject("Unable to update integration, please try again.");
					}
				});
		});
	},
	createIntegration(integration) {
		return new Promise((resolve, reject) => {
			ApiService.post(`/account/Integrations`, integration).then(
				(result) => {
					resolve(result.data);
				},
				(error) => {
					if (error.response.status === badRequestCode) {
						reject(error.response.data);
					} else {
						reject("Unable to create integration, please try again.");
					}
				});
		});
	},
	deleteIntegration(integrationId) {
		return new Promise((resolve, reject) => {
			ApiService.delete(`/account/Integrations/${integrationId}`).then(
				(result) => {
					resolve(result.data);
				},
				() => {
					reject("Unable to delete integration, please try again.");
				});
		});
	},
	getAvailableIntegrations() {
		return new Promise((resolve, reject) => {
			ApiService.get(`/account/Integrations/Providers`).then(
				(result) => {
					resolve(result.data);
				},
				(error) => {
					reject(error);
				});
		});
	},
	getIntegrationsAccess() {
		return new Promise((resolve, reject) => {
			ApiService.get(`/account/Integrations/AccessProviders`).then(
				(result) => {
					resolve(result.data);
				},
				(error) => {
					reject(error);
				});
		});
	},
	setIntegrationsAccess(providers) {
		return new Promise((resolve, reject) => {
			ApiService.put(`/account/Integrations/AccessProviders`, providers).then(
				(result) => {
					resolve(result.data);
				},
				(error) => {
					if (error.response.status === badRequestCode) {
						reject(error.response.data);
					} else {
						reject("Unable to update access providers, please try again.");
					}
				});
		});
	},
	updateSsoConfig(config) {
		return new Promise((resolve, reject) => {
			ApiService.put('/account/SsoConfig', config).then(
				(result) => {
					resolve(result.data);
				},
				(error) => {
					if (error.response.status === badRequestCode) {
						reject(error.response.data);
					} else {
						reject("Unable to save the SSO configuration. Please try again.");
					}
				});
		});
	},
	getSsoConfig() {
		return new Promise((resolve, reject) => {
			ApiService.get(`/account/SsoConfig`).then(
				(result) => {
					resolve(result.data);
				},
				(error) => {
					reject(error);
				});
		});
	},
	checkSsoKeyIsAvailable(ssoKey) {
		return new Promise((resolve, reject) => {
			ApiService.get(`/account/SsoConfig/SsoKey/${ssoKey}/Availability`).then(
				(result) => {
					resolve(result.data);
				},
				(error) => {
					reject(error);
				});
		});
	}
}


function sortSectors(sectors) {
	let otherSector = sectors.find(sector => {
		return sector.name.toLowerCase() === "other";
	});
	if (!HelperFunctions.isNullOrUndefined(otherSector)) {
		sectors.sort((a, b) => a.name.localeCompare(b.name));
		sectors = sectors.filter(sector => {
			return sector.name !== otherSector.name;
		});
		sectors.push(otherSector);
	}
	return sectors;
}

import React, { useState, useEffect, Fragment } from 'react';
import axios from 'axios';
import { useParams } from "react-router-dom";
import { Intent } from '@blueprintjs/core';
import { LocationService, OperatingHoursService } from 'services';
import { NotificationToaster, AlertUnsavedData } from "components/notifications";
import { Button } from "components/buttons";
import { useDirtyData } from "hooks/useDirtyData";
import { UserAccess, UserHasAccess } from 'components/user-access';
import { PageRestricted } from 'pages/errors/page-restricted';
import { HelperFunctions } from "helpers";
import { BusinessAreaOperatingHoursSettings } from 'pages/locations/shared';
import { ShowHide } from "components/layout";

export function BusinessAreaOperatingHours(props) {

    const zeroTime = '2020-01-01 00:00:00'
    const requiredAction = "Internal";
    const { id } = useParams();
    const [additionalPropertiesMapped, setAdditionalPropertiesMapped] = useState(false);
    const [businessAreaAdditionalDetails, setBusinessAreaAdditionalDetails] = useState([]);
    const [originalBusinessAreaAdditionalDetails, setOriginalBusinessAreaAdditionalDetails] = useState(businessAreaAdditionalDetails);

    const [loading, setLoading] = useState(true);

    const [isValid, setIsValid] = useState(true);
    const [saving, setSaving] = useState(false);
    const [allowIsDirty, setAllowIsDirty] = useState(false);
    const [businessAreaTabs, setBusinessAreaTabs] = useState([]);
    const isDataDirty = useDirtyData(originalBusinessAreaAdditionalDetails, businessAreaAdditionalDetails);
    const isInternal = UserHasAccess("Internal");

    useEffect(initialLoad, []);

    function initialLoad() {
        axios.all([LocationService.getLocationAdditionalInformation(id),
        LocationService.getLocationBusinessAreas()
        ])
            .then(axios.spread(function (additionalInfo, businessAreaResponse) {
                var external = businessAreaResponse.filter(isExternalBusinessArea);
                var tabs = [];
                additionalInfo.businessAreaAdditionalDetails.forEach(function (locationBusinessArea) {

                    //For the moment only show Tacho
                    if (locationBusinessArea.locationBusinessAreaId === 1) {
                        var externalbusinessArea = external.find(e => e.locationBusinessAreaId === locationBusinessArea.locationBusinessAreaId && (isInternal || e.BusinessArea === 'Tacho'));
                        if (externalbusinessArea) {
                            locationBusinessArea.operatingHours = OperatingHoursService.getFullWeekOperatingHours(locationBusinessArea.operatingHours, additionalPropertiesMapped, setAdditionalPropertiesMapped);
                            tabs.push({
                                title: `${externalbusinessArea.businessArea} Visiting Hours`, key: `${externalbusinessArea.businessArea}`, operatingHours: locationBusinessArea.operatingHours
                            });
                        }
                    }
                });
                setBusinessAreaTabs(tabs);
                setBusinessAreaAdditionalDetails(additionalInfo.businessAreaAdditionalDetails);
                setOriginalBusinessAreaAdditionalDetails(HelperFunctions.deepClone(additionalInfo.businessAreaAdditionalDetails));
                setLoading(false);
            }), (error) => {
                NotificationToaster.show(Intent.DANGER, "Could not get the visiting hours. Please refresh the page.");
            });
    }

    function isExternalBusinessArea(businessArea) {
        return !businessArea.internal;
    }

    function isNotSet(date) {
        return date === undefined || date === null || date === zeroTime;
    }

    function saveLocationAdditionalInfo() {
        setSaving(true);
        if (!isValid) {
            setSaving(false);
            NotificationToaster.show(Intent.WARNING, "There are errors on the page that need your attention");
            return;
        }

        if (!isDataDirty) {
            setSaving(false);
            NotificationToaster.show(Intent.NONE, "No changes made, no save required.");
            return;
        }

        businessAreaAdditionalDetails.forEach(function (businessAreaAdditionalDetail) {
            businessAreaAdditionalDetail.operatingHours.forEach(function (operatingHour) {
                //if end date is not set then the only valid condition is start date is not set and so pass null. Otherwise range could be 00:00 to endDate
                operatingHour.startTime1 = isNotSet(operatingHour.startDate) && isNotSet(operatingHour.endDate) ? null : operatingHour.startDate.substr(operatingHour.startDate.length - 8);
                operatingHour.endTime1 = isNotSet(operatingHour.endDate) ? null : operatingHour.endDate.substr(operatingHour.endDate.length - 8);
                operatingHour.startTime2 = isNotSet(operatingHour.startDate1) && isNotSet(operatingHour.endDate1) ? null : operatingHour.startDate1.substr(operatingHour.startDate1.length - 8);
                operatingHour.endTime2 = isNotSet(operatingHour.endDate1) ? null : operatingHour.endDate1.substr(operatingHour.endDate1.length - 8);
            });
        });



        LocationService.saveOperatingHours(id, businessAreaAdditionalDetails).then(
            () => {
                setSaving(false);
                setOriginalBusinessAreaAdditionalDetails(HelperFunctions.deepClone(businessAreaAdditionalDetails));
                NotificationToaster.show(Intent.SUCCESS, "Visiting Hours updated successfully.")
            },
            (error) => {
                setSaving(false);
                //For Validation errors this doesn't return a useful message, just System error, so won't bother checking.
                setAllowIsDirty(true);
                NotificationToaster.show(Intent.DANGER, "Could not save settings. Please try again.");
            }
        );
    }

    function updatebusinessAreaAdditionalDetails(setting) {
        setBusinessAreaAdditionalDetails(setting);
    }

    function onOpeningHoursValidationUpdate(onIsValid) {
        setIsValid(onIsValid)
    }

    return (

        <UserAccess perform={requiredAction}
            yes={() => (
                <div className="row">
                    <ShowHide
                        evaluator={businessAreaTabs.length > 0}
                        show={(
                            <Fragment>
                                <BusinessAreaOperatingHoursSettings
                                    businessAreaAdditionalDetails={businessAreaAdditionalDetails}
                                    businessAreaAdditionalTabs={businessAreaTabs}
                                    onOperatingHoursSettingsUpdate={updatebusinessAreaAdditionalDetails}
                                    onValidationUpdate={onOpeningHoursValidationUpdate}
                                    saving={saving}
                                    loading={loading}
                                />
                                <AlertUnsavedData
                                    isDirty={isDataDirty && !allowIsDirty}>
                                </AlertUnsavedData>
                                <Button
                                    text="Save"
                                    intent="save"
                                    onClick={saveLocationAdditionalInfo}
                                    disabled={!isValid || saving || loading}
                                    loading={loading}
                                    icon="floppy-disk"
                                ></Button>
                            </Fragment >
                        )}
                        hide={(
                        <div className="spacer-bottom">
                            <div className="spacer-bottom">
                                <p>There are no visiting hours for the selected business areas.</p>
                            </div>
                        </div>
                        ) }
                    />
                </div>
            )}
            no={() => (
                <div className="row">
                    <PageRestricted />
                </div>
            )}
        />
    );
}
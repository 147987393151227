import { DataDisplayTable } from 'components/data-display';
import { Collapsable, ShowHide } from 'components/layout';
import moment from 'moment';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';

export function JobReportSection(props) {
    const signatureAnswerType = 'signatureimage';
    const dateAnswerType = 'date';

    const [questionGroups, setQuestionGroups] = useState([]);

    useEffect(() => {
        const formattedQuestions = formatQuestionGroups(props.section.questionGroups);
        setQuestionGroups(formattedQuestions);
    }, [props.section]);

    function formatQuestionGroups(groups) {
        const result = [];

        if (groups) {
            for (const questionGroup of groups) {
                const formattedQuestionGroup = formatQuestionGroup(questionGroup);

                if (formattedQuestionGroup) {
                    result.push(formattedQuestionGroup);
                }
            }
        }

        return result;
    }

    function formatQuestionGroup(questionGroup) {
        const allQuestions = questionGroup?.questions?.filter(x => x.answerType.toLowerCase() !== signatureAnswerType);

        if (!allQuestions || allQuestions.length < 1) {
            return null;
        }

        const formattedQuestionGroup = {
            header: questionGroup.questionGroupTitle,
            key: questionGroup.questionGroupId
        };

        // The main question within a question group is the one where the title of the question
        // matches the question group title.
        const mainQuestion = allQuestions
            .find(x => x.questionTitle?.trim()?.toLowerCase() === questionGroup.questionGroupTitle?.trim()?.toLowerCase());

        if (mainQuestion) {
            formattedQuestionGroup.value = formatResponses(mainQuestion.responses, mainQuestion.answerType);
        }

        const additionalQuestions = allQuestions
            .filter(x => x.questionId !== mainQuestion?.questionId)
            .map(x => {
                return {
                    header: x.questionTitle,
                    key: x.questionId,
                    value: formatResponses(x.responses, x.answerType)
                };
            });

        formattedQuestionGroup.additionalData = additionalQuestions;

        return formattedQuestionGroup;
    }

    function onCollapsableClick() {
        props.onCollapsableClick();
    }

    function formatResponses(responses, answerType) {
        let mappedResponses = '';

        // We filter out signature images for the moment. We will handle these at a 
        // later date.
        if (responses && answerType.toLowerCase() !== signatureAnswerType) {
            mappedResponses = responses
                .map(response => answerType.toLowerCase() === dateAnswerType ? formatDate(response) : response)
                .join(', ');
        }

        return mappedResponses;
    }


    function formatDate(response) {
        const date = moment(response);

        return date.isValid() ?
            date.format('YYYY-MM-DD hh:mm:ss') :
            response;
    }

    return (
        <ShowHide
            evaluator={props.isHeaderSection}
            show={
                <DataDisplayTable
                    data={questionGroups}
                    displayInRows={false}
                    loading={props.loading}
                />
            }
            hide={
                <Collapsable
                    loading={props.loading}
                    title={`${props.section.sectionNumber}. ${props.section.sectionTitle}`}
                    fullWidth={true}
                    metadata={`${questionGroups.length} Questions`}
                    expanded={props.section.isExpanded}
                    onClick={onCollapsableClick}
                >
                    <DataDisplayTable
                        data={questionGroups}
                        displayInRows={true}
                        loading={props.loading}
                    />
                </Collapsable>
            }
        />
    );
}

JobReportSection.defaultProps = {
    section: {
        sectionTitle: '',
        sectionType: 'Body',
        questionGroups: []
    },
    loading: false
};

JobReportSection.propTypes = {
    section: PropTypes.object,
    loading: PropTypes.bool,
    onCollapsableClick: PropTypes.func,
    isHeaderSection: PropTypes.bool.isRequired
};
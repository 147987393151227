import React, { Fragment } from 'react';

export function Numeric(props) {

    return (
        <Fragment>{formatStringToNumeric(props.children)}</Fragment>
    );

    function formatStringToNumeric(value) {
        if (value == null || isNaN(value)) {
            return "-";
        }
        else {
            return new Intl.NumberFormat('en-GB').format(value)
        }
    }
}

Numeric.defaultProps = {
};

Numeric.propTypes = {
};
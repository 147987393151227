import React, { useEffect, useState, useRef } from 'react';
import PropTypes from 'prop-types';
import { Button, Position } from "@blueprintjs/core";
import { DateInput, TimePrecision } from "@blueprintjs/datetime";
import { FormHelperText } from "components/form-fields";
import classNames from "classnames";
import moment from "moment";
import 'components/form-fields/FormFields.css';
import "moment/locale/en-gb";
import MomentLocaleUtils from "react-day-picker/moment";
import { ShowHide } from "components/layout";

const dateLocale = "en-gb";
const dateFormat = "DD/MM/YYYY";
const timeFormat = "HH:mm";

export function FormDateInput(props) {
    const [value, setValue] = useState(null);
    const monthsInPastDefault = 30;
    const yearsInFutureDefault = 5;
    const minDateDefault = moment().subtract(monthsInPastDefault, 'months').toDate();
    const maxDateDefault = moment().add(yearsInFutureDefault, 'years').toDate();
    const [minDate, setMinDate] = useState(minDateDefault);
    const [maxDate, setMaxDate] = useState(maxDateDefault);
    const inputRef = useRef(null);
    const [timeSettings, setTimeSettings] = useState({});
    const [dateFormatString, setDateFormatString] = useState("DD/MM/YYYY");

    useEffect(() => {
        var newValue = null;

        if (props.value) {
            if (props.includeTime) {
                newValue = moment.utc(props.value).locale(dateLocale).toDate();
            } else {
                newValue = moment(props.value).locale(dateLocale).toDate();
            }
        }

        setValue(newValue);

    }, [props.value, props.includeTime]);

    useEffect(() => {
        setTimeSettings(props.includeTime ? { timePickerProps: { precision: TimePrecision.MINUTE, showArrowButtons: true, onChange: handleDateChange, selectAllOnFocus: true }, closeOnSelection: false } : {});
        setDateFormatString(props.includeTime ? `${dateFormat} ${timeFormat}` : dateFormat);
    }, [props.includeTime])

    function setMinDateFromProps() {
        const newMinDate = props.minDate ? props.minDate : minDateDefault;
        setMinDate(newMinDate);
    }
    function setMaxDateFromProps() {
        const newMaxDate = props.maxDate ? props.maxDate : maxDateDefault;
        setMaxDate(newMaxDate);
    }

    function handleDateChange(selectedDate, isUserChange) {

        if (!isUserChange) {
            return;
        }

        if (selectedDate === "") {
            selectedDate = null;
        }
        props.onChange(selectedDate);
    }

    function openCalendar() {
        if (inputRef.current != null) {
            inputRef.current.focus();
        }
    }

    function receiveInputRef(ref) {
        inputRef.current = ref;
    }

    useEffect(setMinDateFromProps, [props.minDate]);
    useEffect(setMaxDateFromProps, [props.maxDate]);

    return (
        <div className="form-field">
            <ShowHide
                evaluator={props.headingText != null}
                show={(
                    <h4 className={classNames({ "bp3-skeleton": props.loading })}>{props.headingText}</h4>
                )}
            />
            <FormHelperText loading={props.loading}>{props.helperText}</FormHelperText>
            <DateInput
                disabled={props.disabled}
                className={classNames({ "bp3-skeleton": props.loading })}
                formatDate={date => moment(date).locale(dateLocale).format(dateFormatString)}
                parseDate={str => moment(str, dateFormatString).locale(dateLocale).toDate()}
                placeholder={dateFormatString}
                value={value}
                onChange={handleDateChange}
                popoverProps={{ position: Position.BOTTOM, usePortal: false }}
                inputRef={inputRef}
                inputProps={{ id: props.id, className: props.includeTime ? "medium" : "small", inputRef: receiveInputRef }}
                rightElement={(
                    <Button
                        icon="calendar"
                        minimal={true}
                        onClick={openCalendar}
                    />
                )}
                minDate={minDate}
                maxDate={maxDate}
                modifiers={props.modifiers}
                locale={dateLocale}
                localeUtils={MomentLocaleUtils}
                {...timeSettings}
            ></DateInput>
            <FormHelperText loading={props.loading} danger={true}>{props.dangerHelperText}</FormHelperText>
        </div>
    );
}

FormDateInput.defaultProps = {
    id: null,
    value: null,
    disabled: false,
    loading: false,
    minDate: null,
    maxDate: null,
    headingText: null,
    dangerHelperText: "",
    helperText: "",
    modifiers: null,
    includeTime: false
};

FormDateInput.propTypes = {
    id: PropTypes.string,
    value: PropTypes.string,
    minDate: PropTypes.instanceOf(Date),
    maxDate: PropTypes.instanceOf(Date),
    loading: PropTypes.bool,
    headingText: PropTypes.string,
    disabled: PropTypes.bool,
    onChange: PropTypes.func.isRequired,
    modifiers: PropTypes.object,
    dangerHelperText: PropTypes.string,
    helperText: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
    includeTime: PropTypes.bool
};
import React, { useState, useEffect, Fragment} from 'react';
import { Intent } from '@blueprintjs/core';
import { NotificationToaster, AlertConfirm, AlertUnsavedData } from "components/notifications";
import { ButtonSave } from "components/buttons";
import { FormRadio, FormHelperText, FormFileInput } from "components/form-fields";
import { EmployeeService } from "services";
import { useDirtyData } from "hooks/useDirtyData";
import { UserAccess, UserHasAccess } from 'components/user-access';
import { useValidation } from "hooks/useValidation";
import { Breadcrumbs } from 'components/navigation';
import { PageRestricted } from 'pages/errors/page-restricted';
import axios from 'axios';

var _ = require('underscore');


export function UploadDeclaration(props) {
    const uploadModel = {
        name: "",
        file: null,
        isGenericDocument: true
    };

    const [originalUploadDeclaration, setOriginalUploadDeclaration] = useState(uploadModel);
    const [uploadDeclarationValid, errors, validateUploadDeclaration] = useValidation(); 
    const [loading, setLoading] = useState(true);
    const [saving, setSaving] = useState(false);
    const [fileText, setFileText] = useState('Choose file')
    const [uploadDeclaration, setUploadDeclaration] = useState(originalUploadDeclaration);
    const [fileWarning, setFileWarning] = useState("");
    const [declarations, setDeclarations] = useState([]);
    const [confirmOverrideId, setConfirmOverrideId] = useState(0);
    const [showConfirmNewVersion, setShowConfirmNewVersion] = useState(false);
    const isDataDirty = useDirtyData(originalUploadDeclaration, uploadDeclaration);
    const maxFileSize = 10485760;
    const pdfFileExtension = '.PDF'
    const internalPermission = UserHasAccess("Internal");
    const requiredAction = "EmployeeDeclaration:Upload";

    useEffect(() => {
        if (loading) {
            axios.all([EmployeeService.getDeclarations()
            ]).then(axios.spread(function (declarationData) {

                if (!internalPermission) {
                    setUploadDeclaration({
                        ...uploadDeclaration,
                        isGenericDocument: false
                    })
                }
                setDeclarations(declarationData);
                setLoading(false);
            },
                () => {
                    setSaving(false);
                    NotificationToaster.show(Intent.DANGER, "Could not load settings. Please refresh the page.");
                }
            ));
        }
    }, [loading, uploadDeclaration]);

    useEffect(() => {
    }, [fileWarning]);

    function onIsGenericChange(event) {
        setUploadDeclaration({
            ...uploadDeclaration,
            isGenericDocument: event && event.currentTarget && event.currentTarget.value === "true"
        });
        setConfirmOverrideId(0);
    }

    function checkForNameClash(name, isGeneric) {
        const fileHasMatchingGenericFile = _.some(declarations, function (d) {
            return d.name.toUpperCase() + pdfFileExtension === name.toUpperCase() && d.isGenericDocument && !isGeneric;
        });

        if (fileHasMatchingGenericFile) {
            return "The file name matches the name of a Logistics UK generic declaration. This is not allowed. Please rename the file.";
        }

        const fileHasMatchingNonGenericFile = _.some(declarations, function (d) {
            return d.name.toUpperCase() + pdfFileExtension === name.toUpperCase() && !d.isGenericDocument && isGeneric;
        });

        if (fileHasMatchingNonGenericFile) {
            return "The file name matches the name of a non generic declaration. This is not allowed. Please rename the file.";
        }

        return "";
    }

    function validateFile(file) {
        //vaidation will be picked up by rule is file not selected
        if (!file) {
            return "";
        }
        if (file.type !== 'application/pdf') {
            return "You must uploaded a PDF file.";
        }
        if (file.size === 0) {
            return "The file can not be empty.";
        }
        if (file.size > maxFileSize) {
            return "The file is too large. The file must be less than 10MB in size.";
        }

        return checkForNameClash(file.name, uploadDeclaration.isGenericDocument);
    }

    function onFileChange(event) {
        //if files length = 0 then cancel was selected.
        var fileName = (event.currentTarget.files.length === 0) ? "" : event.currentTarget.files[0].name;
        setFileText(fileName);


        setUploadDeclaration({
            ...uploadDeclaration,
            name: fileName,
            file: (event.currentTarget.files.length > 0) ? event.currentTarget.files[0] : null
        });
        setConfirmOverrideId(0);
        event.target.value = null;
    }

    
    useEffect(validateSettings, [uploadDeclaration]);

    function validateSettings(forceCheck) {
        if (uploadDeclaration || forceCheck) {
            var warning = validateFile(uploadDeclaration.file);
            setFileWarning(warning);

            if (warning === "" && confirmOverrideId === 0 && uploadDeclaration.file) {
                const fileHasMatch = _.find(declarations, function (declaration) {
                    return (declaration.name.toUpperCase() + pdfFileExtension === uploadDeclaration.file.name.toUpperCase()) && declaration.isLatestVersion;
                });
                setShowConfirmNewVersion(fileHasMatch ? true : false);
                setConfirmOverrideId(fileHasMatch ? fileHasMatch.id : 0);

            }

            let uploadDeclarationRules = [{ fieldName: "name", required: true }];
            if (warning !== "") {
                var externalRule = {
                    fieldName: "name",
                    valid: false,
                    errorMessage: warning
                };
                var externalRules = [externalRule];
            }
            validateUploadDeclaration(uploadDeclarationRules, uploadDeclaration, externalRules);
        }
    }

    function saveWorked() {
        setSaving(false);
        reset();
        setOriginalUploadDeclaration(uploadDeclaration);
        NotificationToaster.show(Intent.SUCCESS, "Uploaded successfully.");
    }

    function saveFailed(error) {
        const badRequestCode = 400;
        setSaving(false);

        if (error && error.response && error.response.status === badRequestCode) {
            NotificationToaster.show(Intent.WARNING, error.response.data);
        }
        else {
            NotificationToaster.show(Intent.DANGER, "Could not upload the file please try again.");
        }
    }

    function saveDeclaration() {
        setSaving(true);
        validateSettings(true);
        if (!uploadDeclarationValid) {
            NotificationToaster.show(Intent.WARNING, "There are errors on the page that need your attention");
            setSaving(false);
            return;
        }

        if (confirmOverrideId > 0) {
            EmployeeService.uploadDeclaration(uploadDeclaration, confirmOverrideId).then(
                () => {
                    saveWorked();
                },
                (error) => {
                    saveFailed(error);
                }
            );
        }
        else {
            EmployeeService.createDeclaration(uploadDeclaration).then(
                () => {
                    saveWorked();
                },
                (error) => {
                    saveFailed(error);
                }
            );
        }
    }

    function confirmConfirmNewVersion() {
        setShowConfirmNewVersion(false);
    }

    function reset() {
        setUploadDeclaration({
            ...uploadDeclaration,
            name: "",
            file: null
        });
        setShowConfirmNewVersion(false);
        setFileText('Choose file');
        setConfirmOverrideId(0);
    }
    function cancelConfirmNewVersion() {
        reset();
    }
    return (
        <Fragment>

            <UserAccess perform={requiredAction}
                yes={() => (
                    <div className="row">

                        <Breadcrumbs items={props.breadcrumbs} />

                        <h2>Upload a declaration file</h2>
                        <FormHelperText loading={loading}>
                            <p>Here you can upload a new declaration or update a version of an existing declaration.</p>
                        </FormHelperText>

                        <UserAccess perform="Internal"
                            yes={() => (
                                <FormRadio
                                    disabled={props.saving}
                                    onChange={onIsGenericChange}
                                    headingText="Generic Declaration:"
                                    selectedValue={uploadDeclaration.isGenericDocument}
                                    loading={loading}
                                    helperText="A generic declaration will be for all accounts. Uploading a non generic declaration will be for just this account."
                                ></FormRadio>
                            )}
                        />
                        <FormFileInput
                            text={fileText}
                            buttonText="Browse"
                            headingText="Use the file uploader to upload a declaration."
                            helperText="The file is in PDF format. The file has a maximum size of 10MB. "
                            fill={false}
                            onChange={onFileChange}
                            hasSelection={uploadDeclaration.file && uploadDeclaration.file.name !== ''}
                            dangerHelperText={errors.name}
                        />
                        <AlertUnsavedData
                            isDirty={isDataDirty}>
                        </AlertUnsavedData>

                        <ButtonSave
                            id="btn-declaration-upload"
                            onClick={saveDeclaration}
                            disabled={saving}
                            loading={loading}
                        ></ButtonSave>

                    </div>
                )}
                no={() => (
                    <PageRestricted />
                )}
            />

        <AlertConfirm
            isOpen={showConfirmNewVersion}
            title={"Confirm new version?"}
            onConfirm={confirmConfirmNewVersion}
            onCancel={cancelConfirmNewVersion}
        >
            <p>Uploading will create a new version of an existing declaration. Please confirm you wish to proceed.</p>
            </AlertConfirm>
        </Fragment>
    );
}
import { Intent } from '@blueprintjs/core';
import { Button } from 'components/buttons';
import { FormSuggest, FormDateInput, FormSelect, FormTextInput, FormNumericInput } from 'components/form-fields';
import { Modal } from 'components/notifications';
import { useValidation } from 'hooks/useValidation';
import moment from 'moment';
import PropTypes from 'prop-types';
import React, { Fragment, useEffect, useState } from 'react';
import { HelperFunctions } from 'helpers';

export function OcrTableFormModal(props) {
    const [currentRow, setCurrentRow] = useState([]);
    const [modalIsOpen, setModalIsOpen] = useState(false);

    const [isValid, errors, validate] = useValidation();

    useEffect(() => {
        if (props.rowIndex === props.rowBeingEdited) {
            setModalIsOpen(true);
        } else {
            setModalIsOpen(false);
        }
    }, [props.rowIndex, props.rowBeingEdited]);

    useEffect(() => {
        if (props.row) {
            setCurrentRow([...props.row]);
        }
    }, [props.row, props.rowIndex, props.rowBeingEdited]);

    useEffect(() => {
        HelperFunctions.executeIfNotNull(props.onValidationUpdate, isValid);
    }, [isValid]);

    useEffect(() => {
        validateRow();
    }, [currentRow]);

    function validateRow() {
        const rules = getRules();
        validate(rules, currentRow);
    }

    function getIndexOfCellByType(cellType) {
        if (props.columns && props.columns.length > 0) {
            return props.columns.map(function (col) {
                return col.columnTitle;
            }).indexOf(cellType)
        }
        else {
            return -1;
        }
    }

    function getRules() {
        var emailIdx = getIndexOfCellByType('Email');
        var phoneIdx = getIndexOfCellByType('Phone number');
        if (props.row) {
            return props.row
                .map((_, cellIndex) => {
                    if (emailIdx === cellIndex) {
                        return { fieldName: cellIndex.toString(), required: cellIsEditable(cellIndex) && !cellIsOptional(cellIndex), type: "email" };
                    }
                    else if (phoneIdx === cellIndex) {
                        return { fieldName: cellIndex.toString(), required: cellIsEditable(cellIndex) && !cellIsOptional(cellIndex), type: "phone" };
                    }
                    else {
                        return { fieldName: cellIndex.toString(), required: cellIsEditable(cellIndex) && !cellIsOptional(cellIndex) };
                    }

                });
        }

        return [];
    }

    function cellIsEditable(cellIndex) {
        const columnType = props.getColumnTypeForCell(cellIndex).toUpperCase();

        return props.editableColumnTypes.includes(columnType);
    }

    function cellIsOptional(cellIndex) {
        return props.columns[cellIndex].optional;
    }

    function formatEmployees() {
        return props.employeeList.map(employee => {
            return {
                id: employee.employeeId.toString(),
                name: employee.employeeName
            };
        });
    }

    function formatAssets() {
        return props.assetList.map(asset => {
            return {
                id: asset.assetId.toString(),
                name: asset.registration
            };
        });
    }
    function onSelectValueChange(value, cellIndex) {
        const rowClone = [...currentRow];
        rowClone[cellIndex] = value.id;

        setCurrentRow(rowClone);
    }

    function onTextValueChange(event, cellIndex) {
        const rowClone = [...currentRow];
        rowClone[cellIndex] = event.target.value;

        setCurrentRow(rowClone);
    }

    function selectDateValue(value, cellIndex) {
        const rowClone = [...currentRow];
        rowClone[cellIndex] = value ? moment(value).format("YYYY-MM-DD") : null;

        setCurrentRow(rowClone);
    }

    function selectNumbericValue(value, cellIndex) {
        const rowClone = [...currentRow];
        rowClone[cellIndex] = value;

        setCurrentRow(rowClone);
    }

    function getColumnNameForCell(cellIndex) {
        return props.columns[cellIndex].columnTitle;
    }

    function formatFormField(cell, cellIndex) {
        const columnType = props.getColumnTypeForCell(cellIndex);
        const employee = props.getEmployeeForRow(currentRow);

        switch (columnType.toUpperCase()) {
            case 'FIXED':
            case 'VARIANCE':
                return formatReadonlyTextFormField(cell, cellIndex);
            case 'FIXEDDATE':
                return <FormDateInput
                    value={cell ? moment(cell).toString() : null}
                    onChange={() => null}
                    disabled={true}
                    headingText={getColumnNameForCell(cellIndex)}
                />
            case 'DRIVERNAME':
                return <FormSuggest
                    items={formatEmployees()}
                    selectedValue={cell}
                    loading={props.loading}
                    onItemSelect={(value) => onSelectValueChange(value, cellIndex)}
                    headingText={getColumnNameForCell(cellIndex)}
                    dangerHelperText={errors[cellIndex.toString()]}
                />;
            case 'VEHICLEREGISTRATION':
                return <FormSuggest
                    items={formatAssets()}
                    selectedValue={cell}
                    loading={props.loading}
                    onItemSelect={(value) => onSelectValueChange(value, cellIndex)}
                    headingText={getColumnNameForCell(cellIndex)}
                    dangerHelperText={errors[cellIndex.toString()]}
                />;
            case 'DRIVERLOCATION':
                return formatReadonlyTextFormField(employee ? employee.locationName : null, cellIndex);
            case 'DRIVERSTARTDATE':
                const startDate = employee ? moment(employee.startDate).toString() : null;

                return <FormDateInput
                    value={startDate}
                    onChange={() => null}
                    disabled={true}
                    minDate={moment('01-01-1900').toDate()}
                    maxDate={moment().toDate()}
                    headingText={getColumnNameForCell(cellIndex)}
                />
            case 'DATEPAST':
                return <FormDateInput
                    value={cell ? moment(cell).toString() : null}
                    onChange={(value) => selectDateValue(value, cellIndex)}
                    disabled={false}
                    maxDate={moment().toDate()}
                    headingText={getColumnNameForCell(cellIndex)}
                    dangerHelperText={errors[cellIndex.toString()]}
                />;
            case 'BOOLEAN':
                return <FormSelect
                    loading={props.loading}
                    onItemSelect={(value) => onSelectValueChange(value, cellIndex)}
                    items={props.booleanSelectValues}
                    selectedValue={cell}
                    headingText={getColumnNameForCell(cellIndex)}
                    placeholder='Choose Yes or No'
                    dangerHelperText={errors[cellIndex.toString()]}
                />;
            case 'BOOLEANNA':
                return <FormSelect
                    loading={props.loading}
                    onItemSelect={(value) => onSelectValueChange(value, cellIndex)}
                    items={props.booleanNaSelectValues}
                    selectedValue={cell}
                    headingText={getColumnNameForCell(cellIndex)}
                    placeholder='Choose Yes, No or N/A'
                    dangerHelperText={errors[cellIndex.toString()]}
                />;
            case 'STRING':
                return <FormTextInput
                    loading={props.loading}
                    onChange={(value) => onTextValueChange(value, cellIndex)}
                    value={cell}
                    headingText={getColumnNameForCell(cellIndex)}
                    dangerHelperText={errors[cellIndex.toString()]}
                    maxLength={2000}
                />;
            case 'INTEGER':
            case 'HOLDINGDAYS':
                return <FormNumericInput
                    loading={props.loading}
                    onValueChange={(value) => selectNumbericValue(value, cellIndex)}
                    selectedValue={cell}
                    headingText={getColumnNameForCell(cellIndex)}
                    dangerHelperText={errors[cellIndex.toString()]}
                    max={100000}
                    min={0}
                />;
            case 'FIXEDTIME':
                return <FormTextInput
                    loading={props.loading}
                    onChange={() => null}
                    value={cell ? moment.utc(cell).local().format('HH:mm') : ''}
                    headingText={getColumnNameForCell(cellIndex)}
                    disabled={true}
                />;
            default:
                return formatReadonlyTextFormField('Column type not yet supported', cellIndex);
        }
    }

    function formatReadonlyTextFormField(field, cellIndex) {
        return <FormTextInput
            value={field ? field : ''}
            headingText={getColumnNameForCell(cellIndex)}
            disabled={true}
        />;
    }

    return <Fragment>
        <Modal
            isOpen={modalIsOpen}
            title={props.isEditing ? 'Edit row' : 'Add row'}
            onClose={props.onClose}
        >
            {currentRow && currentRow.map((cell, cellIndex) => {
                return <div key={getColumnNameForCell(cellIndex)}>
                    {formatFormField(cell, cellIndex)}
                </div>;
            })}
            <div className="bp3-dialog-footer">
                <div className="bp3-dialog-footer-actions">
                    <Button text={props.isEditing ? 'Edit row' : 'Add row'} intent={Intent.PRIMARY} onClick={() => props.onFinish(currentRow)} disabled={!isValid}></Button>
                    <Button text="Cancel" onClick={props.onClose}></Button>
                </div>
            </div>
        </Modal>
    </Fragment>;
}

OcrTableFormModal.defaultProps = {
    row: [],
    onValidationUpdate: null
};

OcrTableFormModal.propTypes = {
    columns: PropTypes.array.isRequired,
    row: PropTypes.array,
    getColumnTypeForCell: PropTypes.func.isRequired,
    employeeList: PropTypes.array.isRequired,
    assetList: PropTypes.array.isRequired,
    rowIndex: PropTypes.number.isRequired,
    rowBeingEdited: PropTypes.number,
    onFinish: PropTypes.func.isRequired,
    onClose: PropTypes.func.isRequired,
    isEditing: PropTypes.bool.isRequired,
    booleanSelectValues: PropTypes.array.isRequired,
    booleanNaSelectValues: PropTypes.array.isRequired,
    editableColumnTypes: PropTypes.array.isRequired,
    getEmployeeForRow: PropTypes.func.isRequired,
    onValidationUpdate: PropTypes.func
};
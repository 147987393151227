import React, { Fragment, useState, useEffect } from 'react';
import { Intent, Icon } from '@blueprintjs/core';
import classNames from 'classnames';
import axios from 'axios';

import { UserService, AccountService } from 'services';
import { FormRadio, FormSwitch, FormSelect, FormTextInput } from 'components/form-fields';
import { useValidation } from "hooks/useValidation";
import { Button, ButtonSave } from "components/buttons";
import { HelperFunctions } from "helpers";
import { ShowHide, Expandable } from 'components/layout';
import { Modal, AlertConfirm, NotificationToaster, NotificationInline } from 'components/notifications';
import { UserAccess, UserHasAccess } from 'components/user-access';

var _ = require('underscore');

export function UserRoleSettings(props) {

    const [isValid, errors, validate] = useValidation();
    const [loading, setLoading] = useState(true);
    const [loadingActions, setLoadingActions] = useState(true);
    const [templatesLoading, setTemplatesLoading] = useState(true);
    const [savingTemplate, setSavingTemplate] = useState(false);
    const [showTemplateCreateModal, setShowTemplateCreateModal] = useState(false);
    const [showTemplateDeleteModal, setShowTemplateDeleteModal] = useState(false);
    const [restrictionTemplates, setRestrictionTemplates] = useState([]);
    const [selectedTemplate, setSelectedTemplate] = useState(null);
    const [templateName, setTemplateName] = useState("");
    const [roles, setRoles] = useState([]);
    const [unmappedRoles, setUnmappedRoles] = useState([]);

    const [userSettings, setUserSettings] = useState({
        role: {},
        actionRestrictions: []
    });

    const [tableRoles, setTableRoles] = useState([]);
    const [tableExpanded, setTableExpanded] = useState(false);
    const [matrix, setMatrix] = useState([]);
    const rules = [{ fieldName: "role", required: true }];
    const templatePermission = "Internal";
    const restrictionAccess = UserHasAccess(templatePermission);

    let restrictionColumnCount = 2;
    let staticColumnCount = restrictionAccess ? restrictionColumnCount : 1;

    useEffect(updateSettings, [props.settings])

    function updateSettings() {
        if (userSettings.userId == null && props.settings != null && props.settings.userId != null && props.settings.userId !== 0 && loadingActions) {

            setLoadingActions(false);

            UserService.getActionRestrictions(props.settings.userId).then(function (actionRestrictionResponse) {

                props.onSettingsUpdate({
                    ...props.settings,
                    actionRestrictions: actionRestrictionResponse
                }, true);
            }, function () {
                NotificationToaster.show(Intent.DANGER, "Could not load the user's restrictions. Please refresh the page.");
            });

        } else {
            setUserSettings({
                ...userSettings,
                ...props.settings
            });
        }

        validate(rules, props.settings);
    }

    useEffect(onValidationChange, [isValid])

    function onValidationChange() {
        HelperFunctions.executeIfNotNull(props.onValidationUpdate, isValid);
    }

    useEffect(initialLoad, []);

    function initialLoad() {

        axios.all([UserService.getRoles(), UserService.getActions()]).then(axios.spread(function (rolesResponse, actionResponse) {

            setUnmappedRoles(rolesResponse);
            setMatrix(actionResponse);

            var mappedRoles = rolesResponse.map(function (option) {
                return {
                    value: option.id,
                    label: option.name,
                    key: option.key,
                    helperText: option.key === "" ? option.description : "",
                    disabled: !option.settable && option.key !== "Driver"
                }
            });

            var specialRoles = _.filter(mappedRoles, function (o) {
                return o.key === "Driver";
            })

            var standardRoles = _.filter(mappedRoles, function (o) {
                return o.key !== "Driver" && o.key !== "";
            });

            setRoles(specialRoles);
            setTableRoles(standardRoles);

            setLoading(false);
            HelperFunctions.executeIfNotNull(props.onLoaded);

            AccountService.getRestrictionTemplates().then(function (templates) {
                var mappedTemplates = templates.map(function (t) {
                    return {
                        id: t.templateId,
                        name: t.templateName,
                        actions: t.bannedActions
                    }
                });

                if (mappedTemplates.length > 0) {
                    mappedTemplates.unshift({
                        id: 0,
                        name: "No Restrictions",
                        actions: []
                    });
                }

                setRestrictionTemplates(mappedTemplates);
                setTemplatesLoading(false);
            }, function () {
                NotificationToaster.show(Intent.DANGER, "Could not load templates. Please refresh the page.");
            });


        }), () => {
            NotificationToaster.show(Intent.DANGER, "Could not load options. Please refresh the page.");
        });

    }

    useEffect(findMatchingTemplate, [restrictionTemplates, userSettings.actionRestrictions]);

    function findMatchingTemplate() {

        //Try and find the user's restrictions in a template
        restrictionTemplates.forEach(function (t) {

            if (t.actions.length === userSettings.actionRestrictions.length && _.difference(t.actions, userSettings.actionRestrictions).length === 0) {
                setSelectedTemplate(t.id);
            }

        });
    }


    function onRoleChange(item) {

        var newRole = unmappedRoles.find(element => element.id.toString() === item.currentTarget.value);

        if (newRole != null) {
            props.onSettingsUpdate({
                ...userSettings,
                role: newRole
            });
        }
    }

    function onDisableAction(actionId, checked) {

        var newRestrictions = checked ? _.without(userSettings.actionRestrictions, actionId) : [...userSettings.actionRestrictions, actionId]

        props.onSettingsUpdate({
            ...userSettings,
            actionRestrictions: newRestrictions
        });

        setSelectedTemplate(null);
    }

    function onTableExpand() {
        setTableExpanded((current) => {
            return !current;
        });
    }

    function onRestrictionTemplateSelect(item) {
        setSelectedTemplate(item.id);

        props.onSettingsUpdate({
            ...userSettings,
            actionRestrictions: item.actions
        });

    }

    function onShowTemplateCreateModal() {
        setShowTemplateCreateModal(true);
    }

    function onShowTemplateDeleteModal() {
        setShowTemplateDeleteModal(true);
    }

    function confirmTemplateDelete() {
        AccountService.deleteRestrictionTemplate(selectedTemplate).then(function () {
            NotificationToaster.show(Intent.SUCCESS, "Template successfully deleted");
            setShowTemplateDeleteModal(false);

            var newTemplates = _.reject(restrictionTemplates, function (t) {
                return t.id === selectedTemplate;
            });

            setRestrictionTemplates(newTemplates);
            setSelectedTemplate(null);

        }, function () {
            NotificationToaster.show(Intent.DANGER, "Unable to delete the template, please try again.");
        });
    }

    function cancelTemplateDelete() {
        setShowTemplateDeleteModal(false);
    }

    function onTemplateNameChange(event) {
        setTemplateName(event.target.value);
    }

    function onSaveTemplate() {

        var newTemplate = {
            templateName: templateName,
            bannedActions: userSettings.actionRestrictions
        }

        setSavingTemplate(true);

        AccountService.saveRestrictionTemplate(newTemplate).then(function (id) {
            NotificationToaster.show(Intent.SUCCESS, "Template has been saved.");
            setTemplateName("");
            setSavingTemplate(false);
            setShowTemplateCreateModal(false);
            setRestrictionTemplates([
                ...restrictionTemplates,
                {
                    id: id,
                    name: newTemplate.templateName,
                    actions: newTemplate.bannedActions
                }
            ]);
            setSelectedTemplate(id);
        }, function () {
            NotificationToaster.show(Intent.DANGER, "Unable to save the template, please try again.");
            setSavingTemplate(false);
        });
    }

    function onSaveTemplateCancel() {
        setShowTemplateCreateModal(false);
    }

    return (
        <Fragment>
            <ShowHide
                evaluator={props.settings.userId !== 0 && (props.settings.driverId != null || props.settings.oldDriverId != null)}
                show={(
                    <Fragment>
                        <ShowHide
                            evaluator={props.originalSettings != null && props.originalSettings.userId !== 0 && props.originalSettings.role && props.originalSettings.role.key && props.originalSettings.role.key.toUpperCase() === "DRIVER"}
                            show={(
                                <NotificationInline className={classNames({ "bp3-skeleton": loading })} intent="info" show text="This user is a Vision Driver App user. If you switch them to have a standard role they will lose access to the Vision Driver App and Driver Kiosk. If you do change the role, the user can be reverted to a driver again at a later date." />
                            )}
                        />

                        <FormRadio
                            selectedValue={userSettings.role.id}
                            loading={loading}
                            options={roles}
                            onChange={onRoleChange}
                            dangerHelperText={errors.role}
                        />
                    </Fragment>
                )}
            />


            <div className={classNames("spacer-bottom", { "bp3-skeleton": loading })}>
                <p>The matrix below details what each role is able to do within Vision. Click on the Expand table button to toggle between the list of full actions available. The user's current role's column is highlighted for quick reference.</p>
                <ShowHide evaluator={restrictionAccess || restrictionTemplates?.length > 0}
                    show={(<UserAccess perform="Internal"
                        yes={() => (
                            <p>We recommend you do not change the permissions for the roles listed below. If you need to apply a custom setting for this user and you want to save it and apply it to other users, you can do so by clicking or tapping "Save As Preset". </p>
                        )}
                        no={() => (
                            <p>We recommend you do not change the restriction preset. Applying this preset will restrict the actions for the user regardless of their role.</p>
                        )}
                    />)}
                />
            </div>

            <div className={classNames({ "scrollable": !tableExpanded, "bp3-skeleton": loading })}>

                <div className="clearfix spacer-bottom">
                    <div className="pull-right">
                        <ShowHide evaluator={restrictionAccess || restrictionTemplates?.length > 0}
                            show={(
                                <Fragment>
                                    <div className="inline-item">
                                        <span>Choose preset:</span>
                                    </div>
                                    <div className="inline-item">
                                        <FormSelect
                                            id="restrction-templates"
                                            items={restrictionTemplates}
                                            onItemSelect={onRestrictionTemplateSelect}
                                            selectedValue={selectedTemplate}
                                            placeholder="Apply a preset"
                                            loading={templatesLoading}
                                        ></FormSelect>
                                    </div>
                                    <UserAccess perform={templatePermission}
                                        yes={() => (
                                            <Fragment>
                                                <div className="inline-item">
                                                    <Button text="Remove preset" intent={Intent.DANGER} minimal={true} icon="cross" onClick={onShowTemplateDeleteModal} disabled={selectedTemplate == null}></Button>
                                                </div>
                                                <div className="inline-item">
                                                    <Button
                                                        id="template-restriction-save"
                                                        onClick={onShowTemplateCreateModal}
                                                        disabled={selectedTemplate != null || userSettings.actionRestrictions.length === 0}
                                                        loading={templatesLoading}
                                                        text="Save as preset"
                                                        intent="primary"
                                                        icon="floppy-disk"
                                                    ></Button>
                                                </div>
                                            </Fragment>
                                        )}
                                    />
                                </Fragment>
                            )}></ShowHide>

                        <Button onClick={onTableExpand} text={tableExpanded ? "Collapse Table" : "Expand Table"} intent="primary" icon={tableExpanded ? "minimize" : "maximize"} />
                    </div>
                </div>

                <div className={classNames("sticky-table-header", { "bp3-skeleton": loading })}>
                    <UserAccess perform="Internal"
                        yes={() => (
                            <div className="data-table-header" style={{ maxWidth: '75px' }}>On/Off</div>

                        )} />

                    <div className="data-table-header">Action</div>
                    {
                        tableRoles.map((role) => {
                            return (
                                <div key={role.value} className={classNames("data-table-header", { "data-table-highlight": userSettings.role.id === role.value })}>
                                    <FormRadio
                                        selectedValue={userSettings.role.id}
                                        loading={loading}
                                        options={[role]}
                                        onChange={onRoleChange}
                                    />
                                </div>
                            );
                        })
                    }
                </div>

            </div>
            <div className="spacer-bottom">

                <Expandable loading={loading} expanded={tableExpanded}>

                    <table className="data-table">
                        <thead>
                            <tr>
                                <UserAccess perform="Internal"
                                    yes={() => (
                                        <th className="data-table-header-small"></th>

                                    )} />
                                <th className="data-table-header"></th>
                                {
                                    tableRoles.map((role) => {
                                        return (
                                            <th key={role.value} className="data-table-header"></th>
                                        );
                                    })
                                }
                            </tr>
                        </thead>

                        <tbody>
                            {matrix.map((f) => {
                                return (
                                    <ShowHide 
                                        evaluator={f.actions != null && f.actions.length > 0}
                                        show={(
                                            <Fragment key={f.name}>
                                                <tr>
                                                    <td className="primary-header-cell" colSpan={tableRoles.length + staticColumnCount}>
                                                        {f.name}
                                                    </td>
                                                </tr>
                                                {f.actions != null && f.actions.map((a) => {
                                                    return (
                                                        <Fragment key={a.id}>
                                                            <tr className={classNames({ "action-disabled": userSettings.actionRestrictions && userSettings.actionRestrictions.indexOf(a.id) > -1 })}>
                                                                <UserAccess perform="Internal"
                                                                    yes={() => (
                                                                        <td>
                                                                            <FormSwitch label={null} alignment="left" checked={userSettings.actionRestrictions && userSettings.actionRestrictions.indexOf(a.id) === -1} onChange={(event) => { onDisableAction(a.id, event.target.checked) }} />
                                                                        </td>

                                                                    )} />

                                                                <td>
                                                                    {a.name}
                                                                </td>

                                                                <ShowHide
                                                                    evaluator={userSettings.actionRestrictions && userSettings.actionRestrictions.indexOf(a.id) > -1}
                                                                    show={(
                                                                        <td colSpan={tableRoles.length}></td>
                                                                    )}
                                                                    hide={

                                                                        tableRoles.map((role) => {
                                                                            return (
                                                                                <ShowHide
                                                                                    key={role.key}
                                                                                    evaluator={a.roles && a.roles.indexOf(role.key) > -1}
                                                                                    show={(
                                                                                        <td className={classNames("feature-available", { "data-table-highlight": userSettings.role.id === role.value })}><Icon icon="tick" /></td>
                                                                                    )}
                                                                                    hide={(
                                                                                        <td className={classNames("feature-unavailable", { "data-table-highlight": userSettings.role.id === role.value })}><Icon icon="cross" /></td>
                                                                                    )}
                                                                                />

                                                                            );
                                                                        })

                                                                    }
                                                                />


                                                            </tr>
                                                        </Fragment>
                                                    );
                                                })}

                                            </Fragment>
                                        )}
                                    />
                                );
                            })}
                        </tbody>
                    </table>
                </Expandable>
            </div>

            <Modal
                isOpen={showTemplateCreateModal}
                title="Preset Details"
                onClose={onSaveTemplateCancel}
            >

                <div className="bp3-dialog-body">

                    <p>Saving the selected actions as a preset will enable you to apply these permission to other users.</p>
                    <p>Enter the name of your preset:</p>
                    <FormTextInput
                        value={templateName}
                        onChange={onTemplateNameChange}
                    />

                </div>

                <div className="bp3-dialog-footer">
                    <div className="bp3-dialog-footer-actions">
                        <Button text="Cancel" intent="danger" disabled={savingTemplate} onClick={onSaveTemplateCancel}></Button>
                        <ButtonSave
                            id="template-restriction-save"
                            onClick={onSaveTemplate}
                            disabled={savingTemplate}
                            loading={templatesLoading}
                            simpleDisabled={templateName.trim().length === 0}
                        ></ButtonSave>
                    </div>
                </div>


            </Modal>

            <AlertConfirm
                isOpen={showTemplateDeleteModal}
                title={"Delete Preset?"}
                onConfirm={confirmTemplateDelete}
                onCancel={cancelTemplateDelete}
            >
                <p>Are you sure you want to delete this preset?</p>
            </AlertConfirm>

        </Fragment>
    );

}
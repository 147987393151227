import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { FormGroupedCheckboxList } from 'components/form-fields';
import { HierarchyService, LocationService } from "services";
import PropTypes from 'prop-types';

export function OcrPrevisitLocationSettings(props) {

    const [items, setItems] = useState([]);
    const [loading, setLoading] = useState(true);

    useEffect(function () {

        //Get the hierarchy features
        HierarchyService.getLocationHierarchyFeatures().then(function (featureResponse) {

            //Request each hierarchy for the features and add a tab if exists
            axios.all([
                fetchHierarchy("tachopermissions", featureResponse.hierarchyFeaturesList, "Tacho")
            ]).then(axios.spread(function (tachoHierarchy) {

                if (tachoHierarchy != null) {
                    setItems(tachoHierarchy); 
                }
                setLoading(false);
            }));
        });
    }, []);

    useEffect(function () {
        if (items != null && props.locationHierarchies.hierarchyGroups != null) {
            var clonedItems = [
                ...items
            ]

            applySelectionToHierarchy(clonedItems, props.locationHierarchies, false);
            setItems(clonedItems);
        }
    }, [props.locationHierarchies, loading])


    //Fetch the hierarchy, if no hierarchy exists get a flat list of locations
    function fetchHierarchy(key, features, businessArea) {
        return new Promise((resolve, reject) => {
            var feature = features.find(function (f) {
                return f.featureKey.toLowerCase() === key;
            });

            //Fetch the hierarchy and a list of locations for the business area
            //If the locations arent being used by the hierarchy, then append them to the end of the list
            if (feature != null) {
                axios.all([
                    HierarchyService.getHierarchyForFeature(feature.hierarchyFeatureId),
                    LocationService.getLocationList(0, 1, "", "", "", {
                        "filters": [{
                            "key": "BusinessAreas",
                            "value": businessArea
                        }]
                    }
                    )
                ]).then(axios.spread(function (selectedHierarchy, locationList) {

                    var locationsInUse = [];
                    var mapHierarchy = mapHierachyToItems(selectedHierarchy, "group", 0, locationsInUse);

                    var locationsNotInHierarchy = locationList.data.filter(function (l) {
                        return !locationsInUse.includes(l.id);
                    });

                    var mappedLocations = mapLocationsToItems(locationsNotInHierarchy);
                    mapHierarchy = mapHierarchy.concat(mappedLocations);

                    resolve([{
                        id: 0,
                        type: "group",
                        expanded: true,
                        name: "All locations",
                        checked: false,
                        items: mapHierarchy
                    }]);

                }), function () {
                    reject();
                });
            }

        });

    }

    function mapHierachyToItems(hierarchyItems, itemType, level, inUse) {

        const limit = 5;

        if (hierarchyItems == null) {
            return [];
        }

        return hierarchyItems.map(function (item) {

            var isGroup = itemType === "group";

            if (!isGroup) {
                inUse.push(item.locationId);
            }

            return {
                id: isGroup ? item.hierarchyGroupId : item.locationId,
                type: itemType,
                expanded: level === 0 && hierarchyItems.length < limit ? true : false,
                name: isGroup ? item.hierarchyGroupName : item.locationName,
                state: item.state,
                checked: false,
                items: item.groups != null && item.groups.length > 0 ? mapHierachyToItems(item.groups, "group", level+1, inUse) : mapHierachyToItems(item.locations, "item", level+1, inUse)
            };

        });

    }

    function applySelectionToHierarchy(hierarchy, selections, parentChecked) {

        var childItemsChecked = false;


        for (var i = 0; i < hierarchy.length; i++) {
            var currentItem = hierarchy[i];
            var currentItemChecked = false;
            currentItem.checked = false;
            currentItem.disabled = false;
            currentItem.partialChecked = false;


            if (parentChecked) {
                currentItem.checked = true;
                currentItem.disabled = true;
            } else {

                if (currentItem.type === "group" && selections.hierarchyGroups && selections.hierarchyGroups.includes(currentItem.id)) {
                    currentItem.checked = true;
                    currentItemChecked = true
                    childItemsChecked = true;
                }

                if (currentItem.type === "item" && selections.locations && selections.locations.includes(currentItem.id)) {
                    currentItem.checked = true;
                    childItemsChecked = true;
                }

            }

            var childChecked = applySelectionToHierarchy(currentItem.items, selections, parentChecked || currentItemChecked);

            if (childChecked) {
                childItemsChecked = true;

                if (!currentItem.checked) {
                    currentItem.partialChecked = true;
                }
            }
        }

        return childItemsChecked;

    }

    function mapLocationsToItems(locations) {
        if (locations == null) {
            return [];
        }

        return locations.map(function (item) {
            return {
                id: item.id,
                name: item.name,
                checked: false,
                type: "item",
                expanded: false,                
                state: item.state,
                items: []
            };

        });
    }

    function applySearchToHierarchy(hierarchy, searchTerm) {

        var returnedResults = false;
        var anyChildReturnedResults = false;

        for (var i = 0; i < hierarchy.length; i++) {
            var currentItem = hierarchy[i];
            currentItem.appearInSearch = false;

            if (currentItem.type === "item" && currentItem.name.toLowerCase().indexOf(searchTerm.toLowerCase()) === 0) {
                currentItem.appearInSearch = true;
                returnedResults = true;
            }

            var childSearchReturnedResults = applySearchToHierarchy(currentItem.items, searchTerm);

            if (childSearchReturnedResults) {
                currentItem.appearInSearch = true;
            }

            anyChildReturnedResults = anyChildReturnedResults || childSearchReturnedResults;
        }

        return returnedResults || anyChildReturnedResults;

    }


    function onGroupToggle(itemId) {

        var clonedItems = [
            ...items
        ]

        var group = findRecursiveItem(clonedItems, itemId, "group");
        group.expanded = !group.expanded;

        setItems(clonedItems);
    }

    function onItemSelect(id) {

       
        var clonedSelections = {
            ...props.locationHierarchies
        }

        if (clonedSelections.locations.includes(id)) {
            clonedSelections.locations = clonedSelections.locations.filter(function (l) {
                return l !== id;
            })
        } else {
            clonedSelections.locations.push(id);
        }


        props.onUpdate(clonedSelections);
    }

    function onGroupSelect(id) {

        var clonedSelections = {
            ...props.locationHierarchies
        }

        if (clonedSelections.hierarchyGroups.includes(id)) {
            clonedSelections.hierarchyGroups = clonedSelections.hierarchyGroups.filter(function (l) {
                return l !== id;
            })
        } else {
            clonedSelections.hierarchyGroups.push(id);
        }


        props.onUpdate(clonedSelections);
    }

    function onSearch(term) {

        var clone = [
            ...items
        ]

        applySearchToHierarchy(clone, term);

        setItems(clone);
    }

    function findRecursiveItem(structure, id, type) {
        var response = null;

        if (structure != null) {
            for (var i = 0; i < structure.length; i++) {
                var current = structure[i];

                if (current.type === type && current.id === id) {
                    return current;
                }

                if (current.type === "group") {
                    var innerResponse = findRecursiveItem(current.items, id, type);
                    if (innerResponse != null) {
                        response = innerResponse;
                    }
                }

            }
        }

        return response;
    }

    return (
        <div>
            <FormGroupedCheckboxList 
                items={items}
                searchable 
                groupsSelectable 
                onGroupToggle={onGroupToggle}
                onItemSelect={onItemSelect}
                onGroupSelect={onGroupSelect}
                onSearch={onSearch}
                loading={loading || props.loading}
                saving={props.saving}
                dangerHelperText={props.warning}
                disabled={props.disabled} />
        </div>
    );
}

OcrPrevisitLocationSettings.propTypes = {
    warning: PropTypes.node,
    disabled: PropTypes.bool,
    locationHierarchies: PropTypes.object,
    loading: PropTypes.bool,
    saving: PropTypes.bool,
    onUpdate: PropTypes.func.isRequired,
};

OcrPrevisitLocationSettings.defaultProps = {
    warning: null,
    disabled: false,
    locationHierarchies: {},
    loading: false,
    saving: false
};
import dummyDataScheduleList from './dummy-data/schedule-list.json';
import dummyDataReportGroups from './dummy-data/report-groups.json';
import dummyDataReportParams from './dummy-data/report-params.json';
import dummyDataUsers from './dummy-data/users.json';
import ApiService from 'services/ApiService'

export const ReportingService = {
    getSchedulesList(pageSize, pageNumber, searchTerm, sortField, sortDir) {
        return new Promise((resolve, reject) => {

            const localDummyData = JSON.parse(JSON.stringify(dummyDataScheduleList));
            let blah = localDummyData.data;

            let fakeFieldSplit = sortField.split(" ");
            let fakeField = fakeFieldSplit[0].toLowerCase();
            if (fakeFieldSplit.length > 1) {
                fakeField = fakeField + fakeFieldSplit[1]
            }
            switch (fakeField) {
                case "updatedBy":
                    blah = blah.sort(function (a, b) {
                        var fakeSort = 1;

                        if (sortDir === "D") {
                            fakeSort = -1;
                        }
                        if (a["modifyUser"] < b["modifyUser"]) {
                            return -1 * fakeSort;
                        }
                        if (a["modifyUser"] > b["modifyUser"]) {
                            return 1 * fakeSort;
                        }
                        return 0;
                    })
                    break;
                case "lastUpdated":
                    blah = blah.sort(function (a, b) {
                        var fakeSort = 1;

                        if (sortDir === "D") {
                            fakeSort = -1;
                        }
                        if (a["modifyDate"] < b["modifyDate"]) {
                            return -1 * fakeSort;
                        }
                        if (a["modifyDate"] > b["modifyDate"]) {
                            return 1 * fakeSort;
                        }
                        return 0;
                    })
                    break;
                default:
                    blah = blah.sort(function (a, b) {
                        var fakeSort = 1;
                        if (a[fakeField] == null) {
                            a[fakeField] = "-"
                        }
                        if (b[fakeField] == null) {
                            b[fakeField] = "-"
                        }
                        if (sortDir === "D") {
                            fakeSort = -1;
                        }
                        if (a[fakeField] < b[fakeField]) {
                            return -1 * fakeSort;
                        }
                        if (a[fakeField] > b[fakeField]) {
                            return 1 * fakeSort;
                        }
                        return 0;
                    })
                    break;
            }

            blah = blah.filter(function (schedule) {
                let searchUpper = searchTerm.toUpperCase();
                return schedule.name.toUpperCase().startsWith(searchUpper) ||
                    schedule.modifyUser.toUpperCase().startsWith(searchUpper) ||
                    schedule.scheduleReference.toString().toUpperCase().startsWith(searchUpper)
            });
            let newBlah = localDummyData;
            newBlah.totalCount = blah.length;

            blah = blah.slice(pageSize * (pageNumber - 1), pageSize * pageNumber);

           
            newBlah.data = blah;
           
            resolve(newBlah);
        });
    },
    getReportGroups() {
        return new Promise((resolve, reject) => {
            const localDummyData = JSON.parse(JSON.stringify(dummyDataReportGroups));
            resolve(localDummyData);
        });
    },
    getReportParams(id) {
        return new Promise((resolve, reject) => {
            const localDummyData = JSON.parse(JSON.stringify(dummyDataReportParams));
            resolve(localDummyData);
        });
    },
    getUsers() {
        return new Promise((resolve, reject) => {
            const localDummyData = JSON.parse(JSON.stringify(dummyDataUsers));
            resolve(localDummyData);
        });
    },
    getWidgets() {
        return new Promise((resolve, reject) => {
            ApiService.get(`/reporting/widgets`).then(
                (result) => {
                    resolve(result.data);
                },
                (error) => {
                    reject(error);
                });
        });
    },
    getWidget(widgetId) {
        return new Promise((resolve, reject) => {
            ApiService.get(`/reporting/widgets/${widgetId}`).then(
                (result) => {
                    resolve(result.data);
                },
                (error) => {
                    reject(error);
                });
        });
    },
    saveWidgetOrder(widgetOrder)
    {
        return new Promise((resolve, reject) => {
            ApiService.put('/reporting/widgets/selection', widgetOrder)
                .then(function (response) {
                    resolve(response);
                })
                .catch(function (error) {
                    reject(error);
                });
        })
    }
}
import React, { useState, useEffect } from 'react';
import classNames from 'classnames';
import { InputGroup, Tooltip, Button, Position } from "@blueprintjs/core";
import PropTypes from 'prop-types';

import { FormHelperText } from "components/form-fields";


export function FormPasswordConfirm(props) {

    const [password, setPassword] = useState("");
    const [showPassword, setShowPassword] = useState(false);
    const [passwordConfirm, setPasswordConfirm] = useState("");
    const [showPasswordConfirm, setShowPasswordConfirm] = useState(false);
    const [criteriaMet, setCriteriaMet] = useState(false);
    const [passwordsMatch, setPasswordsMatch] = useState(false);
    const passwordCriteria = new RegExp("^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9]).{8,20}$");

    function onPasswordToggle(setFieldFunction) {
        setFieldFunction((p) => {
            return !p;
        });
    }

    function onPasswordUpdate(setFieldValue, event) {
        setFieldValue(event.target.value);
    }

    useEffect(passwordChangeCallback, [password, passwordConfirm]);

    function passwordChangeCallback() {
        var hasCriteriaBeenMet = passwordCriteria.test(password);
        var matching = password === passwordConfirm;
        props.onChange(password, matching, hasCriteriaBeenMet);
        setPasswordsMatch(matching);
        setCriteriaMet(hasCriteriaBeenMet);
    }

    return (
        <div id={props.id} className="password-confirm">
            <div class="helper-text">
                <p>Your password must meet the following criteria:</p>
                <ul>
                    <li>Minimum length of 8 characters</li>
                    <li>Maximum length of 20 characters</li>
                    <li>At least one number</li>
                    <li>At least one lower case letter</li>
                    <li>At least one upper case letter</li>
                </ul>
            </div>
            <div className="form-field">
                <h4 className={classNames({ "bp3-skeleton": props.loading })}>Password:</h4>
                <InputGroup
                    className={classNames({ "bp3-skeleton": props.loading })}
                    disabled={props.disabled}
                    value={password}
                    onChange={(event) => { onPasswordUpdate(setPassword, event); }}
                    type={showPassword ? "text" : "password"}
                    rightElement={(
                        <Tooltip position={Position.RIGHT} content={`${showPassword ? "Hide" : "Show"} Password`}>
                            <Button
                                icon={showPassword ? "unlock" : "lock"}
                                minimal={true}
                                onClick={() => { onPasswordToggle(setShowPassword); }}
                                tabIndex="-1"
                            />
                        </Tooltip>
                       )}
                />
                <FormHelperText loading={props.loading} danger={true}>{criteriaMet ? null : props.dangerHelperText}</FormHelperText>
            </div>
            <div className="form-field">
                <h4 className={classNames({ "bp3-skeleton": props.loading })}>Confirm Password:</h4>
                <InputGroup
                    className={classNames({ "bp3-skeleton": props.loading })}
                    disabled={props.disabled}
                    value={passwordConfirm}
                    onChange={(event) => { onPasswordUpdate(setPasswordConfirm, event); }}
                    type={showPasswordConfirm ? "text" : "password"}
                    rightElement={(
                        <Tooltip position={Position.RIGHT} content={`${showPasswordConfirm ? "Hide" : "Show"} Password`}>
                            <Button
                                icon={showPasswordConfirm ? "unlock" : "lock"}
                                minimal={true}
                                onClick={() => { onPasswordToggle(setShowPasswordConfirm); }}
                                tabIndex="-1"
                            />
                        </Tooltip>
                    )}
                />
                <FormHelperText loading={props.loading} danger={true}>{passwordsMatch || passwordConfirm.length === 0 ? null : "Your passwords do not match"}</FormHelperText>
            </div>
        </div>
    );
}

FormPasswordConfirm.defaultProps = {
    id: null,
    value: "",
    loading: false,
    headingText: "",
    helperText: null,
    disabled: false,
    dangerHelperText: null,
    placeholder: "",
    large: false,
    icon: null,
    type: "text"
};

FormPasswordConfirm.propTypes = {
    id: PropTypes.string,
    value: PropTypes.string,
    onChange: PropTypes.func,
    loading: PropTypes.bool,
    headingText: PropTypes.string,
    helperText: PropTypes.node,
    disabled: PropTypes.bool,
    dangerHelperText: PropTypes.node,
    placeholder: PropTypes.string,
    large: PropTypes.bool,
    icon: PropTypes.string,
    inputRef: PropTypes.object,
    type: PropTypes.string
};
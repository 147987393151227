import React from 'react';
import PropTypes from 'prop-types';
import classNames from "classnames";
import { UL } from '@blueprintjs/core';

export function BulletedList(props) {

    return (
        <div
            className={classNames({ "bp3-skeleton": props.loading })}
        >
            <UL>
                {
                    props.listItems.map((item) => {
                        return <li key={item}>{item}</li>
                    })
                }
            </UL>
        </div>
    );

}

BulletedList.defaultProps = {
    listItems: [],
    loading: true
};

BulletedList.propTypes = {
    listItems: PropTypes.array.isRequired,
    loading: PropTypes.bool.isRequired,
};
import React, { useState, useEffect } from 'react';
import { Dialog } from '@blueprintjs/core';
import { HelperFunctions } from 'helpers';

export function AccountSwitcher(props) {
    const [isOpen, setIsOpen] = useState(false);

    useEffect(() => {
        setIsOpen(props.isOpen);
    }, [props.isOpen]);

    function handleClose() {
        setIsOpen(false);   
        HelperFunctions.executeIfNotNull(props.onClose);
    }

    return (
        <Dialog
            isOpen={isOpen}
            title="Switch Account"
            isCloseButtonShown={true}
            onClose={handleClose}
            autoFocus={false}
            enforceFocus={false}
        >
            <div className="bp3-dialog-body">
                {props.children}
            </div>

        </Dialog>
    );
}
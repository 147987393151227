import React from 'react';
import PropTypes from "prop-types";
import { Modal } from 'components/notifications';
import { Intent } from '@blueprintjs/core';
import { Key } from 'components/key';
import './CalendarKeyModal.css';

export function CalendarKeyModal(props) {
    const { showKeyModal, closeKeyModal, isInitialising, activityTypesForKeyModal, calendarIconsForKeyModal } = props;

    return (
        <Modal
            isOpen={showKeyModal}
            clickOutsideClose
            onClose={closeKeyModal}
            title="Key"
            icon="warning-sign"
            intent={Intent.DANGER}
            confirmButtonText="Dismiss"
            onConfirm={closeKeyModal}
            className="calendar-key-modal"
        >
            <div>
                <div className="row spacer-bottom">
                    <div className="col">
                        <h5 className="key">Activity Types</h5>
                        <Key listVertical loading={isInitialising} items={activityTypesForKeyModal} />
                    </div>
                    <div className="col spacer-left">
                        <h5 className="key">Calendar Icons</h5>
                        <Key rounded listVertical loading={isInitialising} items={calendarIconsForKeyModal} hideBorder={true} />
                    </div>
                </div>
            </div>
        </Modal>
    );
}


CalendarKeyModal.defaultProps = {
    showKeyModal: false,
    closeKeyModal: () => { },
    isInitialising: false,
    activityTypesForKeyModal: [],
    calendarIconsForKeyModal: []
};

CalendarKeyModal.propTypes = {
    showKeyModal: PropTypes.bool.isRequired,
    closeKeyModal: PropTypes.func,
    isInitialising: PropTypes.bool,
    activityTypesForKeyModal: PropTypes.array.isRequired,
    calendarIconsForKeyModal: PropTypes.array.isRequired
};
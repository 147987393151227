import React, { useState, Fragment, useEffect } from 'react';
import { Icon } from "@blueprintjs/core";
import { Draggable, Droppable } from "react-beautiful-dnd";

import classNames from "classnames";
import { Button } from 'components/buttons';
import { ShowHide } from 'components/layout';
import { useDispatch } from 'react-redux';

import { HelperFunctions } from 'helpers';

import './Hierarchy.css';


export function HierarchyItem(props) {

    const [canAddLocations, setCanAddLocations] = useState(false);
    const [canAddGroup, setCanAddGroup] = useState(false);

    const dispatch = useDispatch();
    const evenRowNumber = 2;

    useEffect(function () {

        setCanAddLocations((props.level === props.maxLevels) || (props.level === props.locationDepth) || (props.level === props.groupDepth && props.locationDepth === null));

        var maxDepthGroupCanBe = props.locationDepth == null ? props.maxLevels : props.locationDepth;
        setCanAddGroup(props.level < maxDepthGroupCanBe && !props.isSearching);

    }, [props.level, props.maxLevels, props.locationDepth, props.groupDepth, props.isSearching]);

    function onGroupAdd() {
        dispatch({ type: 'ADD_HIERARCHY_GROUP', payload: props.item.hierarchyGroupId });
    }

    function onGroupDelete(groupId) {
        dispatch({ type: 'DELETE_HIERARCHY_GROUP', payload: groupId });
    }

    function onGroupEdit(groupId, groupName) {
        dispatch({ type: 'EDIT_HIERARCHY_GROUP', payload: { group: groupId, groupName: groupName } });
    }

    function onLocationRemove(locationId) {

        if (props.disabled) {
            return;
        }

        dispatch({ type: 'DELETE_LOCATION_FROM_GROUP', payload: { group: props.item.hierarchyGroupId, location: locationId } });
    }

    function getMutipleItemSuffix(list) {
        return list.length === 1 ? "" : "s";
    }

    return (
        <Fragment>
            {
                props.item?.groups?.map(function (item) {
                    return (
                        <ShowHide
                            key={`hierarchy-item-${item.hierarchyGroupName}`}
                            evaluator={item.searchVisible == null || item.searchVisible === true}
                            show={(
                                <div key={`hierarchy-item-${item.hierarchyGroupName}`} className={classNames("hierarchy-item", { "item-striped": props.level % evenRowNumber === 0 })}>
                                    <div className="hierarchy-title-row">
                                        <h4 className="hierarchy-title">
                                            <Icon icon={(props.level + 1 === props.maxLevels) || (props.level + 1 === props.locationDepth) ? "map-marker" : "folder-open"} /> &nbsp;
                                    {item.hierarchyGroupName}
                                            <span className="hierarchy-title-info">{item.locations != null && item.locations.length > 0 ? `${item.locations.length} location${getMutipleItemSuffix(item.locations)}` : ""} </span>
                                        </h4>
                                        <div>
                                            <ShowHide
                                                evaluator={props.isSearching}
                                                hide={(
                                                    <Fragment>
                                                        <Button
                                                            icon="edit"
                                                            minimal={true}
                                                            large={false}
                                                            onClick={() => onGroupEdit(item.hierarchyGroupId, item.hierarchyGroupName)}
                                                            loading={props.loading}
                                                            disabled={props.disabled}
                                                        />
                                                        <Button
                                                            icon="trash"
                                                            minimal={true}
                                                            large={false}
                                                            onClick={() => onGroupDelete(item.hierarchyGroupId)}
                                                            loading={props.loading}
                                                            disabled={props.disabled}
                                                        />
                                                    </Fragment>
                                                )}
                                            />
                                            
                                        </div>
                                    </div>

                                    <HierarchyItem item={item} level={props.level + 1} maxLevels={props.maxLevels} groupDepth={props.groupDepth} locationDepth={props.locationDepth} isSearching={props.isSearching} loading={props.loading} disabled={props.disabled} />
                                </div>
                            )}
                        />
                        
                    );
                })
            }


            <div className="hierarchy-item-actions">

                <ShowHide
                    evaluator={canAddLocations}
                    show={(

                        <Droppable droppableId={`${props.item.hierarchyGroupId}`} direction="horizontal" isDropDisabled={props.disabled}>
                            {(provided, snapshot) => (
                                <div {...provided.droppableProps}
                                    ref={provided.innerRef}
                                    className={classNames("hierarchy-drop", {"drop-empty": props.item.locations?.length === 0, "drop-hover": snapshot.isDraggingOver})}
                                >

                                    {HelperFunctions.sortAlphabetically(props.item.locations, 'locationName')?.map((item, index) => (
                                        <Draggable
                                            key={item.locationId}
                                            draggableId={`hierarchy-location-${props.item.hierarchyGroupId}-${item.locationId}`}
                                            index={index}
                                            isDragDisabled={true}
                                        >
                                            {(draggableProvided) => (
                                                <div
                                                    ref={draggableProvided.innerRef}
                                                    {...draggableProvided.draggableProps}
                                                    {...draggableProvided.dragHandleProps}
                                                    className={classNames("hierarchy-location-item", item.class)}
                                                >
                                                    {item.locationName}
                                                    <div className={classNames("delete-location", { "delete-location-disabled": props.disabled })}>
                                                        <Icon
                                                            icon="small-cross"
                                                            onClick={() => onLocationRemove(item.locationId)}
                                                        />
                                                    </div>        
                                                </div>
                                            )}
                                        </Draggable>
                                    ))}
                                    {provided.placeholder}
                                </div>
                            )}
                        </Droppable>

                    )}
                />

                <ShowHide
                    evaluator={canAddLocations && canAddGroup}
                    show={(
                        <div className="call-to-action">or</div>
                    )}
                />
                <ShowHide
                    evaluator={canAddGroup}
                    show={(
                        <div>
                            <Button
                                icon="plus"
                                text={props.item.groups && props.item.groups.length > 0 ? `Add another group to ${props.item.hierarchyGroupName}` : `Add a group to ${props.item.hierarchyGroupName}`}
                                minimal={true}
                                large={false}
                                onClick={onGroupAdd}
                                loading={props.loading}
                                disabled={props.disabled}
                            />
                        </div>
                    )}
                />

            </div>

            

           
            
        </Fragment>
        
    )
}
import ApiService from 'services/ApiService';
import { UserService } from 'services';
import axios from 'axios';

const badRequestCode = 400;

export const LocationService = {
	getLocationList(pageSize, pageNumber, searchTerm, sortField, sortDir, locationFilter) {
		const filterTerm = this.buildLocationListFilterTerm(locationFilter);

		return new Promise((resolve, reject) => {
			ApiService.get(`/location/Locations?requestCount=${pageSize}&pageNumber=${pageNumber}&searchTerm=${encodeURI(searchTerm)}&sortField=${encodeURI(sortField)}&sortDirection=${sortDir}${encodeURI(filterTerm)}`).then(
				(result) => {
					resolve(result.data);
				},
				(error) => {
					reject(error);
				});
		});
	},
	getLocationListForAnotherAccount(accountId, pageSize, pageNumber, searchTerm, sortField, sortDir, locationFilter) {
		const filterTerm = this.buildLocationListFilterTerm(locationFilter);

		return new Promise((resolve, reject) => {
			ApiService.get(`/location/Locations/Account/${accountId}?requestCount=${pageSize}&pageNumber=${pageNumber}&searchTerm=${encodeURI(searchTerm)}&sortField=${encodeURI(sortField)}&sortDirection=${sortDir}${encodeURI(filterTerm)}`).then(
				(result) => {
					resolve(result.data);
				},
				(error) => {
					reject(error);
				});
		});
	},
	getTachoSettings(locationId) {
		return new Promise((resolve, reject) => {
			ApiService.get(`/location/TachoSettings/${locationId}`).then(
				(result) => {
					resolve(result.data);
				},
				(error) => {
					reject(error);
				});
		});
	},
	putTachoSettings(locationId, settings) {
		return new Promise((resolve, reject) => {
			ApiService.put(`/location/TachoSettings/${locationId}`, settings).then(
				(result) => {
					resolve(result.data);
				},
				(error) => {
					reject(error);
				});
		});
	},
	getChangelog(locationId, pageSize, pageNumber, startDate, endDate) {
		return new Promise((resolve, reject) => {
			ApiService.get(`/location/changelog/${locationId}?resultCount=${pageSize}&pageNumber=${pageNumber}&startDate=${startDate}&endDate=${endDate}`).then(
				(result) => {
					resolve(result.data);
				},
				(error) => {
					reject(error);
				});
		});
	},
	getLocationBasicInfo(id) {
		return new Promise((resolve, reject) => {
			ApiService.get(`/location/locations/${id}`).then(
				(result) => {
					resolve(result.data);
				},
				(error) => {
					reject(error.response);
				});
		});
	},	
	getLocationStates() {
		return new Promise((resolve, reject) => {
			ApiService.get(`/location/Options/LocationStates`).then(
				(result) => {
					resolve(result.data);
				},
				(error) => {
					reject(error);
				});
		});
	},
	getLocationBusinessAreas() {
		return new Promise((resolve, reject) => {
			ApiService.get(`/location/Options/LocationBusinessAreas`).then(
				(result) => {
					resolve(result.data);
				},
				(error) => {
					reject(error);
				});
		});
	},
	saveGeneralSettings(location) {
		return new Promise((resolve, reject) => {
			ApiService.put(`/location/Locations/${location.id}`, location)
				.then(function (response) {
					resolve(response);
				})
				.catch(function (error) {
					if (error.response.status === badRequestCode) {
						reject(error.response);
					} else {
						reject("Unable to update settings. Please try again later.");
					}
				});
		});
	},
	createLocation(location) {
		return new Promise((resolve, reject) => {
			ApiService.post('/location/Locations', location)
				.then(function (response) {
					resolve(response.data);
				})
				.catch(function (error) {
					if (error.response.status === badRequestCode) {
						reject(error.response.data);
					} else {
						reject("Could not create your location. Please try again.");
					}
				});
		});
	},
	getContacts(locationId) {
		return new Promise((resolve, reject) => {
			ApiService.get(`/location/Contacts/Location/${locationId}`).then(
				(result) => {
					resolve(result.data);
				},
				(error) => {
					reject(error);
				});
		});
	},
	createContact(contact) {
		return new Promise((resolve, reject) => {
			ApiService.post('/location/Contacts', contact)
				.then(function (response) {
					resolve(response.data);
				})
				.catch(function (error) {
					reject(error);
				});
		});
	},
	createContacts(contacts, external) {

		var apiEndpoint = 'Contacts';

		if (external) {
			apiEndpoint = 'ExternalContacts';
		}

		var endpoint = `/location/${apiEndpoint}/Bulk`;

		return new Promise((resolve, reject) => {
			ApiService.post(endpoint, contacts)
				.then(function (response) {
					resolve(response.data);
				})
				.catch(function (error) {
					reject(error);
				});
		});
	},
	deleteContact(contactId, external) {

		var apiEndpoint = external ? '/location/ExternalContacts' : '/location/Contacts';

		return new Promise((resolve, reject) => {
			ApiService.delete(`${apiEndpoint}/${contactId}`)
				.then(function (response) {
					resolve(response.data);
				})
				.catch(function (error) {
					if (error.response.status === badRequestCode) {
						reject(error.response.data);
					} else {
						reject("Could not delete the contact. Please try again.");
					}
				});
		});
	},
	deleteExternalContact(externalContactId) {
		return new Promise((resolve, reject) => {
			ApiService.delete(`/location/ExternalContacts/${externalContactId}`)
				.then(function (response) {
					resolve(response.data);
				})
				.catch(function (error) {
					if (error.response.status === badRequestCode && error.response.data) {
						reject(error.response.data);
					} else {
						reject("Could not delete the external contact. Please try again.");
					}
				});
		});
	},
	getExternalContacts(locationId) {
		return new Promise((resolve, reject) => {
			ApiService.get(`/location/ExternalContacts/Location/${locationId}`).then(
				(result) => {
					resolve(result.data);
				},
				(error) => {
					reject(error);
				});
		});
	},
	getLocationContactTypes() {
		return new Promise((resolve, reject) => {
			ApiService.get(`/location/Options/ContactTypes`).then(
				(result) => {
					resolve(result.data);
				},
				(error) => {
					reject(error);
				});
		});
    },
	buildLocationListFilterTerm(locationFilter) {
		let filterTerm = '';

		if (locationFilter && locationFilter.filters && locationFilter.filters.length > 0) {
			for (let i = 0; i < locationFilter.filters.length; i++) {
				filterTerm = filterTerm + '&filters[' + i + '].Key=' + locationFilter.filters[i].key + '&filters[' + i + '].Value=' + locationFilter.filters[i].value;
			}
		}

		return filterTerm;
	},
	getLocationAdditionalInformation(locationId) {
		return new Promise((resolve, reject) => {
			ApiService.get(`/location/Locations/${locationId}/AdditionalDetails`).then(
				(result) => {
					resolve(result.data);
				},
				(error) => {
					reject(error);
				});
		});
	},
	saveAdditionalInfoAddress(locationId, address) {
		return new Promise((resolve, reject) => {
			ApiService.put(`/location/Locations/${locationId}/Address`, address)
				.then(function (response) {
					resolve(response);
				})
				.catch(function (error) {
					if (error.response.status === badRequestCode) {
						reject(error.response);
					} else {
						reject("Unable to save address. Please try again later.");
					}
				});
		});
	},
	saveAdditionalInfo(locationId, additionalInfo) {
		return new Promise((resolve, reject) => {
			ApiService.patch(`/location/Locations/${locationId}/AdditionalInfo`, additionalInfo)
				.then(function (response) {
					resolve(response);
				})
				.catch(function (error) {
					if (error.response.status === badRequestCode) {
						reject(error.response);
					} else {
						reject("Unable to save location additional information. Please try again later.");
					}
				});
		});
	},
	postCodeSearch(postcode) {
		return new Promise((resolve, reject) => {
			ApiService.get(`/location/Address/PostcodeLookup/${postcode}`)
				.then(function (response) {
					resolve(response.data);
				})
				.catch(function (error) {
					if (error.response.status === badRequestCode) {
						reject(error.response.data);
					} else {
						reject("Unable to get address list. Please try again later.");
					}
				});
		});
	},
	postCodeDrillDown(id, country) {
		return postCodeDrillDownRetrieve(id, country, "Drilldown");
	},
	postCodeRetrieve(id, country) {
		return postCodeDrillDownRetrieve(id, country, "Retrieve");
	},
	saveOperatingHours(locationId, operatingHours) {
		return new Promise((resolve, reject) => {
			ApiService.put(`/location/Locations/${locationId}/OperatingHours`, operatingHours)
				.then(function (response) {
					resolve(response);
				})
				.catch(function (error) {
					if (error.response.status === badRequestCode) {
						reject(error.response);
					} else {
						reject("Unable to save visiting hours. Please try again later.");
					}
				});
		});
	},
	saveNotes(locationId, notes) {

		return new Promise((resolve, reject) => {
			ApiService.put(`/location/Locations/${locationId}/Notes`, notes)
				.then(function (response) {
					resolve(response);
				})
				.catch(function (error) {
					if (error.response.status === badRequestCode) {
						reject(error.response);
					} else {
						reject("Unable to save location notes. Please try again later.");
					}
				});
		});
	},
	getFormLocationSelectorCustomLocations(businessAreasToProcess) {
		let locationsList = {
			location: [{
				id: 0,
				type: "group",
				expanded: true,
				name: "All locations",
				checked: true,
				items: []
			}],
			hierarchy: []
		};

		return new Promise((resolve, reject) => {
			if (businessAreasToProcess !== null && businessAreasToProcess.length > 0) {
				this.getLocationBusinessAreas().then(function (locBusAreas) {
					let bas = businessAreasToProcess.map(b => b.toLowerCase());
					let businessAreaIds = locBusAreas.filter(lba => bas.includes(lba.businessArea.toLowerCase())).map(b => b.locationBusinessAreaId);
					let axiosProcessList = businessAreaIds.map(i => UserService.getLoggedInUserLocations(i, true, true));

					if (axiosProcessList != null && axiosProcessList.length > 0) {
						axios.all(axiosProcessList).then(function (results) {
							let mergedLocations = [];

							results.forEach(function (a) {
								mergedLocations = mergedLocations.concat(a.location[0].items);
							});

							locationsList.location[0].items = Array.from(new Set(mergedLocations.map(a => a.id)))
								.map(id => {
									return mergedLocations.find(a => a.id === id)
								})
								.sort(function (a, b) { return a.name > b.name ? 1 : -1; });

							resolve(locationsList);
						},
							(error) => {
								reject(error);
							});
					} else {
						reject("Business areas could not be loaded. Please try loading the page again.");
					}
				},
					(error) => {
						reject(error);
					});
			}
			else {
				resolve(locationsList);
			}
		});
	}
};


function postCodeDrillDownRetrieve(id, country, callType) {
	return new Promise((resolve, reject) => {
		//This can have characters like + in and the Axos call encodes it, so if we do this we get the right values in the API
		var idEncoded = encodeURIComponent(id);

		ApiService.get(`/location/Address/${callType}?data8Id=${idEncoded}&addressCountry=${country}`)
			.then(function (response) {
				resolve(response.data);
			})
			.catch(function (error) {
				if (error.response.status === badRequestCode) {
					reject(error.response.data);
				} else {
					reject("Unable to get address list. Please try again later.");
				}
			});
	});
}
import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

export function CustomIcon(props) {
    const iconPrefix = 'custom-icon-';

    return (
        <Fragment>
            <i className={classNames(`${iconPrefix}${props.iconName}`, props.className)}></i>
        </Fragment>
    )
}

CustomIcon.defaultProps = {
    iconName: '',
    className: ''
};

CustomIcon.propTypes = {
    iconName: PropTypes.string.isRequired,
    className: PropTypes.string
};
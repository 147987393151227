import ApiService from 'services/ApiService'

const badRequestCode = 400;

export const CollisionsService = {
	getOptions() {
		return new Promise((resolve, reject) => {
			ApiService.get(`/employee/Options/CollisionSettings`).then(
				(result) => {
					resolve(result.data);
				},
				(error) => {
					reject(error);
				});
		});
	},
    getAllCollisions(pageNumber, pageSize, searchTerm, sortField, sortDirection, filters, selectedLocations) {

        var searchFilters = filters.map((filter) => {
            return {
                key: filter.name,
                value: filter.value
            }
        });

        var locationFilters = selectedLocations.map((location) => {
            return {
                key: "Location",
                value: location
            }
        });

        var searchObject = {
            "requestCount": pageSize,
            "pageNumber": pageNumber,
            "searchTerm": searchTerm,
            "sortField": sortField,
            "sortDirection": sortDirection,
            "filters": [
                ...searchFilters,
                ...locationFilters
            ]
        };

        return new Promise((resolve, reject) => {
            ApiService.post(
                `/employee/Collisions/search`, searchObject).then(
                    (response) => {
                        resolve(response.data);
                    },
                    (error) => {
                        if (error.response.status === badRequestCode) {
                            reject(error.response);
                        } else {
                            reject(
                                "Unable to search collisions. Please try again later."
                            );
                        }
                    }
                );
        });
    },
    deleteCollision(collisionId) {
        return new Promise((resolve, reject) => {
            ApiService.delete(`/employee/Collisions/${collisionId}`).then(
                (result) => {
                    resolve(result.data);
                },
                () => {
                    reject("Unable to delete the collision, please try again.");
                });
        });
    },
    getCollisionOptions() {
        return new Promise((resolve, reject) => {
            ApiService.get(`/employee/Options/CollisionSettings`).then(
                (result) => {
                    resolve(result.data);
                },
                (error) => {
                    reject(error);
                });
        });
    },
    createCollision(collision) {
        return new Promise((resolve, reject) => {
            ApiService.post(`/employee/Collisions`, collision).then(
                (result) => {
                    resolve(result.data);
                },
                (error) => {
                    reject(error);
                });
        });
    },
    getCollision(id) {
        return new Promise((resolve, reject) => {
            ApiService.get(`/employee/Collisions/${id}`).then(
                (result) => {
                    resolve(result.data);
                },
                (error) => {
                    reject(error.response);
                });
        });
    },
    updateCollision(id, collision) {
        return new Promise((resolve, reject) => {

            ApiService.put(`/employee/Collisions/${id}`, collision).then(
                (result) => {
                    resolve(result.data);
                },
                (error) => {
                    reject(error);
                });
        });
    },
}


import React from 'react';
import PropTypes from 'prop-types';
import { NavLink } from 'react-router-dom'

export function InternalLink(props) {

    return (
        <NavLink to={props.link} exact className="">{props.children}</NavLink>
    );

}

InternalLink.propTypes = {
    link: PropTypes.string.isRequired
}
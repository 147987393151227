import React, { useState } from "react";
import PropTypes from 'prop-types';
import { UserAccess } from "components/user-access";
import { PageRestricted } from "pages/errors/page-restricted";
import { Tabs } from "components/navigation";
import { ShowHide } from "components/layout";
import { EmployeeLicenceCheckingDetails } from "pages/employee/edit/licence-checking/details";
import { EmployeeLicenceCheckingCategories } from "pages/employee/edit/licence-checking/categories";
import { EmployeeLicenceCheckingEndorsements } from "pages/employee/edit/licence-checking/endorsements";
import { EmployeeLicenceCheckingAlerts } from "pages/employee/edit/licence-checking/alerts";
import classNames from "classnames";

export function EmployeeLicenceChecking(props) {
    const noLicenceCheckCode = 404;
    const serviceNotAvailableCode = 500;

    const [currentTab, setCurrentTab] = useState(0);

    const requiredActions = ["LicenceCheck:LicenceBureau:View"];

    const [tabs, setTabs] = useState([
        { key: "DETAILS", title: "Details", selected: true },
        { key: "ENDORSEMENTS", title: "Endorsements" },
        { key: "CATEGORIES", title: "Categories" },
        { key: "ALERTS", title: "Alerts" }
    ]);

    function onTabClick(index) {

        var clonedTabs = [
            ...tabs
        ];

        clonedTabs = clonedTabs.map(function (t, i) {
            return {
                ...t,
                selected: index === i
            };
        });

        setTabs(clonedTabs);
        setCurrentTab(index);
    }


    function renderLicenceCheck() {
        if (props.errorCode === noLicenceCheckCode) {
            return <p>No matching employee record found in Licence Bureau (this could be due to an error with the licence number Vision has for this employee).</p>;
        } else if (props.errorCode === serviceNotAvailableCode) {
            return <p>Licence checking service is currently unavailable. Please try again.</p>;
        }

        const currentTabKey = tabs[currentTab].key;

        return <div>
            <div className="spacer-bottom">
                <div className="intro-text">
                    <p className={classNames({ "bp3-skeleton": props.loading })}>In this section you can view the licence information for the employee from Licence Bureau.</p>
                </div>
                <Tabs tabs={tabs} onClick={onTabClick} loading={props.loading} />
                <ShowHide
                    evaluator={currentTabKey === 'DETAILS'}
                    show={(
                        <EmployeeLicenceCheckingDetails licenceCheck={props.licenceCheck} loading={props.loading} />
                    )}
                />

                <ShowHide
                    evaluator={currentTabKey === 'ENDORSEMENTS'}
                    show={(
                        <EmployeeLicenceCheckingEndorsements licenceCheck={props.licenceCheck} />
                    )}
                />

                <ShowHide
                    evaluator={currentTabKey === 'CATEGORIES'}
                    show={(
                        <EmployeeLicenceCheckingCategories licenceCheck={props.licenceCheck} employeeOptions={props.employeeOptions} />
                    )}
                />

                <ShowHide
                    evaluator={currentTabKey === 'ALERTS'}
                    show={(
                        <EmployeeLicenceCheckingAlerts employeeId={props.employeeId} licenceBureauAlertTypes={props.licenceBureauAlertTypes} />
                    )}
                />
            </div>
        </div>;
    }

    return <UserAccess
        perform={requiredActions}
        yes={() => (
            <div className="row">
                <h2>Licence Checking</h2>
                {renderLicenceCheck()}
            </div>
        )}
        no={() => (
            <PageRestricted />
        )}
    />;
}

EmployeeLicenceChecking.defaultProps = {
    employeeOptions: null,
    licenceBureauAlertTypes: [],
    licenceCheck: null,
    loading: false,
    errorCode: 200
}

EmployeeLicenceChecking.propTypes = {
    employeeId: PropTypes.string.isRequired,
    employeeOptions: PropTypes.object,
    licenceBureauAlertTypes: PropTypes.array,
    licenceCheck: PropTypes.object,
    loading: PropTypes.bool,
    errorCode: PropTypes.number
};
import React, { useState } from 'react';
import { HierarchyService } from "services";
import { AuditingTable } from 'components/auditing';

export function HierarchyChangelog(props) {

    const noDataMessage = "It looks like no previous changes have been made to this hierarchy.";
    const [tableData, setTableData] = useState(null);
    const [tableRecordCount, setTableRecordCount] = useState(0);

    function onTableChange(pageSize, pageNumber, startDate, endDate) {
        HierarchyService.getChangelog(props.hierarchyId, pageSize, pageNumber, startDate, endDate).then(r => {
            setTableData(r.data);
            setTableRecordCount(r.totalCount);
        });
    }

    return (
        <AuditingTable data={tableData} totalCount={tableRecordCount} onChange={onTableChange} noDataMessage={noDataMessage}></AuditingTable>
    );

}
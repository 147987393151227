import React, { useState, useEffect } from 'react';
import { Route, Switch, Redirect, useParams } from 'react-router-dom';

import { HorizontalLinkGroup, Breadcrumbs } from 'components/navigation';
import { NotificationInline } from 'components/notifications';
import { PageRestricted } from 'pages/errors/page-restricted';
import { ShowHide } from 'components/layout';
import { LinkButton } from 'components/buttons';
import { HierarchySettings } from 'pages/locations/hierarchies/edit/settings';
import { HierarchyChangelog } from 'pages/locations/hierarchies/edit/changelog';
import { UserAccess, UserHasAccess } from 'components/user-access';
import { HierarchyService } from 'services';
import { HierarchyStructure } from 'pages/locations/hierarchies/edit/structure';

export function Hierarchy(props) {

    const { id } = useParams();
    const [loadingData, setLoadingData] = useState(true);
    const [invalidHierarchy, setInvalidHierarchy] = useState(false);

    const requiredAction = "Locations:HierarchiesManage";
    const canAccessPage = UserHasAccess(requiredAction);

    const [hierarchySettings, setHierarchySettings] = useState({
        hierarchyName: "",
        businessAreas: [],
        hierarchyFeatures: []
    });

    const routes = [
        { location: `/location/hierarchies/${id}/structure`, title: "Hierarchy Structure" },
        { location: `/location/hierarchies/${id}/settings`, title: "Hierarchy Settings" },
        { location: `/location/hierarchies/${id}/changes`, title: "History" }
    ];

    useEffect(() => {

        if (!canAccessPage) {
            return;
        }

        if (loadingData) {
            HierarchyService.getHierarchy(id).then(r => {
                setHierarchySettings(r);
                setLoadingData(false);
            }, (error) => {
                const noAccessCode = 403;

                if (error.status === noAccessCode) {
                    setInvalidHierarchy(true);
                }
            });
        }
    }, [loadingData, id]);

    function onSettingSave(updatedSettings) {
        setHierarchySettings(updatedSettings);
    }

    return (

        <UserAccess perform={requiredAction}
            yes={() => (
                <ShowHide
                    evaluator={invalidHierarchy}
                    show={(
                        <div className="row">
                            <Breadcrumbs items={props.breadcrumbs} />
                            <NotificationInline
                                allowClose={false}
                                show
                                text="You do not have permission to view this hierarchy"
                                intent="danger">
                            </NotificationInline>
                            <div>
                                <LinkButton intent="primary" text="Back to hierarchy listing" href="/location/hierarchies" id="return-listing" />
                            </div>
                        </div>
                    )}
                    hide={(
                        <div className="row">
                            <Breadcrumbs items={props.breadcrumbs} />
                            <h1>{hierarchySettings.hierarchyName}</h1>
                            <div className="intro-text">
                                <p>This page allows you to create and manage your hierarchy and which parts of Vision the hierarchy is applied to. You can manage the locations in the Hierarchy tab. You can manage which parts of Vision it is applied to under the Hierarchy Settings tab.</p>
                            </div>

                            <div className="spacer-bottom">
                                <HorizontalLinkGroup links={routes}></HorizontalLinkGroup>
                            </div>

                            <Switch>

                                <Route path="/location/hierarchies/:id(\d+)/structure">
                                    <HierarchyStructure hierarchy={hierarchySettings} hierarchyId={id} />
                                </Route>
                                <Redirect exact from="/location/hierarchies/:id(\d+)" to="/location/hierarchies/:id(\d+)/structure" />
                                <Route path="/location/hierarchies/:id(\d+)/settings">
                                    <HierarchySettings hierarchy={hierarchySettings} hierarchyId={id} onSettingSave={onSettingSave} />
                                </Route>
                                <Route path="/location/hierarchies/:id(\d+)/changes">
                                    <HierarchyChangelog hierarchyId={id}></HierarchyChangelog>
                                </Route>
                            </Switch>

                        </div>

                            
                    )}
                />
            )}
            no={() => (
                <PageRestricted />
            )}
        />

    );
}
import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { Tooltip } from 'components/tooltip';


export function StringToEllipsesString(props) {
    if (!props.children) {
        return null;
    }

    //Ensure this is a string
    if (typeof props.children !== 'string') {
        return props.children;
    }

    return (
        props.showTooltip && props.children.length > props.length ? <Tooltip content={props.children}>{strToEllipsesLength(props.children, props.length)}</Tooltip> : strToEllipsesLength(props.children, props.length)
    );
}

function strToEllipsesLength(str, ellipseLength) {
    if (str.length > ellipseLength) {
        return <Fragment>{str.substring(0, ellipseLength)}&hellip;</Fragment>;
    }
    return str;
}

StringToEllipsesString.defaultProps = {
    length: 3,
    showTooltip: false
};

StringToEllipsesString.propTypes = {
    length: PropTypes.number,
    showTooltip: PropTypes.bool
};
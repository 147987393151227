const LoggedInUserReducer = (state = {}, action) => {
    switch(action.type) {
        case "SET_USER_ACTIONS":
            return {
                ...state,
                actions: action.payload
            };
        case "SET_DISPLAY_NAME":
            return {
                ...state,
                displayName: action.payload
            };
        case "SET_USER_MEMBER_NUMBER":
            return {
                ...state,
                memberNumber: action.payload
            }
        case "SET_USER_MEMBER_NAME":
            return {
                ...state,
                memberName: action.payload
            }
        case "SET_USER_ACCOUNT_ID":
            return {
                ...state,
                accountId: action.payload
            }
        case "SET_USER_EMAIL":
            return {
                ...state,
                email: action.payload
            }
        case "SET_USER_ID":
            return {
                ...state,
                userId: action.payload
            }
        case "SET_USER_PREVIOUS_LOGIN_DATE":
            return {
                ...state,
                previousLoginDate: action.payload
            }
        default:
            return state;
    }
}

export default LoggedInUserReducer;
import React, { Fragment} from 'react';

import { Route, Switch, Redirect, useParams } from 'react-router-dom'

import { PolicyDetails } from 'pages/policy/edit/policy-details';
import { UserAccess } from 'components/user-access';

import { VerticalLinkGroup, Breadcrumbs } from 'components/navigation';
import { PageRestricted } from 'pages/errors/page-restricted';
import { PolicyChangelog } from './edit/changelog';

export function Policy(props) {
    const { id } = useParams();
    let routes = [
        { location: `/policy/${id}/policy-details`, title: "Policy Details" },
        { location: `/policy/${id}/declaration-details`, title: "Declaration Details" },
        { location: `/policy/${id}/changes`, title: "History" }
    ];

    return (
        <UserAccess perform={["EmployeeDeclaration:Manage"]}
                yes={() => (
                    <Fragment>
                        <div className="row">

                            <Breadcrumbs items={props.breadcrumbs} />

                            <h1>Policy Details</h1>
                            <div className="intro-text">
                                <p>Here you can configure your policy details.</p>
                            </div>
                        </div>

                        <div className="layout-cols">
                            <div className="col side-col">
                                <div className="row">
                                    <VerticalLinkGroup links={routes}></VerticalLinkGroup>
                                </div>
                            </div>
                            <div className="col content-col">
                                <Switch>
                                    <Route path="/policy/:id(\d+)/policy-details">
                                        <PolicyDetails policyId={id} show='Policy'></PolicyDetails>
                                    </Route>
                                    <Route path="/policy/:id(\d+)/declaration-details">
                                        <PolicyDetails policyId={id} show='Declarations'></PolicyDetails>
                                    </Route>
                                    
                                    <Redirect exact from="/policy/:id(\d+)" to="/policy/:id(\d+)/policy-details" />
                                    <Route path="/policy/:id(\d+)/changes">
                                        <PolicyChangelog policyId={id}></PolicyChangelog>
                                    </Route>
                                </Switch>
                            </div>


                        </div>
                    </Fragment>
            )}
            no={() => (
                <PageRestricted />
            )}
            />
      
    );

}
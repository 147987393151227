import React, { useState, useEffect, Fragment } from 'react';
import { Tooltip } from "components/tooltip";
import { Table } from 'components/listing';
import { Icon } from '@blueprintjs/core';
import { FormHelperText, FormTimePicker, FormSwitch } from 'components/form-fields';
import { ShowHide } from "components/layout";
import { Tabs } from 'components/navigation';
import { HelperFunctions } from "helpers";
import moment from "moment";
import PropTypes from 'prop-types';
import { BusinessAreaOperatingHoursTable } from 'pages/locations/shared';

export function BusinessAreaOperatingHoursSettings(props) {

    const timeFormat = "YYYY-MM-DD HH:mm:ss";
    const zeroTime = '2020-01-01 00:00:00'
    const [operatingHours, setOperatingHours] = useState([]);

    const [loading, setLoading] = useState(true);

    const [isValid, setIsValid] = useState(true);
    const [businessAreaTabs, setBusinessAreaTabs] = useState([]);
    const [selectedTab, setSelectedTab] = useState(0);
    const dayCount = 7;
    const sunday = 6;

    useEffect(() => {
        setLoading(props.loading);
    }, [props.loading]);

    useEffect(() => {
        setBusinessAreaTabs(props.businessAreaAdditionalTabs);
    }, [props.businessAreaAdditionalTabs]);

    useEffect(() => {
        props.onValidationUpdate(isValid);
    }, [isValid]);

    function isNotSet(date) {
        return date === undefined || date === null || date === zeroTime;
    }

    function updateValidationRules(ohs) {

        ohs.forEach(function (operatingHour) {
            operatingHour.errorMessage = '';
            operatingHour.valid = true;
            var startDate = moment(operatingHour.startDate, timeFormat);
            var endDate = moment(operatingHour.endDate, timeFormat);
            var startDate1 = moment(operatingHour.startDate1, timeFormat);
            var endDate1 = moment(operatingHour.endDate1, timeFormat);

            if (!isNotSet(operatingHour.startDate) && startDate >= endDate) {
                operatingHour.errorMessage = 'Closed must be after open for the first set of visiting hours';
                operatingHour.valid = false;
            }

            if (operatingHour.valid && !isNotSet(operatingHour.startDate1) && startDate1 >= endDate1) {
                operatingHour.errorMessage = 'Closed must be after open for the second set of visiting hours';
                operatingHour.valid = false;
            }

            if (operatingHour.valid && !isNotSet(operatingHour.startDate1) && startDate1 <= startDate) {
                operatingHour.errorMessage = 'The second set of visiting hours must be after the first';
                operatingHour.valid = false;
            }

            if (operatingHour.valid && !isNotSet(operatingHour.startDate1) && endDate >= startDate1) {
                operatingHour.errorMessage = 'The visiting hours must not overlap';
                operatingHour.valid = false;
            }
        });

        var allValid = true;
        for (var i = 0; i < businessAreaTabs.length; i++) {
            for (var day = 0; day < dayCount; day++) {
                if (!businessAreaTabs[i].operatingHours[day].valid) {
                    allValid = false;
                }
            }
        }
        setIsValid(allValid);
    }

    //When a tab is clicked, select that tab and then set the tabs hierarchy to be the current one
    function onTabClick(index) {

        setSelectedTab(index);
        var clonedTabs = [
            ...businessAreaTabs
        ];

        clonedTabs = clonedTabs.map(function (t, i) {
            return {
                ...t,
                selected: index === i
            }
        });
        setBusinessAreaTabs(clonedTabs);

        setOperatingHours(clonedTabs[index].operatingHours);
    }

    //When tabs are set, select the first one in the list
    useEffect(function () {

        if (businessAreaTabs.length === 0 || loading) {
            return;
        }

        onTabClick(0);

    }, [businessAreaTabs.length, loading])

    function clearAllTimes(openingHour) {
        openingHour.startDate = zeroTime;
        openingHour.endDate = zeroTime;
        clearRange2Times(openingHour);
    }

    function clearRange2Times(openingHour) {
        openingHour.startDate1 = zeroTime;
        openingHour.endDate1 = zeroTime;
    }


    function onOpenAllDay(dayOfWeek, item) {
        var clonedOeratingHours = [
            ...operatingHours
        ];

        var operatingHour = clonedOeratingHours.find(e => e.dayOfWeek === dayOfWeek);
        operatingHour.openAllDay = item.currentTarget.checked;
        clearAllTimes(operatingHour);
        updateValidationRules(clonedOeratingHours);
        setOperatingHours(clonedOeratingHours);
        updateBusinessAreaAdditionalDetails(clonedOeratingHours);
    }

    function onClosedAllDay(dayOfWeek, item) {

        var clonedOeratingHours = [
            ...operatingHours
        ];

        var operatingHour = clonedOeratingHours.find(e => e.dayOfWeek === dayOfWeek);
        operatingHour.closedAllDay = item.currentTarget.checked;
        clearAllTimes(operatingHour);
        updateValidationRules(clonedOeratingHours);
        setOperatingHours(clonedOeratingHours);
        updateBusinessAreaAdditionalDetails(clonedOeratingHours);
    }

    function onStartTimeChange(dayOfWeek, rangeNo, item) {
        var clonedOeratingHours = [
            ...operatingHours
        ];

        var operatingHour = clonedOeratingHours.find(e => e.dayOfWeek === dayOfWeek);
        if (rangeNo === 0) {
            operatingHour.startDate = item ? moment(item).format(timeFormat) : null;
        }
        else {
            operatingHour.startDate1 = item ? moment(item).format(timeFormat) : null;
        }
        operatingHour.range2Disabled = setRange2Disabled(operatingHour);
        if (operatingHour.range2Disabled) {
            clearRange2Times(operatingHour);
        }
        updateValidationRules(clonedOeratingHours);
        setOperatingHours(clonedOeratingHours);
        updateBusinessAreaAdditionalDetails(clonedOeratingHours);

    }

    function updateBusinessAreaAdditionalDetails(ohs) {
        var clonedBusinessAreaAdditionalDetails = HelperFunctions.deepClone(props.businessAreaAdditionalDetails);
        clonedBusinessAreaAdditionalDetails[selectedTab].operatingHours = ohs;
        props.onOperatingHoursSettingsUpdate(clonedBusinessAreaAdditionalDetails);
    }

    function onEndTimeChange(dayOfWeek, rangeNo, item) {
        var clonedOeratingHours = [
            ...operatingHours
        ];

        var operatingHour = clonedOeratingHours.find(e => e.dayOfWeek === dayOfWeek);
        if (rangeNo === 0) {
            operatingHour.endDate = item ? moment(item).format(timeFormat) : null;
        }
        else {
            operatingHour.endDate1 = item ? moment(item).format(timeFormat) : null;
        }
        operatingHour.range2Disabled = setRange2Disabled(operatingHour);
        if (operatingHour.range2Disabled) {
            clearRange2Times(operatingHour);
        }
        updateValidationRules(clonedOeratingHours);
        setOperatingHours(clonedOeratingHours);
        updateBusinessAreaAdditionalDetails(clonedOeratingHours);
    }

    function onCopySettings(dayOfWeek) {
        var clonedOeratingHours = [
            ...operatingHours
        ];

        var destDayOfWeek = dayOfWeek === sunday ? 0 : dayOfWeek + 1;
        var source = clonedOeratingHours.find(e => e.dayOfWeek === dayOfWeek);
        var destination = clonedOeratingHours.find(e => e.dayOfWeek === destDayOfWeek);

        destination.startDate = source.startDate;
        destination.endDate = source.endDate;
        destination.startDate1 = source.startDate1;
        destination.endDate1 = source.endDate1;
        destination.openAllDay = source.openAllDay;
        destination.closedAllDay = source.closedAllDay;
        destination.range2Disabled = source.range2Disabled;

        updateValidationRules(clonedOeratingHours);
        setOperatingHours(clonedOeratingHours);
        updateBusinessAreaAdditionalDetails(clonedOeratingHours);
    }

    function setRange2Disabled(openingHour) {
        return isNotSet(openingHour.startDate) && isNotSet(openingHour.endDate);
    }

    return (
        <Fragment>
            <div className="spacer-bottom">
                <Tabs tabs={businessAreaTabs} onClick={onTabClick} loading={loading} />
            </div>

            <div className='spacer-bottom'>
                <BusinessAreaOperatingHoursTable
                    loading={false}
                    onClosedAllDay={onClosedAllDay}
                    onEndTimeChange={onEndTimeChange}
                    onOpenAllDay={onOpenAllDay}
                    onStartTimeChange={onStartTimeChange}
                    onCopySettings={onCopySettings}
                    operatingHours={operatingHours}
                    saving={props.saving}
                />
                <ShowHide
                    evaluator={!isValid}
                    show={(
                        <div className="form-field">
                            <FormHelperText loading={props.loading} danger={true}>There is an error in the visiting hours. This may be on a different tab.</FormHelperText>
                        </div>
                    )}
                />


            </div>
        </Fragment >
    );
}
BusinessAreaOperatingHoursSettings.defaultProps = {
    saving: false
};

BusinessAreaOperatingHoursSettings.propTypes = {
    businessAreaAdditionalDetails: PropTypes.array.isRequired,
    businessAreaAdditionalTabs: PropTypes.array.isRequired,
    saving: PropTypes.bool,
    loading: PropTypes.bool.isRequired
};
import React, { useState, useEffect } from 'react';
import axios from 'axios';

import { Intent, Icon } from '@blueprintjs/core';
import { Breadcrumbs } from 'components/navigation';
import { NotificationToaster, AlertUnsavedData, NotificationInline } from "components/notifications";
import { LinkButton, Button } from "components/buttons";
import { ShowHide } from "components/layout";
import { FormSelect } from "components/form-fields";
import { Wizard } from "components/wizard";
import { useDirtyData } from 'hooks/useDirtyData';
import { SelfReportingService, LocationService } from "services";
import { UserAccess } from 'components/user-access';
import { PageRestricted } from 'pages/errors/page-restricted';
import { SelfReportingReportSettings } from 'pages/internal-admin/self-service-reports/shared';

export function SelfReportingAdminCreate(props) {

    const reportModel = {
        "url": "",
        "name": "",
        "description": "",
        "restrictDateRange": false,
        "restrictNumberOfMonths": 12,
        "internal": true,
        "locationBusinessAreaId": null,
        "reportGroups": [],
        "reportTypes": [],
        "reportParameters": []
    };

    const [newReports, setNewReports] = useState([]);

    const [originalSettings, setOriginalSettings] = useState(reportModel);
    const [loading, setLoading] = useState(true);
    const [saving, setSaving] = useState(false);
    const [reportCreated, setReportCreated] = useState(false);
    const [report, setReport] = useState(reportModel);
    const [wizardStep, setWizardStep] = useState(0);
    const [wizardValid, setWizardValid] = useState(false);
    const isUserDirty = useDirtyData(originalSettings, report);
    const [reportGroups, setReportGroups] = useState([]);
    const [reportTypes, setReportTypes] = useState([]);
    const [reportParameterTypes, setReportParameterTypes] = useState([]);
    const [locationBusinessAreas, setLocationBusinessAreas] = useState([]);
    const [reportValid, setReportValid] = useState(false);
    const [noNewReports, setNoNewReports] = useState(false);

    const requiredAction = "Internal";

    useEffect(initialLoad, []);
    function initialLoad() {

        axios.all([
            SelfReportingService.getUnallocatedReports(),
            SelfReportingService.getReportGroups(),
            SelfReportingService.getReportTypes(),
            SelfReportingService.getReportParameterTypes(),
            LocationService.getLocationBusinessAreas()
        ]).then(axios.spread(function (unallocatedResponse, reportGroupsResponse, reportTypesResponse, reportParameterTypeResponse, businessAreasResponse) {

            var mappedNewReports = unallocatedResponse.map(function (r) {
                return { "id": r, "name": r }
            });

            var mappedBusinessAreas = businessAreasResponse.map(function (ba) {
                return { "id": ba.locationBusinessAreaId, "name": ba.businessArea }
            });

            setNoNewReports(mappedNewReports.length === 0);
            setNewReports(mappedNewReports);
            setReportGroups(reportGroupsResponse);
            setReportTypes(reportTypesResponse);
            setReportParameterTypes(reportParameterTypeResponse);
            setLocationBusinessAreas(mappedBusinessAreas);
            setLoading(false);
        }));

    }

    function resetComponent() {
        setReport(reportModel);
        setOriginalSettings(reportModel);
        initialLoad();
        setSaving(false);
        setReportCreated(false);
        setWizardStep(0);
    }
    
    function onWizardChange(step) {

        if (step === 1) {
            setLoading(true);
            SelfReportingService.getReportParameters(report.url).then(function (parameterResponse) {
                setLoading(false);
                setReport({
                    ...report,
                    reportParameters: parameterResponse
                });
            });
        }

        setWizardStep(step);
    }

    useEffect(setIfWizardValid, [wizardStep, report, reportValid]);

    function setIfWizardValid() {
        var valid = (wizardStep === 0 && report.url !== '') || (wizardStep === 1 && reportValid);

        setWizardValid(valid);
    }

    function onValidationChange(valid) {
        setReportValid(valid);
    }

    function saveReport() {
        setSaving(true);
        SelfReportingService.createReport(report).then(
            () => {

                setSaving(false);
                setReportCreated(true);
                setOriginalSettings(report);
            },
            (error) => {
                setSaving(false);
                NotificationToaster.show(Intent.DANGER, error);
            }
        );
    }

    function onReportSelect(item) {

        var updatedReport = {
            ...report,
            url: item.id
        };

        onReportUpdate(updatedReport);
    }

    function onReportUpdate(updatedReport) {
        setReport(updatedReport);
    }

    

    return (

        <UserAccess perform={requiredAction}
            yes={() => (

                <div className="row">
                    <Breadcrumbs items={props.breadcrumbs} />
                    <ShowHide
                        evaluator={!reportCreated}
                        show={(
                            <div>

                                <ShowHide
                                    evaluator={noNewReports}
                                    show={(

                                        <div>
                                            <h1 id="header-new-user">Which report would you like to add?</h1>
                                            <NotificationInline
                                                show
                                                text="There are no new reports on the server, all reports have been allocated."
                                                intent="danger"
                                                allowClose={false}
                                            />
                                            <LinkButton intent="primary" text="Back to Report management" id="btn-no-reports-back" href="/internal-admin/self-service-reports" />
                                        </div>
                                    )}
                                    hide={(
                                        <Wizard onStepChange={onWizardChange} onFinish={saveReport} canProceed={wizardValid} disabled={saving || loading}>

                                            <div>
                                                <h1 id="header-new-user">Which report would you like to add?</h1>
                                                <FormSelect
                                                    disabled={saving}
                                                    items={newReports}
                                                    onItemSelect={onReportSelect}
                                                    placeholder="Select report"
                                                    selectedValue={report.url}
                                                    loading={loading}
                                                    id="input-reference-period"
                                                    dynamicWidth
                                                ></FormSelect>
                                            </div>

                                            <div>
                                                <h1 id="header-new-user">Report Settings</h1>
                                                <SelfReportingReportSettings report={report} onSettingsUpdate={onReportUpdate} reportGroups={reportGroups} reportTypes={reportTypes} reportParameterTypes={reportParameterTypes} businessAreas={locationBusinessAreas} saving={saving} loading={loading} onValidationChange={onValidationChange} />
                                            </div>

                                        </Wizard>
                                    )}
                                />
                                
                                <AlertUnsavedData
                                    isDirty={isUserDirty}>
                                </AlertUnsavedData>

                            </div>
                        )}
                        hide={(
                            <div>
                                <div className="spacer-bottom" id="create-success-icon">
                                    <Icon icon="tick-circle" iconSize={40} intent={Intent.SUCCESS} />
                                </div>
                                <h2 id="create-success-name">Report has been created successfully</h2>
                                <div className="spacer-bottom">
                                    <p>The report will now be available in Vision under the report groups you selected.</p>
                                </div>
                                <div className="button-row" id="create-success-buttons">
                                    <LinkButton intent="primary" text="Report management" id="listing-user" href="/internal-admin/self-service-reports" />
                                    <Button intent="primary" text="Add new report" id="new-report" onClick={resetComponent} />
                                </div>
                            </div>
                        )}
                    ></ShowHide>

                </div>

            )}
            no={() => (
                <PageRestricted />
            )}
        />
    );
}
import { Breadcrumbs, VerticalLinkGroup } from "components/navigation";
import { UserAccess, UserHasAccess } from "components/user-access";
import { PageRestricted } from "pages/errors/page-restricted";
import React, { Fragment, useEffect, useState } from "react";
import { Redirect, Route, Switch, useParams } from "react-router-dom";
import { DefectReporting } from "pages/walkaround-check/shared/DefectReporting";
import { AssetService } from "services/AssetService";
import { StatusBlock } from "components/status";
import classNames from "classnames";
import { MotTests } from "pages/asset/mots";
import { SafetyFeatures } from "pages/asset/safety-features";

export function Asset(props) {
  const { id } = useParams();
  const requiredActions = ["Fleet:View", "FleetPCN:View", "FleetVIS:View"];
  const canAccessPage = UserHasAccess(requiredActions);
  const [asset, setAsset] = useState(null);
  const [loadingData, setLoadingData] = useState(true);

  const allRoutes = [
    {
      location: `${window.env.VISION_URL}/#/Fleet/RedirectToProfile?assetId=${id}&tab=detailsTab`,
      title: "Details", 
      externalLink: true, 
          requiredAction: requiredActions
    },
    {
      location: `${window.env.VISION_URL}/#/Fleet/RedirectToProfile?assetId=${id}&tab=inspectionsTab`,
      title: "Inspections", 
      externalLink: true, 
      requiredAction: ['PlannedMaintenance:View', 'AuditsInspections:View'] 
      },
      {
          location: `/asset/${id}/mot-tests`,
          title: "MOT Tests",
          requiredAction: requiredActions,
      },
    {
      location: `${window.env.VISION_URL}/#/Fleet/RedirectToProfile?assetId=${id}&tab=tab-history`,
      title: "History", 
      externalLink: true, 
        requiredAction: requiredActions 
    },
    {
      location: `${window.env.VISION_URL}/#/Fleet/RedirectToProfile?assetId=${id}&tab=pcnTab`,
      title: "PCN", 
      externalLink: true, 
      requiredAction: "FleetPCN:View" 
    },
    {
      location: `/asset/${id}/walkaround-checks`,
      title: "Walkaround Checks",
      requiredAction: "WalkaroundCheck:View",
    },
    {
      location: `/asset/${id}/safety-features`,
      title: "Safety Features",
      requiredAction: requiredActions,
    },
  ];

  const routesForUser = allRoutes.filter((x) =>
    x.requiredAction ? UserHasAccess(x.requiredAction) : true
  );

  useEffect(() => {
    if (!canAccessPage) {
      return;
    }
    setLoadingData(true);

    AssetService.getAsset(id).then(function (response) {
      setLoadingData(false);
      setAsset(response);
    }, () => {
      setLoadingData(false);
    });

  }, [id]);

  return (
    <UserAccess
      perform={requiredActions}
      yes={() => (
        <Fragment>
          <div className="row">
            <Breadcrumbs items={props.breadcrumbs} />
          
            <div className={classNames({ "bp3-skeleton": loadingData }, 'inline-items', 'space-large', 'spacer-bottom')}>
            <h1>{asset?.registration}</h1>
            <StatusBlock status={asset?.assetLocation?.assetStatus?.name} bold={false}>
                {asset?.assetLocation?.assetStatus?.name}
            </StatusBlock>

            </div>
          </div>

          <div className="layout-cols">
            <div className="col side-col">
              <div className="row">
                <VerticalLinkGroup links={routesForUser} />
              </div>
            </div>
            <div className="col content-col">
              <Switch>
                <Redirect
                    exact
                    from="/asset/:id(\d+)"
                    to="/asset/:id(\d+)/mot-tests"
                />
                <Route path="/asset/:id(\d+)/walkaround-checks">
                    <DefectReporting
                        id={id}
                        route={"asset"}
                        sortName={"employeeFullName"}
                        headerName={"Completed by"}
                    />
                </Route>
                <Route path="/asset/:id(\d+)/mot-tests">
                     <MotTests
                        asset={asset}
                        assetId={id}
                        key={id}
                    />
                </Route>
                <Route path="/asset/:id(\d+)/safety-features">
                    <SafetyFeatures assetId={id} key={id} />
                </Route>
              </Switch>
            </div>
          </div>
        </Fragment>
      )}
      no={() => <PageRestricted />}
    />
  );
}

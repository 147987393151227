import React, { useState, useRef, useEffect } from 'react';
import { Intent } from '@blueprintjs/core';

import { UserAccess } from 'components/user-access';
import { PageRestricted } from 'pages/errors/page-restricted';
import { HierarchyService } from "services/HierarchyService";
import { FormTextInput } from "components/form-fields";
import { Breadcrumbs } from 'components/navigation';
import { ListingTable } from "components/listing";
import { LinkButton, Button } from 'components/buttons';
import { AlertConfirm, NotificationToaster } from "components/notifications";
import { useDebounce } from 'hooks/useDebounce';
import { DateToLocal } from 'components/dates';
import { Tooltip } from 'components/tooltip';
import { ShowHide } from "components/layout";
import { Link } from "react-router-dom";

import { UserPermissionsSelection } from "pages/locations/hierarchies/edit/shared";

export function HierarchiesListing(props) {
    const defaultRecordSize = 25;
    const debounceTimeout = 750;

    const [selectedHierarchyId, setselectedHierarchyId] = useState(0);
    const [selectedHierarchy, setselectedHierarchy] = useState(null);
    const [totalRecords, setTotalRecords] = useState(0);
    const [tableRows, setTableRows] = useState([]);
    const [pageNumber, setPageNumber] = useState(1);
    const [pageSize, setPageSize] = useState(defaultRecordSize);
    const [searchTerm, setSearchTerm] = useState("");
    const [loadingData, setLoadingData] = useState(true);
    const [noDataMessage, setNoDataMessage] = useState("You have no locations hierarchies set up, please click 'Add new hierarchy' to get started.");
    const [tableHeaders] = useState(["Hierarchy Name", "Active On", "Created By", "Created", "Last Updated", "Actions"]);
    const sortableHeaders = ["Hierarchy Name", "Created By", "Created", "Last Updated"];
    const [tableSortBy, setTableSortBy] = useState("Hierarchy Name");
    const [tableSortDir, setTableSortDir] = useState("A");
    const [showDeleteConfirm, setShowDeleteConfirm] = useState(false);
    const [showUserPermissionsNotification, setShowUserPermissionsNotification] = useState(false);
    const debouncedSearchTerm = useDebounce(searchTerm, debounceTimeout);
    const inputRef = useRef(null);
    const requiredAction = "Locations:HierarchiesManage";

    const [convertUserPermissions, setConvertUserPermissions] = useState(null);
    
    function onConvertUserPermissionsChange(value) {
        setConvertUserPermissions(value);
    }

    useEffect(() => {
        setPageNumber(1);
        setNoDataMessage("No results found, please try a different search term.");
        setLoadingData(true);
    }, [debouncedSearchTerm]);

    useEffect(() => {

        if (selectedHierarchy != null) {
            setShowUserPermissionsNotification(selectedHierarchy.hierarchyFeatures.some(function (hf) {
                return hf.featureType === "PERMISSIONS"
            }));
        } else {
            setShowUserPermissionsNotification(false);
        }

    }, [selectedHierarchy])

    useEffect(() => {
        if (loadingData) {
            HierarchyService.getHierarchyList(pageSize, pageNumber, debouncedSearchTerm, tableSortBy, tableSortDir).then(r => {
                let results = r.data;
                let data = [];

                if (results != null) {
                    data = results.map((d) => {

                        function generateFeatureListName(features) {
                            switch (features.length) {
                                case 1:
                                    return features[0].featureName;
                                case 0:
                                    return "-";
                                default:
                                    return (<Tooltip position="right" content={(<ul>{features.map((t) => <li key={t.featureKey}>{t.featureName}</li>)}</ul>)}>Multiple Features</Tooltip>);
                            }
                        }

                        return [(
                            <Link to={`/location/hierarchies/${d.hierarchyId}`}>{d.hierarchyName}</Link>),
                            (generateFeatureListName(d.hierarchyFeatures)),
                            d.createdBy,
                            <DateToLocal>{d.createdDate}</DateToLocal>,
                            <DateToLocal>{d.modifiedDate}</DateToLocal>,
                            <Button onClick={() => {
                                    deleteHierarchy(d.hierarchyId);
                                    setselectedHierarchy(d);
                            }} icon="trash" iconOnly={true} text="Delete Hierarchy" large={false} minimal={true}></Button>
                        ];
                    });
                }

                setTableRows(data);
                setTotalRecords(r.totalCount);
                setLoadingData(false);

                setTimeout(function () {
                    if (inputRef.current != null) {
                        inputRef.current.focus();
                    }
                });

            });
        }
    }, [loadingData, pageSize, pageNumber, tableSortBy, tableSortDir, debouncedSearchTerm]);

    const onPagingChange = (newPageNumber, newPageSize) => {
        setPageNumber(newPageNumber);
        setPageSize(newPageSize);
        setLoadingData(true);
    };

    const onSearchChange = (item) => {
        setSearchTerm(item.target.value);
    };

    const onTableSort = (header, direction) => {
        setTableSortBy(header);
        setTableSortDir(direction);
        setLoadingData(true);
    };

    function deleteHierarchy(hierarchyId) {
        setselectedHierarchyId(hierarchyId);
        setShowDeleteConfirm(true);
    }

    function onDeleteConfirm() {
        setShowDeleteConfirm(false);

        HierarchyService.deleteHierarchy(selectedHierarchyId, convertUserPermissions).then(() => {
            NotificationToaster.show(Intent.SUCCESS, "Hierarchy has been successfully deleted.");
            setLoadingData(true);
            setselectedHierarchyId(0);
            setConvertUserPermissions(null);
        }, function () {
            NotificationToaster.show(Intent.DANGER, "Unable to delete the hierarchy, please try again.");
            setselectedHierarchyId(0);
            setConvertUserPermissions(null);
        });
    }

    function onDeleteCancel() {
        setselectedHierarchyId(0);
        setShowDeleteConfirm(false);
        setConvertUserPermissions(null);
    }

    return (
        <UserAccess perform={requiredAction}
            yes={() => (
                <div className="row">
                    <Breadcrumbs items={props.breadcrumbs} />

                    <h1>Location Hierarchies</h1>
                    <div className="intro-text">
                        <p>From here you can edit your location hierarchies.</p>
                    </div>
                    
                    <div className="button-row spacer-bottom">
                        <LinkButton intent="primary" text="Add new Hierarchy" href="/location/hierarchies/create" id="location-hierarchy-wizard" />
                    </div>

                    <ShowHide
                        evaluator={tableRows.length === 0 && !loadingData && debouncedSearchTerm === ""}
                        hide={(
                            <div className="pull-left listing-table-filter-fields single-item">
                                <FormTextInput inputRef={inputRef} placeholder="Location Hierarchy Search" onChange={onSearchChange} value={searchTerm} large disabled={loadingData} icon="search" id="location-search-field" />
                            </div>
                        )}
                    >
                    </ShowHide>

                    <ListingTable
                        id="listing-table-location-hierarchies"
                        headers={tableHeaders}
                        data={tableRows}
                        totalRecordCount={totalRecords}
                        onPagingChange={onPagingChange}
                        loadingData={loadingData}
                        noDataMessage={noDataMessage}
                        sortable
                        sortableHeaders={sortableHeaders}
                        sortedBy={tableSortBy}
                        sortedDir={tableSortDir}
                        onSort={onTableSort}
                    />

                    <AlertConfirm
                        title="Please confirm"
                        isOpen={showDeleteConfirm}
                        onConfirm={onDeleteConfirm}
                        onCancel={onDeleteCancel}
                        disabled={convertUserPermissions == null && showUserPermissionsNotification}
                    >
                        <p>Are you sure you want to delete this hierarchy? If features are assigned to this hierarchy, those features will return to use all locations.</p>
                        <ShowHide
                            evaluator={showUserPermissionsNotification}
                            show={(
                                <UserPermissionsSelection alertText="You are about to delete a hierarchy that controls users permissions. What would you like to happen to users who inherit locations through a hierarchy group?" onConvertUserPermissionsChange={onConvertUserPermissionsChange} value={convertUserPermissions == null ? "" : convertUserPermissions.toString()} />
                            )}
                        />
                    </AlertConfirm>
                </div>
            )}
            no={() => (
                <PageRestricted />
            )}
        />
    )
}
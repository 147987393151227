import React, { Fragment, useState, useEffect } from 'react';
import { Intent } from '@blueprintjs/core';
import { FormRadio, FormHelperText, FormSelect, FormSwitch, FormNumericInput, FormDayMonthSelect, FormDateInput } from "components/form-fields";
import { NotificationInline, NotificationToaster, AlertUnsavedData, AlertConfirm } from "components/notifications";
import { ButtonSave } from "components/buttons";
import { ShowHide } from "components/layout";
import { AccountService } from "services";
import { useValidation } from "hooks/useValidation";
import { useDirtyData } from "hooks/useDirtyData";
import moment from "moment";

var _ = require('underscore');

export function AccountTachoSettings(props) {
    const [isValid, errors, validate] = useValidation();
    const [workingTimeReferenceStartDateRequired, setWorkingTimeReferenceStartDateRequired] = useState(false);
    const [autoPurchaseDataMessage, setAutoPurchaseDataMessage] = useState("");
    const [loading, setLoading] = useState(true);
    const [saving, setSaving] = useState(false);
    const [originalValues, setOriginalValues] = useState({});
    const [tachoSettings, setTachoSettings] = useState({
        tachoAnalysisStartDate: null,
        includeWorkingTime: null,
        optOutNightWork: null,
        workingTimeReferencePeriod: null,
        workingTimeRollingReference: null,
        workingTimeReferenceFull: null,
        workingTimeReferenceStartDate: null,
        workingTimeVehicleOperation: null,
        calculateHolidayAllocation: null,
        statutoryHolidayDays: null,
        calculateHolidayAllocationByDate: null,
        statutoryHolidayStartDay: null,
        statutoryHolidayStartMonth: null,
        vehicleComplianceDays: null,
        driverComplianceDays: null,
        govStandardRefPeriodHolidayDates: null,
        leadInAdjustment: null,
        autoPurchaseData: null,
        moveDataWithLocation: null,
        manualEntryDefault: null,
        analysePeriodOfAvailability: null,
        roamingEnabled: null,
        autoAway: null,
        autoAwayDelayFrequency: null,
        autoAwayDelayFrequencyType: null
    });
    const [showSaveConfirm, setShowSaveConfirm] = useState(false);
    const [currentReferencePeriodMessage, setCurrentReferencePeriodMessage] = useState(null);

    const isDataDirty = useDirtyData(originalValues, tachoSettings);

    const [defaultOptions, setDefaultOptions] = useState({
        calculateHolidayAllocationByDate: [{ value: false, label: "Use my reference period" }, { value: true, label: "Set start date of your companies 12 month holiday period" }],
        workingTimeVehicleOperation: [],
        workingTimeReferenceFull: [],
        autoPurchaseData: [],
        manualEntryDefault: []
    });

    const maxDriverComplianceDays = 28;
    const maxVehicleComplianceDays = 90;
    const maxStatutoryHolidayDays = 60;
    const minLeadInDriveAdjustMinutes = 0;
    const maxLeadInDriveAdjustMinutes = 20;
    const minAutoAwayPeriod = 1;
    const maxAutoAwayPeriod = 999;
    const autoAwayResendFrequencyDefault = 1;
    const autoAwayResendFrequencyTypeDefault = "month";

    const autoAwayResendFrequencyTypeOptionList = [
        { id: "day", name: "Day(s)" },
        { id: "week", name: "Week(s)" },
        { id: "month", name: "Month(s)" },
        { id: "year", name: "Year(s)" },
    ];

    const dateDisplayFormat = "DD/MM/YYYY";
    const weeksInRefPeriod = 52;

    useEffect(initialLoad, []);
    useEffect(validateModelIfDirty, [isDataDirty, tachoSettings]);

    function initialLoad() {
        function getTachoSettings() {
            AccountService.getTachoSettings().then(
                (settings) => {
                    //workingTimeRollingReference is a calculated field on the server side, I don't want to mess with that, but I need to use it here so we'll just set it manaully
                    settings.workingTimeRollingReference = settings.workingTimeReferenceFull != null && settings.workingTimeReferenceFull.toLowerCase().includes("rolling");
                    if (settings.workingTimeRollingReference) {
                        defaultOptions.calculateHolidayAllocationByDate[0].disabled = true;
                        settings.calculateHolidayAllocationByDate = true;
                    }
                    setOriginalValues(_.extend({}, settings));
                    setTachoSettings(settings);
                    setLoading(false);
                },
                (error) => {
                    NotificationToaster.show(Intent.DANGER, "Could not load settings. Please refresh the page.");
                }
            );
        }
        AccountService.getTachoOptions().then(
            (options) => {
                setDefaultOptions(options);

                setDefaultOptions({
                    ...defaultOptions,
                    workingTimeVehicleOperation: options.workingTimeVehicleOperation,
                    workingTimeReferenceFull: options.workingTimeReferenceFull,
                    autoPurchaseData: options.autoPurchaseData,
                    manualEntryDefault: options.manualEntryDefault,
                });
                getTachoSettings();
            },
            (error) => {
                NotificationToaster.show(Intent.DANGER, "Could not load options. Please refresh the page.");
            });
    }

    function saveTachoSettings() {
        if (!isValid) {
            NotificationToaster.show(Intent.WARNING, "There are errors on the page that need your attention");
            return;
        }

        if (originalValues.roamingEnabled && !tachoSettings.roamingEnabled) {
            setShowSaveConfirm(true);
            return;
        }

        setSaving(true);
        putTachoSettings();
    }

    function onSaveConfirm() {
        setShowSaveConfirm(false);
        setSaving(true);
        putTachoSettings();
    }

    function onSaveCancel() {
        setShowSaveConfirm(false);
    }

    function putTachoSettings() {
        AccountService.putTachoSettings(tachoSettings).then(
            () => {
                setOriginalValues(_.extend({}, tachoSettings));
                setSaving(false);
                NotificationToaster.show(Intent.SUCCESS, "Settings updated successfully.")
            },
            (error) => {
                setSaving(false);
                const badRequestCode = 400;
                if (error.response.status === badRequestCode) {
                    validateModel();
                    NotificationToaster.show(Intent.DANGER, "Settings not valid. Please check your settings and try again.");
                } else {
                    NotificationToaster.show(Intent.DANGER, "Could not save settings. Please try again.");
                }
            }
        );
    }

    function onTachoAnalysisStartDateChange(item) {
        setTachoSettings({
            ...tachoSettings,
            tachoAnalysisStartDate: item ? moment(item).format("YYYY-MM-DD") : null
        });
    }

    function onIncludeWorkingTimeChange(item) {
        setTachoSettings({
            ...tachoSettings,
            includeWorkingTime: item.currentTarget.value === "true"
        });
    }

    function onOptOutNightWorkChange(item) {
        setTachoSettings({
            ...tachoSettings,
            optOutNightWork: item.currentTarget.value === "true"
        });
    }

    function onWorkingTimeVehicleOperationChange(item) {
        setTachoSettings({
            ...tachoSettings,
            workingTimeVehicleOperation: item.currentTarget.value
        });
    }

    function onWorkingTimeReferenceFullChange(item) {
        //If this is rolling ref period then they have to use fixed period if they use holiday.
        var workingTimeRollingReference = item.id.toLowerCase().includes("rolling");
        var calculateHolidayAllocationByDate = tachoSettings.calculateHolidayAllocationByDate;

        if (workingTimeRollingReference) {
            defaultOptions.calculateHolidayAllocationByDate[0].disabled = true;
            calculateHolidayAllocationByDate = true;
        } else {
            defaultOptions.calculateHolidayAllocationByDate[0].disabled = false;
        }

        setTachoSettings({
            ...tachoSettings,
            workingTimeReferenceFull: item.id,
            workingTimeRollingReference: workingTimeRollingReference,
            calculateHolidayAllocationByDate: calculateHolidayAllocationByDate
        });
    }

    function getRefPeriodMessageFromWorkingTimeReferenceStartDate() {
        var startOfCurrent = moment(tachoSettings.workingTimeReferenceStartDate);
        var weeksDiff = moment().diff(moment(startOfCurrent), 'week');
        var periodDiff = Math.floor(weeksDiff / weeksInRefPeriod);
        if (startOfCurrent.isAfter(moment(), 'day')) {
            periodDiff = periodDiff - 1;
        }
        if (periodDiff !== 0) {
            //The date they picked is in the future
            startOfCurrent.add(periodDiff * weeksInRefPeriod, 'week');
        }

        return startOfCurrent.format(dateDisplayFormat) + " to " + startOfCurrent.add(weeksInRefPeriod, 'week').add(-1, 'd').format(dateDisplayFormat);
    }

    useEffect(() => {
        var refPeriodMessage = null;
        if (tachoSettings.workingTimeReferenceFull !== null && tachoSettings.calculateHolidayAllocationByDate !== null && tachoSettings.workingTimeRollingReference !== null &&
            !tachoSettings.calculateHolidayAllocationByDate && !tachoSettings.workingTimeRollingReference) {
            if (tachoSettings.workingTimeReferenceFull.toLowerCase().includes("fixed") && tachoSettings.workingTimeReferenceStartDate != null) {
                refPeriodMessage = getRefPeriodMessageFromWorkingTimeReferenceStartDate();
            }

            if (tachoSettings.workingTimeReferenceFull.toLowerCase().includes("government")) {
                //So we need to show the current Gov period if they are switching to this, or the workingTimeReferenceStartDate is not set as there are the only things what will set the start date.
                if (!originalValues.workingTimeReferenceFull?.toLowerCase().includes("government") ||
                    tachoSettings.workingTimeReferenceStartDate === null) {
                    if (tachoSettings.workingTimeReferenceFull.toLowerCase().includes("roi")) {
                        refPeriodMessage = moment(tachoSettings.govStandardRefPeriodHolidayDates.workingTimeRoiGovStandardRefPeriodStart).format(dateDisplayFormat) + " to " + moment(tachoSettings.govStandardRefPeriodHolidayDates.workingTimeRoiGovStandardRefPeriodEnd).format(dateDisplayFormat);
                    } else {
                        refPeriodMessage = moment(tachoSettings.govStandardRefPeriodHolidayDates.workingTimeGovStandardRefPeriodStart).format(dateDisplayFormat) + " to " + moment(tachoSettings.govStandardRefPeriodHolidayDates.workingTimeGovStandardRefPeriodEnd).format(dateDisplayFormat);
                    }
                } else {
                    refPeriodMessage = getRefPeriodMessageFromWorkingTimeReferenceStartDate();
                }
            }
        }
        setCurrentReferencePeriodMessage(refPeriodMessage);
    }, [tachoSettings.calculateHolidayAllocationByDate, tachoSettings.workingTimeRollingReference, tachoSettings.workingTimeReferenceFull, tachoSettings.workingTimeReferenceStartDate]);

    useEffect(() => {

        var item = defaultOptions.workingTimeReferenceFull != null && defaultOptions.workingTimeReferenceFull.find(element => element.id === tachoSettings.workingTimeReferenceFull);
        if (item) {
            setWorkingTimeReferenceStartDateRequired(item.requiresDate);
        }

    }, [tachoSettings.workingTimeReferenceFull, defaultOptions.workingTimeReferenceFull]);

    function onWorkingTimeReferenceStartDateChange(item) {
        if (!disabledDays(item)) {
            setTachoSettings({
                ...tachoSettings,
                workingTimeReferenceStartDate: item ? moment(item).format("YYYY-MM-DD") : null
            });
        }
    }

    function disabledDays(date) {
        if (date == null) {
            return false;
        }
        return date.getDay() !== 1;
    }

    const workingTimeReferenceStartDateModifiers = { disabledDays }

    function onCalculateHolidayAllocationChange(item) {
        setTachoSettings({
            ...tachoSettings,
            calculateHolidayAllocation: item.currentTarget.checked
        });
    }

    function onStatutoryHolidayDaysChange(item) {
        setTachoSettings({
            ...tachoSettings,
            statutoryHolidayDays: item === 0 ? null : item
        });
    }

    function onCalculateHolidayAllocationByDateChange(item) {
        setTachoSettings({
            ...tachoSettings,
            calculateHolidayAllocationByDate: item.currentTarget.value === "true"
        });
    }

    function onStatutoryHolidayStartDayChange(item) {
        setTachoSettings({
            ...tachoSettings,
            statutoryHolidayStartDay: item.id
        });
    }

    function onStatutoryHolidayStartMonthChange(item) {
        setTachoSettings({
            ...tachoSettings,
            statutoryHolidayStartMonth: item.id
        });
    }

    function onAutoPurchaseDataChange(item) {
        setTachoSettings({
            ...tachoSettings,
            autoPurchaseData: item.id
        });
        setAutoPurchaseDataMessage(item.infoText);
    }

    function onMoveDataWithLocationChange(item) {
        setTachoSettings({
            ...tachoSettings,
            moveDataWithLocation: item.currentTarget.value === "true"
        });
    }

    function onManualEntryDefaultChange(item) {
        setTachoSettings({
            ...tachoSettings,
            manualEntryDefault: item.id
        });
    }

    function onVehicleComplianceDaysChange(item) {
        setTachoSettings({
            ...tachoSettings,
            vehicleComplianceDays: item === 0 ? null : item
        });
    }

    function onDriverComplianceDaysChange(item) {
        setTachoSettings({
            ...tachoSettings,
            driverComplianceDays: item === 0 ? null : item
        });
    }

    function onLeadInDriveAdjustMinutesChange(item) {
        setTachoSettings({
            ...tachoSettings,
            leadInAdjustment: item
        });
    }

    function onAnalysePeriodOfAvailabilityChange(item) {
        setTachoSettings({
            ...tachoSettings,
            analysePeriodOfAvailability: item.currentTarget.value === "true"
        });
    }

    function onRoamingEnabledChange(item) {
        var roamingEnabled = item.currentTarget.value === "true";
        setTachoSettings((prevValue) => {
            return {
                ...prevValue,
                roamingEnabled: roamingEnabled
            }
        });
        onAutoAwayChange(item);
    }

    function onAutoAwayChange(item) {
        var autoAway = item.currentTarget.value === "true";
        if (autoAway) {
            setTachoSettings((prevValue) => {
                return {
                    ...prevValue,
                    autoAway: autoAway,
                    autoAwayDelayFrequency: originalValues.autoAwayResendFrequency == null ? autoAwayResendFrequencyDefault : originalValues.autoAwayResendFrequency,
                    autoAwayDelayFrequencyType: originalValues.autoAwayResendFrequencyType == null ? autoAwayResendFrequencyTypeDefault : originalValues.autoAwayResendFrequencyType
                }
            });
        } else {
            setTachoSettings((prevValue) => {
                return {
                    ...prevValue,
                    autoAway: autoAway,
                    autoAwayDelayFrequency: null,
                    autoAwayDelayFrequencyType: null
                }
            });
        }
    }

    function onAutoAwayResendFrequencyChange(item) {
        setTachoSettings((prevValue) => {
            return {
                ...prevValue,
                autoAwayDelayFrequency: item
            }
        });
    }

    function onAutoAwayResendFrequencyTypeChange(item) {
        setTachoSettings((prevValue) => {
            return {
                ...prevValue,
                autoAwayDelayFrequencyType: item.id
            }
        });
    }

    function validateModelIfDirty() {
        if (isDataDirty && !loading) {
            validateModel();
        }
    }

    function validateModel() {
        const rules = getRules();
        validate(rules, tachoSettings);
    }

    function getRules() {
        let rules = [
            { fieldName: "vehicleComplianceDays", required: true, minValue: 1, maxValue: maxVehicleComplianceDays },
            { fieldName: "driverComplianceDays", required: true, minValue: 1, maxValue: maxDriverComplianceDays },
            { fieldName: "leadInAdjustment", required: true, minValue: minLeadInDriveAdjustMinutes, maxValue: maxLeadInDriveAdjustMinutes },
            { fieldName: "autoPurchaseData", required: true },
            { fieldName: "manualEntryDefault", required: true }
        ];
        if (originalValues.tachoAnalysisStartDate != null) {
            rules.push({ fieldName: "tachoAnalysisStartDate", required: true });
        }
        if (tachoSettings.includeWorkingTime) {
            rules.push({ fieldName: "optOutNightWork", required: true });
            rules.push({ fieldName: "workingTimeReferenceFull", required: true });
            if (!tachoSettings.optOutNightWork) {
                rules.push({ fieldName: "workingTimeVehicleOperation", required: true });
            }
            if (workingTimeReferenceStartDateRequired) {
                rules.push({ fieldName: "workingTimeReferenceStartDate", required: true });
            }
        }
        if (tachoSettings.calculateHolidayAllocation) {
            rules.push({ fieldName: "statutoryHolidayDays", required: true, minValue: 1, maxValue: maxStatutoryHolidayDays });
            if (tachoSettings.calculateHolidayAllocationByDate) {
                rules.push({ fieldName: "statutoryHolidayStartDay", required: true });
                rules.push({ fieldName: "statutoryHolidayStartMonth", required: true });
            }
        }
        return rules;
    }

    return (
        <div className="row">
            <h2>Tacho Analysis Settings</h2>

            <FormHelperText loading={loading}>
                <p>By default, your data will be analysed against EU Drivers' Hours rules. You can apply a different rule set to individual drivers and vehicles (in the driver or vehicle profiles) if needed. </p>
            </FormHelperText>
            <FormDateInput
                disabled={saving}
                headingText="The date you wish Vision to analyse your tacho data from:"
                onChange={onTachoAnalysisStartDateChange}
                value={tachoSettings.tachoAnalysisStartDate}
                loading={loading}
                dangerHelperText={errors.tachoAnalysisStartDate}
                minDate={moment("2000-01-01").toDate()}
                id="input-tacho-start-date"
            ></FormDateInput>

            <FormRadio
                disabled={saving}
                onChange={onIncludeWorkingTimeChange}
                headingText="Analyse drivers against the Working Time Directive:"
                selectedValue={tachoSettings.includeWorkingTime}
                loading={loading}
                id="input-working-time-directive"
            ></FormRadio>

            <ShowHide
                evaluator={tachoSettings.includeWorkingTime === true}
                show={(<Fragment>
                    <FormRadio
                        disabled={saving}
                        onChange={onOptOutNightWorkChange}
                        headingText="Opt out of 10 hour night working limit:"
                        helperText={(<p>If your workforce has an agreement in place you can choose to opt out of the 10 hour night work limit and we will calculate your data to reflect this.</p>)}
                        selectedValue={tachoSettings.optOutNightWork}
                        dangerHelperText={errors.optOutNightWork}
                        loading={loading}
                        id="input-opt-out-night-work"
                    ></FormRadio>
                    <ShowHide
                        evaluator={tachoSettings.optOutNightWork === false}
                        show={(
                            <FormRadio
                                disabled={saving}
                                onChange={onWorkingTimeVehicleOperationChange}
                                headingText="Type of operation that best describes your organisation:"
                                selectedValue={tachoSettings.workingTimeVehicleOperation}
                                options={defaultOptions.workingTimeVehicleOperation}
                                dangerHelperText={errors.workingTimeVehicleOperation}
                                loading={loading}
                                id="input-organisation-type"
                            ></FormRadio>
                        )}
                    ></ShowHide>
                    <FormSelect
                        disabled={saving}
                        items={defaultOptions.workingTimeReferenceFull}
                        onItemSelect={onWorkingTimeReferenceFullChange}
                        placeholder="Select a reference period"
                        headingText="Use the following reference period:"
                        selectedValue={tachoSettings.workingTimeReferenceFull}
                        dangerHelperText={errors.workingTimeReferenceFull}
                        loading={loading}
                        id="input-reference-period"
                    ></FormSelect>
                    <ShowHide
                        evaluator={workingTimeReferenceStartDateRequired === true}
                        show={(
                            <FormDateInput
                                disabled={saving}
                                headingText="Date reference period commenced:"
                                onChange={onWorkingTimeReferenceStartDateChange}
                                value={tachoSettings.workingTimeReferenceStartDate}
                                loading={loading}
                                helperText={(<p>If you have previously used a different system to monitor your working time you can upload your working time data on the <a href="https://testvision.fta.co.uk/#/Import">Import page</a> using the RTD Interim Report. </p>)}
                                dangerHelperText={errors.workingTimeReferenceStartDate}
                                modifiers={workingTimeReferenceStartDateModifiers}
                                minDate={moment("2000-01-01").toDate()}
                                id="input-reference-start-date"
                            ></FormDateInput>
                        )}
                    ></ShowHide>
                </Fragment>)}
            ></ShowHide>

            <FormSwitch
                disabled={saving}
                label="Statutory holiday:"
                onChange={onCalculateHolidayAllocationChange}
                checked={tachoSettings.calculateHolidayAllocation}
                loading={loading}
                id="input-stat-holiday"
                helperText={(<Fragment><p> Vision can be asked to switch extra holiday in a period to statutory holiday, depending on the number of days of statutory holiday already applied. If this was set as 20 days and depending on which date period you have specified, any entries added as extra holiday will be changed to statutory holiday automatically by the system, until the driver has reached 20 days.</p>
                    <p>Changing these settings will not automatically recalculate data that already exists in the system. Data within a period can be recalculated upon upload of new driver data for that period.</p></Fragment>)}
            ></FormSwitch>

            <ShowHide
                evaluator={tachoSettings.calculateHolidayAllocation === true}
                show={(
                    <Fragment>
                        <FormNumericInput
                            disabled={saving}
                            min={1}
                            max={maxStatutoryHolidayDays}
                            selectedValue={tachoSettings.statutoryHolidayDays}
                            onValueChange={onStatutoryHolidayDaysChange}
                            headingText={"Number of days to be allocated as statutory holiday:"}
                            dangerHelperText={errors.statutoryHolidayDays}
                            loading={loading}
                            id="input-stat-number-days"
                        ></FormNumericInput>
                        <FormRadio
                        disabled={saving}
                        onChange={onCalculateHolidayAllocationByDateChange}
                        headingText="Statutory holiday application:"
                        selectedValue={tachoSettings.calculateHolidayAllocationByDate}
                        options={defaultOptions.calculateHolidayAllocationByDate}
                        dangerHelperText={errors.calculateHolidayAllocationByDate}
                        loading={loading}
                        id="input-stat-holiday-application"
                        ></FormRadio>
                        <ShowHide
                        evaluator={tachoSettings.calculateHolidayAllocationByDate === true}
                        show={(<FormDayMonthSelect
                            disabled={saving}
                            headingText={"Start Date:"}
                            selectedDayValue={tachoSettings.statutoryHolidayStartDay}
                            selectedMonthValue={tachoSettings.statutoryHolidayStartMonth}
                            onMonthItemSelect={onStatutoryHolidayStartMonthChange}
                            onDayItemSelect={onStatutoryHolidayStartDayChange}
                            dangerHelperText={errors.statutoryHolidayStartDay == null ? errors.statutoryHolidayStartMonth : errors.statutoryHolidayStartDay}
                            loading={loading}
                            id="input-stat-holiday-start-date"
                        ></FormDayMonthSelect>)}
                        ></ShowHide>
                        <ShowHide
                            evaluator={tachoSettings.workingTimeRollingReference}
                            show={(
                                <NotificationInline
                                    allowClose={false}
                                    show
                                    text="Rolling reference period selected so the 12 month holiday period must be used."
                                    intent="info"
                                />)}
                        ></ShowHide>
                        <ShowHide
                            evaluator={currentReferencePeriodMessage !== null && currentReferencePeriodMessage.length > 0}
                            show={(<NotificationInline
                                allowClose={false}
                                show
                                text={`The current statutory holiday calculation period will run from ${currentReferencePeriodMessage} and then continue in 52 week periods.`}
                                intent="info"
                            />)}
                        ></ShowHide> 
                    </Fragment>
                )}
            ></ShowHide>

            <FormNumericInput
                disabled={saving}
                min={1}
                max={maxVehicleComplianceDays}
                selectedValue={tachoSettings.vehicleComplianceDays}
                onValueChange={onVehicleComplianceDaysChange}
                headingText={"Vehicle file upload compliance days:"}
                helperText={(<p>These are the vehicle compliance days you wish assets to be measured against. This can not exceed 90 days.</p>)}
                dangerHelperText={errors.vehicleComplianceDays}
                loading={loading}
                id="input-vehicle-compliance-days"
            ></FormNumericInput>

            <FormNumericInput
                disabled={saving}
                min={1}
                max={maxDriverComplianceDays}
                selectedValue={tachoSettings.driverComplianceDays}
                onValueChange={onDriverComplianceDaysChange}
                headingText={"Driver card upload compliance days:"}
                helperText={(<p>These are the driver compliance days you wish your drivers to be measured against. This can not exceed 28 days.</p>)}
                dangerHelperText={errors.driverComplianceDays}
                loading={loading}
                id="input-driver-compliance-days"
            ></FormNumericInput>

            <FormNumericInput
                disabled={saving}
                min={minLeadInDriveAdjustMinutes}
                max={maxLeadInDriveAdjustMinutes}
                selectedValue={tachoSettings.leadInAdjustment}
                onValueChange={onLeadInDriveAdjustMinutesChange}
                headingText={"Lead-in Drive Adjust (minutes):"}
                helperText={(<p>The drive adjustment field is used in conjunction with the Lead in/ Lead out dashboard and Compliance reviews. The number of minutes added allows for the lead in time to be interrupted by that amount of drive, in order to move a vehicle to a safe place to carry out walk around checks or similar activities.</p>)}
                dangerHelperText={errors.leadInAdjustment}
                loading={loading}
                id="input-lead-in-drive-adjust"
            ></FormNumericInput>

            <NotificationInline
                show={tachoSettings.leadInAdjustment && originalValues.leadInAdjustment !== tachoSettings.leadInAdjustment}
                text="This will take up to 24 hours to recalculate in dashboard"
                intent="info"
            ></NotificationInline>

            <FormSelect
                disabled={saving}
                items={defaultOptions.autoPurchaseData}
                onItemSelect={onAutoPurchaseDataChange}
                placeholder="Select a purchase option"
                headingText="Autopurchase of agency data:"
                selectedValue={tachoSettings.autoPurchaseData}
                helperText={(<p>Vision will automatically purchase any data uploaded for agency drivers depending on which settings are chosen.</p>)}
                dangerHelperText={errors.autoPurchaseData}
                loading={loading}
                id="input-auto-purchase"
            ></FormSelect>

            <NotificationInline
                show={autoPurchaseDataMessage !== "" && autoPurchaseDataMessage != null}
                text={autoPurchaseDataMessage}
                intent="info"
            ></NotificationInline>

            <FormRadio
                disabled={saving}
                onChange={onMoveDataWithLocationChange}
                headingText="Move existing data when an asset/driver changes location:"
                selectedValue={tachoSettings.moveDataWithLocation}
                helperText={(<p>Set to 'Yes' if you want the associated data to be moved when an asset or driver is moved to a new location.</p>)}
                dangerHelperText={errors.moveDataWithLocation}
                loading={loading}
                id="input-move-location-data"
            ></FormRadio>

            <FormSelect
                disabled={saving}
                items={defaultOptions.manualEntryDefault}
                onItemSelect={onManualEntryDefaultChange}
                placeholder="Select a default manual entry analysis"
                headingText="Default manual entry analysis type:"
                selectedValue={tachoSettings.manualEntryDefault}
                helperText={(<p>These are the default manual entry rules that will apply to all manual entries on this account.</p>)}
                dangerHelperText={errors.manualEntryDefault}
                loading={loading}
                id="input-manual-entry-default"
            ></FormSelect>

            <FormRadio
                disabled={saving}
                onChange={onAnalysePeriodOfAvailabilityChange}
                headingText="Analyse POA (Period of Availability) as working hours when there is a driver card in slot 1 and slot 2 of the tachograph:"
                selectedValue={tachoSettings.analysePeriodOfAvailability}
                loading={loading}
                id="input-analyse-poa"
            ></FormRadio>


            <FormRadio
                disabled={saving}
                onChange={onRoamingEnabledChange}
                headingText="Allow drivers to be set as roaming:"
                selectedValue={tachoSettings.roamingEnabled}
                helperText={(<p>When the driver's tacho data is uploaded, the driver's location will be switched to the location of the upload.</p>)}
                loading={loading}
                id="input-roaming-enabled"
            ></FormRadio>

            <ShowHide
                evaluator={tachoSettings.roamingEnabled != null && tachoSettings.roamingEnabled}
                show={(
                    <Fragment>
                        <FormRadio
                            disabled={saving}
                            onChange={onAutoAwayChange}
                            headingText="Automatically set roaming drivers to away:"
                            selectedValue={tachoSettings.autoAway}
                            helperText={(<p>After a selected amount of time with no driver card uploads containing a period of drive, roaming drivers will automatically be set to away.</p>)}
                            loading={loading}
                            id="input-auto-away"
                        ></FormRadio>

                        <ShowHide
                            evaluator={tachoSettings.roamingEnabled != null && tachoSettings.roamingEnabled
                                && tachoSettings.autoAway != null && tachoSettings.autoAway}
                            show={(
                                <Fragment>
                                    <div className="form-row field-combination spacer-bottom-small">
                                        <h4>Set roaming drivers to away after:</h4>
                                        <FormNumericInput
                                            disabled={saving}
                                            min={minAutoAwayPeriod}
                                            max={maxAutoAwayPeriod}
                                            selectedValue={tachoSettings.autoAwayDelayFrequency}
                                            onValueChange={onAutoAwayResendFrequencyChange}
                                            loading={loading}
                                        ></FormNumericInput>
                                        <FormSelect
                                            disabled={saving}
                                            items={autoAwayResendFrequencyTypeOptionList}
                                            onItemSelect={onAutoAwayResendFrequencyTypeChange}
                                            selectedValue={tachoSettings.autoAwayDelayFrequencyType}
                                            loading={loading}
                                        ></FormSelect>
                                    </div>
                                </Fragment>
                            )}
                        ></ShowHide>
                    </Fragment>
                )}
            ></ShowHide>

            <AlertConfirm
                title="Please confirm"
                isOpen={showSaveConfirm}
                onConfirm={onSaveConfirm}
                onCancel={onSaveCancel}
            >
                <p>Switching off the roaming driver feature will remove the roaming driver flag and functionality including reporting suites from your account, are you sure you wish to continue?</p>
            </AlertConfirm>

            <AlertUnsavedData
                isDirty={isDataDirty}>
            </AlertUnsavedData>

            <ButtonSave
                onClick={saveTachoSettings}
                disabled={saving}
                loading={loading}
            ></ButtonSave>
        </div>
    );
}
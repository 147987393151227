import React, { useState } from 'react';
import { UserService } from "services";
import { AuditingTable } from 'components/auditing';

export function UserChangelog(props) {

    const noDataMessage = "It looks like no previous changes have been made to this user.";
    const [tableData, setTableData] = useState(null);
    const [tableRecordCount, setTableRecordCount] = useState(0);

    function onTableChange(pageSize, pageNumber, startDate, endDate) {
        UserService.getChangelog(props.userId, pageSize, pageNumber, startDate, endDate).then(r => {
            setTableData(r.data);
            setTableRecordCount(r.totalCount);
        });
    }

    return (
        <AuditingTable data={tableData} totalCount={tableRecordCount} onChange={onTableChange} noDataMessage={noDataMessage}></AuditingTable>
    );

}
import React, { Fragment, useState, useEffect } from 'react';
import { Intent } from '@blueprintjs/core'

import { FormTextInput, FormHelperText } from "components/form-fields";
import { useValidation } from "hooks/useValidation";
import { LoginLayout } from 'pages/layouts';
import { ShowHide } from 'components/layout';
import { ButtonSave } from 'components/buttons';
import { UserService } from 'services';
import { NotificationToaster } from "components/notifications";
import { Link } from 'react-router-dom';
import './Driver.css';


export function DriverRegistration() {

    const [hasRegistered, setHasRegistered] = useState(false);
    const [isValid, errors, validate] = useValidation();
    const [saving, setSaving] = useState(false);
    const [user, setUser] = useState({ emailAddress: "", driverAppActivationCode: "", driverLicenceNumber: "" });

    const rules = [
        { fieldName: "emailAddress", required: true, type: "email" },
        { fieldName: "driverAppActivationCode", required: true },
        { fieldName: "driverLicenceNumber", required: true }
    ];

    let showReturnLink = document.referrer !== "" && document.referrer.startsWith(window.env.SSOINSTANCEDOMAIN);

    function onGoBack() {
        window.history.back();
    }

    function onEmailChange(event) {
        setUser({
            ...user,
            emailAddress: event.target.value
        });
    }

    function onActivationCodeChange(event) {
        setUser({
            ...user,
            driverAppActivationCode: event.target.value
        });
    }

    function onLicenceChange(event) {
        setUser({
            ...user,
            driverLicenceNumber: event.target.value
        });
    }

    function validateForm() {
        setSaving(true);
    }

    useEffect(function () {
        validate(rules, user);
    }, [user])


    useEffect(registerDriver, [isValid, saving]);

    function registerDriver() {

        if (isValid && saving) {
            UserService.registerDriver(user).then(function () {
                setSaving(false);
                setHasRegistered(true);
            }, function (error) {
                setSaving(false);
                const badRequestCode = 400;
                if (error && error.status === badRequestCode) {
                    NotificationToaster.show(Intent.WARNING, error.data);
                } else {
                    NotificationToaster.show(Intent.DANGER, "Could not complete your registration, please try again.");
                }
            });
        }
        else {
            setSaving(false);
        }
    }


    return (
        <LoginLayout>

            <ShowHide
                evaluator={!hasRegistered}
                show={(
                    <Fragment>

                        <h2>Register for the Vision Driver app</h2>

                        <FormHelperText>
                            <p>If your company is a Logistics UK member you can register for free and a request will be sent to your organisation for approval.</p>
                            <p><strong>If you do not know your activiation code, please get in contact with your company administrator.</strong></p>
                        </FormHelperText>

                        <FormTextInput
                            id="user-email-address"
                            value={user.emailAddress}
                            headingText="Email Address:"
                            onChange={onEmailChange}
                            dangerHelperText={errors.emailAddress}>
                        </FormTextInput>
                        <FormTextInput
                            id="user-activation-code"
                            value={user.driverAppActivationCode}
                            headingText="Activation Code:"
                            onChange={onActivationCodeChange}
                            dangerHelperText={errors.driverAppActivationCode} >
                        </FormTextInput>
                        <FormTextInput
                            id="user-licence-number"
                            value={user.driverLicenceNumber}
                            headingText="Driver Licence Number:"
                            onChange={onLicenceChange}
                            dangerHelperText={errors.driverLicenceNumber} >
                        </FormTextInput>

                        <ButtonSave
                            onClick={validateForm}
                            simpleDisabled={!isValid}
                            disabled={saving}
                            text="Register"
                            icon={null}
                        ></ButtonSave>


                    </Fragment>
                )}
                hide={(
                    <Fragment>
                        <h2>Your registration has been submitted for approval</h2>

                        <FormHelperText>
                            <p>Thank you for taking time to register for the Vision Driver app.</p>
                            <p>Your company are currently dealing with your registration request and will contact you shortly.</p>
                            <br />
                            <p>If you haven't already, you can download the app for iOS or Android using the buttons below.</p>
                            <br />

                            <p><a target="_blank" rel="noopener noreferrer" href="https://apps.apple.com/gb/app/vision-driver-app/id1046508845#?platform=iphone"><img src="/icons/app-store-badge.svg" alt="Download from the App Store" className="app-badge" /></a>
                                <a target="_blank" rel="noopener noreferrer" href="https://play.google.com/store/apps/details?id=com.fta.driverfta&hl=en_GB&gl=US"><img src="/icons/google-play-badge.png" alt="Download from the Google Play Store" className="app-badge app-badge-google" /></a></p>
                        </FormHelperText>
                    </Fragment>
                )}

            />

            <ShowHide
                evaluator={showReturnLink}
                show={(
                    <div className="links">
                        <Link to={'#'} onClick={onGoBack}>Return to login</Link>
                    </div>
                )}
            />
        </LoginLayout>
    );

}
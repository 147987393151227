import React, { useState } from 'react';
import { LocationService } from "services";
import { AuditingTable } from 'components/auditing';

export function LocationChangelog(props) {

    const noDataMessage = "It looks like no previous changes have been made to this location.";
    const [tableData, setTableData] = useState(null);
    const [tableRecordCount, setTableRecordCount] = useState(0);

    function onTableChange(pageSize, pageNumber, startDate, endDate) {
        LocationService.getChangelog(props.locationId, pageSize, pageNumber, startDate, endDate).then(r => {
            setTableData(r.data);
            setTableRecordCount(r.totalCount);
        });
    }

    return (
        <AuditingTable data={tableData} totalCount={tableRecordCount} onChange={onTableChange} noDataMessage={noDataMessage}></AuditingTable>
    );

}
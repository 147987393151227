import { FormHelperText, FormMultiSelect, FormSwitch } from "components/form-fields";
import { ShowHide } from "components/layout";
import { useValidation } from "hooks/useValidation";
import PropTypes from 'prop-types';
import React, { Fragment, useEffect } from "react";

export function MigrationAssetSettings(props) {
    const [isValid, errors, validate] = useValidation();

    useEffect(() => {
        props.onValidationChange(isValid);
    }, [isValid]);

    useEffect(() => {
        let validationRules = [];

        if (!props.assetSettings.migrateAllAssets) {
            validationRules = [
                { fieldName: "assetTypes", required: true },
                { fieldName: "assetStatuses", required: true },
                { fieldName: "assetOwnerships", required: true }
            ]
        }

        validate(validationRules, props.assetSettings);
    }, [props.assetSettings]);

    function hasValue(item, array) {
        if (!array) {
            return false;
        }

        return array.some(function (x) {
            return x.id === item.id;
        });
    }

    function onListChange(item, listName) {
        const listClone = props.assetSettings[listName] ? [...props.assetSettings[listName]] : [];

        if (hasValue(item, listClone)) {
            return;
        }

        listClone.push(item);

        const settingsClone = {
            ...props.assetSettings,
            [listName]: listClone
        };

        props.onAssetSettingsChange(settingsClone);
    }

    function onAssetStatusesChange(item) {
        onListChange(item, 'assetStatuses');
    }

    function onAssetTypesChange(item) {
        onListChange(item, 'assetTypes');
    }

    function onAssetOwnershipsChange(item) {
        onListChange(item, 'assetOwnerships');
    }

    function onRemove(index, listName) {
        const listClone = [...props.assetSettings[listName]];
        listClone.splice(index, 1);

        const settingsClone = {
            ...props.assetSettings,
            [listName]: listClone
        };

        props.onAssetSettingsChange(settingsClone);
    }

    function onAssetStatusesRemove(_, index) {
        onRemove(index, 'assetStatuses');
    }

    function onAssetTypesRemove(_, index) {
        onRemove(index, 'assetTypes');
    }

    function onAssetOwnershipsRemove(_, index) {
        onRemove(index, 'assetOwnerships');
    }

    function onMigrateAllAssetsChange(event) {
        const settingsClone = { ...props.assetSettings };

        settingsClone.migrateAllAssets = event.target.checked;

        props.onAssetSettingsChange(settingsClone);
    }

    return <div>
        <h2>Select the criteria for the migrated assets</h2>
        <FormHelperText>Only assets matching the below criteria will be migrated.</FormHelperText>
        <FormSwitch
            id="select-all-assets"
            disabled={props.saving}
            headingText="Migrate all assets"
            checked={props.assetSettings.migrateAllAssets}
            onChange={onMigrateAllAssetsChange}
        />
        <ShowHide
            evaluator={!props.assetSettings.migrateAllAssets}
            show={(
                <Fragment>
                    <FormMultiSelect
                        id="asset-types"
                        headingText="Which asset types would you like to migrate?"
                        items={props.assetOptions?.assetTypes}
                        onItemSelect={onAssetTypesChange}
                        selectedValues={props.assetSettings.assetTypes ? props.assetSettings.assetTypes : []}
                        dangerHelperText={errors.assetTypes}
                        placeholder="Select asset types"
                        disabled={props.saving}
                        onRemove={onAssetTypesRemove}
                    />
                    <FormMultiSelect
                        id="asset-statues"
                        headingText="Which asset statuses would you like to migrate?"
                        items={props.assetOptions?.assetStatuses}
                        onItemSelect={onAssetStatusesChange}
                        selectedValues={props.assetSettings.assetStatuses ? props.assetSettings.assetStatuses : []}
                        dangerHelperText={errors.assetStatuses}
                        placeholder="Select asset statuses"
                        disabled={props.saving}
                        onRemove={onAssetStatusesRemove}
                    />
                    <FormMultiSelect
                        id="asset-ownerships"
                        headingText="Which asset ownerships would you like to migrate?"
                        items={props.assetOptions?.assetOwnerships}
                        onItemSelect={onAssetOwnershipsChange}
                        selectedValues={props.assetSettings.assetOwnerships ? props.assetSettings.assetOwnerships : []}
                        dangerHelperText={errors.assetOwnerships}
                        placeholder="Select asset ownerships"
                        disabled={props.saving}
                        onRemove={onAssetOwnershipsRemove}
                    />
                </Fragment>
            )}
        />
    </div>;
}

MigrationAssetSettings.propTypes = {
    assetSettings: PropTypes.object.isRequired,
    assetOptions: PropTypes.object.isRequired,
    saving: PropTypes.bool,
    onAssetSettingsChange: PropTypes.func.isRequired,
    onValidationChange: PropTypes.func.isRequired
};

MigrationAssetSettings.defaultProps = {
    saving: false
};
import React, { Fragment, useState, useEffect } from 'react';
import { Intent } from '@blueprintjs/core';

import { FormRadio, FormHelperText, FormDateInput } from "components/form-fields";
import { NotificationToaster, AlertUnsavedData } from "components/notifications";
import { ButtonSave } from "components/buttons";
import { ShowHide } from "components/layout";
import { WorkingTimeSettings } from 'components/settings';
import { InternalLink } from 'components/navigation';

import { useValidation } from 'hooks/useValidation';
import { AccountService, LocationService } from "services";
import { useDirtyData } from "hooks/useDirtyData";
import moment from "moment";

export function LocationTachoSettings(props) {

    const [isValid, errors, validate] = useValidation(); 
    const [workingTimeValid, setWorkingTimeValid] = useState(false); 
    const [loading, setLoading] = useState(true);
    const [saving, setSaving] = useState(false);

    const [originalTachoValues, setOriginalTachoValues] = useState({});
    const [originalWorkingTimeValues, setOriginalWorkingTimeValues] = useState({});

    const [tachoSettings, setTachoSettings] = useState({
        useTachoAnalysisDefaults: true,
        tachoAnalysisStartDate: null
    });

    const [workingTimeSettings, setWorkingTimeSettings] = useState({
        optOutNightWork: null,
        workingTimeReferencePeriod: null,
        workingTimeRollingReference: null,
        workingTimeReferenceFull: null,
        workingTimeReferenceStartDate: null,
        workingTimeVehicleOperation: null,
        analysePeriodOfAvailability: null,
        leadInAdjustment: null
    })

    const areTachoValuesDirty = useDirtyData(originalTachoValues, tachoSettings);
    const areWorkingTimeValuesDirty = useDirtyData(originalWorkingTimeValues, workingTimeSettings);

    const defaultOptions = {
        inheritSettings: [{ value: true, label: "Inherit default settings" }, { value: false, label: "Apply custom settings" }]
    };

    const [accountTachoSettings, setAccountTachoSettings] = useState({});

    useEffect(initialLoad, []);

    function initialLoad() {

        function getAccountTachoSettings(tachoConfig) {
            AccountService.getTachoSettings().then(
                (settings) => {
                    setAccountTachoSettings(settings);

                    let pageSettings = {};

                    if (tachoConfig.useTachoAnalysisDefaults) {
                        pageSettings = {
                            ...tachoConfig,
                            tachoAnalysisStartDate: settings.tachoAnalysisStartDate,
                            optOutNightWork: settings.optOutNightWork,
                            workingTimeReferencePeriod: settings.workingTimeReferencePeriod,
                            workingTimeRollingReference: settings.workingTimeRollingReference,
                            workingTimeReferenceFull: settings.workingTimeReferenceFull,
                            workingTimeReferenceStartDate: settings.workingTimeReferenceStartDate,
                            workingTimeVehicleOperation: settings.workingTimeVehicleOperation,
                            leadInAdjustment : settings.leadInAdjustment
                        };
                    } else {
                        pageSettings = tachoConfig;
                    }

                    const tacho = (({ useTachoAnalysisDefaults, tachoAnalysisStartDate }) => ({ useTachoAnalysisDefaults, tachoAnalysisStartDate }))(pageSettings);
                    const workingTime = (({ optOutNightWork, workingTimeReferencePeriod, workingTimeRollingReference, workingTimeReferenceFull, workingTimeReferenceStartDate, workingTimeVehicleOperation, analysePeriodOfAvailability, leadInAdjustment }) => ({ optOutNightWork, workingTimeReferencePeriod, workingTimeRollingReference, workingTimeReferenceFull, workingTimeReferenceStartDate, workingTimeVehicleOperation, analysePeriodOfAvailability, leadInAdjustment }))(pageSettings);

                    setTachoSettings(tacho);
                    setOriginalTachoValues(tacho);

                    setWorkingTimeSettings(workingTime);
                    setOriginalWorkingTimeValues(workingTime);
                    setLoading(false);
                },
                () => {
                    NotificationToaster.show(Intent.DANGER, "Could not load settings. Please refresh the page.");
                }
            );
        }

        LocationService.getTachoSettings(props.locationId).then(
            (settings) => {
                getAccountTachoSettings(settings);
            },
            () => {
                NotificationToaster.show(Intent.DANGER, "Could not load settings. Please refresh the page.");
            }
        );
    }

    useEffect(validateModel, [areTachoValuesDirty, areWorkingTimeValuesDirty, tachoSettings]);

    function validateModel() {
        if (areTachoValuesDirty || areWorkingTimeValuesDirty) {

            let rules = [];

            if (!tachoSettings.useTachoAnalysisDefaults && originalTachoValues.tachoAnalysisStartDate !== null) {
                    rules.push({ fieldName: "tachoAnalysisStartDate", required: true });
            }

            validate(rules, tachoSettings);
        }
    }

    function saveTachoSettings() {
        setSaving(true);
        if (!tachoSettings.useTachoAnalysisDefaults && (!isValid || !workingTimeValid)) {
            NotificationToaster.show(Intent.WARNING, "There are errors on the page that need your attention");
            setSaving(false);
            return;
        }

        var pageSettings = { ...tachoSettings, ...workingTimeSettings };

        LocationService.putTachoSettings(props.locationId, pageSettings).then(
            () => {
                setSaving(false);
                setOriginalTachoValues(tachoSettings);
                setOriginalWorkingTimeValues(workingTimeSettings);

                NotificationToaster.show(Intent.SUCCESS, "Settings updated successfully.")
            },
            (error) => {
                setSaving(false);
                const badRequestCode = 400;
                if (error.response.status === badRequestCode) {
                    NotificationToaster.show(Intent.DANGER, "Settings not valid. Please check your settings and try again.");
                } else {
                    NotificationToaster.show(Intent.DANGER, "Could not save settings. Please try again.");
                }
            }
        );
    }

    function onWorkingTimeValidationChange(isWorkingTimeValid) {
        setWorkingTimeValid(isWorkingTimeValid);
    }

    function onWorkingTimeSettingsChange(item) {
        setWorkingTimeSettings({
            ...workingTimeSettings,
            ...item
        })
    }

    function onUseDefaultsChange(item) {
        setTachoSettings({
            ...tachoSettings,
            useTachoAnalysisDefaults: item.currentTarget.value === "true"
        });
    }

    function onTachoAnalysisStartDateChange(item) {
        setTachoSettings({
            ...tachoSettings,
            tachoAnalysisStartDate: item ? moment(item).format("YYYY-MM-DD") : null
        });
    }

    return (
        <div className="row">
            <h2>Tacho Analysis Settings</h2>
            <FormHelperText loading={loading}>
                <p>By default, your data will be analysed against EU Drivers' Hours rules. You can apply a different rule set to individual drivers and vehicles (in the driver or vehicle profiles) if needed. </p>
            </FormHelperText>
           
            <FormRadio
                id="location-settings-inherited"
                disabled={saving}
                onChange={onUseDefaultsChange}
                headingText="Inherit the account's tacho settings or apply custom settings:"
                selectedValue={tachoSettings.useTachoAnalysisDefaults}
                options={defaultOptions.inheritSettings}
                loading={loading}
                helperText={tachoSettings.useTachoAnalysisDefaults ? (<p>To see your default settings go to <InternalLink link="/account/tacho-settings">Account Tacho Settings</InternalLink>.</p>) : null}
            ></FormRadio>

            <ShowHide
                evaluator={tachoSettings.useTachoAnalysisDefaults === false}
                show={(<Fragment>

                    <FormDateInput
                        id="location-tacho-start"
                        disabled={saving}
                        headingText="The date you wish Vision to analyse your tacho data from:"
                        onChange={onTachoAnalysisStartDateChange}
                        value={tachoSettings.tachoAnalysisStartDate}
                        loading={loading}
                        dangerHelperText={errors.tachoAnalysisStartDate}
                        minDate={moment("2000-01-01").toDate()}
                    ></FormDateInput>

                    <WorkingTimeSettings
                        saving={saving}
                        settings={workingTimeSettings}
                        workingtimeDisabled={!accountTachoSettings.includeWorkingTime}
                        onSettingsUpdate={onWorkingTimeSettingsChange}
                        onValidationUpdate={onWorkingTimeValidationChange}
                    />
                </Fragment>)}
            />

            <AlertUnsavedData
                isDirty={areTachoValuesDirty || areWorkingTimeValuesDirty}>
            </AlertUnsavedData>
            <ButtonSave
                id="location-tacho-save"
                onClick={saveTachoSettings}
                disabled={saving}
                loading={loading}
            ></ButtonSave>
        </div>
    );
}
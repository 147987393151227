import React, { useState, useEffect } from 'react';

import { Intent } from '@blueprintjs/core';
import axios from 'axios';

import './UserRole.css';

import { UserService } from 'services';
import { NotificationToaster, AlertUnsavedData, NotificationInline } from "components/notifications";
import { ButtonSave } from "components/buttons";
import { UserRoleSettings } from 'components/settings/UserRoleSettings';
import { useDirtyData } from "hooks/useDirtyData";
import { HelperFunctions } from 'helpers';


var _ = require('underscore');

export function UserRole(props) {

    const [originalValues, setOriginalValues] = useState({});
    const [loading, setLoading] = useState(true);
    const [showRoleWarning, setRoleWarning] = useState(false);
    const [user, setUser] = useState({
        role: {}
    });
    const [saving, setSaving] = useState(false);
    const isDataDirty = useDirtyData(originalValues, user);

    useEffect(() => { 
        setUser(props.user);
        setOriginalValues(_.extend({}, props.user));
    }, [props.user]);


    function saveUserRole() {
        setSaving(true);

        var requests = [UserService.putUserBasicInfo(user), UserService.restrictActions(user.userId, user.actionRestrictions)];

        axios.all(requests).then(function () {
            setSaving(false);
            setOriginalValues(_.extend({}, user));
            NotificationToaster.show(Intent.SUCCESS, "User's role updated successfully.")
            HelperFunctions.executeIfNotNull(props.onSave, user);
            setRoleWarning(true);

        }, function () {
            setSaving(false);
            NotificationToaster.show(Intent.DANGER, "Could not update the user's role. Please try again.");
        });
      
    }

    function onRoleChange(userUpdate, initialValues) {
        setUser(userUpdate);

        if (initialValues) {
            setOriginalValues(_.extend({}, userUpdate));
        }

    }

    function onRoleSettingsLoaded() {
        setLoading(false);
    }

    return (
        <div className="row">
            <h2>Role</h2>
         
            <UserRoleSettings
                saving={saving}
                settings={user}
                originalSettings={originalValues}
                onSettingsUpdate={onRoleChange}
                onLoaded={onRoleSettingsLoaded}
            />

            <AlertUnsavedData
                isDirty={isDataDirty}>
            </AlertUnsavedData>
            <NotificationInline
                loading={false}
                show={showRoleWarning}
                text="If you wish for your changes to take immediate effect, get the user to sign out and back in, otherwise the changes will take effect shortly."
                intent="warning">
            </NotificationInline>
            <ButtonSave
                id="user-role-save"
                onClick={saveUserRole}
                disabled={saving}
                loading={loading}
            ></ButtonSave>

        </div>
        );
}
import { Collapsable } from "components/layout";
import React, { useEffect, useState } from "react";
import PropTypes from 'prop-types';
import { JobService } from "services";
import axios from "axios";
import { DataDisplayImageTable } from "components/data-display";
import { NotificationToaster } from "components/notifications";
import { Intent } from "@blueprintjs/core";

export function JobReportSignaturesSection(props) {
    const [loading, setLoading] = useState(true);
    const [signatureImageSources, setSignatureImageSources] = useState([]);

    useEffect(() => {
        setLoading(true);

        const signatureImageRequests = props.signatures.map(signature => JobService.getJobFile(signature.storageDocumentId));

        axios.all(signatureImageRequests)
            .then(signatureImages => {
                setLoading(false);
                setSignatureImageSources(mapSignaturesToImages(signatureImages));
            },
                () => {
                    NotificationToaster.show(Intent.DANGER, "One or more signatures could not be loaded. Please try again.");
                    setLoading(false);
                });
    }, [props.files]);

    function mapSignaturesToImages(signatureImages) {
        return signatureImages.map(signatureImage => URL.createObjectURL(signatureImage));
    }

    return <Collapsable
        loading={loading}
        title={`${props.sectionNumber}. Signatures`}
        fullWidth={true}
        metadata={`${props.signatures.length} Signatures`}
        expanded={props.isExpanded}
        onClick={props.onCollapsableClick}
    >
        <DataDisplayImageTable imageSources={signatureImageSources} />
    </Collapsable>;
}

JobReportSignaturesSection.propTypes = {
    signatures: PropTypes.array.isRequired,
    sectionNumber: PropTypes.number.isRequired,
    isExpanded: PropTypes.bool.isRequired,
    onCollapsableClick: PropTypes.func.isRequired
};
import { FormHelperText, FormMultiSelect, FormSwitch } from "components/form-fields";
import { ShowHide } from "components/layout";
import { useValidation } from "hooks/useValidation";
import PropTypes from 'prop-types';
import React, { Fragment, useEffect } from "react";

export function MigrationEmployeeSettings(props) {
    const [isValid, errors, validate] = useValidation();

    useEffect(() => {
        props.onValidationChange(isValid);
    }, [isValid]);

    useEffect(() => {
        let validationRules = [];

        if (!props.settings.migrateAllEmployees) {
            validationRules = [
                { fieldName: "employeeTypes", required: true },
                { fieldName: "employeeStatuses", required: true },
                { fieldName: "employeeCategories", required: true }
            ]
        }

        validate(validationRules, props.settings);
    }, [props.settings]);

    function hasValue(item, array) {
        if (!array) {
            return false;
        }

        return array.some(function (x) {
            return x.id === item.id;
        });
    }

    function onListChange(item, listName) {
        const listClone = props.settings[listName] ? [...props.settings[listName]] : [];

        if (hasValue(item, listClone)) {
            return;
        }

        listClone.push(item);

        const settingsClone = {
            ...props.settings,
            [listName]: listClone
        };

        props.onSettingsChange(settingsClone);
    }

    function onEmployeeStatusesChange(item) {
        onListChange(item, 'employeeStatuses');
    }

    function onEmployeeTypesChange(item) {
        onListChange(item, 'employeeTypes');
    }

    function onEmployeeCategoriesChange(item) {
        onListChange(item, 'employeeCategories');
    }

    function onRemove(index, listName) {
        const listClone = [...props.settings[listName]];
        listClone.splice(index, 1);

        const settingsClone = {
            ...props.settings,
            [listName]: listClone
        };

        props.onSettingsChange(settingsClone);
    }

    function onEmployeeStatusesRemove(_, index) {
        onRemove(index, 'employeeStatuses');
    }

    function onEmployeeTypesRemove(_, index) {
        onRemove(index, 'employeeTypes');
    }

    function onEmployeeCategoriesRemove(_, index) {
        onRemove(index, 'employeeCategories');
    }

    function onMigrateAllEmployeesChange(event) {
        const settingsClone = { ...props.settings };

        settingsClone.migrateAllEmployees = event.target.checked;

        props.onSettingsChange(settingsClone);
    } 

    return <div>
        <h2>Select the criteria for the migrated employees</h2>
        <FormHelperText>Only employees matching the below criteria will be migrated.</FormHelperText>
        <FormSwitch
            id="select-all-employees"
            disabled={props.saving}
            headingText="Migrate all employees"
            checked={props.settings.migrateAllEmployees}
            onChange={onMigrateAllEmployeesChange}
        />
        <ShowHide
            evaluator={!props.settings.migrateAllEmployees}
            show={(
                <Fragment>
                    <FormMultiSelect
                        id="employee-types"
                        headingText="Which employee types would you like to migrate?"
                        items={props.employeeOptions?.employeeTypes}
                        onItemSelect={onEmployeeTypesChange}
                        selectedValues={props.settings.employeeTypes ? props.settings.employeeTypes : []}
                        dangerHelperText={errors.employeeTypes}
                        placeholder="Select employee types"
                        disabled={props.saving}
                        onRemove={onEmployeeTypesRemove}
                    />
                    <FormMultiSelect
                        id="employee-statues"
                        headingText="Which employee statuses would you like to migrate?"
                        items={props.employeeOptions?.employeeStatuses}
                        onItemSelect={onEmployeeStatusesChange}
                        selectedValues={props.settings.employeeStatuses ? props.settings.employeeStatuses : []}
                        dangerHelperText={errors.employeeStatuses}
                        placeholder="Select employee statuses"
                        disabled={props.saving}
                        onRemove={onEmployeeStatusesRemove}
                    />
                    <FormMultiSelect
                        id="employee-categories"
                        headingText="Which employee categories would you like to migrate?"
                        items={props.employeeOptions?.employeeCategories}
                        onItemSelect={onEmployeeCategoriesChange}
                        selectedValues={props.settings.employeeCategories ? props.settings.employeeCategories : []}
                        dangerHelperText={errors.employeeCategories}
                        placeholder="Select employee categories"
                        disabled={props.saving}
                        onRemove={onEmployeeCategoriesRemove}
                    />
                </Fragment>
            )}
        />
    </div>;
}

MigrationEmployeeSettings.propTypes = {
    settings: PropTypes.object.isRequired,
    employeeOptions: PropTypes.object.isRequired,
    saving: PropTypes.bool,
    onSettingsChange: PropTypes.func.isRequired,
    onValidationChange: PropTypes.func.isRequired
};

MigrationEmployeeSettings.defaultProps = {
    saving: false
};
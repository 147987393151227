import React from 'react';
import PropTypes from 'prop-types';
import { useSelector, useDispatch } from 'react-redux';
import { MenuItem } from "@blueprintjs/core";
import { useHistory } from "react-router-dom";


export function InternalMenuItem(props) {

    let history = useHistory();

    const dispatch = useDispatch();
    const preventSwitch = useSelector(state => state.dirtyData.dirty);

    function navigateToInternalLocation() {

        if (props.forceRedirect) {

            if (preventSwitch === true) {
                dispatch({ type: 'BEGIN_REDIRECT', payload: simpleRedirect });
                return;
            } else {
                simpleRedirect()
            }
            
        } else {
            history.push(props.location);
        }

    }

    function simpleRedirect() {
        window.location = props.location;
    }

    return (
        props.nodeType === "a" ? (<a className={props.className} id={props.id} onClick={navigateToInternalLocation}>{props.text}</a>) : (<MenuItem className={props.className} id={props.id} text={props.text} onClick={navigateToInternalLocation} />)
    );
}


InternalMenuItem.defaultProps = {
    id: null,
    forceRedirect: false,
    menuItemType: 'li'
};

InternalMenuItem.propTypes = {
    id: PropTypes.string,
    text: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.object
    ]).isRequired,
    location: PropTypes.string.isRequired,
    forceRedirect: PropTypes.bool.isRequired,
    nodeType: PropTypes.string,
    className: PropTypes.string
};
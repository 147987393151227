import React, { useEffect, useState } from 'react';
import { Icon } from '@blueprintjs/core';
import PropTypes from 'prop-types';
import { FormSwitchList } from 'components/form-fields';
import { NotificationInline } from "components/notifications";
import { HierarchyService } from 'services';
import { Tooltip } from 'components/tooltip';


var _ = require('underscore');

export function HierarchyFeatures(props) {

    const [allFeatures, setAllFeatures] = useState([]);
    const [hierarchyAreas, setHierarchyAreas] = useState([]);
    const [isLockedFeatures, setIsLockedFeatures] = useState(false);

    useEffect(function () {
        HierarchyService.getLocationHierarchyFeatures().then(function (response) {
            setAllFeatures(response.hierarchyFeaturesList);
        });
    }, []);

    useEffect(function () {

        var hierarchyBusinessAreas = props.hierarchy.businessAreas.map(function (businessArea) {
            var featureItems = allFeatures.filter(function (f) {
                return f.locationBusinessAreaId === businessArea.locationBusinessAreaId
            });

            return {
                ...businessArea,
                "items": featureItems.map(mapFeatureToCheckBoxItem)
            };
        });

        setIsLockedFeatures(hierarchyBusinessAreas.some(function (areas) {
            return areas.items.some(function (item) {
                return item.disabled;
            })
        }));

        setHierarchyAreas(hierarchyBusinessAreas);

    }, [allFeatures.length, props.hierarchy.hierarchyFeatures.length, props.hierarchy.businessAreas.length]);

    function mapFeatureToCheckBoxItem(feature) {
        return {
            "id": feature.hierarchyFeatureId,
            "name": (
                <span className="radio-label-with-help">
                    <span>{feature.featureName} &nbsp;</span>
                    <span>
                        <Tooltip position="right" content={feature.featureDescription}>
                            <Icon icon="help" />
                        </Tooltip>
                    </span>
                </span>
            ),
            "checked": props.hierarchy.hierarchyFeatures.some(function (i) {
                return i.hierarchyFeatureId === feature.hierarchyFeatureId
            }),
            "disabled": feature.hierarchyId > 0 && feature.hierarchyId !== props.hierarchy.hierarchyId
        };
    }

    function onToggleChange(e) {

        var newFeatures = [
            ...props.hierarchy.hierarchyFeatures
        ];

        if (e.target.checked) {
            var match = _.find(allFeatures, function (item) {
                return item.hierarchyFeatureId.toString() === e.currentTarget.getAttribute('identifier');
            });

            newFeatures.push(match);
        } else {
            var toggleId = e.currentTarget.getAttribute('identifier');

            newFeatures = newFeatures.filter(function (item) {
                return item.hierarchyFeatureId.toString() !== toggleId;
            });
        }

        props.onHierarchyUpdate({
            ...props.hierarchy,
            hierarchyFeatures: newFeatures
        });
        
    }

    return (
        <div className="spacer-top">

            <NotificationInline
                show={isLockedFeatures}
                text="Some features are not selectable as they have already been assigned to another hierarchy."
                intent="info" />


            {hierarchyAreas != null && hierarchyAreas.map(function (area) {
                return (
                    <div key={area.businessArea}>
                        <FormSwitchList
                            id={`location-hierarchy-feature-${area.businessArea}-list`}
                            items={area.items}
                            onChange={onToggleChange}
                            headingText={`You have selected ${area.businessArea}. You can assign your hierarchy to these features:`}
                            loading={false}
                            switchListName={`${area.businessArea}-feature-list`}>
                        </FormSwitchList>
                    </div>
                );
            })}

            
            

            

        </div>
    );
}

HierarchyFeatures.defaultProps = {
    disabled: false
};

HierarchyFeatures.propTypes = {
    disabled: PropTypes.bool
};
import React from 'react';
import { Breadcrumbs as BpBreadcrumbs, Breadcrumb } from "@blueprintjs/core";
import { NavLink } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';

export function Breadcrumbs(props) {

    const dispatch = useDispatch();
    const preventSwitch = useSelector(state => state.dirtyData.dirty);

    return (
        <div className="breadcrumbs">
            <BpBreadcrumbs
                currentBreadcrumbRenderer={renderCurrentBreadcrumb}
                breadcrumbRenderer={renderBreadcrumb}
                items={props.items}
            />
        </div>
    );

    function navigateToExternalLocation(navLocation) {
        if (preventSwitch) {
            var redirectFunc = () => {
                window.location = navLocation;
            };

            dispatch({ type: 'BEGIN_REDIRECT', payload: redirectFunc });
        } else {
            window.location = navLocation;
        }
    }

    function renderBreadcrumb(item) {

        if (item == null || item.location == null) {
            return <span></span>
        }
        return item.externalLink ?
            <a onClick={() => navigateToExternalLocation(item.location)} className="bp3-breadcrumb">{item.title}</a> :
            <NavLink to={item.location} exact className="bp3-breadcrumb">{item.title}</NavLink>;
    }

}

function renderCurrentBreadcrumb(item) {
    if (item == null) {
        return <span></span>
    }
    return <Breadcrumb text={item.title} />;
}
import React, { useState, useEffect, Fragment, useRef } from 'react';
import { Intent } from '@blueprintjs/core';
import { FormTextInput, FormSuggest } from 'components/form-fields';
import { LocationService } from 'services';
import { NotificationToaster } from "components/notifications";
import { useValidation } from "hooks/useValidation";
import PropTypes from 'prop-types';
import { ShowHide } from "components/layout";
import { HelperFunctions } from 'helpers';

export function LocationAddressSettings(props) {

    const [loading, setLoading] = useState(true);
    const [postcodeSearch, setPostcodeSearch] = useState("");
    const [postcodeResults, setPostcodeResults] = useState([]);
    const [isValid, errors, validate] = useValidation();
    const [isPostCodeValid, postcodeErrors, validatePostcode] = useValidation();
    const [addressCountry, setAddressCountry] = useState("GB");
    const inputRef = useRef(null);

    const rules = [
        { fieldName: "addressLine1", required: true, maxLength: 255 },
        { fieldName: "addressLine2", maxLength: 255 },
        { fieldName: "addressLine3", maxLength: 255 },
        { fieldName: "postTown", required: true, maxLength: 255 },
        { fieldName: "county", required: true, maxLength: 255 },
        { fieldName: "phoneNumber", type: "phone", maxLength: 50 },
        { fieldName: "postCode", required: true, type: "postcode", maxLength: 255 }
    ];

    const postcodeRules = [
        { fieldName: "postCode", required: false, type: "postcode", maxLength: 255 }
    ];

    useEffect(() => {
        setLoading(props.loading);
    }, [props.loading]);


    useEffect(() => {
        HelperFunctions.executeIfNotNull(props.onValidationUpdate, isValid)
    }, [isValid]);

    useEffect(validateAddress, [props.addressSettings.address, postcodeSearch]);

    useEffect(function () {
        setPostcodeResults([]);
        validatePostcode(postcodeRules, postcodeSearch);
    }, [postcodeSearch])

    useEffect(function () {
        //On inital load isPostCodeValid is set to true, so we'll add the length check
        if (postcodeSearch.length > 0 && isPostCodeValid) {
            searchPostcode();
        }
    }, [isPostCodeValid])

    function validateAddress() {
        validate(rules, props.addressSettings.address);
    }

    function onAddressLine1Change(event) {
            props.onAddressSettingsUpdate({
                ...props.addressSettings.address,
                addressLine1: event.target.value
            });
    }
    function onAddressLine2Change(event) {
            props.onAddressSettingsUpdate({
                ...props.addressSettings.address,
                addressLine2: event.target.value
            });
    }
    function onAddressLine3Change(event) {
        props.onAddressSettingsUpdate({
            ...props.addressSettings.address,
            addressLine3: event.target.value
        });
    }

    function onPostTownChange(event) {
            props.onAddressSettingsUpdate({
                ...props.addressSettings.address,
                postTown: event.target.value
            });
    }

    function onCountyChange(event) {
            props.onAddressSettingsUpdate({
                ...props.addressSettings.address,
                county: event.target.value
            });
    }

    function onPostCodeChange(event) {
            props.onAddressSettingsUpdate({
                ...props.addressSettings.address,
                postCode: String(event.target.value).toUpperCase()
            });
    }

    function onPhoneNumberChange(event) {
        props.onAddressSettingsUpdate({
            ...props.addressSettings.address,
            phoneNumber: event.target.value
        });
    }

    function onPostcodeSearchTextChange(event) {
        setPostcodeSearch(event);
    }

    function postCodeReturn(postcodeRes, retrieve) {
        if (!retrieve) {
            if (postcodeRes.length < 1) {
                NotificationToaster.show(Intent.WARNING, "No addresses found with the search criteria.");
                return;
            }

            var mappedAddresses = postcodeRes.map(function (add) {
                add.name = add.label;
                add.id = add.data8Id;
                add.icon = add.container ? 'folder-close' : 'map-marker'
                return add;
            });

            //The initial call returns this, but the drilldown doesn't for some reason
            if (retrieve.addressCountry && retrieve.addressCountry.length > 0) {
                setAddressCountry(retrieve.addressCountry);
            }

            setPostcodeResults(mappedAddresses);
            //Need to set focus back so the dropdown opens
            inputRef.current.focus();
        } else {
            //This isn't set from the call to the API
            postcodeRes.phoneNumber = props.addressSettings.address.phoneNumber;
            props.onAddressSettingsUpdate(postcodeRes);
        }
    }

    function postCodeReturnError(error) {
        NotificationToaster.show(Intent.DANGER, error);
    }

    function searchPostcode() {
        if (!isPostCodeValid) {
            return;
        }

        LocationService.postCodeSearch(postcodeSearch).then(
            (postcodeRes) => postCodeReturn(postcodeRes, false),
            (error) => postCodeReturnError(error));
    }

    function onPostcodeResultsSelect(item) {
        var addCountry = item.addressCountry;

        if (!addCountry || addCountry.length < 1) {
            addCountry = addressCountry;
        } else {
            setAddressCountry(addCountry);
        }

        if (item.container) {
            LocationService.postCodeDrillDown(item.id, addCountry).then(
                (postcodeRes) => postCodeReturn(postcodeRes, false),
                (error) => postCodeReturnError(error));
        } else {
            //This will kick off the postCodeRetrieve, seems longwinded but wanted the postcode to clear once address set
            LocationService.postCodeRetrieve(item.id, addressCountry).then(
                (postcodeRes) => postCodeReturn(postcodeRes, true),
                (error) => postCodeReturnError(error));
        }
    }

    return (
        <Fragment>
            <ShowHide
                evaluator={props.readOnly}
                hide={(
                    <FormSuggest loading={loading}
                        onQueryChange={onPostcodeSearchTextChange}
                        disabled={false}
                        items={postcodeResults}
                        onItemSelect={onPostcodeResultsSelect}
                        headingText="Postcode search"
                        onFilter={null}
                        debounceTime={0}
                        itemsToReturn={0}
                        resetOnSelect={false}
                        inputRef={inputRef}
                        dangerHelperText={postcodeErrors.postCode}
                        id="postcode-search-field">
                    </FormSuggest>
                )}
            >

            </ShowHide>

            <div className="form-group">
                <FormTextInput
                    id="location-address-address1"
                    loading={loading}
                    value={props.addressSettings.address.addressLine1}
                    headingText="Address"
                    onChange={onAddressLine1Change}
                    disabled={props.saving || props.readOnly}
                    dangerHelperText={props.readOnly ? '' :errors.addressLine1}>
                </FormTextInput>
                <FormTextInput
                    id="location-address-address2"
                    loading={loading}
                    value={props.addressSettings.address.addressLine2}
                    onChange={onAddressLine2Change}
                    disabled={props.saving || props.readOnly}
                    dangerHelperText={props.readOnly ? '' : errors.addressLine2}>
                </FormTextInput>
                <FormTextInput
                    id="location-address-address3"
                    loading={loading}
                    value={props.addressSettings.address.addressLine3}
                    onChange={onAddressLine3Change}
                    disabled={props.saving || props.readOnly}
                    dangerHelperText={props.readOnly ? '' : errors.addressLine3}>
                </FormTextInput>
            </div>
            <FormTextInput
                id="location-address-posttown"
                headingText="Town/City"
                loading={loading}
                value={props.addressSettings.address.postTown}
                onChange={onPostTownChange}
                disabled={props.saving || props.readOnly}
                dangerHelperText={props.readOnly ? '' : errors.postTown}>
            </FormTextInput>
            <FormTextInput
                id="location-address-county"
                headingText="County"
                loading={loading}
                value={props.addressSettings.address.county}
                onChange={onCountyChange}
                disabled={props.saving || props.readOnly}
                dangerHelperText={props.readOnly ? '' : errors.county}>
            </FormTextInput>
            <FormTextInput
                id="location-address-postcode"
                headingText="Postcode"
                loading={loading}
                value={props.addressSettings.address.postCode}
                onChange={onPostCodeChange}
                disabled={props.saving || props.readOnly}
                dangerHelperText={props.readOnly ? '' : errors.postCode}>
            </FormTextInput>
            <FormTextInput
                id="location-address-phone-number"
                headingText="Location Phone Number"
                loading={loading}
                value={props.addressSettings.address.phoneNumber}
                onChange={onPhoneNumberChange}
                disabled={props.saving || props.readOnly}
                dangerHelperText={props.readOnly ? '' : errors.phoneNumber}>
            </FormTextInput>
        </Fragment >
    );
}
LocationAddressSettings.defaultProps = {
    saving: false,
    readOnly: false
};

LocationAddressSettings.propTypes = {
    addressSettings: PropTypes.object.isRequired,
    saving: PropTypes.bool,
    loading: PropTypes.bool.isRequired,
    readOnly: PropTypes.bool
};
import React, { useState, useEffect, Fragment } from "react";
import PropTypes from 'prop-types';

import { Draggable, Droppable } from "react-beautiful-dnd";
import { FixedSizeList as List } from 'react-window';
import { Icon } from '@blueprintjs/core';
import classNames from "classnames";

import { ShowHide } from 'components/layout';
import { FormTextInput, FormSwitch } from "components/form-fields";
import { useDebounce } from 'hooks/useDebounce';
import './DraggableList.css';

export function DraggableList(props) {

    const debounceTimeout = 250;
    const itemSpacing = 10;

    const [searchTerm, setSearchTerm] = useState("");
    const debouncedSearchTerm = useDebounce(searchTerm, debounceTimeout);
    const [isFiltered, setIsFiltered] = useState(true);

    useEffect(function () {
        props.onFilterItems(debouncedSearchTerm, isFiltered);
    }, [debouncedSearchTerm, isFiltered]);

    function onSearchChange(item) {
        setSearchTerm(item.target.value);
    }

    function onFilterChange() {
        setIsFiltered((previous) => {
            return !previous;
        })
    }


    const Row = ({ index, style }) => {

        var item = props.items[index];

        return (

            <Draggable
                key={item.id}
                draggableId={`${item.id}`}
                index={index}
                isDragDisabled={props.disabled}
            >
                {(provided, snapshot) => (
                    <Fragment>
                        <div
                            ref={provided.innerRef}
                            {...provided.draggableProps}
                            {...provided.dragHandleProps}
                            className={classNames("draggable-list-item", item.class, { "draggable-list-item-fixed": !snapshot.isDragging, "draggable-list-item-dragged": snapshot.isDragging, "draggable-list-item-duplicate": item.assignedCount != null && item.assignedCount > 1 && !snapshot.isDragging })}
                            style={{
                                ...style,
                                top: style.top + itemSpacing,
                                width: style.width - itemSpacing,
                                height: style.height - itemSpacing
                            }}>
                            {item.name}
                            <ShowHide
                                evaluator={item.assignedCount != null && item.assignedCount > 1 && !snapshot.isDragging}
                                show={(
                                    <div className="list-item-duplicate">
                                        <Icon className="draggable-item-duplicate" icon="duplicate" iconSize={12} />
                                    </div>
                                )}
                            />

                        </div>

                        
                    </Fragment>

                )}
            </Draggable>


        );
    }

    return (
        <div className="location-listing-container">
            <div className="inline-item fluid-item">
                <FormTextInput id="locationList-search-field" placeholder="Search Location List" onChange={onSearchChange} value={searchTerm} icon="search" loading={props.loading} disabled={props.disabled} />
            </div>

            <div className="spacer-small inline-item form-field-inline-switcher-container">
                <span className={classNames("form-field-inline-switcher-label", { "bp3-skeleton": props.loading })}>{props.filterLabel}</span>

                <div className="form-field-inline-switcher">
                    <FormSwitch id="locationList-show-ungrouped-locations" onChange={onFilterChange} checked={isFiltered} inline={true} alignment="right" loading={props.loading} disabled={props.disabled} />
                </div>
            </div>

           

            <div className="spacer-bottom location-items">
                <Droppable
                    droppableId={props.id}
                    isDropDisabled={true}
                    mode="virtual"
                    renderClone={(provided, snapshot, rubric) => {
                        var item = props.items[rubric.source.index];

                        return (
                            <div
                                {...provided.draggableProps}
                                {...provided.dragHandleProps}
                                ref={provided.innerRef}
                                className={classNames("draggable-list-item draggable-list-item-dragged", item.class)}
                                >
                                {item.name}
                            </div>
                        );
                    }}>
                    {(droppableProvided) => (
                        <div
                            ref={droppableProvided.innerRef}
                            style={{
                                width: "100%",
                                minHeight: "auto",
                            }}
                        >

                            <List
                                height={500}
                                itemCount={props.items.length}
                                itemSize={36}
                                width={210}
                                outerRef={droppableProvided.innerRef}
                                itemData={props.items}
                            >
                                {Row}

                            </List>

                        </div>

                    )}
                </Droppable>
            </div>
        </div>
    )
}

DraggableList.defaultProps = {
    loading: false,
    disabled: false,
    filterLabel: ""
};

DraggableList.propTypes = {
    id: PropTypes.string.isRequired,
    items: PropTypes.array.isRequired,
    loading: PropTypes.bool,
    disabled: PropTypes.bool,
    filterLabel: PropTypes.string,
    onFilterItems: PropTypes.func.isRequired
};
import React, { Fragment } from 'react';
import PropTypes from 'prop-types';

export function ShowHide(props) {

    function renderContent(content) {
        return content == null ? <Fragment /> : content;
    }

    return props.evaluator ? renderContent(props.show) : renderContent(props.hide);
}


ShowHide.propTypes = {
	evaluator: PropTypes.bool.isRequired,
	show: PropTypes.node,
	hide: PropTypes.node
};
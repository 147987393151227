import React, { Fragment, useRef } from 'react';
import { Icon, Position } from '@blueprintjs/core';
import PropTypes from 'prop-types';
import './Wizard.css';
import classNames from 'classnames';
import { ShowHide } from 'components/layout';
import { Tooltip } from 'components/tooltip';

export function WizardSteps(props){
	const dummyNumberOfSteps = 10;

	const wizardStepsRef = useRef(null);

	function createSteps(totalSteps) {
		let steps = [];
		let stepsBeforeAreComplete = true;
		
		for (let i = 0; i < totalSteps; i++) {
			const stepId = 'step-' + i.toString();
			let iconClass = '';
			let stepClass = 'wizard-step ';

			if (i === props.currentStep) {
				iconClass = 'dot';
				stepClass += 'wizard-step-current';
			} else if (!props.allowDirectStepNavigation && i < props.currentStep) {
				iconClass = 'small-tick';
				stepClass += 'wizard-step-complete wizard-step-highlight-line';
			} else if (props.allowDirectStepNavigation) {
				const stepStatus = getStepStatus(i);
				switch (stepStatus.toUpperCase()) {
					case 'COMPLETE':
						stepClass += 'wizard-step-complete';
						iconClass = 'small-tick';
						break;
					case 'COMPLETE-WITH-ERRORS':
						stepClass += 'wizard-step-errors';
						iconClass = 'small-cross';
						break;
					default:
						stepsBeforeAreComplete = false;
						break;
				}
			}

			if (stepsBeforeAreComplete && i > 0 && i <= props.currentStep) {
				steps[i - 1].stepClass += ' wizard-step-highlight-line';
			}

			steps.push({
				stepId: stepId,
				iconClass: iconClass,
				stepClass: stepClass,
			});
		}

		return steps.map((step, i) => (
			<div key={step.stepId} className={step.stepClass} onClick={() => props.onStepClick(i)}>

			<ShowHide
				evaluator={props.minimal && props.showStepTitles}
				show={(
					<Tooltip content={getStepTitle(i)} position={Position.BOTTOM}>
							<div>
								<Icon icon={step.iconClass} iconSize={16} />
							</div>
					</Tooltip>
				)}
					hide={(
					<Fragment>
						<div>
							<Icon icon={step.iconClass} iconSize={16} />
						</div>
						<ShowHide
							evaluator={props.showStepTitles && !props.minimal}
							show={<span className='wizard-step-title'></span>}
							/>
						</Fragment>
				)}
			/>
					</div>

		))	
	}

	function getStepTitle(stepIndex) {
		let stepTitle = '';

		if (props.additionalStepInformation && stepIndex < props.additionalStepInformation.length) {
			stepTitle = props.additionalStepInformation[stepIndex].stepTitle;
		}

		return stepTitle;
	}

	function getStepStatus(stepIndex) {
		let stepStatus = '';

		if (props.additionalStepInformation && stepIndex < props.additionalStepInformation.length) {
			stepStatus = props.additionalStepInformation[stepIndex].stepStatus;
		}

		return stepStatus
	}

	return (
		<div className='wizard-steps-container'>
			<div ref={wizardStepsRef} className={classNames('wizard-steps', { 'wizard-steps-minimal': props.minimal, 'wizard-steps-with-titles': props.showStepTitles, 'wizard-steps-with-navigation': props.allowDirectStepNavigation, 'bp3-skeleton': props.loading, 'wizard-complete': props.wizardComplete })}>
				<ShowHide
					evaluator={!props.loading || props.totalSteps > 0}
					show={createSteps(props.totalSteps)}
					hide={createSteps(dummyNumberOfSteps)}
				/>
			</div>
		</div>
	);
}

WizardSteps.propTypes = {
	currentStep: PropTypes.number.isRequired,
	totalSteps: PropTypes.number.isRequired,
	additionalStepInformation: PropTypes.array,
	showStepTitles: PropTypes.bool,
	onStepClick: PropTypes.func.isRequired,
	allowDirectStepNavigation: PropTypes.bool,
	loading: PropTypes.bool,
	minimal: PropTypes.bool,
	wizardComplete: PropTypes.bool
};

WizardSteps.defaultProps = {
	additionalStepInformation: [],
	showStepTitles: false,
	allowDirectStepNavigation: false,
	loading: false,
	minimal: false,
	wizardComplete: false
};
import React, { useState, useEffect, Fragment, useRef } from 'react';

import { Breadcrumbs, Tabs } from 'components/navigation';
import { LinkButton, Button } from 'components/buttons'
import { ListingTable } from "components/listing";
import { PageRestricted } from 'pages/errors/page-restricted';
import { AlertService, AssetService } from 'services';
import { ShowHide } from "components/layout";
import classNames from "classnames";
import { Tooltip } from 'components/tooltip';
import { Filter } from 'components/filtering';
import { useDispatch } from 'react-redux';
import { SetFiltering, SetRouting } from 'state/actions';
import { FormTextInput, FormLocationSelector } from "components/form-fields";
import { useParams } from 'react-router';
import { useDebounce } from "hooks/useDebounce";
import { DateToLocal } from "components/dates";
import { Intent, Position } from '@blueprintjs/core';
import axios from 'axios';
import { CircleStatus } from 'components/status';
import { LicenceCheckingService } from "services/LicenceCheckingService";
import { Modal, NotificationToaster } from "components/notifications";
import { useHistory } from 'react-router-dom';
import moment from "moment";
import { InstantReportButton } from 'components/reporting/InstantReportButton';
import { UnknownEmployeeModal } from 'pages/alert-type/alert/modal';
import { UserHasAccess } from "components/user-access";

export function AlertListing(props) {

    const queryParams = new URLSearchParams(window.location.search);
    const passedSearchTerm = queryParams.get("searchTerm") ?? "";
    const passedPageNo = parseInt(queryParams.get("pageNo") ?? "1");
    const passedAlertStatus = queryParams.get("alertStatus") ?? "Active";
    const defaultRecordSize = 25;
    const debounceTimeout = 750;
    const unknownEmployeeEditAlertAuthorised = UserHasAccess("TachoAlerts:Dismiss");
    const unknownEmployeeDismissAlertAuthorised = UserHasAccess("TachoAlerts:Dismiss");

    const [tableSortBy, setTableSortBy] = useState({ headerName: "Employee", sortName: "Entity.Employee.FullNameReversed" });
    const [tableSortDir, setTableSortDir] = useState("A");
    const [totalRecords, setTotalRecords] = useState(0);
    const [tableRows, setTableRows] = useState([]);
    const [searchTerm, setSearchTerm] = useState(passedSearchTerm);
    const [pageNumber, setPageNumber] = useState(1);
    const [paramDefinedPageNumber, setParamDefinedPageNumber] = useState(passedPageNo);
    const [pageSize, setPageSize] = useState(defaultRecordSize);
    const [loadingData, setLoadingData] = useState(true);
    const [showFilters, setShowFilters] = useState(false);
    const [appliedFilters, setAppliedFilters] = useState([]);
    const [pageFilters, setPageFilters] = useState([]);
    const [tableHeaders, setTableHeaders] = useState([]);
    const [sortableTableHeaders, setSortableTableHeaders] = useState([]);
    const [loadedFilters, setLoadedFilters] = useState(false);

    const defaultNoDataMessage = "No Alerts found.";
    let history = useHistory();
    const alertsStateName = "alerts";

    const dispatch = useDispatch();

    const [selectedLocations, setSelectedLocations] = useState([]);
    const [useHierarchy, setUseHierarchy] = useState(true);
    const locationListOnFilterOpen = useRef(selectedLocations);
    const inputSearchRef = useRef(null);

    const { alertTypeKey } = useParams();
    const debouncedSearchTerm = useDebounce(searchTerm, debounceTimeout);
    const [emptyListingMessage, setEmptyListingMessage] = useState(defaultNoDataMessage);
    const [validPermission, setValidPermission] = useState(true);
    const [alertType, setAlertType] = useState(null);
    const [statusTabs, setStatusTabs] = useState([
        { key: "ACTIVE", title: "Active", selected: passedAlertStatus === "Active" },
        { key: "DISMISSED", title: "Dismissed", selected: !(passedAlertStatus === "Active") },
    ]);
    const [currentStatusTab, setCurrentStatusTab] = useState(passedAlertStatus);
    const [enableStatusTabs, setEnableStatusTabs] = useState(false);
    const [generatingReportInstanceId, setGeneratingReportInstanceId] = useState();
    const [reportButtonDisabled, setReportButtonDisabled] = useState(false);
    const [reportDownloading, setReportDownloading] = useState(false);

    const [showAlertModal, setShowAlertModal] = useState(false);
    const [modalContents, setModalContents] = useState(null);
    const [modalTitle, setModalTitle] = useState("");
    const [isDismissibleButtonDisabled, setIsDismissibleButtonDisabled] = useState(false);

    function openModal(alert) {
        //Change to switch statement when there are 3 or more modals
        if (alertTypeKey === 'unknown-employee') {
            setModalContents(<UnknownEmployeeModal alert={alert} close={closeModal} save={saveModal} />)
        }

        setModalTitle(alertType.title);
        setShowAlertModal(true);
    }

    function closeModal() {
        setShowAlertModal(false);
    }

    function saveModal() {
        setLoadingData(true);
        closeModal()
    }

    useEffect(() => {
        axios.all([
            AlertService.getAlertTypes(true, false),
        ]).then(axios.spread(function (getAlertTypesReponse) {

            var selectedAlertType = getAlertTypesReponse.find(function (a) {
                return a.key === alertTypeKey;
            });

            if (selectedAlertType) {
                setAlertType(selectedAlertType);
            }

            AlertService.getAlertFilters(selectedAlertType.alertTypeId).then(alertFiltersEnabled => {

                let filterArray = [];

                switch (selectedAlertType.entityType.name.toLowerCase()) {
                    case 'employee':
                        //The ordering will be logical rather than alphabetical
                        let itemStates = [
                            { "id": "Active", "name": "Active" },
                            { "id": "Away", "name": "Away" }
                        ];

                        let itemTypes = [
                            { "id": "Core", "name": "Core" },
                            { "id": "Agency", "name": "Agency" }
                        ];

                        filterArray = [
                            {
                                "displayName": "Status", "name": "EntityStatus", "items": [
                                    ...itemStates
                                ]
                            },
                            {
                                "displayName": "Type", "name": "EntityType", "items": [
                                    ...itemTypes
                                ]
                            }
                        ];
                        break;
                    case 'asset':
                        let itemOwnership = [
                            { "id": "Owned", "name": "Owned" },
                            { "id": "Hired", "name": "Hired" },
                            { "id": "Loaned", "name": "Loaned" },
                            { "id": "Leased", "name": "Leased" },
                            { "id": "Sold", "name": "Sold" },
                            { "id": "Returned to lender", "name": "Returned to lender" }
                        ];

                        filterArray = [
                            {
                                "displayName": "Ownership", "name": "EntityOwnership", "items": [
                                    ...itemOwnership
                                ]
                            }
                        ];

                        break;
                    default:
                }

                let filters =
                    alertFiltersEnabled.filter(function (f) { return f.key.toString() !== 'Location'; })
                        .map(function (f) {
                            return {
                                name: f.key,
                                displayName: f.value,
                                value: f.value
                            }

                        });

                dispatch(SetFiltering(alertsStateName, filters));

                let locs =
                    alertFiltersEnabled.filter(function (f) { return f.key.toString() === 'Location'; })
                        .map(function (f) { return parseInt(f.value) });

                setSelectedLocations(locs);

                setLoadedFilters(true);

                setPageFilters(filterArray);
            });
        }));

    }, []);

    useEffect(() => {
        //If the filers are loaded we know the locations should be as well so we can check that there are some
        if (loadedFilters && (!selectedLocations || selectedLocations.length === 0)) {
            setTableRows([]);
            setTotalRecords(0);
            setLoadingData(false);
            return;
        }

        //This will trigger if the data changes, but will only process if the loadingData is set to true.
        if (loadingData
            && pageFilters.length > 0
            && selectedLocations != null
            && alertType != null
            && loadedFilters) {

            const alertRequest = {
                requestCount: pageSize,
                pageNumber: paramDefinedPageNumber ?? pageNumber,
                searchTerm: searchTerm,
                sortField: tableSortBy.sortName,
                sortDirection: tableSortDir,
                AlertTypeIds: [alertType.alertTypeId],
                filters: getAlertFiltersAsKvp()
            };

            AlertService.getAlerts(alertRequest).then(r => {

                let data = [];

                if (r?.data != null) {
                    data = r.data.map((d) => {
                        return [
                            ...mapRows(d),
                            ...mapAdditionalRows(d),
                            mapAdditionalLinks(d)
                        ];
                    })
                }

                setTableRows(data);
                setTotalRecords(r.totalCount);
                setLoadingData(false);

                if (inputSearchRef.current != null) {
                    setTimeout(function () {
                        inputSearchRef.current.focus();
                    });
                }

                //The setTotalRecords will set the page back to 1, so we have to do this here rather than earlier
                if (paramDefinedPageNumber) {
                    setPageNumber(paramDefinedPageNumber);
                    setParamDefinedPageNumber(null);
                }
            }, (error) => {
                const noAccessCode = 403;
                if (error.status === noAccessCode) {
                    setValidPermission(false);
                }
            });
        }

    }, [loadingData, pageSize, pageNumber, tableSortBy, tableSortDir, appliedFilters, selectedLocations, debouncedSearchTerm, alertType, pageFilters, loadedFilters]);

    useEffect(() => {
        mapHeaders();
    }, [alertType])

    useEffect(() => {
        setLoadingData(true);
        setPageNumber(1);
        setEmptyListingMessage("No results found, please try a different search term.");
    }, [debouncedSearchTerm]);

    useEffect(() => {
        setReportButtonDisabled(selectedLocations.length === 0 || reportDownloading);

    }, [selectedLocations, reportDownloading])

    function mapHeaders() {
        const headers = [];
        const sortableHeaders = [];

        if (alertType) {
            switch (alertType.entityType.name.toLowerCase()) {
                case 'employee':
                    headers.push("Employee");
                    headers.push("Reference");
                    headers.push("Location");

                    sortableHeaders.push({ headerName: "Employee", sortName: "Entity.Employee.FullNameReversed" });
                    sortableHeaders.push({ headerName: "Reference", sortName: "Entity.Employee.Reference" });
                    sortableHeaders.push({ headerName: "Location", sortName: "Location.Name" });
                    break;
            }

            switch (alertTypeKey) {
                case 'licence-bureau-low':
                case 'licence-bureau-high':
                case 'licence-bureau-critical':
                    headers.push('Due Date');
                    headers.push('Type');
                    headers.push('Information');

                    sortableHeaders.push({ headerName: "Due Date", sortName: "AlertData.DueDate" });
                    sortableHeaders.push({ headerName: "Type", sortName: "AlertData.Title" });
                    sortableHeaders.push({ headerName: "Information", sortName: "AlertData.Description" });
                    break;
                case 'walkaround-check-defects':
                    headers.push('Vehicle registration');
                    headers.push('Fleet Number')
                    headers.push('Asset Location')
                    headers.push('Employee');
                    headers.push('Completed date');
                    headers.push('Defect count');

                    setTableSortBy({ headerName: "Vehicle registration", sortName: "Entity.Asset.Registration" })
                    sortableHeaders.push({ headerName: "Employee", sortName: "AlertData.EmployeeFullName" });
                    sortableHeaders.push({ headerName: "Vehicle registration", sortName: "Entity.Asset.Registration" });
                    sortableHeaders.push({ headerName: "Completed date", sortName: "AlertData.EndDate" });
                    sortableHeaders.push({ headerName: "Defect count", sortName: "AlertData.DefectCount" });

                    break;
                case 'asset-registration-error':
                    headers.push('Vehicle registration');
                    headers.push('Original registration');
                    headers.push('Fleet Number')
                    headers.push('Update date');
                    headers.push('Location');

                    setTableSortBy({ headerName: "Update date", sortName: "EventTime" })

                    break;
                case 'awaiting-printout':
                    headers.push('Card number');
                    headers.push('Awaiting Data Date');
                    break;
                case 'unknown-employee':
                    headers.push('Vehicle registration');
                    headers.push('Vehicle Type');
                    headers.push('Fleet Number');
                    headers.push('Driver Name');
                    headers.push('Location');
                    headers.push('Shift Start');
                    headers.push('Shift End');

                    setTableSortBy({ headerName: "Vehicle registration", sortName: "Entity.Asset.Registration" })
                    sortableHeaders.push({ headerName: "Vehicle registration", sortName: "Entity.Asset.Registration" });
                    sortableHeaders.push({ headerName: "Vehicle Type", sortName: "Entity.Type.Name" });
                    sortableHeaders.push({ headerName: "Fleet Number", sortName: "Entity.Asset.FleetNumber" });
                    sortableHeaders.push({ headerName: "Driver Name", sortName: "AlertData.DriverFirstNames" });
                    sortableHeaders.push({ headerName: "Location", sortName: "Location.Name" });
                    sortableHeaders.push({ headerName: "Shift Start", sortName: "DataStartDate" });
                    sortableHeaders.push({ headerName: "Shift End", sortName: "DataEndDate" });
                    setEnableStatusTabs(true);
                    break;
                case 'clashing-data-new':
                    headers.push('Card Number');
                    headers.push('Shift Start');
                    headers.push('Shift End');
                    break;
            }

            if (alertType.isDismissable) {
                setEnableStatusTabs(true);
            }

            setTableHeaders(headers);
            setSortableTableHeaders(sortableHeaders);
        }
    }

    function mapRows(alert) {
        if (alertType.entityType.name.toLowerCase() === 'asset') {
            return [
                <Fragment key={alert.alertId}>
                    <div className="nowrap inline-items no-spacing">
                        <CircleStatus status={alert.entity.status?.name} tooltip={getStatusTooltip(alert.entity)} />
                        <span>{alert.entity.asset.registration}</span>
                    </div>
                </Fragment>,
            ];
        }

        return [
            <Fragment key={alert.alertId}>
                <div className="nowrap inline-items no-spacing">
                    <CircleStatus status={alert.entity.status?.name} hollow={alert.entity.type?.name?.toLowerCase() === "agency"} tooltip={getStatusTooltip(alert.entity)} />
                    <span>{alert.entity.employee.fullNameReversed}</span>
                </div>
            </Fragment>,
            alert.entity.employee.reference,
            alert.location.name,
        ];
    }

    function mapAdditionalLinks(alert) {
        const links = [];
        let activityDate;
        switch (alertType.entityType.name.toLowerCase()) {
            case 'employee':
                activityDate = moment(alert.externalKey).isValid() ? `&activityDate=${moment(alert.externalKey).format('YYYY-MM-DD')}` : '';
                if (alertTypeKey === 'clashing-data-new')
                    activityDate = moment(alert.dataStartDate).isValid() ? `&activityDate=${moment(alert.dataStartDate).format('YYYY-MM-DD')}` : '';

                let returnParams = `&pageNo=${pageNumber}&searchTerm=${searchTerm}&alertType=${alertTypeKey}`;
                if (enableStatusTabs)
                    returnParams = returnParams + `&alertStatus=${currentStatusTab}`;
                links.push(<Tooltip position={Position.TOP} content={`Employee Calendar`}>
                    <LinkButton
                        href={`${window.env.VISION_URL}/#/Drivers/RedirectToCalendar?driverId=${alert.entity.entityId}${activityDate}${returnParams}&openDayDetail=true`}
                        icon={"calendar"}
                        externalLink={true}
                        minimal={true}
                        iconOnly={true}
                        text="Calendar"
                        large={false}
                    />
                </Tooltip>);
                links.push(<Tooltip position={Position.TOP} content="Employee Profile">
                    <LinkButton
                        href={`${window.env.VISION_URL}/#/Drivers/RedirectToProfile?employeeId=${alert.entity.entityId}${returnParams}`}
                        icon={"person"}
                        externalLink={true}
                        minimal={true}
                        iconOnly={true}
                        text="Profile"
                        large={false}
                    />
                </Tooltip>);
                break;
            case 'asset':
                let assetReturnParams = `&pageNo=${pageNumber}&searchTerm=${searchTerm}&alertType=${alertTypeKey}`;
                if (enableStatusTabs) {
                    assetReturnParams = assetReturnParams + `&alertStatus=${currentStatusTab}`;
                }

                links.push(
                    <Tooltip position={Position.TOP} content="Asset Profile" key={`Asset-Profile-${alert.alertId}`}>
                        <LinkButton
                            href={`${window.env.VISION_URL}/#/Fleet/RedirectToProfile?assetId=${alert.entity.entityId}&tab=details${assetReturnParams}`}
                            icon={"truck"}
                            externalLink={true}
                            minimal={true}
                            iconOnly={true}
                            text="Profile"
                            large={false}
                        />
                    </Tooltip>);

                links.push(
                    <Tooltip position={Position.TOP} content="Asset Calendar" key={`Asset-Calendar-${alert.alertId}`}>
                        <LinkButton
                            href={`${window.env.VISION_URL}/#/Fleet/RedirectToCalendar?vehicleId=${alert.entity.entityId}&tab=details${assetReturnParams}&activityDate=${alert.dataStartDate}&openVehicleDayDetail`}
                            icon={"calendar"}
                            externalLink={true}
                            minimal={true}
                            iconOnly={true}
                            text="Calendar"
                            large={false}
                        />
                    </Tooltip>);
                break;
        }

        switch (alertTypeKey) {
            case 'licence-bureau-low':
            case 'licence-bureau-high':
            case 'licence-bureau-critical':
                links.push(<Tooltip content="View in Licence Bureau" key={`Link-LB-${alert.alertId}`}>
                    <Button
                        icon={"link"}
                        minimal={true}
                        iconOnly={true}
                        large={false}
                        onClick={() => navigateToLicenceBureau(alert.alertData.organisationId, alert.externalKey)}
                    />
                </Tooltip>);
                break;
            case 'walkaround-check-defects':
                links.push(<Tooltip content="View walkaround check report" key={`Link-To-Defect-${alert.alertId}`}>
                    <Button
                        icon={"link"}
                        minimal={true}
                        iconOnly={true}
                        large={false}
                        onClick={() => navigateToWalkaroundDetails(alert.externalKey)}
                    />
                </Tooltip>);
                break;
        }

        if (alertType.useModal && currentStatusTab === 'Active' && (unknownEmployeeEditAlertAuthorised || alertTypeKey !== 'unknown-employee')) {
            links.push(<Tooltip content={alertType.modalActionText} key={`Edit-${alert.alertId}`}>
                <Button
                    large={false}
                    minimal={true}
                    iconOnly={true}
                    icon={'edit'}
                    id={alert.alertId.toString()}
                    onClick={() => openModal(alert)}
                />
            </Tooltip>);
        }

        if (alertType.isDismissable && (unknownEmployeeDismissAlertAuthorised || alertTypeKey !== 'unknown-employee')) {
            links.push(<Tooltip content={currentStatusTab === 'Active' ? "Dismiss Alert" : "Reactivate Alert"} key={`Dismiss-Tooltip-${alert.alertId}`}>
                <Button
                    large={false}
                    minimal={true}
                    iconOnly={true}
                    icon={currentStatusTab === 'Active' ? "remove" : "reset"}
                    id={`Dismiss-${alert.alertId}`}
                    onClick={() => onStatusUpdateButtonClick(alert, currentStatusTab)}
                    disabled={isDismissibleButtonDisabled}
                />
            </Tooltip>);
        }

        if (links.length > 0) {
            return <div className="nowrap inline-items no-spacing">
                {links}
            </div>
        }

        return <Fragment />
    }

    function formatDriverName(driverFirstNames, driverLastName) {
        if (!driverFirstNames && !driverLastName) {
            return "-";
        }

        return `${!driverFirstNames ? "" : driverFirstNames} ${!driverLastName ? "" : driverLastName}`;
    }

    function mapAdditionalRows(alert) {
        switch (alertTypeKey) {
            case 'licence-bureau-low':
            case 'licence-bureau-high':
            case 'licence-bureau-critical':
                return [
                    <DateToLocal format='DD/MM/YYYY'>{alert.alertData.dueDate}</DateToLocal>,
                    alert.alertData.title,
                    alert.alertData.description
                ];
            case 'awaiting-printout':
                return [
                    alert.entity.employee?.cardNumber,
                    <DateToLocal format='DD/MM/YYYY' key={alert.alertId}>{alert.externalKey}</DateToLocal>
                ];
            case 'clashing-data-new':
                return [
                    alert.entity.employee?.cardNumber === undefined ? "-" : alert.entity.employee?.cardNumber,
                    <DateToLocal key={alert.alertId - alert.dataStartDate}>{alert.dataStartDate}</DateToLocal>,
                    <DateToLocal key={alert.alertId - alert.dataEndDate}>{alert.dataEndDate}</DateToLocal>
                ]
            case 'walkaround-check-defects':
                return [
                    alert.entity.asset.fleetNumber === "" ? "-" : alert.entity.asset.fleetNumber,
                    alert.location.name,
                    alert.alertData.employeeFullName,
                    <DateToLocal key={alert.externalKey} format='DD/MM/YYYY'>{alert.alertData.endDate}</DateToLocal>,
                    alert.alertData.defectCount,
                ];
            case 'asset-registration-error':
                return [
                    alert.alertData.assetRegistration,
                    alert.entity.asset.fleetNumber === "" ? "-" : alert.entity.asset.fleetNumber,
                    <DateToLocal format='DD/MM/YYYY' key={alert.alertId}>{alert.eventTime}</DateToLocal>,
                    alert.location.name,
                ];
            case 'unknown-employee':
                return [
                    alert.entity.type.name,
                    alert.entity.asset.fleetNumber === "" ? "-" : alert.entity.asset.fleetNumber,
                    formatDriverName(alert.alertData.driverFirstNames, alert.alertData.driverLastName),
                    alert.location.name,
                    <DateToLocal key={"ShiftStartTable - " + alert.alertId}>{alert.dataStartDate}</DateToLocal>,
                    <DateToLocal key={"ShiftEndTable - " + alert.alertId}>{alert.dataEndDate}</DateToLocal>,
                ];
        }
        return [];
    }

    function getStatusTooltip(entity) {
        if (entity.asset?.registration) {
            return `${entity.status?.name} ${entity.asset?.ownership?.name} ${entity.type?.name?.toLowerCase()}`;
        }

        return `${entity.status?.name} ${entity.type?.name?.toLowerCase()} employee`;
    }

    function onTableSort(header, direction) {
        setTableSortBy(header);
        setTableSortDir(direction);
        setLoadingData(true);
    }

    function onPagingChange(newPageNumber, newPageSize) {
        setPageNumber(newPageNumber);
        setPageSize(newPageSize);
        setLoadingData(true);
    }

    function onFilterChange(listingFilters) {
        setLoadingData(true);
        setPageNumber(1);
        setEmptyListingMessage("No results found, please try a different filter.");
        setAppliedFilters(listingFilters);

        if (listingFilters.length > appliedFilters.length) {
            setShowFilters(true);
        }
    }

    function onToggleFilter() {
        setShowFilters((prevState) => {
            return !prevState;
        })
    }

    function onSearchChange(item) {
        setSearchTerm(item.target.value);
    }

    function onLocationListOpened() {
        locationListOnFilterOpen.current = selectedLocations;
    }

    function onLocationListClose() {
        if (locationListOnFilterOpen.current.toString() !== selectedLocations.toString()) {
            setLoadingData(true);
        }
    }

    function navigateToLicenceBureau(orgId, taskId) {
        LicenceCheckingService.navigateToLicenceBureau(orgId,
            taskId,
            () => NotificationToaster.show(Intent.DANGER, "Unable to load the link to the licence checking provider. Please try again."))
    }

    function onStatusUpdateButtonClick(alert, currentStatus) {
        setIsDismissibleButtonDisabled(true);

        let newStatusMap = {
            Active: 2, Dismissed: 1
        }

        if (alertTypeKey === 'unknown-employee' && newStatusMap[currentStatus] === 2) {
            AssetService.assignJourney(alert.entity.entityId, 0, alert.dataStartDate, alert.dataEndDate).then(
                () => AlertService.saveAlertStatus(alert.alertId, newStatusMap[currentStatus], alert.comment).then(
                    () => {
                        setIsDismissibleButtonDisabled(false);
                        NotificationToaster.show(Intent.SUCCESS, "Journey has been successfully processed.");
                        setLoadingData(true);
                    },
                    () => {
                        setIsDismissibleButtonDisabled(false);
                        NotificationToaster.show(Intent.DANGER, "Unable to dismiss notification. Please try again.");
                    }),
                () => {
                    setIsDismissibleButtonDisabled(false);
                    NotificationToaster.show(Intent.DANGER, "Unable to assign a journey. Please try again.");
                }
            );
        }
        else if (alertTypeKey === 'unknown-employee' && newStatusMap[currentStatus] === 1) {
            AssetService.deleteJourney(alert.entity.entityId, 0, alert.dataStartDate, alert.dataEndDate).then(
                () => AlertService.saveAlertStatus(alert.alertId, newStatusMap[currentStatus], alert.comment).then(
                    () => {
                        setIsDismissibleButtonDisabled(false);
                        NotificationToaster.show(Intent.SUCCESS, "Journey has been successfully processed.");
                        setLoadingData(true);
                    },
                    () => {
                        setIsDismissibleButtonDisabled(false);
                        NotificationToaster.show(Intent.DANGER, "Unable to dismiss notification. Please try again.");

                    }),
                () => {
                    setIsDismissibleButtonDisabled(false);
                    NotificationToaster.show(Intent.DANGER, "Unable to delete a journey. Please try again.");
                }
            );
        }
        else {
            AlertService.saveAlertStatus(alert.alertId, newStatusMap[currentStatus], alert.comment).then(
                () => {
                    setIsDismissibleButtonDisabled(false);
                    setLoadingData(true);
                },
                () => {
                    setIsDismissibleButtonDisabled(false);
                    NotificationToaster.show(Intent.DANGER, "Unable to dismiss notification. Please try again.");
                });
        }
    }

    function onTabClick(index) {

        var clonedTabs = [
            ...statusTabs
        ];

        clonedTabs = clonedTabs.map(function (t, i) {
            return {
                ...t,
                selected: index === i
            }
        });
        setPageNumber(1);
        setStatusTabs(clonedTabs);
        setCurrentStatusTab(getTabNameFromIndex(index));
        setLoadingData(true);
    }

    function getTabNameFromIndex(index) {
        let tabNameMap = {
            0: 'Active', 1: 'Dismissed'
        }
        return tabNameMap[index];
    }

    function getAlertStatus() {
        return {
            key: 'AlertStatus',
            value: currentStatusTab
        };
    }

    function navigateToWalkaroundDetails(walkaroundCheckId) {
        dispatch(SetRouting('alert list', 'alert/walkaround-check-defects', null));
        history.push(`/walkaround-checks/${walkaroundCheckId}`);
    }

    function placeholderValue() {
        switch (alertType?.entityType.name.toLowerCase()) {
            case 'asset':
                return "Registration";
            default:
                return "Name or reference search";
        }
    }

    function getAlertFiltersAsKvp() {
        let filtersArray = appliedFilters.map(function (f) {
            return {
                key: f.name,
                ...f
            }
        }).concat(getAlertStatus());

        //Make sure any missing filter types are replaced with 'all' of that type
        //i.e. if none are selected, do not filter:
        pageFilters
            .filter(f => {
                return filtersArray.every(existing => {
                    return existing.name !== f.name
                });
            })
            .map(f => {
                return f.name
            })
            .forEach(neededFilter => {
                const filtersToAdd = pageFilters
                    .filter(o => {
                        return o.name === neededFilter
                    })
                    .map(f => {
                        return f.items.map(q => {
                            return {
                                key: f.name,
                                value: q.name
                            }
                        })
                    });

                filtersToAdd[0].forEach(element => {
                    filtersArray.push(element);
                });
            });

        selectedLocations.forEach(function (l) {
            filtersArray = [...filtersArray,
            { "key": "Location", "value": l }
            ];
        });

        return filtersArray;
    }

    function onReportGenerate() {
        setReportDownloading(true);
        const alertRequest = {
            searchTerm: searchTerm,
            sortField: tableSortBy.sortName,
            sortDirection: tableSortDir,
            AlertTypeId: alertType.alertTypeId,
            filters: getAlertFiltersAsKvp()
        };

        AlertService.postReportRequest(alertRequest).then((result) => {
            setGeneratingReportInstanceId(result);
        }, (error) => {
            NotificationToaster.show(Intent.DANGER, error)
            setGeneratingReportInstanceId(null);
            setReportDownloading(false);
        })
    }

    function onReportDownloadComplete() {
        setGeneratingReportInstanceId(null);
        setReportDownloading(false);
    }

    return (
        <Fragment>
            <ShowHide
                evaluator={validPermission}
                show={(
                    <div className="row">

                        <Breadcrumbs items={props.breadcrumbs} />

                        <h1>Alerts {alertType !== null ? '- ' + alertType.title : ''}</h1>

                        <ShowHide
                            evaluator={!loadingData && tableRows.length === 0 && pageFilters.length === 0}
                            hide={(
                                <Fragment>
                                    <div className={classNames({ "spacer-bottom": !showFilters })}>
                                        <div className="inline-items">
                                            <FormLocationSelector
                                                businessArea={alertType?.locationBusinessArea?.name == null ? '' : alertType?.locationBusinessArea?.name}
                                                loading={loadingData}
                                                onLocationListClose={onLocationListClose}
                                                onLocationListOpened={onLocationListOpened}
                                                selectedLocations={selectedLocations}
                                                setSelectedLocations={setSelectedLocations}
                                                useLocationDefaults={false}
                                                useHierarchy={useHierarchy}
                                                setUseHierarchy={setUseHierarchy}
                                                locationDefaultsToTrue={loadedFilters ? true : null}
                                            />
                                            <FormTextInput inputRef={inputSearchRef} placeholder={placeholderValue()} onChange={onSearchChange} value={searchTerm} large disabled={loadingData} icon="search" id="alert-search-field" />

                                            <Tooltip content="Filter" position="right">
                                                <Button icon="filter" onClick={onToggleFilter} className={classNames({ "active": appliedFilters.length > 0, "hidden": pageFilters.length === 0 })} />
                                            </Tooltip>
                                            <ShowHide evaluator={(alertType?.reportName !== null && alertType?.reportName?.length > 0)}
                                                show={
                                                    <Tooltip content="Download current view to Excel spreadsheet" position="right">
                                                        <InstantReportButton
                                                            onReportGenerate={onReportGenerate}
                                                            onReportDownloadComplete={onReportDownloadComplete}
                                                            id="downloadAlerts"
                                                            text="Download report"
                                                            reportId={generatingReportInstanceId}
                                                            buttonDisabled={reportButtonDisabled || loadingData}
                                                        />
                                                    </Tooltip>
                                                }
                                            />
                                        </div>
                                    </div>
                                    <Filter filterName={alertsStateName} visible={showFilters} filters={pageFilters} onUpdate={onFilterChange} />
                                </Fragment>
                            )}
                        />
                        <div className={classNames("pull-left", { "spacer-bottom": !showFilters })}>
                            <ShowHide
                                evaluator={!loadingData && enableStatusTabs}
                                show={
                                    <div className="spacer-bottom">
                                        <Tabs tabs={statusTabs} onClick={onTabClick} loading={loadingData} />
                                    </div>
                                }
                            />
                        </div>
                        <ListingTable
                            id="listing-table-alerts"
                            headers={tableHeaders}
                            data={tableRows}
                            totalRecordCount={totalRecords}
                            onPagingChange={onPagingChange}
                            loadingData={loadingData}
                            noDataMessage={emptyListingMessage}
                            sortable
                            sortableHeaders={sortableTableHeaders}
                            sortedBy={tableSortBy}
                            sortedDir={tableSortDir}
                            onSort={onTableSort}
                            searchTerm={""}
                            startPage={pageNumber}
                        />
                    </div>
                )}
            />

            <Modal
                isOpen={showAlertModal}
                clickOutsideClose={false}
                onClose={() => setShowAlertModal(false)}
                title={modalTitle}
                icon="warning-sign"
                intent={Intent.DANGER}
                confirmButtonText="Dismiss"
                onConfirm={() => setShowAlertModal(false)}
            >
                {modalContents}
            </Modal>

            <ShowHide
                evaluator={!validPermission}
                show={(
                    <PageRestricted />
                )}
            />
        </Fragment>
    );
}
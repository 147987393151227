import { Intent } from "@blueprintjs/core";
import axios from "axios";
import classNames from "classnames";
import { LinkButton } from "components/buttons";
import { Breadcrumbs, VerticalLinkGroup } from "components/navigation";
import { NotificationToaster } from "components/notifications";
import { CircleStatus, StatusBlock } from "components/status";
import { UserAccess, UserHasAccess } from "components/user-access";
import { PageRestricted } from "pages/errors/page-restricted";
import React, { Fragment, useEffect, useState } from "react";
import { Redirect, Route, Switch, useParams } from "react-router-dom";
import { AlertService, EmployeeService } from "services";
import { EmployeeLicenceChecking } from "pages/employee/edit/licence-checking/EmployeeLicenceChecking";
import { ShowHide } from "components/layout";
import { LicenceCheckingService } from "services/LicenceCheckingService";
import { DefectReporting } from "pages/walkaround-check/shared/DefectReporting";

export function Employee(props) {
    const { id } = useParams();
    const alertTypeKeys = [
        "licence-bureau-low",
        "licence-bureau-high",
        "licence-bureau-critical"
    ];
    const noLicenceCheckCode = 404;

    const [loadingData, setLoadingData] = useState(true);
    const [employee, setEmployee] = useState(null);
    const [employeeOptions, setEmployeeOptions] = useState(null);

    const [loadingLicenceBureau, setLoadingLicenceBureau] = useState(false);
    const [licenceBureauAlertTypes, setLicenceBureauAlertTypes] = useState([]);
    const [licenceBureauAlertStatus, setLicenceBureauAlertStatus] = useState(null);
    const [licenceCheck, setLicenceCheck] = useState(null);
    const [licenceCheckError, setLicenceCheckError] = useState(null);
    const [licenceLink, setLicenceLink] = useState(null);

    const requiredActions = ["Employees:View", "AuthorisedDriver"];
    const licenceBureauAction = 'LicenceCheck:LicenceBureau:View';
    const canAccessPage = UserHasAccess(requiredActions);
    const canAccessLicenceBureau = UserHasAccess(licenceBureauAction);

    const allRoutes = [
        { location: `${window.env.VISION_URL}/#/Drivers/RedirectToProfile?employeeId=${id}`, title: "Details", externalLink: true },
        { location: `${window.env.VISION_URL}/#/Drivers/RedirectToProfile?employeeId=${id}&tab=tab-performance`, title: "Performance", externalLink: true, requiredAction: "Performance:View" },
        { location: `${window.env.VISION_URL}/#/Drivers/RedirectToProfile?employeeId=${id}&tab=tab-debrief-archive`, title: "Debrief Archive", externalLink: true, requiredAction: "Debrief:View" },
        { location: `/employee/${id}/licence-checking`, title: "Licence Checking", requiredAction: licenceBureauAction },
        { location: `${window.env.VISION_URL}/#/Drivers/RedirectToProfile?employeeId=${id}&tab=tab-employee-actions`, title: "Actions", externalLink: true, requiredAction: "EmployeeDeclaration:View" },
        { location: `${window.env.VISION_URL}/#/Drivers/RedirectToProfile?employeeId=${id}&tab=tab-employee-assessments`, title: "Assessments", externalLink: true, requiredAction: "EmployeeAssessment:View" },
        { location: `${window.env.VISION_URL}/#/Drivers/RedirectToProfile?employeeId=${id}&tab=tab-safety`, title: "Collisions", externalLink: true, requiredAction: "Collisions:View" },
        { location: `/employee/${id}/walkaround-checks`, title: "Walkaround Checks", requiredAction: "WalkaroundCheck:View" },
        { location: `${window.env.VISION_URL}/#/Drivers/RedirectToProfile?employeeId=${id}&tab=tab-audit-history`, title: "History", externalLink: true },
    ];

    const routesForUser = allRoutes.filter(x => x.requiredAction ? UserHasAccess(x.requiredAction) : true);

    useEffect(() => {
        if (!canAccessPage) {
            return;
        }

        setLoadingData(true);

        axios.all([
            EmployeeService.getEmployee(id),
            EmployeeService.getEmployeeOptions(),
        ]).then(axios.spread(function (employeeResponse, employeeOptionsResponse) {
            setEmployee(employeeResponse);
            setEmployeeOptions(employeeOptionsResponse);
            setLoadingData(false);
        }),
            () => NotificationToaster.show(Intent.DANGER, "Could not load employee. Please try again."));

        if (canAccessLicenceBureau) {
            setLoadingLicenceBureau(true);
            AlertService.getAlertTypes(false, false).then((response) => {
                const newAlertTypes = response.filter(x => alertTypeKeys.includes(x.key))
                    .map(x => {
                        return {
                            id: x.alertTypeId,
                            key: x.key
                        };
                    });

                setLicenceBureauAlertTypes(newAlertTypes);
            }, () => {
                NotificationToaster.show(Intent.DANGER, "Could not load licence bureau alerts. Please try again.");
                setLoadingLicenceBureau(false);
            });

            LicenceCheckingService.getLicenceCheck(id)
                .then(response => {
                    setLicenceCheck(response);
                }, (error) => {
                    setLoadingLicenceBureau(false);
                    setLicenceCheckError(error.response.status);
                });
        }
    }, [id]);

    useEffect(() => {
        if (licenceBureauAlertTypes.length > 0 && licenceCheck) {
            AlertService.getEntityBreakdown([id], licenceBureauAlertTypes.map(x => x.id)).then((response) => {
                const lowAlertCount = getCountForAlertType("licence-bureau-low", response);
                const highAlertCount = getCountForAlertType("licence-bureau-high", response);
                const criticalAlertCount = getCountForAlertType("licence-bureau-critical", response);

                const alertCounts = {
                    counts: [],
                    color: '00C6B2',
                    tooltip: null
                };

                if (criticalAlertCount > 0) {
                    alertCounts.counts.push(`${criticalAlertCount} critical alerts`);
                    alertCounts.color = 'EC2D6D';
                }

                if (highAlertCount > 0) {
                    alertCounts.color = alertCounts.counts.length === 0 ? 'FF7454' : alertCounts.color;
                    alertCounts.counts.push(`${highAlertCount} high alerts`);
                }

                if (lowAlertCount > 0) {
                    alertCounts.color = alertCounts.counts.length === 0 ? 'FFC107' : alertCounts.color;
                    alertCounts.counts.push(`${lowAlertCount} medium/low alerts`);
                }

                if (alertCounts.counts.length > 0) {
                    alertCounts.tooltip = <div>
                        <p>There are the following licence bureau alerts for this employee:</p>
                        <ul>
                            {alertCounts.counts.map(x => <li key={x}>- {x}</li>)}
                        </ul>
                    </div>;
                } else {
                    alertCounts.tooltip = <span>There are no licence bureau alerts for the employee.</span>;
                }

                setLicenceBureauAlertStatus(alertCounts);
                setLoadingLicenceBureau(false);
            }, () => {
                NotificationToaster.show(Intent.DANGER, "Could not load licence bureau alerts. Please try again.");
                setLoadingLicenceBureau(false);
            });
        } else if (licenceCheckError) {
            setLicenceBureauAlertStatus({
                color: '5C7080',
                tooltip: licenceCheckError === noLicenceCheckCode ?
                    <span>No matching employee record found in Licence Bureau.</span>
                    : <span>The licence checking service is currently unavailable. Please try again.</span>
            });
        }
    }, [licenceBureauAlertTypes, licenceCheck, licenceCheckError]);

    useEffect(() => {
        if (licenceCheck && !licenceLink) {
            LicenceCheckingService.getUser(licenceCheck.organisationId)
                .then((user) => {
                    setLicenceLink(user.signInUrl);
                }, () => {
                    NotificationToaster.show(Intent.DANGER, "Unable to load the link to the licence checking provider. Please try again.");
                });
        }
    }, [licenceCheck])

    function getCountForAlertType(alertTypeKey, breakdown) {
        const alertTypeId = licenceBureauAlertTypes.find(x => x.key === alertTypeKey).id;

        return breakdown ? breakdown[0].alertTypeBreakdown.find(x => x.alertTypeId === alertTypeId).alertCount : 0;
    }

    function isAgencyEmployee() {
        return employee?.employeeLocation?.type?.name?.toLowerCase() === 'agency';
    }

    function getStatusText() {
        if (employee) {
            return isAgencyEmployee() ? `${employee.employeeLocation.status.name} - ${employee.employeeLocation.type.name}` : employee.employeeLocation.status.name;
        }
        return '';
    }

    return <UserAccess perform={requiredActions}
        yes={() => (
            <Fragment>
                <div className="row">

                    <Breadcrumbs items={props.breadcrumbs} />

                    <div className={classNames({ "bp3-skeleton": loadingData }, 'inline-items', 'space-large', 'spacer-bottom')}>
                        <h1>{employee?.fullName}</h1>
                        <StatusBlock
                            status={employee?.employeeLocation?.status?.name}
                            hollow={isAgencyEmployee()}
                            bold={false}
                        >
                            {getStatusText()}
                        </StatusBlock>
                        <ShowHide
                            evaluator={canAccessLicenceBureau}
                            show={(
                                <CircleStatus
                                    background={licenceBureauAlertStatus?.color}
                                    tooltip={licenceBureauAlertStatus?.tooltip}
                                    iconClass={"fa fa-list-alt"}
                                    loading={loadingLicenceBureau}
                                />
                            )}
                        />
                    </div>
                    <div className="button-row spacer-bottom">
                        <LinkButton
                            href={`${window.env.VISION_URL}/#/Drivers/RedirectToCalendar?driverId=${id}&`}
                            text={"Calendar"}
                            loading={loadingData}
                            icon={"calendar"}
                            externalLink={true}
                            intent={Intent.PRIMARY}
                        />
                        <UserAccess
                            perform={'Debrief:Perform'}
                            yes={() => <LinkButton
                                href={`${window.env.VISION_URL}/#/Drivers/RedirectToProfile?employeeId=${id}&openDebrief`}
                                text={"Debrief"}
                                loading={loadingData}
                                icon={"person"}
                                externalLink={true}
                                intent={Intent.PRIMARY}
                            />}
                        />
                        <ShowHide
                            evaluator={canAccessLicenceBureau && licenceCheck}
                            show={<LinkButton
                                href={`${licenceLink}/users/${licenceCheck?.userId}`}
                                text={"Licence Bureau"}
                                loading={!licenceLink}
                                externalLink={true}
                                intent={Intent.PRIMARY}
                                openInNewTab={true}
                                icon="share"
                            />}
                        />
                    </div>
                </div>

                <div className="layout-cols">
                    <div className="col side-col">
                        <div className="row">
                            <VerticalLinkGroup links={routesForUser} />
                        </div>
                    </div>
                    <div className="col content-col">
                        <Switch>
                            <Redirect exact from="/employee/:id(\d+)" to="/employee/:id(\d+)/licence-checking" />
                            <Route path="/employee/:id(\d+)/licence-checking">
                                <EmployeeLicenceChecking
                                    employeeId={id}
                                    employeeOptions={employeeOptions}
                                    licenceBureauAlertTypes={licenceBureauAlertTypes}
                                    licenceCheck={licenceCheck}
                                    errorCode={licenceCheckError}
                                    loading={loadingLicenceBureau}
                                />
                            </Route>
                            <Route path="/employee/:id(\d+)/walkaround-checks">
                                <DefectReporting
                                    id={id} route={"employee"} sortName={"assetRegistration"} headerName={"Vehicle Registration"}
                                />
                            </Route>
                        </Switch>
                    </div>
                </div>
            </Fragment>
        )}
        no={() => (
            <PageRestricted />
        )}
    />;
}
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Position, Tooltip } from "@blueprintjs/core";
import './Status.css';
import classNames from 'classnames';
import { ShowHide } from 'components/layout';

export function CircleStatus(props) {
    const [backgroundColor, setBackgroundColor] = useState('00C6B2');

    useEffect(() => {
        let newBackgroundColor = '00C6B2';

        if (props.background) {
            newBackgroundColor = props.background;
        } else if (props.status?.toLowerCase() === 'away' || props.status?.toLowerCase() === 'vehicle off road') {
            newBackgroundColor = 'FF7454';
        } else if (props.status?.toLowerCase() === 'inactive') {
            newBackgroundColor = 'EC2D6D';
        }

        setBackgroundColor(newBackgroundColor);
    }, [props.background, props.status]);

    return (
        <Tooltip position={Position.TOP} content={props.tooltip}>
            <div style={{ backgroundColor: props.hollow ? 'transparent' : `#${backgroundColor}`, borderColor: `#${backgroundColor}` }} className={classNames({ "bp3-skeleton": props.loading }, { "hollow": props.hollow }, "circle-status", "inline-items")}>
                <ShowHide
                    evaluator={!!props.iconClass}
                    show={<i className={props.iconClass} />}
                />
            </div>
        </Tooltip>
    );
}

CircleStatus.defaultProps = {
    background: '',
    status: '',
    iconClass: '',
    tooltip: '',
    loading: false,
    hollow: false,
    small: false
};

CircleStatus.propTypes = {
    background: PropTypes.string,
    status: PropTypes.string,
    iconClass: PropTypes.string,
    tooltip: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.node
    ]),
    loading: PropTypes.bool,
    hollow: PropTypes.bool,
    small: PropTypes.bool
};
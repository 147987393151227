import React, { Component } from 'react';

export class PageRestricted extends Component {

	render() {
		return (
			<h1>You do not have access to view this page</h1>
		);
	}

}
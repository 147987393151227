import React, { Fragment, useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';

import { UserAccess } from 'components/user-access';
import { Tabs } from 'components/navigation';
import { ShowHide } from "components/layout";
import { PageRestricted } from 'pages/errors/page-restricted';
import { FormFileInput } from "components/form-fields";
import { AssetService } from "services";
import { Intent } from '@blueprintjs/core';
import { ListingTable } from 'components/listing';
import { NotificationToaster } from "components/notifications";
import { Button } from 'components/buttons';
import cardConfig from '../vehicle-unit/card-config/cardconfig.json';

export function VuCardViewer(props) {

    const requiredAction = "Internal";
    const dispatch = useDispatch();

    const defaultTableHeaders = ["Description", "Value"];
    const [fileText, setFileText] = useState('Choose file')
    const [fileCracked, setFileCracked] = useState(false);
    const [loadingData, setLoadingData] = useState(true);
    const [tabs, setTabs] = useState([]);
    const [selectedTab, setSelectedTab] = useState("vehicleIdentification");
    const [vuData, setVuData] = useState([]);
    const [vuFileDataTable, setVuFileDataTable] = useState([]);
    const [tableHeader, setTableHeader] = useState([]);
    const [pageableTab, setPageableTab] = useState(false);

    const defaultRecordSize = 25;
    const twoColumns = 2;
    const [pageNumber, setPageNumber] = useState(1);
    const [pageSize, setPageSize] = useState(defaultRecordSize);
    const [totalRecords, setTotalRecords] = useState(0);
    const [hashHeaders, setHashHeaders] = useState({});
    const [dateFields, setDateFields] = useState([]);

    useEffect(function () {
        dispatch({ type: 'SITE_FULL_WIDTH' });
    }, []);

    function onFileChange(event) {

        setFileCracked(false);
        var file = event.currentTarget.files[0];
        var fileName = (event.currentTarget.files.length === 0) ? "" : file.name;

        const reader = new FileReader();
        reader.onloadend = () => {

            const base64String = reader.result
                .replace('data:', '')
                .replace(/^.+,/, '');

            var vuFile = {
                fileName: fileName,
                base64String: base64String
            };

            AssetService.crackVUCard(vuFile).then(r => {
                setFileCracked(true);
                setVuData(r.data);
            }, (error) => {
                NotificationToaster.show(Intent.WARNING, "Could not crack the file.");
            });
        };
        reader.readAsDataURL(file);

        setFileText(fileName);
        event.target.value = null;
        setLoadingData(false);
    }

    useEffect(parseConfig, [cardConfig]);

    function parseConfig() {
        if (cardConfig) {
            setConfig(cardConfig);
        }
    }

    function setConfig(config) {
        setTabs(config.tabs);
        setHashHeaders(config.headers);
        setDateFields(config.dateFields);
    }

    function onTabClick(index) {
        if (tabs.length === 0) {
            return;
        }

        setLoadingData(true);
        var clonedTabs = [
            ...tabs
        ];

        clonedTabs = clonedTabs.map(function (t, i) {
            return {
                ...t,
                selected: index === i
            }
        });

        setTabs(clonedTabs);
        setSelectedTab(tabs[index].key);
        setPageableTab('activityDailyData' === tabs[index].key);
        if (hashHeaders[tabs[index].key]) {
            setTableHeader(hashHeaders[tabs[index].key]);
        }
        else {
            setTableHeader(defaultTableHeaders);
        }
    }

    function isDefaultHeader(header) {
        return header && header.length === twoColumns && header[0] === 'Description' && header[1] === 'Value';
    }

    function writeProperiesToArray(array, dataObject) {
        var data2 = [];
        var i = 0;
        for (var property in dataObject) {
            var value = dataObject[property];
            value = formatOutput(property, value);
            data2[i] = value;
            i++;
        }
        array.push(data2)
    }

    function formatOutput(property, value) {
        if (dateFields.indexOf(property) > -1) {
            value = value.replace(' 00:00:00', '');
        }
        return value;
    }

    function onPagingChange(newPageNumber, newPageSize) {
        setPageNumber(newPageNumber);
        setPageSize(newPageSize);
    }

    function onNewFile() {
        setFileCracked(false);
        setFileText('Choose file');
    }

    useEffect(() => {
        if (!selectedTab || !vuData || vuData.length === 0 || !tableHeader || tableHeader.length === 0) {
            return;
        }
        var selectedData = vuData[selectedTab];

        var data = [];

        if (isDefaultHeader(tableHeader)) {
            for (var property in selectedData) {
                var value = formatOutput(property, selectedData[property]);
                data.push([property, value])
            }
            setTotalRecords(data.length);
        }
        else {
            if (Array.isArray(selectedData)) {
                setTotalRecords(selectedData.length);
                var start = pageableTab ? (pageNumber - 1) * pageSize : 0;
                var length = pageableTab ? pageSize : selectedData.length;
                for (var i = start; i < length + start; i++) {
                    writeProperiesToArray(data, selectedData[i]);
                }
            }
            else {
                writeProperiesToArray(data, selectedData);
            }
        }

        setVuFileDataTable(data);
        setLoadingData(false);

    }, [selectedTab, vuData, tableHeader, pageableTab, pageNumber, pageSize]);

    useEffect(function () {
        onTabClick(0);
    }, [vuData]);

    return (

        <UserAccess perform={requiredAction}
            yes={() => (
                <div>
                    <div className="row">
                        <ShowHide
                            evaluator={fileCracked}
                            show={(
                                <Fragment>
                                    <h1>{fileText}</h1>
                                    <div className="spacer-bottom">
                                        <Button text="View another file" icon="document-open" intent="primary" onClick={onNewFile} />
                                    </div>
                                </Fragment>
                            )}
                            hide={(
                                <Fragment>
                                    <h1>Vehicle Unit File Viewer</h1>
                                    <FormFileInput
                                        text={fileText}
                                        buttonText="Browse"
                                        headingText="Use the file uploader to upload a vehicle unit file."
                                        helperText="The file is in a vehcile unit format."
                                        fill={false}
                                        onChange={onFileChange}
                                        hasSelection={fileText !== ''}
                                    />
                                </Fragment>
                            )}
                        />


                    </div>

                    <ShowHide
                        evaluator={fileCracked}
                        show={(
                            <div className="layout-cols">
                                <div className="col side-col">
                                    <div className="row">
                                        <Tabs tabs={tabs} onClick={onTabClick} loading={loadingData} vertical={true} />
                                    </div>
                                </div>
                                <div className="col content-col">
                                    <div className="spacer-bottom">
                                        <ListingTable
                                            id="listing-table-schedule-run-stats"
                                            headers={tableHeader}
                                            noDataMessage={"No data available"}
                                            data={vuFileDataTable}
                                            totalRecordCount={totalRecords}
                                            loadingData={loadingData}
                                            pageable={pageableTab}
                                            onPagingChange={onPagingChange}
                                            scrollable
                                        />
                                    </div>
                                </div>
                            </div>


                        )}
                    />
                </div>
            )}
            no={() => (
                <PageRestricted />
            )}
        />

    );

}
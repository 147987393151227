import { Intent } from '@blueprintjs/core';
import { ButtonSave } from 'components/buttons';
import { FormTextArea } from 'components/form-fields';
import { AlertUnsavedData, NotificationToaster } from 'components/notifications';
import { useDirtyData } from 'hooks/useDirtyData';
import React, { useEffect, useState, Fragment } from 'react';
import { JobService } from 'services';
import { UserAccess } from 'components/user-access/UserAccess';
import { ShowHide } from "components/layout";
import { PageRestricted } from 'pages/errors/page-restricted';
import { HelperFunctions } from 'helpers';
import PropTypes from 'prop-types';

export function JobNotes(props) {
    const defaultJobDetails = {
        jobId: null,
        state: null,
        productDescription: null,
        intendedAssetId: null,
        assetType: null,
        locationId: null,
        dueDate: null,
        scheduledDate: null,
        flsStatus: null,
        jobNote: '',
        jobProductBusinessAreaId: null,
        completed: null
    };

    const [jobNotes, setJobNotes] = useState(defaultJobDetails);
    const [originalJobNotes, setOriginalJobNotes] = useState(defaultJobDetails);
    const [loading, setLoading] = useState(true);
    const [saving, setSaving] = useState(false);
    const isDataDirty = useDirtyData(originalJobNotes, jobNotes);
    const [canSave, setCanSave] = useState(false);
    const requiredAction = ["Internal"];
    const tachoBusinessAreaId = 1;

    useEffect(initialLoad, [props.job, props.locationNotes]);

    function initialLoad() {
        setJobNotes(props.job);
        setOriginalJobNotes(props.job);
        setCanSave(props.job.jobProductBusinessAreaId === tachoBusinessAreaId && !props.job.completed);
        setLoading(false);
    }

    function saveJobDetails() {
        setSaving(true);

        JobService.saveJobDetails(jobNotes)
            .then(
                () => {
                    setOriginalJobNotes(jobNotes);
                    NotificationToaster.show(Intent.SUCCESS, "Job notes updated successfully.");
                    setSaving(false);
                },
                () => {
                    NotificationToaster.show(Intent.DANGER, "Could not save job notes. Please try again.");
                    setSaving(false);
                },
        );

        HelperFunctions.executeIfNotNull(props.onSave, jobNotes);
    }

    function onNoteChange(e) {
        setJobNotes({
            ...jobNotes,
            jobNote: e.target.value
        });
    }

    function doNothing(e) {
        //do nothing here because the business want the location notes to be read only for the moment but we will need to add a function here to allow the user to edit the location notes in the future
    }

    return (

        <UserAccess perform={requiredAction}
            yes={() => (
                <Fragment>
                    <div className="row">
                        <h2>Internal Notes</h2>
                        <FormTextArea
                            id="location-note"
                            headingText="Location Notes"
                            loading={loading}
                            value={props.locationNotes}
                            onChange={doNothing}
                            disabled={true}
                            maxLength={255}
                        >
                        </FormTextArea>
                        <FormTextArea
                            id="job-note"
                            headingText="Job Notes"
                            loading={loading}
                            value={jobNotes.jobNote !== null ? jobNotes.jobNote : ''}
                            onChange={onNoteChange}
                            disabled={props.saving || !canSave}
                            maxLength={255}
                        >
                        </FormTextArea>
                        <AlertUnsavedData
                            isDirty={isDataDirty}>
                        </AlertUnsavedData>
                        <ShowHide
                            evaluator={canSave}
                            show=
                            {
                                <ButtonSave
                                    onClick={saveJobDetails}
                                    disabled={saving}
                                    simpleDisabled={false}
                                    loading={loading}
                                ></ButtonSave>
                            }
                        >
                        </ShowHide>

                    </div>
                </Fragment>

            )}
            no={() => (
                <PageRestricted />
            )}
        />

    );
}
JobNotes.propTypes = {
    job: PropTypes.object,
    locationNotes: PropTypes.string,
};
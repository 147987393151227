import classNames from 'classnames';
import { ShowHide } from 'components/layout';
import PropTypes from 'prop-types';
import React, { Fragment } from 'react';

import './DataDisplay.css';

export function DataDisplayTable(props) {
    return (
        <div className={classNames({ "data-display-table-rows": props.displayInRows, "data-display-table-columns": !props.displayInRows }, `data-display-table-columns-${props.columnsPerRow}`)}>
            {props.data && props.data.map(d => {
                return (
                    <div className={classNames("data-display-table-field form-field", { "data-display-table-no-value": !d.value })} key={d.key}>
                        <h4 className={classNames("data-display-table-field-header", { "bp3-skeleton": props.loading })}>{d.header}</h4>
                        <ShowHide
                            evaluator={!!d.value || d.value === 0}
                            show={<span className={classNames("data-display-table-field-value", { "bp3-skeleton": props.loading })}>{d.value}</span>}
                        />

                        {d.additionalData && d.additionalData.map(y => {
                            return (
                                <Fragment key={y.key}>
                                    <h4 className={classNames("data-display-table-field-header data-display-table-additional-field-header", { "bp3-skeleton": props.loading })}>{y.header}</h4>
                                    <span className={classNames("data-display-table-field-value data-display-table-additional-field-value", { "bp3-skeleton": props.loading })}>{y.value}</span>
                                </Fragment>
                            );
                        })}
                    </div>
                );
            })}
        </div>
    );
}

DataDisplayTable.defaultProps = {
    loading: false,
    displayInRows: true,
    columnsPerRow: 2
};

DataDisplayTable.propTypes = {
    data: PropTypes.array.isRequired,
    loading: PropTypes.bool,
    displayInRows: PropTypes.bool,
    columnsPerRow: PropTypes.number
};
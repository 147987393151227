import React from 'react';
import { Tooltip } from "components/tooltip";
import { Table } from 'components/listing';
import { Icon } from '@blueprintjs/core';
import { FormTimePicker, FormSwitch } from 'components/form-fields';
import PropTypes from 'prop-types';
import { HelperFunctions } from 'helpers';
import { Button } from 'components/buttons';
import { ShowHide } from "components/layout";

export function BusinessAreaOperatingHoursTable(props) {

    function formatParamTableHeaders() {

        let paramHeaders = ["", "Day", "Open 24 hours", "Closed All Day", "Open", "Closed", "Open ", "Closed "];

        return {
            headers: paramHeaders.map((value) => {
                return {
                    key: value,
                    value: value
                }
            })
        };
    }

    function onOpenAllDay(dayOfWeek, item) {
        HelperFunctions.executeIfNotNull(props.onOpenAllDay, dayOfWeek, item)
    }

    function onClosedAllDay(dayOfWeek, item) {
        HelperFunctions.executeIfNotNull(props.onClosedAllDay, dayOfWeek, item)
    }

    function onStartTimeChange(dayOfWeek, idx, item) {
        HelperFunctions.executeIfNotNull(props.onStartTimeChange, dayOfWeek, idx, item)
    }

    function onEndTimeChange(dayOfWeek, idx, item) {
        HelperFunctions.executeIfNotNull(props.onEndTimeChange, dayOfWeek, idx, item)
    }

    function onCopysettings(dayOfWeek) {
        HelperFunctions.executeIfNotNull(props.onCopySettings, dayOfWeek)
    }

    function formatParamTable(operatingHours) {
        return operatingHours.map(function (operatingHour) {
            return {
                cells: [
                    <Tooltip content={operatingHour.errorMessage} key={`toolTip${operatingHour.dayOfWeek}`}>
                        <Icon
                            icon={operatingHour.valid ? 'tick' : 'issue'}
                            className={operatingHour.valid ? '' : 'bp3-intent-danger'}
                        />
                    </Tooltip>,
                    operatingHour.day,
                    <FormSwitch
                        id={`select-all-day-${operatingHour.dayOfWeek}`}
                        disabled={props.loading || props.saving || operatingHour.closedAllDay || props.readOnly}
                        checked={operatingHour.openAllDay}
                        onChange={(item) => { onOpenAllDay(operatingHour.dayOfWeek, item) }}
                        showLabels
                        key={`openAllDay2${operatingHour.dayOfWeek}`}
                    />,
                    <FormSwitch
                        id={`select-closed-all-day-${operatingHour.dayOfWeek}`}
                        disabled={props.loading || props.saving || operatingHour.openAllDay || props.readOnly}
                        checked={operatingHour.closedAllDay}
                        onChange={(item) => { onClosedAllDay(operatingHour.dayOfWeek, item) }}
                        showLabels
                        key={`closedAllDay${operatingHour.dayOfWeek}`}
                    />,
                    <FormTimePicker
                        id={`select-start-time-1-${operatingHour.dayOfWeek}`}
                        disabled={props.loading || props.saving || operatingHour.openAllDay || operatingHour.closedAllDay || props.readOnly}
                        value={operatingHour.startDate}
                        onChange={(item) => { onStartTimeChange(operatingHour.dayOfWeek, 0, item) }}
                        key={`start1${operatingHour.dayOfWeek}`}
                    ></FormTimePicker>,
                    <FormTimePicker
                        id={`select-end-time-1-${operatingHour.dayOfWeek}`}
                        disabled={props.loading || props.saving || operatingHour.openAllDay || operatingHour.closedAllDay || props.readOnly}
                        value={operatingHour.endDate}
                        onChange={(item) => { onEndTimeChange(operatingHour.dayOfWeek, 0, item) }}
                        key={`end1${operatingHour.dayOfWeek}`}
                    ></FormTimePicker>,
                    <FormTimePicker
                        id={`select-start-time-2-${operatingHour.dayOfWeek}`}
                        disabled={props.loading || props.saving || operatingHour.range2Disabled || operatingHour.openAllDay || operatingHour.closedAllDay || props.readOnly}
                        value={operatingHour.startDate1}
                        onChange={(item) => { onStartTimeChange(operatingHour.dayOfWeek, 1, item) }}
                        key={`start2${operatingHour.dayOfWeek}`}
                    ></FormTimePicker>,
                    <FormTimePicker
                        id={`select-end-time-2-${operatingHour.dayOfWeek}`}
                        disabled={props.loading || props.saving || operatingHour.range2Disabled || operatingHour.openAllDay || operatingHour.closedAllDay || props.readOnly}
                        value={operatingHour.endDate1}
                        onChange={(item) => { onEndTimeChange(operatingHour.dayOfWeek, 1, item) }}
                        key={`end2${operatingHour.dayOfWeek}`}
                    ></FormTimePicker>,
                    <ShowHide
                        evaluator={operatingHour.valid && operatingHour.dayOfWeek !== 0}
                        key={`showHideCopy${operatingHour.dayOfWeek}`}
                        show={(
                            <Tooltip content="Copy to the row below" key={`copyToolTip${operatingHour.dayOfWeek}`}>
                                <Button onClick={() => onCopysettings(operatingHour.dayOfWeek)} icon="duplicate" iconOnly={true} text="Copy to the row below" large={false} minimal={true} key={`copyIcon${operatingHour.dayOfWeek}`}></Button>                            
                            </Tooltip>
                        
                    )}
                />
                    
                ],
                key: operatingHour.dayOfWeek
            };
        });
    }

    return (
        <Table
        headerData={formatParamTableHeaders()}
            data={formatParamTable(props.operatingHours)}
            loadingData={false}
            fitToContent={false}
        />
    );
}
BusinessAreaOperatingHoursTable.defaultProps = {
    saving: false,
    readOnly: false
};

BusinessAreaOperatingHoursTable.propTypes = {
    operatingHours: PropTypes.array.isRequired,
    saving: PropTypes.bool,
    loading: PropTypes.bool.isRequired,
    readOnly: PropTypes.bool
};
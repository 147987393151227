import React, { useEffect, useState, useRef } from 'react';
import ReactECharts from 'echarts-for-react';
import PropTypes from 'prop-types';
import moment from "moment";
import { ShowHide } from "components/layout";
import { HelperFunctions } from 'helpers';

export function ActivitiesGraph(props) {
    const { activitiesList, harshBraking, undisplayedActivitiesList, isLoading, highlightedActivity, highlightedHarshBraking, onActivitySelected } = props;
    const backgroundColour = "#0C2235";
    const undisplayedColourMain = "#EBEBEB";
    const undisplayedColourBackground = "#E0E0E0";
    const highlightedColourMain = "#E6ECF2";
    const harshBrakingColour = "#F0578A";
    const maxTypeIdGraphHeight = 5;
    const baseXAxisHeight = 1;
    const [graphData, setGraphData] = useState({ stack1: [], stack2: [] });
    const [options, setOptions] = useState(null);
    const [initialOptions, setInitialOptions] = useState(null);
    const noClipFirstXAxis = 30;
    const dateFormat = "YYYY-MM-DD HH:mm";
    const chartRef = useRef(null);
    const currentActivitiesList = useRef();
    const currentBaseDate = useRef();

    function getBackgroundColour(isDisabled, isHighlighted) {
        if (isDisabled) return undisplayedColourBackground;
        if (isHighlighted) return highlightedColourMain;
        return backgroundColour;
    }

    function dateToLocalMoment(date) {
        var dateUtcMoment = moment.utc(date);
        var dateUtc = dateUtcMoment.toDate();
        return moment(dateUtc).local();
    }

    function getStartTimeOfGraph(activities) {
        let startDateOfGraph = activities[0] ? dateToLocalMoment(activities[0].startDate).format(dateFormat) : moment(props.baseDate).format(dateFormat);
        return props.isSummary ? moment(startDateOfGraph, dateFormat).startOf('day') : moment(startDateOfGraph, dateFormat);
    }

    function formatData(data, undisplayedData, hBraking) {
        let baseData = [];
        let timeFormattedData = [];
        let hbMins = [];
        let highlightedHbSt = null;

        if (highlightedHarshBraking?.startDate) {
            highlightedHbSt = dateToLocalMoment(highlightedHarshBraking.startDate).format(dateFormat);
        }

        hBraking.map(h => {
            let stDt = dateToLocalMoment(h.startDate);
            let isHighlighted = highlightedHbSt !== null ? highlightedHbSt === stDt.format(dateFormat) : false;
            for (let i = 0; i < h.duration; i++) {
                let startDate = stDt.add(1, 'minutes').format(dateFormat);
                hbMins.push({
                    startDate: startDate,
                    isHighlighted: isHighlighted
                });
            }
        });
        data.map(a => {
            let stDt = dateToLocalMoment(a.startDate);
            for (let i = 0; i < a.duration; i++) {
                let stActDt = stDt.add(1, 'minutes').format(dateFormat);
                let harshBrakingActivity;
                if (hbMins && hbMins.length > 0) {
                    harshBrakingActivity = hbMins.find(h => h.startDate === stActDt);
                }
                baseData.push({
                    startDate: stActDt,
                    typeId: a.typeId + baseXAxisHeight,
                    isHighlighted: a.activityId === highlightedActivity?.activityId || harshBrakingActivity?.isHighlighted,
                    isDisabled: false,
                    colour: harshBrakingActivity !== undefined ? harshBrakingColour : a.bgColor
                });
            }
        });
        undisplayedData.map(a => {
            for (let i = 0; i < a.duration; i++) {
                baseData.push({
                    startDate: dateToLocalMoment(a.startDate).add(i, 'minutes').format(dateFormat),
                    typeId: a.typeId + baseXAxisHeight,
                    isHighlighted: false,
                    isDisabled: true,
                    colour: undisplayedColourMain,
                });
            }
        });
        //Add/amend values to make sure the graph shows 24 hours:
        const startTime = getStartTimeOfGraph(data);
        for (let i = 0; i < (24 * 60); i++) {
            timeFormattedData.push({ startDate: dateToLocalMoment(startTime).add(i, 'minutes').format(dateFormat), typeId: 0 });
        }
        timeFormattedData = timeFormattedData.map(r => {
            return Object.assign(r, baseData.find(b => b.startDate === r.startDate));
        });

        let stack1 = timeFormattedData.map(a => {
            return { ...a, colour: getBackgroundColour(a.isDisabled, a.isHighlighted) };
        });
        let stack2 = timeFormattedData.map(a => {
            return { ...a, typeId: (a.typeId === 0 ? 0 : maxTypeIdGraphHeight - a.typeId) };
        });
        return { stack1: stack1, stack2: stack2 };
    }

    useEffect(() => {
        let gd = formatData(activitiesList, undisplayedActivitiesList, harshBraking);
        setGraphData(gd);
    }, [activitiesList, harshBraking, undisplayedActivitiesList, highlightedActivity, highlightedHarshBraking]);

    useEffect(() => {
        let opts = formatOptions();
        setOptions(opts);
        if (initialOptions === null) {
            //This is to prevent the graph from re-rendering on every click
            setInitialOptions(opts);
        }
    }, [graphData]);

    useEffect(() => {
        if (initialOptions !== null) {
            setTimeout(() => {
                let instance = chartRef.current.getEchartsInstance();
                instance.setOption(options);
            });
        }
    }, [options]);

    useEffect(() => {
        if (props.baseDate) {
            if (currentBaseDate.current && currentBaseDate.current !== props.baseDate) {
                //Need to reset the graph to stop multiple onclick events
                setInitialOptions(null);
                currentActivitiesList.current = null;
            }
            currentBaseDate.current = props.baseDate;
        }
    }, [props.baseDate]);

    function formatOptions() {
        return {
            grid: {
                left: 0,
                top: 0,
                right: 0,
                bottom: 35,
                tooltip: {
                    show: false
                }
            },
            legend: {
                show: false
            },
            tooltip: {
                trigger: 'axis'
            },
            xAxis: {
                axisLabel: {
                    fontFamily: 'Open Sans',
                    align: 'left',
                    interval: 0,
                    formatter: function (value, index) {
                        if (index === 0 || (moment(value).isSame(moment(value).startOf('day')) && index > noClipFirstXAxis))
                            return "{firstItem|" + moment(value).format("HH:mm") + "\n" + String(moment(value).format("DD MMM")).toUpperCase() + "}";
                        if (moment(value).format("mm") === "00" && index > noClipFirstXAxis)
                            return moment(value).format("HH");
                        else return "";
                    },
                    rich: {
                        firstItem: {
                            fontWeight: 'bold',
                            fontFamily: 'Open Sans'
                        }
                    }
                },
                type: 'category',
                splitLine: {
                    show: true,
                    lineStyle: {
                        color: '#f3f3f3',
                        type: 'solid'
                    },
                    interval: 59
                },
                axisTick: {
                    show: false
                },
                data: graphData.stack1.map(a => a.startDate),
            },
            yAxis: {
                type: 'value',
                axisLabel: {
                    formatter: () => { }
                },
                splitLine: { show: false }
            },
            series: [
                {
                    data: graphData.stack1.map(a => {
                        return {
                            value: a.typeId,
                            itemStyle: {
                                color: a.colour
                            }
                        }
                    }),
                    type: 'bar',
                    barCategoryGap: '0%',
                    stack: 'x',
                    itemStyle: {
                        borderType: 'none'
                    }
                },
                {
                    data: graphData.stack2.map(a => {
                        return {
                            value: a.typeId,
                            itemStyle: {
                                color: a.colour
                            }
                        }
                    }),
                    type: 'bar',
                    barCategoryGap: '0%',
                    stack: 'x',
                    itemStyle: {
                        borderType: 'none'
                    }
                }
            ]
        };
    }

    useEffect(() => {
        if (options !== null) {
            if (!activitiesList || activitiesList.length < 1 || HelperFunctions.deepEqual(activitiesList, currentActivitiesList.current)) {
                //We don't want to set this up multiple times as it causes the click to fire multiple times
                return;
            }
            currentActivitiesList.current = activitiesList;

            //This ie being used rather than the ReactECharts onEvents prop as it was causing the chart to re-render on every click
            if (chartRef ==  null || chartRef.current === null) {
                return;
            }
            let instance = chartRef.current.getEchartsInstance();
            instance.on('click', (params) => {
                if (onActivitySelected) {
                    let startTime = getStartTimeOfGraph(activitiesList);
                    let dateTimeClicked = moment(startTime).add(params.dataIndex, 'minutes');
                    let selectedActivity = activitiesList.find(a => moment(dateTimeClicked).isBetween(dateToLocalMoment(a.startDate), dateToLocalMoment(moment(a.startDate).add(a.duration, 'minutes').format(dateFormat))));
                    if (selectedActivity) {
                        onActivitySelected(selectedActivity);
                    }
                }
            });
        }
    }, [activitiesList, options]);

    return (
        <ShowHide
            evaluator={initialOptions !== null}
            show={
                <ReactECharts
                    option={initialOptions}
                    notMerge={true}
                    lazyUpdate={true}
                    theme={"light"}
                    style={{ height: "100%" }}
                    showLoading={isLoading}
                    ref={chartRef}
                />
            }
            hide={
                <span className={"bp3-skeleton"}>
                </span>
            }
        ></ShowHide>
    );
}
ActivitiesGraph.defaultProps = {
    activitiesList: [],
    harshBraking: [],
    undisplayedActivitiesList: [],
    isLoading: false,
    isSummary: false
};

ActivitiesGraph.propTypes = {
    activitiesList: PropTypes.array,
    harshBraking: PropTypes.array,
    undisplayedActivitiesList: PropTypes.array,
    isLoading: PropTypes.bool,
    isSummary: PropTypes.bool,
    baseDate: PropTypes.string,
    highlightedActivity: PropTypes.object,
    highlightedHarshBraking: PropTypes.object,
    onActivitySelected: PropTypes.func
};
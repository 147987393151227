import React, { Fragment, useState, useEffect } from 'react';
import { FormTextInput, FormSelect } from "components/form-fields";
import { useValidation } from "hooks/useValidation";
import PropTypes from 'prop-types';
import { UserService } from 'services';

export function ApiApplicationBasicSettings(props) {
    const [isValid, errors, validate] = useValidation();
    const [loading, setLoading] = useState(true);
    const [appSettings, setAppSettings] = useState({ applicationName: null, role: {} });
    const [roles, setRoles] = useState([]);

    useEffect(initialLoad, []);

    function initialLoad() {
        UserService.getRoles().then(function (rolesResponse) {
            setRoles(rolesResponse.filter(r => r.settable));
        });
    }

    useEffect(onValidationChange, [isValid])

    function onValidationChange() {
        props.onValidationUpdate(isValid);
    }

    useEffect(() => {
        setAppSettings(props.application);
    }, [props.application]);

    useEffect(() => {
        setLoading(props.loading);
    }, [props.loading]);

    function onNameChange(event) {

        var updatedApp = {
            ...appSettings,
            applicationName: event.target.value
        }

        setAppSettings(updatedApp);
        props.onSettingsUpdate(updatedApp);
    }

    function onRoleChange(value) {
        var updatedApp = {
            ...appSettings,
            role: value
        }

        setAppSettings(updatedApp);
        props.onSettingsUpdate(updatedApp);
    }

    useEffect(updateValidationRules, [props.application])

    function updateValidationRules() {
        let rules = [{ fieldName: "applicationName", required: true }, { fieldName: "role", required: true }];
        validate(rules, props.application);
    }

    return (
        <Fragment>
            <FormTextInput
                id="application-name"
                loading={loading}
                value={appSettings.applicationName}
                headingText="Application Name:"
                onChange={onNameChange}
                disabled={props.editing || props.saving || props.pending || props.ssoAccount}
                dangerHelperText={errors.applicationName}>
            </FormTextInput>
            <FormSelect
                id="application-role"
                disabled={props.saving}
                items={roles}
                onItemSelect={onRoleChange}
                placeholder="Select a role"
                headingText="Role:"
                selectedValue={appSettings.role.id}
                dangerHelperText={errors.role}
                loading={loading}
            ></FormSelect>
        </Fragment>
    );
}
ApiApplicationBasicSettings.defaultProps = {
    saving: false,
    editing: false
};

ApiApplicationBasicSettings.propTypes = {
    application: PropTypes.object.isRequired,
    saving: PropTypes.bool,
    editing: PropTypes.bool,
    loading: PropTypes.bool.isRequired
};
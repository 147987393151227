import { Intent } from "@blueprintjs/core";
import { FormMultiSelect, FormPagedCheckboxList, FormSwitch } from "components/form-fields";
import { ShowHide } from "components/layout";
import { NotificationToaster } from "components/notifications";
import { useValidation } from "hooks/useValidation";
import PropTypes from 'prop-types';
import React, { Fragment, useEffect, useState } from "react";
import { LocationService } from "services";

export function EmployeeAssessmentGeneralSettings(props) {
    const [isValid, errors, validate] = useValidation();
    const [locations, setLocations] = useState([]);
    const [totalLocationRecordCount, setTotalLocationRecordCount] = useState(0);
    const [loadingLocations, setLoadingLocations] = useState(true);

    const locationFilter = {
        "filters":
            [
                { "key": "BusinessAreas", "value": "Tacho" },
                { "key": "States", "value": "Active" }
            ]
    };

    useEffect(validateSettings, [props.generalSettings]);
    useEffect(() => props.onValidationUpdate(isValid), [isValid]);

    function validateSettings() {
        const rules = getRules();
        validate(rules, props.generalSettings);
    }

    function getRules() {
        const rules = [
            { fieldName: "employeeTypes", required: true },
            { fieldName: "employeeCategories", required: true }
        ];

        if (!props.generalSettings.useAllLocations) {
            rules.push({ fieldName: "locations", required: true });
        }
        return rules;
    }

    function onLocationsPageChange(listSize, pageNumber, debouncedSearchTerm) {
        setLoadingLocations(true);
        LocationService.getLocationList(listSize, pageNumber, debouncedSearchTerm, 'Name', 'A', locationFilter).then(pagedLocations => {
            setLocations(pagedLocations.data);
            setTotalLocationRecordCount(pagedLocations.totalCount);
            setLoadingLocations(false);
        }, (error) => {
            NotificationToaster.show(Intent.DANGER, "Could not load settings. Please refresh the page.");
        });
    }

    function onListItemSelected(item, listName) {
        const tempList = [...props.generalSettings[listName]];

        if (tempList.some(x => x.id === item.id)) {
            return;
        }

        tempList.push(item);

        const updatedGeneralSettings = {
            ...props.generalSettings,
            [listName]: tempList
        };

        props.onGeneralSettingsUpdate(updatedGeneralSettings);
    }

    function onListItemRemove(index, listName) {
        const tempList = [...props.generalSettings[listName]];
        tempList.splice(index, 1);

        const updatedGeneralSettings = {
            ...props.generalSettings,
            [listName]: tempList
        };

        props.onGeneralSettingsUpdate(updatedGeneralSettings);
    }

    function onEmployeeTypeSelected(item) {
        onListItemSelected(item, 'employeeTypes');
    }

    function onEmployeeTypeRemove(_, index) {
        onListItemRemove(index, 'employeeTypes');
    }

    function onEmployeeCategoriesSelected(item) {
        onListItemSelected(item, 'employeeCategories');
    }

    function onEmployeeCategoriesRemove(_, index) {
        onListItemRemove(index, 'employeeCategories');
    }

    function onLocationsSelected(item, isChecked) {
        const listName = 'locations';

        if (isChecked) {
            onListItemSelected(item, listName);
        } else {
            const itemIndex = props.generalSettings.locations.findIndex(x => x.id === item.id);
            onListItemRemove(itemIndex, listName);
        }
    }

    function onUseAllLocationsChange(event) {
        const useAllLocations = event.target.checked;
        const updatedSettings = {
            ...props.generalSettings,
            useAllLocations: useAllLocations
        };

        if (useAllLocations) {
            updatedSettings.locations = [];
        }

        props.onGeneralSettingsUpdate(updatedSettings);
    }

    return (<Fragment>
        <FormMultiSelect
            id="employee-types"
            headingText="Which employee types would you like the assessments to apply to?"
            items={props.employeeTypes}
            onItemSelect={onEmployeeTypeSelected}
            selectedValues={props.generalSettings.employeeTypes}
            dangerHelperText={errors.employeeTypes}
            placeholder="Apply employee types"
            loading={props.loading}
            disabled={props.disabled}
            onRemove={onEmployeeTypeRemove}
        ></FormMultiSelect>
        <FormMultiSelect
            id="employee-categories"
            headingText="Which employee categories would you like the assessments to apply to?"
            items={props.employeeCategories}
            onItemSelect={onEmployeeCategoriesSelected}
            selectedValues={props.generalSettings.employeeCategories}
            dangerHelperText={errors.employeeCategories}
            placeholder="Apply employee categories"
            loading={props.loading}
            disabled={props.disabled}
            onRemove={onEmployeeCategoriesRemove}
        ></FormMultiSelect>
        <FormSwitch
            disabled={props.disabled}
            loading={props.loading}
            headingText="Apply to all locations"
            checked={props.generalSettings.useAllLocations}
            onChange={onUseAllLocationsChange}>
        </FormSwitch>
        <ShowHide evaluator={!props.generalSettings.useAllLocations}
            show={(
                <FormPagedCheckboxList
                    id="locations"
                    onPageChange={onLocationsPageChange}
                    headingText="Apply to the following locations:"
                    items={locations}
                    totalItemCount={totalLocationRecordCount}
                    onItemSelect={onLocationsSelected}
                    selectedItems={props.generalSettings.locations}
                    dangerHelperText={errors.locations}
                    placeholder="Search locations"
                    loadingPage={loadingLocations}
                    loading={props.loading}
                    saving={props.disabled}
                ></FormPagedCheckboxList>
            )}>
        </ShowHide>
    </Fragment>);
}

EmployeeAssessmentGeneralSettings.defaultProps = {
    disabled: false,
    loading: false,
};

EmployeeAssessmentGeneralSettings.propTypes = {
    generalSettings: PropTypes.object.isRequired,
    disabled: PropTypes.bool,
    loading: PropTypes.bool,
    employeeTypes: PropTypes.array.isRequired,
    employeeCategories: PropTypes.array.isRequired,
    onGeneralSettingsUpdate: PropTypes.func.isRequired,
    onValidationUpdate: PropTypes.func.isRequired,
};
import React, { useState, useEffect, Fragment } from 'react';
import { useParams } from "react-router-dom";

import { Intent } from '@blueprintjs/core';
import { LocationService } from 'services';
import { NotificationToaster, AlertUnsavedData } from "components/notifications";
import { ButtonSave } from "components/buttons";
import { useDirtyData } from "hooks/useDirtyData";
import { UserAccess } from 'components/user-access';
import { PageRestricted } from 'pages/errors/page-restricted';
import moment from "moment";
import { AdditionalInfoSettings } from 'pages/locations/shared';

var _ = require('underscore');

export function AdditionalInfo(props) {

    const timeFormat = "YYYY-MM-DD HH:mm:ss";
    const inductionDateTime = "01 Jan 2000"

    const requiredAction = "Internal";
    const { id } = useParams();

    const additionalInfoModel = {
        locationId: null,
        weighPadAvailable: null,
        inductionRequired: false,
        inductionDurationTime: null,
        inductionTime: 0
    };

    const [additionalInfo, setAdditionalInfo] = useState(additionalInfoModel);
    const [originalAdditionalInfo, setOriginalAdditionalInfo] = useState(additionalInfo);
    const [loading, setLoading] = useState(true);

    const [isValid, setIsValid] = useState(false);
    const [saving, setSaving] = useState(false);
    const [allowIsDirty, setAllowIsDirty] = useState(false);

    const isDataDirty = useDirtyData(originalAdditionalInfo, additionalInfo);

    useEffect(initialLoad, []);

    function initialLoad() {
        LocationService.getLocationAdditionalInformation(id).then(
            (locationAdditionalInfo) => {
                var hours = 0;
                var mins = 0;
                if (locationAdditionalInfo.additionalInfo.inductionTime !== undefined && locationAdditionalInfo.additionalInfo.inductionTime > 0) {
                    hours = Math.floor(locationAdditionalInfo.additionalInfo.inductionTime / 60);
                    mins = locationAdditionalInfo.additionalInfo.inductionTime - (hours * 60);
                }

                var cloneAdditionalInfo = {
                    ...locationAdditionalInfo.additionalInfo,
                    inductionDurationTime: moment(inductionDateTime).startOf('day').set('hour', hours).set('minute', mins).format(timeFormat),
                    locationId: id,
                    inductionTime: locationAdditionalInfo.additionalInfo.inductionTime == null ? 0 : locationAdditionalInfo.additionalInfo.inductionTime
                };

                setAdditionalInfo(cloneAdditionalInfo);
                setOriginalAdditionalInfo(_.extend({}, cloneAdditionalInfo));
                setLoading(false);
            },
            (error) => {
                NotificationToaster.show(Intent.DANGER, "Could not get the additional information. Please refresh the page.")
            }
        );
    }

    function saveLocationAdditionalInfo() {
        setSaving(true);
        if (!isValid) {
            setSaving(false);
            NotificationToaster.show(Intent.WARNING, "There are errors on the page that need your attention");
            return;
        }

        if (additionalInfo.inductionRequired) {
            var duration = moment(additionalInfo.inductionDurationTime, timeFormat);
            additionalInfo.inductionTime = (duration.hours() * 60) + duration.minutes();
        }
        else {
            additionalInfo.inductionTime = 0;
            additionalInfo.inductionDurationTime = moment(inductionDateTime).startOf('day').format(timeFormat);
        }

        LocationService.saveAdditionalInfo(id, additionalInfo).then(
            () => {
                setSaving(false);
                setOriginalAdditionalInfo(_.extend({}, additionalInfo));

                NotificationToaster.show(Intent.SUCCESS, "Additional Info updated successfully.")
            },
            (error) => {
                setSaving(false);
                //For Validation errors this doesn't return a useful message, just System error, so won't bother checking.
                setAllowIsDirty(true);
                NotificationToaster.show(Intent.DANGER, "Could not save settings. Please try again.");
            }
        );
    }

    function updateAdditionalInfoSettings(setting) {
        setAdditionalInfo(setting);
    }

    function onAdditionalInfoSettingValidationUpdate(onIsValid) {
        setIsValid(onIsValid);
    }

    return (

        <UserAccess perform={requiredAction}
            yes={() => (
                <div className="row">
                    <Fragment>
                        <h2>Additional Info</h2>
                        <AdditionalInfoSettings
                            additionalInfoSettings={{ additionalInfo }}
                            originalInductionTime={'2000-01-01 00:00:00'}
                            onAdditionalInfoSettingsUpdate={updateAdditionalInfoSettings}
                            onValidationUpdate={onAdditionalInfoSettingValidationUpdate}
                            saving={saving}
                            loading={loading}
                        />
                        <AlertUnsavedData
                            isDirty={isDataDirty && !allowIsDirty}>
                        </AlertUnsavedData>
                        <ButtonSave
                            id="location-address-save"
                            onClick={saveLocationAdditionalInfo}
                            disabled={saving}
                            loading={loading}
                        ></ButtonSave>
                    </Fragment >
                </div>
            )}
            no={() => (
                <div className="row">
                    <PageRestricted />
                </div>
            )}
        />
    );
}
import React, { useState, Fragment, useEffect, useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import PropTypes from 'prop-types';
import { Button } from 'components/buttons';
import { Dialog } from '@blueprintjs/core';

export function AlertUnsavedData(props) {

    const [alertOpen, setAlertOpen] = useState(false);
    const [nextLocation, setNextLocation] = useState(null);
    let history = useHistory();
    const dispatch = useDispatch();

    useEffect(init, []);

    function init() {
        return history.block((location) => {
            if (blockNavigationRef.current) {
                setAlertOpen(true);
                setNextLocation(location);
            }
            return !blockNavigationRef.current;
        });
    }

    const blockNavigationRef = useRef();
    const attemptingRedirect = useSelector(state => state.dirtyData.attemptingRedirect);
    const redirectCallback = useSelector(state => state.dirtyData.task);

    useEffect(function () {
        if (props.isDirtySamePage) {
            setAlertOpen(true);
        } else {
            setAlertOpen(false);
        }
    }, [props.isDirtySamePage]);

    useEffect(function () {
        if (attemptingRedirect === true) {
            setAlertOpen(true);
        }
    }, [attemptingRedirect])
    useEffect(onDirtyChange, [props.isDirty]);

    function onDirtyChange() {
        blockNavigationRef.current = props.isDirty;
    }

    function handleStayNavigationClick() {
        setAlertOpen(false);
        if (attemptingRedirect === true) {
            dispatch({ type: 'CANCEL_REDIRECT' })
        }
        if (props.onCancelLeave) {
            props.onCancelLeave();
        }
    }

    function handleLeaveNavigationClick() {
        setAlertOpen(false);

        if (props.onConfirmLeave) {
            props.onConfirmLeave();
            return;
        }

        if (attemptingRedirect === true) {
            if (typeof redirectCallback == 'function') {
                redirectCallback();
            } else {
                dispatch({ type: 'ALLOW_REDIRECT' });
            }
        } else {
            dispatch({ type: 'RESET_DIRTY_FLAG' });
            blockNavigationRef.current = false;
            history.push(nextLocation.pathname);
        }
    }

    return (
        <Fragment>
            <Dialog
                isOpen={alertOpen}
                title="Unsaved Changes"
                isCloseButtonShown={false}
            >
                <div className="bp3-dialog-body">
                    <p>If you leave now, the changes will not be saved. Do you want to discard the changes and continue?</p>
                </div>

                <div className="bp3-dialog-footer">
                    <div className="bp3-dialog-footer-actions">
                        <Button text="Leave page" intent="danger" onClick={handleLeaveNavigationClick}></Button>
                        <Button text="Stay on page" intent="primary" onClick={handleStayNavigationClick}></Button>
                    </div>
                </div>
            </Dialog>
        </Fragment>
    );
}

AlertUnsavedData.propTypes = {
    isDirty: PropTypes.bool.isRequired,
    isDirtySamePage: PropTypes.bool,
    onCancelLeave: PropTypes.func,
    onConfirmLeave: PropTypes.func
};

AlertUnsavedData.defaultProps = {
    isDirtySamePage: false,
    onCancelLeave: null,
    onConfirmLeave: null
};

import React, { useState } from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { ShowHide } from "components/layout";
import { InputGroup, Tooltip, Button, Position } from "@blueprintjs/core";

import { FormHelperText } from "components/form-fields";


export function FormPasswordInput(props) {

    const [showPassword, setShowPassword] = useState(false);

    function onPasswordToggle() {
        setShowPassword(!showPassword);
    }

    return (
        <div className="form-row">
            <div className="form-field">
                <ShowHide
                    evaluator={props.headingText != null}
                    show={(
                        <h4 className={classNames({ "bp3-skeleton": props.loading })}>{props.headingText}</h4>
                    )}
                />
                <FormHelperText loading={props.loading}>{props.helperText}</FormHelperText>
                <InputGroup
                    id={props.id}
                    className={classNames({ "bp3-skeleton": props.loading })}
                    disabled={props.disabled}
                    value={props.value}
                    onChange={props.onChange}
                    large={props.large}
                    placeholder={props.placeholder}
                    maxLength={props.maxLength}
                    inputRef={props.inputRef}
                    type={showPassword ? "text" : "password"}
                    rightElement={(
                        <Tooltip position={Position.RIGHT} content={`${showPassword ? "Hide" : "Show"} Password`}>
                            <Button
                                icon={showPassword ? "unlock" : "lock"}
                                minimal={true}
                                onClick={onPasswordToggle}
                                tabIndex="-1"
                            />
                        </Tooltip>
                    )}
                />
           
                <FormHelperText loading={props.loading} danger={true}>{props.dangerHelperText}</FormHelperText>
            </div>
        </div>
    );
}

FormPasswordInput.defaultProps = {
    id: null,
    value: "",
    loading: false,
    headingText: null,
    helperText: null,
    disabled: false,
    dangerHelperText: null,
    placeholder: "",
    large: false,
    maxLength: null
};

FormPasswordInput.propTypes = {
    id: PropTypes.string,
    value: PropTypes.string,
    onChange: PropTypes.func,
    loading: PropTypes.bool,
    headingText: PropTypes.string,
    helperText: PropTypes.node,
    disabled: PropTypes.bool,
    dangerHelperText: PropTypes.node,
    placeholder: PropTypes.string,
    large: PropTypes.bool,
    inputRef: PropTypes.object,
    maxLength: PropTypes.number
};
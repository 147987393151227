import ApiService from "services/ApiService";
import moment from "moment";
import axios from 'axios';
import { BlobStorageService } from 'services';

const badRequestCode = 400;

export const SelfReportingService = {
    getReportList(pageSize, pageNumber, searchTerm, sortField, sortDir) {
      return new Promise((resolve, reject) => {
        ApiService.get(
          `/reporting/reports?requestCount=${pageSize}&pageNumber=${pageNumber}&searchTerm=${encodeURI(
            searchTerm
          )}&sortField=${encodeURI(sortField)}&sortDirection=${sortDir}`
        ).then(
          (result) => {
            resolve(result.data);
          },
          (error) => {
            reject(error);
          }
        );
      });
    },

    getReport(id, refreshParams) {
        return new Promise((resolve, reject) => {
            ApiService.get(`/reporting/reports/${id}?refreshParameters=${refreshParams}`).then(
                (result) => {
                    resolve(result.data);
                },
                (error) => {
                    reject(error.response);
                });
        });
    },

    getReportGroups() {
        return new Promise((resolve, reject) => {
            ApiService.get(`/reporting/reportGroups`).then(
                (result) => {
                    resolve(result.data);
                },
                (error) => {
                    reject(error);
                });
        });
    },

    getReportTypes() {
        return new Promise((resolve, reject) => {
            ApiService.get(`/reporting/options/ReportTypes`).then(
                (result) => {
                    resolve(result.data);
                },
                (error) => {
                    reject(error);
                });
        });
    },

    getReportParameterTypes() {
        return new Promise((resolve, reject) => {
            ApiService.get(`/reporting/Options/ReportParameterTypes`).then(
                (result) => {
                    resolve(result.data);
                },
                (error) => {
                    reject(error);
                });
        });
    },

    getUnallocatedReports() {
        return new Promise((resolve, reject) => {
            ApiService.get(`/reporting/reports/unallocated`).then(
                (result) => {
                    resolve(result.data);
                },
                (error) => {
                    reject(error);
                });
        });
    },

    createReport(report) {
        return new Promise((resolve, reject) => {
            ApiService.post(`/reporting/reports`, report).then(
                (result) => {
                    resolve(result.data);
                },
                (error) => {
                    returnError(reject, error, "Unable to create report, please try again.");
                });
        });
    },

    saveReport(reportId, report) {
        return new Promise((resolve, reject) => {
            ApiService.put(`/reporting/reports/${reportId}`, report).then(
                (result) => {
                    resolve(result.data);
                },
                (error) => {
                    returnError(reject, error, "Unable to save report, please try again.");
                });
        });
    },

    getReportParameters(reportUrl) {
        return new Promise((resolve, reject) => {
            ApiService.get(`/reporting/ReportParameters?reportUrl=${encodeURIComponent(reportUrl)}`).then(
                (result) => {
                    resolve(result.data);
                },
                (error) => {
                    returnError(reject, error, "Unable to save report, please try again.");
                });
        });
    },

    getFavourites() {
        return new Promise((resolve, reject) => {
            ApiService.get(`/reporting/Reports/Favourite`).then(
                (result) => {
                    resolve(result.data);
                },
                (error) => {
                    reject(error);
                });
        });
    },

    addFavourite(reportId) {
        return new Promise((resolve, reject) => {
            ApiService.post(`/reporting/Reports/${reportId}/Favourite`).then(
                (result) => {
                    resolve(result.data);
                },
                (error) => {
                    reject(error);
                });
        });
    },

    removeFavourite(reportId) {
        return new Promise((resolve, reject) => {
            ApiService.delete(`/reporting/Reports/${reportId}/Favourite`).then(
                (result) => {
                    resolve(result.data);
                },
                (error) => {
                    reject(error);
                });
        });
    },

  generateReport(reportId, reportRequest) {
    return new Promise((resolve, reject) => {
      ApiService.post(
        `/reporting/Reports/${reportId}/Generate`,
        reportRequest,
      ).then(
        (result) => {
          resolve(result.data);
        },
        (error) => {
            returnError(
                reject,
                error,
                "Unable to generate report, please try again."
            );
        }
      );
    });
  },

    getRecentlyRun() {
        return new Promise((resolve, reject) => {
            ApiService.get(`/reporting/Reports/History`).then(
                (result) => {
                    resolve(result.data);
                },
                (error) => {
                    reject(error);
                });
        });
    },

    getSchedulesList(pageSize, pageNumber, searchTerm, sortField, sortDir, filters) {

        return new Promise((resolve, reject) => {
            ApiService.get(`/reporting/schedules?requestCount=${pageSize}&pageNumber=${pageNumber}&searchTerm=${encodeURI(searchTerm)}&sortField=${encodeURI(sortField)}&sortDirection=${sortDir}&${generateFilterString(filters)}`).then(
                (result) => {
                    resolve(result.data);
                },
                (error) => {
                    reject(error);
                });
        });
    },

    deleteSchedule(scheduleId) {
        return new Promise((resolve, reject) => {
            ApiService.delete(`/reporting/schedules/${scheduleId}`).then(
                (result) => {
                    resolve(result.data);
                },
                () => {
                    reject("Unable to delete the schedule, please try again.");
                });
        });
    },

    createSchedule(schedule) {
        return new Promise((resolve, reject) => {
            ApiService.post(`/reporting/schedules`, schedule).then(
                (result) => {
                    resolve(result.data);
                },
                (error) => {
                    if (error.response.status === badRequestCode) {
                        reject(error.response.data);
                    } else {
                        reject("Unable to create the schedule, please try again.");
                    }
                });
        });
    },

    saveSchedule(scheduleId, schedule) {
        return new Promise((resolve, reject) => {
            ApiService.put(`/reporting/schedules/${scheduleId}`, schedule).then(
                (result) => {
                    resolve(result.data);
                },
                (error) => {
                    returnError(reject, error, "Unable to save schedule, please try again.");
                });
        });
    },

    getScheduleStatuses() {
        return new Promise((resolve, reject) => {
            ApiService.get(`/reporting/Options/ScheduleStatuses`).then(
                (result) => {
                    resolve(result.data);
                },
                (error) => {
                    reject(error);
                });
        });
    },

  getGenerationRequests() {
    return new Promise((resolve, reject) => {
      ApiService.get(`/reporting/Reports/GenerationRequests`).then(
        (result) => {
          resolve(result.data);
        },
        (error) => {
          reject(error);
        }
      );
    });
  },

    checkReportGenerationStatus(reportId, generationId, cancellationToken) {

        var config = {};
        if (cancellationToken != null)
        {
            config.cancelToken = cancellationToken;
        }

        return new Promise((resolve, reject) => {
            ApiService.get(`/reporting/Reports/${reportId}/Generate/${generationId}`, config).then(
                (result) => {
                    resolve(result);
                },
                (error) => {
                    reject(error);
                }
            );
        });
    },

    getSchedule(scheduleId) {
        return new Promise((resolve, reject) => {
            ApiService.get(
                `/reporting/Schedules/${scheduleId}`,
            ).then(
                (result) => {

                    var scheduleResponse = result.data;

                    scheduleResponse.reportInstances.forEach(r => {
                        r.name = r.reportName;
                        r.valid = true;

                        var reportParameters = r.parameters.parameters;

                        r.parameters = {
                            reportType: r.parameters.reportType,
                            parameters: r.reportParameters.map(function (rp) {
                                return { "Key": rp.name, "Value": reportParameters.find(c => c.key === rp.name)?.value, "Type": rp.parameterType.key }
                            })
                        };

                        r.reportParameters = r.reportParameters.filter(function (rp) {
                            return rp.parameterType.key !== "LocationList" && rp.parameterType.key !== "DriverList";
                        });

                        r.formats = r.reportTypes.map(function (rt) {
                            return { value: rt.id, label: rt.name }
                        });
                    });

                    resolve(scheduleResponse);
                },
                (error) => {
                    reject(error.response);
                }
            );
        });
    },

    getScheduleRuns(scheduleId) {
        return new Promise((resolve, reject) => {
            ApiService.get(
                `/reporting/Schedules/${scheduleId}/Runs`,
            ).then(
                (result) => {
                    resolve(result.data);
                },
                (error) => {
                    reject(error);
                }
            );
        });
    },

    rerunSchedule(scheduleInstanceId) {
        return new Promise((resolve, reject) => {
            ApiService.post(`/reporting/ScheduleInstances/${scheduleInstanceId}/Rerun`).then(
                (result) => {
                    resolve(result.data);
                },
                (error) => {
                    reject(error);
                });
        });
    },

    rerunScheduleForUser(scheduleInstanceId) {
        return new Promise((resolve, reject) => {
            ApiService.post(`/reporting/ScheduleInstances/${scheduleInstanceId}/Rerun/User`).then(
                (result) => {
                    resolve(result.data);
                },
                (error) => {
                    reject(error);
                });
        });
    },

    getScheduleInstances(pageSize, pageNumber, searchTerm, sortField, sortDir, filters) {
        return new Promise((resolve, reject) => {
            ApiService.get(`/reporting/ScheduleInstances?requestCount=${pageSize}&pageNumber=${pageNumber}&searchTerm=${encodeURI(searchTerm)}&sortField=${encodeURI(sortField)}&sortDirection=${sortDir}&${generateFilterString(filters)}`).then(
                (result) => {
                    resolve(result.data);
                },
                (error) => {
                    reject(error);
                });
        });
    },

    getScheduleInstance(instanceId) {
        return new Promise((resolve, reject) => {
            ApiService.get(`/reporting/ScheduleInstances/${instanceId}`).then(
                (result) => {
                    resolve(result.data);
                },
                (error) => {
                    reject(error.response);
                });
        });
    },

    getScheduledReportToken(instanceId, reportInstanceId) {
        return new Promise((resolve, reject) => {
            ApiService.get(
                `/reporting/ScheduleInstances/${instanceId}/Report/${reportInstanceId}`,
            ).then(
                (result) => {
                    resolve(result.data);
                },
                (error) => {
                    reject(error);
                }
            );
        });
    },

    getScheduleErrors(scheduleId, pageSize, pageNumber) {
        return new Promise((resolve, reject) => {
            ApiService.get(
                `/reporting/Schedules/${scheduleId}/Errors?requestCount=${pageSize}&pageNumber=${pageNumber}`,
            ).then(
                (result) => {
                    resolve(result.data);
                },
                (error) => {
                    reject(error);
                }
            );
        });
    },

    getErroredSchedules(pageSize, pageNumber, searchTerm) {
        return new Promise((resolve, reject) => {
            ApiService.get(
                `/reporting/Schedules/Errors?requestCount=${pageSize}&pageNumber=${pageNumber}&searchTerm=${encodeURI(searchTerm)}`,
            ).then(
                (result) => {
                    resolve(result.data);
                },
                (error) => {
                    reject(error);
                }
            );
        });
    },

    retrySchedule(scheduleId, runId) {
        return new Promise((resolve, reject) => {
            ApiService.post(
                `/reporting/Schedules/${scheduleId}/Runs/${runId}/Retry`,
            ).then(
                (result) => {
                    resolve(result.data);
                },
                (error) => {
                    returnError(reject, error, "Unable to retry failed reports, please try again.");
                }
            );
        });
    },
    
    checkInstantReportGenerationStatus(generationId, cancellationToken) {

        var config = {};
        if (cancellationToken != null) {
            config.cancelToken = cancellationToken;
        }

        return new Promise((resolve, reject) => {
            ApiService.get(`/reporting/InstantReport/Generate/${generationId}`, config).then(
                (result) => {
                    resolve(result);
                },
                (error) => {
                    reject(error);
                }
            );
        });
    },
    getInstantReport(generationId, generationCancelTokenSource, onReportDownloadComplete){
        let reportCheckTimer;
        const checkTimeout = 5000;

        reportCheckTimer = setInterval(() => {
            var notReadyStatus = 204;
            generationCancelTokenSource.current = axios.CancelToken.source();
            var throwError = function () {
                clearInterval(reportCheckTimer);
                onReportDownloadComplete(false);
            }

            this.checkInstantReportGenerationStatus(generationId, generationCancelTokenSource.current.token).then(function (response) {
                if (response.status === notReadyStatus) {
                    return;
                }
                else {
                    clearInterval(reportCheckTimer);
                    BlobStorageService.downloadFile(response.data.storageName, response.data.containerName, response.data.fileName, response.data.token, response.data.downloadName).then(function () {
                        onReportDownloadComplete(true);
                    }, throwError);
                }
            }, throwError);
        }, checkTimeout);
    },
    getScheduleRun(scheduleId, runId) {
        return new Promise((resolve, reject) => {

            ApiService.get(`/reporting/Schedules/${scheduleId}/Runs/${runId}`).then(
                (result) => {
                    resolve(result.data);
                },
                (error) => {
                    reject(error.response);
                });
        });
    },
    calculate445EndDate(startDate) {

        var fourWeekPeriod = 4;
        var fiveWeekPeriod = 5;
        var sixWeekPeriod = 5;
        var weeksInIsoPeriod = 13;
        var weeksInLeapYear = 53;
        var lastIsoPeriodOfYearWeekNumber = 48;

        var startIsoWeek = moment(startDate).startOf('isoWeek');
        var firstIsoWeekOfYear = moment(startIsoWeek).isoWeek(1);

        var fourFourFiveStart;
        var fourFourFiveNextStart = moment(firstIsoWeekOfYear).add(fourWeekPeriod, 'w');
        var fourFourFiveEnd = moment(fourFourFiveNextStart).add(-1, 'd')

        var periodWeeks = 9;

        //Work out the current period we are in

        while (fourFourFiveEnd.isBefore(startIsoWeek)) {

            fourFourFiveStart = moment(fourFourFiveNextStart);

            if (periodWeeks > fiveWeekPeriod) {
                fourFourFiveNextStart.add(fourWeekPeriod, 'w');
                periodWeeks -= fourWeekPeriod;
            } else {

                if (fourFourFiveStart.isoWeek() === lastIsoPeriodOfYearWeekNumber && fourFourFiveStart.isoWeeksInYear() === weeksInLeapYear) {
                    fourFourFiveNextStart.add(sixWeekPeriod, 'w');
                } else {
                    fourFourFiveNextStart.add(fiveWeekPeriod, 'w');
                }

                periodWeeks = weeksInIsoPeriod;
            }

            fourFourFiveEnd = moment(fourFourFiveNextStart).add(-1, 'd');
        }

        return fourFourFiveEnd;
    }
};

function returnError(reject, error, errorMessage) {
    if (error.response.status === badRequestCode) {
        reject(error.response.data);
    } else {
        reject(errorMessage);
    }
}

function generateFilterString(filters) {

    var queryFilters = "";

    filters.forEach(function (element, index) {
        queryFilters += `filters[${index}].Key=${encodeURIComponent(element.name)}&filters[${index}].Value=${encodeURIComponent(element.value)}&`
    });

    return queryFilters;
}

import React, { useEffect, useState, Fragment } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';

export function UserAccess(props) {

    const actions = useSelector(state => state.loggedInUser.actions);
    const [shownContent, setShownContent] = useState(null);

    useEffect(() => {
        if (actions != null) {
            setShownContent(checkPermissions(props.perform, actions) ? props.yes : props.no);
        }
    }, [props.yes, props.no, props.perform, actions]);

    return shownContent == null ? <Fragment /> : shownContent;
}

function checkPermissions(action, actions) {

    if (action == null) {
        return true;
    }

    if (actions == null) {
        return false;
    }

    var allowedActions = actions.map((a) => {
        return a.toLowerCase()
    });

    //If the action is an array of actions, check we have one of the roles
    if (Array.isArray(action)) {
        var matchedActions = action.filter(a => allowedActions.includes(a.toLowerCase()));
        return matchedActions.length > 0;
    } else {
        return allowedActions.includes(action.toLowerCase());
    }
}

export const UserHasAccess = function (action) {
    const actions = useSelector(state => state.loggedInUser.actions);
    return checkPermissions(action, actions);
}

export const UserHasAccessActions = function (actionsToCheck, userActions) {
    return checkPermissions(actionsToCheck, userActions);
}

UserAccess.defaultProps = {
    yes: () => null,
    no: () => null
};

UserAccess.propTypes = {
    perform: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.array
    ]),
    yes: PropTypes.func,
    no: PropTypes.func
};

import React, { Fragment } from 'react';
import PropTypes from 'prop-types';

import { Button, ButtonSave } from 'components/buttons';
import { Dialog } from '@blueprintjs/core';

export function AlertConfirm(props) {

    function handleConfirmClick() {
        props.onConfirm();
    }

    function handleCancelClick() {
        props.onCancel();
    }

    return (
        <Fragment>

            <Dialog
                isOpen={props.isOpen}
                title={props.title}
                isCloseButtonShown={false}
            >
                <div className="bp3-dialog-body">
                    {props.children}
                </div>

                <div className="bp3-dialog-footer">
                    <div className="bp3-dialog-footer-actions">
                        <ButtonSave icon={null} text={props.confirmButtonText} intent={props.confirmButtonIntent} onClick={handleConfirmClick} simpleDisabled={props.disabled} disabled={props.saving} id="confirm-button" />
                        <Button text={props.cancelButtonText} intent={props.cancelButtonIntent} onClick={handleCancelClick} disabled={props.saving} id="cancel-button"></Button>
                    </div>
                </div>
              
            </Dialog>
   
        </Fragment>
    );
}

AlertConfirm.defaultProps = {
    isOpen: false,
    title: "",
    disabled: false,
    saving: false,
    confirmButtonIntent: "danger",
    confirmButtonText: "Confirm",
    cancelButtonIntent: "secondary",
    cancelButtonText: "Cancel"
};

AlertConfirm.propTypes = {
    isOpen: PropTypes.bool.isRequired,
    onConfirm: PropTypes.func.isRequired,
    onCancel: PropTypes.func.isRequired,
    title: PropTypes.string,
    disabled: PropTypes.bool,
    confirmButtonIntent: PropTypes.string,
    confirmButtonText: PropTypes.string,
    cancelButtonIntent: PropTypes.string,
    cancelButtonText: PropTypes.string,
    saving: PropTypes.bool
};

import React, { useState, useEffect, Fragment, useRef } from 'react';
import { Link } from "react-router-dom";

import { Breadcrumbs } from 'components/navigation';
import { FormDateRangeInput, FormLocationSelector } from 'components/form-fields';
import { Button} from 'components/buttons'
import { ListingTable } from "components/listing";
import { PageRestricted } from 'pages/errors/page-restricted';
import { UserAccess, UserHasAccess } from 'components/user-access';
import { JobService } from 'services';
import { ShowHide } from "components/layout";
import classNames from "classnames";
import moment from "moment";
import { Tooltip } from 'components/tooltip';
import { Filter } from 'components/filtering';
import { useSelector, useDispatch } from 'react-redux';
import { SetFiltering } from 'state/actions';
import { DateToLocal } from "components/dates";
import { Icon } from "@blueprintjs/core";

export function ReviewListing(props) {

    const defaultRecordSize = 25;

    const [totalRecords, setTotalRecords] = useState(0);
    const [tableRows, setTableRows] = useState([]);
    const [pageNumber, setPageNumber] = useState(1);
    const [pageSize, setPageSize] = useState(defaultRecordSize);
    const [loadingData, setLoadingData] = useState(true);
    const [tableSortBy, setTableSortBy] = useState({ headerName: "Date", sortName: "COMPLETEDSCHEDULEDDATE" });
    const [tableSortDir, setTableSortDir] = useState("D");
    const [showFilters, setShowFilters] = useState(false);
    const [appliedFilters, setAppliedFilters] = useState([]);
    const [pageFilters, setPageFilters] = useState([]);
    const [startDate, setStartDate] = useState(null);
    const [endDate, setEndDate] = useState(null);
    const [tableHeaders, settableHeaders] = useState(["Review ID", "Product Name", "Date", "Visit Location", "Review Location", "Status", "Notes", "Score"]);

    const noDataMessage = "No Reviews found.";
    const tableHeadersInternal = ["Review ID", "Product Name", "Internal ID", "Date", "Visit Location", "Review Location", "Status", "Notes", "Score"];
    const sortableHeaders = [{ headerName: "Date", sortName: "COMPLETEDSCHEDULEDDATE" }, { headerName: "Visit Location", sortName: "LOCATIONNAME" }, { headerName: "Review Location", sortName: "REVIEWLOCATIONNAME" }];
    const requiredAction = "ComplianceReview:View";
    const canAccessPage = UserHasAccess(requiredAction);
    const internalOcrUser = UserHasAccess("Internal:OCR");
    const doneState = "Done";
    const doneIncompleteState = "Done (Incomplete)";
    const failedAppointmentState = "Failed Appointment";
    const dateFilterName = "reviewDates";
    const reviewLocationHierarchyName = "reviewLocationHierarchy";

    const selectedDates = useSelector(state => state.filters[dateFilterName]);
    const reduxLocationInfo = useSelector(state => state.filters[reviewLocationHierarchyName]);
    const dispatch = useDispatch();

    const [selectedLocations, setSelectedLocations] = useState(null);
    const [useHierarchy, setUseHierarchy] = useState(true);
    const locationListOnFilterOpen = useRef(selectedLocations);
    const [useLocationDefaults, setUseLocationDefaults] = useState(null);

    useEffect(() => {
        //The ordering will be logical rather than alphabetical
        const newState = "New";
        const pendingState = "Pending";
        const readyState = "Ready";
        const inProgressState = "In Progress";

        let itemStates = []

        if (internalOcrUser) {
            settableHeaders(tableHeadersInternal);

            itemStates = [
                { "id": newState, "name": newState },
                { "id": pendingState, "name": pendingState },
                { "id": readyState, "name": readyState },
                { "id": inProgressState, "name": inProgressState }
            ];
        }

        itemStates = [...itemStates,
        { "id": doneState, "name": doneState },
        { "id": doneIncompleteState, "name": doneIncompleteState },
        { "id": failedAppointmentState, "name": failedAppointmentState },
        ];

        var filterArray = [
            {
                "displayName": "Status", "name": "State", "items": [
                    ...itemStates
                ]
            }
        ];

        setPageFilters(filterArray);

    }, []);


    useEffect(() => {
        //This will trigger if the data changes, but will only process if the loadingData is set to true.
        if (loadingData && canAccessPage && pageFilters.length > 0 &&
            ((startDate && endDate) || (!startDate && !endDate))
            && selectedLocations != null) {

            const filterStateKey = "State";

            let mappedFilters = appliedFilters.map(function (f) {
                return {
                    key: f.name,
                    ...f
                }
            });

            //The first entry just gets us the Compliance reviews for the time being
            let filtersArray = [...mappedFilters,
                { "key": "ProductGroup", "value": "Onsite" },
            ];

            if (startDate && endDate) {
                filtersArray = [...filtersArray,
                { "key": "CompletedScheduledStartDate", "value": startDate },
                { "key": "CompletedScheduledEndDate", "value": endDate }
                ];
            }

            //Annoyingly for non-internal users I can't just leave the fileters off as they should only see done ones
            if (appliedFilters.length < 1 && !internalOcrUser) {
                filtersArray = [...filtersArray,
                    { "key": filterStateKey, "value": doneState },
                    { "key": filterStateKey, "value": doneIncompleteState },
                    { "key": filterStateKey, "value": failedAppointmentState }
                ];
            }

            if (selectedLocations != null) {
                selectedLocations.forEach(function (l) {
                    filtersArray = [...filtersArray,
                        { "key": "Location", "value": l }
                    ];
                });
            }

            const jobsRequest = {
                requestCount: pageSize,
                pageNumber: pageNumber,
                sortField: tableSortBy.sortName,
                sortDirection: tableSortDir,
                filters: filtersArray
            };

            JobService.getOcrJobs(jobsRequest).then(r => {
                let data = [];
                let previsitLinkStatus = ['New', 'Pending', 'Ready'];
                let nonOcrCompletedStates = ['Done', 'Failed Appointment', 'Cancelled'];

                if (r?.data != null) {
                    data = r.data.map((d) => {
                        let jobLinkUrl = '/reviews/compliance-review/';
                        if (previsitLinkStatus.includes(d.state)) {
                            jobLinkUrl = jobLinkUrl + 'pre-visit/';
                        }

                        if (internalOcrUser) {
                            return [d.jobId, (<Link to={jobLinkUrl + d.jobId}>{!d.productDescription ? "Compliance Review" : d.productDescription}</Link>),
                            d.pubsJobId,
                            <DateToLocal key={`csd-${d.jobId}`} format='DD/MM/YYYY'>{d.completedScheduledDate}</DateToLocal>,
                            d.locationName, d.reviewLocations ? d.reviewLocations : '', d.state, getJobNote(d.jobNote), `${d.ocrVisitScore ? d.ocrVisitScore + '%' : ''}`];
                        }


                        if (!d.isOcr && nonOcrCompletedStates.includes(d.state)) {
                            let row = [d.pubsJobId,
                                <DateToLocal key={`csd-${d.jobId}`} format='DD/MM/YYYY'>{d.completedScheduledDate}</DateToLocal>,
                                d.locationName, d.reviewLocations ? d.reviewLocations : '', d.state, getJobNote(d.jobNote),`${d.ocrVisitScore ? d.ocrVisitScore + '%' : ''}`];

                            
                            if (d.state === 'Cancelled') {
                                row.unshift(!d.productDescription ? "Compliance Review" : d.productDescription);
                            }
                            else {
                                row.unshift(<a href={`${window.env.VISION_URL}/#/WorkItemReport/Job/${d.pubsJobId}`}>{!d.productDescription ? "Compliance Review" : d.productDescription}</a>);
                            }
                            row.unshift(d.jobId);
                            return row;
                        }

                        return [d.jobId, (<Link to={jobLinkUrl + d.jobId}>{!d.productDescription ? "Compliance Review" : d.productDescription}</Link>),
                            <DateToLocal key={`csd-${d.jobId}`} format='DD/MM/YYYY'>{d.completedScheduledDate}</DateToLocal>,
                            d.locationName, d.reviewLocations ? d.reviewLocations : '', d.state, getJobNote(d.jobNote), `${d.ocrVisitScore ? d.ocrVisitScore + '%' : ''}`];
                    })
                }

                setTableRows(data);
                setTotalRecords(r.totalCount);
                setLoadingData(false);

            });
        }

    }, [loadingData, pageSize, pageNumber, tableSortBy, tableSortDir, appliedFilters, selectedLocations]);

    useEffect(() => {
        if (!loadingData) {
            dispatch(SetFiltering(reviewLocationHierarchyName,
                {
                    list: selectedLocations,
                    hierarchySelected: useHierarchy
                }
            ));
        }
    }, [selectedLocations, useHierarchy]);

    useEffect(() => {
        //We know the redux store has loaded now
        setDates();
    }, [selectedDates]);

    useEffect(() => {
        setReduxLocationInfo();
    }, [reduxLocationInfo]);

    function getJobNote(jobNote) {
        return jobNote !== null && jobNote !== '' ? <div className="text-align-center" title={jobNote}><Icon icon="list" /></div> : <div></div>;
    }

    function setDates() {
        const defaultMonthsBack = -3;

        if (selectedDates && selectedDates.startDate !== undefined && selectedDates.endDate !== undefined) {
            setStartDate(selectedDates.startDate?.format("YYYY-MM-DD"));
            setEndDate(selectedDates.endDate?.format("YYYY-MM-DD"));
        } else {
            setStartDate(moment().add(defaultMonthsBack, 'M').format("YYYY-MM-DD"));

            if (internalOcrUser) {
                setEndDate(moment().format("YYYY-MM-DD"));
            } else {
                setEndDate(moment().add(1, 'M').format("YYYY-MM-DD"));
            }
        }
        setLoadingData(true);
    }

    function setReduxLocationInfo() {
        if (reduxLocationInfo?.hierarchySelected != null) {
            setSelectedLocations(reduxLocationInfo.list);
            setUseHierarchy(reduxLocationInfo.hierarchySelected);
            setUseLocationDefaults(false);
        } else {
            setUseLocationDefaults(true);
        }
    }

    function onPagingChange(newPageNumber, newPageSize) {
        setPageNumber(newPageNumber);
        setPageSize(newPageSize);
        setLoadingData(true);
    }

    function onTableSort(header, direction) {
        setTableSortBy(header);
        setTableSortDir(direction);
        setLoadingData(true);
    }

    function onToggleFilter() {
        setShowFilters((prevState) => {
            return !prevState;
        })
    }

    function onFilterChange(listingFilters) {
        setLoadingData(true);
        setPageNumber(1);
        setAppliedFilters(listingFilters);

        if (listingFilters.length > appliedFilters.length) {
            setShowFilters(true);
        }
    }

    function onDateRangeChange(item) {
        if ((item?.startDate?.date != null && item?.endDate?.date != null || item?.startDate?.date == null && item?.endDate?.date == null)) {
            setPageNumber(1);

            //Using the redux store should mean the setDates is called
            dispatch(SetFiltering(dateFilterName, {
                startDate: item.startDate?.date,
                endDate: item.endDate?.date
            }));
        }
    }

    function onLocationListOpened() {
        locationListOnFilterOpen.current = selectedLocations;
    }

    function onLocationListClose() {
        if (locationListOnFilterOpen.current.toString() !== selectedLocations.toString()) {
            setLoadingData(true);
        }
    }

    return (
        <UserAccess perform={requiredAction}
            yes={() => (
                <div className="row">

                    <Breadcrumbs items={props.breadcrumbs} />

                    <h1>Reviews</h1>

                    <ShowHide
                        evaluator={tableRows.length === 0 && !loadingData && pageFilters.length === 0}
                        hide={(
                            <Fragment>
                                <div className={classNames("pull-left", { "spacer-bottom": !showFilters })}>
                                    <div className="inline-items">
                                        <FormLocationSelector
                                            businessArea={"Tacho"}
                                            loading={loadingData}
                                            selectedLocations={selectedLocations}
                                            setSelectedLocations={setSelectedLocations}
                                            useHierarchy={useHierarchy}
                                            setUseHierarchy={setUseHierarchy}
                                            useLocationDefaults={useLocationDefaults}
                                            locationDefaultsToTrue={true}
                                            onLocationListClose={onLocationListClose}
                                            onLocationListOpened={onLocationListOpened}
                                        />
                                        <FormDateRangeInput
                                            onChange={onDateRangeChange}
                                            startDate={startDate}
                                            endDate={endDate}
                                            disabled={loadingData}
                                            headingText={""}
                                            large
                                        ></FormDateRangeInput>
                                        <Tooltip content="Filter" position="right">
                                            <Button icon="filter" onClick={onToggleFilter} className={classNames({ "active": appliedFilters.length > 0 })} />
                                        </Tooltip>
                                    </div>
                                </div>
                                <Filter filterName="reviews" visible={showFilters} filters={pageFilters} onUpdate={onFilterChange}/>
                            </Fragment>
                        )}
                    />

                    <ListingTable
                        id="listing-table-location"
                        headers={tableHeaders}
                        data={tableRows}
                        totalRecordCount={totalRecords}
                        onPagingChange={onPagingChange}
                        loadingData={loadingData}
                        noDataMessage={noDataMessage}
                        sortable
                        sortableHeaders={sortableHeaders}
                        sortedBy={tableSortBy}
                        sortedDir={tableSortDir}
                        onSort={onTableSort}
                        searchTerm={""}
                    />
                </div>
            )}
            no={() => (
                <PageRestricted />
            )}
        />
    );
}
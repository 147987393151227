import React, { useEffect, useState, useRef } from "react";
import PropTypes from "prop-types";
import { Icon } from "@blueprintjs/core";
import './ActivitiesList.css';
import { ListingTable } from "../listing";
import moment from "moment";
import { DateToLocal } from "components/dates/DateToLocal";
import { FormCheckbox } from "components/form-fields";

export function ActivitiesList(props){

    const [mappedActivityRows, setMappedActivityRows] = useState([]);
    const [mappedActivityRowClasses, setMappedActivityRowClasses] = useState([]);
    const [selectedRowIndex, setSelectedRowIndex] = useState();
    const selectedActivitiesIdPrefix = "act-list-";
    const [selectAll, setSelectAll] = useState(false)   

    const activityTypeMappings = [{ typeId: 0, icon: "break.png", name: "Rest" }, { typeId: 1, icon: "available.png", name: "Available" }, { typeId: 2, icon: "work.png", name: "Work" }, { typeId: 3, icon: "driving.png ", name: "Driving" }];
    const scrollTo = useRef();

    let columnHeaders = [<div key="act-list-colour-header"></div>, "Activity Type", "Time", "Date", "Length"];

    if (props.selectable) {
        columnHeaders.push(<div key="act-list-select-all" className="form-field">Select All <FormCheckbox checked={selectAll} disabled={props.isLoading} onChange={(x) => toggleSelectAll(x)} /></div>);
    }

    useEffect(() => {
        if(props.selectedActivities.length !== props.activitiesList.length) {
            setSelectAll(false);
        }
        
        mapRows(props.activitiesList, selectedRowIndex);
    }, [selectedRowIndex, props.selectedActivities]);

    useEffect(() => {
        if (props.highlightedActivity) {
            let activityIndex = props.activitiesList.findIndex((a) => a.startDate === props.highlightedActivity.startDate);

            if (activityIndex >= 0 && activityIndex !== selectedRowIndex) {
                setSelectedRowIndex(activityIndex);

                setTimeout(() => {
                    if (scrollTo.current) {
                        scrollTo.current.scrollIntoView({ behavior: 'smooth', block: 'center' });
                    }
                } , 100);
            }
        }
    }, [props.highlightedActivity]);

    function toggleSelectAll(event) {
        setSelectAll(event.target.checked);
        props.selectAll(event.target.checked)
    }

    function toggleSelectRow(e) {
        let {id, checked} = e.target;
        id = id.substring(selectedActivitiesIdPrefix.length);
        props.onActivityCheckChange(id, checked);
    }

    function onRowClick(rowIndex) {
        setSelectedRowIndex(rowIndex);
        props.onActivitySelected(props.activitiesList[rowIndex]);
    }

    function mapRows(activities, highlightedIndex)
    {
        let rows = [];
        let rowClasses= [];
        for (let i = 0; i < activities.length; i++) {

            const activityType = activityTypeMappings.find((m) => m.typeId === activities[i].typeId);
            let cols = [
                <div key={moment(activities[i].startDate).format("MMDDHHmm")} className="listing-left-bar" style={{ backgroundColor: activities[i].bgColor }} ref={i === highlightedIndex ? scrollTo : null}></div>,
                <><div className="activity-list-icon"><Icon icon={<img src={`/icons/activity-types/${activityType.icon}`} className="vu-activity-icon" alt={activityType.name} />} iconSize={12} /></div><div className="activity-list-name">{activityType.name}</div></>,
                <DateToLocal key={`${i}-time`} format="HH:mm">{activities[i].startDate}</DateToLocal>,
                <DateToLocal key={`${i}-date`} format="DD MMM YYYY">{activities[i].startDate}</DateToLocal>,
                `${Math.floor(activities[i].duration / 60)}h ${activities[i].duration % 60}m`
            ];
            if (props.selectable) {
               cols.push(<div className="form-field"><FormCheckbox key={`${selectedActivitiesIdPrefix}${activities[i].activityId}-checkbox`} id={`${selectedActivitiesIdPrefix}${activities[i].activityId}`} checked={props.selectedActivities.includes(activities[i].activityId.toString())} onChange={(x) => toggleSelectRow(x)} /></div>);
            }
            rows.push(cols);
            rowClasses.push(i === highlightedIndex && props.rowHighlighting ? "activity-list-highlighted" : null);
        }
        setMappedActivityRows(rows);
        setMappedActivityRowClasses(rowClasses);
    }

    useEffect(() => {
        mapRows(props.activitiesList);
    }, [props.activitiesList]);

    return (
        <div className="activities-list">
            <ListingTable 
                id={props.id}
                headers={columnHeaders}
                data={mappedActivityRows}
                columnClasses={["listing-left-bar", "activities-listing", "activities-listing", "activities-listing", "activities-listing", "activities-listing-checkbox"]}
                loadingData={props.isLoading}
                totalRecordCount={mappedActivityRows.length}
                pageable={false}
                allowTableScrollVertical={true}
                rowClickEnabled={true}
                onRowClick={onRowClick}
                rowClasses={mappedActivityRowClasses}
                noDataHeading="No activities"
            />
        </div>);
}

ActivitiesList.defaultProps = {
    id: "activities-list",
    activitiesList: [],
    isLoading: false,
    selectable: false,
    onActivitySelected: () => {},
    onActivityCheckChange: () => {},
    selectedActivities: [],
    selectAll: () => {},
    rowHighlighting: true
};

ActivitiesList.propTypes = {
    id: PropTypes.string,
    activitiesList: PropTypes.array,
    isLoading: PropTypes.bool,
    selectable: PropTypes.bool,
    onActivitySelected: PropTypes.func,
    onActivityCheckChange: PropTypes.func,
    selectedActivities: PropTypes.array,
    selectAll: PropTypes.func,
    rowHighlighting: PropTypes.bool,
    highlightedActivity: PropTypes.object
};
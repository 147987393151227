import React, { useState, useEffect } from 'react';

import { Intent, Icon } from '@blueprintjs/core';

import { Breadcrumbs } from 'components/navigation';
import { FormTextInput, FormTextArea } from "components/form-fields";
import { NotificationToaster } from "components/notifications";
import { ButtonSave, LinkButton } from "components/buttons";
import { ShowHide } from "components/layout";

import { UserAccess } from 'components/user-access';
import { useValidation } from 'hooks/useValidation';
import { PageRestricted } from 'pages/errors/page-restricted';
import { CustomDashboardService } from 'services/CustomDashboardService';

export function CustomDashboardCreate(props) {

    const customDashboardDefault = {
        name: "",
        description: ""
    };

    const [isValid, errors, validate] = useValidation();
    const [saving, setSaving] = useState(false);
    const [newId, setNewId] = useState(null);

    const [customDashboard, setCustomDashboard] = useState(customDashboardDefault);

    const requiredAction = "internal";

    const validationRules = [
        { fieldName: "name", required: true },
        { fieldName: "description", required: true }
    ];

    useEffect(() => {
        validate(validationRules, customDashboard);
    }, [customDashboard]);

    function onNameChange(event) {
        setCustomDashboard({
            ...customDashboard,
            name: event.target.value
        });
    }

    function onDescriptionChange(event) {
        setCustomDashboard({
            ...customDashboard,
            description: event.target.value
        });
    }

    function saveDahsboard() {
        if (!isValid) {
            NotificationToaster.show(Intent.WARNING, "There are errors on the page that need your attention");
            return;
        }

        setSaving(true);

        CustomDashboardService.createDashboard(customDashboard).then(
            (id) => {
                setNewId(id);
            },
            (error) => {
                setSaving(false);
                NotificationToaster.show(Intent.DANGER, error);
            }
        );
    }


    return (
        <UserAccess perform={requiredAction}
            yes={() => (
                <div className="row">

                    <Breadcrumbs items={props.breadcrumbs} />

                    <ShowHide
                        evaluator={!newId}
                        show={(
                            <div>
                                <h1 id="header-new-dashbaord">Add new custom dashboard</h1>

                                <FormTextInput
                                    id="dashboard-name"
                                    value={customDashboard.name}
                                    headingText="Enter the dashboard's name:"
                                    onChange={onNameChange}
                                    disabled={saving}
                                    dangerHelperText={errors.name}
                                />

                                <FormTextArea
                                    id="dashboard-description"
                                    value={customDashboard.description}
                                    headingText="Give your new dashboard a description:"
                                    onChange={onDescriptionChange}
                                    disabled={saving}
                                    dangerHelperText={errors.description}
                                />

                                <ButtonSave
                                    id="btn-dashboard-create-new"
                                    onClick={saveDahsboard}
                                    disabled={saving}
                                ></ButtonSave>
                            </div>
                        )}
                        hide={(
                            <div>
                                <div className="spacer-bottom" id="create-success-icon">
                                    <Icon icon="tick-circle" iconSize={40} intent={Intent.SUCCESS} />
                                </div>
                                <h2 id="create-success-name">{customDashboard.name} has been created</h2>
                                <div className="spacer-bottom">
                                    <p>You can now edit your new dashboard or view all of your custom dashboards.</p>
                                </div>
                                <div className="button-row" id="create-success-buttons">
                                    <LinkButton intent="primary" text="Custom dashboards" id="listing-dashboards" href="/dashboards/custom" />
                                    <LinkButton intent="primary" text="Edit your new dashboard" id="edit-dashboard" href={`/dashboards/custom/${newId}`} />
                                </div>
                            </div>
                        )}
                    />

                </div>
            )}
            no={() => (
                <PageRestricted />
            )}
        />
    );
}
import React, { useState, useEffect, Fragment } from 'react';
import { useParams, useLocation } from 'react-router-dom';

import { Intent, Icon } from '@blueprintjs/core';
import { NotificationToaster, AlertUnsavedData, NotificationInline } from "components/notifications";
import { LinkButton, Button, ButtonSave } from "components/buttons";
import { ShowHide } from "components/layout";
import { useDirtyData } from 'hooks/useDirtyData';
import { AccountService } from "services";
import { UserAccess } from 'components/user-access';
import { PageRestricted } from 'pages/errors/page-restricted';
import { IntegrationSettings } from 'pages/account/edit/integrations/credential-providers/shared';

export function AccountIntegrationsCreate(props) {

    const integrationModel = {
        providerId: 0,
        username: "",
        password: "",
        fleetName: "",
        token: "",
        services: []
    };

    const [originalSettings, setOriginalSettings] = useState(integrationModel);
    const [settingsValid, setSettingsValid] = useState(false);
    const [loading, setLoading] = useState(true);
    const [saving, setSaving] = useState(false);
    const [integrationCreated, setIntegrationCreated] = useState(false);
    const [integration, setIntegration] = useState(integrationModel);
    const isIntegrationDirty = useDirtyData(originalSettings, integration);
    const [providers, setProviders] = useState([]);

    const requiredAction = "Integrations:Manage";
    const { id } = useParams();
    const location = useLocation();
    const [token, setToken] = useState(null);

    useEffect(initialLoad, []);
    function initialLoad() {

        AccountService.getAvailableIntegrations().then(function (allIntegrations) {
            setLoading(false);

            const searchParams = new URLSearchParams(location.search);

            if (searchParams.has("code")) {
                setToken(searchParams.get("code"));
            }

            setProviders(allIntegrations.filter(function (i) {
                return i.available;
            }));
        });

    }

    function updateIntegration(updatedIntegration) {

        if (updatedIntegration.providerId === integration.providerId) {
            setIntegration(updatedIntegration);
        } else {
            setIntegration({
                ...integrationModel,
                providerId: updatedIntegration.providerId
            });
        }

    }

    useEffect(updateProviderFromQueryString, [id, providers, token])

    function updateProviderFromQueryString() {
        if (providers.length > 0 && id != null) {

            var selectedProvider = providers.find(x => x.providerKey.toLowerCase() === id.toLowerCase());
            if (selectedProvider != null) {
                setIntegration({
                    ...integrationModel,
                    providerId: selectedProvider.providerId,
                    token: token
                });
            }
        }
    }

    function onValidationUpdate(isValid) {
        setSettingsValid(isValid);
    }

    function saveIntegration() {
        setSaving(true);

        AccountService.createIntegration(integration).then(
            () => {
                setSaving(false);
                setIntegrationCreated(true);
                setOriginalSettings(integration);
            },
            (error) => {
                setSaving(false);
                NotificationToaster.show(Intent.DANGER, error);
            }
        );
    }
    function resetComponent() {
        setIntegration(integrationModel);
        setOriginalSettings(integrationModel);
        initialLoad();
        setSaving(false);
        setIntegrationCreated(false);
        setSettingsValid(false);
    }

    return (

        <UserAccess perform={requiredAction}
            yes={() => (

                <div>
                    <ShowHide
                        evaluator={!integrationCreated}
                        show={(
                            <div>
                                <h2>New Integration</h2>

                                <ShowHide
                                    evaluator={providers.length > 0 || loading}
                                    show={(
                                        <Fragment>
                                            <IntegrationSettings settings={integration} loading={loading} saving={saving} providers={providers} onUpdate={updateIntegration} onValidationUpdate={onValidationUpdate} token={token} />
                                            <ButtonSave onClick={saveIntegration} disabled={saving} simpleDisabled={!settingsValid} />
                                            <AlertUnsavedData
                                                isDirty={isIntegrationDirty}>
                                            </AlertUnsavedData>
                                        </Fragment>
                                    )}
                                    hide={(
                                        <Fragment>
                                            <NotificationInline show allowClose={false} intent="danger" text="There are no more providers to be created." />
                                            <LinkButton text="Back to listing" intent="primary" href="/account/integrations" />
                                        </Fragment>
                                    )}
                                />
                                
                            </div>
                        )}
                        hide={(
                            <div>
                                <div className="spacer-bottom" id="create-success-icon">
                                    <Icon icon="tick-circle" iconSize={40} intent={Intent.SUCCESS} />
                                </div>
                                <h2 id="create-success-name">Integration has been added successfully</h2>
                                <div className="spacer-bottom">
                                    <p>Data will begin importing from the next run time.</p>
                                </div>
                                <div className="button-row" id="create-success-buttons">
                                    <LinkButton intent="primary" text="Back to integrations" id="listing-integrations" href="/account/integrations" />
                                    <Button intent="primary" text="Add new integration" id="new-integration" onClick={resetComponent} />
                                </div>
                            </div>
                        )}
                    ></ShowHide>

                </div>

            )}
            no={() => (
                <PageRestricted />
            )}
        />


    );
}
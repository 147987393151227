import React, { useState, useEffect } from 'react';
import axios from 'axios';

import { useParams } from 'react-router-dom';

import { Intent } from '@blueprintjs/core';
import { Breadcrumbs } from 'components/navigation';
import { NotificationToaster, AlertUnsavedData } from "components/notifications";
import { ButtonSave } from "components/buttons";
import { useDirtyData } from 'hooks/useDirtyData';
import { SelfReportingService, LocationService } from "services";
import { UserAccess } from 'components/user-access';
import { PageRestricted } from 'pages/errors/page-restricted';
import { SelfReportingReportSettings } from 'pages/internal-admin/self-service-reports/shared';

var _ = require('underscore');

export function SelfReportingAdminEdit(props) {

    const { id } = useParams();
    const reportModel = {
        "url": "",
        "name": "",
        "description": "",
        "restrictDateRange": false,
        "restrictNumberOfMonths": 12,
        "internal": true,
        "reportGroups": [],
        "reportTypes": [],
        "reportParameters": []
    };

    const [originalSettings, setOriginalSettings] = useState(reportModel);
    const [loading, setLoading] = useState(true);
    const [saving, setSaving] = useState(false);
    const [report, setReport] = useState(reportModel);
    const isUserDirty = useDirtyData(originalSettings, report);
    const [reportGroups, setReportGroups] = useState([]);
    const [reportTypes, setReportTypes] = useState([]);
    const [reportParameterTypes, setReportParameterTypes] = useState([]);
    const [locationBusinessAreas, setLocationBusinessAreas] = useState([]);
    const [isValid, setIsValid] = useState(true);

    const requiredAction = "Internal";

    useEffect(initialLoad, []);

    function initialLoad() {

        axios.all([
            SelfReportingService.getReport(id, true),
            SelfReportingService.getReportGroups(),
            SelfReportingService.getReportTypes(),
            SelfReportingService.getReportParameterTypes(),
            LocationService.getLocationBusinessAreas()
        ]).then(axios.spread(function (reportResponse, reportGroupsResponse, reportTypesResponse, reportParameterTypeResponse, businessAreasResponse) {

            var mappedBusinessAreas = businessAreasResponse.map(function (ba) {
                return { "id": ba.locationBusinessAreaId, "name": ba.businessArea }
            });

            setReport(reportResponse);
            setOriginalSettings(reportResponse);
            setReportGroups(reportGroupsResponse);
            setReportTypes(reportTypesResponse);
            setReportParameterTypes(reportParameterTypeResponse);
            setLocationBusinessAreas(mappedBusinessAreas);

            setLoading(false);
        }));

    }

    function saveReport() {
        setSaving(true);

        SelfReportingService.saveReport(id, report).then(function () {
            setSaving(false);
            setOriginalSettings(_.extend({}, report));
            NotificationToaster.show(Intent.SUCCESS, "Report updated successfully.")
        }, function (error) {
            setSaving(false);
            NotificationToaster.show(Intent.DANGER, error);
        });
    }

    function onReportUpdate(updatedReport) {
        setReport(updatedReport);
    }

    function onValidationChange(valid) {
        setIsValid(valid);
    }

    return (

        <UserAccess perform={requiredAction}
            yes={() => (

                <div className="row">
                    <Breadcrumbs items={props.breadcrumbs} />

                    <div>
                        <h1 id="header-new-user">Report Settings</h1>
                        <SelfReportingReportSettings report={report} onSettingsUpdate={onReportUpdate} reportGroups={reportGroups} reportTypes={reportTypes} reportParameterTypes={reportParameterTypes} businessAreas={locationBusinessAreas} saving={saving} loading={loading} onValidationChange={onValidationChange} />
                    </div>

                    <AlertUnsavedData
                        isDirty={isUserDirty}>
                    </AlertUnsavedData>

                    <ButtonSave text="Save" intent="primary" onClick={saveReport} saving={saving} loading={loading} simpleDisabled={!isValid} />

                </div>

            )}
            no={() => (
                <PageRestricted />
            )}
        />
    );
}
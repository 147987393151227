import React, { useState, useEffect, useRef, Fragment } from 'react';
import classNames from "classnames";
import PropTypes from 'prop-types';

import { FormHelperText, FormCheckboxList, FormTextInput, FormRadio, FormSelect, FormSuggest } from "components/form-fields";
import { LocationService, UserService } from 'services';
import { Filter } from 'components/filtering';
import { useDebounce } from 'hooks/useDebounce';
import { Tooltip } from 'components/tooltip';
import { Button } from 'components/buttons'
import { ListingPagination } from 'components/listing';
import { ShowHide } from 'components/layout';
import { TagList } from 'components/status';

export function ScheduleRecipients(props) {

    const debounceTimeout = 750;

    const [recipients, setRecipients] = useState([]);
    const [showFilters, setShowFilters] = useState(false);
    const [selectedBusinessArea, setSelectedBusinessArea] = useState(null);
    const [locationSearchList, setLocationSearchList] = useState([]);
    const [selectedLocation, setSelectedLocation] = useState(null);
    const [searchTerm, setSearchTerm] = useState("");
    const [pageNumber, setPageNumber] = useState(1);
    const [totalPages, setTotalPages] = useState(1)
    const [appliedFilters, setAppliedFilters] = useState([]);
    const [filterBy, setFilterBy] = useState(null);
    const [businessAreas, setBusinessAreas] = useState([]);
    const pageFilters = [
        {
            "name": "Role",
            "items": [
                { id: "AdvancedEditor", name: "Advanced Editor" },
                { id: "CompanyAdmin", name: "Company Admin" },
                { id: "Editor", name: "Editor" },
                { id: "AccountOwner", name: "Primary Contact" },
                { id: "Viewer", name: "Viewer"}
            ]
        }
    ];
    const listSize = 50;
    const defaultSearchListSize = 25;
    const debouncedSearchTerm = useDebounce(searchTerm, debounceTimeout);
    const inputSearchRef = useRef(null);

    useEffect(fetchUsers, [filterBy, selectedLocation]);

    useEffect(function () {
        LocationService.getLocationBusinessAreas().then(function (businessAreaResponse) {
            setBusinessAreas(businessAreaResponse.map(function (ba) {
                return { id: ba.businessArea, name: ba.businessArea };
            }));
        });
    }, []);

    useEffect(() => {
        props.onValidationUpdate(props.schedule.recipients.length > 0);
    }, [props.schedule])

    function recipientChecked(id) {
        return props.schedule.recipients.some(function (recipient) {
            return recipient.id === id;
        });
    }

    function onItemChange(event, id) {

        var isChecked = event != null && event.target != null && event.target.checked != null && event.target.checked;

        var tempRecipients = [...recipients];

        var selectedRecipient = tempRecipients.find(function (r) {
            return r.id === id;
        });

        if (selectedRecipient != null) {
            selectedRecipient.checked = isChecked;
            setRecipients(tempRecipients);
        }

        var cloneSelectedRecipients = [...props.schedule.recipients];

        if (isChecked) {
            cloneSelectedRecipients.push({
                id: selectedRecipient.id,
                name: selectedRecipient.name
            });
        } else {
            cloneSelectedRecipients = cloneSelectedRecipients.filter(function (r) {
                return r.id !== id;
            });
        }

        var cloneSchedule = {
            ...props.schedule,
            recipients: cloneSelectedRecipients
        }

        props.onScheduleUpdate(cloneSchedule);
    }

    function onSearchChange(item) {
        setSearchTerm(item.target.value);
    }

    function onToggleFilter() {
        setShowFilters((prevState) => {
            return !prevState;
        })
    }

    function onFilterChange(listingFilters) {
       
        setAppliedFilters(listingFilters);

        if (listingFilters.length > appliedFilters.length) {
            setShowFilters(true);
        }
    }

    function onPageChange(newPage) {
        setPageNumber(newPage);
    }

    function fetchUsers() {

        if (filterBy == null || (filterBy === "location" && selectedLocation == null)) {
            return;
        }

        var searchFilter = filterBy === "all" ? appliedFilters : [{ name: "Location", value: selectedLocation }, { name: "LocationBusinessArea", value: selectedBusinessArea }]

        UserService.getUserListWebsite(listSize, pageNumber, debouncedSearchTerm, "", "", searchFilter).then(function (u) {

            setTotalPages(Math.ceil(u.totalCount / listSize));

            var mappedRecipients = u.data.map(function (user) {
                return { id: user.userId, name: user.emailAddress, checked: recipientChecked(user.userId), disabled: props.disabled };
            });
            setRecipients(mappedRecipients);

        });
    }

    useEffect(fetchUsers, [pageNumber, debouncedSearchTerm, appliedFilters]);

    function onRemoveTag(event, id) {
        onItemChange(event, id);
    }

    function sortUsers(a, b) {
        var nameA = a.name.toUpperCase();
        var nameB = b.name.toUpperCase();
        if (nameA < nameB) {
            return -1;
        }
        if (nameA > nameB) {
            return 1;
        }

        // names must be equal
        return 0;

    }

    function onRadioChange(item) {
        setFilterBy(item.currentTarget.value);
        setSelectedLocation(null);
        setLocationSearchList([]);
        setSelectedBusinessArea(null);
    }

    function onBusinessAreaSelect(item) {
        setSelectedBusinessArea(item.id);
        setSelectedLocation(null);
        setLocationSearchList([]);
    }

    function onLocationSelect(item) {
        setSelectedLocation(item.id);
    }

    function onLocationSearchByArea(query) {
        var businessAreaFilter = { filters: [{ key: "BusinessAreas", value: selectedBusinessArea }] };
        LocationService.getLocationList(defaultSearchListSize, 1, query, "", "", businessAreaFilter).then(function (locationResponse) {
            setLocationSearchList(locationResponse.data);
        })
    }

    return (
        <div className="spacer-bottom">
            <h2>Schedule Recipients</h2>
            <FormHelperText danger={true}>{props.dangerHelperText}</FormHelperText>

            <TagList items={props.schedule.recipients.sort(sortUsers)} disabled={props.disabled} onRemoveTag={onRemoveTag} />
            <ShowHide
                evaluator={props.disabled}
                hide={(
                    <FormRadio
                        id="schedule-user-filter-type"
                        disabled={props.disabled}
                        onChange={onRadioChange}
                        headingText="How would you like to add users to your schedule:"
                        selectedValue={filterBy}
                        loading={props.loading}
                        options={[{ value: 'location', label: "Search for users by location" }, { value: 'all', label: "Search all users" }]}
                    ></FormRadio>
                )}
            />
            

            <ShowHide
                evaluator={filterBy === "location"}
                show={(
                    <div className="spacer-bottom">
                        <div className="inline-items">
                            <FormSelect
                                disabled={props.disabled}
                                items={businessAreas}
                                onItemSelect={onBusinessAreaSelect}
                                selectedValue={selectedBusinessArea}
                                placeholder="Select business area"
                            ></FormSelect>

                            <FormSuggest large loading={props.loading} disabled={props.disabled || selectedBusinessArea == null} items={locationSearchList} onItemSelect={onLocationSelect} placeholder="Search for location" selectedValue={selectedLocation} onFilter={onLocationSearchByArea} debounceTime={500} />

                        </div>
                    </div>

                )}
            />
            <div>

                <ShowHide
                    evaluator={filterBy === "all"}
                    show={(
                        <div className="clearfix">
                            <div className={classNames("pull-left", { "spacer-bottom": !showFilters })}>
                                <div className="inline-items">
                                    <FormTextInput inputRef={inputSearchRef} placeholder="User Search" onChange={onSearchChange} value={searchTerm} large disabled={props.disabled} icon="search" id="user-search-field" />
                                <Tooltip content="Filter" position="right">
                                    <Button icon="filter" onClick={onToggleFilter} className={classNames({ "active": appliedFilters.length > 0 })} />
                                    </Tooltip>
                                </div>
                            </div>
                            <Filter filterName="scheduleWizard" visible={showFilters} filters={pageFilters} onUpdate={onFilterChange} />
                        </div>
                    )}

                />

                <ShowHide
                    evaluator={filterBy === "all" || selectedLocation != null}
                    show={(
                        <Fragment>
                            <FormCheckboxList
                                id="recpipients"
                                items={recipients}
                                onChange={onItemChange}
                                loading={props.loading}
                                dangerHelperText=""
                                disabled={props.disabled}
                                striped={true}
                            ></FormCheckboxList>

                            <ShowHide
                                evaluator={totalPages > 1}
                                show={(
                                    <ListingPagination currentPage={pageNumber} totalPages={totalPages} loadingData={false} onPageChange={onPageChange} />
                                )}
                            />
                        </Fragment>
                    )}

                />

                        
            </div>
                    

           

        </div>
    );
}

ScheduleRecipients.defaultProps = {
    disabled: false,
    loading: true
};

ScheduleRecipients.propTypes = {
    disabled: PropTypes.bool,
    loading: PropTypes.bool,
    onValidationUpdate: PropTypes.func.isRequired,
    onScheduleUpdate: PropTypes.func.isRequired,
    schedule: PropTypes.object.isRequired
};
import { Icon, Position, Tooltip } from '@blueprintjs/core';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { ShowHide } from 'components/layout';

import './Headers.css';

export function StatusHeader(props) {
    const smallIconSize = 16;
    const largeIconSize = 25;

    const [iconClass, setIconClass] = useState('');
    const [headerClass, setHeaderClass] = useState('');

    useEffect(() => {
        switch (props.status.toUpperCase()) {
            case 'SUCCESSFUL':
                setIconClass('tick');
                setHeaderClass('status-header-successful');
                break;
            case 'FAILED':
                setIconClass('cross');
                setHeaderClass('status-header-failed');
                break;
            case 'SUCCESSFUL-NOT-COMPLETE':
                setIconClass('tick');
                setHeaderClass('status-header-default');
                break;
            case 'FAILED-NOT-COMPLETE':
                setIconClass('cross');
                setHeaderClass('status-header-default');
                break;
            case 'NA':
                setIconClass('blank');
                setHeaderClass('status-header-default');
                break;
            default:
                setIconClass('refresh');
                setHeaderClass('status-header-default');
                break;
        }
    }, [props.status]);

    return <div className={classNames('inline-items', 'status-header', 'spacer-bottom-small', headerClass, props.className)}>
        <ShowHide
            evaluator={props.showStatus && !!iconClass && iconClass !== 'blank'}
            show={<div className={classNames('status-header-icon-container', 'inline-item', { 'smaller-icon': props.secondaryHeader })}>
                <Tooltip position={Position.RIGHT} content={props.tooltip}>
                    <Icon icon={iconClass} iconSize={props.secondaryHeader ? smallIconSize : largeIconSize} color='#ffffff' />
                </Tooltip>

            </div>}
        />
        <ShowHide
            evaluator={!props.secondaryHeader}
            show={<h1 className='status-header-title inline-item'>{props.text}</h1>}
            hide={<h2 className='status-header-title inline-item'>{props.text}</h2>}
        />
    </div>;

}

StatusHeader.propTypes = {
    text: PropTypes.string,
    status: PropTypes.string,
    secondaryHeader: PropTypes.bool,
    showStatus: PropTypes.bool,
    tooltip: PropTypes.string,
    className: PropTypes.string
};

StatusHeader.defaultProps = {
    secondaryHeader: false,
    showStatus: true,
    tooltip: '',
    className: ''
};


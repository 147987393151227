import React, { useState, useEffect, Fragment } from 'react';
import axios from 'axios';
import { useParams } from 'react-router-dom';
import classNames from 'classnames';

import { Intent } from '@blueprintjs/core';
import { NotificationInline, NotificationToaster, AlertUnsavedData, AlertConfirm } from "components/notifications";
import { Button, ButtonSave, LinkButton } from "components/buttons";
import { ShowHide } from "components/layout";
import { useDirtyData } from 'hooks/useDirtyData';
import { UserService } from "services";
import { UserAccess, UserHasAccess } from 'components/user-access';
import { PageRestricted } from 'pages/errors/page-restricted';
import { UserLocationSettings } from 'pages/users/edit/shared';
import { DateToLocal } from 'components/dates';
import { ApiApplicationBasicSettings } from 'pages/account/edit/integrations/api-applications/shared';
import { Tooltip } from 'components/tooltip';
import { HelperFunctions } from 'helpers';

export function ApiApplicationEdit(props) {

    const appModel = {
        "applicationName": "",
        "role": {}
    };

    const { id } = useParams();

    const [invalidApp, setInvalidApp] = useState(false);
    const [originalSettings, setOriginalSettings] = useState(appModel);
    const [originalLocationSettings, setOriginalLocationSettings] = useState([]);
    const [basicSettingsValid, setBasicSettingsValid] = useState(false);
    const [loading, setLoading] = useState(true);
    const [saving, setSaving] = useState(false);
    const [application, setApplication] = useState(appModel);
    const [userLocationPermissions, setUserLocationPermissions] = useState([]);
    const isUserDirty = useDirtyData(originalSettings, application);
    const isUserLocationsDirty = useDirtyData(originalLocationSettings, userLocationPermissions);
    const [newSecretGenerated, setNewSecretGenerated] = useState(false);
    const [savingSecret, setSavingSecret] = useState(false);

    const [showNewSecretConfirm, setShowNewSecretConfirm] = useState(false);

    const requiredAction = "Integrations:Manage";
    const canAccessPage = UserHasAccess(requiredAction);

    useEffect(() => {

        if (!canAccessPage) {
            return;
        }

        if (loading) {
            UserService.getApplication(id).then(r => {
                setApplication(r);
                setOriginalSettings(r);

                UserService.getLocationPermissions(id).then(function (locationsResponse) {
                    setLoading(false);
                    setUserLocationPermissions(locationsResponse);
                    var originalLocationPermissions = HelperFunctions.deepClone(locationsResponse);
                    setOriginalLocationSettings(originalLocationPermissions);
                }).catch(function () {
                    NotificationToaster.show(Intent.DANGER, "Could not load application settings, please try again.");
                })
                
            }, (error) => {
                const noAccessCode = 403;

                if (error.status === noAccessCode) {
                    setInvalidApp(true);
                }
            });
        }
    }, [loading, id]);

    function updateApplication(updatedApp) {
        setApplication(updatedApp);
    }

    function updateUserLocationPermissions(permissions) {
        setUserLocationPermissions(permissions);
    }

    function generateNewSecret() {
        setShowNewSecretConfirm(true);
    }

    function onSecretCreate() {
        setShowNewSecretConfirm(false);
        setSavingSecret(true);

        UserService.generateApplicationSecret(application.userId).then(function (secretResponse) {
            NotificationToaster.show(Intent.SUCCESS, "New secret created successfully");
            setNewSecretGenerated(true);

            var newApplicationSettings = {
                ...application,
                ...secretResponse
            };

            if (!isUserDirty) {
                setOriginalSettings(newApplicationSettings)
            }

            setApplication(newApplicationSettings);

        }).catch(function () {
            NotificationToaster.show(Intent.DANGER, "Could not create a new secret");
        }).finally(function () {
            setSavingSecret(false);
        });
    }

    function onSecretCancel() {
        setShowNewSecretConfirm(false);
    }

    function saveApplication() {
        setSaving(true);

        var savePromises = [];

        if (isUserDirty) {
            savePromises.push(UserService.saveApplication(id, application));
        }

        if (isUserLocationsDirty) {
            savePromises.push(UserService.updateLocationPermissions(id, userLocationPermissions));
        }

        axios.all(savePromises).then(function () {
            setOriginalSettings(application);
            setOriginalLocationSettings(HelperFunctions.deepClone(userLocationPermissions));
            NotificationToaster.show(Intent.SUCCESS, "Application has been saved successfully");
        }).catch(function (error) {
            NotificationToaster.show(Intent.DANGER, "Could not save the application. Please try again");
        }).finally(function () {
            setSaving(false);
        });
    }

    function onBasicValidationUpdate(isValid) {
        setBasicSettingsValid(isValid);
    }

    function copyText(text) {
        navigator.clipboard.writeText(text);
        NotificationToaster.show(Intent.SUCCESS, "Value copied to clipboard");
    }

    return (

        <UserAccess perform={requiredAction}
            yes={() => (

                <div className="row">

                    <ShowHide
                        evaluator={invalidApp}
                        show={(
                            <div className="row">
                                <NotificationInline
                                    allowClose={false}
                                    show
                                    text="You do not have permission to view this application"
                                    intent="danger">
                                </NotificationInline>
                                <div>
                                    <LinkButton intent="primary" text="Back to application management" href="/account/integrations/api-applications" id="return-app-listing" />
                                </div>
                            </div>
                        )}
                        hide={(
                            <Fragment>
                                <div>
                                    <div className="spacer-bottom">
                                        <h2 id="header-new-user" className={classNames({ "bp3-skeleton": loading })}>Application details</h2>
                                        <ApiApplicationBasicSettings application={application} disabled={savingSecret} loading={loading} editing={false} onSettingsUpdate={updateApplication} saving={saving} onValidationUpdate={onBasicValidationUpdate} />
                                    </div>
                                </div>
                                <div>
                                    <div className={classNames("form-field", { "bp3-skeleton": loading })}>
                                        <h4>Client ID</h4>
                                        <div className="inline-items">
                                            <p>{application.clientId}</p>
                                            <Tooltip content="Copy to clipboard">
                                                <Button minimal large={false} icon="duplicate" iconOnly text="Copy to clipboard" onClick={() => copyText(application.clientId)} />
                                            </Tooltip>
                                        </div>
                                    </div>
                                    <div className={classNames("form-field", { "bp3-skeleton": loading })}>
                                        <h4>Client Secret</h4>
                                        <ShowHide
                                            evaluator={newSecretGenerated}
                                            show={(
                                                <div>
                                                    <div className="inline-items spacer-bottom">
                                                        <p>{application.secret}</p>
                                                        <Tooltip content="Copy to clipboard">
                                                            <Button minimal large={false} loading={loading} icon="duplicate" iconOnly text="Copy to clipboard" onClick={() => copyText(application.secret)} />
                                                        </Tooltip>
                                                    </div>

                                                    <NotificationInline show intent="primary" text="Please take a copy of your application secret. Once you leave this page you will no longer be able to see the full value again." />
                                                </div>
                                            )}
                                            hide={(
                                                <div className="inline-items">
                                                    <p>{application.secretHint}************</p>
                                                    <Tooltip content="Generate new secret">
                                                        <ButtonSave minimal large={false} icon="refresh" iconOnly text="Generate new secret" intent="none" onClick={generateNewSecret} disabled={savingSecret} />
                                                    </Tooltip>
                                                </div>
                                            )}
                                        />
                                        
                                    </div>
                                    <div className={classNames("form-field", { "bp3-skeleton": loading })}>
                                        <h4>Secret Expires</h4>
                                        <p><DateToLocal>{application.secretExpiry}</DateToLocal></p>
                                    </div>
                                </div>
                                <div>
                                    <h1 id="header-new-user" className={classNames({ "bp3-skeleton": loading })}>What locations should the application have access to?</h1>
                                    <UserLocationSettings permissions={userLocationPermissions} onPermissionsUpdate={updateUserLocationPermissions} saving={savingSecret} loading={loading} />
                                </div>

                                <AlertConfirm
                                    title="Generate a new secret?"
                                    isOpen={showNewSecretConfirm}
                                    onConfirm={onSecretCreate}
                                    onCancel={onSecretCancel}
                                    confirmButtonIntent="primary"
                                    confirmButtonText="Generate"
                                >
                                    <p>Are you sure you would like to generate a new secret? This will remove any previous secrets that have been generated.</p>
                                </AlertConfirm>

                                <AlertUnsavedData
                                    isDirty={isUserDirty || isUserLocationsDirty}>
                                </AlertUnsavedData>

                                <ButtonSave text="Save" simpleDisabled={savingSecret || !basicSettingsValid} onClick={saveApplication} disabled={saving} loading={loading} />

                            </Fragment>
                        )}
                    />

                </div>

            )}
            no={() => (
                <PageRestricted />
            )}
        />


    );
}
import React, { useEffect, useState } from "react";
import { FormHeading } from "components/form-fields";
import { ListingTable } from "components/listing";
import PropTypes from "prop-types";
import { DateToLocal } from "components/dates";
import './HistoryTab.css';

export function HistoryTab(props) {
    const { historyData, isLoading, onPagingChange } = props;
    const tableHeaders = ["Date Affected", "Change Type", "Edited By", "Edited On"];
    const noDataMessage = "No history data";
    const [dataForTable, setDataForTable] = useState([]);
    const [totalRecords, setTotalRecords] = useState(0);

    useEffect(() => {
        if (historyData) {
            setDataForTable([
                ...historyData.map((item) => {
                    return [
                        <DateToLocal key={`${item.dateAffected}-${item.changeType}-date-affected`} format="DD MMM YYYY">{item.dateAffected}</DateToLocal>,
                        <p key={`${item.dateAffected}-${item.changeType}-change-type`}>{item.changeType}</p>,
                        <p key={`${item.dateAffected}-${item.changeType}-modified-by`}>{item.modifiedBy}</p>,
                        <DateToLocal key={`${item.dateAffected}-${item.changeType}-modified-date`} format="DD MMM YYYY HH:mm">{item.modifiedDate}</DateToLocal>
                    ];
                })
            ]);
            setTotalRecords(historyData.length);
        }
    }, [historyData])

    return (
        <div className='spacer-top-small'>
            <FormHeading headingLevel="h3">Audit History</FormHeading>
            <ListingTable
                id="listing-table-fleet-history"
                headers={tableHeaders}
                columnClasses={["history-tab-table-item", "history-tab-table-item", "history-tab-table-item", "history-tab-table-item"]}
                data={dataForTable}
                totalRecordCount={totalRecords}
                loadingData={isLoading}
                noDataHeading={noDataMessage}
                noDataMessage={""}
                pageable={true}
                showPerPage={false}
                shrinkLastColumn={true}
                onPagingChange={onPagingChange}
            />
        </div>
    )
}

HistoryTab.defaultProps = {
    historyData: [],
    isLoading: false,
    onPagingChange: () => { }
};

HistoryTab.propTypes = {
    historyData: PropTypes.array.isRequired,
    isLoading: PropTypes.bool,
    onPagingChange: PropTypes.func.isRequired
};
const initialState = {
  route: null,
  id: null,
};

const RoutingReducer = (state = initialState, action) => {
  switch (action.type) {
    case "SET_ROUTE":
      return {
        ...state,
        title: action.title,
        route: action.route,
        id: action.id,
      };
    default:
      return state;
  }
};

export default RoutingReducer;

import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import axios from 'axios';

import { Intent } from '@blueprintjs/core';

import { Breadcrumbs } from 'components/navigation';
import { UserAccess, UserHasAccess } from 'components/user-access';
import { PageRestricted } from 'pages/errors/page-restricted';
import { useDirtyData } from 'hooks/useDirtyData';
import { AlertUnsavedData, NotificationToaster } from 'components/notifications';
import { ButtonSave } from 'components/buttons';

import { OptionsService, SystemMessagesService } from 'services';
import { SystemMessageForm } from 'pages/internal-admin/system-messages/shared';
import { defaultSystemMessageModel } from 'pages/internal-admin/system-messages/shared/systemMessageModel';
import { ShowHide } from 'components/layout';

export function SystemMessageUpdate(props) {
    const { id } = useParams();
    const requiredAction = "Internal";
    const canAccessPage = UserHasAccess(requiredAction);
    const systemMessageModel = defaultSystemMessageModel;
    const [originalSystemMessage, setOriginalSystemMessage] = useState(systemMessageModel);
    const [systemMessage, setSystemMessage] = useState(systemMessageModel);
    const [systemMessageTypes, setSystemMessageTypes] = useState([]);
    const [isSystemMessageValid, setSystemMessageValid] = useState(false); 

    useEffect(initialLoad, []);
    
    const isSystemMessageDirty = useDirtyData(originalSystemMessage, systemMessage);
    const [loading, setLoading] = useState(true);
    const [saving, setSaving] = useState(false);
    const [disabled, setDisabled] = useState(false);


    function initialLoad() {
        if (!canAccessPage) {
            return;
        }

        axios.all([
            OptionsService.getSystemMessageTypes(),
            SystemMessagesService.getSystemMessage(id)
        ]).then(axios.spread(function (systemMessageTypesResponse, systemMessageResponse) {
            let mappedSystemMessageTypes = systemMessageTypesResponse.map(item => {
                return { id: item.systemMessageTypeId, name: item.systemMessageTypeName };
            });
            
            const selectedSystemMessageType = mappedSystemMessageTypes.filter(x => x.id === systemMessageResponse.systemMessageTypeID)[0];
            if (!systemMessageResponse.active && selectedSystemMessageType.name.toLowerCase() !== "general") {
                setDisabled(true);
            }

            setSystemMessageTypes(mappedSystemMessageTypes);
            setSystemMessage(systemMessageResponse);
            setOriginalSystemMessage(systemMessageResponse);

            setLoading(false);
        },
            () => {
                NotificationToaster.show(Intent.DANGER, "Could not load settings, Please refresh the page.");
            }));
    }

    function onSystemMessageUpdate(updatedSystemMessage) {
        setSystemMessage(updatedSystemMessage);
    }

    function onValidationUpdate(isValid) {
        setSystemMessageValid(isValid);
    }

    function updateSystemMessage() {
        if (disabled) {
            NotificationToaster.show(Intent.WARNING, "Can't edit inactive system message");
            return;
        }

        setSaving(true);

        if (!isSystemMessageValid) {
            NotificationToaster.show(Intent.WARNING, "There are errors on the page that need your attention");
            setSaving(false);
            return;
        }

        SystemMessagesService.updateSystemMessage(id, systemMessage).then(
            (response) => {
                setSaving(false);
                setOriginalSystemMessage(systemMessage);

                const selectedSystemMessageType = systemMessageTypes.filter(x => x.id === systemMessage.systemMessageTypeID)[0];
                if (!systemMessage.active && selectedSystemMessageType.name.toLowerCase() !== "general") {
                    setDisabled(true);
                }

                const multiStatusCode = 207;
                if (response.status === multiStatusCode) {
                    NotificationToaster.show(Intent.WARNING, response.data);
                } else {
                    NotificationToaster.show(Intent.SUCCESS, "System message updated successfully.")
                }
            },
            (error) => {
                setSaving(false);

                const badRequestCode = 400;
                if (error.status === badRequestCode) {
                    NotificationToaster.show(Intent.WARNING, error.data);
                } else {
                    NotificationToaster.show(Intent.DANGER, "Could not update system message. Please try again.");
                }
            }
        );
    }

    return (

        <UserAccess perform={requiredAction}
            yes={() => (
                <div className="row">
                    <Breadcrumbs items={props.breadcrumbs} />

                    <h1 id="header-update-system-message">Edit System Message</h1>

                    <SystemMessageForm
                        originalSystemMessage={originalSystemMessage}
                        systemMessage={systemMessage}
                        systemMessageTypes={systemMessageTypes}
                        onSystemMessageUpdate={onSystemMessageUpdate}
                        onValidationUpdate={onValidationUpdate}
                        isSystemMessageDirty={isSystemMessageDirty}
                        saving={saving}
                        loading={loading}
                        disabled={disabled}
                        isUpdatingSystemMessage={true}
                    />

                    <AlertUnsavedData
                        isDirty={isSystemMessageDirty}>
                    </AlertUnsavedData>

                    <ShowHide
                        evaluator={!disabled}
                        show={(
                            <ButtonSave
                                id="btn-system-message-update"
                                onClick={updateSystemMessage}
                                disabled={saving}
                                loading={loading}
                            ></ButtonSave>
                        )}
                    ></ShowHide>
                </div>
            )}
            no={() => (
                <PageRestricted />
            )}
        />
    );
}
import 'components/settings/settings.css';
import { useValidation } from "hooks/useValidation";
import { FormCheckbox, FormHelperText } from "components/form-fields";
import PropTypes from 'prop-types';
import React, { Fragment, useEffect, useState } from 'react';
import { EmployeeService } from "services";
import { ListingTable } from "components/listing";
var _ = require('underscore');


export function DeclarationSettings(props) {

    const noDataMessage = "You have no declarations, please upload a declaration.";
    const tableHeaders = ["", "Name", "Type", "Version"];
    const [tableRows, setTableRows] = useState([]);
    const [isValid, errors, validate] = useValidation();
    const [loading, setLoading] = useState(true);
    const [declarationSettings, setDeclarationSettings] = useState(
        {
            policyDocuments: []
        });
    const [declarations, setDeclarations] = useState([]);
    const [allDeclarations, setAllDeclations] = useState([]);
    const [totalRecords, setTotalRecords] = useState(0);

    useEffect(initialLoad, []);

    function initialLoad() {
        EmployeeService.getDeclarations().then(rdata => {
            setAllDeclations(rdata);          
            setLoading(false);
        });
    }

    useEffect(onValidationChange, [isValid])
    function onValidationChange() {
        props.onValidationUpdate(isValid);
    }

    useEffect(onDeclarationChange, [declarations])
    function onDeclarationChange() {
        let data = [];

        if (declarations != null) {
            setTotalRecords(declarations.length);
            data = declarations.map((d) => {

                return [(<FormCheckbox
                    checked={d.checked}
                    label=''
                    key={'declarationList'}
                    id={d.id}
                    onChange={handleToggleChange}
                    loading={loading}
                    disabled={d.disabled || props.disabled}
                    dangerHelperText={errors.policyDocuments}
                />), d.name, d.type, d.version];
            })
        }

        setTableRows(data);
    }

    useEffect(() => {
        setDeclarationSettings(props.declarations);
        setDeclarations(filterLatestOrSelectedDeclarations(mapDeclarations(allDeclarations, props.declarations.policyDocuments)));
    }, [props.declarations, allDeclarations]);

    useEffect(() => {
        setLoading(props.loading);
    }, [props.loading]);

    useEffect(updateValidationRules, [props.declarations])

    function checkMultipleVersions() {
        var multipleVersionsSelected = [];
        var countByName = _.countBy(props.declarations.policyDocuments, function (item) {
            return item.name;
        });

        for (const name in countByName) {
            if (countByName[name] > 1) {
                multipleVersionsSelected.push(name);
            }
        }
        return multipleVersionsSelected;
    }

    function updateValidationRules() {
        let rules = [];

        rules.push({ fieldName: "policyDocuments", required: true });

        var multipleVersionsSelected = checkMultipleVersions();

        if (multipleVersionsSelected.length > 0) {
            var externalRule = {
                fieldName: "policyDocuments",
                valid: false,
                errorMessage: 'Only one version of the same document can be selected. You have selected multiple versions of ' + multipleVersionsSelected.join(', ')
            };
            var externalRules = [externalRule];
        }

        validate(rules, props.declarations, externalRules);
    }

    function filterLatestOrSelectedDeclarations(data) {
        return _.filter(data, function (d) {
            return d.isLatestVersion || d.checked;
            });
    }

    function mapDeclarations(data, policyDocuments) {
        return _.map(data, function (item) {
            const isSelected = _.some(policyDocuments, function (declaration) {
                return declaration.id === item.id;
            });
            return { id: item.id, name: item.name, checked: isSelected, disabled: false, type: item.type, version: item.versionNumber, isLatestVersion: item.isLatestVersion};
        });
    }

    function handleToggleChange(event) {
        var temp = [...declarationSettings.policyDocuments];
        var id = parseInt(event.currentTarget.getAttribute('identifier'));
        if (event.target.checked) {
            var selectedDeclaration = _.find(allDeclarations, function (item) {
                return item.id === id;
            });
            selectedDeclaration.checked = true;
            temp.push(selectedDeclaration);
        } else {
            temp = temp.filter(function (e) {
                return e.id !== id
            });
        }

        var updatedSettings = {
            ...declarationSettings,
            policyDocuments: temp
        }
        setDeclarationSettings(updatedSettings);
        props.onSettingsUpdate(updatedSettings);
    }
    return (
        <Fragment>
            <FormHelperText loading={loading}>
                <p>Here you can configure your policy settings with your documents.</p>
            </FormHelperText>
            <ListingTable
                id="listing-table-declaration"
                headers={tableHeaders}
                data={tableRows}
                loadingData={loading}
                noDataMessage={noDataMessage}
                pageable={false}
                totalRecordCount={totalRecords}
                disabled={props.disabled}
            />
            <div className="form-field">
                <FormHelperText danger={true}>{errors.policyDocuments}</FormHelperText>
            </div>

        </Fragment>
    );
}
DeclarationSettings.defaultProps = {
    disabled: false
};

DeclarationSettings.propTypes = {
    declarations: PropTypes.object.isRequired,
    disabled: PropTypes.bool,
    loading: PropTypes.bool.isRequired
};
import React, { useEffect, useRef, useState } from 'react';
import { Modal, NotificationToaster } from 'components/notifications';
import { EmployeeService, AssetService } from 'services';
import { AssetStatus } from 'components/status/AssetStatus';
import PropTypes from "prop-types";
import { FormHeading, FormTextInput, FormCheckbox, FormLocationSelector } from 'components/form-fields';
import { DateToLocal } from 'components/dates';
import './AssignDriverModal.css';
import { ListingTable } from 'components/listing';
import { Filter } from 'components/filtering';
import { Button, ButtonSave } from 'components/buttons';
import classNames from 'classnames';
import { CircleStatus } from 'components/status/CircleStatus';
import { ShowHide } from 'components/layout';
import { Dropdown } from 'components/dropdown';
import { ActivitiesList } from 'components/fleet/ActivitiesList';
import { Intent, MenuItem, Icon, Tooltip } from '@blueprintjs/core';
import Moment from 'moment';
import { AlertUnsavedData } from 'components/notifications/AlertUnsavedData';
import { Panel } from 'components/panel';
import { PageRestricted } from "pages/errors/page-restricted";
import { UserAccess } from "components/user-access";
import 'components/form-fields/FormFields.css';
import { HelperFunctions } from 'helpers';

export function AssignDriverModal(props) {
    const activityDateFormat = "YYYY-MM-DDTHH:mm:ss";
    const awaitingPrintoutFlagId = 3;
    const listingPerPageDefault = 25;
    const requiredActions = ["Fleet:AssignWithoutCard"];

    const { showModal, assetDetails, date, cardIw } = props;
    const [searchTerm, setSearchTerm] = useState('');
    const [modalTitle, setModalTitle] = useState(<></>);
    const [driverListData, setDriverListData] = useState([]);
    const [driverListFormatted, setDriverListFormatted] = useState([]);
    const [driverListTotalRecords, setDriverListTotalRecords] = useState(0);
    const [driverListPerPage, setDriverListPerPage] = useState(listingPerPageDefault);
    const [driverListPageNumber, setDriverListPageNumber] = useState(1);
    const [driverListIsLoading, setDriverListIsLoading] = useState(true);
    const [driverListShowFilters, setDriverListShowFilters] = useState(false);
    const [driverListFilters] = useState([
        {
            "displayName": "Status", "name": "Status", "items": [
                { "id": "Active", "name": "Active" },
                { "id": "Away", "name": "Away" }
            ]
        },
        {
            "displayName": "Type", "name": "Type", "items": [
                { "id": "Core", "name": "Core" },
                { "id": "Agency", "name": "Agency" }
            ]
        }
    ]);

    const driverListDefaultFilters = [
        { name: "type", value: "Core", displayName: "Core" },
        { name: "type", value: "Agency", displayName: "Agency" },
        { name: "status", value: "Active", displayName: "Active" },
        { name: "status", value: "Away", displayName: "Away" }
    ];
    const [driverListSelectedFilters, setDriverListSelectedFilters] = useState([]);
    const [driverListSelectedLocations, setDriverListSelectedLocations] = useState([]);
    const [driverListLocationsUseHierarchy, setDriverListLocationsUseHierarchy] = useState(true);
    const [driverListSortedBy, setDriverListSortedBy] = useState('name');
    const [driverListSortedDir, setDriverListSortedDir] = useState('asc');
    const [driverListSelectedDriver, setDriverListSelectedDriver] = useState(null);
    const [stageNumber, setStageNumber] = useState(1);
    const [selectedActivitiesList, setSelectedActivitiesList] = useState([]);
    const [showDirtyDataAlert, setShowDirtyDataAlert] = useState(false);
    const [awaitingPrintoutFlag, setAwaitingPrintoutFlag] = useState(false);
    const [printoutFiles, setPrintoutFiles] = useState([]);
    const [isSaving, setIsSaving] = useState(false);

    const locationListOnFilterOpen = useRef(driverListSelectedLocations);
    const printoutFile = useRef(null);
    const currentPayload = useRef(null);

    function onToggleDriverListFilter() {
        setDriverListShowFilters((prevState) => { return !prevState });
    }

    function onDriverListFiltersChange(filters) {
        setDriverListPageNumber(1);
        setDriverListSelectedFilters(filters);

        if (filters.length > 0) {
            setDriverListShowFilters(true);
        }
    }

    useEffect(() => {
        let updatedDriverList = driverListData.map((d) => {
            d.userSelected = d.id === driverListSelectedDriver;
            return d;
        });
        setDriverListData(updatedDriverList);
        setDriverListFormatted(formatDriverList(updatedDriverList));

    }, [driverListSelectedDriver]);

    function handleSelectedDriver(employeeId) {
        if (employeeId === driverListSelectedDriver) {
            employeeId = null;
        }
        setDriverListSelectedDriver(employeeId);
    }

    function formatDriverName(driver, isHeader = false) {
        if (driver === null) return null;
        let driverStatus = driver.status !== null ? driver.status.name : null;
        let driverType = driver.type !== null ? driver.type.name : null;
        return (<div className={classNames({ "driver-header": isHeader }, { "no-spacing": !isHeader }, "nowrap inline-items")} key={`d-name-${driver.id}`}>
            <CircleStatus status={driverStatus} hollow={driverType.toLowerCase() === "agency"} tooltip={`${driverStatus} ${driverType}`} />
            <span>
                {isHeader
                    ? `${driver.firstName} ${driver.surname}`
                    : `${driver.surname}, ${driver.firstName}`
                }
            </span>
        </div>);
    }

    function formatDriverList(driverList) {
        return driverList.map((d) => {
            return [
                formatDriverName(d, false),
                d.location,
                d.reference,
                <div className="driver-checkbox-cell form-field" key={`checkbox-${d.id}`}>
                    <FormCheckbox
                        checked={d.userSelected}
                        onChange={() => handleSelectedDriver(d.id)}
                    />
                </div>]
        });
    }

    function onLocationListOpened() {
        locationListOnFilterOpen.current = driverListSelectedLocations;
    }

    function resetDriverSearchResults() {
        setDriverListTotalRecords(0);
        setDriverListData([]);
        setDriverListFormatted([]);
        setDriverListIsLoading(false);
    }

    function handleEmployeeListSearch(perPage, pageNumber, thisSearchTerm, sortField, sortDir, filters) {
        if (perPage !== driverListPerPage) {
            setDriverListPerPage(perPage);
        }
        //if no location filters are set, do not call the endpoint, just return empty:
        if (driverListSelectedLocations.length === 0) {
            resetDriverSearchResults();
            return;
        }

        const payload = {
            ignoreLocationPermissions: false,
            requestCount: perPage,
            pageNumber: pageNumber,
            searchTerm: thisSearchTerm,
            sortField: sortField.toLowerCase() === 'name' ? 'surname' : sortField,
            sortDirection: sortDir,
            filters: filters,
            LocationFiltersAsIds: true
        };

        if (HelperFunctions.deepEqual(payload, currentPayload.current)){
            return;
        }
        setDriverListIsLoading(true);

        currentPayload.current = payload;

        EmployeeService.searchEmployees(payload).then(
            (result) => {
                let driverList = result.data.map((d) => {
                    return {
                        id: d.employeeId,
                        firstName: d.firstName,
                        surname: d.surname,
                        location: d.location.name,
                        reference: d.reference,
                        status: d.status,
                        type: d.type,
                        userSelected: false
                    };
                });
                setDriverListTotalRecords(result.totalCount);
                setDriverListData(driverList);
                setDriverListFormatted(formatDriverList(driverList));
                setDriverListSelectedDriver(null);
            }
        ).catch((error) => {
            resetDriverSearchResults();
            NotificationToaster.show(Intent.DANGER, error.message, true);
        }).finally(() => { setDriverListIsLoading(false); });
    };

    function handleEmployeeListOnPagingChange(pageNumber, perPage) {
        setDriverListPerPage(perPage);
        setDriverListPageNumber(pageNumber);
    }

    function handleEmployeeListOnSort(sortField, sortDir) {
        setDriverListSortedBy(sortField);
        setDriverListSortedDir(sortDir);
    }

    function formatFiltersForApi(filters, locations) {
        if (filters === null) {
            filters = [];
        }
        if (locations === null) {
            locations = [];
        }
        filters = [...filters.map((f) => { return { key: f.name, value: f.value } }), ...locations.map((l) => { return { key: 'LocationId', value: l } })];
        filters.push({ key: 'HasDriverCard', value: true });
        return filters;
    }

    function onSelectedLocationsChange(locations) {
        setDriverListSelectedLocations(locations);
    }

    useEffect(() => {
        if (!assetDetails) {
            return;
        }
        let driver = <>Assign Driver to </>;
        if (driverListSelectedDriver !== null) {
            let selectedDriver = driverListData.find((d) => { return d.id === driverListSelectedDriver });
            driver = <>Assign {formatDriverName(selectedDriver, true)} to</>;
        }
        setModalTitle(
            <div className="inline-items">
                <FormHeading headingLevel="h2" className={"inline"}>{driver}</FormHeading>
                <AssetStatus statusId={assetDetails.assetStatusId} ownershipId={assetDetails.assetOwnershipId} isLoading={false} statusAttributeMappings={assetDetails.statusAttributeMappings} ownershipAttributeMappings={assetDetails.ownershipAttributeMappings} />
                <FormHeading headingLevel="h2" isLoading={false}>{assetDetails.assetRegistration}

                </FormHeading>
                <FormHeading headingLevel="h2">on <DateToLocal format='DD MMM YYYY'>{date}</DateToLocal></FormHeading>
            </div>
        );
    }, [assetDetails, date, driverListSelectedDriver]);

    useEffect(() => {
        handleEmployeeListSearch(driverListPerPage, driverListPageNumber, searchTerm, driverListSortedBy, driverListSortedDir, formatFiltersForApi(driverListSelectedFilters, driverListSelectedLocations));
    }, [driverListSelectedFilters, driverListSelectedLocations, searchTerm, driverListSortedBy, driverListSortedDir, driverListPerPage, driverListPageNumber]);

    function onSearchTextChange(searchText) {
        setDriverListPageNumber(1);
        setSearchTerm(searchText);
    }
    function toggleStageNumber() {
        setStageNumber((prevState) => { return prevState === 1 ? 2 : 1 });
    }

    function onActivityCheckChange(activityId, checked) {

        let selectedActivitiesTempList = selectedActivitiesList;

        //Adding or removing the activity from the selected activities list
        if (checked) {
            selectedActivitiesTempList = [...selectedActivitiesList, activityId];
        }
        else {
            selectedActivitiesTempList = selectedActivitiesTempList.slice(0, selectedActivitiesTempList.indexOf(activityId));
        }

        //If 2 or more activities are selected, then select all the activities between the first and last selected activities
        if (selectedActivitiesTempList.length >= 2 && checked) {

            let selectedActivitiesTempListIndex = selectedActivitiesTempList.map(x => cardIw.activities.indexOf(cardIw.activities.find(a => a.activityId === parseInt(x))));
            let minIndex = Math.min(...selectedActivitiesTempListIndex);
            let maxIndex = Math.max(...selectedActivitiesTempListIndex);
            selectedActivitiesTempList = cardIw.activities.filter((x, i) => i >= minIndex && i <= maxIndex).map(x => x.activityId.toString());
        }

        setSelectedActivitiesList(selectedActivitiesTempList);
    }

    function selectAll(checked) {
        if (checked) {
            setSelectedActivitiesList(cardIw.activities.map(x => x.activityId.toString()));
        }
        else {
            setSelectedActivitiesList([]);
        }
    }

    function onCloseModal() {
        if (driverListSelectedDriver !== null || (selectedActivitiesList !== null && selectedActivitiesList.length > 0)) {
            setShowDirtyDataAlert(true);
        }
        else {
            resetData();
            props.onCloseModal();
        }
    }

    function handleConfirmLeave() {
        setShowDirtyDataAlert(false);
        resetData();
        props.onCloseModal();
    }

    function handleCancelLeave() {
        setShowDirtyDataAlert(false);
    }

    function handleUploadPrintoutCall() {
        printoutFiles.forEach((f) => {
            EmployeeService.uploadPrintout(f, date, driverListSelectedDriver).then((result) => {
                if (!result.status.startsWith("2")) {
                    NotificationToaster.show(Intent.DANGER, result.data, false);
                }
            }).catch((error) => {
                NotificationToaster.show(Intent.DANGER, error.response.data, false);
            });
        });
    }

    function handleAddAwaitingPrintoutFlag() {
        if (awaitingPrintoutFlag) {
            EmployeeService.postAwaitingData(driverListSelectedDriver, date, awaitingPrintoutFlagId).then((result) => {
                if (!result.status.startsWith("2")) {
                    NotificationToaster.show(Intent.DANGER, result.data, false);
                }
            }).catch((error) => {
                NotificationToaster.show(Intent.DANGER, error.response.data, false);
            });
        }
    }

    function handleSave() {
        setIsSaving(true);
        let activities = cardIw.activities.filter((a) => { return selectedActivitiesList.includes(a.activityId.toString()) }).sort((x, y) => { Moment(x.startDate).isAfter(Moment(y.startDate)) });
        if (activities.length === 0) {
            NotificationToaster.show(Intent.DANGER, "No activities selected", false);
            return;
        }
        let minDate = Moment(activities[0].startDate).format(activityDateFormat);
        let maxDate = Moment(activities[activities.length - 1].startDate).add(activities[activities.length - 1].duration, 'minutes').format(activityDateFormat);

        AssetService.assignEmployee(assetDetails.assetId, driverListSelectedDriver, minDate, maxDate).then((result) => {
            //Call upload printout:
            handleUploadPrintoutCall();
            handleAddAwaitingPrintoutFlag();
            resetData();
            NotificationToaster.show(Intent.SUCCESS, "Driver assigned successfully", false);
            setIsSaving(false);
            props.onCloseModal(driverListSelectedDriver);
            if (props.onSave) {
                props.onSave();
            }
        }).catch((error) => {
            let errorMessage = "Error occurred assigning employee, ";
            if (error) {
                errorMessage += error;
            }
            NotificationToaster.show(Intent.DANGER, errorMessage, false);
            setIsSaving(false);
        });
    }

    function resetData() {
        setDriverListSelectedDriver(null);
        setSelectedActivitiesList([]);
        setStageNumber(1);
        setPrintoutFiles([]);
        setAwaitingPrintoutFlag(false);
        setIsSaving(false);
        setSearchTerm('');
        setModalTitle(<></>);
        currentPayload.current = null;
    }

    function onUploadPrintoutLaterClick() {
        setAwaitingPrintoutFlag(true);
    }

    const onButtonClick = () => {
        printoutFile.current.click();
    };

    function printoutAlreadyUploading(file) {
        if (printoutFiles.length > 0) {
            const re = printoutFiles.find(x => x.name === file.name);
            return re !== undefined;
        }
        else {
            return false;
        }
    }

    function onPrintoutFileChange(e) {
        if (printoutAlreadyUploading(e.target.files[0])) {
            return;
        }

        setPrintoutFiles((prevState) => {
            prevState.push(e.target.files[0]);
            return [...prevState];
        });

        printoutFile.current.value = null;
    }

    function onPrintoutFileDelete(index) {
        setPrintoutFiles((prevState) => {
            prevState.splice(index, 1);
            return [...prevState];
        });
    }

    return (
        <Modal
            isOpen={showModal}
            onClose={onCloseModal}
            clickOutsideClose={true}
            title={modalTitle}
            isCloseButtonShown={false}
            updateStateOnClose={false}
            className='assign-driver-modal'

        >
            <UserAccess perform={requiredActions}
                yes={() => {
                    return (
                        <>
                            <ShowHide evaluator={stageNumber === 1} show={
                                <>
                                    <div className='inline-items'>
                                        <FormLocationSelector
                                            businessArea={"Tacho"}
                                            loading={driverListIsLoading}
                                            onLocationListOpened={onLocationListOpened}
                                            selectedLocations={driverListSelectedLocations}
                                            setSelectedLocations={onSelectedLocationsChange}
                                            useLocationDefaults={true}
                                            useHierarchy={driverListLocationsUseHierarchy}
                                            locationDefaultsToTrue={true}
                                            setUseHierarchy={setDriverListLocationsUseHierarchy}
                                        />
                                        <FormTextInput placeholder="Search drivers" onChange={(x) => onSearchTextChange(x.target.value)} value={searchTerm} large icon="search" id="user-search-field" />
                                        <Tooltip content="Filter" position="right">
                                            <Button icon="filter" onClick={onToggleDriverListFilter} className={classNames({ "active": driverListFilters.length > 0, "hidden": driverListFilters.length === 0 })} />
                                        </Tooltip>
                                    </div>
                                    <Filter filterName='assignDrivers' visible={driverListShowFilters} filters={driverListFilters} onUpdate={onDriverListFiltersChange} defaultFilterValues={driverListDefaultFilters} />
                                    <div className='assign-drivers-header'>
                                        <h3>All Drivers</h3>
                                    </div>
                                    <div className='assign-drivers-table'>
                                        <ListingTable
                                            headers={['Name', 'Location', 'Reference', 'Select']}
                                            sortable={true}
                                            sortableHeaders={['Name', 'Location', 'Reference']}
                                            data={driverListFormatted}
                                            loadingData={driverListIsLoading}
                                            totalRecordCount={driverListTotalRecords}
                                            onPagingChange={handleEmployeeListOnPagingChange}
                                            onSort={handleEmployeeListOnSort}
                                            sortedBy={driverListSortedBy}
                                            sortedDir={driverListSortedDir}
                                            noDataHeading="No drivers found"
                                            allowTableScrollVertical={true}
                                            showPerPage={false}
                                        />
                                    </div>
                                </>
                            }
                            />
                            <ShowHide evaluator={stageNumber === 2} show={
                                <>
                                    <div className="assign-driver-printout-section">
                                        <Dropdown
                                            text="Printout"
                                            intent={Intent.PRIMARY}
                                            items={[
                                                <MenuItem text="Upload Printout" onClick={onButtonClick} key="upload-printout" />,
                                                <MenuItem text="Upload Printout Later" onClick={onUploadPrintoutLaterClick} key="upload-printout-later" />
                                            ]}
                                            popoverPosition='BOTTOM_LEFT'
                                        />
                                        <ShowHide evaluator={awaitingPrintoutFlag} show={
                                            <div className="awaiting-printout-section">
                                                <div>
                                                    <Icon icon="time" />
                                                    <span className='awaiting-printout-flag-wording'>Awaiting Printout</span>
                                                </div>
                                                <Button className='' iconOnly={true} minimal={true} large={true} icon="cross" onClick={() => setAwaitingPrintoutFlag(false)} />

                                            </div>
                                        } />
                                        <input type='file' id='file' ref={printoutFile} style={{ display: 'none' }} onChange={onPrintoutFileChange} />
                                        <ShowHide evaluator={printoutFiles.length > 0} show={
                                            <Panel color="#008CFF" className="printout-section">
                                                <h3>Driver Printouts</h3>
                                                <ListingTable
                                                    headers={['File', 'Delete']}
                                                    data={printoutFiles.map((f, i) => { return [f.name, <Button icon="trash" minimal={true} onClick={() => { onPrintoutFileDelete(i) }} key={`button-delete-${f.name}`} />] })}
                                                    loadingData={false}
                                                    totalRecordCount={1}
                                                    sortable={false}
                                                    pageable={false}
                                                    columnClasses={['printout-file', 'printout-delete']}
                                                    id="printout-listing"
                                                />
                                            </Panel>
                                        } />
                                    </div>

                                    <div>
                                        <h3>Vehicle Activities</h3>
                                        <p>Select the first and last activity to be assigned to this driver.</p>
                                    </div>
                                    <ActivitiesList
                                        id="assign-activities-list"
                                        isLoading={false}
                                        activitiesList={cardIw ? cardIw.activities : []}
                                        selectable={true}
                                        onActivityCheckChange={onActivityCheckChange}
                                        selectedActivities={selectedActivitiesList}
                                        selectAll={selectAll}
                                        rowHighlighting={false}
                                    />
                                </>
                            }
                            />

                            <div className='inline-items push-right spacer-top'>
                                <Button text="Cancel" onClick={onCloseModal} disabled={isSaving} />
                                <Button text={stageNumber === 1 ? "Next" : "Back"} intent="primary" disabled={!driverListSelectedDriver || isSaving} onClick={toggleStageNumber} />
                                <ShowHide evaluator={driverListSelectedDriver !== null && selectedActivitiesList !== null && selectedActivitiesList.length > 0 && stageNumber === 2} show={
                                    <ButtonSave
                                        text="SAVE AND CLOSE"
                                        intent={Intent.PRIMARY}
                                        onClick={handleSave}
                                        loading={false}
                                        disabled={isSaving}
                                        icon="floppy-disk"
                                    />
                                } />
                                {showDirtyDataAlert && (
                                    <AlertUnsavedData
                                        isDirty={showDirtyDataAlert}
                                        isDirtySamePage={showDirtyDataAlert}
                                        onConfirmLeave={handleConfirmLeave}
                                        onCancelLeave={handleCancelLeave}
                                    />
                                )}
                            </div>
                        </>)
                }
                } no={() => <PageRestricted />}
            />
        </Modal>
    );
}

AssignDriverModal.defaultProps = {

}

AssignDriverModal.propTypes = {
    showModal: PropTypes.bool.isRequired,
    onCloseModal: PropTypes.func.isRequired,
    assetDetails: PropTypes.object.isRequired,
    date: PropTypes.string.isRequired,
    cardIw: PropTypes.object,
    onSave: PropTypes.func
}
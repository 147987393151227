import React, { useState, useRef, useEffect, Fragment } from 'react';
import { Link } from "react-router-dom";
import axios from 'axios';
import classNames from "classnames";

import { useDebounce } from 'hooks/useDebounce';
import { DateToLocal } from 'components/dates';
import { Breadcrumbs } from 'components/navigation';
import { FormTextInput } from "components/form-fields";
import { LinkButton, Button } from 'components/buttons'
import { ListingTable } from "components/listing";
import { ShowHide } from "components/layout";
import { Tooltip } from 'components/tooltip';
import { Filter } from 'components/filtering';

import { HelperFunctions } from 'helpers';

import { PageRestricted } from 'pages/errors/page-restricted';
import { UserAccess, UserHasAccess } from 'components/user-access';
import { LocationService } from 'services';

export function LocationListing(props) {

    const defaultRecordSize = 25;
    const debounceTimeout = 750;

    const [initalSetupComplete, setInitalSetupComplete] = useState(false);
    const [totalRecords, setTotalRecords] = useState(0);
    const [tableRows, setTableRows] = useState([]);
    const [pageNumber, setPageNumber] = useState(1);
    const [pageSize, setPageSize] = useState(defaultRecordSize);
    const [pageFilters, setPageFilters] = useState([]);
    const [searchTerm, setSearchTerm] = useState("");
    const [loadingData, setLoadingData] = useState(true);
    const [noDataMessage, setNoDataMessage] = useState("You have no locations set up, please click 'Add new location' to get started.");
    const tableHeaders = ["Name", "Business Area", "Created", "Last Updated", "Status"];
    const sortableHeaders = ["Name", "Created", "Last Updated", "Status"];
    const [tableSortBy, setTableSortBy] = useState("Name");
    const [tableSortDir, setTableSortDir] = useState("A");
    const debouncedSearchTerm = useDebounce(searchTerm, debounceTimeout);
    const inputRef = useRef(null);
    const requiredAction = "Locations:Manage";
    const hasHierarchiesManage = UserHasAccess("Locations:HierarchiesManage");
    const canAccessPage = UserHasAccess(requiredAction);


    const [appliedFilters, setAppliedFilters] = useState([]);
    const [showFilters, setShowFilters] = useState(false);

    const currentPageNumber = useRef();
    const currentPageSize = useRef();
    const currentSearchTerm = useRef();
    const currentTableSortBy = useRef();
    const currentTableSortDir = useRef();
    const currentAppliedFilters = useRef();

    useEffect(() => {

        axios.all([
            LocationService.getLocationBusinessAreas(),
            LocationService.getLocationStates()
        ]).then(axios.spread(function (businessAreas, states) {

            var mappedAreas = HelperFunctions.sortAlphabetically(businessAreas.map(function (area) {
                return { "id": area.businessArea, "name": area.businessArea }
            }), "name");

            var mappedStates = HelperFunctions.sortAlphabetically(states.map(function (state) {
                return { "id": state.name, "name": state.name }
            }), "name");


            var filterArray = [
                {
                    "displayName": "Business Area", "name": "BusinessAreas", "items": [
                        ...mappedAreas
                    ]
                },
                {
                    "displayName": "State", "name": "States", "items": [
                        ...mappedStates
                    ]
                }
            ];

            setPageFilters(filterArray);

        }));
    }, []);

    useEffect(() => {
        setPageNumber(1);
        setNoDataMessage("No results found, please try a different search term.");
        setLoadingData(true);
    }, [debouncedSearchTerm]);

    useEffect(() => {
        
    }, [loadingData, pageSize, pageNumber, tableSortBy, tableSortDir, debouncedSearchTerm]);

    function fetchTableData() {

        if (canAccessPage && initalSetupComplete) {

            if (
                currentPageNumber.current === pageNumber &&
                currentPageSize.current === pageSize &&
                currentSearchTerm.current === debouncedSearchTerm &&
                currentTableSortBy.current === tableSortBy &&
                currentTableSortDir.current === tableSortDir &&
                currentAppliedFilters.current === appliedFilters.length
            ) {
                //If none of the values have changed, then 2 have been updated at once, so prevent the listing from performing another call.
                return;
            }

            setLoadingData(true);

            currentPageNumber.current = pageNumber;
            currentPageSize.current = pageSize;
            currentSearchTerm.current = debouncedSearchTerm;
            currentTableSortBy.current = tableSortBy;
            currentTableSortDir.current = tableSortDir;
            currentAppliedFilters.current = appliedFilters.length;


            var mappedFilters = appliedFilters.map(function (f) {
                return {
                    key: f.name,
                    ...f
                }
            });

            LocationService.getLocationList(pageSize, pageNumber, debouncedSearchTerm, tableSortBy, tableSortDir, { filters: mappedFilters }).then(r => {
                let results = r.data;
                let data = [];

                if (results != null) {
                    data = results.map((d) => {
                        var businessAreas = d.businessAreas.map(b => {
                            return b.businessArea;
                        });
                        return [(<Link to={`/location/${d.id}`}>{d.name}</Link>), businessAreas.join(", "), <DateToLocal>{d.createdDate}</DateToLocal>, <DateToLocal>{d.modifyDate}</DateToLocal>, d.state.name];
                    })
                }

                setTableRows(data);
                setTotalRecords(r.totalCount);
                setLoadingData(false);
                setTimeout(function () {
                    if (inputRef.current != null) {
                        inputRef.current.focus();
                    }
                });

            });
        }
    }

    useEffect(() => {
        fetchTableData();
    }, [pageSize, pageNumber, tableSortBy, tableSortDir, debouncedSearchTerm, appliedFilters.length, initalSetupComplete]);

    function onPagingChange(newPageNumber, newPageSize) {
        setPageNumber(newPageNumber);
        setPageSize(newPageSize);
    }

    function onToggleFilter() {
        setShowFilters((prevState) => {
            return !prevState;
        })
    }

    function onSearchChange(item) {
        setSearchTerm(item.target.value);
    }

    function onTableSort(header, direction) {
        setTableSortBy(header);
        setTableSortDir(direction);
    }

    function onFilterChange(listingFilters) {
        setInitalSetupComplete(true);
        setPageNumber(1);
        setNoDataMessage("No results found, please try a different filter.");
        setAppliedFilters(listingFilters);

        if (listingFilters.length > appliedFilters.length) {
            setShowFilters(true);
        }
    }

    return (
        <UserAccess perform={requiredAction}
            yes={() => (
                <div className="row">

                    <Breadcrumbs items={props.breadcrumbs} />

                    <h1>Location Management</h1>
                    <div className="intro-text">
                        <p>In this section you can add and manage the locations you use for each of the different services provided by Logistics UK.</p>
                    </div>

                    <div className="button-row button-row-stacked-mobile spacer-bottom">
                        <div>
                            <LinkButton intent="primary" text="Add new location" href="/location/create" id="new-location" />
                            <ShowHide
                                evaluator={hasHierarchiesManage}
                                show={(
                                    <LinkButton intent="primary" text="Manage Location Hierarchies" href="/location/hierarchies" id="manage-location-hierarchies" />

                                )}
                            >
                            </ShowHide>
                        </div>
                    </div>

                    <ShowHide
                        evaluator={tableRows.length === 0 && !loadingData && debouncedSearchTerm === "" && appliedFilters.length === 0 && initalSetupComplete}
                        hide={(
                            <Fragment>
                                <div className={classNames("pull-left", { "spacer-bottom": !showFilters })}>
                                    <div className="inline-items">
                                        <FormTextInput inputRef={inputRef} placeholder="Location Search" onChange={onSearchChange} value={searchTerm} large disabled={loadingData} icon="search" id="location-search-field" />
                                        <Tooltip content="Filter" position="right">
                                            <Button icon="filter" onClick={onToggleFilter} className={classNames({ "active": appliedFilters.length > 0 })} />
                                        </Tooltip>
                                    </div>
                                </div>
                                <Filter filterName="locations" visible={showFilters} filters={pageFilters} onUpdate={onFilterChange} />
                            </Fragment>
                        )}
                    />

                    <ListingTable
                        id="listing-table-location"
                        headers={tableHeaders}
                        data={tableRows}
                        totalRecordCount={totalRecords}
                        onPagingChange={onPagingChange}
                        loadingData={loadingData}
                        noDataMessage={noDataMessage}
                        sortable
                        sortableHeaders={sortableHeaders}
                        sortedBy={tableSortBy}
                        sortedDir={tableSortDir}
                        onSort={onTableSort}
                    />
                </div>
            )}
            no={() => (
                <PageRestricted />
            )}
        />
    );
}
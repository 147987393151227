import React, { useState, useEffect } from 'react';
import { Breadcrumbs } from 'components/navigation';
import { ExternalContactService } from "services";
import { UserAccess } from 'components/user-access';
import { PageRestricted } from 'pages/errors/page-restricted';
import { ExternalContactSetting } from 'pages/external-contacts/shared';
import { LinkButton, Button } from "components/buttons";
import { ShowHide } from "components/layout";
import { Intent, Icon } from '@blueprintjs/core';

export function ExternalContactCreate(props) {

    const externalContactModel = {
        userType: "External"
    };

    const [loading, setLoading] = useState(true);
    const [saving, setSaving] = useState(false);
    const [externalContact] = useState(externalContactModel);
    const [externalContactCreated, setExternalContactCreated] = useState(false);

    const requiredAction = "Users:ExternalContacts:Manage";

    useEffect(initialLoad, []);
    function initialLoad() {
        setLoading(false);
    }

    function resetComponent() {
        initialLoad();
        setExternalContactCreated(false);
    }

    function onExternalContactSave(newExternalContact) {
        setSaving(true);


        return new Promise((resolve, reject) => {
            ExternalContactService.createExternalContact(newExternalContact).then(
                () => {
                    setSaving(false);
                    setExternalContactCreated(true);
                    resolve("External contact created successfully.");
                    window.scrollTo({ top: 0, behavior: 'smooth' });
                },
                (error) => {
                    setSaving(false);
                    reject(error);
                }
            );
        });
    }

    return (
        <UserAccess perform={requiredAction}
            yes={() => (

                <div>
                    <Breadcrumbs items={props.breadcrumbs} />

                    <h2>External Contact Details</h2>

                    <ShowHide
                        evaluator={!externalContactCreated}
                        show={(
                            <ExternalContactSetting
                                externalContact={externalContact}
                                loading={loading}
                                editing={false}
                                onExternalContactSave={onExternalContactSave}
                                saving={saving}
                            />
                        )}
                        hide={(
                            <div>
                                <div className="spacer-bottom" id="create-success-icon">
                                    <Icon icon="tick-circle" iconSize={40} intent={Intent.SUCCESS} />
                                </div>
                                <h2 id="create-success-name">External contact has been added successfully</h2>
                                <div className="spacer-bottom">
                                    <p>Your external contact has been created. They will shortly receive an email, asking them to validate their email. Until their email is validated they will not be able to be used as a location external contact.</p>
                                </div>
                                <div className="button-row" id="create-success-buttons">
                                    <LinkButton intent="primary" text="External Contact Management" id="listing-external-contact" externalLink href="/external-contact" />
                                    <Button intent="primary" text="Add new external contact" id="new-asset" onClick={resetComponent} />
                                </div>
                            </div>
                        )}
                    ></ShowHide>
                </div>
            
            )}
            no={() => (
                <PageRestricted />
            )}
        /> 
    );
}
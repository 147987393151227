import { Intent } from "@blueprintjs/core";
import axios from "axios";
import { ShowHide } from "components/layout";
import { NotificationToaster } from "components/notifications";
import { useDirtyData } from "hooks/useDirtyData";
import React, { useEffect, useState } from "react";
import { EmployeeService } from "services";
import { EmployeeAssessmentSettingsCreate } from "./create";
import { EmployeeAssessmentSettingsEdit } from "./edit";

export function EmployeeAssessmentSettings() {
    const defaultEmployeeAssessmentSettings = {
        employeeTypes: [],
        employeeCategories: [],
        useAllLocations: false,
        locations: [],
        greenBandSettings: {
            reoccurrenceSchedule: {
                reoccurring: true,
                cycle: {
                    frequency: 1,
                    frequencyType: 'year',
                    type: 'repeat'
                }
            },
            alertType: 'OFF'
        },
        amberBandSettings: {
            reoccurrenceSchedule: {
                reoccurring: true,
                cycle: {
                    frequency: 6,
                    frequencyType: 'month',
                    type: 'repeat'
                }
            },
            alertType: 'OFF'
        },
        redBandSettings: {
            reoccurrenceSchedule: {
                reoccurring: true,
                cycle: {
                    frequency: 3,
                    frequencyType: 'month',
                    type: 'repeat'
                }
            },
            alertType: 'OFF'
        }
    };
    const [employeeTypes, setEmployeeTypes] = useState([]);
    const [employeeCategories, setEmployeeCategories] = useState([]);
    const [alertTypes, setAlertTypes] = useState([]);
    const [employeeAssessmentSettings, setEmployeeAssessmentSettings] = useState(defaultEmployeeAssessmentSettings);
    const [originalEmployeeAssessmentSettings, setOriginalEmployeeAssessmentSettings] = useState(defaultEmployeeAssessmentSettings);
    const [loading, setLoading] = useState(true);
    const [saving, setSaving] = useState(false);
    const [isCreating, setIsCreating] = useState(false);
    const [isValid, setIsValid] = useState(true);

    const isDataDirty = useDirtyData(originalEmployeeAssessmentSettings, employeeAssessmentSettings);

    useEffect(onInitialLoad, []);

    function onInitialLoad() {
        axios.all([
            EmployeeService.getEmployeeOptions(),
            EmployeeService.getEmployeeAssessmentSettingsOptions(),
            EmployeeService.getEmployeeAssessmentSettings(),
        ]).then(axios.spread(function (employeeOptions, employeeAssessmentSettingOptions, employeeAssessmentSettingsData) {
            setEmployeeTypes(employeeOptions.employeeTypes);
            setEmployeeCategories(employeeOptions.employeeCategories);
            setAlertTypes(employeeAssessmentSettingOptions.alertTypes);

            setLoading(false);

            if (employeeAssessmentSettingsData) {
                setEmployeeAssessmentSettings(employeeAssessmentSettingsData);
                setOriginalEmployeeAssessmentSettings(employeeAssessmentSettingsData);
                setIsCreating(false);
            } else {
                setIsCreating(true);
            }
        }),
            () => {
                NotificationToaster.show(Intent.DANGER, "Could not load settings. Please refresh the page.");
            }
        );
    }

    function saveSettings() {
        setSaving(true);

        EmployeeService.saveEmployeeAssessmentSettings(employeeAssessmentSettings)
            .then(
                () => {
                    setSaving(false);
                    setOriginalEmployeeAssessmentSettings(employeeAssessmentSettings);

                    if (!isCreating) {
                        NotificationToaster.show(Intent.SUCCESS, "Settings updated successfully.");
                    } else {
                        setIsCreating(true);
                        onInitialLoad();
                    }

                },
                () => {
                    setSaving(false);
                    NotificationToaster.show(Intent.DANGER, "Could not save the settings. Please try again.");
                }
            );
    }

    function onGeneralSettingsUpdate(settings) {
        const updatedSettings = {
            ...employeeAssessmentSettings,
            ...settings
        };

        setEmployeeAssessmentSettings(updatedSettings);
    }

    function onBandSettingsUpdate(settings, propertyName) {
        const updatedBandSettings = {
            ...employeeAssessmentSettings[propertyName],
            ...settings
        };

        const updatedSettings = {
            ...employeeAssessmentSettings
        };
        updatedSettings[propertyName] = updatedBandSettings;

        setEmployeeAssessmentSettings(updatedSettings);
    }

    function onValidationUpdate(item) {
        setIsValid(item);
    }

    function onReset() {
        setIsCreating(false);
        setLoading(true);
        onInitialLoad();
    }

    return (
        <ShowHide
            evaluator={isCreating}
            show={(
                <EmployeeAssessmentSettingsCreate
                    employeeAssessmentSettings={employeeAssessmentSettings}
                    saving={saving}
                    loading={loading}
                    employeeTypes={employeeTypes}
                    employeeCategories={employeeCategories}
                    alertTypes={alertTypes}
                    onGeneralSettingsUpdate={onGeneralSettingsUpdate}
                    onBandSettingsUpdate={onBandSettingsUpdate}
                    onValidationUpdate={onValidationUpdate}
                    onSave={saveSettings}
                    isDataDirty={isDataDirty}
                    isValid={isValid}
                    onRefresh={onReset}
                />
            )}
            hide={(
                <EmployeeAssessmentSettingsEdit
                    employeeAssessmentSettings={employeeAssessmentSettings}
                    saving={saving}
                    loading={loading}
                    employeeTypes={employeeTypes}
                    employeeCategories={employeeCategories}
                    alertTypes={alertTypes}
                    onGeneralSettingsUpdate={onGeneralSettingsUpdate}
                    onBandSettingsUpdate={onBandSettingsUpdate}
                    onValidationUpdate={onValidationUpdate}
                    onSave={saveSettings}
                    isDataDirty={isDataDirty}
                    isValid={isValid}
                />
            )}
        />
    );
}
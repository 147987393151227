import classNames from 'classnames';
import { DateToLocal } from 'components/dates';
import { Expandable, ShowHide } from 'components/layout';
import { EditTable } from 'components/listing';
import moment from 'moment';
import PropTypes from 'prop-types';
import React, { useState, Fragment } from 'react';
import { OcrTableFormModal } from '.';
import { ActiveStatus } from 'components/status';

export function OcrTableLayout(props) {
    const [rowBeingEdited, setRowBeingEdited] = useState(null);
    const [addingRow, setAddingRow] = useState(false);
    const [rowValidation, setRowValidation] = useState([]);

    function formatRows() {
        if (!props.layout.table.rows) {
            return [];
        }

        return props.layout.table.rows
            .filter((_, index) => !hasMultipleHeaderRows() || index !== 0)
            .map((row, rowIndex) => {

                var rowValid = true;
                const foundValidValue = rowValidation.find(e => e.id === rowIndex);

                if (foundValidValue) {
                    rowValid = foundValidValue.value
                }

                return {
                    key: rowIndex,
                    cells: formatRow(row),
                    validity: formatRowValidity(row, rowIndex),
                    isEditable: false,
                    valid: rowValid
                };
            });
    }

    function formatRowValidity(row, rowIndex) {
        return <OcrTableFormModal
            columns={props.layout.table.columns}
            row={row}
            getColumnTypeForCell={props.getColumnTypeForCell}
            employeeList={props.employeeList}
            assetList={props.assetList}
            rowBeingEdited={rowBeingEdited}
            rowIndex={rowIndex}
            onFinish={onTableFormFinish}
            onClose={onTableFormClose}
            isEditing={!addingRow}
            booleanSelectValues={props.booleanSelectValues}
            booleanNaSelectValues={props.booleanNaSelectValues}
            editableColumnTypes={props.editableColumnTypes}
            getEmployeeForRow={findEmployee}
            onValidationUpdate={(isValid) => { onRowValidChange(rowIndex, isValid) }}
        />;
    }

    function formatRow(row) {
        return row.map((cell, cellIndex) => {
            const columnType = props.getColumnTypeForCell(cellIndex);
            const employee = findEmployee(row);
            const vehicle = findVehicle(row);

            switch (columnType?.toUpperCase()) {
                case 'FIXED':
                case 'STRING':
                case 'INTEGER':
                    return formatCell(cell);
                case 'POUND':
                    return '\u00a3' + formatCell(cell);
                case 'PASSFAIL':
                    return <ActiveStatus isActive={cell === 'True'} activeText='Compliant' inactiveText='Non Compliant' />;
                case 'RATING':
                    return formatRatingCell(cell);
                case 'FIXEDDATE':
                case 'DATEPAST':
                    return <DateToLocal format='DD/MM/YYYY'>{formatDateCell(cell, 'YYYY-MM-DD')}</DateToLocal>;
                case 'DRIVERNAME':
                    return formatCell(employee?.employeeName);
                case 'VEHICLEREGISTRATION':
                    return formatCell(vehicle?.registration);
                case 'DRIVERLOCATION':
                    return formatCell(employee?.locationName);
                case 'DRIVERSTARTDATE':
                    return <DateToLocal format='DD/MM/YYYY'>{formatDateCell(employee?.startDate)}</DateToLocal>;
                case 'FIXEDTIME':
                    return <DateToLocal format='HH:mm'>{cell}</DateToLocal>;
                case 'BOOLEAN':
                    if (cell == null || cell === '') {
                        return '-';
                    } else {
                        return props.booleanSelectValues.find(x => x.id === cell).name;
                    }
                case 'BOOLEANNA':
                    if (cell == null || cell === '') {
                        return '-';
                    } else {
                        return props.booleanNaSelectValues.find(x => x.id === cell).name;
                    }
                case 'HOLDINGDAYS':
                    return formatHoldingDayCell(cell);
                case 'VARIANCE':
                    return formatVarianceCell(cell);
                default:
                    return 'Column type not yet supported';
            }
        });
    }

    function formatCell(cellValue) {
        return cellValue ? cellValue : '-';
    }

    function formatRatingCell(cellValue) {
        if (props.previsit.usePerformanceRatings) {
            var className = 'cell-rating-' + cellValue.toLowerCase();

            return <Fragment>
                <span className={classNames(className, 'performance-rating')}>{cellValue ? cellValue : '-'}</span>
            </Fragment>;
        }

        return <span></span>;
    }

    function formatHoldingDayCell(cellValue) {
        var className;
        var greenCutOffDays = 7;
        var days = Number(cellValue);
        if (!isNaN(days)) {
            className = 'form-field-' + ((days <= greenCutOffDays) ? 'green' : 'red');
        }

        return <Fragment>
            <span className={classNames(className)}>{cellValue ? cellValue : '-'}</span>
        </Fragment>;
    }

    function formatVarianceCell(cellValue) {
        var className;
        var value = Number(cellValue);
        if (!isNaN(value)) {

            className = 'form-field-';
            if (value < 0) {
                className = className + "red";
            } else if (value > 0) {
                className = className + "green";
            }
        }

        return <Fragment>
            <span className={classNames(className)}>{cellValue ? cellValue : '-'}</span>
        </Fragment>;
    }

    

    function formatDateCell(cellValue, inputFormat) {
        return cellValue ? moment(cellValue, inputFormat) : '-';
    }

    function findEmployee(row) {
        const employeeColumnIndex = props.layout.table.columns
            .findIndex(c => c.columnType.toUpperCase() === 'DRIVERNAME');

        if (employeeColumnIndex > -1) {
            const employeeId = row[employeeColumnIndex];

            return props.employeeList.find(e => e.employeeId === +employeeId);
        }

        return null;
    }

    function findVehicle(row) {
        const vehicleColumnIndex = props.layout.table.columns
            .findIndex(c => c.columnType.toUpperCase() === 'VEHICLEREGISTRATION');

        if (vehicleColumnIndex > -1) {
            const assetId = row[vehicleColumnIndex];

            return props.assetList.find(e => e.assetId === +assetId);
        }

        return null;
    }


    function onTableFormFinish(row) {
        const rowsClone = [...props.layout.table.rows];
        rowsClone[rowBeingEdited] = row.map(cell => cell === null ? null : cell.toString());

        const tableClone = {
            ...props.layout.table,
            rows: rowsClone
        };

        props.onChange({
            ...props.layout,
            table: tableClone
        });

        setRowBeingEdited(null);
    }

    function onTableFormClose() {
        if (addingRow) {
            onDeleteRow(rowBeingEdited);
        }

        setRowBeingEdited(null);
    }

    function formatHeaders() {
        if (hasMultipleHeaderRows()) {
            return props.layout.table.rows[0].map((row, rowIndex) => {
                return {
                    key: rowIndex,
                    value: row
                };
            });
        }

        return props.layout.table.columns.map((column, i) => {
            return {
                key: column.columnId,
                value: props.getColumnTypeForCell(i).toUpperCase() === 'RATING' && !props.previsit.usePerformanceRatings ? '' : column.columnTitle
            };
        });
    }

    function formatHeaderData() {
        return {
            headers: formatHeaders(),
            topLevelHeaders: formatTopLevelHeader(),
        }
    }

    function formatTopLevelHeader() {
        if (!hasMultipleHeaderRows()) {
            return [];
        }

        return props.layout.table.columns.map(column => {
            return {
                value: column.columnTitle,
                colspan: column.colspan ? column.colspan : 1
            };
        });
    }

    function hasMultipleHeaderRows() {
        return props.layout.table.columns.some((column) => column.colspan > 1);
    }

    function onAddRow(rowIndex) {
        const row = props.layout.table.columns.map(_ => '');

        const tableRowsClone = props.layout.table.rows ? [...props.layout.table.rows] : [];

        tableRowsClone.splice(rowIndex + 1, 0, row);

        const tableClone = {
            ...props.layout.table,
            rows: tableRowsClone
        };

        props.onChange({
            ...props.layout,
            table: tableClone
        });

        setRowBeingEdited(rowIndex + 1);
        setAddingRow(true);
    }

    function onDeleteRow(rowIndex) {
        const tableRowsClone = props.layout.table.rows ? [...props.layout.table.rows] : [];

        tableRowsClone.splice(rowIndex, 1);

        const tableClone = {
            ...props.layout.table,
            rows: tableRowsClone
        };

        props.onChange({
            ...props.layout,
            table: tableClone
        });
    }

    function onEditRow(rowIndex) {
        setRowBeingEdited(rowIndex);
        setAddingRow(false);
    }

    function anyEditableRows() {
        return props.layout.table.columns
            .filter(x => props.editableColumnTypes.includes(x.columnType.toUpperCase()))
            .length > 0;
    }

    function canEditRows() {
        return props.layout.table.canAddRows || anyEditableRows();
    }

    function onRowValidChange(rowIndex, isValid) {
        setRowValidation(function (prevState) {

            var clonedArray = [
                ...prevState
            ];

            const foundValue = clonedArray.find(e => e.id === rowIndex);

            if (foundValue) {
                foundValue.value = isValid;
            } else {
                clonedArray.push({ id: rowIndex, value: isValid });
            }

            return clonedArray;
        });
    }

    function renderTable() {
        return <EditTable
            headerData={formatHeaderData()}
            data={formatRows()}
            loadingData={props.loading}
            noDataMessage={props.layout.table.emptyMessage}
            noDataHeading={null}
            canAddRows={props.layout.table.canAddRows && !props.readonly}
            onAddRowClick={onAddRow}
            canDeleteRows={props.layout.table.canAddRows && !props.readonly}
            onDeleteRowClick={onDeleteRow}
            canEditAllRows={canEditRows() && !props.readonly}
            onEditRowClick={onEditRow}
            disabled={props.disabled || props.loading || props.readonly}
            fitToContent={true}
        />
    }

    return <div className='spacer-bottom'>
        <ShowHide
            evaluator={!!props.layout.secondaryLayoutTitle}
            show={<h4>{props.layout.secondaryLayoutTitle}</h4>}
        />
        <ShowHide
            evaluator={props.tableExpandable}
            show={
                <Expandable
                    loading={props.loading}
                    expanded={props.tableExpanded}
                >
                    {renderTable()}
                </Expandable>
            }
            hide={renderTable()}
        />
    </div>;
}

OcrTableLayout.propTypes = {
    loading: PropTypes.bool,
    layout: PropTypes.object.isRequired,
    employeeList: PropTypes.array.isRequired,
    assetList: PropTypes.array.isRequired,
    onChange: PropTypes.func.isRequired,
    disabled: PropTypes.bool,
    booleanSelectValues: PropTypes.array.isRequired,
    booleanNaSelectValues: PropTypes.array.isRequired,
    editableColumnTypes: PropTypes.array.isRequired,
    getColumnTypeForCell: PropTypes.func.isRequired,
    readonly: PropTypes.bool,
    tableExpanded: PropTypes.bool,
    tableExpandable: PropTypes.bool,
    previsit: PropTypes.object
};

OcrTableLayout.defaultProps = {
    loading: false,
    disabled: false,
    readonly: false,
    tableExpanded: false,
    tableExpandable: false,
    previsit: {}
};

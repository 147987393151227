import ApiService from "./ApiService";

export const OptionsService = {
    getSystemMessageTypes() {
        return new Promise((resolve, reject) => {
            ApiService.get('/system/options/SystemMessages').then(
                (result) => {
                    resolve(result.data);
                },
                (error) => {
                    reject(error);
                });
        });
    }
}
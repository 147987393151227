import { useEffect, useState } from "react";
import { Note } from "../../../components/note";
import { AlertUnsavedData, Modal } from "../../../components/notifications";
import { useDirtyData } from "../../../hooks/useDirtyData";
import './NotesModal.css';

export function NotesModal(props) {
    const { showNotesModal, closeNotesModal, notesModalTitle, onSaveNotes, onDeleteNotes, loading,
        setNotesModalTitle, selectedDaysNotes, formatDateSelection    } = props;


    const [initialNoteValue, setInitialNoteValue] = useState(selectedDaysNotes);
    const [currentNoteValue, setCurrentNoteValue] = useState(selectedDaysNotes);
    const isDataDirty = useDirtyData(initialNoteValue, currentNoteValue);
    const [showAlert, setShowAlert] = useState(false);

    function handleNoteChange(note) {
        setCurrentNoteValue(note);
    }


    useEffect(() => {
        if (showNotesModal) {
            setInitialNoteValue(selectedDaysNotes);
            setCurrentNoteValue(selectedDaysNotes);
        }
    }, [showNotesModal, selectedDaysNotes])

    function handleCloseNotesModal() {
        if (isDataDirty) {
            setShowAlert(true);
        } else {
            closeNotesModal();
        }
    }

    function handleConfirmLeave() {
        closeNotesModal();
        setShowAlert(false);
    }

    function handleCancelLeave() {
        setShowAlert(false);
    }

    return(
        <Modal
            isOpen={showNotesModal}
            onClose={handleCloseNotesModal}
            title={notesModalTitle}
            autoFocus={false}
            enforceFocus={false}
            canOutsideClickClose={false}
            updateStateOnClose={false}
            className="notes-modal"
        >
            <Note
                onSave={(note) => onSaveNotes(note)}
                onDelete={() => onDeleteNotes()}
                disabled={loading}
                setTitle={setNotesModalTitle}
                notesValue={selectedDaysNotes}
                datesLabel={formatDateSelection()}
                inModal={true}
                onNoteChange={handleNoteChange}
            >
            </Note>
            {showAlert && (
                <AlertUnsavedData
                    isDirty={isDataDirty}
                    isDirtySamePage={isDataDirty}
                    onConfirmLeave={handleConfirmLeave}
                    onCancelLeave={handleCancelLeave}
                />
            )}
        </Modal>
    )
}
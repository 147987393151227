import React, { useState, useRef, useEffect } from 'react';
import { Link } from "react-router-dom";
import { Intent, Icon } from '@blueprintjs/core';
import { useDebounce } from 'hooks/useDebounce';
import { Tooltip } from 'components/tooltip';
import { Breadcrumbs } from 'components/navigation';
import { FormTextInput, FormLocationSelector } from "components/form-fields";
import { LinkButton, Button } from 'components/buttons'
import { ListingTable } from "components/listing";
import { ShowHide } from "components/layout";
import { AlertConfirm, NotificationToaster } from "components/notifications";
import { AccountService, ExternalContactService, LocationService } from 'services';
import { DateToLocal } from "components/dates";
import { PageRestricted } from 'pages/errors/page-restricted';
import { UserAccess, UserHasAccess } from 'components/user-access';
import { Filter } from 'components/filtering';
import { useSelector, useDispatch } from 'react-redux';
import { SetFiltering } from 'state/actions';

import classNames from "classnames";

export function ExternalContactListing(props) {

    const defaultRecordSize = 25;
    const debounceTimeout = 750;

    const [selectedExternalContactId, setSelectedExternalContactId] = useState(0);
    const [totalRecords, setTotalRecords] = useState(0);
    const [rawTableData, setRawTableData] = useState([]);
    const [tableRows, setTableRows] = useState([]);
    const [pageNumber, setPageNumber] = useState(1);
    const [pageFilters, setPageFilters] = useState([]);
    const [pageSize, setPageSize] = useState(defaultRecordSize);
    const [searchTerm, setSearchTerm] = useState("");
    const [loadingData, setLoadingData] = useState(true);
    const [noDataMessage, setNoDataMessage] = useState("You have no external contacts set up, please click 'Add new external contact' to get started.");
    const [tableHeaders] = useState(["Email Address", "First Name", "Last Name", "Phone Number", "Secondary Phone Number", "Created", "Number Of Locations"]);
    const sortableHeaders = ["Email Address", "First Name", "Last Name", "Created", "Phone Number", "Secondary Phone Number"];
    const [tableSortBy, setTableSortBy] = useState("Email Address");
    const [tableSortDir, setTableSortDir] = useState("A");
    const [showDeleteConfirm, setShowDeleteConfirm] = useState(false);
    const [appliedFilters, setAppliedFilters] = useState([]);
    const [showFilters, setShowFilters] = useState(false);
    const [allowAdd, setAllowAdd] = useState(false);
    const debouncedSearchTerm = useDebounce(searchTerm, debounceTimeout);
    const inputSearchRef = useRef(null);
    const [locationList, setLocationList] = useState(null);
    const [selectedLocations, setSelectedLocations] = useState([]);
    const locationListOnFilterOpen = useRef(selectedLocations);
    const [useHierarchy, setUseHierarchy] = useState(false);

    const currentPageNumber = useRef();
    const currentPageSize = useRef();
    const currentSearchTerm = useRef();
    const currentTableSortBy = useRef();
    const currentTableSortDir = useRef();
    const currentAppliedFilters = useRef();
    const currentLocations = useRef();
    const initialLoadProcessing = useRef();
    const dispatch = useDispatch();

    const requiredAction = "Users:ExternalContacts:Manage";
    const canAccessPage = UserHasAccess(requiredAction);
    const externalContactLocationLocationReduxName = "externalContactListingLocation";

    const reduxLocations = useSelector(state => state.filters[externalContactLocationLocationReduxName]);

    function initialise() {
        if (canAccessPage) {
            setupLocationAndLoadPage();
        }
    }

    function setupLocationAndLoadPage() {
        var filterArray = [
            {
                "name": "Status", "items": [
                    { "id": "Active", "name": "Active" },
                    { "id": "Pending", "name": "Pending" }
                ]
            },
            {
                "name": "Zero locations", "items": [
                    { "id": "Exclude", "name": "Exclude" }
                ]
            }
        ];

        setPageFilters(filterArray);

        AccountService.getGeneralSettings().then(function (accountSettings) {
            setAllowAdd(!accountSettings.ssoEnabled);
        });
        LocationService.getFormLocationSelectorCustomLocations(['Tacho', 'Vis', 'Audit']).then(function (locations) {
            setLocationList(locations);

            if (reduxLocations != null && reduxLocations.list != null) {
                setSelectedLocations(reduxLocations.list);
            } else {
                setSelectedLocations(locations.location[0].items.map(l => l.id));
            }
        }, function (error) {
            NotificationToaster.show(Intent.DANGER, `Locations failed ${error}`);
        });
    }

    function getFilters() {
        let mappedFilters = appliedFilters.map(function (f) {
            return {
                key: f.name,
                ...f
            }
        });

        if (selectedLocations != null) {
            selectedLocations.forEach(function (l) {
                mappedFilters = [...mappedFilters,
                { "key": "Location", "value": l }
                ];
            });
        }

        return mappedFilters;
    }

    function fetchTableData(forceRefresh) {
        let filtersArray = getFilters();

        if (currentPageNumber.current === pageNumber &&
            currentPageSize.current === pageSize &&
            currentSearchTerm.current === debouncedSearchTerm &&
            currentTableSortBy.current === tableSortBy &&
            currentTableSortDir.current === tableSortDir &&
            currentAppliedFilters.current === filtersArray.length &&
            currentLocations.current === selectedLocations &&
            !forceRefresh
        ) {
            //If none of the values have changed, then 2 have been updated at once, so prevent the listing from performing another call.
            return;
        }

        currentPageNumber.current = pageNumber;
        currentPageSize.current = pageSize;
        currentSearchTerm.current = debouncedSearchTerm;
        currentTableSortBy.current = tableSortBy;
        currentTableSortDir.current = tableSortDir;
        currentAppliedFilters.current = filtersArray.length;
        currentLocations.current = selectedLocations;

        const searchRequest = {
            requestCount: pageSize,
            pageNumber: pageNumber,
            SearchTerm: debouncedSearchTerm,
            sortField: tableSortBy,
            sortDirection: tableSortDir,
            filters: filtersArray,
        };

        setLoadingData(true);
        ExternalContactService.searchExternalContacts(searchRequest).then((r) => {
                setRawTableData(r.data);
                setTotalRecords(r.totalCount);
                setLoadingData(false);
                setTimeout(() => {
                    if (inputSearchRef.current != null) {
                        inputSearchRef.current.focus();
                    }
                });
            },
            (error) => {
                NotificationToaster.show(Intent.DANGER, error);
            });
    }

    function onLocationListOpened() {
        locationListOnFilterOpen.current = selectedLocations;
    }

    function onLocationListClose() {
        if (locationListOnFilterOpen.current.toString() !== selectedLocations.toString()) {
            setLoadingData(true);
        }
    }

    useEffect(() => {
        setPageNumber(1);
        setNoDataMessage("No results found, please try a different search term.");
    }, [debouncedSearchTerm]);

    useEffect(() => {
        //We'll just set up the page when the redux has loaded
        if (locationList == null) {
            initialise();
        }
    }, [reduxLocations]);

    useEffect(() => {
        if (locationList != null && !initialLoadProcessing.current) {
            initialLoadProcessing.current = true;
            fetchTableData(false);
        }
        if (!loadingData) {
            dispatch(SetFiltering(externalContactLocationLocationReduxName,
                {
                    list: selectedLocations
                }
            ));
        }
    }, [selectedLocations]);

    useEffect(() => {
        if (locationList != null) {
            fetchTableData(false);
        }
    }, [loadingData, pageSize, pageNumber, tableSortBy, tableSortDir, debouncedSearchTerm, appliedFilters.length]);

    useEffect(() => {

        let data = [];

        if (rawTableData != null) {
            data = rawTableData.map((d) => {

                return [
                    <Link to={`/external-contact/${d.externalContactID}`} key={d.externalContactID}>{d.emailAddress}</Link>,
                    d.firstName,
                    d.lastName,
                    d.phoneNumber,
                    d.secondaryPhoneNumber,
                    <DateToLocal key={"createdate-" + d.externalContactID}>{d.createdDate}</DateToLocal>,
                    d.locations.length,
                    <div className="button-row-small" key={"actions-" + d.externalContactID}>
                        <ShowHide
                            evaluator={d.locations.length === 0}
                            show={(
                                <Tooltip content="Delete external contact">
                                    <Button onClick={() => deleteExternalContact(d.externalContactID)} icon="trash" iconOnly={true} text="Delete External Contact" large={false} minimal={true}></Button>
                                </Tooltip>
                            )}
                            hide={(
                                <Tooltip content="Remove all location links to enable deletion">
                                    <Button onClick={() => { }} icon="trash" iconOnly={true} text="Delete External Contact" large={false} minimal={true} disabled></Button>
                                </Tooltip>
                            )}>
                        </ShowHide>
                        <ShowHide
                            evaluator={!d.verified}
                            show={(
                                <Tooltip content="Email verification is incomplete">
                                    <Icon icon="time" />
                                </Tooltip>
                            )}>
                        </ShowHide>
                        
                    </div>];
            })
        }

        setTableRows(data);
    }, [rawTableData]);

    function onTableSort(header, direction) {
        setTableSortDir(direction);
        setTableSortBy(header);
    }

    function onSearchChange(item) {
        setSearchTerm(item.target.value);
    }

    function onPagingChange(newPageNumber, newPageSize) {
        setPageNumber(newPageNumber);
        setPageSize(newPageSize);
    }

    function deleteExternalContact(externalContactId) {
        setSelectedExternalContactId(externalContactId);
        setShowDeleteConfirm(true);
    }

    function onDeleteConfirm() {
        setShowDeleteConfirm(false);
        setLoadingData(true);
        ExternalContactService.deleteExternalContact(selectedExternalContactId).then(() => {
            NotificationToaster.show(Intent.SUCCESS, "External contact has been successfully deleted.");
            fetchTableData(true);
            setSelectedExternalContactId(0);
        }, function () {
            NotificationToaster.show(Intent.DANGER, "Unable to delete the external contact, please try again.");
            setSelectedExternalContactId(0);
        });
    }

    function onToggleFilter() {
        setShowFilters((prevState) => {
            return !prevState;
        })
    }

    function onFilterChange(listingFilters) {
        setPageNumber(1);
        setAppliedFilters(listingFilters);
        setNoDataMessage("No results found, please try a different filter.");

        if (listingFilters.length > appliedFilters.length) {
            setShowFilters(true);
        }
    }

    function onDeleteCancel() {
        setSelectedExternalContactId(0);
        setShowDeleteConfirm(false);
    }

    return (
        <UserAccess perform={requiredAction}
            yes={() => (
                <div className="row">
                    <Breadcrumbs items={props.breadcrumbs} />

                    <h1>External Contact Management</h1>
                    <div className="intro-text">
                        <p>From here you can manage your external contacts</p>
                    </div>

                    <div className="button-row button-row-stacked-mobile spacer-bottom">
                        <div>
                        <ShowHide
                            evaluator={allowAdd}
                            show={(
                                <LinkButton intent="primary" text="Add new external contacts" href="/external-contact/create" id="new-external-contact" />
                            )}
                        >

                        </ ShowHide>
                        </div>
                    </div>


                    <div className={classNames("pull-left", { "spacer-bottom": !showFilters })}>
                    <div className="inline-items">
                            <FormTextInput inputRef={inputSearchRef} placeholder="External Contact Search" onChange={onSearchChange} value={searchTerm} large disabled={loadingData} icon="search" id="user-search-field" />
                            <FormLocationSelector
                                businessArea={""}
                                loading={loadingData}
                                selectedLocations={selectedLocations}
                                setSelectedLocations={setSelectedLocations}
                                useHierarchy={useHierarchy}
                                setUseHierarchy={setUseHierarchy}
                                useLocationDefaults={false}
                                onLocationListClose={onLocationListClose}
                                onLocationListOpened={onLocationListOpened}
                                customLocations={locationList}
                                useCustomLocations={true}
                            />
                            <Tooltip content="Filter" position="right">
                                <Button icon="filter" onClick={onToggleFilter} className={classNames({ "active": appliedFilters.length > 0 })} />
                            </Tooltip>
                        </div>
                    </div>
                    <Filter filterName="externalContacts" visible={showFilters} filters={pageFilters} onUpdate={onFilterChange} />
        

                    <ListingTable
                        id="listing-table-external-contact"
                        headers={tableHeaders}
                        loadingData={loadingData}
                        noDataMessage={noDataMessage}
                        data={tableRows}
                        totalRecordCount={totalRecords}
                        onPagingChange={onPagingChange}
                        sortedBy={tableSortBy}
                        sortedDir={tableSortDir}
                        onSort={onTableSort}
                        sortable
                        sortableHeaders={sortableHeaders}
                    />

                    <AlertConfirm
                        title="Please confirm"
                        onConfirm={onDeleteConfirm}
                        onCancel={onDeleteCancel}
                        isOpen={showDeleteConfirm}
                    >
                        <p>Are you sure you want to delete this external contact?</p>
                    </AlertConfirm>
                </div>
            )}
            no={() => (
                <PageRestricted />
            )}
        />
    );
}
import React, { Fragment, useState, useEffect, useRef } from 'react';
import { FormDateRangeInput } from "components/form-fields";
import { ListingTable } from "components/listing";
import { ShowHide } from "components/layout";
import { DateToLocal } from "components/dates";
import PropTypes from 'prop-types';
import moment from "moment";
import { useSelector, useDispatch } from 'react-redux';
import { SetFiltering } from 'state/actions';

const rows25 = 25;
const rows50 = 50;
const rows100 = 100;

const perPageOptions = [rows25, rows50, rows100];

export function AuditingTable(props) {

    const { onChange } = props;
    const reduxAuditingHistoryName = "auditingDates";
    const dispatch = useDispatch();

    const reduxSelectedDates = useSelector(state => state.filters[reduxAuditingHistoryName]);

    const [tableOptions, setTableOptions] = useState({
        startDate: moment().format("YYYY-MM-DD"),
        endDate: moment().format("YYYY-MM-DD"),
        pageNumber: 1,
        pageSize: props.pageSize
    });
    const optionsRef = useRef(tableOptions);
    const onChangeRef = useRef(onChange);

    const [loadingData, setLoadingData] = useState(true);
    const [tableRows, setTableRows] = useState([]);
    const [noDataMessage, setNoDataMessage] = useState(props.noDataMessage);

    useEffect(() => {
        if (reduxSelectedDates?.startDate != null) {
            tableOptions.startDate = reduxSelectedDates?.startDate;
            tableOptions.endDate = reduxSelectedDates?.endDate;
        }
    }, [reduxSelectedDates]);

    useEffect(() => {
        if (loadingData) {
            onChangeRef.current(optionsRef.current.pageSize, optionsRef.current.pageNumber, optionsRef.current.startDate, optionsRef.current.endDate);
        }

    }, [loadingData]);

    useEffect(() => {

        if (props.data != null) {

            let data = [];

            if (!props.dataIsFormatted) {
                data = props.data.map((d) => {
                    return [<DateToLocal key={`date-${d.date}`}>{d.date}</DateToLocal>, d.username, d.propertyFriendlyName,
                        (d.dataType === 'dateTime') ? <DateToLocal inputFormat={"DD/MM/YYYY HH:mm"}>{d.oldValue}</DateToLocal> : d.oldValue,
                        (d.dataType === 'dateTime') ? <DateToLocal inputFormat={"DD/MM/YYYY HH:mm"}>{d.newValue}</DateToLocal> : d.newValue,

                    ];
                });
            } else {
                data = props.data;
            }

            setTableRows(data);
            setLoadingData(false);
        }

    }, [props.data]);


    function onDateRangeChange(item) {

        if ((item.startDate.date != null && item.endDate.date != null) || (item.startDate.date == null && item.endDate.date == null)) {

            setNoDataMessage("No results found, please try a different date range.");
            optionsRef.current = {
                ...tableOptions,
                startDate: (item.startDate.date ? item.startDate.date.format("YYYY-MM-DD") : ""),
                endDate: (item.endDate.date ? item.endDate.date.format("YYYY-MM-DD") : ""),
                pageNumber: 1
            };
            setTableOptions(optionsRef.current);

            dispatch(SetFiltering(reduxAuditingHistoryName,
                {
                    startDate: optionsRef.current.startDate,
                    endDate: optionsRef.current.endDate
                }
            ));

            setLoadingData(true);
        }
    }

    function onPagingChange(newPageNumber, newPageSize) {
        optionsRef.current = {
            ...tableOptions,
            pageSize: newPageSize,
            pageNumber: newPageNumber
        };
        setTableOptions(optionsRef.current);

        setLoadingData(true);
    }

    return (
        <div className="row">
            <ShowHide
                evaluator={props.hideHeader}
                hide={<h2>History</h2>}
            >
            </ShowHide>

            <ShowHide
                evaluator={tableRows.length === 0 && !loadingData && tableOptions.startDate === "" && tableOptions.endDate === ""}
                hide={(
                    <Fragment>
                        <div className="pull-left listing-table-filter-fields">
                            <FormDateRangeInput
                                onChange={onDateRangeChange}
                                startDate={tableOptions.startDate}
                                endDate={tableOptions.endDate}
                                disabled={loadingData || props.loading}
                            ></FormDateRangeInput>
                        </div>
                    </Fragment>
                )}
            >
            </ShowHide>

            <ListingTable
                headers={props.tableHeaders}
                data={tableRows}
                totalRecordCount={props.totalCount}
                onPagingChange={onPagingChange}
                loadingData={loadingData || props.loading}
                noDataMessage={noDataMessage}
                pageSize={props.pageSize}
            >
            </ListingTable>

        </div>
    );
}

AuditingTable.defaultProps = {
    data: [],
    totalCount: 0,
    noDataMessage: "",
    dataIsFormatted: false,
    tableHeaders: ["Date", "User", "Setting", "Old Value", "New Value"],
    hideHeader: false,
    loading: false,
    pageSize: 25
};

AuditingTable.propTypes = {
    data: PropTypes.array,
    totalCount: PropTypes.number.isRequired,
    onChange: PropTypes.func.isRequired,
    noDataMessage: PropTypes.string,
    dataIsFormatted: PropTypes.bool,
    tableHeaders: PropTypes.array,
    hideHeader: PropTypes.bool,
    loading: PropTypes.bool,
    pageSize: PropTypes.oneOf(perPageOptions)
};
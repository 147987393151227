import React, { Fragment, useEffect, useState } from 'react';
import { FormDateInput, FormRadio, FormSelect, FormNumericInput, FormDatePeriodSelector, FormHelperText } from 'components/form-fields';
import moment from "moment";
import { ShowHide } from 'components/layout';
import { useValidation } from "hooks/useValidation";
import PropTypes from 'prop-types';
import classNames from 'classnames';

export function ReoccurrenceSettings(props) {

    const reoccurrenceRepeatOptionList = [
        { id: "day", name: "Day(s)" },
        { id: "week", name: "Week(s)" },
        { id: "month", name: "Month(s)" },
        { id: "year", name: "Year(s)" },
      ];

    const [isValid, errors, validate] = useValidation();
    const [reoccurrenceRepeatOptions, setReoccurrenceRepeatOptions] = useState(reoccurrenceRepeatOptionList);

    const key445 = '445';
    const minFrequencyPeriod = 1;
    const maxFrequencyPeriod = 9999;
    const defaultEndDateYears = 2;
    const reoccurrenceFrequencyDefaults = {
        frequency: 1,
        frequencyType: "month"
    };

    useEffect(() => {
        let values = reoccurrenceRepeatOptionList;

        if (!props.allowWeeklyRepeating) {
            values = reoccurrenceRepeatOptionList.filter(a => a.id !== 'week');
        }

        setReoccurrenceRepeatOptions(values);

    }, [props.allowWeeklyRepeating]);

    const reoccurrenceOptions = [
        {
            value: "repeat", label: "Repeat every", onSelectRender: (repeatEveryFields())
        },
        {
            value: key445, label: "Every 4-4-5 period", onSelectRender: (
                <Fragment>
                    <p className="spacer para-text">When 4-4-5 is selected, by default we'll run the nearest period to your schedule start date. If you would like to add a delay to your 4-4-5 period you can do so by moving the dates back and we'll run the schedule with the delay each time the schedule is run. Please be aware if data has not been uploaded to Vision prior to the schedule running reports, these will be blank.</p>
                    <FormDatePeriodSelector startDate={props.startDate} periodType={key445} periodsToShow={1} initialPeriodOffset={props.reoccurrenceSettings.cycle != null ? props.reoccurrenceSettings.cycle.frequencyPeriodOffset : -1} maxPeriods={-1} minPeriods={-12} onChange={onFrequencyPeriodStartChange} disabled={props.disabled} />

                </Fragment>
            )
        }
    ];

    function repeatEveryFields(header) {
        return (
            <div className={classNames('form-row field-combination', { 'outer-validation': !!errors["cycle.frequency"] })}>
                <h4>{header}</h4>
                <FormHelperText loading={props.loading}>{props.reoccurrenceFrequencyHelperText}</FormHelperText>
                <FormNumericInput
                    disabled={props.disabled}
                    min={minFrequencyPeriod}
                    max={maxFrequencyPeriod}
                    selectedValue={props.reoccurrenceSettings.cycle?.frequency}
                    onValueChange={onReoccurrenceFrequencyChange}
                    loading={props.loading}
                ></FormNumericInput>
                <FormSelect
                    disabled={props.disabled}
                    items={reoccurrenceRepeatOptions}
                    onItemSelect={onReoccurrenceFrequencyTypeChange}
                    selectedValue={props.reoccurrenceSettings.cycle?.frequencyType}
                    loading={props.loading}
                ></FormSelect>
                <FormHelperText loading={props.loading} danger={true}>{errors["cycle.frequency"]}</FormHelperText>
            </div>
        ); 
    }
   
    const endOptions = [
        { value: "never", label: "Never" },
        {
            value: "date", label: "On", onSelectRender: (
                <FormDateInput
                    id="reoccurrence-end-date"
                    onChange={onReoccurrenceEndDateChange}
                    value={props.reoccurrenceSettings.cycle?.endDate}
                    loading={props.loading}
                    minDate={props.startDate ? moment(props.startDate, "YYYY-MM-DD HH:mm").toDate() : null}
                    dangerHelperText={errors["cycle.endDate"]}
                    disabled={props.disabled}
                />
            )
        },
        {
            value: "occurrence", label: "After number of reoccurrences", onSelectRender: (
                <FormNumericInput
                    disabled={props.disabled}
                    min={minFrequencyPeriod}
                    max={maxFrequencyPeriod}
                    selectedValue={props.reoccurrenceSettings.cycle?.endFrequency}
                    onValueChange={onReoccurrenceEndFrequencyChange}
                    loading={props.loading}
                    dangerHelperText={errors["cycle.endFrequency"]}
                ></FormNumericInput>
            )
        }
    ];

    useEffect(onValidationChange, [isValid])

    function onValidationChange() {
        props.onValidationUpdate(isValid);
    }

    useEffect(updateValidationRules, [props.reoccurrenceSettings]);

    function updateValidationRules() {
        const rules = [
            { fieldName: "reoccurring", required: true }
        ];

        const externalRules = [];

        if (props.reoccurrenceSettings.reoccurring) {
            if (!props.hide445) {
                rules.push({ fieldName: "cycle.type", required: true });
            }
            if (!props.hideEndDates) {
                rules.push({ fieldName: "cycle.endType", required: true });
    
                if (props.reoccurrenceSettings.cycle.endType === "occurrence") {
                    rules.push({ fieldName: "cycle.endFrequency", required: true, minValue: minFrequencyPeriod, maxValue: maxFrequencyPeriod });
                }
    
                if (props.reoccurrenceSettings.cycle.endType === "date") {
                    rules.push({ fieldName: "cycle.endDate", required: true });
                }
            }

            if (props.reoccurrenceSettings.cycle.type === "repeat" || props.hide445) {
                rules.push({ fieldName: "cycle.frequency", required: true, type: "number", minValue: minFrequencyPeriod, maxValue: maxFrequencyPeriod });
            }
        }

        validate(rules, props.reoccurrenceSettings, externalRules);
    }

    function onStartDateChange() {
        //If the on end date is before the start date, then shift the end date on.
        if (props.reoccurrenceSettings && props.reoccurrenceSettings.cycle && props.reoccurrenceSettings.cycle.endDate && moment(props.reoccurrenceSettings.cycle.endDate).isBefore(props.startDate)) {
            onReoccurrenceEndDateChange(moment(props.startDate).add(defaultEndDateYears, "Y").format("YYYY-MM-DD"));
        }
    }

    useEffect(onStartDateChange, [props.startDate]);

    function onReoccurrenceChange(item) {
        const reoccurrence = {
            ...props.reoccurrenceSettings,
            reoccurring: item.currentTarget.value === "true"
        };

        if (item && (!reoccurrence.cycle || !reoccurrence.cycle.frequency)) {
            reoccurrence.cycle = {
                type: 'repeat',
                frequency: 1,
                frequencyType: 'day',
                endType: 'never'
            };
        }

        props.onReoccurrenceUpdate(reoccurrence);
    }

    function onReoccurrencePatternChange(item) {

        const frequencyDefaults = item.currentTarget.value === key445 ? { frequencyPeriodOffset: -1} : reoccurrenceFrequencyDefaults;

        const reoccurrence = {
            ...props.reoccurrenceSettings,
            cycle: {
                ...props.reoccurrenceSettings.cycle,
                ...frequencyDefaults,
                type: item.currentTarget.value,
            }
        }

        props.onReoccurrenceUpdate(reoccurrence);
    }

    function onReoccurrenceFrequencyChange(item) {

        const reoccurrence = {
            ...props.reoccurrenceSettings,
            cycle: {
                ...props.reoccurrenceSettings.cycle,
                frequency: item
            }
        }

        props.onReoccurrenceUpdate(reoccurrence);
    }

    function onReoccurrenceFrequencyTypeChange(item) {

        const reoccurrence = {
            ...props.reoccurrenceSettings,
            cycle: {
                ...props.reoccurrenceSettings.cycle,
                frequencyType: item.id
            }
        }

        props.onReoccurrenceUpdate(reoccurrence);
    }

    function onReoccurrenceEndChange(item) {
        
        const occurrenceCycle = {
            ...props.reoccurrenceSettings.cycle,
            endType: item.currentTarget.value
        };

        if (item.currentTarget.value === "date" && occurrenceCycle.endDate == null) {
            occurrenceCycle.endDate = moment(props.startDate).add(defaultEndDateYears,"Y").format("YYYY-MM-DD");
        }

        if (item.currentTarget.value === "occurrence" && occurrenceCycle.endFrequency == null) {
            occurrenceCycle.endFrequency = 10;
        }

        const reoccurrence = {
            ...props.reoccurrenceSettings,
            cycle: occurrenceCycle
        }

        props.onReoccurrenceUpdate(reoccurrence);
    }

    function onReoccurrenceEndFrequencyChange(item) {

        const reoccurrence = {
            ...props.reoccurrenceSettings,
            cycle: {
                ...props.reoccurrenceSettings.cycle,
                endFrequency: item
            }
        }

        props.onReoccurrenceUpdate(reoccurrence);
    }

    function onReoccurrenceEndDateChange(item) {

        const reoccurrence = {
            ...props.reoccurrenceSettings,
            cycle: {
                ...props.reoccurrenceSettings.cycle,
                endDate: item ? moment(item).format("YYYY-MM-DD") : null
            }
        }

        props.onReoccurrenceUpdate(reoccurrence);
    }

    function onFrequencyPeriodStartChange(item, endDate, offset) {

        const reoccurrence = {
            ...props.reoccurrenceSettings,
            cycle: {
                ...props.reoccurrenceSettings.cycle,
                frequencyPeriodStart: item ? moment(item, "DD/MM/YYYY", null).format("YYYY-MM-DD") : null,
                frequencyPeriodEnd: endDate ? moment(endDate, "DD/MM/YYYY", null).format("YYYY-MM-DD") : null,
                frequencyPeriodOffset: offset
            }
        }

        props.onReoccurrenceUpdate(reoccurrence);
    }

    return (
        <div>

            <FormRadio
                id="reoccurrence"
                onChange={onReoccurrenceChange}
                headingText={!!props.reoccurrenceToggleText ? 
                    props.reoccurrenceToggleText :
                    `Would you like your ${props.name} to reoccur?`}
                selectedValue={props.reoccurrenceSettings.reoccurring}
                loading={props.loading}
                disabled={props.disabled}
            ></FormRadio>

            <ShowHide
                evaluator={props.reoccurrenceSettings.reoccurring}
                show={(
                    <Fragment>
                        <ShowHide 
                            evaluator={!props.hide445}
                            show={(
                                <FormRadio
                                    id="reoccurence-pattern"
                                    onChange={onReoccurrencePatternChange}
                                    headingText= {`How often should this ${props.name} reoccur?`}
                                    selectedValue={props.reoccurrenceSettings.cycle?.type}
                                    options={reoccurrenceOptions}
                                    loading={props.loading}
                                    dangerHelperText={errors["cycle.type"]}
                                    disabled={props.disabled}
                                ></FormRadio>
                            )}
                        />

                        <ShowHide
                            evaluator={props.hide445}
                            show={repeatEveryFields('Repeat every')}
                        />

                        <ShowHide
                            evaluator={!props.hideEndDates}
                            show={(
                                <FormRadio
                                    id="reoccurence-end"
                                    onChange={onReoccurrenceEndChange}
                                    headingText={`When should this ${props.name} end?`}
                                    selectedValue={props.reoccurrenceSettings.cycle?.endType}
                                    options={endOptions}
                                    loading={props.loading}
                                    dangerHelperText={errors["cycle.endType"]}
                                    disabled={props.disabled}
                                ></FormRadio>
                            )}
                        />
                    </Fragment>
                )}
            />
        </div>
    );
}

ReoccurrenceSettings.defaultProps = {
    hide445: false,
    hideEndDates: false,
    startDate: null,
    loading: false,
    disabled: false,
    reoccurrenceSettings: {
        reoccurring: false,
        cycle: {
            type: 'repeat',
            frequency: 1,
            frequencyType: 'day',
            frequencyPeriodStart: null,
            frequencyPeriodOffset: -1,
            endType: 'never',
            endDate: null,
            endFrequency: 1
        }
    },
    name: 'schedule',
    allowWeeklyRepeating: false
};

ReoccurrenceSettings.propTypes = {
    hide445: PropTypes.bool,
    hideEndDates: PropTypes.bool,
    startDate: PropTypes.string,
    loading: PropTypes.bool,
    disabled: PropTypes.bool,
    reoccurrenceSettings: PropTypes.object.isRequired,
    name: PropTypes.string,
    reoccurrenceToggleText: PropTypes.string,
    reoccurrenceFrequencyHelperText: PropTypes.string,
    allowWeeklyRepeating: PropTypes.bool
};

import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { FormSwitchList } from 'components/form-fields';
import { NotificationInline } from "components/notifications";
import { LocationService } from 'services';

var _ = require('underscore');

export function HierarchyBusinessAreas(props) {

    const [loading, setLoading] = useState(true);
    const [businessAreas, setBusinessAreas] = useState([]);
    const [mappedBusinessAreas, setMappedBusinessAreas] = useState([]);
    const [lockedFeatures, setLockedFeatures] = useState(false);

    function onToggleChange(e) {

        var newAreas = [
            ...props.hierarchy.businessAreas
        ];

        if (e.target.checked) {
            var match = _.find(businessAreas, function (item) {
                return item.locationBusinessAreaId.toString() === e.currentTarget.getAttribute('identifier');
            });

            newAreas.push(match);
        } else {
            newAreas = newAreas.filter(function (area) {
                return area.locationBusinessAreaId.toString() !== e.currentTarget.getAttribute('identifier');
            });
        }

        props.onHierarchyUpdate({
            ...props.hierarchy,
            businessAreas: newAreas
        });

    }

    useEffect(function () {

        if (mappedBusinessAreas.length === 0) {
            return;
        }

        var newMappedAreas = [
            ...mappedBusinessAreas
        ];

        var showFeatureLockMessage = false;

        _.each(newMappedAreas, function (area) {

            var selectedArea = _.find(props.hierarchy.businessAreas, function (sa) {
                return sa.locationBusinessAreaId === area.locationBusinessAreaId
            });

            area.checked = selectedArea != null;
            area.disabled = selectedArea != null && selectedArea.disabled;

            if (area.disabled) {
                showFeatureLockMessage = true;
            }

        });

        setLockedFeatures(showFeatureLockMessage);

        setMappedBusinessAreas(newMappedAreas);
        props.onValidationUpdate(props.hierarchy.businessAreas.length > 0);

    }, [props.hierarchy, mappedBusinessAreas.length])

    useEffect(function () {

        LocationService.getLocationBusinessAreas().then(function (response) {

            var mappedToggles = response.map(function (area) {
                return {
                    ...area,
                    id: area.locationBusinessAreaId,
                    name: area.businessArea,
                    checked: false,
                    disabled: false
                }
            });

            setBusinessAreas(response);
            setMappedBusinessAreas(mappedToggles);
            setLoading(false);
        });

    }, []);

    return (
        <div>

            <NotificationInline
                show={lockedFeatures}
                text="Some business areas cannot be switched off because the hierarchy uses a feature or has locations of that business area type"
                intent="info" />

            <FormSwitchList
                id="location-hierarchy-business-areas"
                items={mappedBusinessAreas}
                onChange={onToggleChange}
                headingText="Please select the business areas you want this hierarchy to be associated with:"
                loading={loading}
                switchListName="businessAreasList"
                dangerHelperText={!loading && props.hierarchy.businessAreas.length === 0 ? "At least one business area must be switched on" : ""}
            >
            </FormSwitchList>
        </div>
    );
}

HierarchyBusinessAreas.defaultProps = {
    disabled: false
};

HierarchyBusinessAreas.propTypes = {
    disabled: PropTypes.bool
};
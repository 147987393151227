import React, { useState, useEffect, Fragment } from 'react';
import { PageRestricted } from 'pages/errors/page-restricted';
import { UserAccess, UserHasAccess } from 'components/user-access';
import { AssetService } from 'services';
import { FormSelect } from 'components/form-fields';
import { AlertUnsavedData, NotificationToaster } from 'components/notifications';
import { ButtonSave } from 'components/buttons';
import { useDirtyData } from 'hooks/useDirtyData';
import { Intent } from '@blueprintjs/core';
import { ShowHide } from 'components/layout';

export function SafetyFeatures(props) {
    const requiredAction = ["Fleet:View", "FleetPCN:View", "FleetVIS:View"];
    const requiredEditActions = ["Fleet:Edit", "FleetPCN:Edit", "FleetVIS:Edit"];
    const canAccessPage = UserHasAccess(requiredAction);
    const canEditSafetyFeatures = UserHasAccess(requiredEditActions);
    
    const yesNoOptions = [
        { id: null, name: "Unknown or N/A" },
        { id: true, name: "Yes" },
        { id: false, name: "No" }
    ];
    
    const assetSafetyFeaturesModel = {
        reversingCamera: null,
        dashboardCamera: null,
        driverCamera: null,
        reversingAudibleAlert: null,
        frontSensors: null,
        rearSensors: null,
        heightIndicator: null,
        underruns: null,
        wheelNutIndicator: null,
        vehicleTracker: null
    };

    const [originalAssetSafetyFeatures, setOriginalAssetSafetyFeatures] = useState(assetSafetyFeaturesModel);
    const [assetSafetyFeatures, setAssetSafetyFeatures] = useState(assetSafetyFeaturesModel);
    const [loading, setLoading] = useState(true);
    const [saving, setSaving] = useState(false);
    const isDirty = useDirtyData(originalAssetSafetyFeatures, assetSafetyFeatures);

    useEffect(onInit, [props.assetId]);

    function onInit() {
        if (!canAccessPage || props.assetId == null) {
            return;
        }

        AssetService.getSafetyFeatures(props.assetId).then(function (data) {
            setAssetSafetyFeatures(data);
            setOriginalAssetSafetyFeatures(data);
            setLoading(false);
        }, function () {
            NotificationToaster.show(Intent.DANGER, "Unable to load safety features. Please try again")
        });
    }

    function onSafetyFeatureChange(field, value) {
        setAssetSafetyFeatures((prev) => {
            var newInstance = {
                ...prev
            };
            newInstance[field] = value;
            return newInstance;
        });
    }

    function saveSafetyFeatures() {
        if (!canEditSafetyFeatures) {
            return;
        }

        setSaving(true);

        AssetService.updateAssetSafetyFeatures(props.assetId, assetSafetyFeatures).then(
            () => {
                setSaving(false);
                setOriginalAssetSafetyFeatures(assetSafetyFeatures);
                NotificationToaster.show(Intent.SUCCESS, "Asset safety features have been updated successfully")
            },
            (error) => {
                setSaving(false);
                NotificationToaster.show(Intent.DANGER, error); 
            }
        );
    }

    return (
        <UserAccess perform={requiredAction}
            yes={() => (
                <div className="row">

                    <h1>Safety Features</h1>

                    <FormSelect
                        id="field-reversing-camera"
                        disabled={saving || !canEditSafetyFeatures}
                        items={yesNoOptions}
                        onItemSelect={(item) => onSafetyFeatureChange("reversingCamera", item.id)}
                        placeholder="Select a value"
                        headingText="Is the asset fitted with a reversing camera?"
                        selectedValue={assetSafetyFeatures.reversingCamera}
                        loading={loading}
                    ></FormSelect>

                    <FormSelect
                        id="field-dashboard-camera"
                        disabled={saving || !canEditSafetyFeatures}
                        items={yesNoOptions}
                        onItemSelect={(item) => onSafetyFeatureChange("dashboardCamera", item.id)}
                        placeholder="Select a value"
                        headingText="Is the asset fitted with a dashboard camera?"
                        selectedValue={assetSafetyFeatures.dashboardCamera}
                        loading={loading}
                    ></FormSelect>

                    <FormSelect
                        id="field-driver-camera"
                        disabled={saving || !canEditSafetyFeatures}
                        items={yesNoOptions}
                        onItemSelect={(item) => onSafetyFeatureChange("driverCamera", item.id)}
                        placeholder="Select a value"
                        headingText="Is the asset fitted with a driver facing camera?"
                        selectedValue={assetSafetyFeatures.driverCamera}
                        loading={loading}
                    ></FormSelect>

                    <FormSelect
                        id="field-reversing-audible-alert"
                        disabled={saving || !canEditSafetyFeatures}
                        items={yesNoOptions}
                        onItemSelect={(item) => onSafetyFeatureChange("reversingAudibleAlert", item.id)}
                        placeholder="Select a value"
                        headingText="Is the asset fitted with an audible reversing alert device?"
                        selectedValue={assetSafetyFeatures.reversingAudibleAlert}
                        loading={loading}
                    ></FormSelect>

                    <FormSelect
                        id="field-front-sensors"
                        disabled={saving || !canEditSafetyFeatures}
                        items={yesNoOptions}
                        onItemSelect={(item) => onSafetyFeatureChange("frontSensors", item.id)}
                        placeholder="Select a value"
                        headingText="Is the asset fitted with front parking sensors?"
                        selectedValue={assetSafetyFeatures.frontSensors}
                        loading={loading}
                    ></FormSelect>

                    <FormSelect
                        id="field-rear-sensors"
                        disabled={saving || !canEditSafetyFeatures}
                        items={yesNoOptions}
                        onItemSelect={(item) => onSafetyFeatureChange("rearSensors", item.id)}
                        placeholder="Select a value"
                        headingText="Is the asset fitted with rear parking sensors?"
                        selectedValue={assetSafetyFeatures.rearSensors}
                        loading={loading}
                    ></FormSelect>

                    <FormSelect
                        id="field-height-indicator"
                        disabled={saving || !canEditSafetyFeatures}
                        items={yesNoOptions}
                        onItemSelect={(item) => onSafetyFeatureChange("heightIndicator", item.id)}
                        placeholder="Select a value"
                        headingText="Is the asset fitted with a height indicator?"
                        selectedValue={assetSafetyFeatures.heightIndicator}
                        loading={loading}
                    ></FormSelect>

                    <FormSelect
                        id="field-underruns"
                        disabled={saving || !canEditSafetyFeatures}
                        items={yesNoOptions}
                        onItemSelect={(item) => onSafetyFeatureChange("underruns", item.id)}
                        placeholder="Select a value"
                        headingText="Is the asset fitted with an underrun?"
                        selectedValue={assetSafetyFeatures.underruns}
                        loading={loading}
                    ></FormSelect>

                    <FormSelect
                        id="field-wheel-nut-indicator"
                        disabled={saving || !canEditSafetyFeatures}
                        items={yesNoOptions}
                        onItemSelect={(item) => onSafetyFeatureChange("wheelNutIndicator", item.id)}
                        placeholder="Select a value"
                        headingText="Is the asset fitted with a wheel nut indicator?"
                        selectedValue={assetSafetyFeatures.wheelNutIndicator}
                        loading={loading}
                    ></FormSelect>

                    <FormSelect
                        id="field-vehicle-tracker"
                        disabled={saving || !canEditSafetyFeatures}
                        items={yesNoOptions}
                        onItemSelect={(item) => onSafetyFeatureChange("vehicleTracker", item.id)}
                        placeholder="Select a value"
                        headingText="Is the asset fitted with a vehicle tracker?"
                        selectedValue={assetSafetyFeatures.vehicleTracker}
                        loading={loading}
                    ></FormSelect>



                    <ShowHide
                        evaluator={canEditSafetyFeatures}
                        show={(
                            <Fragment>
                                <AlertUnsavedData
                                    isDirty={isDirty}>
                                </AlertUnsavedData>

                                <ButtonSave text="Save safety features" 
                                    disabled={saving} 
                                    loading={loading} 
                                    onClick={saveSafetyFeatures} 
                                />
                            </Fragment>
                        )}
                    />


                </div>
            )}
            no={() => (
                <PageRestricted />
            )}
        />
    );
}
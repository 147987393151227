import React, { Fragment, useEffect, useState } from "react";
import { Alignment, Intent, NonIdealState } from "@blueprintjs/core";

import { UserAccess, UserHasAccess } from "components/user-access";
import { Link, useParams } from "react-router-dom";
import { AssetService } from "services/AssetService";
import { Tooltip } from 'components/tooltip';
import { PageRestricted } from "pages/errors/page-restricted";
import { Collapsable, ShowHide } from "components/layout";
import { Breadcrumbs } from "components/navigation";
import { Modal, NotificationInline, NotificationToaster } from "components/notifications";
import { DataDisplayTable } from "components/data-display";
import { DateToLocal } from "components/dates";
import moment from "moment";
import { ImageViewer } from "components/notifications/ImageViewer";
import { Button, ButtonSave } from "components/buttons";
import { FormCheckbox, FormTextArea, FormTextInput, FormRadio, FormDateInput, FormSwitch } from "components/form-fields";
import { useSelector } from "react-redux";
var _ = require("underscore");

export function DefectReportingDetails(props) {

    const { id } = useParams();
    const requiredAction = "WalkaroundCheck:View";
    const canAccessPage = UserHasAccess(requiredAction);
    const [showImageModal, setShowImageModal] = useState(false);
    const [image, setImage] = useState("");
    const [headerSections, setHeaderSections] = useState([]);
    const [breadcrumbs, setBreadcrumbs] = useState([]);
    const [walkaroundCheck, setWalkaroundCheck] = useState({});
    const [signOffRequired, setSignOffRequired] = useState(false);
    const [hasDefects, setHasDefects] = useState(false);
    const [hasOutstandingDefects, setHasOutstandingDefects] = useState(false);
    const [showOnlyDefectSections, setShowOnlyDefectSections] = useState(false);
    const [answers, setAnswers] = useState([{ answerId: 1, question: "Dummy data", pictures: [] }, { answerId: 2, question: "Dummy data", pictures: [] }, { answerId: 3, question: "Dummy data", pictures: [] }]);
    const [loadingData, setLoadingData] = useState(true);
    const [permissionDenied, setPermissionDenied] = useState(false);
    const [savingDefect, setSavingDefect] = useState(false);
    const [signOffLoading, setSignOffLoading] = useState(true);
    const [signOffDialogOpen, setSignOffDialogOpen] = useState(false);
    const route = useSelector(state => state.route.route);
    const title = useSelector(state => state.route.title);
    const routeId = useSelector(state => state.route.id);

    useEffect(() => {

        var clonedBreadcrumbs = [...props.breadcrumbs];

        if (routeId !== null && route !== null) {
            clonedBreadcrumbs[0].location = `/${route}/${routeId}/walkaround-checks`;
            clonedBreadcrumbs[0].title = title;
        } else {
            clonedBreadcrumbs[0] = { title: "Walkaround Checks", location: `/walkaround-checks` }
        }

        setBreadcrumbs(clonedBreadcrumbs);

    }, [route, routeId, title, walkaroundCheck.asset]);

    useEffect(() => {

        if (!canAccessPage) {
            return;
        }

        loadWalkaroundCheck();

    }, [id]);

    function loadWalkaroundCheck() {
        setLoadingData(true);
        AssetService.getWalkaroundCheck(id).then((response) => {
            setWalkaroundCheck(response);

            var defectsPresent = _.some(response.answers, function (answer) {
                return answer.hasDefect === true;
            });

            setShowOnlyDefectSections(defectsPresent);
            setHasDefects(defectsPresent);
            setSignOffRequired(!response.reportSignOffDate && defectsPresent);
            setHasOutstandingDefects(_.some(response.answers, function (answer) {
                return answer.hasDefect && _.some(answer.defects, function (defect) {
                    return defect.signedOffDate == null;
                });
            }));

            setAnswers(response.answers.map(function (answer) {
                return {
                    ...answer,
                    expanded: answer.hasDefect,
                };
            }));
            var headers = [
                {
                    header: "Vehicle Registration",
                    value: response.asset?.assetId != null ? <Link to={`/asset/${response.asset.assetId}/walkaround-checks`}>{response.asset.assetRegistration}</Link> : response.asset.assetRegistration,
                    key: 'vehcile-reg'
                },
                {
                    header: "Start date",
                    value: (
                        <DateToLocal format="DD/MM/YYYY HH:mm">
                            {response.startDate}
                        </DateToLocal>
                    ),
                    key: 'start-date'
                },
                {
                    header: "Driver Name",
                    value: response.employee?.employeeId != null ? <Link to={`/employee/${response.employee.employeeId}/walkaround-checks`}>{response.employee.employeeFullName}</Link> : response.employee.employeeFullName,
                    key: 'driver-name'
                },
                {
                    header: "Walkaround Duration",
                    value: setUpDuration(response.startDate, response.endDate),
                    key: 'duration'
                },
            ];

            setHeaderSections(headers);
            setLoadingData(false);
        },
        (error) => {
            const noAccessCode = 403;

            if (error.status === noAccessCode) {
                setPermissionDenied(true);
            }
        });
    }

    function setUpDuration(startDate, endDate) {
        var startTime = moment.utc(startDate, "YYYY-MM-DD HH:mm:ss");
        var endTime = moment.utc(endDate, "YYYY-MM-DD HH:mm:ss");

        var duration = moment.duration(endTime.diff(startTime));
        var hours = parseInt(duration.asHours());
        var hourInMinutes = 60;
        var minutes = parseInt(duration.asMinutes()) - hours * hourInMinutes;

        return hours > 0 ? `${hours} hours and ${minutes} minutes` : `${minutes} minutes`;
    }

    function onCollapsableClick(index) {
        var clonedAnswers = [...answers];

        var selectedSection = clonedAnswers[index];
        selectedSection.expanded = !selectedSection.expanded;

        setAnswers(clonedAnswers);
    }

    function onDefectUpdate(questionIndex, defectIndex, field, value) {

        var clonedAnswers = [...answers];

        var clonedDefects = [
            ...clonedAnswers[questionIndex].defects
        ];

        var clonedDefect = {
            ...clonedDefects[defectIndex]
        };

        clonedDefect[field] = value;

        clonedDefects[defectIndex] = clonedDefect;

        var clonedAnswer = {
            ...clonedAnswers[questionIndex],
            defects: clonedDefects
        }

        clonedAnswers[questionIndex] = clonedAnswer
        setAnswers(clonedAnswers);
    }

    function displayDefectInfo(defect, questionIndex, defectIndex) {

        let images = defect.images.map(function (img) {
            return <img key={img.fileName} onClick={() => onShowImage(img)} className="inline-item list-table-clickable-header" height={100} src={generateImageUrl(img)} />;
        });

        var defectInfo = [
            {
                header: "Defect comment",
                value: defect.defectComment,
                key: 'comment'
            },
            {
                header: "Images",
                value: images.length > 0 ? images : "-",
                key: 'images'
            },
        ];

        if (defect.signedOffDate == null) {

            defectInfo.push(
                {
                    header: "Has the defect been rectified",
                    value: (
                        <FormRadio
                            disabled={savingDefect}
                            onChange={(item) => { onDefectUpdate(questionIndex, defectIndex, "rectified", item.currentTarget.value === "true") }}
                            selectedValue={defect.rectified}
                            loading={loadingData}
                        ></FormRadio>
                    ),
                    key: 'defect-is-rectified'
                },
               
            );

            if (defect.rectified === true) {
                defectInfo.push(
                    {
                        header: "Action taken to rectify the defect",
                        value: (
                            <FormTextArea
                                maxLength={255}
                                disabled={savingDefect}
                                loading={false}
                                onChange={(item) => { onDefectUpdate(questionIndex, defectIndex, "rectifiedComment", item.target.value) }}
                                value={defect.rectifiedComment}
                            />
                        ),
                        key: 'defect-action-taken'
                    },
                    {
                        header: "Maintenance Provider",
                        value: (
                            <FormTextInput
                                loading={false}
                                disabled={savingDefect}
                                onChange={(item) => { onDefectUpdate(questionIndex, defectIndex, "maintenanceProvider", item.target.value) }}
                                value={defect.maintenanceProvider}
                            />
                        ),
                        key: 'defect-maintenance-provider'
                    },
                    {
                        header: "Rectified By",
                        value: (
                            <FormTextInput
                                loading={false}
                                disabled={savingDefect}
                                onChange={(item) => { onDefectUpdate(questionIndex, defectIndex, "rectifiedBy", item.target.value) }}
                                value={defect.rectifiedBy}
                            />
                        ),
                        key: 'defect-rectified-by'
                    },
                    {
                        header: "Rectified Date",
                        value: (
                            <FormDateInput
                                onChange={(item) => { onDefectUpdate(questionIndex, defectIndex, "rectifiedDate", item ? moment(item).format("YYYY-MM-DD") : null) }}
                                value={defect.rectifiedDate}
                                disabled={savingDefect}
                                loading={false}
                                maxDate={moment().toDate()}
                            />
                        ),
                        key: 'defect-rectified-date'
                    }
                );
            }

            if (defect.rectified === false) {
                defectInfo.push(
                    {
                        header: "Reason defect was not rectified",
                        value: (
                            <FormTextArea
                                maxLength={255}
                                loading={false}
                                disabled={savingDefect}
                                onChange={(item) => { onDefectUpdate(questionIndex, defectIndex, "rectifiedComment", item.target.value) }}
                                value={defect.rectifiedComment}
                            />
                        ),
                        key: 'defect-action-taken'
                    }
                );
            }
            

        } else {

            defectInfo.push(
                {
                    header: "Has the defect been rectified",
                    value: defect.rectified ? "Yes" : "No",
                    key: 'defect-is-rectified'
                },
                {
                    header: defect.rectified ? "Action taken to rectify the defect" : "Reason defect was not rectified",
                    value: defect.rectifiedComment,
                    key: 'defect-action-taken'
                }
            );

            if (defect.rectified) {
                defectInfo.push(
                    {
                        header: "Maintenance Provider",
                        value: defect.maintenanceProvider,
                        key: 'defect-maintenance-provider'
                    },
                    {
                        header: "Rectified By",
                        value: defect.rectifiedBy,
                        key: 'defect-rectified-by'
                    },
                    {
                        header: "Rectified Date",
                        value: (
                            <DateToLocal format="DD/MM/YYYY">
                                {defect.rectifiedDate}
                            </DateToLocal>
                        ),
                        key: 'defect-rectified-date'
                    }
                );
            }
        }

        

        return defectInfo;
    }

    function onHideImage() {
        setShowImageModal(false);
    }

    function signOff() {
        setSignOffLoading(true);

        AssetService.signOffWalkaroundCheck(id).then(
        () =>
            {
                NotificationToaster.show(Intent.SUCCESS, "You have successfully signed off the walkaround check.");
                setSignOffRequired(false);
                loadWalkaroundCheck();
                setSignOffDialogOpen(false);
            },
        () =>
            {
                NotificationToaster.show(Intent.DANGER, "Walkaround check could not be signed off. Please try again.");
                setSignOffDialogOpen(false);
            }
        );
    }

    function openSignOff() {
        setSignOffDialogOpen(true);
    }

    function onSignOffDialogClosed() {
        setSignOffDialogOpen(false);
        setSignOffLoading(false);
    }

    function onApprove(event) {
        setSignOffLoading(!event.target.checked);
    }

    function onShowImage(img) {
        setImage(generateImageUrl(img));
        setShowImageModal(true);
    }

    function generateImageUrl(img) {
        return `https://${img?.storageName}.blob.core.windows.net/${img.containerName}/${img?.fileName}?${img?.token}`;
    }

    function signOffNotification() {
        return (
            <div>
                <span>Report signed off by {walkaroundCheck.reportSignOffUser} on </span>  
                <DateToLocal format="DD/MM/YYYY HH:mm">
                    {walkaroundCheck.reportSignOffDate}
                </DateToLocal>
            </div>
        );
    }

    function isDefectSignoffValid(defect) {
        let minimumTextLength = 3;
        return !((defect.rectified == null) || (defect.rectified === true && (defect.rectifiedComment == null || defect.rectifiedComment.trim().length < minimumTextLength || defect.maintenanceProvider == null || defect.maintenanceProvider.trim().length < minimumTextLength || defect.rectifiedBy == null || defect.rectifiedBy.trim().length < minimumTextLength || defect.rectifiedDate == null)) || (defect.rectified === false && (defect.rectifiedComment == null || defect.rectifiedComment.trim().length < minimumTextLength)))
    }

    function onDefectViewChange() {
        setShowOnlyDefectSections((previous) => !previous);
    }

    function signoffDefect(answerIndex, defectIndex) {
        setSavingDefect(true);

        var defect = answers[answerIndex].defects[defectIndex];

        AssetService.signOffWalkaroundDefect(id, defect.defectId, defect).then(() => {
            NotificationToaster.show(Intent.SUCCESS, "Defect has been signed off");
            loadWalkaroundCheck();
        },
        () => {
            NotificationToaster.show(Intent.DANGER, "Defect could not be signed off. Please try again.");
        }).finally(() => {
            setSavingDefect(false);
        });
    }

    function displayWalkaroundAnswer(answer, index) {
        return (
            <ShowHide
                key={answer.questionId}
                evaluator={(showOnlyDefectSections && answer.hasDefect) || !showOnlyDefectSections}
                show={(
                    <Fragment>
                        <Collapsable
                            key={answer.questionId}
                            loading={loadingData}
                            title={`${index + 1}. ${answer.question}`}
                            fullWidth={true}
                            expanded={answer.expanded}
                            onClick={() => onCollapsableClick(index)}
                        >
                            <div className="spacer-bottom">
                                <ShowHide
                                    evaluator={answer.hasDefect === true}
                                    show={(
                                        <div>
                                            {answer.defects && answer.defects.map(function (defect, defectId) {
                                                return (
                                                    <Fragment key={defect.defectId}>
                                                        <div className="spacer-top">
                                                            <h3>Reported Defect {defectId + 1}</h3>

                                                            <div className="spacer-bottom padded" style={{ backgroundColor: '#F5F7F9' }}>
                                                                <DataDisplayTable
                                                                    data={displayDefectInfo(defect, index, defectId)}
                                                                    displayInRows={true}
                                                                    loading={loadingData}
                                                                />
                                                                <div className="padded">
                                                                    <ShowHide
                                                                        evaluator={defect.signedOffDate != null}
                                                                        show={(
                                                                            <NotificationInline
                                                                                allowClose={false}
                                                                                loading={loadingData}
                                                                                show
                                                                                text={(
                                                                                    <div>
                                                                                        <span>Defect signed off by {defect.signedOffBy} on </span>
                                                                                        <DateToLocal format="DD/MM/YYYY HH:mm">
                                                                                            {defect.signedOffDate}
                                                                                        </DateToLocal>
                                                                                    </div>
                                                                                )}
                                                                                intent="success"
                                                                            ></NotificationInline>
                                                                        )}
                                                                        hide={(
                                                                            <ButtonSave text="Sign off defect" icon="tick" disabled={savingDefect} simpleDisabled={!isDefectSignoffValid(defect)} onClick={() => { signoffDefect(index, defectId) }} />
                                                                        )}
                                                                    />

                                                                </div>
                                                            </div>
                                                        </div>
                                                    </Fragment>
                                                );


                                            })}
                                        </div>

                                    )}
                                    hide={(
                                        <div className="inline-items inline-items-center padded">
                                            <div className="inline-item">
                                                <NonIdealState
                                                    description="There were no defects reported for this section"
                                                />
                                            </div>
                                        </div>
                                    )}
                                />

                            </div>
                        </Collapsable>
                    </Fragment>
                )}
            />
        );
    }

    return (
        <UserAccess
            perform={requiredAction}
            yes={() => (
                <ShowHide
                    evaluator={permissionDenied}
                    show={
                        <div className="row">
                            <Breadcrumbs items={breadcrumbs} />
                            <NotificationInline
                                allowClose={false}
                                show
                                text="You do not have permission to view this walkaround check"
                                intent="danger"
                            ></NotificationInline>
                        </div>
                    }
                    hide={
                        <div className="row">
                            <Breadcrumbs items={breadcrumbs} />
                            <h1>{walkaroundCheck.template}</h1>

                            <UserAccess
                                perform={["WalkaroundCheck:SignOff"]}
                                yes={() => (
                                    <div className="spacer-bottom">
                                        <NotificationInline
                                            allowClose={false}
                                            loading={loadingData}
                                            show={signOffRequired}
                                            text={signOffRequired && hasOutstandingDefects ? "This walkaround check report still has defects to be signed off before the report can be signed off" : "This walkaround check report still requires sign off"}
                                            intent="warning"
                                        ></NotificationInline>
                                    </div>
                                )}
                            />

                            <DataDisplayTable
                                data={headerSections}
                                displayInRows={false}
                                loading={loadingData}
                            />

                            <NotificationInline
                                allowClose={false}
                                loading={loadingData}
                                show={walkaroundCheck.reportSignOffDate != null}
                                text={signOffNotification()}
                                intent="success"
                            ></NotificationInline>

                            
                            <ShowHide
                                evaluator={hasDefects}
                                show={
                                    <div>
                                        <FormSwitch id="filter-defects-only" onChange={onDefectViewChange} label="Only show sections that contain a defect" checked={showOnlyDefectSections} inline alignment={Alignment.RIGHT} loading={loadingData} />
                                    </div>
                                }
                            />
                           
                            <div>
                                {walkaroundCheck &&
                                    answers.map(displayWalkaroundAnswer)
                                }
                            </div>

                            <UserAccess
                                perform={["WalkaroundCheck:SignOff"]}
                                yes={() => (
                                    <ShowHide
                                        evaluator={signOffRequired}
                                        show={
                                            <div className="spacer-bottom">
                                                <Tooltip content={hasOutstandingDefects ? "All defects must be signed off before the report can be signed off" : ""}>
                                                    <div>
                                                        <Button
                                                            intent="primary"
                                                            text="Sign off report"
                                                            id="button-job-report-sign-off"
                                                            loading={loadingData}
                                                            onClick={openSignOff}
                                                            disabled={hasOutstandingDefects}
                                                        />
                                                    </div>
                                                </Tooltip>
                                                
                                            </div>
                                        }
                                    />
                                )}
                            />

                            <Modal
                                isOpen={signOffDialogOpen}
                                title="Report Sign Off"
                                onClose={onSignOffDialogClosed}
                            >
                                <div className="spacer-bottom">
                                    <FormCheckbox
                                        checked={!signOffLoading}
                                        label={(<Fragment><p>I consider that the defects have been rectified satisfactorily and this vehicle is now in a safe and roadworthy condition. </p><p><strong>Note:</strong> It is always the responsibility of the operator that the vehicle is in a roadworthy condition before being used on the road.</p></Fragment>)}
                                        id={id}
                                        onChange={onApprove}
                                    />
                                </div>

                                <div className="bp3-dialog-footer">
                                    <div className="bp3-dialog-footer-actions">
                                        <Button
                                            id="button-job-report-approve"
                                            text="Approve"
                                            intent="primary"
                                            disabled={signOffLoading}
                                            onClick={signOff}
                                        />
                                        <Button
                                            id="button-job-report-cancel-approve"
                                            text="Cancel"
                                            onClick={onSignOffDialogClosed}
                                        />
                                    </div>
                                </div>
                            </Modal>

                            <ImageViewer
                                isOpen={showImageModal}
                                onClose={onHideImage}
                                mediaSource={image}
                            />
                        </div>
                    }
                />
            )}
            no={() => <PageRestricted />}
        />
    );
}

import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import { FormTextInput, FormGroupedCheckboxItems, FormHelperText } from 'components/form-fields';
import { ShowHide } from "components/layout";
import { TagList } from 'components/status';
import { HelperFunctions } from 'helpers';

var _ = require('underscore');

export function FormGroupedCheckboxList(props) {

    const [searchTerm, setSearchTerm] = useState("");
    const [selectedItems, setSelectedItems] = useState([]);

    useEffect(initialLoad, [props.items]);

    function initialLoad() {

        setSelectedItems(_.chain(props.items).pluck('items').flatten().where({ checked: true }).uniq(function (i) {
            return i.id;
        }).value());

    }

    function onRemoveItem(event, id) {
        HelperFunctions.executeIfNotNull(props.onTagRemove, id);
    }

    function onSearchChange(item) {

        var newSearchTerm = item.target.value;

        setSearchTerm(newSearchTerm);
        props.onSearch(newSearchTerm);
    }

    return (
        <div className="form-field form-field-grouped-checkboxes">
            <ShowHide
                evaluator={props.headingText != null}
                show={(
                    <h4 className={classNames({ "bp3-skeleton": props.loading })}>{props.headingText}</h4>
                )}
            />

            <ShowHide
                evaluator={props.showTags}
                show={(
                    <TagList items={selectedItems} disabled={props.saving || props.disabled} onRemoveTag={onRemoveItem} />
                )}
            />
            <ShowHide
                evaluator={props.searchable}
                show={(
                    <div className="chechbox-list-search">
                        <FormTextInput placeholder="Search" onChange={onSearchChange} value={searchTerm} large disabled={props.saving || props.disabled} icon="search" loading={props.loading} />
                    </div>
                )}
            />
            <div className={classNames("grouped-checkbox-list-container", { "bp3-skeleton": props.loading })}>
                <div className="grouped-checkbox-list-scroll">
                    <FormGroupedCheckboxItems items={props.items} searchTerm={searchTerm} groupsSelectable={props.groupsSelectable} onGroupToggle={props.onGroupToggle} onItemSelect={props.onItemSelect} onGroupSelect={props.onGroupSelect} loading={props.loading} filter={props.filter} itemIcon={props.itemIcon} disabled={props.saving || props.disabled} />
                </div>
            </div>
            <FormHelperText loading={props.loading} danger={true}>{props.dangerHelperText}</FormHelperText>
        </div>
    );
}

FormGroupedCheckboxList.defaultProps = {
    searchable: false,
    showTags: false,
    groupsSelectable: false,
    onGroupSelect: () => { },
    onSearch: () => { },
    loading: false,
    saving: false,
    dangerHelperText: null,
    filter: null,
    itemIcon: null,
    onTagRemove: null,
    disabled: false
};

FormGroupedCheckboxList.propTypes = {
    items: PropTypes.array.isRequired,
    searchable: PropTypes.bool,
    showTags: PropTypes.bool,
    groupsSelectable: PropTypes.bool,
    onGroupToggle: PropTypes.func.isRequired,
    onItemSelect: PropTypes.func.isRequired,
    onGroupSelect: PropTypes.func,
    onSearch: PropTypes.func,
    loading: PropTypes.bool,
    saving: PropTypes.bool,
    dangerHelperText: PropTypes.node,
    filter: PropTypes.func,
    itemIcon: PropTypes.string,
    onTagRemove: PropTypes.func,
    disabled: PropTypes.bool
};

import React, { Fragment, useEffect, useState, useRef } from "react";
import PropTypes from 'prop-types';
import classNames from "classnames";
import { Link } from "react-router-dom";

import { UserAccess, UserHasAccess } from 'components/user-access';
import { Breadcrumbs } from 'components/navigation';
import { DateToLocal } from 'components/dates';
import { FormTextInput } from "components/form-fields";
import { PageRestricted } from 'pages/errors/page-restricted';
import { ListingTable } from "components/listing";
import { useDebounce } from 'hooks/useDebounce';
import { SelfReportingService } from 'services';
import { ShowHide } from "components/layout";
import { Filter } from 'components/filtering';
import { Tooltip } from 'components/tooltip';
import { Button } from 'components/buttons'
import { HelperFunctions } from "helpers";

import _ from 'underscore';

export function ScheduledReportsListing(props) {

    const defaultRecordSize = 25;
    const debounceTimeout = 750;

    const requiredAction = "ScheduleReports:View";

    const [initalSetupComplete, setInitalSetupComplete] = useState(false);
    const [totalRecords, setTotalRecords] = useState(0);
    const [rawTableData, setRawTableData] = useState([]);
    const [tableRows, setTableRows] = useState([]);
    const [pageNumber, setPageNumber] = useState(1);
    const [pageSize, setPageSize] = useState(defaultRecordSize);
    const [pageFilters, setPageFilters] = useState([]);
    const [searchTerm, setSearchTerm] = useState("");
    const [loadingData, setLoadingData] = useState(true);
    const [noDataMessage, setNoDataMessage] = useState("You have not received any scheduled reports");
    const tableHeaders = ["Name", "Run Date", "Number of Reports"];
    const sortableHeaders = ["Name", "Run Date"];
    const [tableSortBy, setTableSortBy] = useState("Run Date");
    const [tableSortDir, setTableSortDir] = useState("D");
    const [appliedFilters, setAppliedFilters] = useState([]);
    const [showFilters, setShowFilters] = useState(false);
    const debouncedSearchTerm = useDebounce(searchTerm, debounceTimeout);
    const canAccessPage = UserHasAccess(requiredAction);
    const inputSearchRef = useRef(null);

    const currentPageNumber = useRef();
    const currentPageSize = useRef();
    const currentSearchTerm = useRef();
    const currentTableSortBy = useRef();
    const currentTableSortDir = useRef();
    const currentAppliedFilters = useRef();

    useEffect(() => {
        SelfReportingService.getReportGroups().then(function (reportGroups) {

            var mappedReports = HelperFunctions.sortAlphabetically(
                _.chain(reportGroups).pluck("reports").flatten().uniq(function (r) {
                    return r.id
                }).value(), "name");

            var filterArray = [
                {
                    "name": "Reports", "items": [
                        ...mappedReports
                    ]
                }
            ];

            setPageFilters(filterArray);

        });
    }, []);

    useEffect(() => {
        fetchTableData(false);
    }, [pageSize, pageNumber, tableSortBy, tableSortDir, debouncedSearchTerm, appliedFilters.length, initalSetupComplete]);


    useEffect(() => {
        setPageNumber(1);
        setNoDataMessage("No results found, please try a different search term.");
    }, [debouncedSearchTerm]);

    function onPagingChange(newPageNumber, newPageSize) {
        setPageNumber(newPageNumber);
        setPageSize(newPageSize);
    }

    function onSearchChange(item) {
        setSearchTerm(item.target.value);
    }

    function onTableSort(header, direction) {
        setTableSortDir(direction);
        setTableSortBy(header);
    }

    function onToggleFilter() {
        setShowFilters((prevState) => {
            return !prevState;
        })
    }

    function fetchTableData(forceRefresh) {
        if (canAccessPage && initalSetupComplete) {

            if (
                currentAppliedFilters.current === appliedFilters.length &&
                currentTableSortDir.current === tableSortDir &&
                currentPageNumber.current === pageNumber &&
                currentPageSize.current === pageSize &&
                currentSearchTerm.current === debouncedSearchTerm &&
                currentTableSortBy.current === tableSortBy &&
                !forceRefresh
            ) {
                //If none of the values have changed, then 2 have been updated at once, so prevent the listing from performing another call.
                return;
            }

            currentAppliedFilters.current = appliedFilters.length;
            currentTableSortBy.current = tableSortBy;
            currentTableSortDir.current = tableSortDir;
            currentPageNumber.current = pageNumber;
            currentPageSize.current = pageSize;
            currentSearchTerm.current = debouncedSearchTerm;

            setLoadingData(true);
            SelfReportingService.getScheduleInstances(pageSize, pageNumber, debouncedSearchTerm, tableSortBy, tableSortDir, appliedFilters).then(function (r) {
                setRawTableData(r.data);
                setTotalRecords(r.totalCount);
                setLoadingData(false);

                setTimeout(function () {
                    if (inputSearchRef.current != null) {
                        inputSearchRef.current.focus();
                    }
                });

            });
        }
    }

    useEffect(() => {

        let data = [];

        if (rawTableData != null) {
            data = rawTableData.map((d) => {

                return [
                    <Link to={`/scheduled-reports/${d.scheduleInstanceId}`}>{d.scheduleInstanceName}</Link>,
                    <DateToLocal>{d.runDate}</DateToLocal>,
                    d.reportCount
                ];

            })
        }

        setTableRows(data);
    }, [rawTableData]);

    function onFilterChange(listingFilters) {
        setInitalSetupComplete(true);
        setPageNumber(1);
        setNoDataMessage("No results found, please try a different filter.");
        setAppliedFilters(listingFilters);

        if (listingFilters.length > appliedFilters.length) {
            setShowFilters(true);
        }
    }

    return (

        <UserAccess perform={requiredAction}
            yes={() => (
                <div className="row">
                    <Breadcrumbs items={props.breadcrumbs} />

                    <h1>Scheduled Reports</h1>

                    <ShowHide
                        evaluator={tableRows.length === 0 && !loadingData && debouncedSearchTerm === "" && appliedFilters.length === 0 && initalSetupComplete}
                        hide={(
                            <Fragment>
                                <div className={classNames("pull-left", { "spacer-bottom": !showFilters })}>
                                    <div className="inline-items">
                                        <FormTextInput inputRef={inputSearchRef} placeholder="Schedule Search" onChange={onSearchChange} value={searchTerm} large disabled={loadingData} icon="search" id="scheduled-reports-search-field" />
                                        <Tooltip content="Filter" position="right">
                                            <Button icon="filter" onClick={onToggleFilter} className={classNames({ "active": appliedFilters.length > 0 })} />
                                        </Tooltip>
                                    </div>
                                </div>
                                <Filter filterName="scheduledReports" visible={showFilters} filters={pageFilters} onUpdate={onFilterChange} />
                            </Fragment>
                        )}
                    />

                    <ListingTable
                        id="listing-table-schedule"
                        noDataMessage={noDataMessage}
                        sortable
                        sortableHeaders={sortableHeaders}
                        sortedBy={tableSortBy}
                        sortedDir={tableSortDir}
                        onSort={onTableSort}
                        headers={tableHeaders}
                        data={tableRows}
                        totalRecordCount={totalRecords}
                        onPagingChange={onPagingChange}
                        loadingData={loadingData}
                    />

                </div>
            )}
            no={() => (
                <PageRestricted />
            )}
        />

    );

}

ScheduledReportsListing.propTypes = {
    breadcrumbs: PropTypes.array.isRequired
};
import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { Button } from "components/buttons";
import { Spinner } from "@blueprintjs/core";
import { ShowHide } from 'components/layout';

import './Buttons.css';

export function ButtonSave(props) {
   
    return (
        <Fragment>
            <Button
                loading={props.loading}
                text={props.text}
                icon={props.icon}
                intent={props.intent}
                onClick={props.onClick}
                disabled={props.disabled || props.simpleDisabled}
                id={props.id}
                minimal={props.minimal}
                iconOnly={props.iconOnly}
                large={props.large}
            ></Button>
            <ShowHide
                evaluator={props.disabled}
                show={(
                    <div className="save-loader">
                        <Spinner size="25" />
                    </div>
                )}
            >
                    
            </ShowHide>
        </Fragment>
    );

}

ButtonSave.defaultProps = {
    id: null,
    loading: false,
    disabled: false,
    simpleDisabled: false,
    text: "Save",
    icon: "floppy-disk",
    intent: "save",
    minimal: false,
    iconOnly: false,
    large: true
};

ButtonSave.propTypes = {
    id: PropTypes.string,
    loading: PropTypes.bool,
    onClick: PropTypes.func.isRequired,
    disabled: PropTypes.bool,
    text: PropTypes.string,
    icon: PropTypes.string,
    simpleDisabled: PropTypes.bool,
    intent: PropTypes.string,
    minimal: PropTypes.bool,
    iconOnly: PropTypes.bool,
    large: PropTypes.bool
};
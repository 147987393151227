import React, { useState, useEffect, useRef, Fragment } from 'react';
import PropTypes from 'prop-types';

import { FormHelperText, FormCheckboxList, FormTextInput } from "components/form-fields";
import { useDebounce } from 'hooks/useDebounce';
import { ListingPagination } from 'components/listing';
import { ShowHide } from 'components/layout';
import { TagList } from 'components/status';
import { NonIdealState } from '@blueprintjs/core';
import classNames from 'classnames';

export function FormPagedCheckboxList(props) {
    const debounceTimeout = 750;

    const [searchTerm, setSearchTerm] = useState("");
    const [pageNumber, setPageNumber] = useState(1);
    const [totalPages, setTotalPages] = useState(1);
    const [items, setItems] = useState([]);

    const listSize = 50;
    const debouncedSearchTerm = useDebounce(searchTerm, debounceTimeout);
    const inputSearchRef = useRef(null);

    const dummyItems = [];
    const numberOfDummyItems = 10;

    for (let i = 0; i < numberOfDummyItems; i++) {
        dummyItems.push({ id: i, name: '', checked: false });
    }

    useEffect(() => {
        onPageChange(1);
    }, [debouncedSearchTerm]);

    useEffect(() => {
        const itemsClone = [...props.items];

        for (let item of itemsClone) {
            item.checked = props.selectedItems?.some(x => x.id === item.id);
        }

        setItems(itemsClone);
    }, [props.items, props.selectedItems, props.saving]);

    useEffect(() => {
        setTotalPages(Math.ceil(props.totalItemCount / listSize));
    }, [props.totalItemCount]);

    function onItemChange(event, id) {
        const isChecked = event?.target?.checked != null && event.target.checked;
        const item = items.find(x => x.id === id);
        props.onItemSelect(item, isChecked);
    }

    function onSearchChange(item) {
        setSearchTerm(item.target.value);
    }

    function onPageChange(newPage) {
        setPageNumber(newPage);
        props.onPageChange(listSize, newPage, debouncedSearchTerm);
    }

    function onRemoveTag(event, id) {
        const item = props.selectedItems.find(x => x.id === id);
        props.onItemSelect(item, false);
    }

    function sortItems(a, b) {
        var nameA = a.name.toUpperCase();
        var nameB = b.name.toUpperCase();
        if (nameA < nameB) {
            return -1;
        }
        if (nameA > nameB) {
            return 1;
        }

        // names must be equal
        return 0;

    }

    return (
        <div className='form-field'>
            <ShowHide
                evaluator={props.headingText != null}
                show={(
                    <h4 className={classNames({ "bp3-skeleton": props.loading })}>{props.headingText}</h4>
                )}
            />

            <TagList items={props.selectedItems.sort(sortItems)} disabled={props.saving || props.disabled} onRemoveTag={onRemoveTag} />

            <ShowHide
                evaluator={props.disabled}
                hide={(
                    <Fragment>
                        <div className="clearfix">
                            <div className="pull-left spacer-bottom">
                                <div className="inline-items">
                                    <FormTextInput inputRef={inputSearchRef} placeholder={props.placeholder} onChange={onSearchChange} value={searchTerm} large disabled={props.saving || props.disabled} loading={props.loading} icon="search" id="user-search-field" />
                                </div>
                            </div>
                        </div>

                        <ShowHide
                            evaluator={items.length > 0 || props.loadingPage}
                            show={(
                                <FormCheckboxList
                                    id={props.id}
                                    items={props.loadingPage && items.length === 0 ? dummyItems : items}
                                    onChange={onItemChange}
                                    loading={props.loadingPage || props.saving}
                                    striped={true}
                                ></FormCheckboxList>
                            )}
                            hide={(<NonIdealState
                                title={''}
                                description={props.noDataMessage}
                            />)}
                        />


                        <ShowHide
                            evaluator={totalPages > 1}
                            show={(
                                <ListingPagination currentPage={pageNumber} totalPages={totalPages} loadingData={false} onPageChange={onPageChange} />
                            )}
                        />
                        <FormHelperText danger={true}>{props.dangerHelperText}</FormHelperText>
                    </Fragment>
                )}
            />
        </div>
    );
}

FormPagedCheckboxList.defaultProps = {
    headingText: '',
    placeholder: 'Search items',
    id: 'paged-checkbox',
    disabled: false,
    loading: false,
    loadingPage: false,
    saving: false,
    noDataMessage: 'No results found, please try a different search term.',
    dangerHelperText: ''
};

FormPagedCheckboxList.propTypes = {
    onPageChange: PropTypes.func.isRequired,
    items: PropTypes.array.isRequired,
    selectedItems: PropTypes.array.isRequired,
    onItemSelect: PropTypes.func.isRequired,
    totalItemCount: PropTypes.number.isRequired,
    headingText: PropTypes.string,
    placeholder: PropTypes.string,
    id: PropTypes.string,
    disabled: PropTypes.bool,
    loading: PropTypes.bool,
    loadingPage: PropTypes.bool,
    saving: PropTypes.bool,
    noDataMessage: PropTypes.string,
    dangerHelperText: PropTypes.string
};
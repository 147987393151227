import React, { useState, useEffect, Fragment } from 'react';
import axios from 'axios';
import { Intent } from '@blueprintjs/core';
import { useSelector } from 'react-redux';

import { NotificationToaster } from "components/notifications";
import { ButtonSave } from 'components/buttons'
import { PageRestricted } from 'pages/errors/page-restricted';
import { UserAccess } from 'components/user-access';
import { AccountService } from 'services';
import { FormSwitchList } from 'components/form-fields';


export function AccessProviders() {

    const [loading, setLoading] = useState(true);
    const [saving, setSaving] = useState(false);
    const [providers, setProviders] = useState([]);
    const [featureList, setFeatureList] = useState([]);
    const memberNumber = useSelector(state => state.loggedInUser.memberNumber);


    const requiredAction = "Integrations:Manage";

    useEffect(init, []);

    function init() {

        axios.all([
            AccountService.getIntegrationsAccess(),
            AccountService.getFeatures()
        ]).then(axios.spread(function (providersResponse, featuresResponse) {

            setProviders(providersResponse);

            var featuresWithProviders = featuresResponse.filter(function (f) {
                return providersResponse.some(function (p) {
                    return p.featureId === f.id;
                })
            });

            setFeatureList(featuresWithProviders);
            setLoading(false);

        }), () => {
            NotificationToaster.show(Intent.DANGER, "Could not load options. Please refresh the page.");
        });

    }

    function onProviderUpdate(event) {
        var toggleId = parseInt(event.currentTarget.getAttribute('identifier'));
        var checked = event.target.checked;

        var tempProviders = [
            ...providers
        ];

        var selectedProvider = tempProviders.find(p => p.providerId === toggleId);
        selectedProvider.enabled = checked;

        setProviders(tempProviders);
    }

    function mapProivders(featureId) {
        return providers.filter(function (p) {
            return p.featureId === featureId
        }).map(function (p) {
            return {
                id: p.providerId,
                name: p.providerName,
                checked: p.enabled
            }
        });
    }

    function onSave() {
        setSaving(true);

        AccountService.setIntegrationsAccess(providers).then(function () {
            NotificationToaster.show(Intent.SUCCESS, "Access providers have been updated.");
        }, function () {
            NotificationToaster.show(Intent.DANGER, "Could not update access providers, please try again.");
        }).finally(function () {
            setSaving(false);
        })

    }

    return (
        <UserAccess perform={requiredAction}
            yes={() => (
                <div>

                    <div className="intro-text">
                        <p>These integrations need you to grant them permission to allow third parties to push data into your Vision account.</p>
                        <p>For the services to work correctly, please make sure you have supplied the providers you have enabled with your member number (<strong>{memberNumber}</strong>).</p>
                    </div>

                    {
                        featureList && featureList.map(function (feature) {
                            return (
                                <Fragment>
                                    <h3>{feature.name}</h3>
                                    <FormSwitchList
                                        items={mapProivders(feature.id)}
                                        onChange={onProviderUpdate}
                                        loading={loading}
                                        switchListName="featureList"
                                        disabled={saving}
                                    />
                                </Fragment>
                            );
                        })

                    }

                    <ButtonSave onClick={onSave} disabled={saving} loading={loading} />

                </div>
            )}
            no={() => (
                <div className="row">
                    <PageRestricted />
                </div>
            )}
        />
    );

}
import React, { useState, useEffect, useRef } from 'react';
import classNames from 'classnames';
import { NumericInput } from "@blueprintjs/core";
import PropTypes from 'prop-types';
import { FormHelperText } from "components/form-fields";
import 'components/form-fields/FormFields.css';
import { ShowHide } from 'components/layout';

export function FormNumericInput(props) {
    const [selectedValue, setSelectedValue] = useState("");
    const inputRef = useRef(null);

    useEffect(() => {
        const value = props.selectedValue || props.selectedValue === 0 ? props.selectedValue : "";

        if (value !== props.selectedValue) {
            props.onValueChange(value);
        }

        setSelectedValue(value);
    }, [props.selectedValue]);

    useEffect(() => {
        if (inputRef.current != null) {
            inputRef.current.disabled = props.disabledInput;
        }
    }, [inputRef, props.disabledInput])

    function onInputRefChange(ref) {
        inputRef.current = ref;
    }

    function onValueChange(value, valueString) {
        // If decimals are allowed, then we must return the string
        // representation of the value. This is to make sure that 
        // when the user types '2.' (for example) it is not turned
        // back into '2', preventing them from adding the decimal.
        props.onValueChange(props.allowDecimal ? valueString : value);
    }

    return (
        <div className="form-field">
            <ShowHide
				evaluator={props.headingText.length > 0}
				show={(
					<h4 className={classNames({ "bp3-skeleton": props.loading })}>{props.headingText}</h4>
				)}
			>
			</ShowHide>
            <FormHelperText loading={props.loading}>{props.helperText}</FormHelperText>
            <NumericInput
                id={props.id}
                disabled={props.disabled}
                className={classNames("small", { "bp3-skeleton": props.loading, "disabled-input-box": props.disabledInput })}
                onValueChange={onValueChange}
                value={selectedValue}
                max={props.max}
                min={props.min}
                inputRef={onInputRefChange}
                minorStepSize={!props.allowDecimal ? null : (1 / Math.pow(10, props.decimalPlaces))}
                leftIcon={props.prefixText == null ? null : <span className="form-numeric-icon">{props.prefixText}</span>}
            ></NumericInput>
            <FormHelperText loading={props.loading} danger={true}>{props.dangerHelperText}</FormHelperText>
        </div>
    );
}

FormNumericInput.defaultProps = {
    id: null,
    selectedValue: null,
    loading: false,
    headingText: "",
    helperText: null,
    disabled: false,
    disabledInput: false,
    max: null,
    min: null,
    dangerHelperText: null,
    allowDecimal: false,
    decimalPlaces: 2,
    prefixText: null
};

FormNumericInput.propTypes = {
    id: PropTypes.string,
    selectedValue: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number
    ]),
    loading: PropTypes.bool,
    headingText: PropTypes.string,
    helperText: PropTypes.node,
    disabled: PropTypes.bool,
    disabledInput: PropTypes.bool,
    onValueChange: PropTypes.func.isRequired,
    max: PropTypes.number,
    min: PropTypes.number,
    dangerHelperText: PropTypes.node,
    allowDecimal: PropTypes.bool,
    decimalPlaces: PropTypes.number,
    prefixText: PropTypes.string
};
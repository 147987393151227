import React from 'react';
import classNames from 'classnames';
import { Switch, Alignment } from "@blueprintjs/core";
import PropTypes from 'prop-types';
import { ShowHide } from 'components/layout';

import { FormHelperText, FormHeading } from "components/form-fields";

import 'components/form-fields/FormFields.css';

export function FormSwitch(props) {

	return (
		<div className={classNames("form-field form-field-switcher", { "no-label": !props.label })}>
			<FormHeading loading={props.loading} headingLevel={"h4"}>{props.headingText}</FormHeading>
			<ShowHide
				evaluator={!props.inline}
				show={(
					<label className={classNames({ "bp3-skeleton": props.loading })} htmlFor={props.identifier}>{props.label}</label>
				)}
			/>

			<FormHelperText loading={props.loading}>{props.helperText}</FormHelperText>
			<Switch
				id={props.id}
				identifier={props.identifier}
				disabled={props.disabled}
				className={classNames({ "bp3-skeleton": props.loading })}
				label={props.inline ? props.label : ""}
				alignIndicator={props.alignment}
				onChange={props.onChange}
				checked={props.checked}
				innerLabelChecked={props.showLabels ? "On" : ""}
				innerLabel={props.showLabels ? "Off" : ""}
				labelElement={props.labelElement}
			/>
			<FormHelperText loading={props.loading} danger={true}>{props.dangerHelperText}</FormHelperText>
		</div>
	);
}

FormSwitch.defaultProps = {
	id: null,
	checked: false,
	loading: false,
	inline: false,
	headingText: "",
	helperText: null,
	disabled: false,
	dangerHelperText: null,
	alignment: Alignment.LEFT,
	showLabels: true,
	labelElement: null
};

FormSwitch.propTypes = {
	id: PropTypes.string,
	checked: PropTypes.bool,
	onChange: PropTypes.func.isRequired,
	loading: PropTypes.bool,
	inline: PropTypes.bool,
	headingText: PropTypes.string,
	helperText: PropTypes.node,
	disabled: PropTypes.bool,
	dangerHelperText: PropTypes.node,
	alignment: PropTypes.string,
	showLabels: PropTypes.bool,
	labelElement: PropTypes.node
};
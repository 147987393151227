import React from 'react';
import PropTypes from 'prop-types';
import { Popover, Position, Intent } from "@blueprintjs/core";
import { Button } from 'components/buttons'

export function ButtonList(props) {

    let intent = Intent.NONE;
    if (props.intent) {
        switch (props.intent.toLowerCase()) {
            case "save":
            case "info":
            case "primary":
                intent = Intent.PRIMARY;
                break;
            case "warning":
                intent = Intent.WARNING;
                break;
            case "danger":
            case "delete":
                intent = Intent.DANGER;
                break;
            default:
                intent = Intent.NONE
        }
    }

    return (
        <Popover content={props.menuItems} position={Position.BOTTOM}>
            <Button id={props.id} disabled={props.disabled} icon={props.icon} iconOnly={props.iconOnly} text={props.text} intent={intent} minimal={props.minimal} large={props.large} onClick={() => { }} />
        </Popover>
    );
}

ButtonList.defaultProps = {
    id: null,
    intent: "",
    disabled: false,
    icon: null,
    iconOnly: false,
    text: "",
    minimal: false,
    large: true
};

ButtonList.propTypes = {
    id: PropTypes.string,
    intent: PropTypes.string,
    disabled: PropTypes.bool,
    icon: PropTypes.string,
    iconOnly: PropTypes.bool,
    text: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.object
    ]),
    minimal: PropTypes.bool,
    large: PropTypes.bool
};
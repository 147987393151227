import moment from "moment";
import { Tooltip, Position } from "@blueprintjs/core";
import { LinkButton } from "components/buttons"

export const LinkService = {

    renderAssetCalendarLink(assetId, date, usePortal = true) {
        return (
            <Tooltip position={Position.TOP} content={`Vehicle Calendar`} usePortal={usePortal}>
                <LinkButton
                    href={`${window.env.VISION_URL}/#/Fleet/RedirectToCalendar?vehicleId=${assetId}&activityDate=${moment(date).format('YYYY-MM-DD')}`}
                    icon={"calendar"}
                    externalLink={true}
                    minimal={true}
                    iconOnly={true}
                    large={false}
                    text="Calendar"
                />
            </Tooltip>
        );
    },

    renderEmployeeCalendarLink(employeeId, date, usePortal = true) {
        return (
            <Tooltip position={Position.TOP} content={`Driver Calendar`} usePortal={usePortal}>
                <LinkButton
                    href={`${window.env.VISION_URL}/#/Drivers/RedirectToCalendar?driverId=${employeeId}&activityDate=${moment(date).format('YYYY-MM-DD')}`}
                    icon={"calendar"}
                    externalLink={true}
                    minimal={true}
                    iconOnly={true}
                    large={false}
                />
            </Tooltip>
        );
    },

    getVehicleCalendarUrl(assetId, date) {
        return `${window.env.VISION_URL}/#/Fleet/RedirectToCalendar?vehicleId=${assetId}&activityDate=${moment(date).format('YYYY-MM-DD')}`
    },

    getVehicleProfileUrl(assetId) {
        return `${window.env.VISION_URL}/#/Fleet/RedirectToProfile?assetId=${assetId}`;
    },

    getDriverProfileUrl(employeeId) {
        return `${window.env.VISION_URL}/#/Drivers/RedirectToProfile?employeeId=${employeeId}`;
    },

    renderVehicleProfileLink(assetId, usePortal = true) {
        return (
            <Tooltip position={Position.TOP} content={"Vehicle Profile"} usePortal={usePortal}>
                <LinkButton
                    href={this.getVehicleProfileUrl(assetId)}
                    icon={"fa-truck"}
                    externalLink={true}
                    minimal={true}
                    iconOnly={true}
                    large={false}
                    faColour="#5c7080"
                />
            </Tooltip>
        );
    },

    renderEmployeeProfileLink(employeeId, usePortal = true) {
        return (
            <Tooltip position={Position.TOP} content={"Driver Profile"} usePortal={usePortal}>
                <LinkButton
                    href={`${window.env.VISION_URL}/#/Drivers/RedirectToProfile?employeeId=${employeeId}`}
                    icon={"fa-user"}
                    externalLink={true}
                    minimal={true}
                    iconOnly={true}
                    large={false}
                    faColour="#5c7080"
                />
            </Tooltip>
        );
    }
};
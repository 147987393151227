import React, { Fragment, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import classNames from "classnames";
import { Button, Intent, NonIdealState } from "@blueprintjs/core";
import { ShowHide } from 'components/layout/ShowHide';
import 'components/listing/Listing.css';
import { Table } from 'components/listing';

export function EditTable(props) {
    const [numberOfEditButtons, setNumberOfEditButtons] = useState(0);

    useEffect(() => {
        let numberOfButtons = 0;

        if ((props.data?.some(row => row.isEditable)) || props.canEditAllRows) {
            numberOfButtons++;
        }

        if (props.canAddRows) {
            numberOfButtons++;
        }

        if (props.canDeleteRows) {
            numberOfButtons++;
        }

        setNumberOfEditButtons(numberOfButtons);
    }, [props.canAddRows, props.data, props.canDeleteRows, props.canEditAllRows]);

    function renderEditButtonsHeader() {
        return <Fragment>
            <th className={classNames('listing-table-button-header hide-in-print', `listing-table-button-header-${numberOfEditButtons}`)}>
                {props.canAddRows && <Button icon='plus' minimal={true} intent={Intent.NONE} onClick={() => props.onAddRowClick(-1)} disabled={props.disabled}></Button>}
            </th>
        </Fragment>;
    }

    function renderRowButtons(row, rowIndex) {
        return <ShowHide
            evaluator={numberOfEditButtons > 0}
            show={<Fragment>
                <td className='listing-table-buttons hide-in-print'>
                    <ShowHide
                        evaluator={props.canAddRows}
                        show={
                            <Button icon='plus' minimal={true} intent={Intent.NONE} onClick={() => props.onAddRowClick(rowIndex)} disabled={props.disabled}></Button>
                        }
                    />
                    <ShowHide
                        evaluator={props.canEditAllRows || row.isEditable}
                        show={
                            <Button icon='edit' minimal={true} intent={Intent.NONE} onClick={() => props.onEditRowClick(rowIndex)} disabled={props.disabled}></Button>
                        }
                    />
                    <ShowHide
                        evaluator={props.canDeleteRows}
                        show={
                            <Button icon='trash' minimal={true} intent={Intent.NONE} onClick={() => props.onDeleteRowClick(rowIndex)} disabled={props.disabled}></Button>
                        }
                    />
                </td>
                <td className='listing-table-validity'>{row.validity}</td>
            </Fragment>}
        />;
    }

    function formatData() {
        return props.data.map((row, rowIndex) => {
            return {
                key: row.key,
                cells: row.cells,
                additionalCells: renderRowButtons(row, rowIndex),
                class: row.valid === false && !props.disabled ? "table-row-invalid" : ""
            };
        });
    }

    function formatHeaderData() {
        return {
            ...props.headerData,
            additionalHeaders: renderEditButtonsHeader()
        };
    }

    return (
        <ShowHide
            evaluator={props.data.length === 0 && !props.canAddRows && !props.loadingData}
            show={(
                <Fragment>
                    <div className="clear-divide"></div>
                    <div className="list-table-no-results">
                        <NonIdealState                            
                            title={props.noDataHeading}
                            description={props.noDataMessage}
                        />
                    </div>
                </Fragment>

            )}
            hide={(
                <Table
                    data={formatData()}
                    headerData={formatHeaderData()}
                    loadingData={props.loadingData}
                    fitToContent={props.fitToContent}
                />
            )}
        />
    );
}

EditTable.defaultProps = {
    data: [],
    headerData: {},
    loadingData: false,
    fitToContent: false,
    onAddRowClick: () => null,
    onEditRowClick: () => null,
    onDeleteRowClick: () => null,
    canAddRows: false,
    canDeleteRows: false,
    canEditAllRows: false,
    disabled: false,
    noDateMessage: '',
    noDataHeading: 'Nothing to see here...'
};

EditTable.propTypes = {
    data: PropTypes.array.isRequired,
    headerData: PropTypes.object.isRequired,
    loadingData: PropTypes.bool,
    fitToContent: PropTypes.bool,
    onAddRowClick: PropTypes.func,
    onEditRowClick: PropTypes.func,
    onDeleteRowClick: PropTypes.func,
    canAddRows: PropTypes.bool,
    canDeleteRows: PropTypes.bool,
    canEditAllRows: PropTypes.bool,
    disabled: PropTypes.bool,
    noDataMessage: PropTypes.string,
    noDataHeading: PropTypes.string,
};

import ApiService from "services/ApiService";

const badRequestCode = 400;

export const CustomDashboardService = {
    getDashboards(pageSize, pageNumber, searchTerm) {
        return new Promise((resolve, reject) => {
            ApiService.get(`/reporting/customdashboards?requestCount=${pageSize}&pageNumber=${pageNumber}&searchTerm=${encodeURI(searchTerm)}`).then(
                (result) => {
                    resolve(result.data);
                },
                (error) => {
                    reject(error);
                });
        });
    },
    getDashboard(dashboardId) {
        return new Promise((resolve, reject) => {
            ApiService.get(`/reporting/customdashboards/${dashboardId}`).then(
                (result) => {
                    resolve(result.data);
                },
                (error) => {
                    reject(error);
                });
        });
    },
    getEmbedToken(dashboardId) {
        return new Promise((resolve, reject) => {
            ApiService.get(`/reporting/customdashboards/${dashboardId}/EmbedToken`).then(
                (result) => {
                    resolve(result.data);
                },
                (error) => {
                    reject(error);
                });
        });
    },
    createDashboard(dashboard) {
        return new Promise((resolve, reject) => {
            ApiService.post('/reporting/customdashboards', dashboard)
                .then(function (response) {
                    resolve(response.data);
                })
                .catch(function (error) {
                    if (error.response.status === badRequestCode) {
                        reject(error.response.data);
                    } else {
                        reject("Could not create your dashboard. Please try again.");
                    }
                });
        });
    },
};
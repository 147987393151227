import React, { useState, useEffect, Fragment } from 'react';

import { Intent } from '@blueprintjs/core';

import { FormTextInput, FormSelect, FormCheckboxList } from 'components/form-fields';
import { LocationService } from 'services';
import { NotificationToaster, AlertUnsavedData, Modal } from "components/notifications";
import { ButtonSave, LinkButton } from "components/buttons";
import { useValidation } from "hooks/useValidation";
import { useDirtyData } from "hooks/useDirtyData";
import { ShowHide } from "components/layout";

var _ = require('underscore');

export function LocationGeneralSettings(props) {
    const [isValid, errors, validate] = useValidation();
    const [location, setLocation] = useState({ state: { id: null }, businessAreas: []});
    const [states, setStates] = useState([]);
    const [businessAreas, setBusinessAreas] = useState([]);
    const [defaultBusinessAreas, setDefaultBusinessAreas] = useState([]);
    const [saving, setSaving] = useState(false);
    const [loading, setLoading] = useState(true);
    const [originalValues, setOriginalValues] = useState(location);
    const [showLocationTransferLinkModal, setShowLocationTransferLinkModal] = useState(false);
    const [inactivateLocationErrorMessage, setInactivateLocationErrorMessage] = useState("");
    const [allowIsDirty, setAllowIsDirty] = useState(false);
    const [disableValidationTitle, setDisableValidationTitle] = useState("Disable Validation");
    const [disableValidationUrl, setDisableValidationUrl] = useState("");
    const [disableValidationText, setDisableValidationText] = useState("");

    const isDataDirty = useDirtyData(originalValues, location);

    const rules = [
        { fieldName: "name", required: true, maxLength: 255 },
        { fieldName: "state.id", required: true },
        { fieldName: "businessAreas", required: true }
    ];

    useEffect(() => {       
        setLocation(props.location);        
        setOriginalValues(_.extend({}, props.location));
    }, [props.location]);

    useEffect(validateModel, [isDataDirty, location]);
    useEffect(() => {
        setLoading(props.loading);
    }, [props.loading]);

    useEffect(() => {
        if (!props.loading) {         
            setLoading(true);
            LocationService.getLocationBusinessAreas().then(
                (areas) => {
                    setDefaultBusinessAreas(areas);
                    setLoading(false);
                },
                (error) => {
                    NotificationToaster.show(Intent.DANGER, "Could not load settings. Please refresh the page.");
                    setLoading(false);
                });          
        }
    }, [props.loading]);

    useEffect(() => {

        if (defaultBusinessAreas.length > 0) {
            mapBusinessAreas(defaultBusinessAreas);
        }

        function mapBusinessAreas(data) {

            var allowedAreas = ["Tacho", "VIS", "Audit"];

            var mappedAreas = _.map(data, function (item) {
                const locationHasArea = _.some(location.businessAreas, function (area) {
                    return area.locationBusinessAreaId === item.locationBusinessAreaId;
                });

                return { id: item.locationBusinessAreaId, name: item.businessArea, checked: locationHasArea, disabled: !item.internal && allowedAreas.indexOf(item.businessArea) === -1 };
            });

            setBusinessAreas(mappedAreas);

            
        }

    }, [location.businessAreas, defaultBusinessAreas])

    useEffect(() => {
        if (!props.loading) {
            setLoading(true);
            LocationService.getLocationStates().then(
                (data) => {
                    setStates(data);
                    setLoading(false);
                },
                (error) => {
                    NotificationToaster.show(Intent.DANGER, "Could not load settings. Please refresh the page.");
                    setLoading(false);
                });            
        }        
    }, [props.loading]);

    function saveGeneralSettings() {        
        setSaving(true);
        if (!isValid) {
            setSaving(false);
            NotificationToaster.show(Intent.WARNING, "There are errors on the page that need your attention");
            return;
        }

        LocationService.saveGeneralSettings(location).then(
            () => {
                setOriginalValues(_.extend({}, location));
                setSaving(false);
                props.onLocationUpdated(location);
                NotificationToaster.show(Intent.SUCCESS, "Settings updated successfully.");
            },
            (error) => {
                setSaving(false);
                const badRequestCode = 400;

                if (error && error.status === badRequestCode) {
                    setInactivateLocationErrorMessage(error.data);

                    if (error.data.includes("hierarchy")) {
                        setDisableValidationTitle("Business Area Validation");
                        setDisableValidationText("Go to Location Hierarchies");
                        setDisableValidationUrl(`/location/hierarchies`);
                    }
                    else {
                        //This is validation for checking Jobs and Assets/Employees for inactive account and removing business areas
                        error.data.includes("inactive")
                            ? setDisableValidationTitle("Deactivation Validation")
                            : setDisableValidationTitle("Business Area Validation");

                        if (error.data.includes("asset") || error.data.includes("employee")) {
                            setDisableValidationText("Go to Location Migration");
                            setDisableValidationUrl(`/location/${location.id}/migration/create`)
                        } else {
                            setDisableValidationText("");
                            setDisableValidationUrl("");
                        }
                    }

                    setAllowIsDirty(true);
                    setShowLocationTransferLinkModal(true);
                } else {
                    NotificationToaster.show(Intent.DANGER, "Could not save settings. Please try again.");
                }                
            });
    }   

    function onNameChange(event) {
        setLocation({
            ...location,
            name: event.target.value
        });
    }

    function onReferenceChange(event) {
        setLocation({
            ...location,
            reference: event.target.value
        });
    }

    function onStateChange(item, originalValue) {
        if (originalValue !== null) {
            setLocation({
                ...location,
                state: {
                    name: item.name,
                    id: item.id
                }
            });            
        }	
    }
    function handleCheckboxChange(event, id) {
        var tempItems = [...businessAreas];

        var match = _.find(tempItems, function (item) {
            return item.id === id;
        });        
        match.checked = event.target.checked;

        var selectedItems = _.chain(tempItems).filter(function (item) {
            return item.checked;
        }).map(function (item) {
            return { locationBusinessAreaId: item.id, businessArea: item.name };
        }).value();

        setLocation({
            ...location,
            businessAreas: selectedItems
        });           

    }

    function validateModel() {
        if (isDataDirty) {            
            validate(rules, location);
        }    
    }

    function onLocationTransferLinkModalClosed() {
        setAllowIsDirty(false);
        setShowLocationTransferLinkModal(false);
    }

    return (
        <Fragment>
        <div className="row">
            <h2>General Settings</h2>
            <FormTextInput
                id="location-name"
                loading={loading}
                value={location.name}
                headingText="Name:"
                onChange={onNameChange}
                disabled={saving}
                dangerHelperText={errors.name}>
            </FormTextInput>            
            <FormSelect
                id="location-status"
                loading={loading}
                disabled={saving}
                items={states}
                placeholder="Select a status"
                headingText="Status:"
                selectedValue={location.state.id}
                onItemSelect={onStateChange}
                dangerHelperText={errors["state.id"]}>
            </FormSelect>
            <FormTextInput
                id="location-reference"
                loading={loading}
                value={location.reference}
                headingText="External Reference:"
                helperText="You can use this value as a reference to link to this location in another system outside of Vision"
                onChange={onReferenceChange}
                disabled={saving}
                dangerHelperText={errors.reference}>
            </FormTextInput>
            <FormCheckboxList
                id="location-business-areas"
                items={businessAreas}
                onChange={handleCheckboxChange}
                headingText="Business Areas:"
                loading={loading}
                dangerHelperText={errors.businessAreas}
            >
            </FormCheckboxList>
            <AlertUnsavedData
                isDirty={isDataDirty && !allowIsDirty}>
            </AlertUnsavedData>
            <ButtonSave
                id="location-general-save"
                loading={loading}
                onClick={saveGeneralSettings}
                disabled={saving}
            ></ButtonSave>
        </div>
        <Modal
            isOpen={showLocationTransferLinkModal}
            title={disableValidationTitle}
            onClose={onLocationTransferLinkModalClosed}
            >
                <div className="bp3-dialog-body">
                    <ShowHide
                        evaluator={inactivateLocationErrorMessage.length > 0}
                        show={(
                            <div className="spacer-bottom">{inactivateLocationErrorMessage}</div>
                        )}
                    />

                    <ShowHide
                        evaluator={disableValidationText.length > 0}
                        show={(
                            <LinkButton text={disableValidationText} intent="primary" href={disableValidationUrl} />
                        )}
                    />
                </div>
            </Modal>
        </Fragment >
    );       
}
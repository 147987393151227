import React from 'react';
import { Alignment } from "@blueprintjs/core";
import { FormSwitch, FormHelperText, FormHeading } from 'components/form-fields';
import PropTypes from 'prop-types';

export function FormSwitchList(props) {  

    function onItemChange(event) {  
        props.onChange(event);
    }

    return (
        <div className="form-field">
            <FormHeading loading={props.loading} headingLevel={props.headingLevel}>{props.headingText}</FormHeading>
            <FormHelperText loading={props.loading}>{props.helperText}</FormHelperText>
            <div className="switchlist">
                {props.items && props.items.length > 0 && props.items.map((item) => {                
                    return (
                        <FormSwitch
                            id={props.switchListName + item.id}
                            key={item.id}
                            disabled={props.disabled || item.disabled}
                            onChange={onItemChange}
                            checked={item.checked}
                            loading={props.loading}
                            label={item.name}
                            identifier={item.id}
                            inline={true}
                            alignment={Alignment.RIGHT}
                            labelElement={item.labelElement}
                        ></FormSwitch>
                    )
                })}
            </div>
            <FormHelperText danger={true}>{props.dangerHelperText}</FormHelperText>
        </div>
    );
}

FormSwitchList.defaultProps = {
    headingLevel: "h4"
};

FormSwitchList.propTypes = {
    headingLevel: PropTypes.string
};
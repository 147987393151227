import moment from "moment";

const fourMinuteBuffer = 4;

export const defaultSystemMessageModel = {
    systemMessageID: 0,
    startDate: moment().add(fourMinuteBuffer, 'm').format("YYYY-MM-DD HH:mm"),
    endDate: moment().add(1, 'd').format("YYYY-MM-DD HH:mm"),
    systemMessageTypeID: 0,
    message: "",
    url: "",
    active: true,
    statusPageID: "0"
};
import React, { Fragment, useState, useEffect } from 'react';

import { Route, Switch, Redirect, useParams } from 'react-router-dom';
import { LocationChangelog } from 'pages/locations/edit/changelog';
import { LocationTachoSettings } from 'pages/locations/edit/tacho-settings';
import { NotificationInline } from 'components/notifications';
import { LinkButton } from 'components/buttons';
import { VerticalLinkGroup, Breadcrumbs } from 'components/navigation';
import { LocationGeneralSettings } from 'pages/locations/edit/general-settings';
import { LocationService } from 'services';
import { ShowHide } from 'components/layout';
import { UserAccess, UserHasAccess } from 'components/user-access';
import { PageRestricted } from 'pages/errors/page-restricted';
import { LocationMigration } from './migration';
import { LocationContactListing } from 'pages/locations/edit/contacts/listing';
import { LocationContactCreate } from 'pages/locations/edit/contacts/create';
import { LocationAddress } from 'pages/locations/edit/address';
import { BusinessAreaOperatingHours } from 'pages/locations/edit/business-area-operating-hours';
import { BusinessAreaLocationNotes } from 'pages/locations/edit/notes';
import { AdditionalInfo } from 'pages/locations/edit/AdditionalInfo';

export function Location(props) {
    const { id } = useParams();
    const [invalidLocation, setInvalidLocation] = useState(false);
    const [loadingData, setLoadingData] = useState(true);
    const [location, setLocation] = useState({ name: "", state: {id: null}});

    const routes = [
        { location: `/location/${id}/general-settings`, title: "General Settings" },
        { location: `/location/${id}/tacho-settings`, title: "Tacho Settings" }        
    ];

    const requiredAction = "Locations:Manage";
    const canAccessPage = UserHasAccess(requiredAction);

    const canAccessMigrationTab = UserHasAccess("Employees:Migrate") || UserHasAccess("Fleet:Migrate");
    const isInternal = UserHasAccess("Internal");

    if (isInternal) {
        routes.push({ location: `/location/${id}/location-address`, title: "Location Address" });
        routes.push({ location: `/location/${id}/additional-info`, title: "Additional Info" });
        routes.push({ location: `/location/${id}/visiting-hours`, title: "Visiting Hours" });
        routes.push({ location: `/location/${id}/notes`, title: "Internal Notes" });
    }

    routes.push({ location: `/location/${id}/contacts`, title: "Contacts" });

    if (canAccessMigrationTab) {
        routes.push({ location: `/location/${id}/migration`, title: "Location Migration" });
    }

    routes.push({ location: `/location/${id}/changes`, title: "History" });

    useEffect(() => {
        if (loadingData && canAccessPage) {
            LocationService.getLocationBasicInfo(id).then(r => {
                setLocation(r); 
                setLoadingData(false);
            }, (error) => {

                const noAccessCode = 403;

                if (error.status === noAccessCode) {
                    setInvalidLocation(true);
                }
            });
        }

    }, [loadingData, id]);

    function onLocationUpdated(updatedLocation) {
        setLocation(updatedLocation);
    }

    return (


        <UserAccess perform={requiredAction}
            yes={() => (

        <Fragment>            

            <ShowHide
                evaluator={invalidLocation}
                show={(
                    <div className="row">
                        <Breadcrumbs items={props.breadcrumbs} />
                        <NotificationInline
                            allowClose={false}
                            show
                            text="You do not have permission to view this location"
                            intent="danger">
                        </NotificationInline>  
                        <div>
                            <LinkButton intent="primary" text="Back to location management" href="/location" id="return-location-listing" />
                        </div>
                    </div>
                )}
                hide={(
                    <Fragment>
                        <div className="row">
                            <Breadcrumbs items={props.breadcrumbs} />
                            <h1>{location.name}</h1>
                            <div className="intro-text">
                                <p>Here you can configure your location settings for {location.name}.</p>
                            </div>
                        </div>

                        <div className="layout-cols">
                            <div className="col side-col">
                                <div className="row">
                                    <VerticalLinkGroup links={routes} exactRouteMatch={false}></VerticalLinkGroup>
                                </div>
                            </div>
                            <div className="col content-col">
                                <Switch>
                                    <Route path="/location/:id(\d+)/general-settings">
                                        <LocationGeneralSettings
                                            location={location}
                                            loading={loadingData}
                                            onLocationUpdated={onLocationUpdated}>
                                        </LocationGeneralSettings>
                                    </Route>
                                    <Route path="/location/:id(\d+)/tacho-settings">
                                        <LocationTachoSettings locationId={id}></LocationTachoSettings>
                                    </Route>
                                    <Route path="/location/:id(\d+)/location-address">
                                        <LocationAddress locationId={id}></LocationAddress>
                                    </Route>
                                    <Route path="/location/:id(\d+)/additional-info">
                                        <AdditionalInfo locationId={id}></AdditionalInfo>
                                    </Route>
                                    <Route path="/location/:id(\d+)/visiting-hours">
                                        <BusinessAreaOperatingHours locationId={id}></BusinessAreaOperatingHours>
                                    </Route>
                                    {isInternal && <Route path="/location/:id(\d+)/notes">
                                        <BusinessAreaLocationNotes locationId={id}></BusinessAreaLocationNotes>
                                    </Route>}
                                    {canAccessMigrationTab && <Route path="/location/:id(\d+)/migration">
                                        <LocationMigration locationId={id} locationName={location.name} locationBusinessAreas={location.businessAreas ? location.businessAreas : []} />
                                    </Route>}
                                    <Route path="/location/:id(\d+)/changes">
                                        <LocationChangelog locationId={id}></LocationChangelog>
                                    </Route>
                                    <Route path="/location/:id(\d+)/contacts/create">
                                        <LocationContactCreate locationId={id}></LocationContactCreate>
                                    </Route>
                                    <Route path="/location/:id(\d+)/externalcontacts/create">
                                        <LocationContactCreate locationId={id} external={true}></LocationContactCreate>
                                    </Route>
                                    <Route path="/location/:id(\d+)/contacts">
                                        <LocationContactListing locationId={id}></LocationContactListing>
                                    </Route>
                                    <Redirect exact from="/location/:id(\d+)" to="/location/:id(\d+)/general-settings" />
                                </Switch>
                            </div>
                        </div>
                    </Fragment>
                )}
            />

            
                </Fragment>
            )}
            no={() => (
                <PageRestricted />
            )}
        />
    );

}
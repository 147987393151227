import React, { useEffect, useState } from 'react';
import classNames from 'classnames';
import { MenuItem, Intent, Spinner } from "@blueprintjs/core";
import { Button } from "components/buttons";
import { ShowHide } from "components/layout";
import { Suggest } from "@blueprintjs/select";
import PropTypes from 'prop-types';
import { useDebounce } from 'hooks/useDebounce';

import { FormHelperText } from "components/form-fields";

import 'components/form-fields/FormFields.css';

var _ = require('underscore');

export function FormSuggest(props) {

	const [items, setItems] = useState([]);
	const [filteredItems, setFilteredItems] = useState([]);
	const [selectedItem, setSelectedItem] = useState(null);
	const [currentQuery, setCurrentQuery] = useState("");
	const debouncedQuery = useDebounce(currentQuery, props.debounceTime);
	const [externalFiltering, setExternalFiltering] = useState(false);

	useEffect(setAllItems, [props.items]);

	function setAllItems() {
		setItems(props.items);

		if (props.onQueryChange != null || currentQuery.length > 0) {
			setFilteredItems(props.items);
		}

		setExternalFiltering(false);

	}

	useEffect(setItemFromValue, [props.selectedValue, props.items]);

	function setItemFromValue() {
		var newItem = props.items.find(obj => obj.id === props.selectedValue);

		if (newItem === undefined) {
			newItem = null;
		}

		setSelectedItem(newItem);
	}

	function renderSelect(item, { handleClick, modifiers }) {
		return (
			<MenuItem
				active={modifiers.active}
				onClick={handleClick}
				key={item.id}
				text={item.name}
				disabled={modifiers.disabled}
				icon={item.icon}
			/>
		);
	}

	function filterItems(query) {
		setCurrentQuery(query);
	}

	useEffect(function () {

		if (props.onFilter != null) {

			if (debouncedQuery.length === 0) {
				setFilteredItems([]);
				return;
			} else {
				setExternalFiltering(true);
				props.onFilter(debouncedQuery);
			}
		}
		else {
			var queryLower = debouncedQuery.toLowerCase();

			var filteredList = _.filter(items, function (i) {
				var valueLower = i.name.toLowerCase();
				return valueLower.indexOf(queryLower) > -1;
			});

			if (props.itemsToReturn > 0) {
				setFilteredItems(_.first(filteredList, props.itemsToReturn));
			}
		}

	}, [debouncedQuery]);

	function renderInputValue(item) {
		return item.name;
	}

	function clearItem() {
		props.onItemSelect({ id: null });
		setFilteredItems([]);
	}

	return (
		<div className={classNames("form-field form-field-suggest", { "suggest-clearable": props.clearSelection })} id={props.id}>
			<ShowHide
				evaluator={props.headingText.length > 0}
				show={<h4 className={classNames({ "bp3-skeleton": props.loading })}>{props.headingText}</h4>}
			/>
			<FormHelperText loading={props.loading}>{props.helperText}</FormHelperText>
			<Suggest
				disabled={props.disabled}
				className={classNames({ "bp3-skeleton": props.loading })}
				items={filteredItems}
				itemRenderer={renderSelect}
				inputValueRenderer={renderInputValue}
				onItemSelect={props.onItemSelect}
				inputProps={{ large: props.large, inputRef: props.inputRef }}
				popoverProps={{ minimal: true, usePortal: false }}
				fill={props.fullWidth}
				onQueryChange={props.onQueryChange == null ? filterItems : props.onQueryChange}
				selectedItem={selectedItem}
				resetOnSelect={props.resetOnSelect}
			/>
			<ShowHide
				evaluator={selectedItem != null && props.clearSelection}
				show={(
					<Button loading={props.loading} text="Clear" intent={Intent.DANGER} minimal={true} large={false} icon="cross" onClick={clearItem} disabled={props.disabled || externalFiltering}></Button>
				)}
			></ShowHide>
			<ShowHide
				evaluator={externalFiltering}
				show={(
					<div className="save-loader">
						<Spinner size="25" />
					</div>
				)}
			/>
			<FormHelperText loading={props.loading} danger={true}>{props.dangerHelperText}</FormHelperText>
		</div>
	);
}

FormSuggest.defaultProps = {
	id: null,
	items: [],
	disabled: false,
	loading: false,
	headingText: "",
	helperText: null,
	dangerHelperText: null,
	clearSelection: true,
	fullWidth: false,
	onFilter: null,
	debounceTime: 100,
	large: false,
	onQueryChange: null,
	itemsToReturn: 30,
	resetOnSelect: true,
	inputRef: null
};

FormSuggest.propTypes = {
	id: PropTypes.string,
	items: PropTypes.array,
	disabled: PropTypes.bool,
	onItemSelect: PropTypes.func.isRequired,
	loading: PropTypes.bool,
	headingText: PropTypes.string,
	helperText: PropTypes.node,
	dangerHelperText: PropTypes.node,
	clearSelection: PropTypes.bool,
	fullWidth: PropTypes.bool,
	onFilter: PropTypes.func,
	debounceTime: PropTypes.number,
	large: PropTypes.bool,
	onQueryChange: PropTypes.func, //If set it means the object consuming this needs to pass a method to run when data being entered changes
	itemsToReturn: PropTypes.number,
	resetOnSelect: PropTypes.bool,
	inputRef: PropTypes.object
};
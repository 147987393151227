import React, { useEffect, useState }  from "react";
import moment from "moment";
import PropTypes from 'prop-types';

import { FormDateInput, FormHelperText, FormSwitch } from "components/form-fields";
import { Table } from 'components/listing';
import { ShowHide } from "components/layout";

export function DebriefEventSettings(props) {

    const [isValid, setIsValid] = useState(false);

    useEffect(validateSettings, [props.settings]);
    useEffect(() => props.onValidationUpdate(isValid), [isValid]);

    function validateSettings() {

        var valid = true;

        props.settings.events.forEach(function (event) {
            if (event.startDate == null) {
                valid = false;
            }
        });

        setIsValid(valid);

    }

    function formatParamTableHeaders() {

        let paramHeaders = ["Event Name", "Enabled", "Enabled From Date"];

        return {
            headers: paramHeaders.map((value) => {
                return {
                    key: value,
                    value: value
                }
            })
        };
    }

    function formatParamTable() {
        return props.settings.events.map(function (event) {
            return {
                cells: [
                    event.name,
                    <FormSwitch
                        onChange={(ev) => { onEventEnableChange(event.key, ev) }}
                        checked={event.enabled}
                        loading={props.loading}
                        disabled={props.saving}
                        id={`input-event-switch-${event.key}`}
                    ></FormSwitch>,
                    <FormDateInput
                        disabled={props.saving || !event.enabled}
                        onChange={(ev) => { onEventDateChange(event.key, ev) }}
                        value={event.startDate}
                        loading={props.loading}
                        minDate={moment("2010-01-01").toDate()}
                        id={`input-event-input-${event.key}`}
                    ></FormDateInput>
                ],
                key: event.key
            };
        });
    }

    function onEventEnableChange(key, item) {
        var events = [
            ...props.settings.events
        ];

        var event = events.find(l => l.key === key);
        event.enabled = item.currentTarget.checked;

        props.onUpdate({
            ...props.settings,
            events: events
        });
    }

    function onEventDateChange(key, item) {
        var events = [
            ...props.settings.events
        ];

        var event = events.find(l => l.key === key);
        event.startDate = item ? moment(item).format("YYYY-MM-DD") : null;

        props.onUpdate({
            ...props.settings,
            events: events
        });
    }

    return (

        <div className='spacer-bottom'>
            <Table
                headerData={formatParamTableHeaders()}
                data={formatParamTable()}
                loadingData={false}
                fitToContent={false}
            />
            <ShowHide
                evaluator={!isValid}
                show={(
                    <div className="form-field">
                        <FormHelperText loading={props.loading} danger={true}>All events must have a start date</FormHelperText>
                    </div>
                )}
            />
            

        </div>
             
    );
}

DebriefEventSettings.defaultProps = {
    disabled: false,
    loading: false,
};

DebriefEventSettings.propTypes = {
    settings: PropTypes.object.isRequired,
    saving: PropTypes.bool,
    loading: PropTypes.bool,
    onValidationUpdate: PropTypes.func.isRequired,
    onUpdate: PropTypes.func.isRequired,
};
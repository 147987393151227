import React, { useState, useEffect, Fragment } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { useParams } from 'react-router-dom';
import ReactECharts from 'echarts-for-react';
import 'pages/risk/risk.css';

import { UserAccess } from 'components/user-access';
import { Breadcrumbs } from 'components/navigation';
import { Button, ButtonSave, LinkButton } from 'components/buttons';
import { DataDisplayTable } from 'components/data-display';
import { RiskService } from 'services/RiskService';
import { Collapsable, ShowHide } from 'components/layout';
import { Modal, NotificationInline, NotificationToaster } from 'components/notifications';
import { FormHeading, FormSwitch, FormTextArea } from 'components/form-fields';
import { ListingPagination, ListingTable } from 'components/listing';
import { TrendStatus } from 'components/status/TrendStatus';
import { StatusBlock } from 'components/status';
import { HelperFunctions } from 'helpers';
import { DateToLocal } from 'components/dates';
import { Alignment, Intent } from '@blueprintjs/core';
import { Tooltip } from 'components/tooltip';

export function RiskProfile(props) {

    const { id } = useParams();

    const [invalidEntity, setInvalidEntity] = useState(false);
    const [entityName, setEntityName] = useState('Entity Name');
    const [loading, setLoading] = useState(true);
    const [entityInfo, setEntityInfo] = useState([]);

    const [riskPeriods, setRiskPeriods] = useState([]);
    const [currentRiskPeriods, setCurrentRiskPeriods] = useState([]);
    const [periodPages, setPeriodPages] = useState(0);
    const [currentPeriodPage, setCurrentPeriodPage] = useState(1);
    const [graphPeriods, setGraphPeriods] = useState([]);
    const [graphData, setGraphData] = useState([]);
    const [graphBands, setGraphBands] = useState([]);
    const [showGraphBands, setShowGraphBands] = useState(false);
    const [selectedEventToView, setSelectedEventToView] = useState(null);
    const [selectedEventToSuppress, setSelectedEventToSuppress] = useState(null);
    const [isUnsuppressing, setIsUnsuppressing] = useState(false);
    const [suppressionMessage, setSuppressionMessage] = useState("");
    const [suppressionPeriodNumber, setSuppressionPeriodNumber] = useState(null);
    const [savingSuppression, setSavingSuppression] = useState(false);
    const [loadingPeriodBreakdown, setLoadingPeriodBreakdown] = useState(false);
    const periodsPerPage = 10;
    const graphPeriodsToShow = 20;
    const tableHeaders = ["Event", "Occurred At", "Date Received", "Points Raised"];

    useEffect(() => {
        RiskService.getEntityProfile(id, props.riskEntityController).then(profileResponse => {

            switch (props.riskEntityType) {
                case "Asset":
                    setEntityName(profileResponse.registration);
                    break;
                case "Employee":
                    setEntityName(profileResponse.employeeName);
                    break;
            }

            setEntityInfo(props.profileResponseMapper(profileResponse));

            setRiskPeriods(profileResponse.periods);
            setPeriodPages(Math.ceil(profileResponse.periods.length / periodsPerPage));

            var latestPeriods = HelperFunctions.sortDesc(profileResponse.periods, 'periodNumber');
            latestPeriods = latestPeriods.slice(0, graphPeriodsToShow);
            latestPeriods = latestPeriods.reverse();

            setGraphData(latestPeriods.map(p => p.score.total));
            setGraphPeriods(latestPeriods.map(p => p.periodNumber));

            setLoading(false);

        }, (error) => {
            const noAccessCode = 403;
            if (error.status === noAccessCode) {
                setInvalidEntity(true);
            }
        });

        RiskService.getGraphBands().then(function (bandsResponse) {
            setGraphBands(bandsResponse);
        });
    }, [id]);


    function onPeriodPageChange(pageNumber) {
        setCurrentPeriodPage(pageNumber);

        var clonedPeriods = [...riskPeriods];

        var startIndex = (pageNumber - 1) * periodsPerPage;
        var endIndex = startIndex + periodsPerPage;
        var currentPeriods = clonedPeriods.slice(startIndex, endIndex);
        setCurrentRiskPeriods(currentPeriods);
    }

    useEffect(function () {
        onPeriodPageChange(currentPeriodPage)
    }, [riskPeriods]);

    function onShowGraphRiskBandsChange() {
        setShowGraphBands(function (prev) {
            return !prev;
        });
    }

    function onPeriodExpand(periodNumber) {

        if (loadingPeriodBreakdown) {
            return;
        }

        var tempPeriods = [...riskPeriods];
        var selectedPeriod = tempPeriods.find(function (p) {
            return p.periodNumber === periodNumber;
        });

        if (selectedPeriod.scoreBreakdown == null) {
            //Go and fetch the breakdown
            fetchPeriodBreakdown(periodNumber);
        } 

        selectedPeriod.expanded = !selectedPeriod.expanded;
        setRiskPeriods(tempPeriods);
    }

    function fetchPeriodBreakdown(periodNumber) {

        setLoadingPeriodBreakdown(true);

        RiskService.getEntityPeriodBreakdown(id, periodNumber, props.riskEntityController)
            .then(
                (breakdownResponse) => mapPeriodBreakdown(breakdownResponse, periodNumber), 
                function () {
                    NotificationToaster.show(Intent.DANGER, "Could not load period breakdown, please try again");
                }
            ).finally(function () {
                setLoadingPeriodBreakdown(false);
            });

    }

    function mapPeriodBreakdown(breakdownResponse, periodNumber) {

        var selectedPeriodInfo = [
            {
                header: "Risk Score",
                value: <span className="inline-items"><h3>{breakdownResponse.totalScore}</h3></span>,
                key: 'risk-score'
            },
            {
                header: "Events Captured",
                value: breakdownResponse.eventsCaptured,
                key: 'captured-events'
            },
            {
                header: "Position in Location",
                value: `${breakdownResponse.location.position}/${breakdownResponse.location.totalPositions}`,
                key: 'position-location'
            },
            {
                header: "Position in Company",
                value: `${breakdownResponse.company.position}/${breakdownResponse.company.totalPositions}`,
                key: 'position-company'
            }
        ];

        var calculations = breakdownResponse.categories.map(function (area) {
            return [area.name, area.score];
        });

        calculations.push([<em key="period-points">Period points</em>, <em key="period-points-value">{breakdownResponse.periodScore}</em>]);
        calculations.push([<em key="period-points-carried">Points carried from previous periods</em>, <em key="period-points-carried-value">{breakdownResponse.carriedScore}</em>]);
        calculations.push([<strong key="period-points-total">Total points</strong>, <strong key="period-points-total-value">{breakdownResponse.totalScore}</strong>]);

        var areaBreakdowns = breakdownResponse.categories.map((area) => mapPeriodBreakdownArea(area, periodNumber));

        var tempPeriods = [...riskPeriods];
        var selectedPeriod = tempPeriods.find(function (p) {
            return p.periodNumber === periodNumber;
        });

        selectedPeriod.scoreBreakdown = {
            stats: selectedPeriodInfo,
            topLevelCalculations: calculations,
            areaBreakdown: areaBreakdowns
        };
        selectedPeriod.expanded = true;

        setRiskPeriods(tempPeriods);
    }

    function mapPeriodBreakdownArea(area, periodNumber) {

        var areaEvents = area.events.map(function (event) {
            return [
                <div key={`event-name-${event.id}`} className={classNames("inline-items", { "risk-event-suppressed": event.suppression?.suppressed === true })}>
                    <div className="inline-item">
                        <span className="risk-event-name">{event.name}</span> {event.suppression?.suppressed === true ? "(Suppressed)" : ""}
                    </div>
                    <div className="button-row-small inline-item">
                        <Tooltip content="Event information">
                            <Button onClick={() => viewEventInfo(event)} icon="info-sign" iconOnly={true} large={false} minimal={true}></Button>
                        </Tooltip>
                        <UserAccess
                            perform={"Risk:Manage"}
                            yes={() => (
                                <ShowHide
                                    evaluator={event.suppression?.suppressed === true}
                                    show={(
                                        <Tooltip content="Unsuppress event">
                                            <Button onClick={() => viewSuppressionInfo(event, periodNumber)} icon="clean" iconOnly={true} large={false} minimal={true}></Button>
                                        </Tooltip>

                                    )}
                                    hide={(
                                        <Tooltip content="Suppress event">
                                            <Button onClick={() => viewSuppressionInfo(event, periodNumber)} icon="disable" iconOnly={true} large={false} minimal={true}></Button>
                                        </Tooltip>
                                    )}
                                />
                            )}
                        />
                    </div>

                </div>
                ,
                <div key={`data-occurred-${event.id}`} className={classNames({ "risk-event-suppressed": event.suppression?.suppressed === true })}>
                    <DateToLocal>{event.occurredAt}</DateToLocal>
                </div>,
                <div key={`data-received-${event.id}`} className={classNames({ "risk-event-suppressed": event.suppression?.suppressed === true })}>
                    <DateToLocal>{event.receivedAt}</DateToLocal>
                </div>,
                <div key={`points-received-${event.id}`} className={classNames({ "risk-event-suppressed": event.suppression?.suppressed === true })}>
                    {event.points}
                </div>
            ];
        });

        if (areaEvents.length === 0) {
            areaEvents.push([<em key="events-non-raised">No events were raised for this category</em>, "", "", ""]);
        }

        areaEvents.push([<strong key="summary-total">Total points</strong>, "", "", <strong key="summary-total-value">{area.score}</strong>]);

        return {
            name: area.name,
            events: areaEvents
        };

    }

    function calculateRiskBand(value) {
        if (value === 0) {
            return "Very Low Risk";
        }

        var matchedBand = graphBands.find(function (band) {
            return (value > band[0].yAxis && value <= band[1].yAxis);
        });

        return matchedBand != null ? matchedBand[0].name : "";
    }

    function viewEventInfo(event) {
        setSelectedEventToView(event);
    }

    function viewSuppressionInfo(event, periodNumber) {
        setSelectedEventToSuppress(event);
        setIsUnsuppressing(event.suppression?.suppressed === true);
        setSuppressionPeriodNumber(periodNumber);
    }

    function onSuppressionInfoClose() {
        setSelectedEventToSuppress(null);
        setIsUnsuppressing(false);
        setSuppressionMessage("");
        setSuppressionPeriodNumber(null);
    }

    function onSuppressionMessageChange(e) {
        setSuppressionMessage(e.target.value);
    }

    function onEventSuppressSave() {
        setSavingSuppression(true);

        var message = {
            reason: suppressionMessage
        };

        if (isUnsuppressing) {
            RiskService.unsuppressEvent(selectedEventToSuppress.id, message).then(function () {
                NotificationToaster.show(Intent.SUCCESS, "Event unsuppressed successfully");
                onSuppressionInfoClose();
                fetchPeriodBreakdown(suppressionPeriodNumber);
            }, function () {
                NotificationToaster.show(Intent.DANGER, "Could not unsuppress event, please try again");
            }).finally(function () {
                setSavingSuppression(false);
            });
        } else {
            RiskService.suppressEvent(selectedEventToSuppress.id, message).then(function () {
                NotificationToaster.show(Intent.SUCCESS, "Event suppressed successfully");
                onSuppressionInfoClose();
                fetchPeriodBreakdown(suppressionPeriodNumber);
            }, function () {
                NotificationToaster.show(Intent.DANGER, "Could not suppress event, please try again");
            }).finally(function () {
                setSavingSuppression(false);
            });;
        }
    }

    function getEntityProfileLink() {
        switch (props.riskEntityType) {
            case "Asset":
                return `${window.env.VISION_URL}/#/Fleet/RedirectToProfile?assetId=${id}&tab=detailsTab`;
            case "Employee":
                return `${window.env.VISION_URL}/#/Drivers/RedirectToProfile?employeeId=${id}`;
            default:
                break;
        }

        return "";
    }

    return (
        <Fragment>
            <ShowHide
                evaluator={invalidEntity}
                show={(
                    <div className="row">
                        <Breadcrumbs items={props.breadcrumbs} />
                        <NotificationInline
                            allowClose={false}
                            show
                            text={`You do not have permission to view this ${props.riskEntityType.toLowerCase()}`}
                            intent="danger">
                        </NotificationInline>
                        <div>
                            <LinkButton 
                                intent="primary" 
                                text={`Back to ${props.riskEntityType.toLowerCase()} scores`} 
                                href={`/risk/${props.riskEntityType.toLowerCase()}`} 
                                id="return-risk-entity-listing" />
                        </div>
                    </div>
                )}
                hide={(

                    <div className="row">
                        <Breadcrumbs items={props.breadcrumbs} />
                        <FormHeading headingLevel="h1" loading={loading}>{entityName}</FormHeading>
                        <div className="button-row spacer-bottom">
                            <LinkButton
                                href={getEntityProfileLink()}
                                externalLink={true}
                                text="View profile"
                                loading={loading}
                                intent="primary"
                            />
                        </div>

                        <div className="spacer-bottom">
                            <DataDisplayTable
                                data={entityInfo}
                                displayInRows={false}
                                loading={loading}
                                columnsPerRow={entityInfo.length}
                            />
                        </div>

                        <ShowHide
                            evaluator={graphData.length > 1}
                            show={(
                                <div className="spacer-bottom">
                                    <h3>Risk Score Over Time</h3>
                                    <div className="inline-item spacer-bottom-small">
                                        <FormSwitch checked={showGraphBands} loading={loading} alignment={Alignment.RIGHT} inline label={"Show risk bands"} onChange={onShowGraphRiskBandsChange} />
                                    </div>
                                    <ReactECharts
                                        style={{ width: '100%' }}
                                        option={{
                                            color: [
                                                '#0C2235',
                                            ],
                                            tooltip: {
                                                trigger: 'axis',
                                                formatter: function (p) {
                                                    return `<strong>Period ${p[0].name}:</strong><br />${p[0].value} - ${calculateRiskBand(p[0].value)}`;
                                                },
                                                axisPointer: {
                                                    // Use axis to trigger tooltip
                                                    type: 'shadow' // 'shadow' as default; can also be 'line' or 'shadow'
                                                }
                                            },
                                            grid: {
                                                left: '0',
                                                right: '0',
                                                bottom: '5%',
                                                top: '5%',
                                                containLabel: true
                                            },
                                            xAxis: {
                                                name: 'Period',
                                                type: 'category',
                                                data: graphPeriods
                                            },
                                            yAxis: {
                                                type: 'value'
                                            },
                                            legend: {
                                                show: false
                                            },
                                            series: [
                                                {
                                                    name: 'Risk Score Over Time',
                                                    type: 'line',
                                                    data: graphData,
                                                    markArea: {
                                                        silent: true,
                                                        itemStyle: {
                                                            opacity: 0.3,
                                                        },
                                                        label: {
                                                            visible: true,
                                                            position: ['100%', '50%'],
                                                            align: 'right',
                                                            offset: [-10, -4]
                                                        },
                                                        symbol: 'none',
                                                        data: showGraphBands ? graphBands : []
                                                    }
                                                }
                                            ]
                                        }}
                                    />
                                </div>
                            )}
                        />

                        {
                            currentRiskPeriods && currentRiskPeriods.map(function (p, index) {
                                return (<Collapsable
                                    title={(
                                        <div className="inline-items">
                                            <div>Period {p.periodNumber}</div>
                                            <div className="inline-item">&nbsp;</div>
                                            <div className="inline-item">
                                                {HelperFunctions.formatDate(p.periodStartDate, "DD/MM/YYYY")} - {HelperFunctions.formatDate(p.periodEndDate, "DD/MM/YYYY")}
                                            </div>
                                        </div>
                                    )}
                                    key={p.periodNumber}
                                    fullWidth={true}
                                    expanded={p.expanded}
                                    onClick={() => onPeriodExpand(p.periodNumber)}
                                    metadata={(
                                        <div style={{ display: 'inline' }}>
                                            <div className="inline-items metadata-status">
                                                <ShowHide
                                                    evaluator={p.score.trend != null}
                                                    show={(
                                                        <div className="inline-item">
                                                            <TrendStatus trend={p.score.trend} />
                                                        </div>
                                                    )}
                                                />
                                                <div className="inline-item">
                                                    <StatusBlock bold background={p.score.bandColour}>{p.score.total} - {p.score.band}</StatusBlock>
                                                </div>

                                            </div>
                                        </div>

                                    )}
                                >

                                    <div className="collapsable-content">
                                        <DataDisplayTable
                                            data={p.scoreBreakdown == null ? [] : p.scoreBreakdown.stats}
                                            displayInRows={false}
                                            loading={p.scoreBreakdown == null}
                                            columnsPerRow={4}
                                        />

                                        <FormHeading headingLevel="h3" loading={p.scoreBreakdown == null}>Score Breakdown</FormHeading>

                                        <div className="spacer-bottom risk-breakdown-table-summary">
                                            <ListingTable
                                                pageable={false}
                                                headers={["Category", "Points Raised"]}
                                                data={p.scoreBreakdown?.topLevelCalculations}
                                                totalRecordCount={p.scoreBreakdown?.topLevelCalculations?.length}
                                                loadingData={p.scoreBreakdown == null}
                                            />
                                        </div>

                                        <FormHeading headingLevel="h3" loading={p.scoreBreakdown == null}>Events Received</FormHeading>

                                        {
                                            p.scoreBreakdown?.areaBreakdown && p.scoreBreakdown?.areaBreakdown.map(function (area) {
                                                return (
                                                    <div className="spacer risk-breakdown-table" key={area.name}>
                                                        <h4><strong>{area.name}</strong></h4>
                                                        <ListingTable
                                                            pageable={false}
                                                            headers={tableHeaders}
                                                            data={area.events}
                                                            totalRecordCount={1}
                                                            loadingData={p.scoreBreakdown == null || loadingPeriodBreakdown}
                                                        />
                                                        
                                                    </div>
                                                );
                                            })
                                        }

                                    </div>

                                </Collapsable>);
                            })
                        }


                        <ListingPagination currentPage={currentPeriodPage} onPageChange={onPeriodPageChange} totalPages={periodPages} loadingData={loading} />

                        <Modal
                            isOpen={selectedEventToSuppress != null}
                            title={"Event Suppression"}
                            clickOutsideClose={false}
                            onClose={onSuppressionInfoClose}
                        >

                            <NotificationInline allowClose={false} show text={`Suppressing an event will not instantly update the ${props.riskEntityType.toLowerCase()}'s risk score. Period scores and positions are recalculated overnight so you won't see a change in scores and positions until the next day.`} intent="warning" />

                            <ShowHide
                                evaluator={selectedEventToSuppress?.suppression != null}
                                show={(
                                    <div className="spacer-bottom">
                                        <p>This event was previously {isUnsuppressing ? "suppressed" : "suppressed then unsuppressed"} on <DateToLocal>{selectedEventToSuppress?.suppression?.date}</DateToLocal> by <strong>{selectedEventToSuppress?.suppression?.username}</strong> for the following reason:</p>
                                        <p>{selectedEventToSuppress?.suppression?.message}</p>
                                    </div>
                                )}
                            />
                            
                            
                            <div>
                                <FormTextArea headingText={isUnsuppressing ? "Reason for unsuppression" : "Reason for suppression"} value={suppressionMessage} loading={false} maxLength={1000} showCounter onChange={onSuppressionMessageChange} disabled={savingSuppression} />
                                <div className="button-row">
                                    <ButtonSave onClick={onEventSuppressSave} intent="primary" text={isUnsuppressing ? "Unsuppress Event" : "Suppress Event"} simpleDisabled={suppressionMessage.length < 5} disabled={savingSuppression} icon={isUnsuppressing ? "clean" : "disable"} />
                                    <Button onClick={onSuppressionInfoClose} text="Cancel" disabled={savingSuppression} />
                                </div>
                            </div>
                                
                            
                        </Modal>

                        <Modal
                            isOpen={selectedEventToView != null}
                            title={"Event Information"}
                            onClose={() => { setSelectedEventToView(null) }}
                        >

                            <div className="inline-items spacer-small align-items-flex-start">
                                <div className="inline-item"><p><strong>Event Name:</strong></p></div>
                                <div className="inline-item"><p>{selectedEventToView?.name}</p></div>
                            </div>

                            <div className="inline-items spacer-small align-items-flex-start">
                                <div className="inline-item"><p><strong>Description:</strong></p></div>
                                <div className="inline-item"><p>{selectedEventToView?.description}</p></div>
                            </div>

                            <ShowHide
                                evaluator={selectedEventToView?.suppression != null}
                                show={(
                                    <div>
                                        <hr />
                                        <p>This event was {selectedEventToView?.suppression?.suppressed ? "suppressed" : "suppressed then unsuppressed"} on <DateToLocal>{selectedEventToView?.suppression?.date}</DateToLocal> by <strong>{selectedEventToView?.suppression?.username}</strong> for the following reason:</p>
                                        <p>{selectedEventToView?.suppression?.message}</p>
                                    </div>
                                )}
                            />

                        </Modal>

                    </div>

                )}
            />
        </Fragment>
    );
}

RiskProfile.defaultProps = {
    riskEntityType: "Entity",
    searchInputPlaceholder: "Entity Search"
};

RiskProfile.propTypes = {
    title: PropTypes.string,
    riskEntityType: PropTypes.string,
    riskEntityController: PropTypes.string,
    profileResponseMapper: PropTypes.func,
    breadcrumbs: PropTypes.array
};
import classNames from 'classnames';
import { Button } from 'components/buttons';
import { FormReadonly } from 'components/form-fields/FormReadonly';
import { StatusHeader } from 'components/headers';
import { ShowHide } from 'components/layout';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { OcrNumberOfNumberLayout, OcrSelectLayout, OcrStringLayout, OcrDateRangeLayout, OcrTableLayout, OcrVarianceTableLayout, OcrIntegerLayout, OcrFixedPercentageRedGreen, OcrDateRangeEditableLayout } from './layout-types';
import { OcrDateLayout } from './layout-types/OcrDateLayout';
import { OcrTextareaLayout } from './layout-types/OcrTextareaLayout';

export function OcrLayout(props) {
    const [tableExpanded, setTableExpanded] = useState(false);

    const tableExpanderThreshold = 50;

    const tableLayoutTypes = [
        'TABLE',
        'VARIANCETABLE',
        'VARIANCETABLETWOROWSPERCENTAGE',
        'VARIANCETABLEPERCENTAGENONCOMPARATIVE'
    ]

    const booleanSelectValues = [
        {
            id: "true",
            name: "Yes"
        },
        {
            id: "false",
            name: "No"
        }
    ];

    const booleanNaSelectValues = [
        ...booleanSelectValues,
        {
            id: "na",
            name: "N/A"
        }
    ];

    const booleanEvidencedValues = [
        {
            id: "true",
            name: "Evidenced"
        },
        {
            id: "false",
            name: "Discussed"
        },
        {
            id: "na",
            name: "Unavailable"
        }
    ];

    const booleanEvidencedNaValues = [
        {
            id: "true",
            name: "Evidenced"
        },
        {
            id: "false",
            name: "Discussed"
        },
        {
            id: "Unavailable",
            name: "Unavailable"
        },
        {
            id: "na",
            name: "N/A"
        }
    ];

    const manualEntriesDropdown = [
        {
            id: "NO",
            name: "No"
        },
        {
            id: "BYSITE",
            name: "Entries made by site"
        },
        {
            id: "ANALOGUECHARTS",
            name: "Entries made using analogue charts"
        },
        {
            id: "LOGISTICSUK",
            name: "Entries made by Logistics UK"
        },
        {
            id: "MIXEDMETHODS",
            name: "Entries made using mixed methods"
        }
    ];

    const editableColumnTypes = [
        "DRIVERNAME",
        "DATEPAST",
        "BOOLEAN",
        "STRING",
        "INTEGER",
        "BOOLEANNA",
        "BOOLEANEVIDENCED",
        "BOOLEANEVIDENCEDNA"
    ];

    function renderLayoutType() {
        if (props.layout.layoutType) {
            switch (props.layout.layoutType.toUpperCase()) {
                case 'TABLE':
                    return <OcrTableLayout
                        layout={props.layout}
                        loading={props.loading}
                        onChange={props.onChange}
                        employeeList={props.employees}
                        assetList={props.assets}
                        disabled={props.disabled}
                        booleanSelectValues={booleanSelectValues}
                        booleanNaSelectValues={booleanNaSelectValues}
                        editableColumnTypes={editableColumnTypes}
                        getColumnTypeForCell={getColumnTypeForCell}
                        readonly={props.readonly}
                        tableExpanded={tableExpanded}
                        tableExpandable={tableIsExpandable()}
                        previsit={props.previsit}
                    />;
                case 'DATERANGE':
                    return <OcrDateRangeLayout
                        layout={props.layout}
                        loading={props.loading}
                        disabled={props.disabled}
                        readonly={props.readonly}
                    />
                case 'DATERANGEEDITABLE':
                    return <OcrDateRangeEditableLayout
                        layout={props.layout}
                        loading={props.loading}
                        disabled={props.disabled}
                        onChange={props.onChange}
                        readonly={props.readonly}
                    />
                case 'STRING':
                    return <OcrStringLayout
                        layout={props.layout}
                        loading={props.loading}
                        onChange={props.onChange}
                        disabled={props.disabled}
                        readonly={props.readonly}
                    />;
                case 'TEXTAREA':
                    return <OcrTextareaLayout
                        layout={props.layout}
                        loading={props.loading}
                        onChange={props.onChange}
                        disabled={props.disabled}
                        readonly={props.readonly}
                    />;
                case 'VARIANCETABLE':
                    return <OcrVarianceTableLayout
                        layout={props.layout}
                        loading={props.loading}
                        onChange={props.onChange}
                        disabled={props.disabled}
                        readonly={props.readonly}
                        tableExpanded={tableExpanded}
                        tableExpandable={tableIsExpandable()}
                    />;
                case 'VARIANCETABLETWOROWSPERCENTAGE':
                    return <OcrVarianceTableLayout
                        layout={props.layout}
                        loading={props.loading}
                        onChange={props.onChange}
                        disabled={props.disabled}
                        addPercentageRow={true}
                        readonly={props.readonly}
                        tableExpanded={tableExpanded}
                        tableExpandable={tableIsExpandable()}
                    />;
                case 'VARIANCETABLEPERCENTAGENONCOMPARATIVE':
                    return <OcrVarianceTableLayout
                        layout={props.layout}
                        loading={props.loading}
                        onChange={props.onChange}
                        disabled={props.disabled}
                        addPercentageRow={true}
                        readonly={props.readonly}
                        tableExpanded={tableExpanded}
                        tableExpandable={tableIsExpandable()}
                        showVariance={false}
                    />;
                case 'BOOLEAN':
                    return <OcrSelectLayout
                        layout={props.layout}
                        loading={props.loading}
                        onChange={props.onChange}
                        items={booleanSelectValues}
                        placeholder='Choose Yes or No'
                        disabled={props.disabled}
                        readonly={props.readonly}
                    />;
                case 'BOOLEANNA':
                    return <OcrSelectLayout
                        layout={props.layout}
                        loading={props.loading}
                        onChange={props.onChange}
                        items={booleanNaSelectValues}
                        placeholder='Choose Yes, No or N/A'
                        disabled={props.disabled}
                        readonly={props.readonly}
                    />;
                case 'BOOLEANEVIDENCED':
                    return <OcrSelectLayout
                        layout={props.layout}
                        loading={props.loading}
                        onChange={props.onChange}
                        items={booleanEvidencedValues}
                        placeholder='Choose Evidenced, Discussed or Unavailable'
                        disabled={props.disabled}
                        readonly={props.readonly}
                    />;
                case 'BOOLEANEVIDENCEDNA':
                    return <OcrSelectLayout
                        layout={props.layout}
                        loading={props.loading}
                        onChange={props.onChange}
                        items={booleanEvidencedNaValues}
                        placeholder='Choose Evidenced, Discussed, Unavailable or N/A'
                        disabled={props.disabled}
                        readonly={props.readonly}
                    />;
                case 'MANUALENTRIESDROPDOWN':
                    return <OcrSelectLayout
                        layout={props.layout}
                        loading={props.loading}
                        onChange={props.onChange}
                        items={manualEntriesDropdown}
                        disabled={props.disabled}
                        readonly={props.readonly}
                    />;
                case 'DATE':
                    return <OcrDateLayout
                        layout={props.layout}
                        loading={props.loading}
                        onChange={props.onChange}
                        disabled={props.disabled}
                        readonly={props.readonly}
                    />;
                case 'NUMBEROFNUMBER':
                    return <OcrNumberOfNumberLayout
                        layout={props.layout}
                        loading={props.loading}
                        onChange={props.onChange}
                        disabled={props.disabled}
                        readonly={props.readonly}
                        firstNumberHeading={props.layout?.table?.columns.length > 1 ? props.layout.table.columns[0]?.columnTitle : ''}
                        secondNumberHeading={props.layout?.table?.columns.length > 1 ? props.layout.table.columns[1]?.columnTitle : ''}
                    />;
                case 'FIXEDSTRING':
                    return <FormReadonly
                        headingText={props.layout.secondaryLayoutTitle ? props.layout.secondaryLayoutTitle : ''}
                        value={props.layout.answer}
                        loading={props.loading}
                        largerValueText={!props.layout.secondaryLayoutTitle}
                    />;
                case 'INTEGER':
                    return <OcrIntegerLayout
                        layout={props.layout}
                        loading={props.loading}
                        onChange={props.onChange}
                        disabled={props.disabled}
                        readonly={props.readonly}
                    />;
                case 'FIXEDPERCENTAGEREDGREEN':
                    return <OcrFixedPercentageRedGreen
                        layout={props.layout}
                        loading={props.loading}
                        onChange={props.onChange}
                        disabled={props.disabled}
                        readonly={props.readonly}
                    />;
            }
        }

        return <div>Layout not supported</div>;
    }

    function getColumnTypeForCell(cellIndex) {
        const columnTypes = [];

        for (let i = 0; i < props.layout.table.columns.length; i++) {
            const column = props.layout.table.columns[i];

            const colspan = column.colspan ? column.colspan : 1;

            for (let j = 0; j < colspan; j++) {
                columnTypes.push(column.columnType);
            }
        }

        return columnTypes[cellIndex];
    }

    function expandTable() {
        setTableExpanded(!tableExpanded);
    }

    function tableIsExpandable() {
        const layoutType = props.layout.layoutType?.toUpperCase();

        return tableLayoutTypes.includes(layoutType) && props.layout?.table?.rows?.length > tableExpanderThreshold;
    }

    return <div className='spacer-bottom print-page-break-avoid'>
        <ShowHide
            evaluator={!!props.layout.layoutTitle}
            show={(
                <div className={classNames({ 'inline-items spacer-bottom-small  inline-items-space': tableIsExpandable() })} >
                    <StatusHeader
                        text={props.layout.layoutTitle}
                        status={props.status}
                        showStatus={props.layout.businessLogicRequired}
                        secondaryHeader={true}
                        tooltip={props.statusTooltip}
                        className={classNames({ 'inline-item': tableIsExpandable() })}
                    />
                    <ShowHide
                        evaluator={tableIsExpandable()}
                        show={<Button
                            text={tableExpanded ? 'Collapse table' : 'Expand table'}
                            intent="primary"
                            className='inline-item'
                            icon={tableExpanded ? "minimize" : "maximize"}
                            onClick={expandTable}
                        />}
                    />
                </div>
            )}
        />
        {renderLayoutType()}
    </div>;
}

OcrLayout.propTypes = {
    loading: PropTypes.bool,
    layout: PropTypes.object.isRequired,
    status: PropTypes.string.isRequired,
    statusTooltip: PropTypes.string.isRequired,
    onChange: PropTypes.func.isRequired,
    employees: PropTypes.array.isRequired,
    assets: PropTypes.array.isRequired,
    disabled: PropTypes.bool,
    readonly: PropTypes.bool,
    previsit: PropTypes.object
};

OcrLayout.defaultProps = {
    loading: false,
    disabled: false,
    readonly: false,
    previsit: {}
};

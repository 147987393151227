import React from 'react';
import { Route } from 'react-router-dom';
import store from 'index';
import { PageRestricted } from 'pages/errors/page-restricted';

export function AuthenticatedRoute(props) {
    function checkPermissions() {
        const userActions = store.getState()?.loggedInUser?.actions;
        let userHasAccess = false;

        if (props.action == null) {
            userHasAccess = true;
        }
        else if (userActions != null) {
            const allowedActions = userActions.map((a) => {
                return a.toLowerCase();
            });

            //If the action is an array of actions, check we have one of the roles
            if (Array.isArray(props.action)) {
                const matchedActions = props.action.filter(a => allowedActions.includes(a.toLowerCase()));
                userHasAccess = matchedActions.length > 0;
            } else {
                userHasAccess = allowedActions.includes(props.action.toLowerCase());
            }
        }

        return userHasAccess ? props.children : <PageRestricted />;
    }

    return (
        <Route {...props}>
            {
                checkPermissions()
            }
        </Route>
    );
}
import React, { useState } from 'react';

import { FormCheckbox, FormHelperText, FormTextInput } from 'components/form-fields';
import { ShowHide } from "components/layout";
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { TagList } from 'components/status';

export function FormCheckboxList(props) {

    const [searchTerm, setSearchTerm] = useState("");

    function onItemChange(event, id) {
        props.onChange(event, id);
    }

    function onSearchChange(item) {
        setSearchTerm(item.target.value);
    }

    function onRemoveItem(event, id) {
        props.onChange(event, id);
    }


    return (
        <div className={classNames("form-field checkbox-list", { "grouped": props.grouped, "striped": props.striped })}>
            <ShowHide
                evaluator={props.headingText != null}
                show={(
                    <h4 className={classNames({ "bp3-skeleton": props.loading })}>{props.headingText}</h4>
                )}
            />
            <ShowHide
                evaluator={props.showTags && props.items && props.items.length > 0}
                show={(
                    <TagList items={props.items == null ? [] :
                         props.items.filter(function (item) {
                            return item.checked;
                        })} disabled={props.disabled} onRemoveTag={onRemoveItem} />
                )}
            />
           
            <ShowHide
                evaluator={props.searchable}
                show={(
                    <div className="chechbox-list-search">
                        <FormTextInput placeholder="Search" onChange={onSearchChange} value={searchTerm} large disabled={props.disabled} icon="search" />
                    </div>
                )}
            />
            <div className="checkbox-list-container">
                {props.items && props.items.length > 0 && props.items.filter(function (item) {

                    if (searchTerm.length === 0) {
                        return true;
                    }

                    return item.name.toLowerCase().indexOf(searchTerm.toLowerCase()) > -1;

                }).map((item) => {
                    return (
                        <FormCheckbox
                            checked={item.checked}
                            label={item.name}
                            key={item.id}
                            identifier={item.id}
                            onChange={onItemChange}
                            loading={props.loading}
                            disabled={item.disabled}
                        />
                    )
                })}
            </div>
            <FormHelperText danger={true}>{props.dangerHelperText}</FormHelperText>
        </div>
    );
}

FormCheckboxList.defaultProps = {
    id: null,
    items: [],
    disabled: false,
    loading: false,
    headingText: null,
    dangerHelperText: "",
    grouped: false,
    striped: false,
    searchable: false,
    showTags: false
};

FormCheckboxList.propTypes = {
    id: PropTypes.string,
    items: PropTypes.array,
    loading: PropTypes.bool,
    headingText: PropTypes.string,
    disabled: PropTypes.bool,
    onChange: PropTypes.func.isRequired,
    dangerHelperText: PropTypes.string,
    grouped: PropTypes.bool,
    striped: PropTypes.bool,
    searchable: PropTypes.bool,
    showTags: PropTypes.bool
};
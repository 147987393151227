import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { Tag, Intent } from "@blueprintjs/core";
import './Status.css';

export function ActiveStatus(props) {
            
    return (
        <Tag
            className={classNames('active-status', { "bp3-skeleton": props.loading })}
            intent={props.isActive ? Intent.SUCCESS : Intent.DANGER}
        >
            {props.isActive ? props.activeText : props.inactiveText}
        </Tag>
    );    
}

ActiveStatus.defaultProps = {
    isActive: true,
    loading: false,
    activeText: 'Active',
    inactiveText: 'Inactive'
};

ActiveStatus.propTypes = {
    isActive: PropTypes.bool,    
    loading: PropTypes.bool,
    activeText: PropTypes.string,
    inactiveText: PropTypes.string
};
import React, { useState, useEffect, Fragment } from 'react';
import { Intent } from '@blueprintjs/core';

import { ListingTable } from 'components/listing';
import { Button, ButtonSave } from 'components/buttons';
import { UserAccess, UserHasAccess } from 'components/user-access';
import { FormTextInput } from 'components/form-fields';
import { AlertConfirm, Modal, NotificationToaster } from 'components/notifications';
import { ShowHide } from 'components/layout';

import { PageRestricted } from 'pages/errors/page-restricted';
import { JourneyTypesService } from 'services';

export function JourneyTypes(props) {
    const requiredAction = "TachoAlerts:Manage";
    const canAccessPage = UserHasAccess(requiredAction);

    const [rawJourneyTypes, setRawJourneyTypes] = useState([]);
    const pageable = false;
    const [totalRecords, setTotalRecords] = useState(0);
    const [tableRows, setTableRows] = useState([]);
    const [isLoadingData, setIsLoadingData] = useState(true);
    const noDataMessage = "There are no journey types, please click 'Add new journey type' to get started.";
    const tableHeaders = ["Journey Type", "Actions"];

    const [journeyTypeIdToModify, setJourneyIdTypeToModify] = useState(0);
    const [showAddJourneyTypeModal, setShowAddJourneyTypeModal] = useState(false)
    const [showDeleteJourneyTypeModal, setShowDeleteJourneyTypeModal] = useState(false)
    const [showEditJourneyTypeModal, setShowEditJourneyTypeModal] = useState(false)
    const [isSaving, setIsSaving] = useState(false);
    const [journeyTypeName, setJourneyTypeName] = useState("");
    const [addOrEditModalTitle, setAddOrEditModalTitle] = useState("Add Journey Type");
    
    useEffect(fetchTableData, [])

    function fetchTableData() {
        if (!canAccessPage) {
            return;
        }

        JourneyTypesService.getAllJourneyTypes().then(
            (journeyTypesResponse) => {
                setRawJourneyTypes(journeyTypesResponse);
            },
            () => {
                NotificationToaster.show(Intent.DANGER, "Could not fetch journey types, please try again.");  
            }
        );
    }

    useEffect(mapTableData, [rawJourneyTypes])

    function mapTableData() {
        let data = [];
        
        data = rawJourneyTypes.map((j) => {
            return [
                j.journeyName,
                <div key={j.journeyTypeID} className="button-row-small">
                    <Button 
                        onClick={() => onShowEditJourneyTypeModal(j.journeyTypeID)} 
                        icon="edit" 
                        iconOnly
                        text="Update Journey Type" 
                        large={false} 
                        minimal
                    ></Button>
                    <Button 
                        onClick={() => onShowDeleteJourneyTypeModal(j.journeyTypeID)} 
                        icon="trash" 
                        iconOnly 
                        text="Delete Journey Type" 
                        large={false} 
                        minimal
                    ></Button>
                </div>
            ];
        });

        setIsLoadingData(false);
        setTableRows(data);            
        setTotalRecords(data.length);  
        setIsLoadingData(false);
    }

    function onShowAddJourneyTypeModal() {
        setAddOrEditModalTitle('Add Journey Type');
        setShowAddJourneyTypeModal(true);
    }

    function onShowEditJourneyTypeModal(journeyTypeId) {
        const journeyTypeToEdit = rawJourneyTypes.filter(j => j.journeyTypeID === journeyTypeId)[0]

        if (!journeyTypeToEdit) {
            NotificationToaster.show(Intent.DANGER, "Journey type not be found.");
            return;
        }

        setJourneyIdTypeToModify(journeyTypeId);
        setJourneyTypeName(journeyTypeToEdit.journeyName);
        setAddOrEditModalTitle('Edit Journey Type');
        setShowEditJourneyTypeModal(true);
    }

    function onJourneyNameChange(event) {
        setJourneyTypeName(event.target.value);
    }

    function addJourneyType() {
        setIsSaving(true);

        const journeyType = { 
            journeyName: journeyTypeName 
        };
        
        JourneyTypesService.createJourneyTypes(journeyType).then(
            () => {
                fetchTableData();
                NotificationToaster.show(Intent.SUCCESS, "Journey type has been added successfully.");
                
                resetJourneyTypeToModifyVariables();
                hideAddOrEditModal();
                setIsSaving(false);
            },
            (error) => {
                setIsSaving(false);

                const badRequestCode = 400;
                if (error.status === badRequestCode) {
                    NotificationToaster.show(Intent.WARNING, error.data);
                } else {
                    NotificationToaster.show(Intent.DANGER, "Could not create journey type. Please try again.");
                }
            } 
        )


    }

    function editJourneyType() {
        setIsSaving(true);

        const journeyTypeToEdit = rawJourneyTypes.filter(j => j.journeyTypeID === journeyTypeIdToModify)[0]

        if (journeyTypeToEdit) {
            journeyTypeToEdit.journeyName = journeyTypeName;
        }

        JourneyTypesService.updateJourneyTypes(journeyTypeIdToModify, journeyTypeToEdit).then(
            () => {
                fetchTableData();
                NotificationToaster.show(Intent.SUCCESS, "Journey type has been updated successfully.");

                resetJourneyTypeToModifyVariables();
                hideAddOrEditModal();
                setIsSaving(false);
            },
            (error) => {
                setIsSaving(false);

                const badRequestCode = 400;
                if (error.status === badRequestCode) {
                    NotificationToaster.show(Intent.WARNING, error.data);
                } else {
                    NotificationToaster.show(Intent.DANGER, "Could not create journey type. Please try again.");
                }
            }
        )
    }

    function onAddOrEditJourneyTypeCancel() {
        setShowAddJourneyTypeModal(false);
        setShowEditJourneyTypeModal(false);
        setJourneyIdTypeToModify(0);
    }

    function onShowDeleteJourneyTypeModal(journeyTypeId) {
        setJourneyIdTypeToModify(journeyTypeId);
        setShowDeleteJourneyTypeModal(true);
    }

    function deleteJourneyType() {
        setIsSaving(true);

        JourneyTypesService.deleteJourneyTypes(journeyTypeIdToModify).then(
            () => {
                fetchTableData();
                NotificationToaster.show(Intent.SUCCESS, "Journey type has been deleted successfully.");

                resetJourneyTypeToModifyVariables();
                setShowDeleteJourneyTypeModal(false);
                setIsSaving(false);
            },
            (error) => {
                setIsSaving(false);

                const badRequestCode = 400;
                if (error.status === badRequestCode) {
                    NotificationToaster.show(Intent.WARNING, error.data);
                } else {
                    NotificationToaster.show(Intent.DANGER, "Could not create journey type. Please try again.");
                } 
            }
        )
    }

    function onDeleteJourneyTypeCancel() {
        setShowDeleteJourneyTypeModal(false);
        setJourneyIdTypeToModify(0);
    }

    function resetJourneyTypeToModifyVariables() {
        setJourneyIdTypeToModify(0);
        setJourneyTypeName('');
    }

    function hideAddOrEditModal() {
        setShowAddJourneyTypeModal(false);
        setShowEditJourneyTypeModal(false);
    }

    return (
        <UserAccess perform={requiredAction}
            yes={() => (
                <Fragment>

                    <div className="spacer-bottom">
                        <Button id="new-journey-type" intent="primary" text="Add new journey type" onClick={onShowAddJourneyTypeModal}/>
                    </div>

                    <ListingTable
                        id="listing-table-location"
                        headers={tableHeaders}
                        data={tableRows}
                        totalRecordCount={totalRecords}
                        loadingData={isLoadingData}
                        noDataMessage={noDataMessage}
                        pageable={pageable}
                        shrinkLastColumn={true}
                    />

                    <Modal
                        isOpen={showAddJourneyTypeModal || showEditJourneyTypeModal}
                        title={addOrEditModalTitle}
                        onClose={onAddOrEditJourneyTypeCancel}
                    >

                        <div className="bp3-dialog-body">
                            <p>Enter the name of your journey type:</p>
                            <FormTextInput
                                value={journeyTypeName}
                                onChange={onJourneyNameChange}
                            />
                        </div>

                        <div className="bp3-dialog-footer">
                            <ShowHide 
                                evaluator={!showAddJourneyTypeModal}
                                hide={(
                                    <div className="bp3-dialog-footer-actions">
                                        <Button 
                                            text="Cancel" 
                                            intent="danger" 
                                            disabled={isSaving} 
                                            onClick={onAddOrEditJourneyTypeCancel}
                                        ></Button>
                                        <ButtonSave
                                            id="journey-type-save"
                                            onClick={addJourneyType}
                                            disabled={isSaving}
                                            loading={isLoadingData}
                                            simpleDisabled={journeyTypeName.trim().length === 0}
                                        ></ButtonSave>
                                    </div>
                                )}
                            />
                            <ShowHide 
                                evaluator={!showEditJourneyTypeModal}
                                hide={(
                                    <div className="bp3-dialog-footer-actions">
                                        <Button 
                                            text="Cancel" 
                                            intent="danger" 
                                            disabled={isSaving} 
                                            onClick={onAddOrEditJourneyTypeCancel}
                                        ></Button>
                                        <ButtonSave
                                            id="journey-type-edit"
                                            onClick={editJourneyType}
                                            disabled={isSaving}
                                            loading={isLoadingData}
                                            simpleDisabled={journeyTypeName.trim().length === 0}
                                        ></ButtonSave>
                                    </div>
                                )}
                            />
                        </div>


                    </Modal>

                    <AlertConfirm
                        isOpen={showDeleteJourneyTypeModal}
                        title={"Delete Preset?"}
                        onConfirm={deleteJourneyType}
                        onCancel={onDeleteJourneyTypeCancel}
                    >
                        <p>Are you sure you want to delete this journey type?</p>
                    </AlertConfirm>

                </Fragment>
            )}
            no={() => (
                <div className="row">
                    <PageRestricted />
                </div>
            )}
        />
    );
}
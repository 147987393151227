import React, { Fragment } from "react";
import PropTypes from 'prop-types';
import { ReoccurrenceSettings } from "components/settings";
import { FormRadio } from "components/form-fields";

export function EmployeeAssessmentBandSettings(props) {
    function onReoccurrenceChange(item) {
        const updatedBandSettings = {
            ...props.bandSettings,
            reoccurrenceSchedule: item
        }

        props.onBandSettingsUpdate(updatedBandSettings);
    }

    function onAlertTypeChange(item) {
        const updatedBandSettings = {
            ...props.bandSettings,
            alertType: item.target.value
        }

        props.onBandSettingsUpdate(updatedBandSettings);
    }

    return (<Fragment>
        <ReoccurrenceSettings
            loading={props.loading}
            reoccurrenceSettings={props.bandSettings.reoccurrenceSchedule}
            onReoccurrenceUpdate={onReoccurrenceChange}
            hideEndDates={true}
            hide445={true}
            onValidationUpdate={props.onValidationUpdate}
            name="employee assessments"
            disabled={props.disabled}
            reoccurrenceToggleText="Would you like your employee assessments to generate automatically?"
            reoccurrenceFrequencyHelperText="When an employee assessment is completed, the next assessment will be generated using the interval given below."
        ></ReoccurrenceSettings>
        <FormRadio
            selectedValue={props.bandSettings.alertType}
            loading={props.loading}
            options={props.alertTypes}
            onChange={onAlertTypeChange}
            disabled={props.disabled}
            headingText="How would you like to send alerts for your employee assessments?"
            helperText="Notification alerts will be generated in Vision and these can be confirmed once reviewed by a relevant Vision user. Sign Off alerts will be generated in Vision and can only be removed once sign off is completed by a Vision user with the relevant action."
        />
    </Fragment>);
}

EmployeeAssessmentBandSettings.defaultProps = {
    disabled: false,
    loading: false,
};

EmployeeAssessmentBandSettings.propTypes = {
    bandSettings: PropTypes.object.isRequired,
    disabled: PropTypes.bool,
    loading: PropTypes.bool,
    onBandSettingsUpdate: PropTypes.func.isRequired,
    onValidationUpdate: PropTypes.func.isRequired,
    alertTypes: PropTypes.array.isRequired
};
import { Intent } from "@blueprintjs/core";
import { ButtonSave } from "components/buttons";
import { HorizontalLinkGroup } from "components/navigation";
import { AlertUnsavedData, NotificationInline, NotificationToaster } from "components/notifications";
import { UserAccess } from "components/user-access";
import { PageRestricted } from "pages/errors/page-restricted";
import React, { Fragment } from "react";
import { Redirect, Route, Switch } from "react-router-dom";
import { EmployeeAssessmentBandSettings } from "../shared/band-settings";
import { EmployeeAssessmentGeneralSettings } from "../shared/general-settings";
import PropTypes from 'prop-types';

export function EmployeeAssessmentSettingsEdit(props) {
    const routes = [
        { location: "/employee-settings/assessments/general-settings", title: "General Settings" },
        { location: "/employee-settings/assessments/green-band-settings", title: "Green Band Settings" },
        { location: "/employee-settings/assessments/amber-band-settings", title: "Amber Band Settings" },
        { location: "/employee-settings/assessments/red-band-settings", title: "Red Band Settings" }
    ];

    function saveSettings() {
        if (!props.isValid) {
            NotificationToaster.show(Intent.WARNING, "There are errors on the page which need to be resolved before the settings can be saved.");
            return;
        }

        props.onSave();
    }

    return (
        <UserAccess perform={["EmployeeAssessment:Manage"]}
            yes={() => (
                <div className="row">
                    <h1>Employee Assessment Settings</h1>
                    <div className="intro-text">
                        <p>In this section you can configure your employee assessment settings.</p>
                    </div>
                    <div className="spacer-bottom">
                        <HorizontalLinkGroup links={routes}></HorizontalLinkGroup>
                    </div>
                    <Switch>
                        <Route path="/employee-settings/assessments/general-settings">
                            <EmployeeAssessmentGeneralSettings
                                loading={props.loading}
                                disabled={props.saving}
                                generalSettings={props.employeeAssessmentSettings}
                                employeeTypes={props.employeeTypes}
                                employeeCategories={props.employeeCategories}
                                onGeneralSettingsUpdate={props.onGeneralSettingsUpdate}
                                onValidationUpdate={props.onValidationUpdate}
                            />
                        </Route>
                        <Route path="/employee-settings/assessments/green-band-settings">
                            <EmployeeAssessmentBandSettings
                                loading={props.loading}
                                disabled={props.saving}
                                bandSettings={props.employeeAssessmentSettings.greenBandSettings}
                                alertTypes={props.alertTypes}
                                onBandSettingsUpdate={(settings) => props.onBandSettingsUpdate(settings, 'greenBandSettings')}
                                onValidationUpdate={props.onValidationUpdate}
                            />
                        </Route>
                        <Route path="/employee-settings/assessments/amber-band-settings">
                            <EmployeeAssessmentBandSettings
                                loading={props.loading}
                                disabled={props.saving}
                                bandSettings={props.employeeAssessmentSettings.amberBandSettings}
                                alertTypes={props.alertTypes}
                                onBandSettingsUpdate={(settings) => props.onBandSettingsUpdate(settings, 'amberBandSettings')}
                                onValidationUpdate={props.onValidationUpdate}
                            />
                        </Route>
                        <Route path="/employee-settings/assessments/red-band-settings">
                            <EmployeeAssessmentBandSettings
                                loading={props.loading}
                                disabled={props.saving}
                                bandSettings={props.employeeAssessmentSettings.redBandSettings}
                                alertTypes={props.alertTypes}
                                onBandSettingsUpdate={(settings) => props.onBandSettingsUpdate(settings, 'redBandSettings')}
                                onValidationUpdate={props.onValidationUpdate}
                            />
                        </Route>
                        <Route exact path="/employee-settings/assessments" render={() => (<Redirect to="/employee-settings/assessments/general-settings" />)} />
                    </Switch>
                    <Fragment>
                        <NotificationInline
                            id="notification-employee-assessments"
                            loading={props.loading}
                            show={true}
                            text="Once saved Vision will begin re-scheduling employee assessments based on the last completed assessment date."
                            intent="info">
                        </NotificationInline>
                        <ButtonSave
                            loading={props.loading}
                            onClick={saveSettings}
                            disabled={props.saving}
                        ></ButtonSave>
                    </Fragment>
                    <AlertUnsavedData
                        isDirty={props.isDataDirty}
                        on
                    ></AlertUnsavedData>
                </div>
            )}
            no={() => (
                <PageRestricted />
            )}
        />
    );
}

EmployeeAssessmentSettingsEdit.propTypes = {
    employeeAssessmentSettings: PropTypes.object.isRequired,
    saving: PropTypes.bool,
    loading: PropTypes.bool,
    employeeTypes: PropTypes.array.isRequired,
    employeeCategories: PropTypes.array.isRequired,
    alertTypes: PropTypes.array.isRequired,
    onGeneralSettingsUpdate: PropTypes.func.isRequired,
    onBandSettingsUpdate: PropTypes.func.isRequired,
    onValidationUpdate: PropTypes.func.isRequired,
    onSave: PropTypes.func.isRequired,
    breadcrumbs: PropTypes.array.isRequired,
    isDataDirty: PropTypes.bool.isRequired,
    isValid: PropTypes.bool.isRequired
};
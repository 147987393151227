import React, { Fragment, useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';

import { Breadcrumbs } from 'components/navigation';
import { ShowHide } from 'components/layout';
import { UserAccess, UserHasAccess } from 'components/user-access';
import { PageRestricted } from 'pages/errors/page-restricted';
import { Tree, Intent } from '@blueprintjs/core';
import { ListingTable } from 'components/listing';
import { AssetService, EmployeeService } from "services";
import { NotificationToaster } from "components/notifications";
import { Button } from 'components/buttons';
import { FormFileInput, FormSelect } from "components/form-fields";
import moment from "moment";

export function TachoFileViewer(props) {

    const requiredAction = "DCVU:Import";
    const [menu, setMenu] = useState([]);
    const [entityType, setEntityType] = useState({});
    const [currentSection, setCurrentSection] = useState([]);
    const [dropDownValues, setDropDownValues] = useState([]);
    const [dropDownTitle, setDropDownTitle] = useState("");
    const [dropDownSelected, setDropDownSelected] = useState({});
    const [dropDownContents, setDropDownContents] = useState({});
    const [fileCracked, setFileCracked] = useState(false);
    const [fileText, setFileText] = useState('Choose file')
    const [loadingData, setLoadingData] = useState(false);
    const [vuData, setVuData] = useState({});
    const [dcData, setDcData] = useState({});

    const dispatch = useDispatch();
    const internalPermission = UserHasAccess("Internal");
    const restrictedSections = ["MemberStateCertificate", "VUCertificate", "Signature"];
    const entities = [
        { id: "asset", name: "Vehicle Unit File" },
        { id: "employee", name: "Driver Card" }
    ]
    const dateFormat = "DD/MM/YYYY";
    const longDateFormat = "DD/MM/YYYY HH:mm:ss";
    const hour23 = 23;
    const secondMinute59 = 59;
    const regexTitleMatch = /[A-Z][a-z]+|\d+/g;

    useEffect(function () {
        dispatch({ type: 'SITE_FULL_WIDTH' });
    }, []);

    useEffect(() => {
        if (!fileCracked || !vuData) {
            return;
        }

        let menuStructure = [];
        let gen = [];
        let title = "Vehicle Unit File - "

        if (vuData.CardStructureGenerationType === 0 && vuData.Generation1) {
            gen = mapMenuItem(vuData.Generation1, 2);
            title = title + "Gen 1";
        } else if (vuData.CardStructureGenerationType === 1 && vuData.CardStructureVersionType === 0 && vuData.Generation2) {
            gen = mapMenuItem(vuData.Generation2, 2);
            title = title + "Gen 2 Ver 1";
        } else if (vuData.CardStructureGenerationType === 1 && vuData.CardStructureVersionType === 1 && vuData.Generation2Version2) {
            gen = mapMenuItem(vuData.Generation2Version2, 2);
            title = title + "Gen 2 Ver 2";
        }

        menuStructure.push({
            id: `menu-item-1-0`,
            hasCaret: true,
            icon: "folder-close",
            label: title,
            isExpanded: false,
            childNodes: gen,
            containsChildArrays: true
        });

        if (internalPermission) {
            let sanitised = mapMenuItem(vuData.SanitisedVehicleUnit, 2);

            menuStructure.push({
                id: `menu-item-1-1`,
                hasCaret: true,
                icon: "folder-close",
                label: "Sanitised Data",
                isExpanded: false,
                childNodes: sanitised,
                containsChildArrays: true
            });
        }

        if (vuData.CorruptedSections && vuData.CorruptedSections.length > 0) {
            menuStructure.push({
                id: `menu-item-1-2`,
                hasCaret: false,
                icon: "list",
                label: "Corrupted Sections",
                isExpanded: false,
                childNodes: [],
                nodeData: vuData.CorruptedSections,
                containsChildArrays: false
            });
        }

        setMenu([{
            id: 0,
            hasCaret: true,
            icon: "folder-close",
            label: "Vehicle Unit",
            isExpanded: true,
            childNodes: menuStructure,
            containsChildArrays: true
        }]);
    }, [vuData]);

    useEffect(() => {
        if (!fileCracked || !dcData) {
            return;
        }

        let menuStructure = [];
        let gen = [];
        let title = "Driver Card - "

        if (dcData.Generation1.ApplicationIdentification) {
            gen = mapMenuItem(dcData.Generation1, 2);
            title = title + "Gen 1";
        } else if (dcData.Generation2.ApplicationIdentification) {
            gen = mapMenuItem(dcData.Generation2, 2);
            title = title + "Gen 2";
        }

        menuStructure.push({
            id: `menu-item-1-0`,
            hasCaret: true,
            icon: "folder-close",
            label: title,
            isExpanded: false,
            childNodes: gen,
            containsChildArrays: true
        });

        setMenu([{
            id: 0,
            hasCaret: true,
            icon: "folder-close",
            label: "Vehicle Unit",
            isExpanded: true,
            childNodes: menuStructure,
            containsChildArrays: true
        }]);
    }, [dcData]);

    useEffect(() => {
        if (dropDownSelected && dropDownSelected.id >= 0) {
            var contents = dropDownContents[dropDownSelected.id];
            var standardHeaders = ["Name", "Value"];
            var standardValues = [];
            var additionalTables = [];

            mapValues(contents, standardValues, additionalTables, "");

            if (standardValues.length === 0) {
                setCurrentSection(additionalTables);
            } else {
                setCurrentSection([[standardHeaders, standardValues], ...additionalTables]);
            }
        }

    }, [dropDownSelected]);

    function displaySection(node) {
        if (node.containsChildArrays) {
            //If the object has arrays under it then we don't want to allow them to click on the top level
            if (node.isExpanded) {
                collapseSection(node);
            } else {
                expandSection(node);
            }

            return;
        }

        setDropDownValues([]);
        setDropDownSelected({});

        var contents = node.nodeData;
        var standardHeaders = ["Name", "Value"];
        var standardValues = [];
        var additionalTables = [];

        mapValues(contents, standardValues, additionalTables, node.label);

        if (standardValues.length === 0) {
            setCurrentSection(additionalTables);
        } else {
            setCurrentSection([[standardHeaders, standardValues], ...additionalTables]);
        }
    }

    var isDate = function (date) {
        return moment(date, longDateFormat).isValid();
    }

    var formatDateforDropdown = function (date) {
        let momentDt = moment(date, longDateFormat);

        if ((momentDt.hours() === hour23 && momentDt.minutes() === secondMinute59 && momentDt.seconds() === secondMinute59) ||
            (momentDt.hours() === 0 && momentDt.minutes() === 0 && momentDt.seconds() === 0)) {
            return momentDt.format(dateFormat);
        } 
        return momentDt.format(longDateFormat);
    }

    function mapArray(contents, additionalTables) {
        let first = contents[0];
        let isString = typeof first === 'string';
        let containsArray = false;

        if (!isString) {
            for (const prop in first) {
                if (Array.isArray(first[prop])) {
                    containsArray = true;
                    break;
                }
            }
        }

        if (containsArray) {
            let title = "Row Number"
            let dateList = [];
            let dropdownList = [];

            //Find first date field
            for (const prop2 in first) {
                if (typeof first[prop2] === 'string' && isDate(first[prop2])) {
                    title = friendlyTitle(prop2);
                    dateList = contents.map(c => {
                        return c[prop2];
                    });
                    break;
                }
            }

            for (var i = 0; i < contents.length; i++) {
                let elementName = i + 1;
                if (dateList.length >= i) {
                    elementName = formatDateforDropdown(dateList[i]);
                }
                let searchElement = { id: i, name: elementName }
                dropdownList.push(searchElement);
            }

            setDropDownTitle(title);
            setDropDownValues(dropdownList);
            setDropDownContents(contents);
        }
        else {
            var tableHeaders = ["Row"];
            var tableValues = [];

            if (isString) {
                tableHeaders.push("Value")
            }
            else {
                mapValueHeaders(first, tableHeaders);
            }

            for (var j = 0; j < contents.length; j++) {

                var row = [j + 1];

                if (isString) {
                    row.push(contents[j]);
                }
                else {
                    mapValueValues(contents[j], row);
                }
                tableValues.push(row);
            }

            additionalTables.push([tableHeaders, tableValues]);
        }
    }

    function mapValues(contents, standardValues, additionalTables, label) {
        if (typeof contents == 'string') {
            standardValues.push([label, contents]);
            return;
        }

        if (Array.isArray(contents)) {
            if (contents.length < 1) {
                standardValues.push([label, "No entries"]);
                return;
            }

            mapArray(contents, additionalTables);

            return;
        }

        if (typeof contents == 'object') {
            for (const property in contents) {
                if (!restrictedSections.find(r => r === property)) {
                    var value = contents[property];
                    mapValues(value, standardValues, additionalTables, friendlyTitle(property));
                }
            }
        }
    }

    function mapValueHeaders(contents, headers, containerFriendlyTitle) {
        for (const property in contents) {
            if (!restrictedSections.find(r => r === property)){
                var value = contents[property];

                let prefix = '';

                if (containerFriendlyTitle && containerFriendlyTitle.length > 0) {
                    prefix = containerFriendlyTitle + ' - ';
                }

                if (typeof value == 'object') {
                    mapValueHeaders(value, headers, prefix + friendlyTitle(property));
                    continue;
                }

                headers.push(prefix + friendlyTitle(property));
            }
        }
    }

    function mapValueValues(contents, values) {
        for (const property in contents) {
            if (!restrictedSections.find(r => r === property)) {
                var value = contents[property];

                if (typeof value == 'object') {
                    mapValueValues(value, values);
                    continue;
                }

                values.push(value);
            }
        }
    }

    function resetPage() {
        setLoadingData(true);
        setFileCracked(false);
        setMenu([]);
        setCurrentSection([]);
        setDropDownValues([]);
    }

    function onFileChange(event) {
        resetPage();
        var file = event.currentTarget.files[0];
        var fileName = (event.currentTarget.files.length === 0) ? "" : file.name;

        if (entityType.id.toUpperCase() === 'EMPLOYEE') {
            EmployeeService.crackDriverCard(file).then(r => {
                setFileCracked(true);
                setDcData(r.data);
            }, (error) => {
                NotificationToaster.show(Intent.WARNING, "Could not crack the card.");
            });
        } else {
            AssetService.crackVUCard(file).then(r => {
                setFileCracked(true);
                setVuData(r.data);
            }, (error) => {
                NotificationToaster.show(Intent.WARNING, "Could not crack the file.");
            });
        }

        setFileText(fileName);
        event.target.value = null;
        setLoadingData(false);
    }

    function onNewFile() {
        setFileCracked(false);
        setFileText('Choose file');
    }

    function friendlyTitle(title) {
        let matchArray = title.match(regexTitleMatch);
        if (matchArray) {
            return title.match(regexTitleMatch).join(" ");
        }
        return title;
    }

    function mapMenuItem(item, level) {

        var itemIndex = 1;
        var menuItems = [];

        for (const property in item) {
            let restricted = restrictedSections.find(r => r === property);
            if (!restricted) {
                let isArray = Array.isArray(item[property]);
                let checkForChildren = typeof item[property] === 'object';

                let childItems = checkForChildren ? mapMenuItem(item[property], level + 1) : [];

                let containsChildArrays = false;
                if (childItems && childItems.length > 0) {
                    containsChildArrays = childItems.some(c => c.isArray === true);
                }

                let isFolder = item[property] !== null && !isArray &&
                    checkForChildren && containsChildArrays;

                let folderDocIcon = isFolder ? "folder-close" : "document";
                let icon = isArray ? "list" : folderDocIcon;

                menuItems.push({
                    id: `menu-item-${level}-${itemIndex}`,
                    hasCaret: isFolder,
                    icon: icon,
                    label: friendlyTitle(property),
                    isExpanded: false,
                    childNodes: childItems,
                    nodeData: restricted ? null : item[property],
                    isArray: isArray,
                    containsChildArrays: containsChildArrays,
                    className: containsChildArrays ? null : "cursor-pointer",
                    restricted: restricted
                });

                itemIndex++;
            }
        }

        return menuItems;
    }

    function updateNodeIsExpanded(node, nodeId, isExpanded) {
        if (node.id === nodeId) {
            node.isExpanded = isExpanded;
            return true;
        } else if (node.childNodes && node.childNodes.length > 0) {
            for (var i = 0; i < node.childNodes.length; i++) {
                var set = updateNodeIsExpanded(node.childNodes[i], nodeId, isExpanded);
                if (set) {
                    return true;
                }
            }
        }
        return false;
    }

    function expandCollapseSection(node, isExpanded) {
        setMenu(function (prevMenu) {
            var clonedMenu = [...prevMenu];
            updateNodeIsExpanded(clonedMenu[0], node.id, isExpanded);
            return clonedMenu;
        });
    }

    function expandSection(node) {
        expandCollapseSection(node, true);
    }

    function collapseSection(node) {
        expandCollapseSection(node, false);
    }

    function onFileSelectionSelect(selected) {
        setDropDownSelected(selected);
    }

    function onEntityTypeSelect(selected) {
        setEntityType(selected);
    }

    return (

        <UserAccess perform={requiredAction}
            yes={() => (
                <Fragment>
                    <div className="row">
                        <Breadcrumbs items={props.breadcrumbs} />
                    </div>

                    <div className="row">
                        <ShowHide
                            evaluator={fileCracked}
                            show={(
                                <Fragment>
                                    <h1>{fileText}</h1>
                                    <div className="spacer-bottom">
                                        <Button text="View another file" icon="document-open" intent="primary" onClick={onNewFile} />
                                    </div>
                                </Fragment>
                            )}
                            hide={(
                                <Fragment>
                                    <h1>File Viewer</h1>

                                    <FormSelect
                                        disabled={loadingData}
                                        items={entities}
                                        onItemSelect={onEntityTypeSelect}
                                        selectedValue={entityType.id}
                                        large={true}
                                        placeholder="File type"
                                    ></FormSelect>

                                    <ShowHide
                                        evaluator={entityType.id != null}
                                        show={(
                                    <FormFileInput
                                        text={fileText}
                                        buttonText="Browse"
                                        headingText="Select the file to view the contents."
                                        fill={false}
                                        onChange={onFileChange}
                                        hasSelection={fileText !== ''}
                                            />
                                        )}
                                    />  
                                </Fragment>
                            )}
                        />


                    </div>

                    <ShowHide
                        evaluator={fileCracked}
                        show={(
                            
                            <div className="layout-cols">
                                <div className="col side-col">
                                    <div className="row">
                                        <Tree
                                            contents={menu}
                                            onNodeExpand={expandSection}
                                            onNodeCollapse={collapseSection}
                                            onNodeClick={displaySection}
                                        />
                                    </div>

                                </div>


                                <div className="col content-col">
                                    <ShowHide
                                        evaluator={dropDownValues && dropDownValues.length > 0}
                                        show={(
                                            <div className="row">
                                                <FormSelect
                                                    disabled={false}
                                                    items={dropDownValues}
                                                    onItemSelect={onFileSelectionSelect}
                                                    selectedValue={dropDownSelected.id}
                                                    large={true}
                                                    placeholder={dropDownTitle}
                                                ></FormSelect>
                                            </div>
                                        )}
                                    />

                                    {currentSection && currentSection.map(function (table, index) {
                                        return (
                                            <div className="spacer-bottom wide-table" key={`current-section-${index}`}>
                                                <ListingTable
                                                    id="listing-table-schedule-runs"
                                                    headers={table[0]}
                                                    loadingData={false}
                                                    data={table[1]}
                                                    totalRecordCount={table[1].length}
                                                    pageable={false}
                                                    noWrap={true}
                                                />
                                            </div>
                                        );

                                    })}
                                </div>
                            </div>

                        )}
                    />                   
                </Fragment>
            )}
            no={() => (
                <PageRestricted />
            )}
        />
    );
}
import { FormSelect } from 'components/form-fields';
import { useValidation } from 'hooks/useValidation';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import React, { Fragment, useEffect } from 'react';

export function OcrSelectLayout(props) {
    const [_, errors, validate] = useValidation();

    useEffect(() => {
        validateLayout();
    }, [props.layout.answer]);

    function validateLayout() {
        const required = props.layout.requiredAnswerCount > 0;
        const rules = [{ fieldName: 'answer', required: required }];
        validate(rules, props.layout);
    }

    function onValueChange(value) {
        const clonedLayout = { ...props.layout };

        clonedLayout.answer = value.id;

        props.onChange(clonedLayout);
    }

    function itemName() {
        var name = '';
        if (props.items) {
            var matchedItem = props.items.find(o => o.id === props.layout.answer);

            if (matchedItem) {
                name = matchedItem.name;
            }
        }
        return name;
    }

    return <Fragment>
        <div className={classNames({ "hide-in-print": !props.readonly, "hidden": props.readonly })}>
            <FormSelect
                loading={props.loading}
                onItemSelect={onValueChange}
                items={props.items}
                selectedValue={props.layout.answer}
                placeholder={props.placeholder}
                headingText={props.layout.secondaryLayoutTitle ? props.layout.secondaryLayoutTitle : ''}
                disabled={props.disabled}
                dangerHelperText={errors.answer}
            />
        </div>
        <div className={classNames({ "show-in-print": !props.readonly, "visible": props.readonly })}>
            <h4 className={classNames({ "bp3-skeleton": props.loading })}>{props.layout.secondaryLayoutTitle ? props.layout.secondaryLayoutTitle : ''}</h4>
            <p className={classNames({ "bp3-skeleton": props.loading })}>{itemName()}</p>
        </div>
    </Fragment>;

}

OcrSelectLayout.propTypes = {
    loading: PropTypes.bool,
    layout: PropTypes.object.isRequired,
    onChange: PropTypes.func.isRequired,
    items: PropTypes.array.isRequired,
    placeholder: PropTypes.string,
    disabled: PropTypes.bool,
    readonly: PropTypes.bool
};

OcrSelectLayout.defaultProps = {
    loading: false,
    disabled: false,
    readonly: false

};

import React, { Fragment, useEffect, useRef, useState } from "react";
import { Intent, Tooltip } from "@blueprintjs/core";
import { Button } from "components/buttons";
import { PageRestricted } from "pages/errors/page-restricted";
import { UserAccess, UserHasAccess } from "components/user-access";
import { ListingTable } from "components/listing";
import { DateToLocal } from "components/dates";
import { useDebounce } from "hooks/useDebounce";
import { ShowHide } from "components/layout";
import { FormDateRangeInput, FormTextInput } from "components/form-fields";
import { Filter } from "components/filtering";
import { AssetService } from "services/AssetService";
import moment from "moment";
import classNames from "classnames";
import { Link } from "react-router-dom";
import store from 'index';
import { useSelector, useDispatch } from 'react-redux';
import { SetRouting, SetFiltering } from "state/actions";
import { NotificationToaster } from "components/notifications";

export function DefectReporting(props) {

    const dispatch = useDispatch();
    const defaultRecordSize = 25;
    const debounceTimeout = 750;
    const defaultDateRangeInMonths = -3;
    const [tableOptions, setTableOptions] = useState({
        startDate: moment().add(defaultDateRangeInMonths, 'M').format("YYYY-MM-DD"),
        endDate: moment().format("YYYY-MM-DD 23:59:59"),
        pageNumber: 1,
        pageSize: defaultRecordSize,
    });
    const optionsRef = useRef(tableOptions);
    const [loading, setLoading] = useState(false);
    const [noDataMessage, setNoDataMessage] = useState(
        `It looks like no previous walkaround checks have been made to the ${props.route}.`
    );
    const dateFilterName = "walkaroundDates";

    const selectedDates = useSelector(state => state.filters[dateFilterName]);

    const sortableHeaders = [
        { headerName: props.headerName, sortName: props.sortName },
        { headerName: "Date", sortName: "ENDDATE" },
        { headerName: "Defect Count", sortName: "DefectCount" },
    ];
    const tableHeaders = [props.headerName, "Date", "Defect Count", "Defects Signed Off", "Defects Signed Off By"];
    const filterArray = [
        {
            name: "HASDEFECT",
            displayName: "Has Defects",
            items: [
                { id: "TRUE", name: "Yes" },
                { id: "FALSE", name: "No" },
            ],
        },
        {
            name: "DEFECTSIGNEDOFF",
            displayName: "Report Signed Off",
            items: [
                { id: "TRUE", name: "Yes" },
                { id: "FALSE", name: "No" },
            ],
        }
    ];
    const [tableSortBy, setTableSortBy] = useState({
        headerName: "Date",
        sortName: "ENDDATE",
    });
    const [tableSortDir, setTableSortDir] = useState("A");
    const [appliedFilters, setAppliedFilters] = useState([]);
    const [totalRecords, setTotalRecords] = useState(0);
    const [rawTableData, setRawTableData] = useState([]);
    const [tableRows, setTableRows] = useState([]);
    const [pageNumber, setPageNumber] = useState(1);
    const [pageFilters, setPageFilters] = useState([]);
    const [showFilters, setShowFilters] = useState(false);
    const [pageSize, setPageSize] = useState(defaultRecordSize);
    const [searchTerm, setSearchTerm] = useState("");
    const debouncedSearchTerm = useDebounce(searchTerm, debounceTimeout);
    const inputSearchRef = useRef(null);
    const requiredAction = "WalkaroundCheck:View";
    const canAccessPage = UserHasAccess(requiredAction);

    const currentPageNumber = useRef();
    const currentPageSize = useRef();
    const currentSearchTerm = useRef();
    const currentTableSortBy = useRef();
    const currentTableSortDir = useRef();
    const currentAppliedFilters = useRef();
    const currentStartDate = useRef();
    const currentEndDate = useRef();

    useEffect(() => {
        setPageFilters(filterArray);
    }, []);

    useEffect(() => {
        setPageNumber(1);
        setNoDataMessage("No results found, please try a different search term.");
    }, [debouncedSearchTerm]);

    useEffect(() => {
        fetchTableData(false);
    }, [
        pageSize,
        pageNumber,
        tableSortBy.sortName,
        tableSortDir,
        debouncedSearchTerm,
        tableOptions.startDate,
        tableOptions.endDate,
        appliedFilters.length,
    ]);

    useEffect(() => {
        let data = [];

        if (rawTableData != null) {
            data = rawTableData.map((d) => {
                return [
                    <Link key={d.walkaroundCheckId} to={`/walkaround-checks/${d.walkaroundCheckId}`}>{d[props.sortName]}</Link>,
                    <DateToLocal key={d} format="DD/MM/YYYY">
                        {d.endDate}
                    </DateToLocal>,
                    d.defectCount,
                    calculateSignOffStatus(d),
                    d.reportSignoffUser ? d.reportSignoffUser : "-",
                ];
            });
        }

        store.dispatch(SetRouting(props.route, props.route, props.id));

        setTableRows(data);
    }, [rawTableData]);

    function calculateSignOffStatus(check) {

        if (check.defectCount === 0) {
            return "-";
        }

        return check.reportSignoffDate ? "Yes" : "No";
    }

    useEffect(() => {
        if (selectedDates && selectedDates.startDate != null && selectedDates.endDate != null) {

            optionsRef.current = {
                ...tableOptions,
                startDate: selectedDates.startDate?.format("YYYY-MM-DD"),
                endDate: selectedDates.endDate?.format("YYYY-MM-DD"),
                pageNumber: 1,
            };
            setTableOptions(optionsRef.current);
        }
        setLoading(true);
    }, [selectedDates]);

    function fetchTableData(forceRefresh) {
        if (
            canAccessPage &&
            currentPageNumber.current === pageNumber &&
            currentPageSize.current === pageSize &&
            currentSearchTerm.current === debouncedSearchTerm &&
            currentTableSortBy.current === tableSortBy &&
            currentTableSortDir.current === tableSortDir &&
            currentAppliedFilters.current === appliedFilters.length &&
            currentStartDate.current === tableOptions.startDate &&
            currentEndDate.current === tableOptions.endDate &&
            !forceRefresh
        ) {
            return;
        }

        currentPageNumber.current = pageNumber;
        currentPageSize.current = pageSize;
        currentSearchTerm.current = debouncedSearchTerm;
        currentTableSortBy.current = tableSortBy;
        currentTableSortDir.current = tableSortDir;
        currentStartDate.current = tableOptions.startDate;
        currentEndDate.current = tableOptions.endDate;

        setLoading(true);
        AssetService.getWalkaroundChecks(
            props.route,
            props.id,
            pageNumber,
            pageSize,
            debouncedSearchTerm,
            tableSortBy.sortName,
            tableSortDir,
            tableOptions.startDate,
            tableOptions.endDate,
            appliedFilters
        ).then(function (walkaroundChecksResponse) {
            setRawTableData(walkaroundChecksResponse.data);
            setTotalRecords(walkaroundChecksResponse.totalCount);
            setLoading(false);
            setTimeout(function () {
                if (inputSearchRef.current != null) {
                    inputSearchRef.current.focus();
                }
            });
        }, function () {
            NotificationToaster.show(Intent.DANGER, "Could not retrieve walkaround checks, please try again");
        });
    }

    function onSearchChange(item) {
        setSearchTerm(item.target.value);
    }

    function onPagingChange(newPageNumber, newPageSize) {
        setPageNumber(newPageNumber);
        setPageSize(newPageSize);
    }

    function onTableSort(header, direction) {
        setTableSortDir(direction);
        setTableSortBy(header);
    }

    function onFilterChange(listingFilters) {
        setPageNumber(1);
        setNoDataMessage("No results found, please try a different filter.");
        setAppliedFilters(listingFilters);

        if (listingFilters.length > appliedFilters.length) {
            setShowFilters(true);
        }
    }

    function onToggleFilter() {
        setShowFilters((prevState) => {
            return !prevState;
        });
    }

    function onDateRangeChange(item) {
        if (
            (item.startDate.date != null && item.endDate.date != null) ||
            (item.startDate.date == null && item.endDate.date == null)
        ) {
            setNoDataMessage("No results found, please try a different date range.");
            dispatch(SetFiltering(dateFilterName, {
                startDate: item.startDate?.date,
                endDate: item.endDate?.date
            }));
        }
    }

    return (
        <UserAccess
            perform={requiredAction}
            yes={() => (
                <div className="row">
                    <h1>Walkaround Checks</h1>
                    <div className="intro-text">
                        <p>In this section you can see all completed walkaround checks.</p>
                    </div>
                    <ShowHide
                        evaluator={
                            tableRows.length === 0 && !loading && pageFilters.length === 0
                        }
                        hide={
                            <Fragment>
                                <div
                                    className={classNames("pull-left", {
                                        "spacer-bottom": !showFilters,
                                    })}
                                >
                                    <div className="inline-items">
                                        <FormTextInput
                                            inputRef={inputSearchRef}
                                            placeholder={props.route === "employee" ? "Registration" : "Employee name"}
                                            onChange={onSearchChange}
                                            value={searchTerm}
                                            large
                                            disabled={loading}
                                            icon="search"
                                            id="walkaround-check-search-field"
                                        />

                                        <FormDateRangeInput
                                            onChange={onDateRangeChange}
                                            startDate={tableOptions.startDate}
                                            endDate={tableOptions.endDate}
                                            disabled={loading}
                                            large
                                            allowClear={false}
                                        ></FormDateRangeInput>

                                        <Tooltip content="Filter" position="right">
                                            <Button
                                                icon="filter"
                                                onClick={onToggleFilter}
                                                className={classNames({
                                                    active: appliedFilters.length > 0,
                                                })}
                                            />
                                        </Tooltip>
                                    </div>
                                </div>
                                <Filter
                                    filterName="walkaroundChecks"
                                    visible={showFilters}
                                    filters={pageFilters}
                                    onUpdate={onFilterChange}
                                />
                            </Fragment>
                        }
                    />

                    <ListingTable
                        id="listing-table-walkaround-check"
                        noDataMessage={noDataMessage}
                        sortable
                        sortableHeaders={sortableHeaders}
                        sortedBy={tableSortBy}
                        sortedDir={tableSortDir}
                        onSort={onTableSort}
                        headers={tableHeaders}
                        data={tableRows}
                        totalRecordCount={totalRecords}
                        onPagingChange={onPagingChange}
                        loadingData={loading}
                    />
                </div>
            )}
            no={() => <PageRestricted />}
        />
    );
}

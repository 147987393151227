import React, { useState, useEffect, Fragment } from 'react';
import { FormTextArea } from 'components/form-fields';
import { Tabs } from 'components/navigation';
import PropTypes from 'prop-types';

export function LocationNotesSettings(props) {

    const [note, setNote] = useState("");
    const [loading, setLoading] = useState(true);
    const [selectedTab, setSelectedTab] = useState(0);
    const [businessAreaTabs, setBusinessAreaTabs] = useState([]);

    useEffect(() => {
        setLoading(props.loading);
    }, [props.loading]);


    useEffect(function () {

        if (props.businessAreaTabs.length === 0 ) {
            return;
        }

        setBusinessAreaTabs(props.businessAreaTabs);

    }, [props.businessAreaTabs])

    
    //When a tab is clicked, select that tab and then set the tabs hierarchy to be the current one
    function onTabClick(index) {

        setSelectedTab(index);
        var clonedTabs = [
            ...businessAreaTabs
        ];

        clonedTabs = clonedTabs.map(function (t, i) {
            return {
                ...t,
                selected: index === i
            }
        });
        setBusinessAreaTabs(clonedTabs);

        setNote(props.businessAreaTabs[index].notes);
    }

    //When tabs are set, select the first one in the list
    useEffect(function () {

        if (businessAreaTabs.length === 0 || loading) {
            return;
        }

        onTabClick(0);

    }, [businessAreaTabs.length, loading])

    function onNoteChange(e) {
        setNote(e.target.value);

        var clonedBusinessAreaTabs = [
            ...props.businessAreaTabs
        ];
        clonedBusinessAreaTabs[selectedTab].notes = e.target.value;
        props.onBusinessAreaTabsUpdate(clonedBusinessAreaTabs);


        var clonedNotes = [
            ...props.notesSettings
        ];
        clonedNotes[selectedTab] = e.target.value;
        props.onNotesUpdate(clonedNotes);
    }

    return (

            <Fragment>
                <div className="spacer-bottom">
                    <Tabs tabs={businessAreaTabs} onClick={onTabClick} loading={loading || props.loading} />
                </div>

                <FormTextArea
                    id="location-note"
                    headingText="Internal Notes"
                    loading={loading}
                    value={note}
                    onChange={onNoteChange}
                    disabled={props.saving}
                    maxLength={255}
                >
                </FormTextArea>
            </Fragment >
    );
}
LocationNotesSettings.defaultProps = {
    saving: false
};

LocationNotesSettings.propTypes = {
    businessAreaTabs: PropTypes.array.isRequired,
    notesSettings: PropTypes.array.isRequired,
    saving: PropTypes.bool,
    loading: PropTypes.bool.isRequired
};
import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useHistory } from "react-router-dom";

import { Intent, Alignment } from '@blueprintjs/core';
import { FormSwitchList, FormSwitch } from "components/form-fields";
import { NotificationToaster, AlertUnsavedData, AlertConfirm } from "components/notifications";
import { ButtonSave } from "components/buttons";
import { AccountService, UserService } from "services";
import { useDirtyData } from "hooks/useDirtyData";
import { ShowHide } from 'components/layout';
import { HelperFunctions } from 'helpers';

var _ = require('underscore');

export function UserAccountAccess(props) {
    const history = useHistory();
    const [loading, setLoading] = useState(true);
    const [saving, setSaving] = useState(false);
    const [originalValues, setOriginalValues] = useState({});
    const [accountAccess, setAccountAccess] = useState({
        canSwitchAccount: false,
        switchAllAccounts: false,
        switchAccounts: []
    });
    const [readOnly, setReadOnly] = useState(false);
    const [currentAccountId, setCurrentAccountId] = useState();
    const [willLoseUser, setWillLoseUser] = useState(false);
    const isDataDirty = useDirtyData(originalValues, accountAccess);
    const minimumNoOfAccounts = 2;

    useEffect(initialLoad, [props.user]);

    function initialLoad() {

        if (props.user == null || props.user.userId == null) {
            return;
        }

        axios.all([UserService.getAccountAccess(props.user.userId), UserService.getLoggedInUser(), AccountService.getAllAccounts()]).then(axios.spread(function (userAccountAccess, userDetails, accounts) {

            setReadOnly(props.user.userId === userDetails.userId);
            setLoading(false);
            setCurrentAccountId(userDetails.accountId);

            var currentAccount = _.findWhere(accounts.data, { id: userDetails.accountId });
            var parentId = currentAccount.parentId != null ? currentAccount.parentId : userDetails.accountId;
            var relatedAccounts = _.filter(accounts.data, function (account) {
                return account.parentId === parentId || account.id === parentId;
            });

            var aa = {
                canSwitchAccount: userAccountAccess.canSwitchAccount,
                switchAllAccounts: userAccountAccess.switchAllAccounts,
                currentAccountId: userDetails.accountId,
                switchAccounts: mapAccounts(relatedAccounts, userAccountAccess.accountIds, props.user.currentAccountId)
            };

            setAccountAccess(aa);
            setOriginalValues(HelperFunctions.deepClone(aa));

        }), () => {
            NotificationToaster.show(Intent.DANGER, "Could not load options. Please refresh the page.");
        });
    }

    function onCanSwitchChange(event) {
        setAccountAccess({
            ...accountAccess,
            canSwitchAccount: event.target.checked,
            switchAllAccounts: event.target.checked,
            switchAccounts: event.target.checked ? accountAccess.switchAccounts : clearAccounts()
        });
    }

    function onSwitchAllChange(event) {
        setAccountAccess({
            ...accountAccess,
            switchAllAccounts: event.target.checked,
            switchAccounts: event.target.checked ? clearAccounts() : accountAccess.switchAccounts
        });
    }

    function saveAccountAccess(overrideWarnings) {
        var selectedAccounts = getSelectedItems(accountAccess.switchAccounts);
        //Check that at least on more then the users current account have been selected.
        if (accountAccess.canSwitchAccount && !accountAccess.switchAllAccounts && selectedAccounts.length < minimumNoOfAccounts) {
            NotificationToaster.show(Intent.WARNING, "You must select at least one more account. Your changes have not been saved.");
            return;
        }

        //If they are not giving access to this account, show them a warning modal
        if (overrideWarnings !== true && accountAccess.canSwitchAccount && !accountAccess.switchAllAccounts && !selectedAccounts.includes(currentAccountId)) {
            setWillLoseUser(true);
            return;
        } else {
            setWillLoseUser(false);
        }

        var aa = {
            canSwitchAccount: accountAccess.canSwitchAccount,
            switchAllAccounts: accountAccess.switchAllAccounts,
            accountIds: []
        };

        if (accountAccess.canSwitchAccount && !accountAccess.switchAllAccounts) {
            aa.accountIds = getSelectedItems(accountAccess.switchAccounts);
        }
        UserService.putAccountAccess(props.user.userId, aa).then(
            () => {
                setOriginalValues(HelperFunctions.deepClone(accountAccess));
                setSaving(false);
                NotificationToaster.show(Intent.SUCCESS, "Settings updated successfully.");

                if (overrideWarnings === true) {
                    //We can no longer edit this user
                    history.push("/user");
                }

            },
            () => {
                setSaving(false);
                NotificationToaster.show(Intent.DANGER, "Unable to update settings. Please try again later.");
            });
    }

    function handleToggleChange(event) {
        var temp = [...accountAccess.switchAccounts];

        var match = _.find(temp, function (item) {
            return item.id.toString() === event.currentTarget.getAttribute('identifier');
        });
        match.checked = event.target.checked;

        setAccountAccess({
            ...accountAccess,
            switchAccounts: temp
        });
    }

    function getSelectedItems(items) {
        return _.chain(items).filter(function (item) {
            return item.checked;
        }).map(function (item) {
            return item.id;
        }).value();
    }

    function clearAccounts() {
        return _.map(accountAccess.switchAccounts, function (item) {
            return { id: item.id, name: item.name, checked: props.user.currentAccountId === item.id, disabled: item.disabled };
        });
    }

    function mapAccounts(data, enabledAccounts, userCurrentAccountId) {
        return _.map(data, function (item) {
            const userHasFeature = _.some(enabledAccounts, function (feature) {
                return feature === item.id;
            });
            return { id: item.id, name: item.name + ' ' + item.memberNumber, checked: userHasFeature || userCurrentAccountId === item.id, disabled: userCurrentAccountId === item.id };
        });
    }

    function cancelWarning() {
        setWillLoseUser(false);
    }

    function confirmWarning() {
        saveAccountAccess(true);
    }

    return (
        <div className="row">
            <h2>Account Access</h2>
            <FormSwitch
                disabled={saving || readOnly}
                loading={loading}
                headingText="Allow user to switch between accounts:"
                checked={accountAccess.canSwitchAccount}
                alignIndicator={Alignment.LEFT}
                onChange={onCanSwitchChange}>
            </FormSwitch>
            <ShowHide
                evaluator={accountAccess.canSwitchAccount}
                show={(
                    <FormSwitch
                        disabled={saving || readOnly}
                        loading={loading}
                        headingText="Switch between all accounts:"
                        checked={accountAccess.switchAllAccounts}
                        alignIndicator={Alignment.LEFT}
                        onChange={onSwitchAllChange}>
                    </FormSwitch>
                )}
            ></ShowHide>
            <ShowHide
                evaluator={accountAccess.canSwitchAccount && !accountAccess.switchAllAccounts}
                show={(
                    <FormSwitchList
                        id="account-features"
                        items={accountAccess.switchAccounts}
                        onChange={handleToggleChange}
                        headingText="Allow user to switch between the following accounts:"
                        loading={loading}
                        switchListName="featureList"
                        disabled={readOnly}>
                    </FormSwitchList>
                )}
            ></ShowHide>

            <AlertUnsavedData
                isDirty={isDataDirty}>
            </AlertUnsavedData>
            <AlertConfirm
                isOpen={willLoseUser}
                title={"User will be removed"}
                onConfirm={confirmWarning}
                onCancel={cancelWarning}
            >
                <p>If you continue, this user will be removed from this account and you will no longer be able to edit them, are you sure you want to continue?</p>
            </AlertConfirm>
            <ShowHide
                evaluator={!readOnly}
                show={(
                    <ButtonSave
                        onClick={saveAccountAccess}
                        disabled={saving}
                        loading={loading}
                            ></ButtonSave>
                )}
            ></ShowHide>
        </div>
    );
}
import React, { Fragment, useState, useEffect } from 'react';
import { Intent } from '@blueprintjs/core'
import { FormHelperText, FormPasswordConfirm } from "components/form-fields";
import { LoginLayout } from 'pages/layouts';
import { ShowHide } from 'components/layout';
import { ButtonSave } from 'components/buttons';
import { useValidation } from "hooks/useValidation";
import { UserService } from 'services';
import { NotificationToaster } from "components/notifications";
import { useQuery } from 'hooks/useQuery';

export function UserConfirm() {

    const query = useQuery();
    const userId = query.get("u");
    const resetCode = query.get("c");

    const [confirmRequest, setConfirmRequest] = useState({
        password: '',
        resetGuid: resetCode
    });

    const [approved, setApproved] = useState(false);
    const [saving, setSaving] = useState(false);
    const [passwordsMatch, setPasswordsMatch] = useState(false);
    const [passwordCriteriaMet, setPasswordCriteriaMet] = useState(false);
    const [isValid, errors, validate] = useValidation();

    const rules = [
        { fieldName: "password", required: true }
    ];

    function validateForm() {
        setSaving(true);
        validate(rules, confirmRequest, [{ fieldName: "password", valid: passwordCriteriaMet, errorMessage: "Your password does not meet the minimum requirements"}]);
    }

    useEffect(confirmUser, [isValid, saving]);

    function onPasswordChange(password, matching, criteriaMet) {
        setPasswordsMatch(matching);
        setPasswordCriteriaMet(criteriaMet);
        setConfirmRequest({
            ...confirmRequest,
            password: password
        });
    }

    function confirmUser() {
        if (isValid && passwordsMatch && passwordCriteriaMet && saving)
        {
            UserService.confirmUser(userId, confirmRequest).then(function () {
                setSaving(false);
                setApproved(true);
            }, function (error) {
                setSaving(false);
                    const badRequestCode = 400;
                    if (error && error.status === badRequestCode) {
                    NotificationToaster.show(Intent.WARNING, "Confirmation code is either invalid or the user has already been approved.");
                } else {
                    NotificationToaster.show(Intent.DANGER, "Could not activate the user, please try again.");
                }
            });
        }
        else
        {
            setSaving(false);
        }
    }

    return (
        <LoginLayout>

            <ShowHide
                evaluator={!approved}
                show={(
                    <Fragment>
                        <h2>Activate your account</h2>

                        <FormHelperText>
                            <p>To complete your account activation, please create a password</p>
                        </FormHelperText>

                        <FormPasswordConfirm
                            id="password-confirm"
                            value={confirmRequest.password}
                            onChange={onPasswordChange}
                            dangerHelperText={errors.password}
                        />

                        <ButtonSave
                            onClick={validateForm}
                            disabled={saving}
                            text="Activate account"
                            icon={null}
                            simpleDisabled={!passwordsMatch}
                        ></ButtonSave>	

                    </Fragment>
                )}
                hide={(
                    <Fragment>
                        <h2>Your account has been activated</h2>

                        <FormHelperText>
                            <p>To begin getting started please <a href={window.env.VISION_URL}>login to Vision</a>.</p>
                        </FormHelperText>
                    </Fragment>
                )}

            />
            
        </LoginLayout>
    );

}
import { FormNumericInput } from 'components/form-fields';
import { useValidation } from 'hooks/useValidation';
import PropTypes from 'prop-types';
import React, { Fragment, useEffect, useState } from 'react';
import classNames from 'classnames';

export function OcrNumberOfNumberLayout(props) {
    const defaultMinValue = 1;
    const defaultMaxValue = 12;

    const [_, errors, validate] = useValidation();

    const [layoutAnswer, setLayoutAnswer] = useState({
        visitNumber: null,
        ofVisit: null
    });

    const [maxAllowedVisitNumber, setMaxAllowedVisitNumber] = useState(defaultMaxValue);
    const [minAllowedOfVisit, setMinAllowedOfVisit] = useState(defaultMinValue);

    useEffect(() => {
        validateLayout();
    }, [layoutAnswer, maxAllowedVisitNumber, minAllowedOfVisit]);

    useEffect(() => {
        if (layoutAnswer.visitNumber) {
            setMinAllowedOfVisit(layoutAnswer.visitNumber < defaultMaxValue ? layoutAnswer.visitNumber : defaultMaxValue);
        } else {
            setMinAllowedOfVisit(defaultMinValue);
        }

        if (layoutAnswer.ofVisit) {
            setMaxAllowedVisitNumber(layoutAnswer.ofVisit > defaultMinValue ? layoutAnswer.ofVisit : 1);
        } else {
            setMaxAllowedVisitNumber(defaultMaxValue);
        }
    }, [layoutAnswer]);

    useEffect(() => {
        if (props.layout.table && props.layout.table.rows && props.layout.table.rows.length > 0) {
            const clonedAnswer = { ...layoutAnswer };

            clonedAnswer.visitNumber = props.layout.table.rows[0][0] ? +props.layout.table.rows[0][0] : null;
            clonedAnswer.ofVisit = props.layout.table.rows[0][1] ? +props.layout.table.rows[0][1] : null;

            setLayoutAnswer(clonedAnswer);
        }
    }, [props.layout]);

    function validateLayout() {
        const rules = getRules();
        validate(rules, layoutAnswer);
    }

    function getRules() {
        return [
            { fieldName: 'visitNumber', required: true, minValue: defaultMinValue, maxValue: maxAllowedVisitNumber },
            { fieldName: 'ofVisit', required: true, minValue: minAllowedOfVisit, maxValue: defaultMaxValue },
        ];
    }

    function onVisitNumberChange(value) {
        const formatedValue = value ? +value : null;
        if (formatedValue !== layoutAnswer.visitNumber) {
            const clonedAnswer = { ...layoutAnswer };

            clonedAnswer.visitNumber = value ? +value : null;

            setLayoutAnswer(clonedAnswer);
            updateRows(clonedAnswer);
        }
    }

    function onOfVisitChange(value) {
        const formatedValue = value ? +value : null;
        if (formatedValue !== layoutAnswer.ofVisit) {
            const clonedAnswer = { ...layoutAnswer };

            clonedAnswer.ofVisit = value ? +value : null;

            setLayoutAnswer(clonedAnswer);
            updateRows(clonedAnswer);
        }
    }

    function updateRows(clonedAnswer) {
        const layoutClone = { ...props.layout };

        layoutClone.table.rows =
            [[clonedAnswer.visitNumber ? clonedAnswer.visitNumber.toString() : null, clonedAnswer.ofVisit ? clonedAnswer.ofVisit.toString() : null]];

        props.onChange(layoutClone);
    }

    return <Fragment>
        <div className={classNames({ "hide-in-print": !props.readonly, "hidden": props.readonly })}>
            <FormNumericInput
                disabled={props.disabled}
                onValueChange={onVisitNumberChange}
                selectedValue={layoutAnswer.visitNumber}
                headingText={props.firstNumberHeading}
                max={maxAllowedVisitNumber}
                min={defaultMinValue}
                dangerHelperText={errors.visitNumber}
                disabledInput
            />
            <FormNumericInput
                disabled={props.disabled}
                onValueChange={onOfVisitChange}
                selectedValue={layoutAnswer.ofVisit}
                headingText={props.secondNumberHeading}
                max={defaultMaxValue}
                min={minAllowedOfVisit}
                dangerHelperText={errors.ofVisit}
                disabledInput
            />
        </div>
        <p className={classNames({ "show-in-print": !props.readonly, "visible": props.readonly, "bp3-skeleton": props.loading })}>{layoutAnswer.visitNumber} of {layoutAnswer.ofVisit}</p>
    </Fragment>;
}

OcrNumberOfNumberLayout.propTypes = {
    loading: PropTypes.bool,
    layout: PropTypes.object.isRequired,
    onChange: PropTypes.func.isRequired,
    firstNumberHeading: PropTypes.string.isRequired,
    secondNumberHeading: PropTypes.string.isRequired,
    disabled: PropTypes.bool,
    readonly: PropTypes.bool
};

OcrNumberOfNumberLayout.defaultProps = {
    loading: false,
    disabled: false,
    readonly: false
};

import { AuditingTable } from 'components/auditing';
import { DateToLocal } from 'components/dates';
import React, { useState, useEffect, Fragment } from 'react';
import { AssetService, EmployeeService, JobService } from 'services';
import PropTypes from 'prop-types';
import { UserHasAccess } from 'components/user-access';
import { Intent } from "@blueprintjs/core";
import { NotificationToaster } from "components/notifications";
import { FormSelect } from 'components/form-fields';
import { ShowHide } from "components/layout";

export function LocationMigrationChangeLog(props) {

    const noDataMessage = "It looks like no previous migrations have been performed for this location.";
    const noOfHistoryTypesToShowDropdown = 3;
    const [tableData, setTableData] = useState(null);
    const [tableRecordCount, setTableRecordCount] = useState(0);
    const [selectedHistory, setSelectedHistory] = useState(null);
    const [historyType, setHistoryType] = useState([]);
    const [loading, setLoading] = useState(true);

    const canMigrationEmployee = UserHasAccess("Employees:Migrate");
    const canMigrationAsset = UserHasAccess("Fleet:Migrate");
    const canMigrationJob = UserHasAccess("Internal");
    
    useEffect(() => {
        if (canMigrationEmployee != null && canMigrationAsset != null && canMigrationJob != null) {
            if (!historyType || historyType.length < 1) {
                var type = [];
                var selected = null;
                if (canMigrationEmployee) {
                    type.push({ id: "employee", name: "Employee" })
                    selected = "employee";
                }

                if (canMigrationAsset) {
                    type.push({ id: "asset", name: "Asset" })
                    if (!selected) {
                        selected = "asset";
                    }
                }

                if (canMigrationJob) {
                    type.push({ id: "job", name: "Job" })
                    if (!selected) {
                        selected = "job";
                    }
                }
                setHistoryType(type);
                setSelectedHistory(selected);
            }            
        }
    }, [canMigrationEmployee, canMigrationAsset, canMigrationJob]);

    function setHistoryData(entityHistoryService, pageSize, pageNumber, startDate, endDate) {
        setLoading(true);
        entityHistoryService.getBulkMoveChangeLogForLocation(props.locationId, pageSize, pageNumber, startDate, endDate).then(entityHistory => {
            var formattedData = entityHistory.data.map(d => [<DateToLocal key={ d.date } >{d.date}</DateToLocal>, d.username, d.toLocation, d.changes]);

            setTableData(formattedData);
            setTableRecordCount(formattedData.length);
            setLoading(false);
        }, () => {
            NotificationToaster.show(Intent.DANGER, "Could not load options. Please refresh the page.");
        });
    }

    function onTableChange(pageSize, pageNumber, startDate, endDate) {
        if (canMigrationEmployee && selectedHistory === "employee") {
            setHistoryData(EmployeeService, pageSize, pageNumber, startDate, endDate);
        }

        if (canMigrationAsset && selectedHistory === "asset") {
            setHistoryData(AssetService, pageSize, pageNumber, startDate, endDate);
        }

        if (canMigrationJob && selectedHistory === "job") {
            setHistoryData(JobService, pageSize, pageNumber, startDate, endDate);
        }
    }

    function onHistoryTypeSelect(item) {
        setSelectedHistory(item.id);
    }

    return (
        <Fragment>
            <div className="row">
                <h2>History</h2>
                <ShowHide
                    evaluator={historyType.length < noOfHistoryTypesToShowDropdown}
                    hide={
                        <FormSelect
                            id="history-type"
                            headingText="Choose history type:"
                            items={historyType}
                            onItemSelect={onHistoryTypeSelect}
                            selectedValue={selectedHistory}
                            placeholder="Apply a preset"
                            loading={loading}
                        ></FormSelect>
                    }
                >
                </ShowHide>
            </div>
            <AuditingTable
                data={tableData}
                totalCount={tableRecordCount}
                onChange={onTableChange}
                noDataMessage={noDataMessage}
                dataIsFormatted={true}
                tableHeaders={["Date", "User", "Target Location", "Migration"]}
                hideHeader={true}
                key={selectedHistory}
                loading={loading}
            />
        </Fragment>
    );
}

LocationMigrationChangeLog.propTypes = {
    locationId: PropTypes.string.isRequired
};
import React, { useState, useEffect, Fragment } from 'react';
import axios from 'axios';

import { Intent } from '@blueprintjs/core';
import { FormSuggest, FormSwitchList } from "components/form-fields";
import { NotificationToaster, AlertUnsavedData, NotificationInline } from "components/notifications";
import { ButtonSave } from "components/buttons";
import classNames from "classnames";
import { AccountService, UserService } from "services";
import { useDirtyData } from "hooks/useDirtyData";
import { BulletedList } from 'components/listing';
import { ShowHide } from 'components/layout';

import './AccountInternalConfiguration.css';

var _ = require('underscore');

export function AccountInternalConfiguration(props) {
    const [loading, setLoading] = useState(true);
    const [saving, setSaving] = useState(false);
    const [originalValues, setOriginalValues] = useState({});
    const [configuration, setConfiguration] = useState({
        parentId: null,
        selectedFeatures: [],
        ssoEnabled: false
    });
    const [accountList, setAccountList] = useState([]);
    const [childAccounts, setChildAccounts] = useState([]);
    const [isParentAccount, setIsParentAccount] = useState(false);
    const isDataDirty = useDirtyData(originalValues, configuration);

    useEffect(initialLoad, []);

    function initialLoad() {

        axios.all([
            UserService.getLoggedInUser(),
            AccountService.getAllAccounts(),
            AccountService.getFeatures(),
            AccountService.getInternalConfiguration(),
            AccountService.getGeneralSettings()
        ]).then(axios.spread(function (userDetails, accounts, allFeatures, accountFeatures, generalSettings) {
            setLoading(false);

            var currentAccount = _.findWhere(accounts.data, { id: userDetails.accountId });
            var filteredChildAccounts = _.chain(accounts.data).where({
                parentId: userDetails.accountId
            }).map(function (account) {
                return `${account.memberNumber} - ${account.name}`
            }).value();

            setChildAccounts(filteredChildAccounts);
            setIsParentAccount(filteredChildAccounts.length > 0);

            if (currentAccount != null) {
                var config = {
                    parentId: currentAccount.parentId,
                    selectedFeatures: mapFeatures(allFeatures, accountFeatures, userDetails.isInternal),
                    ssoEnabled: generalSettings.ssoEnabled
                };

                setConfiguration(config);
                setOriginalValues(_.extend({}, configuration));
            }

            var settableParents = _.filter(accounts.data, function (account) {
                return account.parentId == null && account.id !== userDetails.accountId;
            });

            setAccountList(settableParents.map(function (account) {
                account.name = `${account.memberNumber} - ${account.name}`;
                return account;
            }));

        }), () => {
            NotificationToaster.show(Intent.DANGER, "Could not load options. Please refresh the page.");
        });
    }

    function onParentSelect(item) {
        setConfiguration({
            ...configuration,
            parentId: item.id
        });
    }

    function saveInternalConfiguration() {
        var config = {
            parentId: configuration.parentId,
            features: getSelectedItems(configuration.selectedFeatures),
            ssoEnabled: configuration.ssoEnabled
        };

        setSaving(true);
        AccountService.saveInternalConfiguration(config).then(
            () => {
                setOriginalValues(_.extend({}, configuration));
                setSaving(false);
                NotificationToaster.show(Intent.SUCCESS, "Settings updated successfully.");
            },
            () => {
                setSaving(false);
                NotificationToaster.show(Intent.DANGER, "Unable to update settings. Please try again later.");
            });
    }

    function handleToggleChange(event) {
        var temp = [...configuration.selectedFeatures];

        var match = _.find(temp, function (item) {
            return item.id.toString() === event.currentTarget.getAttribute('identifier');
        });
        match.checked = event.target.checked;

        setConfiguration({
            ...configuration,
            selectedFeatures: temp
        });
    }

    function getSelectedItems(items) {
        return _.chain(items).filter(function (item) {
            return item.checked;
        }).map(function (item) {
            return { id: item.id, name: item.name, autoSet: item.disabled };
        }).value();
    }

    function mapFeatures(data, enabledFeatures, internal) {
        return _.map(data, function (item) {

            var accountFeature = _.find(enabledFeatures, function (feature) {
                return feature.id === item.id;
            });

            const userHasFeature = accountFeature != null;
            const autoSet = userHasFeature && accountFeature.autoSet;

            return { id: item.id, name: item.name, checked: userHasFeature, disabled: !internal || autoSet };
        });
    }

    return (
        <div className="row">
            <h2>Internal Configuration</h2>

            <NotificationInline
                id="notification-settings-disabled"
                loading={loading}
                show={isParentAccount}
                text="This account is already a parent account, so this option is unavailable"
                intent="danger">
            </NotificationInline>
            <FormSuggest
                loading={loading}
                disabled={isParentAccount}
                items={accountList}
                onItemSelect={onParentSelect}
                placeholder="Select parent"
                headingText="Parent account:"
                helperText="Select the account to which this account is a child of"
                selectedValue={configuration.parentId}
            />
            <ShowHide
                evaluator={childAccounts.length > 0}
                show={(
                    <div className="form-field">
                        <h4 className={classNames({ "bp3-skeleton": loading })}>Child Accounts:</h4>
                        <BulletedList
                            loading={loading}
                            listItems={childAccounts}
                            displayField="fullName"
                        />
                    </div>
                )}
            />
            <div id="internal-feature-list">
                <FormSwitchList
                    id="account-features"
                    items={configuration.selectedFeatures}
                    onChange={handleToggleChange}
                    headingText="Account Features:"
                    helperText={(
                        <Fragment>
                            <p>Turning a feature off will remove access to the features for this account.</p>
                            <p className="internal-features-helper">*Features marked in blue are automatically set from dynamics and cannot be changed.</p>
                        </Fragment>
                    )}
                    loading={loading}
                    switchListName="featureList">
                </FormSwitchList>
            </div>
            <AlertUnsavedData
                isDirty={isDataDirty}>
            </AlertUnsavedData>
            <ButtonSave
                onClick={saveInternalConfiguration}
                disabled={saving}
                loading={loading}
            ></ButtonSave>
        </div>
    );
}
import ApiService from 'services/ApiService'

export const EmailService = {

	
	getEmailHistory(email, pageSize, pageNumber, tableSortDir) {
		return new Promise((resolve, reject) => {
			ApiService.get(`/email/Email?emailAddress=${encodeURIComponent(email)}&requestCount=${pageSize}&pageNumber=${pageNumber}&sortDirection=${tableSortDir}`).then(
				(result) => {
					resolve(result.data);
				},
				(error) => {
					reject(error);
				});
		});

	}
}

import React from 'react';
import PropTypes from 'prop-types';
import { useHistory } from "react-router-dom";
import classNames from 'classnames';
import { AnchorButton, Intent, Icon as BlueprintIcon } from "@blueprintjs/core";
import { IconNames } from "@blueprintjs/icons";

export function LinkButton(props) {

    let intent = Intent.NONE;
    if (props.intent) {
        switch (props.intent.toLowerCase()) {
            case "save":
            case "info":
            case "primary":
                intent = Intent.PRIMARY;
                break;
            case "warning":
                intent = Intent.WARNING;
                break;
            case "danger":
            case "delete":
                intent = Intent.DANGER;
                break;
            default:
                intent = Intent.NONE;
        }
    }
    let history = useHistory();

    function onButtonClick(e) {

        if (props.externalLink && props.openInNewTab) {
            return;
        }

        if (props.externalLink) {
            window.location = props.href;
        }
        else if (!props.openInNewTab) {
            e.preventDefault();
            history.push(props.href);
        }

        if (props.onClick) {
            props.onClick();
        }
    }

    let iconElement = null;

    if (props.icon) {
        if (props.icon.startsWith("fa-")) {
            iconElement = (
                <span
                    className={classNames('fa', props.icon)}
                    style={{ color: props.faColour || 'inherit' }}
                />
            );
        } else if (IconNames[props.icon.toUpperCase()]) {
            iconElement = <BlueprintIcon icon={IconNames[props.icon.toUpperCase()]} style={{ color: props.faColour }} />;
        }
    }

    return (
        <AnchorButton
            className={classNames('btn-form', { "bp3-skeleton": props.loading })}
            onClick={onButtonClick}
            icon={iconElement}
            disabled={props.disabled}
            large={props.large}
            intent={intent}
            href={props.href}
            target={props.openInNewTab ? "_blank" : "_self"}
            id={props.id}
            minimal={props.minimal}
        >
            {!props.iconOnly && props.text}
        </AnchorButton>
    );
}

LinkButton.defaultProps = {
    id: null,
    intent: "",
    loading: false,
    disabled: false,
    icon: null,
    iconOnly: false,
    text: "",
    openInNewTab: false,
    externalLink: false,
    large: true,
    minimal: false,
    faColour: null
};

LinkButton.propTypes = {
    id: PropTypes.string,
    intent: PropTypes.string,
    loading: PropTypes.bool,
    href: PropTypes.string.isRequired,
    disabled: PropTypes.bool,
    icon: PropTypes.string,
    iconOnly: PropTypes.bool,
    text: PropTypes.string,
    openInNewTab: PropTypes.bool,
    onClick: PropTypes.func,
    externalLink: PropTypes.bool,
    large: PropTypes.bool,
    minimal: PropTypes.bool,
    faColour: PropTypes.string
};
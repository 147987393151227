import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

export function TextButton(props) {

    return (
        <button
            className={classNames('btn-inline-text', { "bp3-skeleton": props.loading }, props.className)}
            onClick={props.onClick}
            disabled={props.disabled}
            id={props.id}
        >{props.children}</button>
    );
}

TextButton.defaultProps = {
    id: null,
    loading: false,
    disabled: false,
};

TextButton.propTypes = {
    id: PropTypes.string,
    loading: PropTypes.bool,
    onClick: PropTypes.func.isRequired,
    disabled: PropTypes.bool,
    className: PropTypes.string,
    children: PropTypes.oneOfType([
        PropTypes.arrayOf(PropTypes.node),
        PropTypes.node
    ]).isRequired
};
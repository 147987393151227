import React, { Fragment, useState, useEffect } from 'react';
import { FormTextInput, FormDateInput, FormMultiSelect, FormSwitch, FormNumericInput, FormPagedCheckboxList } from "components/form-fields";
import { ShowHide } from "components/layout";
import { useValidation } from "hooks/useValidation";
import PropTypes from 'prop-types';
import { EmployeeService, LocationService } from "services";
import { Intent } from '@blueprintjs/core';
import { NotificationToaster, NotificationInline } from "components/notifications";
import moment from "moment";
import { ReoccurrenceSettings } from './ReoccurrenceSettings';

export function PolicySettings(props) {
    const [isValid, errors, validate] = useValidation();
    const [loading, setLoading] = useState(true);
    const [policySettings, setPolicySettings] = useState(
        {
            name: "",
            startDate: "",
            endDate: "",
            employeeCategorys: [],
            employeeTypes: [],
            locations: [],
            resendSchedule: {
                reoccurring: false
            },
            useAllLocations: true,
            sendDebriefAlert: true,
            debriefAlertDelayDays: 0
        });
    const [driverTypes, setDriverTypes] = useState([]);
    const [driverCategories, setDriverCategories] = useState([]);
    const [locations, setLocations] = useState([]);
    const [totalLocationRecordCount, setTotalLocationRecordCount] = useState(0);
    const [loadingLocations, setLoadingLocations] = useState(true);

    const [reoccurrenceValid, setReoccurrenceValid] = useState(false);

    const maxNameLength= 255;
    const maxAlertDelayDays = 365;

    const locationFilter = {
        "filters":
            [
                { "key": "BusinessAreas", "value": "Tacho" },
                { "key": "States", "value": "Active" }
            ]
    };

    useEffect(initialLoad, []);

    function initialLoad() {
        EmployeeService.getEmployeeOptions().then(function (employeeOptions) {
            setDriverTypes(employeeOptions.employeeTypes);
            setDriverCategories(employeeOptions.employeeCategories);
            setLoading(false);
        },
            () => {
                NotificationToaster.show(Intent.DANGER, "Could not load settings. Please refresh the page.");
            }
        );

    }
    useEffect(onValidationChange, [isValid, reoccurrenceValid]);

    function onValidationChange() {
        props.onValidationUpdate(isValid && reoccurrenceValid);
    }

    function onReoccurrenceValidationUpdate(isReoccurrenceValid) {
        setReoccurrenceValid(isReoccurrenceValid);
    }

    useEffect(() => {
        setPolicySettings(props.policy);
    }, [props.policy]);

    useEffect(() => {
        setLoading(props.loading);
    }, [props.loading]);

    function onLocationsPageChange(listSize, pageNumber, debouncedSearchTerm) {
        setLoadingLocations(true);
        LocationService.getLocationList(listSize, pageNumber, debouncedSearchTerm, 'Name', 'A', locationFilter).then(pagedLocations => {
            setLocations(pagedLocations.data);
            setTotalLocationRecordCount(pagedLocations.totalCount);
            setLoadingLocations(false);
        }, (error) => {
            NotificationToaster.show(Intent.DANGER, "Could not load settings. Please refresh the page.");
        });
    }

    function onNameChange(event) {

        var updatedPolicySettings = {
            ...policySettings,
            name: event.target.value
        }
        setPolicySettings(updatedPolicySettings);
        props.onSettingsUpdate(updatedPolicySettings);
    }
    function onAlertDateDelayChange(value) {

        var updatedPolicySettings = {
            ...policySettings,
            debriefAlertDelayDays: value
        }
        setPolicySettings(updatedPolicySettings);
        props.onSettingsUpdate(updatedPolicySettings);
    }    
    function onstartDateChange(item) {
        var updatedPolicySettings = {
            ...policySettings,
            startDate: item ? moment(item).format("YYYY-MM-DD") : null
        }
        setPolicySettings(updatedPolicySettings);
        props.onSettingsUpdate(updatedPolicySettings);
    }

    function onendDateChange(item) {
        var updatedPolicySettings = {
            ...policySettings,
            endDate: item ? moment(item).format("YYYY-MM-DD") : null
        }
        setPolicySettings(updatedPolicySettings);
        props.onSettingsUpdate(updatedPolicySettings);
    }

    function onReoccurrenceChange(item) {
        var updatedPolicySettings = {
            ...policySettings,
            resendSchedule: item
        }
        setPolicySettings(updatedPolicySettings);
        props.onSettingsUpdate(updatedPolicySettings);
    }

    function hasValue(item, array) {
        return array.some(function (x) {
            return x.id === item.id
        });
    }
    function onChange(item, listName) {
        var temp = [...policySettings[listName]];

        if (hasValue(item, temp)) {
            return;
        }
        temp.push(item);
        var updatedPolicySettings = {
            ...policySettings,
            [listName]: temp
        }
        setPolicySettings(updatedPolicySettings);
        props.onSettingsUpdate(updatedPolicySettings);
    }
    function onDriverCategoriesChange(item) {
        onChange(item, 'employeeCategorys');
    }
    function onDriverTypesChange(item) {
        onChange(item, 'employeeTypes');
    }

    function onLocationsChange(item, isChecked) {
        if (isChecked) {
            const locationsClone = [...policySettings.locations];
            locationsClone.push(item);

            props.onSettingsUpdate({
                ...policySettings,
                locations: locationsClone
            });
        } else {
            const selectedLocationIndex = policySettings.locations.findIndex(x => x.id === item.id);
            onRemove(selectedLocationIndex, 'locations');
        }
    }

    function onRemove(index, listName) {
        var temp = [...policySettings[listName]];
        temp.splice(index, 1);
        var updatedPolicySettings = {
            ...policySettings,
            [[listName]]: temp
        }
        setPolicySettings(updatedPolicySettings);
        props.onSettingsUpdate(updatedPolicySettings);
    }
    function onDriverCategoriesRemove(tag, index) {
        onRemove(index, 'employeeCategorys');
    }
    function onDriverTypesRemove(tag, index) {
        onRemove(index, 'employeeTypes');
    }

    function onuseAllLocationsChange(event) {
        policySettings.useAllLocations = event.target.checked;
        if (policySettings.useAllLocations) {
            policySettings.locations = [];
        }
        props.onSettingsUpdate(policySettings);
    }
    function onsendDebriefAlertChange(event) {
        policySettings.sendDebriefAlert = event.target.checked;
        if (!policySettings.sendDebriefAlert) {
            policySettings.debriefAlertDelayDays = 0;
        }
        props.onSettingsUpdate(policySettings);
    }
    
    useEffect(updateValidationRules, [props.policy])

    function updateValidationRules() {
        let rules = [];

        if (!props.editing) {
            rules.push({ fieldName: "name", required: true, maxLength: maxNameLength });
            rules.push({ fieldName: "startDate", required: true });
            rules.push({ fieldName: "employeeCategorys", required: true });
            rules.push({ fieldName: "employeeTypes", required: true });

            if (props && props.policy && !props.policy.useAllLocations) {
                rules.push({ fieldName: "locations", required: true });
            }

            rules.push({ fieldName: "useAllLocations", required: true });
            rules.push({ fieldName: "debriefAlertDelayDays", required: true, minValue: 0,  maxValue: maxAlertDelayDays });            
        }

        validate(rules, props.policy);
    }

    return (
        <Fragment>
            <FormTextInput
                id="policy-name"
                loading={loading}
                value={policySettings.name}
                headingText="Policy Name:"
                placeholder="Policy name"
                onChange={onNameChange}
                disabled={props.disabled || props.saving}
                dangerHelperText={errors.name} >
            </FormTextInput>
            <FormMultiSelect
                id="driver-types"
                headingText="Which employee types would you like this policy to be applied to?"
                items={driverTypes}
                onItemSelect={onDriverTypesChange}
                selectedValues={policySettings.employeeTypes}
                dangerHelperText={errors.employeeTypes}
                placeholder="Apply employee types"
                loading={loading}
                disabled={props.disabled || props.saving}
                onRemove={onDriverTypesRemove}
            ></FormMultiSelect>
            <FormMultiSelect
                id="driver-categories"
                headingText="Apply to the following employee categories:"
                items={driverCategories}
                onItemSelect={onDriverCategoriesChange}
                selectedValues={policySettings.employeeCategorys}
                dangerHelperText={errors.employeeCategorys}
                placeholder="Apply employee categories"
                loading={loading}
                disabled={props.disabled || props.saving}
                onRemove={onDriverCategoriesRemove}
            ></FormMultiSelect>
            <FormSwitch
                disabled={props.disabled || props.saving}
                loading={loading}
                headingText="Apply to all locations"
                checked={policySettings.useAllLocations}
                onChange={onuseAllLocationsChange}>
            </FormSwitch>
            <ShowHide evaluator={!policySettings.useAllLocations}
                show={(
                    <FormPagedCheckboxList
                        id="locations"
                        onPageChange={onLocationsPageChange}
                        headingText="Apply to the following locations:"
                        items={locations}
                        totalItemCount={totalLocationRecordCount}
                        onItemSelect={onLocationsChange}
                        selectedItems={policySettings.locations}
                        dangerHelperText={errors.locations}
                        placeholder="Search locations"
                        loading={loading}
                        loadingPage={loadingLocations}
                        disabled={props.disabled}
                        saving={props.saving}
                    ></FormPagedCheckboxList>
                )}>
            </ShowHide>
            <FormDateInput
                id="policy-start-date"
                disabled={props.disabled || props.reoccurrenceSettingsDisabled || props.saving}
                headingText="Policy start date:"
                onChange={onstartDateChange}
                value={policySettings.startDate}
                loading={loading}
                dangerHelperText={errors.startDate}
                minDate={moment("2000-01-01").toDate()}
                maxDate={policySettings.endDate ? moment(policySettings.endDate).toDate() : null}
            ></FormDateInput>
            <NotificationInline
                id="notification-settings-disabled"
                loading={loading}
                show={props.reoccurrenceSettingsDisabled}
                text={props.reoccurrenceSettingsDisabledNotification}
                intent="info">
            </NotificationInline>
            <FormDateInput
                id="policy-end-date"
                disabled={props.disabled || props.saving}
                headingText="Policy end date:"
                onChange={onendDateChange}
                value={policySettings.endDate}
                loading={loading}
                dangerHelperText={errors.endDate}
                minDate={policySettings.startDate ? moment(policySettings.startDate).add(1, 'day').toDate() : moment("2000-01-01").toDate()}
            ></FormDateInput>
            <ReoccurrenceSettings
                loading={loading}
                reoccurrenceSettings={policySettings.resendSchedule}
                onReoccurrenceUpdate={onReoccurrenceChange}
                hideEndDates={true}
                hide445={true}
                onValidationUpdate={onReoccurrenceValidationUpdate}
                name="policy"
                startDate={policySettings.startDate}
                disabled={props.disabled || props.reoccurrenceSettingsDisabled || props.saving}
            ></ReoccurrenceSettings>
            <FormSwitch
                disabled={props.disabled || props.saving}
                loading={loading}
                label="Send employee declaration due alert for this policy"
                checked={policySettings.sendDebriefAlert}
                helperText="This will raise an employee declaration due alert for the drivers in the alerts. It will override any settings previously applied for the driver during the previous employee declarations."
                onChange={onsendDebriefAlertChange}>
            </FormSwitch>
            <ShowHide evaluator={policySettings.sendDebriefAlert }
                show={(
                    <FormNumericInput
                        disabled={props.disabled || props.saving}
                        min={0}
                        max={maxAlertDelayDays}
                        selectedValue={policySettings.debriefAlertDelayDays}
                        onValueChange={onAlertDateDelayChange}
                        headingText={"Employee declaration due alert delay in days:"}
                        helperText={(<p>These are the number of days you wish to wait before the employee declaration alert is sent. This can not exceed 365 days.</p>)}
                        dangerHelperText={errors.debriefAlertDelayDays}
                        loading={loading}
                    ></FormNumericInput>
                )}>
            </ShowHide>
        </Fragment>
    );
}
PolicySettings.defaultProps = {
    disabled: false,
    saving: false,
    reoccurrenceSettingsDisabled: false
};

PolicySettings.propTypes = {
    policy: PropTypes.object.isRequired,
    disabled: PropTypes.bool,
    saving: PropTypes.bool,
    reoccurrenceSettingsDisabled: PropTypes.bool,
    loading: PropTypes.bool.isRequired
};
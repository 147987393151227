import React from "react";
import { Overlay } from "@blueprintjs/core";
import PropTypes from "prop-types";

import { HelperFunctions } from "helpers";
import { ShowHide } from "components/layout";

export function ImageViewer(props) {
  function handleClose() {
    HelperFunctions.executeIfNotNull(props.onClose);
  }

  return (
    <Overlay
      isOpen={props.isOpen}
      onClose={handleClose}
      portalClassName="image-overlay"
    >
      <div className="bp3-dialog-container">
        <ShowHide
          evaluator={props.mediaSource != null}
          show={<img src={props.mediaSource} />}
        />
      </div>
    </Overlay>
  );
}

ImageViewer.defaultProps = {
  onClose: null,
};

ImageViewer.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  mediaSource: PropTypes.string,
  onClose: PropTypes.func,
};

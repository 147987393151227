import React from 'react';
import { Redirect, Route, Switch } from "react-router-dom";

import { PageRestricted } from 'pages/errors/page-restricted';
import { UserAccess } from 'components/user-access';
import { HorizontalLinkGroup } from 'components/navigation';
import { JourneyTypes } from 'pages/account/edit/alert-settings/journey-types/JourneyTypes';
import { AlertHistory } from 'pages/account/edit/alert-settings/history/AlertHistory';

export function AlertSettings(props) {
    const requiredAction = "TachoAlerts:Manage";

    const routes = [
        { location: "/account/alert-settings/journey-types", title: "Journey Types" },
        { location: `${window.env.VISIONMEMBER_URL}/#/alertsadmin?tab=dismissal-settings`, title: "Auto Dismiss", externalLink: true },
        { location: `${window.env.VISIONMEMBER_URL}/#/alertsadmin?tab=vehicle-odometer`, title: "Alert Specific Settings", externalLink: true },
        { location: "/account/alert-settings/history", title: "History" }
    ];

    return (
        <UserAccess perform={requiredAction}
            yes={() => (
                <div className="row">

                    <h2>Alert Settings</h2>
                    <div className="intro-text">
                        <p>Here you can manage all settings for your alerts.</p>
                    </div>

                    <div className="spacer-bottom">
                        <HorizontalLinkGroup links={routes} exactRouteMatch={false}></HorizontalLinkGroup>

                    </div>

                    <Switch>
                        <Route path="/account/alert-settings/journey-types">
                            <JourneyTypes />
                        </Route>

                        <Route path="/account/alert-settings/history">
                            <AlertHistory />
                        </Route>

                        <Redirect exact from="/account/alert-settings" to="/account/alert-settings/journey-types" />

                    </Switch>


                </div>
            )}
            no={() => (
                <div className="row">
                    <PageRestricted />
                </div>
            )}
        />
    );
}
import React, { useState, useEffect } from 'react';
import axios from 'axios';

import { Intent } from '@blueprintjs/core';
import { AccountService, EmployeeService, UserService, AssetService  } from "services";
import { useDirtyData } from "hooks/useDirtyData";
import { NotificationToaster } from "components/notifications";
import { MigrationWizard } from "components/wizard";
import { MigrationEmployeeSettings, MigrationEmployeeBehaviours, MigrationLocationsSelector, MigrationAssetSettings } from 'components/settings';

var _ = require('underscore');

export function AccountMigration(props) {
    const defaultEmployeeSettings = {
        employeeTypes: [],
        employeeStatuses: [],
        employeeCategories: [],
        locationsMatch: [],
        migrateAllEmployees: false,
        migrateBannedStatus: true
    };

    const defaultAssetSettings = {
        assetTypes: [],
        assetStatuses: [],
        assetOwnerships: [],
        locationsMatch: [],
        migrateAllAssets: false
    };

    const entityList = [
        { id: "USERS", name: "Users and Driver App Users", checked: false, disabled: false, successMessage: "Users are migrated immediately." },
        { id: "EMPLOYEES", name: "Employees", checked: false, disabled: false, wizardSteps: [], successMessage: "Employee migration could take up to 6 hours before being completely transferred." },
        { id: "ASSETS", name: "Assets", checked: false, disabled: false, wizardSteps: [], successMessage: "Asset migration could take up to 6 hours before being completely transferred." }
    ];

    const [originalSettings, setOriginalSettings] = useState({});
    const [loading, setLoading] = useState(true);
    const [saving, setSaving] = useState(false);
    const [accountList, setAccountList] = useState([]);
    const [currentAccount, setCurrentAccount] = useState({});
    const [selectedAccount, setSelectedAccount] = useState({});
    const [allowUserMove, setAllowUserMove] = useState(true);
    const [selectedEntities, setSelectedEntities] = useState([]);
    const [employeeOptions, setEmployeeOptions] = useState({});
    const [employeeSettings, setEmployeeSettings] = useState(defaultEmployeeSettings);
    const [employeeSettingsAreValid, setEmployeeSettingsAreValid] = useState(false);
    const [assetOptions, setAssetOptions] = useState({});
    const [assetSettings, setAssetSettings] = useState(defaultAssetSettings);
    const [assetSettingsAreValid, setAssetSettingsAreValid] = useState(false);

    const [entities, setEntities] = useState(entityList);

    const isWizardDirty = useDirtyData(originalSettings, selectedAccount);

    useEffect(initialLoad, []);

    function initialLoad() {
        axios.all([
            UserService.getLoggedInUser(),
            AccountService.getAllAccounts(),
            EmployeeService.getEmployeeOptions(),
            AssetService.getAssetOptions()
        ]).then(axios.spread(function (userDetails, accounts, employeeOptionsResponse, assetOptionsResponse) {
            setLoading(false);

            var current = _.findWhere(accounts.data, { id: userDetails.accountId });
            current.name = `${current.memberNumber} - ${current.name}`;
            setCurrentAccount(current);

            var relatedAccounts = _.some(accounts.data, function (account) {
                return (account.id === userDetails.accountId && account.parentId != null) || (account.parentId === userDetails.accountId);
            });

            setAllowUserMove(!relatedAccounts);

            var selectableAccounts = _.filter(accounts.data, function (account) {
                return account.id !== userDetails.accountId;
            });

            setAccountList(selectableAccounts.map(function (account) {
                account.name = `${account.memberNumber} - ${account.name}`;
                return account;
            }));

            setEmployeeOptions(employeeOptionsResponse);
            setAssetOptions(assetOptionsResponse);
        }), () => {
            NotificationToaster.show(Intent.DANGER, "Could not load options. Please refresh the page.");
        });
    }

    useEffect(function () {
        if (entities != null) {
            var selected = _.filter(entities, function (e) {
                return e.checked;
            });
            setSelectedEntities(selected);
        }

    }, [entities]);

    useEffect(() => {
        const employeeEntityWizardSteps = [
            {
                key: 'EMPLOYEE-OPTIONS',
                valid: employeeSettingsAreValid,
                step: <MigrationEmployeeSettings
                    key="employee-settings"
                    settings={employeeSettings}
                    employeeOptions={employeeOptions}
                    saving={saving}
                    onSettingsChange={onEmployeeSettingsChange}
                    onValidationChange={onEmployeeSettingsValidationChange}
                />
            },
            {
                key: 'EMPLOYEE-BEHAVIOUR',
                valid: employeeSettingsAreValid,
                step: <MigrationEmployeeBehaviours
                    key="employee-behaviours"
                    settings={employeeSettings}
                    saving={saving}
                    onSettingsChange={onEmployeeSettingsChange}
                />
            },
            {
                key: 'EMPLOYEE-LOCATIONS',
                valid: employeeSettings?.locationsMatch?.length > 0,
                step: <MigrationLocationsSelector
                    key="location-selector"
                    toAccountId={selectedAccount?.id}
                    settings={employeeSettings}
                    saving={saving}
                    onSettingsChange={onEmployeeSettingsChange}
                    entityTitle="Employees"
                    additionalLocationFilters={[{ "key": "MustHaveEmployees", "value": "true" }]}
                />
            }
        ];

        const assetEntityWizardSteps = [
            {
                key: 'ASSET-OPTIONS',
                valid: assetSettingsAreValid,
                step: <MigrationAssetSettings
                    key="asset-settings"
                    assetSettings={assetSettings}
                    assetOptions={assetOptions}
                    saving={saving}
                    onAssetSettingsChange={onAssetSettingsChange}
                    onValidationChange={onAssetSettingsValidationChange}
                />
            },
            {
                key: 'ASSET-LOCATIONS',
                valid: assetSettings?.locationsMatch?.length > 0,
                step: <MigrationLocationsSelector
                    key="location-selector"
                    toAccountId={selectedAccount?.id}
                    settings={assetSettings}
                    saving={saving}
                    onSettingsChange={onAssetSettingsChange}
                    entityTitle="Assets"
                    additionalLocationFilters={[{ "key": "MustHaveAssets", "value": "true" }]}
                />
            }
        ];
        const entityClone = [...entities];

        const employeeEntity = entityClone.find(x => x.id.toUpperCase() === 'EMPLOYEES');
        employeeEntity.wizardSteps = employeeEntityWizardSteps;

        const assetEntity = entityClone.find(x => x.id.toUpperCase() === 'ASSETS');
        assetEntity.wizardSteps = assetEntityWizardSteps;

        const userEntity = entityClone.find(x => x.id.toUpperCase() === 'USERS');
        userEntity.disabled = !allowUserMove;

        setEntities(entityClone);
    }, [allowUserMove, employeeSettings, employeeSettingsAreValid, saving, selectedAccount, assetSettings, assetSettingsAreValid]);

    function onTargetAccountSelect(item) {
        setSelectedAccount(item);
    }

    function resetComponent() {
        setOriginalSettings({});
        setSaving(false);
        setSelectedEntities([]);
        setSelectedAccount({});
        setEmployeeSettings(defaultEmployeeSettings);
        setAssetSettings(defaultAssetSettings);
        setEntities(entityList);
    }

    function onEntitySelected(selected, entityId) {
        const entitiesClone = [...entities];

        const entity = entitiesClone.find(x => x.id === entityId);
        entity.checked = selected;

        setEntities(entitiesClone);
    }

    function getTransferConfirmationList() {
        const affectedEntityCountPromises = [];

        if (userEntitySelected()) {
            const userPromise = UserService.getUserList(1, 1, '', '', '', [])
                .then(users => <span key='users'>{users.totalCount} users</span>);

            affectedEntityCountPromises.push(userPromise);
        }

        if (employeeEntitySelected()) {
            let filters = [];

            if (!employeeSettings.migrateAllEmployees) {
                filters = filters.concat(mapLookupsToFilter(employeeSettings.employeeTypes, 'type'));
                filters = filters.concat(mapLookupsToFilter(employeeSettings.employeeCategories, 'category'));
                filters = filters.concat(mapLookupsToFilter(employeeSettings.employeeStatuses, 'status'));
            }

            filters = filters.concat(mapLookupsToFilter(employeeSettings.locationsMatch.map(x => x.fromLocation), 'location'));

            const employeesRequest = {
                ignoreLocationPermissions: true,
                requestCount: 1,
                pageNumber: 1,
                filters: filters
            };

            const employeePromise = EmployeeService.searchEmployees(employeesRequest)
                .then(employees => <span key='employees'>{employees.totalCount} employees</span>);

            affectedEntityCountPromises.push(employeePromise);
        }

        if (assetEntitySelected()) {
            let filters = [];

            if (!assetSettings.migrateAllAssets) {
                filters = filters.concat(mapLookupsToFilter(assetSettings.assetTypes, 'assettype'));
                filters = filters.concat(mapLookupsToFilter(assetSettings.assetOwnerships, 'ownership'));
                filters = filters.concat(mapLookupsToFilter(assetSettings.assetStatuses, 'status'));
            }

            filters = filters.concat(mapLookupsToFilter(assetSettings.locationsMatch.map(x => x.fromLocation), 'location'));

            const assetsRequest = {
                requestCount: 1,
                pageNumber: 1,
                filters: filters
            };

            const assetPromise = AssetService.searchAssets(assetsRequest)
                .then(assets => <span key='assets'>{assets.totalCount} assets</span>);

            affectedEntityCountPromises.push(assetPromise);
        }

        return axios.all(affectedEntityCountPromises);
    }

    function mapLookupsToFilter(lookups, key) {
        return lookups.map(x => {
            return {
                key: key,
                value: x.name
            };
        });
    }

    function onTransferEntitiesConfirm() {
        setSaving(true);
        var promiseList = [];

        for (var i = 0; i < selectedEntities.length; i++) {
            var entityKey = selectedEntities[i].id.toUpperCase();

            switch (entityKey) {
                case "USERS":
                    promiseList.push(UserService.bulkMoveUsers(selectedAccount.id));
                    break;
                case "EMPLOYEES":
                    const bulkMoveEmployeeSettings = {
                        toAccount: selectedAccount.id,
                        ...employeeSettings,
                        locationsMatch: employeeSettings.locationsMatch.map(x => {
                            return {
                                fromLocation: x.fromLocation.id,
                                toLocation: x.toLocation.id
                            };
                        })
                    };
                    promiseList.push(EmployeeService.bulkMoveEmployeesForAccount(bulkMoveEmployeeSettings));
                    break;
                case "ASSETS":
                    const bulkMoveAssetSettings = {
                        toAccount: selectedAccount.id,
                        ...assetSettings,
                        locationsMatch: assetSettings.locationsMatch.map(x => {
                            return {
                                fromLocation: x.fromLocation.id,
                                toLocation: x.toLocation.id
                            };
                        })
                    };

                    promiseList.push(AssetService.bulkMoveAssetsForAccount(bulkMoveAssetSettings));
                    break;
                default:
                    break;
            }
        }
        return axios.all(promiseList);
    }

    function onTransferEntitiesCancel() {
        setSaving(false);
    }

    function onEmployeeSettingsChange(item) {
        setEmployeeSettings({ ...item });
    }

    function onEmployeeSettingsValidationChange(item) {
        setEmployeeSettingsAreValid(item);
    }

    function employeeEntitySelected() {
        return selectedEntities.some(x => x.id.toUpperCase() === 'EMPLOYEES');
    }

    function onAssetSettingsChange(item) {
        setAssetSettings({ ...item });
    }

    function onAssetSettingsValidationChange(item) {
        setAssetSettingsAreValid(item);
    }

    function assetEntitySelected() {
        return selectedEntities.some(x => x.id.toUpperCase() === 'ASSETS');
    }

    function userEntitySelected() {
        return selectedEntities.some(x => x.id.toUpperCase() === 'USERS');
    }

    return <MigrationWizard
        entities={entities}
        onTargetEntitySelect={onTargetAccountSelect}
        targetEntityList={accountList}
        onReset={resetComponent}
        onEntitySelected={onEntitySelected}
        getTransferConfirmationList={getTransferConfirmationList}
        onTransferConfirm={onTransferEntitiesConfirm}
        onTransferCancel={onTransferEntitiesCancel}
        isWizardDirty={isWizardDirty}
        sourceEntityName={currentAccount?.name ? currentAccount.name : ''}
        targetEntityTitle="Account"
        loading={loading}
        saving={saving}
        selectedTargetEntity={selectedAccount}
        entitySelectionWarning={allowUserMove ? '' : 'You cannot perform an account migration for users whilst the account is still linked to another account'}
    />;
}
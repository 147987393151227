import { NotificationInline } from "components/notifications";
import { UserAccess } from "components/user-access";
import { PageRestricted } from "pages/errors/page-restricted";
import React, { Fragment } from "react";
import { EmployeeAssessmentBandSettings } from "../shared/band-settings";
import { EmployeeAssessmentGeneralSettings } from "../shared/general-settings";
import PropTypes from 'prop-types';
import { Wizard } from "components/wizard";

export function EmployeeAssessmentSettingsCreate(props) {
    return (
        <UserAccess perform={["EmployeeAssessment:Manage"]}
            yes={() => (
                <div className="row">
                    <Fragment>
                        <Wizard
                            onStepChange={() => null}
                            onFinish={props.onSave}
                            canProceed={props.isValid}
                            disabled={props.saving}
                        >
                            <div>
                                <h2 id="header-employee-assessment-create-general">Employee Assessment Settings</h2>
                                <EmployeeAssessmentGeneralSettings
                                    loading={props.loading}
                                    disabled={props.saving}
                                    generalSettings={props.employeeAssessmentSettings}
                                    employeeTypes={props.employeeTypes}
                                    employeeCategories={props.employeeCategories}
                                    onGeneralSettingsUpdate={props.onGeneralSettingsUpdate}
                                    onValidationUpdate={props.onValidationUpdate}
                                />
                            </div>

                            <div>
                                <h2 id="header-employee-assessment-create-green">Green Band Settings</h2>
                                <EmployeeAssessmentBandSettings
                                    loading={props.loading}
                                    disabled={props.saving}
                                    bandSettings={props.employeeAssessmentSettings.greenBandSettings}
                                    alertTypes={props.alertTypes}
                                    onBandSettingsUpdate={(settings) => props.onBandSettingsUpdate(settings, 'greenBandSettings')}
                                    onValidationUpdate={props.onValidationUpdate}
                                />
                            </div>

                            <div>
                                <h2 id="header-employee-assessment-create-amber">Amber Band Settings</h2>
                                <EmployeeAssessmentBandSettings
                                    loading={props.loading}
                                    disabled={props.saving}
                                    bandSettings={props.employeeAssessmentSettings.amberBandSettings}
                                    alertTypes={props.alertTypes}
                                    onBandSettingsUpdate={(settings) => props.onBandSettingsUpdate(settings, 'amberBandSettings')}
                                    onValidationUpdate={props.onValidationUpdate}
                                />
                            </div>

                            <div>
                                <h2 id="header-employee-assessment-create-red">Red Band Settings</h2>
                                <EmployeeAssessmentBandSettings
                                    loading={props.loading}
                                    disabled={props.saving}
                                    bandSettings={props.employeeAssessmentSettings.redBandSettings}
                                    alertTypes={props.alertTypes}
                                    onBandSettingsUpdate={(settings) => props.onBandSettingsUpdate(settings, 'redBandSettings')}
                                    onValidationUpdate={props.onValidationUpdate}
                                />
                                <NotificationInline
                                    id="notification-employee-assessments"
                                    loading={props.loading}
                                    show={true}
                                    text="Once saved Vision will create assessments for the selected employees immediately."
                                    intent="info">
                                </NotificationInline>
                            </div>
                        </Wizard>
                    </Fragment>
                </div>
            )}
            no={() => (
                <PageRestricted />
            )}
        />
    );
}

EmployeeAssessmentSettingsCreate.propTypes = {
    employeeAssessmentSettings: PropTypes.object.isRequired,
    saving: PropTypes.bool,
    loading: PropTypes.bool,
    employeeTypes: PropTypes.array.isRequired,
    employeeCategories: PropTypes.array.isRequired,
    alertTypes: PropTypes.array.isRequired,
    onValidationUpdate: PropTypes.func.isRequired,
    onGeneralSettingsUpdate: PropTypes.func.isRequired,
    onBandSettingsUpdate: PropTypes.func.isRequired,
    onSave: PropTypes.func.isRequired,
    isDataDirty: PropTypes.bool.isRequired,
    isValid: PropTypes.bool.isRequired,
    onRefresh: PropTypes.func.isRequired
};
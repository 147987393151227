import React, { useState, useEffect } from 'react';
import { Intent } from '@blueprintjs/core';

import { Tooltip } from 'components/tooltip';
import { ShowHide } from "components/layout";
import { ListingTable } from "components/listing";
import { UserAccess, UserHasAccess } from 'components/user-access';
import { PageRestricted } from 'pages/errors/page-restricted';
import { LocationService } from 'services';
import { Button, LinkButton } from 'components/buttons';
import { HelperFunctions } from 'helpers';
import { AlertConfirm, NotificationToaster } from "components/notifications";
import { Tabs } from "components/navigation";

export function LocationContactListing(props) {

    const sortableHeaders = [
        { headerName: "Name", sortName: "Name" },
        { headerName: "Email Address", sortName: "Email Address" },
        { headerName: "Contact Type", sortName: "Contact Type" }
    ];

    const requiredAction = "Locations:Manage";
    const canAccessPage = UserHasAccess(requiredAction);
    const [totalRecords, setTotalRecords] = useState(0);
    const [tableRows, setTableRows] = useState([]);
    const [tableSortBy, setTableSortBy] = useState(sortableHeaders[1]);
    const [tableSortDir, setTableSortDir] = useState("A");
    const [loading, setLoading] = useState(true);
    const [showDeleteConfirm, setShowDeleteConfirm] = useState(false);
    const [selectedContactId, setselectedContactId] = useState(0);
    const [currentTab, setCurrentTab] = useState(0);
    const contactsTab = 0;
    const exContactsTab = 1;

    const [tabs, setTabs] = useState([
        { key: "CONTACTS", title: "Contacts", selected: true },
        { key: "EXTERNAL CONTACTS", title: "External Contacts" },
    ]);

    const tableHeaders = ["Email Address", "Name", "Phone Number", "Contact Type", "Actions"];

    function onTableSort(header, direction) {
        setTableSortDir(direction);
        setTableSortBy(header);

        //sort the data locally
        var clonedResults = [
            ...tableRows
        ];

        var index = tableHeaders.indexOf(header.headerName);

        clonedResults = HelperFunctions.sortAlphabetically(clonedResults, index);

        if (direction === 'D') {
            clonedResults = clonedResults.reverse();
        }

        setTableRows(clonedResults);
    }

    function deleteContact(contactId) {
        setselectedContactId(contactId);
        setShowDeleteConfirm(true);
    }

    function onDeleteCancel() {
        setselectedContactId(0);
        setShowDeleteConfirm(false);
    }

    function onDeleteConfirm() {
        setShowDeleteConfirm(false);

        var contactString = 'Contact '

        //if 1 then deleting external contact
        if ((currentTab === exContactsTab)) {
            contactString = 'External contact ';
        }        

        var successMessage = `${contactString}has been successfully deleted`;
        var errorMessage = `Unable to delete the ${contactString}, please try again. `;

        LocationService.deleteContact(selectedContactId, (currentTab === 1)).then(() => {
            NotificationToaster.show(Intent.SUCCESS, successMessage);
            fetchData(currentTab === exContactsTab);
        }, function () {
            NotificationToaster.show(Intent.DANGER, errorMessage);
        }).finally(function () {
            setselectedContactId(0);
        });
    }

    function checkIfUserCanDelete(data, contact, external) {

        var contactType = contact.contactTypeKey;
        var contactId = external ? contact.locationExternalContactId : contact.locationContactId;

        var foundType = data.findIndex(function (c) {
            var locationContactId = external ? c.locationExternalContactId : c.locationContactId;
            
            return c.contactTypeKey === contactType && locationContactId !== contactId;
        });

        return foundType > -1;
    }

    function onTabClick(index) {

        var clonedTabs = [
            ...tabs
        ];

        clonedTabs = clonedTabs.map(function (t, i) {
            return {
                ...t,
                selected: index === i
            }
        });

        setTabs(clonedTabs);
        setCurrentTab(index);
        if (index === 0) {
            fetchData();
        } else {
            fetchData(true)
        }
    }

    function renderTable(data, external) {
        let tableData = data.map((d) => {
            var contactId = external ? d.locationExternalContactId : d.locationContactId;
            
            return [
                d.emailAddress,
                `${d.firstName} ${d.lastName}`,
                d.phoneNumber,
                d.contactType,
                <ShowHide
                    key={d.locationContactId}
                    evaluator={currentTab === 0 && checkIfUserCanDelete(data, d, external)}
                    show={(
                        <Button onClick={() => deleteContact(contactId)} icon="trash" iconOnly text="Delete Contact" large={false} minimal></Button>
                    )}
                    hide={(
                        <Tooltip content="There must be at least one contact of this type">
                            <Button onClick={() => { }} disabled icon="trash" iconOnly text="Delete Contact" large={false} minimal></Button>
                        </Tooltip>
                    )}>
                </ShowHide>
                
            ];
        })

        setTableRows(tableData);
        setTotalRecords(data.length);
        setLoading(false);

    }

    function fetchData(external) {
        if (props.locationId != null && props.locationId !== 0 && canAccessPage) {

            setLoading(true);

            if (external) {
                LocationService.getExternalContacts(props.locationId).then(function (data) {

                    renderTable(data, external);
                }, function () {

                });
            } else {
                LocationService.getContacts(props.locationId).then(function (data) {

                    renderTable(data,external);

                }, function () {

                });
            }
        }
    }

    useEffect(fetchData, [props.id]);

    return (
        <UserAccess perform={requiredAction}
            yes={() => (
                <div className="row">

                    <h1>Contacts</h1>
                    <div className="intro-text">
                        <p>Configure contacts related to this location. Contacts should be users who are responsible for performing tasks related to the area they have been assigned to.</p>
                    </div>

                    <div className="spacer-bottom">
                        <Tabs tabs={tabs} onClick={onTabClick} loading={loading} />
                    </div>


                    <ShowHide
                        evaluator={currentTab === contactsTab}
                        show={(
                            <div className="spacer-bottom">
                                <LinkButton intent="primary" text="Add new contacts" href={`/location/${props.locationId}/contacts/create`} id="new-contact" />
                            </div>
                        )}
                    />

                    <ShowHide
                        evaluator={currentTab === exContactsTab}
                        show={(
                            <div className="spacer-bottom">
                                <LinkButton intent="primary" text="Add new external contacts" href={`/location/${props.locationId}/externalcontacts/create`} id="new-ex-contact" />
                            </div>
                        )}
                    />

                    <ListingTable
                        id="listing-table-location-contacts"
                        noDataMessage={currentTab === contactsTab ? "No contacts have been created for this location" : "No external contacts have been created for this location"}
                        sortable
                        sortableHeaders={sortableHeaders}
                        sortedBy={tableSortBy}
                        sortedDir={tableSortDir}
                        onSort={onTableSort}
                        headers={tableHeaders}
                        data={tableRows}
                        totalRecordCount={totalRecords}
                        loadingData={loading}
                        pageable={false}
                    />

                    <AlertConfirm
                        title="Please confirm"
                        isOpen={showDeleteConfirm}
                        onConfirm={onDeleteConfirm}
                        onCancel={onDeleteCancel}
                    >
                        <p>Are you sure you want to delete this contact?</p>
                    </AlertConfirm>

                </div>

                
            )}
            no={() => (
                <PageRestricted />
            )}
        />
    );
}
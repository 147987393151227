import React, { useState } from 'react';
import { AuditingTable } from "components/auditing";
import { AccountService } from "services";

export function AccountChangelog(props) {

    const noDataMessage = "It looks like no previous changes have been made to your account settings.";
    const [tableData, setTableData] = useState(null);
    const [tableRecordCount, setTableRecordCount] = useState(0);

    function onTableChange(pageSize, pageNumber, startDate, endDate) {
        AccountService.getChangelog(pageSize, pageNumber, startDate, endDate).then(r => {
            setTableData(r.data);
            setTableRecordCount(r.totalCount);
        });
    }

    return (
        <AuditingTable data={tableData} totalCount={tableRecordCount} onChange={onTableChange} noDataMessage={noDataMessage}></AuditingTable>
    );

}
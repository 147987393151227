import React, { useState, useEffect } from 'react';
import { MenuItem, Position } from "@blueprintjs/core";
import { MultiSelect } from "@blueprintjs/select";
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { FormHelperText } from "components/form-fields";
import 'components/form-fields/FormFields.css';
import { ShowHide } from 'components/layout';


export function FormMultiSelect(props) {

	const [selectedItems, setSelectedItems] = useState([]);
	const minFilterLength = 3;
	useEffect(setItemsFromValues, [props.selectedValues, props.items]);

	function setItemsFromValues() {
		var items = [];
		if (props.selectedValues && props.items && props.selectedValues.length > 0 && props.items.length > 0) {
			//Try and find the item in the list
			props.selectedValues.forEach(function (v) {
				const item = getItemFromValue(v.id);

				if (item) {
					items.push(item);
				}
			});
		}
		setSelectedItems(items);
	}

	function filteritems(query, item) {
		if (props.largeList && query.length < minFilterLength ) {
			return false;
        }
		return item.name.toLowerCase().indexOf(query.toLowerCase()) >= 0;
	}

	function getItemFromValue(id) {
		return props.items.find(obj => obj.id === id)
	}

	function isSelected(item) {		
		return props.selectedValues.some(function (x) {
			return x.id === item.id
		});
	}

	function renderSelect(item, { handleClick, modifiers }) {	
		return (
			<MenuItem
				icon={isSelected(item) ? "tick" : "blank"}
				onClick={handleClick}
				key={item.id}
				text={item.name}
				disabled={modifiers.disabled}
				shouldDismissPopover={false}
			/>
		);
	}

	function tagRender(item) {
		return item.name;
    }

	return (
		<div className="form-field form-field-multi-select" id={props.id}>
			<ShowHide
				evaluator={props.headingText.length > 0}
				show={(
					<h4 className={classNames({ "bp3-skeleton": props.loading })}>{props.headingText}</h4>
				)}
			>
			</ShowHide>
			
			<FormHelperText loading={props.loading}>{props.helperText}</FormHelperText>
			<MultiSelect
				className={classNames({ "bp3-skeleton": props.loading })}
				items={props.items}
				onItemSelect={props.onItemSelect}
				itemRenderer={renderSelect}
				tagRenderer={tagRender}
				filterable={false}
				popoverProps={{ minimal: true, position: Position.BOTTOM_LEFT, boundary: "window" }}
				disabled={props.disabled}
				selectedItems={selectedItems}
				placeholder={props.placeholder}
				resetOnSelect={props.resetOnSelect}
				itemPredicate={filteritems}
				tagInputProps={{
					onRemove: props.onRemove,
					disabled: props.disabled
				}}
			>
			</MultiSelect>
			<FormHelperText loading={props.loading} danger={true}>{props.dangerHelperText}</FormHelperText>
		</div>
	);
		
}

FormMultiSelect.defaultProps = {
	id: null,
	placeholder: "Choose an item",
	items: [],
	loading: false,
	headingText: "",
	helperText: null,
	disabled: false,
	selectedValues: null,
	dangerHelperText: null,
	resetOnSelect: true,
	largeList: false
};

FormMultiSelect.propTypes = {
    id: PropTypes.string,
	placeholder: PropTypes.string,
	items: PropTypes.array,
	onItemSelect: PropTypes.func.isRequired,
	onRemove: PropTypes.func.isRequired,
	dynamicWidth: PropTypes.bool,
	loading: PropTypes.bool,
	headingText: PropTypes.string,
	helperText: PropTypes.node,
	disabled: PropTypes.bool,
	selectedValues: PropTypes.array,
	dangerHelperText: PropTypes.node,
	resetOnSelect: PropTypes.bool,
	largeList: PropTypes.bool
};
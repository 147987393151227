import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';

import { Intent } from '@blueprintjs/core';
import { Breadcrumbs } from 'components/navigation';
import { NotificationToaster, AlertUnsavedData } from "components/notifications";
import { ButtonSave } from "components/buttons";
import classNames from "classnames";
import { useDirtyData } from 'hooks/useDirtyData';
import { CollisionsService } from "services";
import { UserAccess } from 'components/user-access';
import { PageRestricted } from 'pages/errors/page-restricted';
import { CollisionSettings } from '../shared';
import { useValidation } from 'hooks/useValidation';
import { DateToLocal } from 'components/dates';
import { FormReadonly } from 'components/form-fields';

export function CollisionEdit(props) {
    const { id } = useParams();
  
    const [originalSettings, setOriginalSettings] = useState({});
    const [loading, setLoading] = useState(true);
    const [saving, setSaving] = useState(false);
    const [collision, setCollision] = useState({});

    const isDirty = useDirtyData(originalSettings, collision);
    const [isValid, errors, validate] = useValidation();
    const [selectedAsset, setSelecedAsset] = useState(null);

    const requiredAction = "Collisions:Import";
    const validationErrorResponse = 400;

    useEffect(initialLoad, [id]);
    function initialLoad() {

        CollisionsService.getCollision(id).then(function (data) {
            setCollision(data);
            setOriginalSettings(data);
            setLoading(false);
            setSelecedAsset({"id": data.assetId, "name": `${data.assetRegistration} - ${data.assetLocation}`})
        }, function () {
            NotificationToaster.show(Intent.DANGER, "Unable to load collision. Please try again")
        });
    }

    function onCollisionUpdate(field, value) {
        setCollision((prev) => {
            var newInstance = {
                ...prev
            };
            newInstance[field] = value;
            return newInstance;
        });
    }
  
    function saveCollision() {
        setSaving(true);

        CollisionsService.updateCollision(id, collision).then(
            () => {
                setSaving(false);
                setOriginalSettings(collision);
                NotificationToaster.show(Intent.SUCCESS, "Collision has been updated successfully")
            },
            (error) => {
                setSaving(false);
                if (error.response.status === validationErrorResponse) {
                    NotificationToaster.show(Intent.WARNING, error.response.data);
                } else {
                    NotificationToaster.show(Intent.DANGER, "Unable to edit collision, please try again.");
                }
                
            }
        );
    }

    function validateModel() {
        const rules = [
            { fieldName: "employeeId", required: true },
            { fieldName: "assetId", required: true },
            { fieldName: "incidentDate", required: true }
        ];

        validate(rules, collision);
    }

    useEffect(validateModel, [collision]);

    return (

        <UserAccess perform={requiredAction}
            yes={() => (

                <div className="row">
                    <Breadcrumbs items={props.breadcrumbs} />

                    <h1 className={classNames({ "bp3-skeleton": loading })}>Collision on <DateToLocal format="DD/MM/YYYY">{collision.incidentDate}</DateToLocal> at <DateToLocal format="HH:mm">{collision.incidentDate}</DateToLocal></h1>

                    <FormReadonly
                        headingText="Employee involved in the collision:"
                        value={collision.employeeName}
                        loading={loading}
                    />

                    <CollisionSettings onCollisionUpdate={onCollisionUpdate} collision={collision} errors={errors} loading={loading} saving={saving} selectedAsset={selectedAsset} />

                    <AlertUnsavedData
                        isDirty={isDirty}>
                    </AlertUnsavedData>

                    <ButtonSave text="Save collision" simpleDisabled={!isValid} disabled={saving} loading={loading} onClick={saveCollision} />

                </div>

            )}
            no={() => (
                <PageRestricted />
            )}
        />


    );
}
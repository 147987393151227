import ApiService from 'services/ApiService';

const badRequestCode = 400;

export const HierarchyService = {
	getHierarchyList(pageSize, pageNumber, searchTerm, sortField, sortDir) {
		return new Promise((resolve, reject) => {
			ApiService.get(`/location/Hierarchies?requestCount=${pageSize}&pageNumber=${pageNumber}&searchTerm=${encodeURI(searchTerm)}&sortField=${encodeURI(sortField)}&sortDirection=${sortDir}`).then(
				(result) => {
					resolve(result.data);
				},
				(error) => {
					reject(error);
				});
		});
	},
	getHierarchy(hierarchyId) {
		return new Promise((resolve, reject) => {
			ApiService.get(`/location/Hierarchies/${hierarchyId}`).then(
				(result) => {
					resolve(result.data);
				},
				(error) => {
					reject(error.response);
				});
		});
	},
	getHierarchyStructure(hierarchyId) {
		return new Promise((resolve, reject) => {
			ApiService.get(`/location/Hierarchies/${hierarchyId}/structure`).then(
				(result) => {
					resolve(result.data);
				},
				(error) => {
					reject(error.response);
				});
		});
    },
	getLocationHierarchyFeatures() {
		return new Promise((resolve, reject) => {
			ApiService.get(`/location/HierarchyFeatures`).then(
				(result) => {
					resolve(result.data);
				},
				(error) => {
					reject(error.response);
				}
			);
		});
	},
	getHierarchyForFeature(featureId) {
		return new Promise((resolve, reject) => {
			ApiService.get(`/location/HierarchyFeatures/${featureId}/hierarchy`).then(
				(result) => {
					resolve(result.data);
				},
				(error) => {
					reject(error.response);
				});
		});
	},
	getChangelog(hierarchyId, pageSize, pageNumber, startDate, endDate) {
		return new Promise((resolve, reject) => {
			ApiService.get(`/location/hierarchies/${hierarchyId}/changelog?resultCount=${pageSize}&pageNumber=${pageNumber}&startDate=${startDate}&endDate=${endDate}`).then(
				(result) => {
					resolve(result.data);
				},
				(error) => {
					reject(error);
				});
		});

	},
	deleteHierarchy(hierarchyId, convertUserPermissions) {
		return new Promise((resolve, reject) => {
			ApiService.delete(`/location/Hierarchies/${hierarchyId}`, {
				data: {
					deleteUserPermissions: !convertUserPermissions
				}
			}).then(
				(result) => {
					resolve(result);
				},
				(error) => {
					reject(error);
				}
			);
		});
	},
	createLocationHierarchy(hierarchySettings) {
		return new Promise((resolve, reject) => {
			ApiService.post(`/location/Hierarchies`, hierarchySettings)
				.then((response) => {
					resolve(response.data);
				})
				.catch((error) => {
					if (error.response.status === badRequestCode) {
						reject(error.response.data);
					} else {
						reject("Could not create your location hierarchy. Please try again.");
					}
				});
		});
	},
	updateHierarchySettings(hierarchyId, hierarchySettings, convertUserPermissions) {

		return new Promise((resolve, reject) => {
			ApiService.put(`/location/Hierarchies/${hierarchyId}`, {
				...hierarchySettings,
				deleteUserPermissions: !convertUserPermissions
			})
				.then((response) => {
					resolve(response.data);
				})
				.catch((error) => {
					reject(error);
				});
		});
	},
	updateHierarchyStructure(hierarchyId, hierarchyStructure, convertUserPermissions) {
		return new Promise((resolve, reject) => {
			ApiService.put(`/location/Hierarchies/${hierarchyId}/structure`, {
				deleteUserPermissions: !convertUserPermissions,
				structure: hierarchyStructure
			})
				.then((response) => {
					resolve(response.data);
				})
				.catch((error) => {
					reject(error);
				});
		});
	},
	exportHierarchy(hierarchyId) {
		return new Promise((resolve, reject) => {
			ApiService.get(`/location/Hierarchies/${hierarchyId}/export`, {
				responseType: 'blob'
			})
				.then((response) => {
					resolve(response);
				})
				.catch((error) => {
					reject(error);
				});
		});
    }
};


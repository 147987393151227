import React, { useEffect, useState } from "react";
import PropTypes from 'prop-types';
import axios from 'axios';

import { RiskService } from "services/RiskService";
import { FormHelperText, FormNumericInput, FormSwitch } from "components/form-fields";
import { Tabs } from "components/navigation";
import { Table } from "components/listing";
import { ShowHide } from "components/layout";
import { Tooltip } from "components/tooltip";
import { Button, ButtonSave } from "components/buttons";
import { AlertUnsavedData, Modal, NotificationInline, NotificationToaster } from "components/notifications";
import { Icon, Intent } from "@blueprintjs/core";
import { useDirtyData } from "hooks/useDirtyData";


export function RiskEventSettings(props) {

    const [riskCategories, setRiskCategories] = useState([]);
    const [tabEvents, setTabEvents] = useState([]);
    const [events, setEvents] = useState([]);
    const tableHeaders = formatParamTableHeaders();
    const [modifiedEvents, setModifiedEvents] = useState([]);
    const [saving, setSaving] = useState(false);
    const [loading, setLoading] = useState(true);
    const [modalEvent, setModalEvent] = useState(null);
    const [pendingEventsExist, setPendingEventsExist] = useState(false);
    const isDataDirty = useDirtyData(0, modifiedEvents.length);
    const [selectedTab, setSelectedTab] = useState(0);
    

    useEffect(init, [props.areaId]);

    function init() {

        setLoading(true);
        setModifiedEvents([]);
        setEvents([]);

        if (props.areaId == null) {
            return;
        }

        axios.all([
            RiskService.getCategories(props.areaId),
            RiskService.getEvents(props.areaId)
        ]).then(axios.spread(function (categoryResponse, eventResponse) {

            setRiskCategories(categoryResponse.map(function (c, categoryIndex) {
                return {
                    title: c.name,
                    key: c.id,
                    selected: categoryIndex === 0
                }
            }));
            setEvents(eventResponse);
            setPendingEventsExist(eventResponse.some(e => e.pendingChange != null));
            setLoading(false);

        }), function () {
            NotificationToaster.show(Intent.DANGER, "Settings could not be loaded. Please try loading the page again");
        });
    }

    function onTabClick(tabIndex) {
        var clonedCats = riskCategories.map(function (c, index) {
            return {
                ...c,
                selected: index === tabIndex
            }
        });

        var categoryId = clonedCats[tabIndex].key;
        var selectedEvents = events.filter(e => e.categoryId === categoryId);
        setSelectedTab(tabIndex);

        setRiskCategories(clonedCats);
        setTabEvents(selectedEvents);
    }

    function formatParamTableHeaders() {

        let paramHeaders = ["Event", "Enabled", "Recommended Weighting", "Weighting", ""];

        return {
            headers: paramHeaders.map((value) => {
                return {
                    key: value,
                    value: value
                }
            })
        };
    }

    function onEventChange(enabled, weighting, eventId) {
        setModifiedEvents((prev) => {
            var clonedEvents = [...prev];
            var matchedEventIndex = clonedEvents.findIndex(e => e.eventId === eventId);
            if (matchedEventIndex === -1) {
                clonedEvents.push({
                    eventId: eventId,
                    weighting: weighting,
                    enabled: enabled
                });
            } else {
                var matchedEvent = clonedEvents[matchedEventIndex];
                matchedEvent.weighting = weighting;
                matchedEvent.enabled = enabled;

                var previousEvent = events.find(e => e.id === eventId);
                if (previousEvent != null) {

                    var latestSettings = previousEvent.pendingChange != null ? previousEvent.pendingChange : previousEvent.accountEvent;

                    //If the event was previously disabled and the pending change is also disabled, remove the pending change
                    if (latestSettings == null && !matchedEvent.enabled) {
                        clonedEvents.splice(matchedEventIndex, 1);
                    }

                    //If the event was previously enabled and the pending change is also enabled and the weightings match, remove the pending change
                    if (latestSettings != null && matchedEvent.enabled && latestSettings.weighting === matchedEvent.weighting) {
                        clonedEvents.splice(matchedEventIndex, 1);
                    }
                }
            }

            return clonedEvents;
        });
    }

    function showPendingInfo(event) {
        setModalEvent(event);
    }

    function onModalDismiss() {
        setModalEvent(null);
    }

    function formatParamTable(tableEvents) {
        if (tableEvents == null) {
            return [];
        }

        return tableEvents.map(function (event) {

            var currentValue = event.defaultWeighting;

            if (event.pendingChange != null) {
                currentValue = event.pendingChange.weighting;
            }
            else if (event.accountEvent != null) {
                currentValue = event.accountEvent.weighting;
            }

            var currentlyEnabled = event.pendingChange != null ? event.pendingChange.enabled : event.accountEvent != null;

            var modifiedEvent = modifiedEvents.find(e => e.eventId === event.id);
            if (modifiedEvent != null) {
                currentValue = modifiedEvent.weighting;
                currentlyEnabled = modifiedEvent.enabled;
            }

            return {
                cells: [
                    <div key={`event-title-${event.id}`}>
                        <p><strong>{event.name}</strong></p>
                        <p className="description-text">{event.description}</p>
                    </div>,
                    <FormSwitch
                        onChange={(ev) => { onEventChange(ev.currentTarget.checked, currentValue, event.id) }}
                        checked={currentlyEnabled}
                        loading={loading}
                        disabled={saving}
                        key={`event-enabled-${event.id}`}
                        id={`input-event-switch-${event.key}`}
                    ></FormSwitch>,
                    event.defaultWeighting,
                    <FormNumericInput
                        disabled={!currentlyEnabled || saving}
                        min={1}
                        max={250}
                        key={`event-weighting-${event.id}`}
                        loading={loading}
                        selectedValue={currentValue}
                        onValueChange={(ev) => { onEventChange(currentlyEnabled, ev, event.id) }}
                        id={`input-event-input-${event.key}`}
                    ></FormNumericInput>,
                    <div className="button-row-small" key={`event-action-${event.id}`}>
                        <ShowHide
                            evaluator={event.pendingChange != null}
                            show={(
                                <Tooltip content="Event has a pending change, click for more info">
                                    <Button onClick={() => showPendingInfo(event)} icon="time" iconOnly={true} large={false} minimal={true}></Button>
                                </Tooltip>
                            )}>
                        </ShowHide>

                    </div>
                ],
                key: event.key
            };
        });
    }

    function onSaveEvents() {
        setSaving(true);

        RiskService.saveEvents(props.areaId, modifiedEvents).then(function () {
            NotificationToaster.show(Intent.SUCCESS, "Events saved successfully");
            init();
        }, function (error) {
            NotificationToaster.show(Intent.DANGER, error);
        }).finally(function () {
            setSaving(false);
        });
            
    }

    useEffect(function () {
        if (events.length > 0 && riskCategories.length > 0) {
            onTabClick(selectedTab);
        }
    }, [events.length, riskCategories.length]);


    return (

        <div className='row'>
            <h2>{props.areaName} Event Settings</h2>
                
            <FormHelperText loading={false}>
                <p>Within this page you can configure which events you want to contribute towards your risk scores and how much impact each event has.</p>
            </FormHelperText>

            <NotificationInline
                intent="primary"
                show={!loading && pendingEventsExist}
                allowClose={false}
                text={(
                    <span>
                        There are saved pending changes to your risk events. These changes will take effect from the start of your next risk period. Click the &nbsp;<Icon icon="time" />&nbsp; icon next to the event for more information.
                    </span>
                )}
            />

            <div className="spacer-bottom">
                <Tabs tabs={riskCategories} onClick={onTabClick} loading={loading} />
            </div>

            <div className="spacer-bottom">

                <Table
                    headerData={tableHeaders}
                    data={formatParamTable(tabEvents)}
                    loadingData={loading}
                    fitToContent={false}
                />
            </div>

            <NotificationInline intent="warning" text="Because a risk period is currently in progress, any changes you make will not take effect until the next period begins" show={props.riskStarted && modifiedEvents.length > 0} />
            <AlertUnsavedData isDirty={isDataDirty}></AlertUnsavedData>
            <ButtonSave onClick={onSaveEvents} disabled={saving} simpleDisabled={modifiedEvents.length === 0} loading={loading} />

            <Modal
                isOpen={modalEvent != null}
                title={"Event Changes"}
                onClose={onModalDismiss}
            >
                <p>This event has been modified during the current period and the following changes will take place from the start of your next risk period:</p>
                <div className="inline-items spacer-small">
                    <div className="inline-item"><strong>Enabled:</strong></div>                      
                    <div className="inline-item">{modalEvent?.accountEvent != null ? "Yes" : "No"}</div> 
                    <div className="inline-item"><Icon icon="arrow-right" /></div>
                    <div className="inline-item">{modalEvent?.pendingChange.enabled ? "Yes" : "No"}</div>
                </div>
                <div className="inline-items">
                    <div className="inline-item"><strong>Weighting:</strong></div>
                    <div className="inline-item">{modalEvent?.accountEvent != null ? modalEvent?.accountEvent.weighting : "-"}</div>
                    <div className="inline-item"><Icon icon="arrow-right" /></div>
                    <div className="inline-item">{modalEvent?.pendingChange.enabled ? modalEvent?.pendingChange.weighting : "-"}</div>
                </div>
            </Modal>

        </div>

    );
}

RiskEventSettings.propTypes = {
    areaId: PropTypes.number.isRequired,
    areaName: PropTypes.string.isRequired,
    riskStarted: PropTypes.bool.isRequired
};

import React, { Fragment, useEffect, useState } from 'react'; 
 
import { Route, Switch, Redirect, useLocation } from 'react-router-dom' 
 
import { AccountGeneralSettings } from 'pages/account/edit/general-settings'; 
import { AccountTachoSettings } from 'pages/account/edit/tacho-settings'; 
import { AccountChangelog } from 'pages/account/edit/changelog'; 
import { AccountInternalConfiguration } from 'pages/account/edit/internal-configuration'; 
import { AccountMigration } from 'pages/account/edit/migration'; 
import { AuthenticatedRoute, UserAccess, UserHasAccess } from 'components/user-access'; 
import { Integrations } from './integrations'; 
import { VerticalLinkGroup, Breadcrumbs } from 'components/navigation'; 
import { PageRestricted } from 'pages/errors/page-restricted'; 
import { AccountSsoConfiguration } from './sso-configuration'; 
import { AlertSettings } from 'pages/account/edit/alert-settings/AlertSettings'; 

export function Account(props) {
    const [currentRoute, setCurrentRoute] = useState([]);

    let routes = [
        { location: "/account/general-settings", title: "General Settings" },
        { location: "/account/tacho-settings", title: "Tacho Settings" },
    ];

    if (UserHasAccess("Integrations:Manage")) {
        routes.push({ location: "/account/integrations", title: "Integrations" })
    }

    if (UserHasAccess("TachoAlerts:Manage")) {
        routes.push({ location: "/account/alert-settings", title: "Alert Settings" }); 
    }

    if (UserHasAccess("Performance:Manage")) {
        routes.push({ location: `${window.env.VISIONADMIN_URL}/#/perfadmin`, title: "Performance settings", externalLink: true }) 
    }
    
    routes.push({ location: "/account/changes", title: "History" });

    if (UserHasAccess("internal")) {
        routes.push({ location: "/account/configuration", title: "Internal Configuration" })
        routes.push({ location: "/account/migration", title: "Account Migration" })
    }

    if (UserHasAccess('SSO:Manage')) {
        routes.push({ location: '/account/sso-configuration', title: 'SSO Configuration' });
    }

    let location = useLocation();

    useEffect(findCurrentRoute, [location]);

    function findCurrentRoute() {
        const result = routes.find((l) => {
            return location.pathname.indexOf(l.location) === 0;
        });

        setCurrentRoute([result]);
    }

    return (
        <UserAccess perform={["Account:Manage", "Internal"]}
                yes={() => (
                    <Fragment>
                        <div className="row">

                            <Breadcrumbs items={props.breadcrumbs.concat(currentRoute)} />

                            <h1>Account Settings</h1>
                            <div className="intro-text">
                                <p>In this section you can configure your account settings to suit the different services provided by Logistics UK.</p>
                            </div>
                        </div>

                        <div className="layout-cols">
                            <div className="col side-col">
                                <div className="row">
                                    <VerticalLinkGroup links={routes} exactRouteMatch={false}></VerticalLinkGroup>
                                </div>
                            </div>
                            <div className="col content-col">
                                <Switch>
                                    <Route path="/account/general-settings"> 
                                        <AccountGeneralSettings />
                                    </Route> 
                                    <Route exact path="/account" render={() => (<Redirect to="/account/general-settings" />)} /> 
 
                                    <Route path="/account/tacho-settings"> 
                                        <AccountTachoSettings />
                                    </Route> 
                                     
                                    <Route path="/account/alert-settings"> 
                                        <AlertSettings /> 
                                    </Route> 
 
                                    <Route path="/account/integrations"> 
                                        <Integrations /> 
                                    </Route> 
 
                                    <Route path="/account/changes"> 
                                        <AccountChangelog /> 
                                    </Route> 
                                    
                                    <AuthenticatedRoute path="/account/configuration" action="Internal"> 
                                        <AccountInternalConfiguration /> 
                                    </AuthenticatedRoute> 
 
                                    <AuthenticatedRoute path="/account/migration" action="Internal"> 
                                        <AccountMigration /> 
                                    </AuthenticatedRoute> 
 
                                    <AuthenticatedRoute path="/account/sso-configuration" action="SSO:Manage"> 
                                        <AccountSsoConfiguration /> 
                                    </AuthenticatedRoute> 
                                </Switch>
                            </div>


                        </div>
                    </Fragment>
            )}
            no={() => (
                <PageRestricted />
            )}
            />
      
    );

}
import React, { Fragment, useState, useEffect, useRef } from 'react';
import { Icon, Intent, Button, NonIdealState, Popover, PopoverInteractionKind, Position } from '@blueprintjs/core';
import { NotificationToaster } from "components/notifications";
import { SystemMessagesService, AccountService, ReportingService, EmployeeService, AssetService, LinkService } from 'services';
import { useSelector, useDispatch } from 'react-redux';
import { FormHelperText, FormTextInput } from "components/form-fields";
import { Widget } from 'pages/home-page/widget';
import { ShowHide } from 'components/layout';
import { PreferencesModal } from "components/preferences-modal/PreferencesModal";
import axios from 'axios';
import moment from "moment";
import Cookies from 'js-cookie';
import './HomePage.css';
import { Table } from "components/listing";
import { useDebounce } from 'hooks/useDebounce';
import { Tooltip } from "components/tooltip";
import { KeyBlock } from "components/key/KeyBlock";
import classNames from 'classnames';

export function HomePage(props) {

    const [loading, setLoading] = useState(true);
    const [timeOfDay, setTimeOfDay] = useState("");
    const [companyMessage, setCompanyMessage] = useState(null);
    const [systemMessages, setSystemMessages] = useState([]);
    const [displayMessageIds, setDisplayMessageIds] = useState([]);
    const [displayMessages, setDisplayMessages] = useState([]);
    const [widgets, setWidgets] = useState([]);
    const [selectedWidgets, setSelectedWidgets] = useState([]);
    const [showPreferencesModal, setShowPreferencesModal] = useState(false);
    const [preferencesUpdated, setPreferencesUpdated] = useState(false);
    const dispatch = useDispatch();
    const lastActivity = useSelector(state => state.loggedInUser.previousLoginDate);
    const companyMessageId = 10;
    const systemMessageDismiss = "System-Message-Dismiss-";
    const hour12 = 12;
    const hour17 = 17;
    const homePageCookie = 'Use_New_Home_Page';
    const visionLinkToReplace = "#vision#";

    const debounceTimeout = 750;
    const [searchTerm, setSearchTerm] = useState("");
    const debouncedSearchTerm = useDebounce(searchTerm, debounceTimeout);

    const [isSearching, setIsSearching] = useState(true);
    const [assetSearchResultData, setAssetSearchResultData] = useState([]);
    const [assetTotalRecords, setAssetTotalRecords] = useState(0);
    const inputSearchRef = useRef(null);
    const [showSearchPopover, setShowSearchPopover] = useState(false);
    const [assetData, setAssetData] = useState([]);
    const [isOverlayVisible, setIsOverlayVisible] = useState(false);

    const driverSearchHeaders = {
        headers: [
            { key: 'NAME', value: 'Driver' },
            { key: 'TYPE', value: '' },
            { key: 'CALENDAR', value: '' },
            { key: 'LOCATION', value: 'Location' },
            { key: 'REFERENCE', value: 'Reference' },
        ]
    };
    const vehicleSearchHeaders = {
        headers: [
            { key: 'REGISTRATION', value: 'ID / Registration' },
            { key: 'TYPE', value: '' },
            { key: 'CALENDAR', value: '' },
            { key: 'LOCATION', value: 'Location' },
            { key: 'FLEET', value: 'Fleet Number' },
        ]
    };
    const [employeeSearchResultData, setEmployeeSearchResultData] = useState([]);
    const [employeeTotalRecords, setEmployeeTotalRecords] = useState(0);
    const [employeeData, setEmployeeData] = useState([]);
    const searchLength = 3;
    const initialDate = moment().startOf('isoWeek');
    const [startDate] = useState(initialDate);

    const systemMessageLookups = [
        { id: 1, icon: "info-sign", messageHeader: "Maintenance", colourClass: "maintenance-colour", showClose: true, order: 1 },
        { id: 2, icon: "warning-sign", messageHeader: "Incident", colourClass: "incident-colour", showClose: true, order: 0 },
        { id: 3, icon: "notifications", messageHeader: "General", colourClass: "general-colour", showClose: true, order: 2 },
        { id: 10, icon: "chat", messageHeader: "Company", colourClass: "company-colour", showClose: false }
    ];

    function getStatusIcon(status, ownership, isEmployee = false) {
        const defaultColour = "FF7454";
        const statusMappings = {
            asset: [
                { Id: 1, Colour: "00C6B2" },
                { Id: 2, Colour: "EC2D6D" },
                { Id: 3, Colour: defaultColour }
            ],
            employee: [
                { Id: 1, Colour: "00C6B2" },
                { Id: 2, Colour: defaultColour },
                { Id: 3, Colour: "EC2D6D" }
            ]
        };

        const statusAttributeMappings = isEmployee ? statusMappings.employee : statusMappings.asset;
        const statusAttribute = statusAttributeMappings.find(o => o.Id === status.id);
        const statusColour = statusAttribute?.Colour ?? defaultColour;

        return (
            <Tooltip position={Position.TOP} content={`${status?.name ?? ''} ${ownership?.name ?? ''}`} usePortal={false}>
                <KeyBlock rounded={true} icon={""} className={`status-icon`} colours={`#${statusColour}`} size={8} />
            </Tooltip>
        );
    }



    function onSearchTextChange(item) {
        setSearchTerm(item);
    }

    function highlightText(text, searchText) {
        if (!searchText) return text;
        const regex = new RegExp(`(${searchText})`, 'gi');
        return text.split(regex).map((part, i) =>
            regex.test(part) ? <span key={`${part}-${i}`} className="highlight">{part}</span> : part
        );
    }

    function openSearchPopover() {
        setShowSearchPopover(true);
        setIsOverlayVisible(true);
    }

    function closeSearchPopover() {
        setAssetSearchResultData([]);
        setEmployeeSearchResultData([]);
        setAssetTotalRecords(0);
        setEmployeeTotalRecords(0);
        setIsSearching(true);
        setShowSearchPopover(false);
        setIsOverlayVisible(false);
        setSearchTerm("");
    }

    function clearSearch() {
        setAssetSearchResultData([]);
        setEmployeeSearchResultData([]);
        setAssetTotalRecords(0);
        setEmployeeTotalRecords(0);
        setIsSearching(true);
    }

    function performSearch() {
        openSearchPopover();

        clearSearch();

        const searchPayload = getSearchPayload();

        AssetService.searchAssets(searchPayload).then((response) => {
            if (response.totalCount === 0) {
                setAssetSearchResultData([]);
                setAssetTotalRecords(response.totalCount);
                setIsSearching(false);
                return;
            }

            if (response.data != null) {
                setAssetSearchResultData(formatDataFromAssetSearchResult(response.data));
            }

            setAssetTotalRecords(response.totalCount);
            setIsSearching(false);
        })
            .catch((error) => {
                NotificationToaster.show(Intent.DANGER, `Asset search failed. Please try again. ${error} `);
                setIsSearching(false);
            });

        EmployeeService.searchEmployees(searchPayload).then((response) => {
            if (response.totalCount === 0) {
                setEmployeeSearchResultData([]);
                setEmployeeTotalRecords(response.totalCount);
                setIsSearching(false);
                return;
            }

            if (response.data != null) {
                setEmployeeSearchResultData(formatDataFromEmployeeSearchResult(response.data));
            }

            setEmployeeTotalRecords(response.totalCount);
            setIsSearching(false);
        })
            .catch((error) => {
                NotificationToaster.show(Intent.DANGER, `Employee search failed. Please try again. ${error} `);
                setIsSearching(false);
            });
    }

    function getSearchPayload() {
        return {
            requestCount: 10,
            pageNumber: 1,
            searchTerm: searchTerm
        };
    }

    function formatDataFromAssetSearchResult(apiData) {
        return apiData.map((x) => {
            return {
                id: x.assetId,
                status: x.assetStatus,
                registration: x.assetRegistration,
                type: x.assetType.id,
                location: x.location.name,
                fleetNumber: x.fleetNumber
            };
        });
    }

    function formatDataFromEmployeeSearchResult(apiData) {
        return apiData.map((x) => {
            return {
                id: x.employeeId,
                status: x.status,
                name: x.fullName,
                type: x.type.id,
                location: x.location.name,
                reference: x.reference
            };
        });
    }

    function displayEmployeeData() {
        setEmployeeData(
            employeeSearchResultData.map(x => {
                return {
                    key: x.id,
                    cells: [
                        [getStatusIcon(x.status, "", true), highlightText(x.name, debouncedSearchTerm)],
                        LinkService.renderEmployeeProfileLink(x.id, false),
                        LinkService.renderEmployeeCalendarLink(x.id, startDate, false),
                        x.location,
                        highlightText(x.reference, debouncedSearchTerm)
                    ],
                };
            })
        );
    }

    useEffect(() => {
        if (loading || debouncedSearchTerm === '') {
            if (debouncedSearchTerm === '') {
                clearSearch();
                setShowSearchPopover(false);
            }
            return;
        }
        if (debouncedSearchTerm.length < searchLength) { return; }
        performSearch();
    }, [debouncedSearchTerm]);

    useEffect(() => {
        displayAssetData();
    }, [assetSearchResultData]);

    useEffect(() => {
        displayEmployeeData();
    }, [employeeSearchResultData]);

    function displayAssetData() {
        setAssetData(
            assetSearchResultData.map(x => {
                return {
                    key: x.id,
                    cells: [
                        [getStatusIcon(x.status, ""), highlightText(x.registration, debouncedSearchTerm)],
                        LinkService.renderVehicleProfileLink(x.id, false),
                        LinkService.renderAssetCalendarLink(x.id, startDate, false),
                        x.location,
                        highlightText(x.fleetNumber, debouncedSearchTerm)
                    ],
                };
            })
        );
    }

    function closeSystemMessageNotification(systemMessageID) {
        localStorage.setItem(`${systemMessageDismiss}${systemMessageID}`, systemMessageID);
        setDisplayMessages(current => [...current.filter(x => x.key !== `display-message-${systemMessageID}`)]);
    }

    function getMessage(smLookups, systemMessage) {
        let urlLink = null;
        let closeButton = null;

        if (systemMessage && systemMessage.url) {
            urlLink = (<strong><a href={decodeURIComponent(systemMessage.url)} target="_blank">Read More</a></strong>);
        }

        if (smLookups.showClose) {
            closeButton = (<Button icon="cross" minimal={true} onClick={() => closeSystemMessageNotification(systemMessage.systemMessageID)} />);
        }

        return (<div className={`inline-items inline-items-space system-message ${smLookups.colourClass}`} key={`display-message-${systemMessage.systemMessageID}`}>
            <div className="inline-items inline-item">
                <div className="inline-item system-message-icon">
                    <Icon icon={smLookups.icon} />
                </div>
                <div className="inline-item">
                    <span><strong>{smLookups.messageHeader} message:</strong> {systemMessage.message} </span>
                    {urlLink}
                </div>
            </div>
            {closeButton}
        </div>);
    };

    function checkForCookie(cookieName) {
        return Cookies.get(cookieName) !== undefined;
    }


    const handleSavePreferences = (newPreferences) => {
        setWidgets(newPreferences);
        savePreferences();
    };

    const handleSwitchBackClick = () => {
        window.location.href = `/beta-features`;
    };

    function savePreferences() {


        const redirectIfCookieDoesntExist = () => {
            const cookieExists = checkForCookie(homePageCookie);
            if (!cookieExists) {
                window.location.href = `${window.env.VISION_URL}/#/`;
            }
        };

        redirectIfCookieDoesntExist();
        setLoading(true);
        dispatch({ type: 'SITE_FULL_WIDTH' });
        let hoursNow = new Date().getHours();
        let tod = "";

        switch (true) {
            case (hoursNow >= 0 && hoursNow < hour12):
                tod = "morning";
                break;
            case (hoursNow >= hour12 && hoursNow < hour17):
                tod = "afternoon";
                break;
            case (hoursNow >= hour17):
                tod = "evening";
                break;
        }

        setTimeOfDay(tod);

        axios.all([
            SystemMessagesService.getAllSystemMessages(true),
            AccountService.getGeneralSettings(),
            ReportingService.getWidgets()
        ]).then(axios.spread(function (sysMessages, genSettings, widgetResults) {
            setSystemMessages(sysMessages);
            setCompanyMessage(genSettings.companyMessage);
            setSelectedWidgets(selectedWidgetMapper(widgetResults).sort((a, b) => a.userPriority - b.userPriority));
            setWidgets(widgetResults);
            setLoading(false);
        }), () => {
            NotificationToaster.show(Intent.DANGER, "Could not load details. Please refresh the page.");
        });
    }

    useEffect(savePreferences, []);

    function selectedWidgetMapper(ws) {
        if (ws.length < 1) {
            return [];
        }

        ws.forEach((w, index) => {
            if (w.linkUrl != null && w.linkUrl.toLowerCase().includes(visionLinkToReplace)) {
                w.linkUrl = w.linkUrl.toLowerCase().replace(visionLinkToReplace, `${window.env.VISION_URL}/#`);
            }
        });

        return ws.filter(w => w.userSelected).sort(function (a, b) { return a.userOrder - b.userOrder; });
    }

    function systemMessagesSort(a, b) {
        let aDetails = systemMessageLookups.find(x => x.id === a.systemMessageTypeID);
        let bDetails = systemMessageLookups.find(x => x.id === b.systemMessageTypeID);

        if (aDetails.order < bDetails.order) {
            return -1;
        } else if (aDetails.order > bDetails.order) {
            return 1;
        }
        //Equal
        return 0;
    }

    function messageAlreadyExists(id) {
        //Logically this should never happen in live, but locally these were reloading when you saved self admin
        return displayMessageIds.includes(id);
    }

    useEffect(() => {
        if (companyMessage && companyMessage.length > 0) {
            if (messageAlreadyExists(-1)) {
                return;
            }
            let m = systemMessageLookups.find(x => x.id === companyMessageId);
            let systemMessage = { systemMessageID: -1, message: companyMessage };
            setDisplayMessages(current => [getMessage(m, systemMessage), ...current]);
            setDisplayMessageIds(current => [...current, -1]);
        }
    }, [companyMessage]);

    useEffect(() => {
        systemMessages.filter(sm => { return sm.active }).sort(systemMessagesSort).forEach(sm => {
            if (messageAlreadyExists(sm.systemMessageID)) {
                return;
            }
            let dismissed = localStorage.getItem(`${systemMessageDismiss}${sm.systemMessageID}`);
            if (!dismissed) {
                let m = systemMessageLookups.find(x => x.id === sm.systemMessageTypeID);
                setDisplayMessages(current => [...current, getMessage(m, sm)]);
                setDisplayMessageIds(current => [...current, sm.systemMessageID]);
            }
        });
    }, [systemMessages]);

    useEffect(() => {
        if (preferencesUpdated) {
            setLoading(true);
            setPreferencesUpdated(false);
        }
    }, [preferencesUpdated]);

    function openPreferencesModal() {
        setShowPreferencesModal(true);
    }

    function closePreferencesModal() {
        setShowPreferencesModal(false);
    }

    return (
        <Fragment>
            <div className={isOverlayVisible ? "overlay" : ""} onClick={closeSearchPopover} aria-hidden="true"></div>
            <div className="notification-inline" id="home-messages">
                {displayMessages}
            </div>

            <div className="row" id="home-welcome">
                <h1>Good {timeOfDay}, welcome to Vision</h1>
                <FormHelperText loading={loading}>
                    <p>You previously logged into Vision on: <strong>{moment(lastActivity).local().format("dddd DD MMMM yyyy")} at {moment(lastActivity).local().format("HH:mm")}</strong></p>
                </FormHelperText>
            </div>
            <div className="row spacer-bottom-small">
                <span>You can manage your page preferences </span>
                <a
                    href="/beta-features"
                    onClick={handleSwitchBackClick}
                >
                    here
                </a>
                <span>.</span>
            </div>
            <div className="row search-row">
                <div className={classNames({ sticky: isOverlayVisible })}>
                    <Popover
                        isOpen={showSearchPopover}
                        position={Position.BOTTOM_LEFT}
                        usePortal={false}
                        minimal={true}
                        interactionKind={PopoverInteractionKind.CLICK}
                        modifiers={{
                            flip: { enabled: false },
                        }}
                        onInteraction={(nextOpenState, e) => {
                            if (nextOpenState) {
                                setIsOverlayVisible(true);
                            } else if (e && e.target && inputSearchRef.current && !inputSearchRef.current.contains(e.target)) {
                                setShowSearchPopover(false);
                            }
                        }}
                        content={
                            <div className="popover-content">
                                <h1>Search Results</h1>
                                <span>{`${employeeTotalRecords + assetTotalRecords} Results, ${employeeTotalRecords} Drivers${employeeTotalRecords > 10 ? " (10 shown)" : ""}, ${assetTotalRecords} Vehicles${assetTotalRecords > 10 ? " (10 shown)" : ""}.`}</span>
                                <div className="scrollable-table">
                                    <ShowHide
                                        evaluator={employeeTotalRecords > 0}
                                        show={(
                                            <>
                                                <h2>Drivers</h2>
                                                <Table
                                                    headerData={driverSearchHeaders}
                                                    data={employeeData}
                                                />
                                            </>)}
                                    />
                                    <ShowHide
                                        evaluator={assetTotalRecords > 0}
                                        show={(
                                            <>
                                                <h2>Vehicles</h2>
                                                <Table
                                                    headerData={vehicleSearchHeaders}
                                                    data={assetData}
                                                />
                                            </>
                                        )}
                                    />
                                    <ShowHide
                                        evaluator={employeeTotalRecords === 0 && assetTotalRecords === 0 && !isSearching}
                                        show={(
                                            <NonIdealState
                                                title="No results found."
                                            />)}
                                    />
                                </div>
                            </div>
                        }
                    >
                        <div className="search-container">
                            <FormTextInput
                                placeholder="Search vehicles and drivers"
                                onChange={(x) => onSearchTextChange(x.target.value)}
                                value={searchTerm}
                                large
                                disabled={loading}
                                icon="search"
                                id="user-search-field"
                                ultraWide={true}
                                inputRef={inputSearchRef}
                                onFocus={() => openSearchPopover()}
                                className="search-input"
                            />
                            <Icon
                                className={`remove-button ${searchTerm.length > 0 ? 'active' : ''}`}
                                icon="cross"
                                onClick={() => {
                                    clearSearch();
                                    setSearchTerm("");
                                    inputSearchRef.current.focus();
                                }}
                            />

                        </div>
                    </Popover>
                </div>
            </div>
            <div className={classNames('row', 'search-row', { "below-search": isOverlayVisible })}>
                <div id="widget-header">
                    <h3>Widgets</h3>
                    <Button text="Widget Settings" minimal={true} large={false} intent="secondary" icon="cog" id="configure-properties" onClick={openPreferencesModal} loading={loading} />
                </div>
                <PreferencesModal
                    isOpen={showPreferencesModal}
                    onClose={closePreferencesModal}
                    displayPreferences={widgets}
                    cancelButtonIntent={Intent.NONE}
                    onSave={handleSavePreferences}
                    onPreferencesSaved={() => setPreferencesUpdated(true)}
                    onLoadPreferences={ReportingService.getWidgets}
                    onSavePreferences={ReportingService.saveWidgetOrder}
                    preferencesKey={"Widget"}
                />
            </div>
            <div>
                <ShowHide
                    evaluator={!loading && widgets.length > 0}
                    show={(
                        <div id="widget-container">
                            {selectedWidgets.map(function (widget) {
                                return (
                                    <Widget widget={widget} loading={loading} key={widget.id}></Widget>
                                );
                            })}
                            <ShowHide
                                evaluator={!loading && selectedWidgets.length === 0}
                                show={(
                                    <div className="row spacer-top-small">
                                        <NonIdealState
                                            title="You have no widgets selected"
                                            description={<Fragment>To select widgets to view please click on the widget settings button above.</Fragment>}
                                        />
                                    </div>
                                )}
                            />
                        </div>
                    )}
                />
            </div>
        </Fragment>
    );

}
import React, { useState, useEffect, Fragment } from 'react';
import axios from 'axios';
import { NonIdealState } from "@blueprintjs/core";
import classNames from 'classnames';


import { Tabs } from 'components/navigation';
import { FormGroupedCheckboxList, FormSwitch } from 'components/form-fields';
import { NotificationInline } from "components/notifications";

import { HierarchyService, LocationService } from "services";
import { ShowHide } from 'components/layout';

export function UserLocationSettings(props) {

    const [items, setItems] = useState([]);
    const [businessAreaTabs, setBusinessAreaTabs] = useState([]);
    const [loading, setLoading] = useState(true);
    const [currentPermissions, setCurrentPermissions] = useState(null);
    const [showActiveOnly, setShowActiveOnly] = useState(false);

    useEffect(function () {

        //Get the hierarchy features so we can pull out the ones with our permissions
        HierarchyService.getLocationHierarchyFeatures().then(function (featureResponse) {

            //Request each hierarchy for the features and add a tab if exists
            axios.all([
                fetchPermissionHierarchy("tachopermissions", featureResponse.hierarchyFeaturesList, "Tacho"),
                fetchPermissionHierarchy("vispermissions", featureResponse.hierarchyFeaturesList, "VIS"),
                fetchPermissionHierarchy("auditpermissions", featureResponse.hierarchyFeaturesList, "Audit")
            ]).then(axios.spread(function (tachoHierarchy, visHierarchy, auditHierarchy) {

                var tabs = [];

                if (tachoHierarchy != null) {
                    tabs.push({
                        title: "Tacho Permissions", hierarchy: tachoHierarchy, key: "TACHO"
                    });
                }

                if (visHierarchy != null) {
                    tabs.push({
                        title: "VIS Permissions", hierarchy: visHierarchy, key: "VIS"
                    });
                }

                if (auditHierarchy != null) {
                    tabs.push({
                        title: "Audit Permissions", hierarchy: auditHierarchy, key: "AUDIT"
                    });
                }

                setBusinessAreaTabs(tabs);
                setLoading(false);

            }));

        });

    }, []);

    //When the current permissions get updated update the main permissions model
    useEffect(function () {

        if (currentPermissions == null) {
            return;
        }

        var clonedPermissions = [
            ...props.permissions
        ];

        clonedPermissions = clonedPermissions.filter(p => p.type !== currentPermissions.type);
        clonedPermissions.push(currentPermissions);

        props.onPermissionsUpdate(clonedPermissions);

    }, [currentPermissions]);


    //When tabs are set, select the first one in the list
    useEffect(function () {

        if (businessAreaTabs.length === 0 || props.loading) {
            return;
        }

        onTabClick(0);

    }, [businessAreaTabs.length, props.loading])

    //Fetch the hierarchy for each permission, if no hierarchy exists get a flat list of locations
    function fetchPermissionHierarchy(key, features, businessAreaKey) {
        return new Promise((resolve, reject) => {
            var selectedFeature = features.find(function (f) {
                return f.featureKey.toLowerCase() === key;
            });

            //Fetch the hierarchy and a list of locations for the business area
            //If the locations arent being used by the hierarchy, then append them to the end of the list
            if (selectedFeature != null) {

                axios.all([
                    HierarchyService.getHierarchyForFeature(selectedFeature.hierarchyFeatureId),
                    LocationService.getLocationList(0, 1, "", "", "", {
                        "filters": [{
                            "key": "BusinessAreas",
                            "value": businessAreaKey
                        }]
                    }
                    )
                ]).then(axios.spread(function (selectedHierarchy, locationList) {

                    var locationsInUse = [];
                    var mappedHierarchy = mapHierachyToItems(selectedHierarchy, "group", 0, locationsInUse);

                    var locationsNotInHierarchy = locationList.data.filter(function (l) {
                        return !locationsInUse.includes(l.id);
                    });
                    var mappedLocations = mapLocationsToItems(locationsNotInHierarchy);

                    mappedHierarchy = mappedHierarchy.concat(mappedLocations);

                    resolve([{
                        id: 0,
                        type: "group",
                        expanded: true,
                        name: "All locations",
                        checked: false,
                        items: mappedHierarchy
                    }]);

                }), function () {
                    reject();
                });
            }

        });

    }

    //When a tab is clicked, select that tab and then set the tabs hierarchy to be the current one
    function onTabClick(index) {

        var clonedTabs = [
            ...businessAreaTabs
        ];

        clonedTabs = clonedTabs.map(function (t, i) {
            return {
                ...t,
                selected: index === i
            }
        });

        setBusinessAreaTabs(clonedTabs);

        var clonedHierarchy = [
            ...clonedTabs[index].hierarchy
        ];

        var tabPermissions = getPermissionsForBusinessArea(props.permissions, clonedTabs[index].key);

        if (tabPermissions == null) {
            tabPermissions = {
                type: clonedTabs[index].key,
                permissions: {
                    locations: [],
                    hierarchyGroups: []
                }
            }
        }

        setCurrentPermissions(tabPermissions);
        applyPermissionsToHierarchy(clonedHierarchy, tabPermissions.permissions, false);

        setItems(clonedHierarchy);

    }

    function getPermissionsForBusinessArea(allPermissions, businessArea) {

        return allPermissions.filter(function (p) {
            return p.type === businessArea
        })[0];
    }

    function mapHierachyToItems(hierarchyItems, itemType, level, inUseLocations) {

        const expandLimit = 5;

        if (hierarchyItems == null) {
            return [];
        }

        return hierarchyItems.map(function (item) {

            var isGroup = itemType === "group";

            if (!isGroup) {
                inUseLocations.push(item.locationId);
            }

            return {
                id: isGroup ? item.hierarchyGroupId : item.locationId,
                type: itemType,
                expanded: level === 0 && hierarchyItems.length < expandLimit ? true : false,
                name: isGroup ? item.hierarchyGroupName : item.locationName,
                state: item.state,
                checked: false,
                items: item.groups != null && item.groups.length > 0 ? mapHierachyToItems(item.groups, "group", level+1, inUseLocations) : mapHierachyToItems(item.locations, "item", level+1, inUseLocations)
            };

        });

    }

    function mapLocationsToItems(locations) {
        if (locations == null) {
            return [];
        }

        return locations.map(function (item) {
            return {
                id: item.id,
                type: "item",
                expanded: false,
                name: item.name,
                reference: item.reference,
                checked: false,
                state: item.state,
                items: []
            };

        });
    }

    function applyPermissionsToHierarchy(hierarchy, permissions, parentChecked) {

        var childItemsChecked = false;


        for (var i = 0; i < hierarchy.length; i++) {
            var currentItem = hierarchy[i];
            var currentItemChecked = false;
            currentItem.checked = false;
            currentItem.disabled = false;
            currentItem.partialChecked = false;


            if (parentChecked) {
                currentItem.checked = true;
                currentItem.disabled = true;
            } else {

                if (currentItem.type === "group" && permissions.hierarchyGroups.includes(currentItem.id)) {
                    currentItem.checked = true;
                    currentItemChecked = true
                    childItemsChecked = true;
                }

                if (currentItem.type === "item" && permissions.locations.includes(currentItem.id)) {
                    currentItem.checked = true;
                    childItemsChecked = true;
                }

            }

            var childChecked = applyPermissionsToHierarchy(currentItem.items, permissions, parentChecked || currentItemChecked);

            if (childChecked) {
                childItemsChecked = true;

                if (!currentItem.checked) {
                    currentItem.partialChecked = true;
                }
            }
        }

        return childItemsChecked;

    }

    function applySearchToHierarchy(hierarchy, searchTerm) {

        var searchReturnedResults = false;
        var anyChildrenReturnedResults = false;

        for (var i = 0; i < hierarchy.length; i++) {
            var currentItem = hierarchy[i];
            currentItem.appearInSearch = false;

            if (currentItem.type === "item" && (currentItem.name.toLowerCase().indexOf(searchTerm.toLowerCase()) === 0
            || (currentItem.reference && currentItem.reference.toLowerCase().indexOf(searchTerm.toLowerCase()) === 0))) {
                currentItem.appearInSearch = true;
                searchReturnedResults = true;
            }

            var childSearchReturnedResults = applySearchToHierarchy(currentItem.items, searchTerm);

            if (childSearchReturnedResults) {
                currentItem.appearInSearch = true;
            }

            anyChildrenReturnedResults = anyChildrenReturnedResults || childSearchReturnedResults;
        }
      
        return searchReturnedResults || anyChildrenReturnedResults;

    }


    function onGroupToggle(id) {

        var clonedItems = [
            ...items
        ]

        var group = findRecursiveItem(clonedItems, id, "group");
        group.expanded = !group.expanded;

        setItems(clonedItems);
    }

    function onItemSelect(id) {

       
        var clonedPermissions = {
            ...currentPermissions
        }

        var clonedItems = [
            ...items
        ];

        if (clonedPermissions.permissions.locations.includes(id)) {
            clonedPermissions.permissions.locations = clonedPermissions.permissions.locations.filter(function (l) {
                return l !== id;
            })
        } else {
            clonedPermissions.permissions.locations.push(id);
        }


        setCurrentPermissions(clonedPermissions);
        applyPermissionsToHierarchy(clonedItems, clonedPermissions.permissions, false);
        setItems(clonedItems);
    }

    function onGroupSelect(id) {

        var clonedPermissions = {
            ...currentPermissions
        }

        var clonedItems = [
            ...items
        ];

        if (clonedPermissions.permissions.hierarchyGroups.includes(id)) {
            clonedPermissions.permissions.hierarchyGroups = clonedPermissions.permissions.hierarchyGroups.filter(function (l) {
                return l !== id;
            })
        } else {
            clonedPermissions.permissions.hierarchyGroups.push(id);
        }


        setCurrentPermissions(clonedPermissions);
        applyPermissionsToHierarchy(clonedItems, clonedPermissions.permissions, false);
        setItems(clonedItems);
    }

    function onSearch(searchTerm) {

        var clonedItems = [
            ...items
        ]

        applySearchToHierarchy(clonedItems, searchTerm);

        setItems(clonedItems);
    }

    function findRecursiveItem(structure, id, type) {
        var response = null;

        if (structure != null) {
            for (var i = 0; i < structure.length; i++) {
                var currentItem = structure[i];

                if (currentItem.type === type && currentItem.id === id) {
                    return currentItem;
                }

                if (currentItem.type === "group") {
                    var innerResponse = findRecursiveItem(currentItem.items, id, type);
                    if (innerResponse != null) {
                        response = innerResponse;
                    }
                }

            }
        }

        return response;
    }

    function onActiveFilterChange(item) {
        setShowActiveOnly(item.target.checked);
    }

    function filterItemsByActive(item) {
        return item.state == null || !showActiveOnly || item.state.name === "Active";
    }

    return (
        <div>
            <div className={classNames("intro-text", { "bp3-skeleton": props.loading })}>
                <p>You will need to give this user permission to locations for viewing information in Vision and reporting. Select the service by clicking the tab and then pick the locations you want the user to access.</p>
            </div>

           

            <ShowHide
                evaluator={businessAreaTabs.length === 0 && !loading && !props.loading}
                show={(
                    <NonIdealState
                        title="No locations to assign"
                        description="You need to create locations before you can assign user permissions"
                    />
                )}
                hide={(
                    <Fragment>

                        <FormSwitch label="Only show active locations" alignment="right" inline checked={showActiveOnly} onChange={onActiveFilterChange} loading={loading || props.loading} />

                        <div className="spacer-bottom">
                            <Tabs tabs={businessAreaTabs} onClick={onTabClick} loading={loading || props.loading} />
                        </div>

                        <NotificationInline
                            show={currentPermissions != null && currentPermissions.permissions != null && currentPermissions.permissions.hierarchyGroups.length > 0}
                            text="This user has permissions to a grouping, they will automatically inherit permissions to any location that is added under this group."
                            intent="info">
                        </NotificationInline>


                        <FormGroupedCheckboxList items={items} searchable groupsSelectable onGroupToggle={onGroupToggle} onItemSelect={onItemSelect} onGroupSelect={onGroupSelect} onSearch={onSearch} loading={loading || props.loading} saving={props.saving} filter={filterItemsByActive} />

                    </Fragment>
                )}
            />
        </div>
    );
}
import React, { Fragment, useState, useEffect } from 'react';
import { FormRadio, FormDateInput, FormTextInput, FormCheckboxList } from "components/form-fields";
import { ShowHide } from "components/layout";
import { WorkingTimeSettings } from 'components/settings';

import { UserAccess } from 'components/user-access';
import { useValidation } from 'hooks/useValidation';
import PropTypes from 'prop-types';
import { HelperFunctions } from 'helpers';
import moment from "moment";
import { Intent } from '@blueprintjs/core';
import { NotificationToaster } from "components/notifications";

var _ = require('underscore');


export function LocationSettings(props) {


    const [generalSettingsValid, generalSettingsErrors, validateGeneralSettings] = useValidation(); 
    const [tachoSettingsValid, tachoSettingsErrors, validateTachoSettings] = useValidation(); 
    const [businessAreas, setBusinessAreas] = useState([]);
    const [workingTimeValid, setWorkingTimeValid] = useState(true);
    const [loading, setLoading] = useState(true);

    const defaultOptions = {
        inheritSettings: [{ value: true, label: "Inherit default settings" }, { value: false, label: "Apply custom settings" }]
    };

    useEffect(() => {
        setLoading(props.loading);
    }, [props.loading]);


    useEffect(() => {
        setBusinessAreas(props.generalSettings.businessAreas)
    }, [props.generalSettings]);

    useEffect(() => {
        props.onValidationUpdate(generalSettingsValid && tachoSettingsValid && (props.generalSettings.tachoSettings.useTachoAnalysisDefaults || workingTimeValid));
    }, [generalSettingsValid, props.generalSettings.tachoSettings.useTachoAnalysisDefaults, tachoSettingsValid, workingTimeValid]);

    useEffect(validateSettings, [props.generalSettings]);

    function validateSettings() {
        let generalRules = [{ fieldName: "name", required: true }];

        if (props.user.isInternal) {
            generalRules.push({ fieldName: "businessAreas", required: true });
        }

        validateGeneralSettings(generalRules, props.generalSettings.generalSettings);

        let tachoRules = [];
        if (!props.generalSettings.tachoSettings.useTachoAnalysisDefaults) {
            tachoRules.push({ fieldName: "tachoAnalysisStartDate", required: true });
        }
        validateTachoSettings(tachoRules, props.generalSettings.tachoSettings);
    }

    function onNameChange(event) {
        var updateSettings = HelperFunctions.deepClone(props.generalSettings);
        updateSettings.generalSettings.name = event.target.value;
        props.onSettingsUpdate(updateSettings);
    }

    function onReferenceChange(event) {
        var updateSettings = HelperFunctions.deepClone(props.generalSettings);
        updateSettings.generalSettings.reference = event.target.value;
        props.onSettingsUpdate(updateSettings);
    }

    function handleCheckboxChange(event, id) {

        var tempBusinessAreas = [...businessAreas];

        var match = _.find(tempBusinessAreas, function (item) {
            return item.id === id;
        });

        if (!event.target.checked && props.canRemoveBusinessArea) {
            if (!props.canRemoveBusinessArea(match.name)) {
                    NotificationToaster.show(Intent.WARNING, 'You have contacts assigned to this business area. Please remove them before you can unselect this business area.');
                return;
            }
        }

        if (match != null) {
            match.checked = event.target.checked;
        }

        var selectedItems = _.chain(tempBusinessAreas).filter(function (item) {
            return item.checked;
        }).map(function (item) {
            return { locationBusinessAreaId: item.id, businessArea: item.name };
        }).value();

        var updateSettings = HelperFunctions.deepClone(props.generalSettings);
        updateSettings.generalSettings.businessAreas= selectedItems;
        props.onSettingsUpdate(updateSettings);
    }

    function onWorkingTimeValidationChange(isValid) {
        setWorkingTimeValid(isValid);
    }

    function onWorkingTimeSettingsChange(item) {
        var updateSettings = HelperFunctions.deepClone(props.generalSettings);
        updateSettings.tachoSettings = item;
        props.onSettingsUpdate(updateSettings);
    }

    function onUseDefaultsChange(item) {
        var updateSettings = HelperFunctions.deepClone(props.generalSettings);
        updateSettings.tachoSettings.useTachoAnalysisDefaults = item.currentTarget.value === "true";
        props.onSettingsUpdate(updateSettings);
    }

    function onTachoAnalysisStartDateChange(item) {
        var updateSettings = HelperFunctions.deepClone(props.generalSettings);
        updateSettings.tachoSettings.tachoAnalysisStartDate = item ? moment(item).format("YYYY-MM-DD") : null;
        props.onSettingsUpdate(updateSettings);
    }


    return (

                <div>
                    <FormTextInput
                        id="location-name"
                        loading={loading}
                        value={props.generalSettings.generalSettings.name}
                        headingText="Enter the location name:"
                        onChange={onNameChange}
                        disabled={props.saving}
                        dangerHelperText={generalSettingsErrors.name}>
                    </FormTextInput>

                    <FormTextInput
                        id="location-reference"
                        loading={loading}
                        value={props.generalSettings.generalSettings.reference}
                        headingText="External Reference:"
                        onChange={onReferenceChange}
                        disabled={props.saving}
                    >
                    </FormTextInput>

                    <UserAccess perform="Internal"
                        yes={() => (

                            <FormCheckboxList
                                id="location-business-areas"
                                items={businessAreas}
                                onChange={handleCheckboxChange}
                                headingText="Business Areas:"
                                loading={loading}
                                dangerHelperText={generalSettingsErrors.businessAreas}
                            />

                        )}
                    />

                    <FormRadio
                        id="location-settings-inherited"
                        disabled={props.saving}
                        onChange={onUseDefaultsChange}
                        headingText="Would you like to inherit the account's tacho settings or apply custom settings?"
                        selectedValue={props.generalSettings.tachoSettings.useTachoAnalysisDefaults}
                        options={defaultOptions.inheritSettings}
                        loading={loading}
                    ></FormRadio>

                    <ShowHide
                evaluator={props.generalSettings.tachoSettings.useTachoAnalysisDefaults === false}
                        show={(<Fragment>

                            <FormDateInput
                                id="location-tacho-start"
                                disabled={props.saving}
                                headingText="The date you wish Vision to analyse your tacho data from:"
                                onChange={onTachoAnalysisStartDateChange}
                                value={props.generalSettings.tachoSettings.tachoAnalysisStartDate}
                                loading={loading}
                                dangerHelperText={tachoSettingsErrors.tachoAnalysisStartDate}
                                minDate={moment("2000-01-01").toDate()}
                            ></FormDateInput>
                            <WorkingTimeSettings
                                saving={props.saving}
                                settings={props.generalSettings.tachoSettings}
                                workingtimeDisabled={!props.generalSettings.accountTachoSettings.includeWorkingTime}
                                onSettingsUpdate={onWorkingTimeSettingsChange}
                                onValidationUpdate={onWorkingTimeValidationChange}
                            />
                        </Fragment>)}
                    />
                </div>

    );
}
LocationSettings.defaultProps = {
    saving: false,
    editing: false,
};

LocationSettings.propTypes = {
    generalSettings: PropTypes.object.isRequired,
    user: PropTypes.object.isRequired,
    saving: PropTypes.bool,
    editing: PropTypes.bool,
    loading: PropTypes.bool.isRequired
};
import React from 'react';

import { Checkbox } from "@blueprintjs/core";

import classNames from 'classnames';

export function FormCheckbox(props) {
    const checkboxHtml = <Checkbox checked={props.checked} label={props.label} disabled={props.loading || props.disabled} id={props.id} identifier={props.id} onChange={onChange} />

    function onChange(event) {        
        props.onChange(event, props.identifier);
    }
    if (props.headingText) {
        return (        
            <div className="form-field">
                <h4 className={classNames({ "bp3-skeleton": props.loading })}>{props.headingText}</h4>
                {checkboxHtml}
            </div>
        );
    }

    return checkboxHtml;   
    
}
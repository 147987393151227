import React, { useState, useEffect } from 'react';
import { Button, ButtonSave } from 'components/buttons';
import { DataDisplayTable } from 'components/data-display';
import { FormSelect } from 'components/form-fields';
import { AssetService } from 'services/AssetService';
import { AlertService } from 'services';
import { NotificationInline, NotificationToaster } from 'components/notifications';
import { Intent } from '@blueprintjs/core';
import { ShowHide } from 'components/layout';
import PropTypes from 'prop-types';
import { DateToLocal } from 'components/dates';

export function UnknownEmployeeModal(props) {
    const { alert, close, save } = props;
    const [specificAlertDetails, setSpecificAlertDetails] = useState([])
    const [journeyTypes, setJourneyTypes] = useState([]);
    const [selectedJourneyTypeId, setSelectedJourneyTypeId] = useState(0);
    const [isLoading, setIsLoading] = useState(true);
    const [selectedJourneyTypeErrorMessage, setSelectedJourneyTypeErrorMessage] = useState("");
    const [isSaving, setIsSaving] = useState(false);

    function formatDriverName(driverFirstNames, driverLastName) {
        if (!driverFirstNames && !driverLastName) {
            return "-";
        }

        return `${!driverFirstNames ? "" : driverFirstNames} ${!driverLastName ? "" : driverLastName}`;
    }

    useEffect(() => {
        if (alert === null) {
            return;
        }

        AssetService.getAllJourneyTypesForAccount().then(journeyTypesForAccount => {
            setJourneyTypes(journeyTypesForAccount.map((journeyType) => {
                return {
                    id: journeyType.journeyTypeID,
                    name: journeyType.journeyName
                }
            }));
            setIsLoading(false);
        }, () => NotificationToaster.show(Intent.DANGER, "Unable to load journey types. Please try again."));

        var alertDetails = [
            {
                header: "Vehicle Registration",
                value: alert.entity.asset.registration,
                key: "VehicleRegistration - " + alert.alertId
            },
            {
                header: "Vehicle Type",
                value: alert.entity.type.name,
                key: "VehicleType - " + alert.alertId
            },
            {
                header: "Fleet Number",
                value: alert.entity.asset.fleetNumber || "-",
                key: "FleetNumber - " + alert.alertId
            },
            {
                header: "Driver Name",
                value: formatDriverName(alert.alertData.driverFirstNames, alert.alertData.driverLastName),
                key: "DriverName - " + alert.alertId
            },
            {
                header: "Location",
                value: alert.location.name,
                key: "Location - " + alert.alertId
            },
            {
                header: "Shift Start",
                value: <DateToLocal key={"ShiftStartTable - " + alert.alertId}>{alert.dataStartDate}</DateToLocal>,
                key: "ShiftStart - " + alert.alertId
            },
            {
                header: "Shift End",
                value: <DateToLocal key={"ShiftEndTable - " + alert.alertId}>{alert.dataEndDate}</DateToLocal>,
                key: "ShiftEnd - " + alert.alertId
            }
        ]

        setSpecificAlertDetails(alertDetails);
    }, [alert])

    function onJourneyTypeChange(journeyType) {
        if (selectedJourneyTypeId === 0) {
            setSelectedJourneyTypeErrorMessage("");
        }

        if (journeyType !== null && journeyType.id !== null) {
            setSelectedJourneyTypeId(journeyType.id);
        }
    }

    function saveJourneyType() {
        setIsSaving(true);

        if (selectedJourneyTypeId === 0) {
            setSelectedJourneyTypeErrorMessage("Please select a journey type");
            setIsSaving(false);
            return;
        }
        let assetId = alert.entity.entityId;
        let startDate = alert.dataStartDate;
        let endDate = alert.dataEndDate;
        let alertId = alert.alertId;
        let processStatusId = 3;
        let comment = "";

        AssetService.assignJourney(assetId, selectedJourneyTypeId, startDate, endDate).then(
            () => AlertService.saveAlertStatus(alertId, processStatusId, comment).then(
                () => {
                    NotificationToaster.show(Intent.SUCCESS, "Journey has been successfully processed.");
                    save();
                },
                () => {
                    NotificationToaster.show(Intent.DANGER, "Unable to process alert. Please try again.");
                    setIsSaving(false);
                }),
            () => {
                NotificationToaster.show(Intent.DANGER, "Unable to assign a journey. Please try again.");
                setIsSaving(false);
            }
        );
    }

    return (
        <>
            <div className="spacer-bottom padded">
                <DataDisplayTable data={specificAlertDetails} />
            </div>

            <ShowHide
                evaluator={journeyTypes.length > 0}
                show={<FormSelect
                    headingText="Select a journey type"
                    id="journey-types"
                    loading={isLoading}
                    disabled={false}
                    items={journeyTypes}
                    placeholder="Select a journey type"
                    selectedValue={selectedJourneyTypeId}
                    onItemSelect={onJourneyTypeChange}
                    key={alert.alertId}
                    dangerHelperText={selectedJourneyTypeErrorMessage}
                />}
            />

            <NotificationInline
                id="notification-journey-types"
                loading={isLoading}
                text={"No journey types are available, please contact your company administrator to create one."}
                intent="danger"
                show={journeyTypes.length === 0 && !isLoading}
                allowClose={false}
            />

            <div className="bp3-dialog-footer">
                <div className="bp3-dialog-footer-actions">
                    <ButtonSave
                        id="journey-type-edit"
                        onClick={saveJourneyType}
                        disabled={journeyTypes.length === 0 || isSaving}
                    />
                    <Button
                        text="Cancel"
                        intent="secondary"
                        onClick={close}
                    />
                </div>
            </div>
        </>
    )
}

UnknownEmployeeModal.defaultProps = {
    alert: null,
    close: () => { },
    save: () => { }
};

UnknownEmployeeModal.propTypes = {
    alert: PropTypes.object.isRequired,
    close: PropTypes.func.isRequired,
    save: PropTypes.func.isRequired
}
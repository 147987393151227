import React from 'react';
import PropTypes from 'prop-types';
import classNames from "classnames";
import { Button, Popover, PopoverInteractionKind } from "@blueprintjs/core";

export function ListingPagination(props) {

    let pagesToShow = 3;
    let showPageSelector = true;

    if (props.totalPages < pagesToShow) {
        pagesToShow = props.totalPages;
        showPageSelector = false;
    }

    let startPage = props.currentPage - Math.floor(pagesToShow / 2);

    if (startPage < 1) {
        startPage = 1;
    } else if (startPage + pagesToShow > props.totalPages) {
        startPage = props.totalPages - (pagesToShow - 1);
    }

    const items = [];
    const listItems = [];

    for (let i = 0; i < pagesToShow; i++) {

        let page = startPage + i;

        items.push(<Button key={i} className={classNames({
            active: (page === props.currentPage)
        })} text={page} minimal={true} large={true} disabled={props.loadingData} onClick={() => {
            onPageChange(page)
        }}></Button>)
    }

    for (let i = 1; i <= props.totalPages; i++) {
        listItems.push(<Button key={i} minimal={true} className="bp3-popover-dismiss" onClick={() => {
            onPageChange(i)
        }}>{props.itemName} {i}</Button>);
    }

    function onPageChange (pageNumber) {
        props.onPageChange(pageNumber);
    }

    function gotoNextPage() {
        onPageChange(props.currentPage + 1);
    }

    function gotoPreviousPage() {
        onPageChange(props.currentPage - 1);
    }

    return (
        <div className={classNames("pagination", props.className)}>
            <Button icon="chevron-left" disabled={props.currentPage === 1 || props.loadingData} minimal={true} large={true} onClick={gotoPreviousPage}></Button>
            {items}
            <Button icon="chevron-right" disabled={props.currentPage === props.totalPages || props.loadingData} minimal={true} large={true} onClick={gotoNextPage}></Button>
            {showPageSelector ?
                <Popover disabled={props.loadingData} position={props.pagingListPosition} interactionKind={PopoverInteractionKind.CLICK} popoverClassName="pagination-page-select" minimal={true} >
                    <Button minimal={true} large={true} icon="layout-linear" disabled={props.loadingData}></Button>
                    <div>
                        {listItems}
                    </div>
                </Popover> : null}
        </div>
    );

}

ListingPagination.defaultProps = {
    totalPages: 1,
    currentPage: 1,
    loadingData: true,
    className: "",
    itemName: "Page",
    pagingListPosition: "auto"
};

ListingPagination.propTypes = {
    totalPages: PropTypes.number.isRequired,
    currentPage: PropTypes.number.isRequired,
    loadingData: PropTypes.bool.isRequired,
    onPageChange: PropTypes.func.isRequired,
    className: PropTypes.string,
    itemName: PropTypes.string,
    pagingListPosition: PropTypes.string
};
import { useValidation } from 'hooks/useValidation';
import { FormTextArea } from 'components/form-fields';
import React, { useEffect, Fragment } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

export function OcrTextareaLayout(props) {
    const [_, errors, validate] = useValidation();

    useEffect(() => {
        validateLayout();
    }, [props.layout.answer]);

    function validateLayout() {
        const rules = getRules();
        validate(rules, props.layout);
    }

    function getRules() {
        const isRequired = props.layout.requiredAnswerCount > 0;
        return [{ fieldName: 'answer', required: isRequired }];
    }

    function onValueChange(event) {
        const layoutClone = { ...props.layout };

        layoutClone.answer = event.target.value;

        props.onChange(layoutClone);
    }

    return (
        <Fragment>
            <div className={classNames({ "hide-in-print": !props.readonly, "hidden": props.readonly })}>
                <FormTextArea
                    loading={props.loading}
                    disabled={props.disabled}
                    headingText={props.layout.secondaryLayoutTitle}
                    onChange={onValueChange}
                    dangerHelperText={errors.answer}
                    value={props.layout.answer ? props.layout.answer : ''}
                    maxLength={2000}
                    wide
                />
            </div>
            <p className={classNames({ "show-in-print": !props.readonly, "visible": props.readonly, "bp3-skeleton": props.loading }, "preserve-white-space")}>{props.layout.answer ? props.layout.answer : 'None'}</p>
        </Fragment>
    )
}

OcrTextareaLayout.propTypes = {
    onChange: PropTypes.func.isRequired,
    loading: PropTypes.bool,
    layout: PropTypes.object.isRequired,
    disabled: PropTypes.bool
};

OcrTextareaLayout.defaultProps = {
    disabled: false,
    loading: false
};

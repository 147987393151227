import React, { useState, useEffect } from 'react';
import { Link } from "react-router-dom";
import { Intent } from '@blueprintjs/core';


import { LinkButton, Button } from 'components/buttons'
import { ListingTable } from "components/listing";
import { AlertConfirm, NotificationToaster } from "components/notifications";
import { DateToLocal } from "components/dates";
import { PageRestricted } from 'pages/errors/page-restricted';
import { UserAccess } from 'components/user-access';
import { AccountService } from 'services';
import { ArrayToEllipsesString } from 'components/formatting';


export function AccountIntegrationsListing() {

    const [selectedIntegrationId, setSelectedIntegrationId] = useState(0);
    const [totalRecords, setTotalRecords] = useState(0);
    const [tableRows, setTableRows] = useState([]);
    const [loadingData, setLoadingData] = useState(false);
    const noDataMessage = "You have no integrations set up, please click 'Add new integration' to get started.";
    const tableHeaders = ["Integration Name", "Username", "Services", "Last Imported", "Actions"];
    const [showDeleteConfirm, setShowDeleteConfirm] = useState(false);

    const requiredAction = "Integrations:Manage";

    useEffect(fetchTableData, []);

    function fetchTableData() {

        setLoadingData(true);
        AccountService.getIntegrations().then(function (integrationsResponse) {

            var data = integrationsResponse.map((d) => {

                return [
                    <Link to={`/account/integrations/credentials/${d.accountIntegrationId}`}>{d.providerName}</Link>,
                    d.username,
                    <ArrayToEllipsesString>
                        {d.services.map(function (s) {
                            return s.name;
                        })}
                    </ArrayToEllipsesString>,
                    <DateToLocal>{d.lastRunTime}</DateToLocal>,
                    <div className="button-row-small">
                        <Button onClick={() => deleteIntegration(d.accountIntegrationId)} icon="trash" iconOnly={true} text="Delete Integration" large={false} minimal={true}></Button>
                    </div>
                ];

            });

            setTotalRecords(data.length);
            setTableRows(data);
            setLoadingData(false);
        });

    }


    function deleteIntegration(id) {
        setSelectedIntegrationId(id);
        setShowDeleteConfirm(true);
    }

    function onDeleteConfirm() {

        setShowDeleteConfirm(false);

        AccountService.deleteIntegration(selectedIntegrationId).then(function () {
            NotificationToaster.show(Intent.SUCCESS, "Integration has been successfully deleted");
            fetchTableData();
            setSelectedIntegrationId(0);
        }, function () {
            NotificationToaster.show(Intent.DANGER, "Unable to delete the integration, please try again.");
            setSelectedIntegrationId(0);
        });

    }

    function onDeleteCancel() {
        setSelectedIntegrationId(0);
        setShowDeleteConfirm(false);
    }

    return (
        <UserAccess perform={requiredAction}
            yes={() => (
                <div>

                    <div className="intro-text">
                        <p>These integrations will require you to create logins or generate tokens for Vision to be able to collect data from each webservice.</p>
                    </div>

                    <div className="spacer-bottom">
                        <LinkButton intent="primary" text="Add new integration" href="/account/integrations/credentials/create" id="new-account-integration" loading={loadingData} />
                    </div>
                  
                    <ListingTable
                        id="listing-table-user"
                        headers={tableHeaders}
                        data={tableRows}
                        totalRecordCount={totalRecords}
                        onPagingChange={() => { }}
                        loadingData={loadingData}
                        noDataMessage={noDataMessage}
                        pageable={false}

                    />

                    <AlertConfirm
                        title="Please confirm"
                        isOpen={showDeleteConfirm}
                        onConfirm={onDeleteConfirm}
                        onCancel={onDeleteCancel}
                    >
                        <p>Are you sure you want to delete this integration?</p>
                    </AlertConfirm>

                </div>
            )}
            no={() => (
                <div className="row">
                    <PageRestricted />
                </div>
            )}
        />
    );

}
import ApiService from "services/ApiService";

const badRequestCode = 400;
const successCode = 200;

export const DashboardService = {
    getDashboards() {
        return new Promise((resolve, reject) => {
            ApiService.get(`/reporting/dashboards`).then(
                (result) => {
                    resolve(result.data);
                },
                (error) => {
                    reject(error);
                });
        });
    },
    getEmbedToken(dashboardId) {
        return new Promise((resolve, reject) => {
            ApiService.get(`/reporting/dashboards/${dashboardId}/EmbedToken`).then(
                (result) => {
                    resolve(result.data);
                },
                (error) => {
                    reject(error);
                });
        });
    },
    getCustomViews(dashboardId) {
        return new Promise((resolve, reject) => {
            ApiService.get(`/reporting/dashboards/${dashboardId}/Views`).then(
                (result) => {
                    resolve(result.data);
                },
                (error) => {
                    reject(error);
                });
        });
    },
    getCustomFilters(dashboardId) {
        return new Promise((resolve, reject) => {
            ApiService.get(`/reporting/dashboards/${dashboardId}/Filters`).then(
                (result) => {
                    resolve(result.data);
                },
                (error) => {
                    reject(error);
                });
        });
    },
    getCustomSlicers(dashboardId) {
        return new Promise((resolve, reject) => {
            ApiService.get(`/reporting/dashboards/${dashboardId}/Slicers`).then(
                (result) => {
                    resolve(result.data);
                },
                (error) => {
                    reject(error);
                });
        });
    },
    deleteCustomView(dashboardId, customViewId) {
        return new Promise((resolve, reject) => {
            ApiService.delete(`/reporting/dashboards/${dashboardId}/Views/${customViewId}`).then(
                (result) => {
                    resolve(result);
                },
                (error) => {
                    reject(error);
                }
            );
        });
    },
    createCustomView(dashboardId, customView) {
        return new Promise((resolve, reject) => {
            ApiService.post(`/reporting/dashboards/${dashboardId}/Views/`, customView).then(
                (response) => {
                    resolve(response.data);
                },
                (error) => {
                    if (error.response.status === badRequestCode) {
                        reject(error.response);
                    } else {
                        reject("Unable to create view. Please try again later.");
                    }
                });
        });
    },
    updateCustomView(dashboardId, customViewId, customView) {
        return new Promise((resolve, reject) => {
            ApiService.put(`/reporting/dashboards/${dashboardId}/Views/${customViewId}`, customView).then(
                (response) => {
                    resolve(response);
                },
                (error) => {
                    if (error.response.status === badRequestCode) {
                        reject(error.response);
                    } else {
                        reject("Unable to update custom view. Please try again later.");
                    }
                });
        });
    },
    downloadUserGuide(dashboardId) {
        return new Promise((resolve, reject) => {
            ApiService.get(`/reporting/dashboards/${dashboardId}/UserGuide`).then(
                (result) => {
                    if (result.status === successCode) {
                        resolve(result.data);
                    } else {
                        reject();
                    }
                },
                (error) => {
                    reject(error);
                });
        });
    },
    logError(expectedUsername, actualUsername, message, dashboardId) {

        var errorModel = {
            dashboardId: dashboardId,
            expectedUsername: expectedUsername,
            ActualUsername: actualUsername,
            Error: message
        }

        ApiService.post(`/reporting/dashboards/LogError`, errorModel);
    }
};
import React, { Fragment, useState, useEffect } from 'react';
import { FormTextInput } from "components/form-fields";
import { useValidation } from "hooks/useValidation";
import PropTypes from 'prop-types';
import { ShowHide } from 'components/layout';
import { Button, ButtonSave } from 'components/buttons';
import { Intent } from '@blueprintjs/core';
import { NotificationInline, NotificationToaster } from 'components/notifications';
import { UserService } from 'services';
import classNames from "classnames";
import { useSelector } from 'react-redux';

export function UserBasicSettings(props) {
    const [isValid, errors, validate] = useValidation();
    const [loading, setLoading] = useState(true);
    const [updateEmail, setUpdateEmail] = useState(false);
    const [userSettings, setUserSettings] = useState({ emailAddress: "", firstName: null, lastName: null, pendingEmailAddress: "", phoneNumber: null, secondaryPhoneNumber: null });
    const [pendingEmailAddress, setPendingEmailAddress] = useState("");
    const [canUpdateEmail, setCanUpdateEmail] = useState(false);
    const loggedInUserId = useSelector(state => state.loggedInUser.userId);
    const [savingUpdateEmailChange, setSavingUpdateEmailChange] = useState(false);

    useEffect(onValidationChange, [isValid])

    function onValidationChange() {
        props.onValidationUpdate(isValid);
    }

    useEffect(function () {
        setPendingEmailAddress(props.pendingEmailAddress);
    }, [props.pendingEmailAddress])

    useEffect(() => {
        setUserSettings(props.user);
        if (props.user.userId && props.user.userId !== loggedInUserId && props.user.status !== "Pending") {
            setCanUpdateEmail(true);
        }
        
    }, [props.user]);

    useEffect(() => {
        setLoading(props.loading);
    }, [props.loading]);

    function onEmailChange(event) {

        if (updateEmail) {
            setPendingEmailAddress(event.target.value);
        } else {
            var updatedUser = {
                ...userSettings,
                emailAddress: event.target.value
            }

            setUserSettings(updatedUser);
            props.onSettingsUpdate(updatedUser);
        }
    }

    function onFirstNameChange(event) {

        var updatedUser = {
            ...userSettings,
            firstName: event.target.value
        }

        setUserSettings(updatedUser);
        props.onSettingsUpdate(updatedUser);
    }

    function onLastNameChange(event) {

        var updatedUser = {
            ...userSettings,
            lastName: event.target.value
        }

        setUserSettings(updatedUser);
        props.onSettingsUpdate(updatedUser);
    }

    function onPhoneNumberChange(event) {

        var updatedUser = {
            ...userSettings,
            phoneNumber: event.target.value,
            secondaryPhoneNumber: event.target.value === '' ? '' : userSettings.secondaryPhoneNumber
        }

        setUserSettings(updatedUser);
        props.onSettingsUpdate(updatedUser);
    }

    function onSecondaryPhoneNumberChange(event) {

        var updatedUser = {
            ...userSettings,
            secondaryPhoneNumber: event.target.value
        }

        setUserSettings(updatedUser);
        props.onSettingsUpdate(updatedUser);
    }

    useEffect(updateValidationRules, [pendingEmailAddress, updateEmail, props.user])

    function updateValidationRules() {
        let rules = [];

        if (!props.editing || updateEmail || props.externalUser) {
            rules.push({ fieldName: "emailAddress", required: true, type: "noneLogisticsEmail" });
        }

        if (props.externalUser) {
            rules.push({ fieldName: "firstName", required: true, maxLength: 50 });
            rules.push({ fieldName: "lastName", required: true, maxLength: 50 });
        }

        if (props.user?.phoneNumber !== undefined && props.user?.phoneNumber !== '') {
            rules.push({ fieldName: "phoneNumber", required: false, type: "phone" });
        }

        if (props.user?.secondaryPhoneNumber !== undefined && props.user?.secondaryPhoneNumber !== '') {
            rules.push({ fieldName: "secondaryPhoneNumber", required: false, type: "phone" });
        }

        if (!props.editing || props.externalUser)
        {
            validate(rules, props.user);
        }
        else
        {
            var emailUpdateObject = {
                emailAddress: pendingEmailAddress,
                phoneNumber: props.user.phoneNumber,
                secondaryPhoneNumber: props.user.secondaryPhoneNumber
            };

            validate(rules, emailUpdateObject);
        }
    }

    function OnUpdateEmail() {
        if (updateEmail) {
            setUserSettings({
                ...userSettings,
                pendingEmailAddress: ""
            });
        }

        setUpdateEmail(!updateEmail);

    }

    function OnUpdateEmailCancel() {

        setSavingUpdateEmailChange(true);

        UserService.updateEmailAddress(userSettings.userId, { emailAddress: null }).then(() => {
            setSavingUpdateEmailChange(false);
            NotificationToaster.show(Intent.SUCCESS, "Email address change was successfully cancelled.");
            setPendingEmailAddress("");
            setUpdateEmail(false);
            var updatedUser = {
                ...userSettings,
                pendingEmailAddress: ""
            }

            props.onSettingsUpdate(updatedUser);

        }, (error) => {
            setSavingUpdateEmailChange(false);
            NotificationToaster.show(Intent.DANGER, error);
        });
    }

    function OnUpdateEmailConfirm(){

        if (!isValid) {
            NotificationToaster.show(Intent.WARNING, "Settings not valid. Please check your settings and try again.");
            return;
        }

        setLoading(true);

        UserService.updateEmailAddress(userSettings.userId, { emailAddress: pendingEmailAddress }).then(() => {
            setLoading(false);
            var updatedUser = {
                ...userSettings,
                pendingEmailAddress: pendingEmailAddress
            }

            props.onSettingsUpdate(updatedUser);
            NotificationToaster.show(Intent.SUCCESS, "An email has been sent to the user to confirm the email address");
        }, (error) => {
            setLoading(false);
            NotificationToaster.show(Intent.DANGER, error);
        });
    }

    return (
        <Fragment>
            <div>

                <ShowHide
                    evaluator={userSettings.pendingEmailAddress !== undefined && userSettings.pendingEmailAddress != null && userSettings.pendingEmailAddress !== ""}
                    show={(
                        <Fragment>

                            <FormTextInput
                                large
                                id="user-email-address"
                                value={userSettings.emailAddress}
                                onChange={() => { }}
                                disabled
                                headingText="Current Email Address:"
                            />
                            <h4 className='spacer-bottom-medium'>Pending Email Address:</h4>
                            <div className="inline-items spacer-bottom">
                                <FormTextInput
                                    large
                                    id="user-email-address"
                                    value={userSettings.pendingEmailAddress}
                                    onChange={() => { }}
                                    disabled
                                />
                                <ButtonSave
                                    text="Cancel change"
                                    intent="danger"
                                    icon="cross"
                                    onClick={OnUpdateEmailCancel}
                                    disabled={savingUpdateEmailChange}
                                    loading={loading}
                                ></ButtonSave>
                            </div>

                            <NotificationInline
                                show
                                text="The above user's email address is pending confirmation, once the user has confirmed the change they will be able to use it for login"
                                intent="info"
                                allowClose={false}
                            ></NotificationInline>

                        </Fragment>
                    )}
                    hide={(
                        <Fragment>
                            <NotificationInline
                                show={props.externalUser && props.pending && !loading}
                                text="This external contact needs to verify their email address before changes can be made."
                                intent="info"
                                allowClose={false}
                            ></NotificationInline>

                            <h4 className='spacer-bottom-medium'>Email Address:</h4>
                            <div className={classNames("inline-items align-items-flex-start", { "spacer-bottom": !errors.emailAddress })}>

                                <FormTextInput
                                    large
                                    id="user-email-address"
                                    value={updateEmail ? pendingEmailAddress : userSettings.emailAddress}
                                    onChange={onEmailChange}
                                    disabled={(props.user.userId && (!canUpdateEmail || loading || props.saving || props.ssoAccount || props.user.userType === "SSO" || !updateEmail))
                                        || (props.externalUser && (props.pending || props.saving))}
                                    loading={loading}
                                    dangerHelperText={errors.emailAddress}
                                    inputRef={props.inputRef}>
                                </FormTextInput>
                                <ShowHide evaluator={props.user.userId !== undefined && props.user.userId && canUpdateEmail && !loading && props.user.userType !== "SSO" && props.user.userType !== "External"}
                                    show={(
                                        <div>
                                            <Button
                                                text={ !updateEmail ? "Change Email" : "Cancel"} 
                                                intent="primary"
                                                onClick={OnUpdateEmail}
                                                disabled={props.saving || loading}
                                                loading={loading}
                                            ></Button>
                                        </div>
                                    )}
                                />
                            </div>

                            <ShowHide evaluator={updateEmail && props.user.userId}
                                show={(
                                    <div className="spacer-bottom">
                                        <NotificationInline intent="info" show allowClose={false} text="When a user's email is updated, they will need to validate their email address before they can use it for login" />

                                        <ButtonSave
                                            text="Update Email Address"
                                            intent="primary"
                                            onClick={OnUpdateEmailConfirm}
                                            disabled={props.saving || loading || !updateEmail || props.ssoAccount || savingUpdateEmailChange}
                                            simpleDisabled={!isValid}
                                        ></ButtonSave>
                                    </div>
                                )}
                            />
                        </Fragment>
                    )}
                />

                
            </div>
            
            <FormTextInput
                large
                id="user-first-name"
                loading={loading}
                value={userSettings.firstName}
                headingText="First Name:"
                onChange={onFirstNameChange}
                disabled={props.saving || props.pending || props.ssoAccount}
                dangerHelperText={errors.firstName} >
            </FormTextInput>
            <FormTextInput
                large
                id="user-last-name"
                loading={loading}
                value={userSettings.lastName}
                headingText="Last Name:"
                onChange={onLastNameChange}
                disabled={props.saving || props.pending || props.ssoAccount}
                dangerHelperText={errors.lastName} >
            </FormTextInput>
            <FormTextInput
                large
                id="phone-number"
                loading={loading}
                value={userSettings.phoneNumber}
                headingText="Phone Number:"
                onChange={onPhoneNumberChange}
                disabled={props.saving || props.pending || props.ssoAccount}
                dangerHelperText={errors.phoneNumber} >
            </FormTextInput>
            <FormTextInput
                large
                id="secondary-phone-number"
                loading={loading}
                value={userSettings.secondaryPhoneNumber}
                headingText="Secondary Phone Number:"
                onChange={onSecondaryPhoneNumberChange}
                disabled={props.saving || props.pending || props.ssoAccount || userSettings.phoneNumber === null || userSettings.phoneNumber === ''}
                dangerHelperText={errors.secondaryPhoneNumber} >
            </FormTextInput>
        </Fragment>
    );
}
UserBasicSettings.defaultProps = {
    saving: false,
    editing: false,
    ssoAccount: false,
    externalUser: false
};

UserBasicSettings.propTypes = {
    user: PropTypes.object.isRequired,
    saving: PropTypes.bool,
    editing: PropTypes.bool,
    loading: PropTypes.bool.isRequired,
    ssoAccount: PropTypes.bool,
    externalUser: PropTypes.bool,
    inputRef: PropTypes.object
};
import React, { Component } from 'react';

export class Page404 extends Component {

	render() {
		return (
			<h1>Page cannot be found</h1>
		);
	}

}
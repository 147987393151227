import React, { useState, useEffect } from 'react';
import { Intent } from '@blueprintjs/core';
import { NotificationToaster, AlertUnsavedData, NotificationInline } from "components/notifications";
import { ButtonSave} from "components/buttons";
import { PolicySettings, DeclarationSettings } from 'components/settings';
import { EmployeeService } from "services";
import { useDirtyData } from "hooks/useDirtyData";
import { ShowHide } from 'components/layout';
import moment from "moment";

var _ = require('underscore');


export function PolicyDetails(props) {

    const [originalPolicySettings, setOriginalPolicySettings] = useState();
    const [policySettingValid, setPolicySettingValid] = useState(true);

    const [loading, setLoading] = useState(true);
    const [saving, setSaving] = useState(false);
    const [disabled, setDisabled] = useState(false);
    const [reoccurrenceSettingsDisabled, setReoccurrenceSettingsDisabled] = useState(false);

    const [policySettings, setPolicySettings] = useState({
        name: "",
        startDate: "",
        endDate: "",
        employeeCategorys: [],
        employeeTypes: [],
        locations: [],
        resendSchedule: {
            reoccurring: false
        },
        resendPeriodId: null,
        useAllLocations: true,
        policyDocuments: [],
        sendDebriefAlert: true
    });
    const isDataDirty = useDirtyData(originalPolicySettings, policySettings);

    useEffect(() => {
        if (props.policyId != null && props.policyId > 0) {
            EmployeeService.getPolicy(props.policyId).then(
                (response) => {
                    setPolicySettings(response);
                    setOriginalPolicySettings(_.extend({}, response));
                    setLoading(false);

                    if (response.endDate && moment(response.endDate).isBefore(moment(), 'day')) {
                        setDisabled(true);
                    } else {
                        if (response.startDate && moment(response.startDate).isBefore(moment(), 'minute')) {
                            setReoccurrenceSettingsDisabled(true);
                        }
                    }
                },
                () => {
                    setSaving(false);
                    NotificationToaster.show(Intent.DANGER, "Could not load policy. Please try again.");
                }
            );
        }
    }, [props.policyId]);

    function onPolicySettingValidationChange(isValid) {
        setPolicySettingValid(isValid);
    }

    function onPolicySettingsChange(item) {
        setPolicySettings({
            ...policySettings,
            ...item
        })
    }

    function savePolicy() {
        setSaving(true);

        if (!policySettingValid) {
            NotificationToaster.show(Intent.WARNING, "There are errors on the page that need your attention");
            setSaving(false);
            return;
        }

        EmployeeService.savePolicy(policySettings).then(
            () => {
                setSaving(false);
                setOriginalPolicySettings(policySettings);
                NotificationToaster.show(Intent.SUCCESS, props.show + " updated successfully.");
            },
            () => {
                setSaving(false);
                NotificationToaster.show(Intent.DANGER, "Could not update the " + props.show  + " Please try again.");
            }
        );
    }

    return (
        <div className="row">
            <h2 id="header-new-location">Edit {props.show}</h2>
            <NotificationInline
                id="notification-settings-disabled"
                loading={loading}
                show={disabled}
                text="The policy has ended so can no longer be edited."
                intent="info">
            </NotificationInline>
            <div>
                <ShowHide evaluator={props.show === 'Policy'}
                    show={(
                        <PolicySettings
                            policy={policySettings}
                            onSettingsUpdate={onPolicySettingsChange}
                            onValidationUpdate={onPolicySettingValidationChange}
                            loading={loading}
                            disabled={disabled}
                            saving={saving}
                            reoccurrenceSettingsDisabled={reoccurrenceSettingsDisabled}
                            reoccurrenceSettingsDisabledNotification='Cannot alter start date or reoccurrence settings as the policy has already started.'
                        />
                    )}
                ></ShowHide>
                <ShowHide evaluator={props.show === 'Declarations'}
                    show={(
                        <DeclarationSettings
                            declarations={policySettings}
                            onSettingsUpdate={onPolicySettingsChange}
                            onValidationUpdate={onPolicySettingValidationChange}
                            loading={loading}
                            disabled={disabled || saving}
                        />
                    )}
                ></ShowHide>

                <AlertUnsavedData
                    isDirty={isDataDirty}>
                </AlertUnsavedData>

                <ButtonSave
                    id="btn-policy-edit"
                    onClick={savePolicy}
                    disabled={saving}
                    simpleDisabled={disabled}
                    loading={loading}
                ></ButtonSave>
            </div>
        </div>
    );
}
import React, { Fragment, useState, useEffect } from 'react';
import { Intent } from '@blueprintjs/core'
import { FormHelperText } from "components/form-fields";
import { LoginLayout } from 'pages/layouts';
import { ShowHide } from 'components/layout';
import { UserService } from 'services';
import { NotificationToaster } from "components/notifications";
import { useQuery } from 'hooks/useQuery';

export function ValidateExternalUserEmail() {

    const query = useQuery();
    const externalContactId = query.get("i");
    const verificationCode = query.get("v");

    const [confirmed, setConfirmed] = useState(false);
    const [saving, setSaving] = useState(false);

    const verificationRequest = {
        verificationCode: verificationCode
    };

    useEffect(() => {
        setSaving(true);
        UserService.verifyExternalContact(externalContactId, verificationRequest).then(function () {
            setConfirmed(true);
            setSaving(false);
        }, function (error) {
            setSaving(false);
            setConfirmed(false);

            const forbiddenCode = 403;
            const badRequestCode = 400;

            if (error && (error.status === forbiddenCode || error.status === badRequestCode)) {
                if (typeof error.data !== "string") {
                    NotificationToaster.show(Intent.WARNING, "Verification code is either invalid or the external contact email address already been verified.");
                } else {
                    NotificationToaster.show(Intent.WARNING, error.data.toString());
                }
            } else {
                NotificationToaster.show(Intent.DANGER, "Could not verify the email address, please try again.");
            }
        });
    }, []);

    return (
        <LoginLayout>
            <ShowHide
                evaluator={!saving}
                show={(
                    <ShowHide
                        evaluator={!confirmed}
                        show={(
                            <Fragment>
                                <div className="text-align-center">
                                    <h2>We were unable to verify your email address</h2>
                                    <FormHelperText>
                                        <p>Please request this external contact is removed and readded to receive another email validation request.</p>
                                    </FormHelperText>
                                </div>
                                
                            </Fragment>
                        )}
                        hide={(
                            <Fragment>
                                <div className="text-align-center">
                                <h2>Your email has been successfully validated</h2>
                                    <FormHelperText>
                                        <p>You should now recieve any relevant Vision emails.</p>
                                    </FormHelperText>
                                </div>
                            </Fragment>
                        )}
                    />
                )}
            />
        </LoginLayout>
    );

}
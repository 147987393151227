import React, { useState } from 'react';
import { Intent, Icon } from '@blueprintjs/core';

import { UserAccess } from 'components/user-access';
import { NotificationToaster, AlertUnsavedData } from "components/notifications";
import { PageRestricted } from 'pages/errors/page-restricted';
import { ShowHide } from "components/layout";
import { LocationService } from 'services';
import { Button, ButtonSave, LinkButton } from 'components/buttons';
import { useDirtyData } from 'hooks/useDirtyData';

import { LocationContactSettings } from 'pages/locations/shared';

export function LocationContactCreate(props) {

    const originalModel = {
        users: [],
        locationContactTypeId: null
    };

    const [originalSettings, setOriginalSettings] = useState(originalModel)
    const requiredAction = "Locations:Manage";
    const [contactCreated, setContactCreated] = useState(false);
    const [saving, setSaving] = useState(false);
    const [isValid, setIsValid] = useState(false);
    const [contacts, setContacts] = useState(originalModel);
    const isContactsDirty = useDirtyData(originalSettings, contacts);

    var excludedTypes = [];
    if (props.external) {        
        excludedTypes.push('WALKAROUND');
        excludedTypes.push('VIS'); 
        excludedTypes.push('AUDIT'); 
    }

    function saveContact() {
        setSaving(true);
        
        var newContacts = contacts.users.map(function (contact) {
            if (props.external) {
                return {
                    "ExternalContactId": contact.id,
                    "LocationId": props.locationId,
                    "LocationContactTypeId": contacts.locationContactTypeId
                };
            } else {
                return {
                    "UserId": contact.id,
                    "LocationId": props.locationId,
                    "LocationContactTypeId": contacts.locationContactTypeId
                };
            }
        });


        LocationService.createContacts(
            newContacts, props.external
        ).then(function () {
            setContactCreated(true);
            setContacts(originalModel);
        }, function (error) {
            const badRequestCode = 400;
            if (error.response.status === badRequestCode) {
                NotificationToaster.show(Intent.WARNING, error.response.data);
            } else {
                NotificationToaster.show(Intent.DANGER, "Could not save contacts. Please try again.");
            }
        }).finally(function () {
            setSaving(false);
        });
    }

    function resetComponent() {
        setContacts(originalModel);
        setOriginalSettings(originalModel);
        setContactCreated(false);
    }

    function updateLocationContactSettings(setting) {
        setContacts(setting);
    }

    function onLocationContactSettingValidationUpdate(onIsValid) {
        setIsValid(onIsValid);
    }

    return (
        <UserAccess perform={requiredAction}
            yes={() => (
                <div className="row">
                    <ShowHide
                        evaluator={!contactCreated}
                        show={(
                            <div>
                                <LocationContactSettings
                                    contacts={contacts}
                                    excludeContactTypes={excludedTypes}
                                    external={props.external}
                                    locationId={props.locationId}
                                    onLocationContactSettingsUpdate={updateLocationContactSettings}
                                    onValidationUpdate={onLocationContactSettingValidationUpdate}
                                    saving={saving}
                                    loading={false}
                                    onContactsUpdate={updateLocationContactSettings}
                                />
                                <ButtonSave onClick={saveContact} disabled={saving} simpleDisabled={!isValid} />
                                <AlertUnsavedData
                                    isDirty={isContactsDirty}>
                                </AlertUnsavedData>
                            </div>
                        )}
                        hide={(
                            <div>
                                <div className="spacer-bottom" id="create-success-icon">
                                    <Icon icon="tick-circle" iconSize={40} intent={Intent.SUCCESS} />
                                </div>
                                <h2 id="create-success-name">{props.external ? "External " : ""}Contacts have been added successfully</h2>
                                <div className="button-row" id="create-success-buttons">
                                    <LinkButton intent="primary" text={props.external ? "Back to external contact list" : "Back to contact list"} id="listing-contact" href={`/location/${props.locationId}/contacts`} />
                                    <Button intent="primary" text={props.external ? "Add new external contacts" : "Add new contacts"} id="new-contact" onClick={resetComponent} />
                                </div>
                            </div>
                        )}
                    ></ShowHide>
                    
                </div>
            )}
            no={() => (
                <PageRestricted />
            )}
        />
    );
}
import { Intent } from "@blueprintjs/core";
import axios from "axios";
import { NotificationToaster } from "components/notifications";
import { MigrationWizard } from "components/wizard";
import { useDirtyData } from "hooks/useDirtyData";
import React, { Fragment, useEffect, useState } from "react";
import { AssetService, EmployeeService, LocationService, JobService } from "services";
import PropTypes from 'prop-types';
import { MigrationEmployeeSettings, MigrationAssetSettings } from "components/settings";
import { Redirect, Route, Switch } from "react-router-dom";
import { HorizontalLinkGroup } from "components/navigation";
import { LocationMigrationChangeLog } from "pages/locations/edit/migration/changelog";
import { UserHasAccess } from 'components/user-access';

export function LocationMigration(props) {
    const entityList = [];
    const canMigrationEmployee = UserHasAccess("Employees:Migrate");
    const canMigrationAsset = UserHasAccess("Fleet:Migrate");
    const canMigrationJob = UserHasAccess("Internal");

    const locationFilter = {
        "filters":
            [
                { "key": "States", "value": "Active" }
            ]
    };

    const defaultSettings = {
        toLocation: null,
    };

    const defaultEmployeeSettings = {
        employeeTypes: [],
        employeeStatuses: [],
        employeeCategories: [],
        migrateAllEmployees: false
    };

    const defaultAssetSettings = {
        assetTypes: [],
        assetStatuses: [],
        assetOwnership: [],
        migrateAllEmployees: false
    };

    const defaultLocationJobCount = {
        "openJobCount": 0,
        "visClosedJobCount": 0,
        "auditClosedJobCount": 0,
        "tachoClosedJobCount": 0
    }

    const defaultPath = `/location/${props.locationId}/migration/create`;

    const routes = [
        { location: defaultPath, title: 'Create Migration' },
        { location: `/location/${props.locationId}/migration/history`, title: 'History' }
    ];

    const [originalSettings, setOriginalSettings] = useState(defaultSettings);
    const [loading, setLoading] = useState(true);
    const [saving, setSaving] = useState(false);
    const [locationList, setLocationList] = useState([]);
    const [settings, setSettings] = useState(defaultSettings);
    const [selectedLocation, setSelectedLocation] = useState({});
    const [entities, setEntities] = useState(entityList);
    const [selectedEntities, setSelectedEntities] = useState([]);
    const [employeeOptions, setEmployeeOptions] = useState({});
    const [assetOptions, setAssetOptions] = useState({});
    const [locationJobCount, setLocationJobCount] = useState(defaultLocationJobCount);
    const [employeeSettings, setEmployeeSettings] = useState(defaultEmployeeSettings);
    const [assetSettings, setAssetSettings] = useState(defaultAssetSettings);
    const [employeeSettingsAreValid, setEmployeeSettingsAreValid] = useState(false);
    const [assetSettingsAreValid, setAssetSettingsAreValid] = useState(false);
    const [allowEmployeeMove, setAllowEmployeeMove] = useState(false);
    const [allowAssetMove, setAllowAssetMove] = useState(false);
    const [allowJobMove, setAllowJobMove] = useState(false);
    const [allowMoveMessages, setAllowMoveMessages] = useState([]);

    const areSettingsDirty = useDirtyData(originalSettings, settings);

    if (canMigrationEmployee) {
        entityList.push({ id: "EMPLOYEES", name: `Employees`, checked: false, wizardSteps: [], disabled: false });
    }

    if (canMigrationAsset) {
        entityList.push({ id: "ASSETS", name: `Assets`, checked: false, wizardSteps: [], disabled: false });
    }

    if (canMigrationJob) {
        entityList.push({ id: "JOBS", name: `Jobs`, checked: false, wizardSteps: [], disabled: false });
    }

    useEffect(() => {
        if (entities != null) {
            var selected = entities.filter((e) => e.checked);
            setSelectedEntities(selected);
        }
    }, [entities]);

    useEffect(initialLoad, []);

    useEffect(() => {
        const entityClone = [...entities];

        if (canMigrationEmployee) {
            const employeeEntityWizardSteps = [
                {
                    key: 'EMPLOYEE-OPTIONS',
                    valid: employeeSettingsAreValid,
                    step: <MigrationEmployeeSettings
                        key="employee-settings"
                        onSettingsChange={onEmployeeSettingsChange}
                        employeeOptions={employeeOptions}
                        settings={employeeSettings}
                        onValidationChange={onEmployeeSettingsValidationChange}
                        saving={saving}
                    />
                }
            ];

            const employeeEntity = entityClone.find(x => x.id.toUpperCase() === 'EMPLOYEES');
            employeeEntity.wizardSteps = employeeEntityWizardSteps;
            employeeEntity.disabled = !allowEmployeeMove;
        }

        if (canMigrationAsset) {
            const assetEntityWizardSteps = [
                {
                    key: 'ASSET-OPTIONS',
                    valid: assetSettingsAreValid,
                    step: <MigrationAssetSettings
                        key="asset-settings"
                        onAssetSettingsChange={onAssetSettingsChange}
                        assetOptions={assetOptions}
                        assetSettings={assetSettings}
                        onValidationChange={onAssetSettingsValidationChange}
                        saving={saving}
                    />
                }
            ];

            const assetEntity = entityClone.find(x => x.id.toUpperCase() === 'ASSETS');
            if (assetEntity) {
                assetEntity.wizardSteps = assetEntityWizardSteps;
                assetEntity.disabled = !allowAssetMove;
            }
        }

        if (canMigrationJob) {
            const jobEntity = entityClone.find(x => x.id.toUpperCase() === 'JOBS');
            if (jobEntity) {
                jobEntity.disabled = !allowJobMove;
            }
        }

        setEntities(entityClone);
    }, [employeeSettings, employeeSettingsAreValid, allowEmployeeMove, allowAssetMove, assetSettings, assetSettingsAreValid, allowJobMove, saving, loading]);

    useEffect(() => {
        let newAllowMoveMessages = [];
        var newAllowEmployeeMove = props.locationBusinessAreas?.some(x => x.businessArea.toUpperCase() === 'TACHO');

        var newAllowAssetMove = props.locationBusinessAreas?.some(x => x.businessArea.toUpperCase() === 'TACHO' || x.businessArea.toUpperCase() === 'VIS' || x.businessArea.toUpperCase() === 'PCN');


        if (selectedLocation.hasOwnProperty("businessAreas")) {
            if (newAllowEmployeeMove && !selectedLocation?.businessAreas?.some(b => b.businessArea.toUpperCase() === 'TACHO')){
                newAllowEmployeeMove = false;
            }

            if (newAllowAssetMove && !selectedLocation?.businessAreas?.some(b => b.businessArea.toUpperCase() === 'TACHO' || b.businessArea.toUpperCase() === 'VIS' || b.businessArea.toUpperCase() === 'PCN')) {
                newAllowAssetMove = false;
            }
        }

        setAllowEmployeeMove(newAllowEmployeeMove);
        setAllowAssetMove(newAllowAssetMove);

        if (!newAllowEmployeeMove && canMigrationEmployee) {
            newAllowMoveMessages.push({message: 'Employees cannot be migrated as the location is not assigned the Tacho business area.'});
        }
        if (!newAllowAssetMove && canMigrationAsset) {
            newAllowMoveMessages.push({ message: 'Assets cannot be migrated as the location is not assigned the Tacho, VIS or PCN business area.' });
        }


        var jobMove = true;
        if (canMigrationJob) {
            if (locationJobCount.closedJobCount === 0) {
                jobMove = false;
                newAllowMoveMessages.push({ message: `There are no closed jobs for this location.` });
            }
            else if (locationJobCount.openJobCount > 0) {
                jobMove = false;
                newAllowMoveMessages.push({ message: `Jobs cannot be migrated as there ${locationJobCount.openJobCount === 1 ? 'is' : 'are'} ${locationJobCount.openJobCount} open job${locationJobCount.openJobCount === 1 ? '' : 's'} for this location.` });
            }
            else {
                // Check if the destination location has the required business areas
                if (checkMigrateForBusinessArea('Tacho', newAllowMoveMessages, locationJobCount.tachoClosedJobCount, selectedLocation) === false) {
                    jobMove = false;
                }
                if (checkMigrateForBusinessArea('VIS', newAllowMoveMessages, locationJobCount.visClosedJobCount, selectedLocation) === false) {
                    jobMove = false;
                }
                if (checkMigrateForBusinessArea('Audit', newAllowMoveMessages, locationJobCount.auditClosedJobCount, selectedLocation) === false) {
                    jobMove = false;
                }
            }
        }
        setAllowJobMove(jobMove);
        if (!jobMove) {
            deselectJob();
        }
        setAllowMoveMessages(newAllowMoveMessages);
    }, [props.locationBusinessAreas, locationJobCount, selectedLocation])

    function checkMigrateForBusinessArea(businessArea, messages, closedJobCount, location) {
        var allow = true;
        var businessAreaUpperCase = businessArea.toUpperCase();
        if (closedJobCount > 0 && (!selectedLocation.hasOwnProperty("businessAreas") || !selectedLocation?.businessAreas?.some(b => b.businessArea.toUpperCase() === businessAreaUpperCase))) {
            allow = false;
            messages.push({ message: `Jobs cannot be migrated as there ${closedJobCount === 1 ? 'is' : 'are'} ${closedJobCount} ${businessArea} job${closedJobCount === 1 ? '' : 's'} for this location and no ${businessArea} service for the destination location. ` });
        }

        return allow;
    }

    function initialLoad() {

        var axiosArray = [LocationService.getLocationList(0, 1, '', 'Name', 'A', locationFilter),
            EmployeeService.getEmployeeOptions(),
            AssetService.getAssetOptions()];

        if (canMigrationJob) {
            axiosArray.push(JobService.getLocationJobCount(props.locationId));
        }

        axios.all(axiosArray).then(axios.spread((locationResponse, employeeOptionsResponse, assetOptionsResponse, jobResponse) => {
            const newLocationList = locationResponse.data.filter(x => x.id !== +props.locationId);

            setLocationList(newLocationList);
            setEmployeeOptions(employeeOptionsResponse);
            setAssetOptions(assetOptionsResponse);
            if (!jobResponse) {
                jobResponse = defaultLocationJobCount;
            }
            setAllowJobMove(jobResponse.openJobCount === 0);
            setLocationJobCount(jobResponse);
            setLoading(false);
        }), () => {
            NotificationToaster.show(Intent.DANGER, "Could not load the options needed. Please refresh the page.");
        });
    }

    function onEntitySelected(selected, entityId) {
        const entitiesClone = [...entities];

        const entity = entitiesClone.find(x => x.id === entityId);
        entity.checked = selected;

        setEntities(entitiesClone);
    }

    function deselectJob() {
        const selectedEntitiesClone = [...selectedEntities];

        const entity = selectedEntitiesClone.find(x => x.id === 'JOBS');
        if (entity !== undefined) {
            entity.checked = false;
        }

        setSelectedEntities(selectedEntitiesClone);
    }

    function resetComponent() {
        setSaving(false);
        setOriginalSettings(defaultSettings);
        setEntities(entityList);
        setSettings(defaultSettings);
        setEmployeeSettings(defaultEmployeeSettings);
        setAssetSettings(defaultAssetSettings);
        setSelectedEntities([]);
        setSelectedLocation({});
    }

    function getTransferConfirmationList() {
        const affectedEntityCountPromises = [];

        if (employeeEntitySelected()) {
            let filters = [];

            if (!employeeSettings.migrateAllEmployees) {
                filters = filters.concat(mapLookupsToFilter(employeeSettings.employeeTypes, 'type'));
                filters = filters.concat(mapLookupsToFilter(employeeSettings.employeeCategories, 'category'));
                filters = filters.concat(mapLookupsToFilter(employeeSettings.employeeStatuses, 'status'));
            }

            filters.push({
                key: 'Location',
                value: props.locationName
            });

            const employeesRequest = {
                ignoreLocationPermissions: true,
                pageNumber: 1,
                filters: filters,
                requestCount: 1
            };

            const employeesPromise = EmployeeService.searchEmployees(employeesRequest)
                .then(employees => <span key='employees'>{employees.totalCount} employees</span>);

            affectedEntityCountPromises.push(employeesPromise);
        }

        if (assetEntitySelected()) {
            let filters = [];

            if (!assetSettings.migrateAllAssets) {
                filters = filters.concat(mapLookupsToFilter(assetSettings.assetTypes, 'assettype'));
                filters = filters.concat(mapLookupsToFilter(assetSettings.assetOwnerships, 'ownership'));
                filters = filters.concat(mapLookupsToFilter(assetSettings.assetStatuses, 'status'));
            }

            filters.push({
                key: 'Location',
                value: props.locationName
            });

            const assetsRequest = {
                ignoreLocationPermissions: true,
                pageNumber: 1,
                filters: filters,
                requestCount: 1
            };

            const assetsPromise = AssetService.searchAssets(assetsRequest)
                .then(assets => <span key='assets'>{assets.totalCount} assets</span>);

            affectedEntityCountPromises.push(assetsPromise);
        }

        if (jobEntitySelected()) {
            const jobPromise = JobService.getLocationJobCount(props.locationId)
                .then(jobs => <span key='jobs'>{jobs.closedJobCount} jobs</span>);

            affectedEntityCountPromises.push(jobPromise);
        }
        return axios.all(affectedEntityCountPromises);
    }

    function onTransferEntitiesConfirm() {
        const promiseList = [];
        setSaving(true);

        for (var i = 0; i < selectedEntities.length; i++) {
            var entityKey = selectedEntities[i].id.toUpperCase();

            switch (entityKey) {
                case "EMPLOYEES": {
                    const employeeSettingsData = {
                        ...employeeSettings,
                        fromLocation: props.locationId,
                        toLocation: selectedLocation.id
                    };

                    promiseList.push(EmployeeService.bulkMoveEmployeesForLocation(employeeSettingsData));
                    break;
                }

                case "ASSETS": {
                    const assetSettingsData = {
                        ...assetSettings,
                        fromLocation: props.locationId,
                        toLocation: selectedLocation.id
                    };

                    promiseList.push(AssetService.bulkMoveAssetsForLocation(assetSettingsData));
                    break;
                }

                case "JOBS": {
                    const jobSettingsData = {
                        fromLocation: props.locationId,
                        toLocation: selectedLocation.id
                    };

                    promiseList.push(JobService.bulkMoveJobsForLocation(jobSettingsData));
                    break;
                }

                default:
                    break;
            }
        }

        return axios.all(promiseList);
    }

    function mapLookupsToFilter(lookups, key) {
        return lookups.map(x => {
            return {
                key: key,
                value: x.name
            };
        });
    }

    function onTransferEntitiesCancel() {
        setSaving(false);
    }

    function onTargetLocationSelected(item) {
        setSelectedLocation(item);
    }

    function onEmployeeSettingsChange(item) {
        setEmployeeSettings({ ...item });
    }

    function onAssetSettingsChange(item) {
        setAssetSettings({ ...item });
    }

    function onEmployeeSettingsValidationChange(item) {
        setEmployeeSettingsAreValid(item);
    }

    function onAssetSettingsValidationChange(item) {
        setAssetSettingsAreValid(item);
    }

    function employeeEntitySelected() {
        return selectedEntities.some(x => x.id.toUpperCase() === 'EMPLOYEES');
    }

    function assetEntitySelected() {
        return selectedEntities.some(x => x.id.toUpperCase() === 'ASSETS');
    }

    function jobEntitySelected() {
        return selectedEntities.some(x => x.id.toUpperCase() === 'JOBS');
    }

    return <Fragment>
        <div className="row">
            <h2>Location Migration</h2>
            <div className="intro-text">
                <p>In this section you can migrate different entities from this location to another one.</p>
            </div>
            <div className="spacer-bottom">
                <HorizontalLinkGroup links={routes}></HorizontalLinkGroup>
            </div>
        </div>
        <Switch>
            <Route path="/location/:id(\d+)/migration/create">
                <MigrationWizard
                    entities={entities}
                    onTargetEntitySelect={onTargetLocationSelected}
                    targetEntityList={locationList}
                    onReset={resetComponent}
                    onEntitySelected={onEntitySelected}
                    getTransferConfirmationList={getTransferConfirmationList}
                    onTransferConfirm={onTransferEntitiesConfirm}
                    onTransferCancel={onTransferEntitiesCancel}
                    isWizardDirty={areSettingsDirty}
                    sourceEntityName={props.locationName}
                    targetEntityTitle="Location"
                    loading={loading}
                    saving={saving}
                    selectedTargetEntity={selectedLocation}
                    entitySelectionWarning={''}
                    entitySelectionWarnings={allowMoveMessages}
                />
            </Route>
            <Route exact path="/location/:id(\d+)/migration" render={() => (<Redirect to={defaultPath} />)} />

            <Route path="/location/:id(\d+)/migration/history">
                <LocationMigrationChangeLog locationId={props.locationId} />
            </Route>
        </Switch>
    </Fragment>;
}

LocationMigration.propTypes = {
    locationId: PropTypes.string.isRequired,
    locationName: PropTypes.string.isRequired,
    locationBusinessAreas: PropTypes.array.isRequired
};
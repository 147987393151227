import React from 'react';
import { Tooltip as BPTooltip, Position } from "@blueprintjs/core";
import PropTypes from 'prop-types';

import 'components/tooltip/Tooltip.css';

export function Tooltip(props) {

    let direction;
    switch (props.position.toLowerCase()) {
        case "top":
            direction = Position.TOP;
            break;
        case "bottom":
            direction = Position.BOTTOM;
            break;
        case "left":
            direction = Position.LEFT;
            break;
        case "right":
            direction = Position.RIGHT;
            break;
        default:
            direction = "auto";
            break;
    }

    return (
        <BPTooltip content={props.content} position={direction} placement={direction} minimal={props.minimal} disabled={props.disabled} usePortal={props.usePortal}>
            {props.children}
        </BPTooltip>
    );

}

Tooltip.defaultProps = {
    position: "auto",
    minimal: false,
    disabled: false,
    usePortal: true
};

Tooltip.propTypes = {
    content: PropTypes.any.isRequired,
    position: PropTypes.string,
    minimal: PropTypes.bool,
    disabled: PropTypes.bool,
    usePortal: PropTypes.bool
};

import React, { useState, useEffect, Fragment } from 'react';
import PropTypes from 'prop-types';

import { FormSelect, FormSwitchList, FormTextInput } from 'components/form-fields';
import { ShowHide } from 'components/layout';
import { FormPasswordInput } from 'components/form-fields/FormPasswordInput';
import { useValidation } from "hooks/useValidation";
import { NotificationInline } from 'components/notifications';
import { Button } from 'components/buttons';


export function IntegrationSettings(props) {

    const [isValid, errors, validate] = useValidation();

    const [selectedProvider, setSelectedProvider] = useState({
        providerId: null
    });

    const [providers, setProviders] = useState([]);
    const [services, setServices] = useState([]);

    useEffect(function () {

        setProviders(props.providers.map(function (provider) {
            return {
                ...provider,
                id: provider.providerId,
                name: provider.providerName
            };
        }));

    }, [props.providers]);

    useEffect(onValidationChange, [isValid]);

    function onValidationChange() {
        props.onValidationUpdate(isValid);
    }

    useEffect(updateValidationRules, [props.settings, selectedProvider]);

    function updateValidationRules() {

        const rules = [
            { fieldName: "services", required: true },
            { fieldName: "providerId", required: true }
        ];

        if (selectedProvider.authType === "User") {
            rules.push({ fieldName: "username", required: true });

            if (!props.editing) {
                rules.push({ fieldName: "password", required: true });
            }
        }

        if ((selectedProvider.authType === "OAuth" || selectedProvider.authType === "Token") && !props.editing) {
            rules.push({ fieldName: "token", required: true });
        }

        if (selectedProvider.fleetNameRequired) {
            rules.push({ fieldName: "fleetName", required: true });
        }

        validate(rules, props.settings, []);
    }

    useEffect(function () {
        var foundProvider = providers.find(p => p.providerId === props.settings.providerId);
        setSelectedProvider(foundProvider != null ? foundProvider : {});
    }, [providers, props.settings.providerId]);

    useEffect(function () {
        if (selectedProvider.services != null) {

            var mappedServices = selectedProvider.services.map(function (service) {

                var isChecked = props.settings.services && props.settings.services.some(function (enabledService) {
                    return enabledService === service.id;
                });

                return {
                    ...service,
                    checked: isChecked
                }
            })

            setServices(mappedServices);
        }
    }, [selectedProvider.services, props.settings.services]);


    function onProviderUpdate(item) {
        props.onUpdate({
            ...props.settings,
            providerId: item.id
        });
    }

    function onUsernameUpdate(item) {
        props.onUpdate({
            ...props.settings,
            username: item.target.value
        });
    }

    function onPasswordUpdate(item) {
        props.onUpdate({
            ...props.settings,
            password: item.target.value
        });
    }

    function onTokenUpdate(item) {
        props.onUpdate({
            ...props.settings,
            token: item.target.value
        });
    }

    function onFleetNameUpdate(item) {
        props.onUpdate({
            ...props.settings,
            fleetName: item.target.value
        });
    }

    function handleServiceChange(event) {
        var tempServices = [...props.settings.services];
        var toggleId = parseInt(event.currentTarget.getAttribute('identifier'));

        if (event.target.checked) {
            tempServices.push(toggleId);
        } else {
            tempServices = tempServices.filter(function (s) {
                return s !== toggleId;
            });
        }

        props.onUpdate({
            ...props.settings,
            services: tempServices
        });
    }

    function onOauthRedirect() {
        window.location = selectedProvider.authUrl;
    }

    return (
        <Fragment>
            <FormSelect
                disabled={props.saving || props.editing || (selectedProvider.authType === "OAuth" && props.token != null)}
                items={providers}
                onItemSelect={onProviderUpdate}
                placeholder="Select a provider"
                headingText="Select the provider you wish to connect to:"
                selectedValue={selectedProvider.providerId}
                dangerHelperText={""}
                loading={props.loading}
                id="input-integration-provider"
            ></FormSelect>

            <ShowHide
                evaluator={selectedProvider.providerId != null}
                show={(
                    <Fragment>

                        <ShowHide
                            evaluator={selectedProvider.authType === "User"}
                            show={(
                                <Fragment>
                                    <FormTextInput
                                        loading={props.loading}
                                        headingText="Username:"
                                        value={props.settings.username}
                                        disabled={props.saving || props.editing}
                                        onChange={onUsernameUpdate}
                                        dangerHelperText={errors.username}
                                    />

                                    <NotificationInline show={props.editing} intent="info" text="Your password is only required if you wish to change it from the current password" />

                                    <FormPasswordInput
                                        loading={props.loading}
                                        headingText="Password:"
                                        value={props.settings.password}
                                        disabled={props.saving}
                                        onChange={onPasswordUpdate}
                                        dangerHelperText={errors.password}
                                    />
                                </Fragment>
                            )}
                        />

                        <ShowHide
                            evaluator={selectedProvider.authType === "Token"}
                            show={(
                                <Fragment>
                                    <NotificationInline show={props.editing} intent="info" text="Your token is only required if you wish to change it from the current token" />
                                    <FormPasswordInput
                                        loading={props.loading}
                                        headingText="Token:"
                                        value={props.settings.token}
                                        disabled={props.saving}
                                        onChange={onTokenUpdate}
                                        dangerHelperText={errors.token}
                                    />
                                </Fragment>
                            )}
                        />

                        <ShowHide
                            evaluator={selectedProvider.fleetNameRequired === true}
                            show={(
                                <FormTextInput
                                    loading={props.loading}
                                    headingText="Fleet name:"
                                    value={props.settings.fleetName}
                                    onChange={onFleetNameUpdate}
                                    dangerHelperText={errors.fleetName}
                                />
                            )}
                        />

                        <NotificationInline show={props.token != null} allowClose={false} intent="success" text="User credentials entered successfully, please select integration services to complete integration setup." />

                        <ShowHide
                            evaluator={selectedProvider.authType === "OAuth" && props.token == null}
                            show={(
                                <div className="spacer-bottom">
                                    <div className="spacer-bottom">
                                        <p>To authenticate with this provider, you will need to click the login link below. You will be taken to the provider's login page where you will need to enter your credentials. Upon successful login you will be redirected back here to complete the process.</p>
                                    </div>
                                    <Button text={`Login with ${selectedProvider.providerName}`} intent="primary" onClick={onOauthRedirect} />
                                </div>
                            )}
                            hide={(
                                <FormSwitchList
                                    id="integration-services"
                                    items={services}
                                    onChange={handleServiceChange}
                                    headingText="Integration Services:"
                                    helperText={"Select which services you would like the integration to collect data for"}
                                    loading={props.loading}
                                    switchListName="featureList"
                                    disabled={props.saving}
                                    dangerHelperText={errors.services}
                                />
                            )}
                        />
                        
                    </Fragment>
                )}
            />

        </Fragment>

    );
}


IntegrationSettings.defaultProps = {
    providers: [],
    settings: {},
    editing: false,
    saving: false,
    loading: true,
    token: null
};

IntegrationSettings.propTypes = {
    providers: PropTypes.array.isRequired,
    settings: PropTypes.object.isRequired,
    onValidationUpdate: PropTypes.func.isRequired,
    onUpdate: PropTypes.func.isRequired,
    editing: PropTypes.bool,
    saving: PropTypes.node,
    loading: PropTypes.bool,
    token: PropTypes.string
};
import React, { useState, useEffect } from 'react';
import { Intent, Icon } from '@blueprintjs/core';
import { Breadcrumbs } from 'components/navigation';
import { LinkButton, Button } from "components/buttons";
import { ShowHide } from "components/layout";
import { Wizard } from "components/wizard";
import { useDirtyData } from 'hooks/useDirtyData';
import { UserAccess } from 'components/user-access';
import { PageRestricted } from 'pages/errors/page-restricted';
import { ScheduleReoccurrenceSettings } from 'pages/schedules/shared/reoccurrence-settings';
import { ScheduleReports } from 'pages/schedules/shared/reports';
import { ScheduleReportParameters } from 'pages/schedules/shared/report-parameters';
import { ScheduleRecipients } from 'pages/schedules/shared/recipients';
import { AlertUnsavedData, NotificationInline, NotificationToaster } from "components/notifications";
import { useLocation } from 'react-router-dom';
import moment from "moment";
import { SelfReportingService } from 'services';
import { DateToLocal } from 'components/dates';
import { useDispatch } from 'react-redux';
import { SetFiltering } from 'state/actions';

export function ScheduleCreate(props) {
    const search = useLocation().search;

    const scheduleModel = {
        name: "",
        startDate: moment().utc().add(1, "H").minute(0).format("YYYY-MM-DD HH:mm"),
        reoccurrenceSettings: {
            reoccurring: false
        },
        reports: [],
        reportInstances: [],
        recipients: []
    };

    const [originalSettings, setOriginalSettings] = useState(scheduleModel);
    const [loading, setLoading] = useState(true);
    const [saving, setSaving] = useState(false);
    const [scheduleCreated, setScheduleCreated] = useState(false);
    const [schedule, setSchedule] = useState(scheduleModel);
    const [wizardValid, setWizardValid] = useState(false);
    const [pastSchedule, setPastSchedule] = useState(false);
    const isScheduleDirty = useDirtyData(originalSettings, schedule);
    const dispatch = useDispatch();
    const requiredAction = "ScheduleReports:Manage";

    useEffect(() => {
        
        const id = new URLSearchParams(search).get("clone");
        
        if(id){
            SelfReportingService.getSchedule(id).then(
                function (result) {
                    result.startDate = moment().utc().startOf('hour').add(1,'hours').format("YYYY-MM-DD HH:mm");
                    result.name = result.name + ' - Copy';
                    setSchedule(result);
                },
                function () {
                    NotificationToaster.show(Intent.DANGER, "Unable to clone the schedule.");
                }
            );
        }
        
    },[]);

    useEffect(initialLoad, []);
    function initialLoad() {
        setLoading(false);
        return () => {
            resetComponent() ;
        };
    }

    function updateSchedule(updatedSchedule) {
        setPastSchedule(moment.utc(updatedSchedule.startDate).isBefore(moment().utc()));
        setSchedule(updatedSchedule);
    }
    function saveSchedule() {
        setSaving(true);

        SelfReportingService.createSchedule(schedule).then(
            () => {

                setSaving(false);
                setScheduleCreated(true);

                setOriginalSettings(schedule);
            },
            (error) => {
                setSaving(false);
                NotificationToaster.show(Intent.DANGER, error);
            }
        );
        
    }
    function resetComponent() {
        setSchedule(scheduleModel);
        setOriginalSettings(scheduleModel);
        initialLoad();
        setSaving(false);
        setScheduleCreated(false);
        dispatch(SetFiltering("scheduleWizard", []));
    }



    function onValidationUpdate(isValid) {
        setWizardValid(isValid);
    }

    return (

        <UserAccess perform={requiredAction}
            yes={() => (
                <div className="row">
                    <Breadcrumbs items={props.breadcrumbs} />
                    <ShowHide
                        evaluator={!scheduleCreated}
                        show={(
                            <div>
                                <Wizard onStepChange={() => { }} onFinish={saveSchedule} canProceed={wizardValid} disabled={saving}>
                                    
                                    <div>
                                        <h1 id="header-new-schedule">Add schedule name and details</h1>
                                        <NotificationInline intent="warning" allowClose={false} show={pastSchedule} text="You have selected your schedule to start in the past, upon creating your schedule the recipients may receive multiple reports whilst the schedule catches up to the current day." />
                                        <ScheduleReoccurrenceSettings allowWeeklyRepeating={true} schedule={schedule} onScheduleUpdate={updateSchedule} onValidationUpdate={onValidationUpdate} loading={loading} />
                                    </div>
                                    <div>
                                        <h1 id="header-new-schedule">Choose reports to schedule</h1>
                                        <ScheduleReports schedule={schedule} onScheduleUpdate={updateSchedule} onValidationUpdate={onValidationUpdate} loading={loading} />
                                    </div>
                                    <div>
                                        <h1 id="header-new-schedule">Enter the parameters for the reports</h1>
                                        <p>Here you can configure the parameters for the following reports:</p>
                                        <p>You can specify a date range for the report itself. This is not the same as the schedule start date.</p>
                                        <ScheduleReportParameters schedule={schedule} onScheduleUpdate={updateSchedule} onValidationUpdate={onValidationUpdate} loading={loading} />

                                    </div>
                                    <div>
                                        <h1 id="header-new-schedule">Add recipients to the schedule</h1>
                                        <ScheduleRecipients schedule={schedule} onScheduleUpdate={updateSchedule} onValidationUpdate={onValidationUpdate} loading={loading} />
                                    </div>
                                    <div>
                                        <div className="spacer-bottom">
                                            <h1 id="header-new-schedule">Summary of your schedule</h1>
                                            <div className="intro-text">
                                                <p>Review and confirm your schedule details or click previous to make changes to any part of the schedule.</p>
                                            </div>
                                            <ScheduleReoccurrenceSettings schedule={schedule} onScheduleUpdate={updateSchedule} onValidationUpdate={onValidationUpdate} disabled={true} />
                                            <ScheduleReportParameters schedule={schedule} onScheduleUpdate={updateSchedule} onValidationUpdate={onValidationUpdate} disabled={true} />
                                            <ScheduleRecipients schedule={schedule} onScheduleUpdate={updateSchedule} onValidationUpdate={onValidationUpdate} disabled={true} />
                                        </div>
                                    </div>
                                </Wizard>
                                <AlertUnsavedData
                                    isDirty={isScheduleDirty}>
                                </AlertUnsavedData>

                            </div>
                        )}
                        hide={(
                            <div>
                                <div className="spacer-bottom" id="create-success-icon">
                                    <Icon icon="tick-circle" iconSize={40} intent={Intent.SUCCESS} />
                                </div>
                                <h2 id="create-success-name">{schedule.name} has been added as a schedule</h2>
                                <div className="spacer-bottom">
                                    <p>Your schedule will first run on <strong><DateToLocal>{schedule.startDate}</DateToLocal></strong></p>
                                </div>
                                <div className="button-row" id="create-success-buttons">
                                    <LinkButton intent="primary" text="Schedule management" id="listing-schedule" href="/schedule" />
                                    <Button intent="primary" text="Add new schedule" id="new-schedule" onClick={resetComponent} />
                                </div>
                            </div>
                        )}
                    ></ShowHide>

                </div>

            )}
            no={() => (
                <PageRestricted />
            )}
        />


    );
}
import React, { useState, useEffect } from 'react';
import { Intent, Icon } from '@blueprintjs/core';

import { Breadcrumbs } from 'components/navigation';
import { NotificationToaster, AlertUnsavedData } from "components/notifications";
import { LinkButton, Button, ButtonSave } from "components/buttons";
import { ShowHide } from "components/layout";
import { useDirtyData } from 'hooks/useDirtyData';
import { CollisionsService, EmployeeService } from "services";
import { UserAccess } from 'components/user-access';
import { PageRestricted } from 'pages/errors/page-restricted';
import { CollisionSettings } from 'pages/collisions/shared';
import { FormDateInput, FormSuggest } from 'components/form-fields';
import { useValidation } from 'hooks/useValidation';

import moment from "moment";

export function CollisionCreate(props) {

    const collisionModel = {
        "employeeId": null,
        "assetId": null,
        "incidentDate": null,
        "areaOfDamage": "",
        "driverAtFault": null,
        "thirdPartyInvolvement": null,
        "collisionInjuryLevelId": null,
        "collisionDamageLevelId": null,
        "repairCost": null,
        "totalCost": null
    };

    const [originalSettings, setOriginalSettings] = useState(collisionModel);
    const [loading, setLoading] = useState(true);
    const [saving, setSaving] = useState(false);
    const [collisionCreated, setCollisionCreated] = useState(false);
    const [collision, setCollision] = useState(collisionModel);
    const isDirty = useDirtyData(originalSettings, collision);
    const [isValid, errors, validate] = useValidation();
    const [employeeList, setEmployeeList] = useState([]);

    const validationErrorResponse = 400;
    const requiredAction = "Collisions:Import";

    useEffect(initialLoad, []);
    function initialLoad() {
        setLoading(false);
    }

    function validateModel() {
        const rules = [
            { fieldName: "employeeId", required: true },
            { fieldName: "assetId", required: true },
            { fieldName: "incidentDate", required: true }
        ];

        validate(rules, collision);
    }

    useEffect(validateModel, [collision]);

    function saveCollision() {
        setSaving(true);

        CollisionsService.createCollision(collision).then(
            () => {
                setCollisionCreated(true);
                setSaving(false);
                setOriginalSettings(collision);
            },
            (error) => {
                setSaving(false);
                if (error.response.status === validationErrorResponse) {
                    NotificationToaster.show(Intent.WARNING, error.response.data);
                } else {
                    NotificationToaster.show(Intent.DANGER, "Unable to create collision, please try again.");
                }
                
            }
        );
    }

    function resetComponent() {
        setCollision(collisionModel);
        setOriginalSettings(collisionModel);
        initialLoad();
        setSaving(false);
        setCollisionCreated(false);
    }

    function onIncidentDateChange(item) {
        setCollision((prev) => {
            return {
                ...prev,
                incidentDate: item ? moment(item).format("YYYY-MM-DD HH:mm") : null
            }
        });
    }

    function onEmployeeSelect(item) {
        setCollision((prev) => {
            return {
                ...prev,
                employeeId: item.id
            }
        });
    }

    function onEmployeeSearch(query) {
        var driversToShow = 50;

        EmployeeService.getEmployeeList(driversToShow, 1, query, "FIRSTNAME", "A", []).then(function (driverResponse) {
            setEmployeeList(driverResponse.data.map(function (d) {
                return {
                    id: d.employeeId,
                    name: `${d.fullName} - ${d.location.name}`
                }
            }));
        }, function () {
            NotificationToaster.show(Intent.WARNING, "Could not search for a specific employee, please try again.");
            setEmployeeList([]);
        });
    }

    function onCollisionUpdate(field, value) {
        setCollision((prev) => {
            var newInstance = {
                ...prev
            };
            newInstance[field] = value;
            return newInstance;
        });
    }

    return (

        <UserAccess perform={requiredAction}
            yes={() => (

                <div className="row">
                    <Breadcrumbs items={props.breadcrumbs} />
                    <ShowHide
                        evaluator={!collisionCreated}
                        show={(
                            <div>

                                <h1>Add a new collision</h1>

                                <FormDateInput
                                    disabled={saving}
                                    headingText="Incident date:"
                                    onChange={onIncidentDateChange}
                                    value={collision.incidentDate}
                                    loading={loading}
                                    dangerHelperText={errors.incidentDate}
                                    maxDate={moment().toDate()}
                                    id="collision-incident-date"
                                    includeTime
                                ></FormDateInput>

                                <FormSuggest
                                    loading={loading}
                                    disabled={saving}
                                    items={employeeList}
                                    onItemSelect={onEmployeeSelect}
                                    dangerHelperText={errors.employeeId}
                                    headingText="Employee involved in the collision:"
                                    selectedValue={collision.employeeId}
                                    onFilter={onEmployeeSearch}
                                    debounceTime={500}
                                    id="collision-employee"
                                />

                                <CollisionSettings onCollisionUpdate={onCollisionUpdate} collision={collision} errors={errors} loading={loading} saving={saving} />

                                <AlertUnsavedData
                                    isDirty={isDirty}>
                                </AlertUnsavedData>

                                <ButtonSave text="Add collision" simpleDisabled={!isValid} disabled={saving} onClick={saveCollision} />

                            </div>
                        )}
                        hide={(
                            <div>
                                <div className="spacer-bottom" id="create-success-icon">
                                    <Icon icon="tick-circle" iconSize={40} intent={Intent.SUCCESS} />
                                </div>
                                <h2 id="create-success-name">Collision has been created</h2>
                                <div className="button-row" id="create-success-buttons">
                                    <LinkButton intent="primary" text="Collisions overview" id="listing-collisions" href="/collisions" />
                                    <Button intent="primary" text="Add a new collision" id="new-collision" onClick={resetComponent} />
                                </div>
                            </div>
                        )}
                    ></ShowHide>

                </div>

            )}
            no={() => (
                <PageRestricted />
            )}
        />


    );
}